import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit {

  @Input("title") title;
  @Input("isDisplay") isDisplay;
  @Input("footer") footer;
  @Input("width") width?: string;
  @Input("hideSave") hideSave?: boolean;
  @Output() isClose: EventEmitter<any> = new EventEmitter();
  @Output() isSave: EventEmitter<any> = new EventEmitter();

  constructor() {
    if (!this.width) {
      this.width = '650px';
    }

    if (!this.hideSave) {
      this.hideSave = false;
    }

  }

  ngOnInit() {
  }

  closeModel() {
    this.isClose.emit();
  }

  save() {
    this.isSave.emit();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    this.isClose.emit();
  }
}
