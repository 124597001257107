import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-invalid-lesson-url',
  templateUrl: './invalid-lesson-url.component.html',
  styleUrls: ['./invalid-lesson-url.component.less']
})
export class InvalidLessonUrlComponent implements OnInit {

  constructor(private router: Router, public usersService: UsersService) { }

  ngOnInit() {
  }

  goToDashboard(event) {
    this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
  }

}
