export interface JobRoles {
    name: string;
    value: string;
}

export class MiniBenchmarkQuestion {
    questionId: string;
    questionText: string;
}

export class MiniBenchmarkAnswer {
    questionId: string;
    answer: string;
    answeredOnDate: Date;
}

export class MiniBenchmarkQuestionAnswer {
    userId: string;
    jobId: string;
    firstName: string;
    lastName: string;
    email: string;
    questions: MiniBenchmarkAnswer[];
    startTime: Date;
    endTime: Date;
}

export class MiniBenchmarkScore {
    scoreId: string;
    userId: string;
    userName: string;
    jobRole: string;
    candidateScores: Score[];
    socialDesirabilityInPercentageScore: string;
    readinessInPercentageScore: string;
    hasReport: string;
    jobRoleId: string;
}

export class Score {
    skillName: string;
    idealScore: number;
    calculatedScore: string;
    scoreInPercentage: string;
    skillDescription: string;
}

export class GleacSpiderWebModel {
    categories: string[];
    optimalZones: number[];
    benchmarkScores: number[];
}