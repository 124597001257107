import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { BenchmarkService } from 'src/app/services/benchmark.service';
import { ConfigsService } from 'src/app/services/configs.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.less']
})
export class PaymentSuccessComponent implements OnInit {
  showLoginLink: boolean;

  constructor(private configsService: ConfigsService, private router: Router, private userService: UsersService, private benchmarkService: BenchmarkService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params != {} && params["status"] != undefined) {
        this.showLoginLink = false;
      }
      else {
        this.configsService.loading(true);
        this.showLoginLink = true;
        let sessionId = localStorage.getItem("sessionId");
        this.benchmarkService.updatePurchase(sessionId).subscribe(response => {
          this.configsService.loading(false);
          localStorage.removeItem("sessionId");
        }, (err) => {
          this.configsService.loading(false);
        },
          () => {
            this.configsService.loading(false);
          });
      }
    });
  }

  continue($event) {
    window.open('', '_self', '').close();
  }
}