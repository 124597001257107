import { Injectable } from '@angular/core';
import { Lesson, CandidateLessonAnswer } from '../components/common/common.model';
import { HttpClient } from '@angular/common/http';
import { appSettings } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidateLessonService {

  candidateAnswer: CandidateLessonAnswer;
  userEmailId: string;
  candidateLesson: Lesson;
  baseUrl: string = appSettings.lessonUrl;

  constructor(private http: HttpClient) { }

  getLessonById(lessonId: string, userEmailId: string) {
    let url = this.baseUrl + "Lessons/" + lessonId + "/" + userEmailId;
    return this.http.get<any>(url);
  }

  getLesson(lessonId: string) {
    let url = this.baseUrl + "Lessons/" + lessonId;
    return this.http.get<any>(url);
  }

  getLessonIdByTitle(lessonTitle: string) {
    let url = this.baseUrl + "Lessons/getIdByTitle/" + lessonTitle;
    return this.http.get<any>(url);
  }

  insertLessonAnswer(lessonAnswer: CandidateLessonAnswer) {
    let url = this.baseUrl + "Answers";
    return this.http.post<any>(url, lessonAnswer);
  }

  getDemoLesson(lessonId: string) {
    let url = this.baseUrl + "Lessons/demo/" + lessonId;
    return this.http.get<any>(url);
  }
}
