import {Component, OnInit} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  AsyncValidatorFn,
  ValidationErrors
} from "@angular/forms";
import {Login, UserRegistration, VerificationCode} from "./benchmark-login.model";
import {UsersService} from "src/app/services/users.service";
import {Router, ActivatedRoute} from "@angular/router";
import {UserType} from "../common/common.model";
import {map, delay, debounce, catchError, debounceTime, mergeMap} from "rxjs/operators";
import {of} from 'rxjs';
import {appSettings, environment} from 'src/environments/environment';
import {ConfigsService} from '../../services/configs.service';
import {CookiePopupComponent} from 'src/app/dialogs/cookie-popup/cookie-popup.component';
import {MatDialogRef, MatDialog} from '@angular/material';
import {BenchmarkPopupComponent} from 'src/app/dialogs/benchmark-popup/benchmark-popup.component';
import {BenchmarkService} from 'src/app/services/benchmark.service';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser} from 'angular-6-social-login';
import {SocialGleacUser, UserVerification} from '../login/login.model';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-benchmark-login',
  templateUrl: './benchmark-login.component.html',
  styleUrls: ['./benchmark-login.component.less']
})
export class BenchmarkLoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });
  verificationForm: FormGroup = new FormGroup({
    code_1: new FormControl(),
    code_2: new FormControl(),
    code_3: new FormControl(),
    code_4: new FormControl(),
    code_5: new FormControl(),
    code_6: new FormControl()
  });
  errors: string[] = [];
  showLoginLoader: boolean = false;
  showRegisterLoader: boolean = false;
  showSignup: boolean = false;
  isVerified: boolean = false;
  registrationStepOne: boolean = true;
  loginInProgress: boolean = false;
  registrationStepTwo: boolean = false;
  registerForm: FormGroup;
  loginNotifs: string[] = [];
  isMobileLesson: boolean = false;
  verificationError: boolean = false;
  verificationCode: number;
  socialUser: SocialGleacUser;
  showVerificationLoader: boolean = false;
  cookiePopup: MatDialogRef<CookiePopupComponent>;
  benchmarkPopup: MatDialogRef<BenchmarkPopupComponent>;
  dynamicImageUrl: string = "Gleac-dance.jpg";
  imgArray: string[] = ["Gleac-dance.jpg", "Gleac-fullcircle.jpg", "Gleac-perfecthire.jpg", "Gleac-tongue.jpg", "Gleac-zen.jpg"];
  skipLogin: boolean = false;
  constructor(protected configsService: ConfigsService, protected usersService: UsersService, protected router: Router, protected formBuilder: FormBuilder,
              protected dialog: MatDialog, protected route: ActivatedRoute, protected benchmarkService: BenchmarkService, protected socialService: AuthService) {
  }

  ngOnInit() {
    localStorage.removeItem('GATk');
    this.initializeFormValidations();
    this.loadGsiScript();

    if (environment.isMobile) {
      this.isMobileLesson = true;
    }

    var accessToken = localStorage.getItem("GATk");
    if (accessToken && !window.location.pathname.includes("/lesson/")) {
      this.goToBenchmark(accessToken);
    }

    //this.dynamicImageUrl = this.imgArray[Math.floor(Math.random() * this.imgArray.length)];
  }

  loginUser($event, isGuest: boolean = false) {
    var loginDetails: Login = {
      userName: isGuest? appSettings.guestUserName :this.loginForm.controls["email"].value,
      password: isGuest? appSettings.guestPassword :this.loginForm.controls["password"].value
    };
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid || isGuest) {
      this.showLoginLoader = true;
      this.loginInProgress = true;
      this.usersService.userLogin(loginDetails).subscribe(
        data => {
          if (data.hasError && data.statusCode === 401) {
            this.errors = [];
            this.showLoginLoader = false;
            this.loginInProgress = false;
            if (data.errors && data.errors[0]) {
              this.errors.push(data.errors[0]);
            } else {
              this.errors.push("Incorrect username/password.");
            }
            // Got focus to the error field
            setTimeout(function () {
              let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
              invalidFields[0].focus();
              invalidFields[0].scrollIntoView({behavior: 'smooth'});
            }, 500);
          }
            // else if (data.hasError && data.statusCode === 403) {
            //   this.cookiePopup = this.dialog.open(CookiePopupComponent, {
            //     disableClose: true
            //   });
            //   this.cookiePopup.afterClosed().subscribe(popup => {
            //     this.usersService.acceptPrivacyPolicy(loginDetails.userName).subscribe(
            //       status => {
            //         console.log(status);
            //       },
            //       error => {
            //         this.errors = [];
            //         this.showLoginLoader = false;
            //         this.errors.push("Something went wrong, please try again.");
            //       }
            //     );
            //   });
          // }
          else
            this.gotoUserDashboard(data);
        },
        error => {
          this.errors = [];
          this.showLoginLoader = false;
          this.loginInProgress = false;
          this.errors.push("Something went wrong, please try again.");
          // Got focus to the error field
          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);
        }
      );
    } else {
      this.showLoginLoader = false;
      this.loginInProgress = false;
    }
  }

  goToBenchmark(accessToken) {
    this.configsService.loading(true);
    let jwtData = accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions: decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.route.queryParams.subscribe(params => {
      if (params["backTo"]) {
        this.router.navigate([
          params["backTo"]
        ]);
      } else if (
        this.usersService.currentUser &&
        this.usersService.currentUser.permissions && this.usersService.currentUser.permissions.length > 0
      ) {
        this.errors = [];
        this.configsService.loading(false);
        return this.errors.push("Hello there! As an admin account you don't have access to this page. Kindly refresh this page and login with a non-admin user id.");
      } else {
        this.benchmarkService.getUserBenchmarks(this.usersService.currentUser.userId).subscribe(res => {
          this.configsService.loading(false);
          if (res.hasError == false && res.result.length > 0) {
            this.benchmarkPopup = this.dialog.open(BenchmarkPopupComponent, {
              disableClose: true,
              width: "70%",
              data: {
                dataKey: false
              }
            });
          } else {
            this.benchmarkPopup = this.dialog.open(BenchmarkPopupComponent, {
              disableClose: true,
              width: "70%",
              data: {
                dataKey: true
              }
            });
          }
        });
      }
    })
  }

  gotoUserDashboard(data) {
    this.configsService.loading(true);
    this.showLoginLoader = false;
    this.registrationStepOne = true;
    this.registrationStepTwo = false;
    this.errors = [];
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions: decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.route.queryParams.subscribe(params => {
      if (params["backTo"]) {
        this.router.navigate([
          params["backTo"]
        ]);
      } else if (
        this.usersService.currentUser &&
        this.usersService.currentUser.permissions && this.usersService.currentUser.permissions.length > 0
      ) {
        this.errors = [];
        this.configsService.loading(false);
        return this.errors.push("Hello there! As an admin account you don't have access to this page. Kindly refresh this page and login with a non-admin user id.");
      } else {
        this.benchmarkService.getUserBenchmarks(this.usersService.currentUser.userId).subscribe(res => {
          this.configsService.loading(false);
          if (res.hasError == false && res.result.length > 0) {
            this.benchmarkPopup = this.dialog.open(BenchmarkPopupComponent, {
              disableClose: true,
              width: "70%",
              data: {
                dataKey: false
              }
            });
          } else {
            this.benchmarkPopup = this.dialog.open(BenchmarkPopupComponent, {
              disableClose: true,
              width: "70%",
              data: {
                dataKey: true
              }
            });
          }
        });
      }
    })
  }

  initializeFormValidations() {
    this.loginForm.controls["email"].setValidators([
      Validators.required,
      Validators.email
    ]);
    this.loginForm.controls["password"].setValidators([Validators.required]);
  }

  showUserRegistrationFrom(event: Event) {
    try{
    event.stopPropagation();
    }catch{}
    let newUserRegistration: UserRegistration = {
      // dateOfBirth: undefined,
      confirmationPassword: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      userRole: "",
      acceptPolicy: false
    }
    this.registerForm = this.formBuilder.group(newUserRegistration);
    this.setupRegistrationFormValidations();
    this.showSignup = true;
    this.registrationStepOne = true;
    this.registrationStepTwo = false;
    this.loadGsiScript();
  }

  setupRegistrationFormValidations() {
    for (let key in this.registerForm.controls) {
      switch (key) {
        case "firstName":
        case "lastName":
          this.registerForm.controls[key].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(20), this.blacklistedspecialCharacters.bind(this)]);
          break;
        case "email":
          this.registerForm.controls[key].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.email])
          this.registerForm.controls[key].setAsyncValidators([this.isEmailAvailable.bind(this)])
          break;
        // case "dateOfBirth":
        //   this.registerForm.controls[key].setValidators([Validators.required, this.datePickerValidation.bind(this)])
        //   break;
        // case "organization":
        //   this.registerForm.controls[key].setValidators([Validators.min(2)])
        //   break;
        case "password":
          this.registerForm.controls[key].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(25)])
          break;
        case "confirmationPassword":
          this.registerForm.controls[key].setValidators([Validators.required, this.passwordConfirmationValidator.bind(this)])
          break;
      }
    }
  }

  blacklistedspecialCharacters(control: AbstractControl) {
    const nameRegexp: RegExp = /^[a-zA-Z.]+(?:\s+[a-zA-Z]+)*$/;
    if (control.value && !nameRegexp.test(control.value)) {
      return {"inCorrectName": true};
    }
  }


  passwordConfirmationValidator(control: AbstractControl) {
    var value = this.registerForm.controls.password.value;
    return control.value !== value ? {"invalid": true} : null
  }

  isEmailAvailable(control: AbstractControl) {
    return this.usersService.emailAvailable(control.value).pipe(map(res => {
        if (res.result == 'Email already exists') {
          return {"emailNotAvailable": true}
        }
        return null;
      }), catchError(err => {
        return of({"emailNotAvailable": true})
      }),
      debounceTime(2000))
  }

  datePickerValidation(control: AbstractControl) {
    var date = new Date(control.value);
    var todaysDate = new Date();
    if (!date) {
      return {"invalid": true};
    } else if (date.getTime() < todaysDate.getTime()) {
      return null;
    } else {
      return {"invalid": true}
    }
  }


  hideUserRegistrationFrom() {
    this.registerForm = undefined;
    this.showSignup = false;
    this.registrationStepOne = true;
    this.registrationStepTwo = false;
    this.loadGsiScript();
  }

  registerUser(event) {
    this.errors = [];
    this.showRegisterLoader = true;
    let newUser = this.registerForm.value;
    if (newUser.acceptPolicy) {
      // var loginDetails: Login = {
      //   userName: newUser.email,
      //   password: newUser.password
      // };
      this.registerForm.markAllAsTouched();
      if (this.registerForm.valid) {
        newUser.userRole = UserType.USER;
        //newUser.dateOfBrith = new Date(this.registerForm.controls.dateOfBirth.value).toISOString();
        //this.usersService.registerUser(newUser).pipe(mergeMap(res => this.usersService.userLogin(loginDetails))).subscribe(data => {
        this.usersService.registerUser(newUser).subscribe(data => {
          if (data.hasError) {
            this.showRegisterLoader = false;
            this.showSignup = false;
            this.registrationStepOne = false;
            this.registrationStepTwo = true;
            if (data.errors[0].includes("Account not verified")) {
              this.errors = [];
              if (window.location.pathname.includes("/lesson/")) {
                this.loginNotifs.push("Hey there! We have sent you a verification email. Please verify your account to proceed further. If you refresh this page, your answers will not save.");
                // Got focus to the error field
                setTimeout(function () {
                  let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                  invalidFields[0].focus();
                  invalidFields[0].scrollIntoView({behavior: 'smooth'});
                }, 500);
              } else {
                this.loginNotifs.push("Hey there! We have sent you a verification email. Please verify your account to proceed further.");
                // Got focus to the error field
                setTimeout(function () {
                  let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                  invalidFields[0].focus();
                  invalidFields[0].scrollIntoView({behavior: 'smooth'});
                }, 500);
              }
            } else {
              this.errors.push(data.errors[0]);
              // Got focus to the error field
              setTimeout(function () {
                let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                invalidFields[0].focus();
                invalidFields[0].scrollIntoView({behavior: 'smooth'});
              }, 500);
            }
          } else {
            this.verificationCode = data.result;
            this.showRegisterLoader = false;
            this.showSignup = false;
            this.registrationStepOne = false;
            this.registrationStepTwo = true;
          }
        })
      } else {
        this.showRegisterLoader = false;
      }
    } else {
      this.showRegisterLoader = false;
      this.errors = [];
      this.errors.push("Hey there! Please accept our policies to proceed further.")
      // Got focus to the error field
      setTimeout(function () {
        let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
        invalidFields[0].focus();
        invalidFields[0].scrollIntoView({behavior: 'smooth'});
      }, 500);
    }
  }

  gotoForgotPassword(event) {
    this.router.navigate(["password/forgot"])
  }

  verifyUser(event) {
    this.showVerificationLoader = true;
    var verificationDetails: VerificationCode = {
      code_1: this.verificationForm.controls["code_1"].value,
      code_2: this.verificationForm.controls["code_2"].value,
      code_3: this.verificationForm.controls["code_3"].value,
      code_4: this.verificationForm.controls["code_4"].value,
      code_5: this.verificationForm.controls["code_5"].value,
      code_6: this.verificationForm.controls["code_6"].value
    };
    this.verificationForm.markAllAsTouched();
    if (this.verificationForm.valid) {
      var userCode = Number(verificationDetails.code_1 + '' + verificationDetails.code_2 + '' + verificationDetails.code_3 + ''
        + verificationDetails.code_4 + '' + verificationDetails.code_5 + '' + verificationDetails.code_6);
      if (userCode) {
        this.verificationError = false;
        var verification: UserVerification = {
          Email: this.registerForm.controls["email"].value,
          VerificationCode: userCode.toString()
        }
        var loginDetails: Login = {
          userName: this.registerForm.controls.email.value,
          password: this.registerForm.controls.password.value
        };
        this.showLoginLoader = true;
        this.loginInProgress = true;

        this.usersService.verifyUser(verification).subscribe(verifyRes => {

          if (verifyRes.hasError) {
            this.verificationError = true;
            this.showVerificationLoader = false;
            this.configsService.loading(false);

            this.errors.push(verifyRes.errors[0]);
            setTimeout(function () {
              let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
              invalidFields[0].focus();
              invalidFields[0].scrollIntoView({behavior: 'smooth'});
            }, 500);
          } else {
            this.usersService.userLogin(loginDetails).subscribe(data => {
              if (data.hasError) {
                this.showVerificationLoader = false;
                this.configsService.loading(false);
                this.errors.push(data.errors[0]);
                // Got focus to the error field
                setTimeout(function () {
                  let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                  invalidFields[0].focus();
                  invalidFields[0].scrollIntoView({behavior: 'smooth'});
                }, 500);
              } else {
                this.isVerified = true;
                this.showVerificationLoader = false;
                this.gotoUserDashboardAfterVerification(data);
              }
            })
          }
        });
      } else {
        this.verificationError = true;
        this.showVerificationLoader = false;
        this.configsService.loading(false);
      }
    } else {
      this.showVerificationLoader = false;
    }
  }

  verifyBenchmarkUser(event) {
    this.showVerificationLoader = true;
    var verificationDetails: VerificationCode = {
      code_1: this.verificationForm.controls["code_1"].value,
      code_2: this.verificationForm.controls["code_2"].value,
      code_3: this.verificationForm.controls["code_3"].value,
      code_4: this.verificationForm.controls["code_4"].value,
      code_5: this.verificationForm.controls["code_5"].value,
      code_6: this.verificationForm.controls["code_6"].value
    };
    this.verificationForm.markAllAsTouched();
    if (this.verificationForm.valid) {
      var userCode = Number(verificationDetails.code_1 + '' + verificationDetails.code_2 + '' + verificationDetails.code_3 + ''
        + verificationDetails.code_4 + '' + verificationDetails.code_5 + '' + verificationDetails.code_6);
      if (this.verificationCode == userCode) {
        this.verificationError = false;
        var verification: UserVerification = {
          Email: this.registerForm.controls["email"].value,
          VerificationCode: this.verificationCode.toString()
        }
        var loginDetails: Login = {
          userName: this.registerForm.controls.email.value,
          password: this.registerForm.controls.password.value
        };
        this.showLoginLoader = true;
        this.loginInProgress = true;
        this.usersService.verifyUser(verification).pipe(mergeMap(res => this.usersService.userLogin(loginDetails))).subscribe(data => {
          if (data.hasError) {
            this.showVerificationLoader = false;
            this.configsService.loading(false);
            this.errors.push(data.errors[0]);
            // Got focus to the error field
            setTimeout(function () {
              let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
              invalidFields[0].focus();
              invalidFields[0].scrollIntoView({behavior: 'smooth'});
            }, 500);
          } else {
            this.isVerified = true;
            this.showVerificationLoader = false;
            this.gotoUserDashboard(data);
          }
        });
      } else {
        this.verificationError = true;
        this.showVerificationLoader = false;
        this.configsService.loading(false);
      }
    } else {
      this.showVerificationLoader = false;
    }
  }

  gotoUserDashboardAfterVerification(data) {
    this.showLoginLoader = false;
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions: decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.configsService.loading(false);
  }

  resendCode(event) {
    let email = this.registerForm.controls.email.value;
    this.usersService.resendVerificationCode(email).subscribe(data => {
      if (!data.hasError) {
        this.verificationCode = data.result;
      }
    });
  }

  moveFocus(event) {
    if (event.target.value != null && event.target.value != undefined && event.target.value != "") {
      let element = event.srcElement.nextElementSibling; // get the sibling element
      if (element == null)  // check if its null
        return;
      else
        element.focus();   // focus if not null
    }
  }

  socialSigin(platform: string) {
    if (this.loginInProgress == true) {
      return false;
    }
    let socialPlatformProvider;
    if (platform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (platform == "gmail") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialService.signIn(socialPlatformProvider).then((userData) => {
      this.configsService.loading(true);
      this.socialUser = {
        email: userData.email,
        socialId: userData.id,
        image: userData.image,
        name: userData.name,
        provider: userData.provider,
        idToken: userData.idToken,
        token: userData.token,
        userRole: UserType.USER
      };
      this.usersService.authorizeSocialUser(this.socialUser).subscribe(
        data => {
          if (data.hasError) {
            this.errors = [];
            this.showLoginLoader = false;
            this.configsService.loading(false);
            this.errors.push("Something went wrong, please try again.")
            // Got focus to the error field
            setTimeout(function () {
              let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
              invalidFields[0].focus();
              invalidFields[0].scrollIntoView({behavior: 'smooth'});
            }, 500);
          } else {
            this.configsService.loading(false);
            this.gotoUserDashboard(data);
          }
        },
        error => {
          this.errors = [];
          this.showLoginLoader = false;
          this.configsService.loading(false);
          this.errors.push("Something went wrong, please try again.");
          // Got focus to the error field
          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);
        }
      );
    });
  }

  public loadGsiScript() {
    const context = this;
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = "https://accounts.google.com/gsi/client";
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = function () {
      setTimeout(function () {
        let google = (window as any).google;
        google.accounts.id.initialize({
          client_id: appSettings.googleClientId,
          callback: (data) => {
            context.handleGsiCredentialResponse(data);
          }
        });

        google.accounts.id.renderButton(
          document.getElementById("gButton"),
          {type: "icon", theme: "outline", size: "large", shape: "circle"}  // customization attributes
        );
        google.accounts.id.renderButton(
          document.getElementById("gsButton"),
          {type: "icon", theme: "outline", size: "large", shape: "circle"}  // customization attributes
        );
      }, 200);

    }
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  handleGsiCredentialResponse(data) {

    if (this.loginInProgress == true) {
      return false;
    }

    let decodedJwtData: any = jwt_decode(data.credential);
    console.log(decodedJwtData);

    this.socialUser = {
      email: decodedJwtData.email,
      socialId: decodedJwtData.sub,
      image: decodedJwtData.picture,
      name: decodedJwtData.name,
      provider: 'google',
      userRole: UserType.USER
    };

    this.configsService.loading(true);

    this.usersService.authorizeSocialUser(this.socialUser).subscribe(
      data => {
        if (data.hasError) {
          this.errors = [];
          this.showLoginLoader = false;
          this.configsService.loading(false);
          this.errors.push("Something went wrong, please try again.")
          // Got focus to the error field
          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);
        } else {
          this.configsService.loading(false);
          this.gotoUserDashboard(data);
        }
      },
      error => {
        this.errors = [];
        this.showLoginLoader = false;
        this.configsService.loading(false);
        this.errors.push("Something went wrong, please try again.");
        // Got focus to the error field
        setTimeout(function () {
          let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
          invalidFields[0].focus();
          invalidFields[0].scrollIntoView({behavior: 'smooth'});
        }, 500);
      }
    );
  }

}
