import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[appExpandableRow]'
})
export class ExpandableRowDirective {

  constructor(public viewContainerRef: ViewContainerRef) {}

  @Input() rowExtraData: any;
}
 