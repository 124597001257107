import { Component, OnInit } from '@angular/core';
import { LessonUpsertComponent } from '../lesson-upsert/lesson-upsert.component';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsService } from 'src/app/services/lessons.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Section, SectionElementType } from '../common/common.model';
import { MatDialog } from '@angular/material';
import { ConfigsService } from '../../services/configs.service';
import {MentorService} from "../../services/mentor.service";

@Component({
  selector: 'app-lesson-customize',
  templateUrl: './lesson-customize.component.html',
  styleUrls: ['./lesson-customize.component.less']
})
export class LessonCustomizeComponent extends LessonUpsertComponent implements OnInit {

  constructor(formBuilder: FormBuilder,
    lessonsService: LessonsService,
    route: ActivatedRoute,
    router: Router,
    protected configsService : ConfigsService,
    usersService: UsersService,
    protected sanitizer: DomSanitizer,
    protected mentorService : MentorService,
    dialog: MatDialog) {
    super(formBuilder, lessonsService, route, router, usersService, dialog,sanitizer, mentorService,configsService)
  }

  ngOnInit() {
  }

  sanitinzeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  saveSection(event) {
    this.sectionFormErrors = [];
    this.lessonFormErrors = [];
    this.sectionForm.markAllAsTouched();
    if (this.sectionForm.valid && this.newSectionControls.length > 0) {
      var section = this.populateSection();
      section.isCustom = true;
      this.lessonDetails.sections.push(section);
      this.discardNewSection();
    } else {
      if (this.newSectionControls.length == 0) {
        this.sectionFormErrors.push("Atleast one control is required");
      }
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push(
        "There are invalid values in new section. Please correct them."
      );
    }
  }

  dropSection(event) {
    let section = this.lessonDetails.sections[event.previousIndex];
    // this.lessonDetails.sections[event.previousIndex] = this.lessonDetails.sections[
    //   event.currentIndex
    // ];
    // this.lessonDetails.sections[event.currentIndex] = temp;
    this.lessonDetails.sections.splice(event.previousIndex, 1);
    this.lessonDetails.sections.splice(event.currentIndex, 0, section);
  }

  editSection(event, index) {
    this.selectedControlType.setValue(1);
    this.sectionToEdit = this.lessonDetails.sections[index];
    let sectionControlsEditForm = this.formBuilder.array(
      this.sectionToEdit.sectionControls.map(x => {
        let element = {
          sectionElementContent: this.formBuilder.control(x.sectionElementContent, Validators.required),
          sectionElementType: x.sectionElementType,
          sectionElementOptions: undefined,
          sectionElementImageName: undefined
        };
        if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE) {
          element.sectionElementOptions = this.formBuilder.array(
            x.sectionElementOptions,
            Validators.required
          );
        }
        return this.formBuilder.group(element, Validators.required);
      }),
    );
    this.sectionEditForm = this.formBuilder.group({
      sectionControls: sectionControlsEditForm,
      sectionTitle: this.formBuilder.control(
        this.sectionToEdit.sectionTitle,
        Validators.required
      )
    });
  }

  saveEditedSection(event, index) {
    this.sectionFormErrors = [];
    this.sectionEditForm.markAllAsTouched();
    if (this.sectionEditForm.valid && this.sectionEditForm.value.sectionControls.length > 0) {
      this.lessonDetails.sections[index] = this.sectionEditForm.value;
      this.discardEditSection();
    }
    else if (this.sectionEditForm.value.sectionControls.length == 0) {
      this.sectionFormErrors.push("Atleast 1 section is required")
    }
  }

  dropControl(event, sectionIndex) {
    let section = this.lessonDetails.sections[sectionIndex];
    let control = section.sectionControls[event.previousIndex];
    // let temp = section.sectionControls[event.previousIndex];
    // section.sectionControls[event.previousIndex] =
    //   section.sectionControls[event.currentIndex];
    // section.sectionControls[event.currentIndex] = temp;
    section.sectionControls.splice(event.previousIndex, 1);
    section.sectionControls.splice(event.currentIndex, 0, control)
  }

  addCustomLesson(event) {
    if (
      this.selfReviewForm.valid &&
      !this.sectionToEdit &&
      !this.sectionForm &&
      this.lessonDetails.sections.length > 0
    ) {
      this.lessonDetails = {
        answer: this.lessonDetails.answer,
        lessonCategoryId: this.lessonDetails.lessonCategoryId,
        companyId: this.usersService.currentUser.userId,
        lessonId: undefined,
        lessonSummary: this.lessonDetails.lessonSummary,
        lessonImage: this.lessonDetails.lessonImage,
        level: this.lessonDetails.level,
        subLevel: this.lessonDetails.subLevel,
        title: this.lessonDetails.title,
        selfReviewQuestions: this.lessonDetails.selfReviewQuestions,
        sections: this.lessonDetails.sections
      };

      this.configsService.loading(true);
      this.lessonsService.uploadSectionImages(this.imageUploadArray).subscribe(res => {
      }, err => {
        this.configsService.loading(false);
        this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
      },()=> {
         this.configsService.loading(false);
      });

      this.configsService.loading(true);
      this.lessonsService.addLesson(this.lessonDetails).subscribe(
        res => {
          this.done.emit(res.result.lessonId);
        },
        err => {
          this.configsService.loading(false);
          this.lessonEditTab.nativeElement.scrollIntoView();
          this.lessonFormErrors.push("Something went wrong. Please try again");
        },()=> {
            this.configsService.loading(false);
        });


    } else if (this.lessonDetails.sections.length === 0) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Atleast 1 section is required");
    } else if (this.sectionToEdit) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    } else if (this.sectionForm) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    }
  }

}
