import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { safeJsonParse } from 'ngx-cookie';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VotingService {
  baseUrl = appSettings.mobileUrl;
  constructor(private http : HttpClient) { }
  getVotingChallenge(challengeId){
    let url=this.baseUrl + "Challenge/GetChallengeDetailsForVoting/" + challengeId;
    return this.http.get<any>(url);
  }
  castVote(vote){
    let url = this.baseUrl+"Challenge/SaveCastedVote";
   
    let deviceId = localStorage.getItem("deviceId");
    
        if (!deviceId) {
          deviceId = this.uuidv4();
          localStorage.setItem("deviceId", deviceId);
        }
    
        vote.device_id = deviceId;
    
    let response = this.http.post<any>(url,vote);

    return response; 
  }
  uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
  }

}
