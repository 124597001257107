import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {LessonsService} from "src/app/services/lessons.service";
import {ConfigsService} from 'src/app/services/configs.service';
import {SkillTags, SkillType} from '../common/common.model';
import {NgForm} from '@angular/forms';
import {CommonService} from 'src/app/services/common.service';
import {from} from 'rxjs';
import {appSettings} from 'src/environments/environment';
import {Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {MentorService} from 'src/app/services/mentor.service';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import {FeaturedMentorAnswerService} from "../../services/featured-mentor-answer.service";
import {FileUploadService} from "../../services/file-upload.service";
import {F} from "@angular/cdk/keycodes";
import {saveAs} from 'file-saver';
import {MentorBookingsService} from "../../services/mentor-bookings.service";
import {CouponService} from "../../services/coupon.service";
import {GoalsService} from "../../services/goal.service";


@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.less']
})
export class CouponsComponent implements OnInit {

  organizationOptions = [];

  pageUrl: string = "mentor/Coupons";
  page_title = "Coupons";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  sortColumn: string = '';
  sortOrder: string = '';
  status: number;
  selectedCoupon: any;

  gridData: any;

  addCouponFlag = false;

  meeting: any;

  viewBookings: boolean = false;
  bookingsData = {list: [], totalCount: 0};


  isconfirmPopup = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  iscreatePopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  create_popup = {
    display: false,
    title: "",
    createrName: '',
    isUpdate: false,
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    public usersService: UsersService,
    private couponService: CouponService,
    private goalsService: GoalsService,
    private datepipe: DatePipe,
    private mentorBookings: MentorBookingsService,
  ) {
    this.getCoupons();
  }

  ngOnInit() {
    this.getOrganizationsList();
    this.usersService.isMentor = true;
  }

  bookingsColumnData: GleacColumnDef[] = [
    {
      displayName: "Booker Name",
      data: "bookerName",
      isExpandable: true,
    },
    {
      displayName: "Booker Email",
      data: "bookerEmail",
    },
    {
      displayName: "Mentors",
      data: "Mentors",
      disableSort: true,
      render: (data, row) => {
        return row.mentors ? row.mentors.join(", ") : "";
      }
    },
    {
      displayName: "Date",
      data: "bookingDateTime",
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${row.bookingDateTime}`)), 'MMM d, y HH:mm');
      }
    },
    {
      displayName: "Created At",
      data: "createdAt",
      isDate: true
    },
  ];

  nftColumnData: GleacColumnDef[] = [
    {
      displayName: "Company Name",
      data: "companyName",
      isExpandable: true,
    },
    {
      displayName: "Code",
      data: "code",
      disableSort: true,
    },
    {
      displayName: "Credits Left",
      data: "creditsLeft",
    },
    {
      displayName: "Expiration Date",
      data: "expirationDate",
      render: (data, row) => {
        if (row.expirationDate)
          return this.datepipe.transform(new Date(`${row.expirationDate}`), 'MMM d, y');
        return "";
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        }
      ],
    },
  ];

  getCoupons() {
    this.configsService.loading(true);
    this.couponService.getCoupons(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder,
    ).subscribe(
      (res) => {
        if (res) {
          this.gridData = {
            list: res.result.data,
            totalCount: res.result.totalCount
          };
          //this.mentors = res.result.data;
        } else {
          this.gridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getOrganizationsList() {
    this.goalsService.getCompanies('', 1000, 1).subscribe((res) => {
      res.result.results.forEach(org => {
        this.organizationOptions.push({
          value: org.company_id,
          name: org.company_name
        });
      });
    }, (err) => {

    });
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getCoupons();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getCoupons();
  }

  sortChange(event) {
    this.pageNumber = 1;
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.getCoupons()
  }

  onClose() {
    this.addCouponFlag = false;
    this.pageNumber = 1;
    this.getCoupons();
  }

  deleteCoupon(id) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        this.couponService.deleteCoupon({id: id}).subscribe(
          (data) => {
            this.postStatus(data, "Deleted Successfully");
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.couponService.exportCoupons(1,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "Coupons" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  actionClick(event) {
    if (event.action === "bookings") {
    } else if (event.action === "edit") {
      this.addUpdateCoupon(event.data);
    } else if (event.action === "delete") {
      this.deleteCoupon(event.data.id)
    }
  }

  addUpdateCoupon(data: any) {
    let companyDetails = data ? this.organizationOptions.filter(x => {
      return x.value == data.companyId;
    }) : null;

    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      title: "Add/Update Coupon",
      createrName: 'coupon',
      isUpdate: false,
      field: [
        {
          disabled: data != null,
          label: "Company",
          key: "companyId",
          required: "required",
          placeholder: "Select a company",
          type: "dropdown",
          bindLabel: "name",
          multiple: false,
          value: companyDetails != null && companyDetails.length > 0 ? companyDetails[0] : null,
          options: this.organizationOptions,
        },
        {
          label: "Credits",
          key: "creditsLeft",
          placeholder: "Enter the amount of credits",
          type: "input",
          cssClass: 'col-12',
          innertype: "number",
          min: 0,
          max: 6,
          pattern: "^[0-9]{1,7}$",
          required: "required",
          maxlength: 100,
          value: data ? data.creditsLeft : "",
          errorMsg: "Enter valid credit limits below 1000000"
        },
        {
          label: "Expiry Date",
          key: "date",
          type: "date_picker_date",
          cssClass: 'col-12',
          placeholder: "Expiry Date",
          value: data && data.expirationDate ? this.convertUTCDateToLocalDate(new Date(`${data.expirationDate}`)) : "",
        },
      ],
      bulk_upload: false,
      bulkInfo: {},
      buttonPositive: data ? "Update" : "Save",
      refreshData: function () {
        this.getCoupons();
      },
      closeModal: function () {
        this.iscreatePopup = false;
      },
      createrCall: form => {
        let couponData = Object.assign(form.value);
        let coupon: any = {};

        let expiryDate = new Date();

        if (couponData.date) {
          expiryDate.setFullYear(couponData.date.getFullYear());
          expiryDate.setMonth(couponData.date.getMonth());
          expiryDate.setDate(couponData.date.getDate());
          expiryDate.setHours(0);
          expiryDate.setMinutes(0);
          expiryDate.setSeconds(0);
        }

        let date_utc = couponData.date ? this.toIsoString(expiryDate).split("T")[0] : null;

        coupon.id = data ? data.id : 0;
        coupon.companyId = couponData.companyId ? couponData.companyId.value : 0;
        coupon.creditsLeft = couponData.creditsLeft;
        coupon.expirationDate = date_utc;

        if (form.valid) {

          if (!data) {
            this.configsService.loading(true);
            this.couponService.addCoupon(coupon).subscribe((res) => {
              this.configsService.loading(false);
              this.iscreatePopup = false;
              this.postStatus(res, "Coupon Added Successfully");
            }, () => {
              this.configsService.loading(false);
            })
          } else {
            this.configsService.loading(true);
            this.couponService.updateCoupon(coupon).subscribe((res) => {
              this.configsService.loading(false);
              this.iscreatePopup = false;
              this.postStatus(res, "Coupon Updated Successfully");
            }, () => {
              this.configsService.loading(false);
            })
          }
        }
      }
    }
  }

  postStatus(result, message) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getCoupons();
        },
      };
    } else {
      this.iserrorPopup = true;
    }
  }

  convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }

  closeViewBookings() {
    this.viewBookings = false;
    this.bookingsData = {list: [], totalCount: 0};
  }

  toIsoString(date) {
    let tzo = -date.getTimezoneOffset(), dif = tzo >= 0 ? '+' : '-', pad = function (num) {
      return (num < 10 ? '0' : '') + num;
    };

    return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + 'T' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds()) + dif + pad(Math.floor(Math.abs(tzo) / 60)) + ':' + pad(Math.abs(tzo) % 60);
  }
}

