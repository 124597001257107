import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA} from '@angular/material';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {CommonService} from 'src/app/services/common.service';
import {ConfigsService} from 'src/app/services/configs.service';
import {from} from 'rxjs';
import {appSettings} from 'src/environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {GoalsService} from 'src/app/services/goal.service';
import {GoalsPopupComponent} from 'src/app/dialogs/goal-popup/goals-popup.component';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';

import {saveAs} from 'file-saver';
import {CommunicationService} from 'src/app/services/communication.service';
import {CommunicationsPopupComponent} from 'src/app/dialogs/communication-popup/communications-popup.component';

import * as fileSaver from 'file-saver';
import {CloseScrollStrategy} from '@angular/cdk/overlay';


@Component({
  selector: 'app-communicationsLibrary',
  templateUrl: './communications-library.component.html',
  styleUrls: ['./communications-library.component.less']
})
export class CommunicationsLibraryComponent implements OnInit {
  pageUrl: string = "communicationsLibrary";
  page_title = "Communications Library";
  searchString: string = "";
  pageNumber: number = 1;
  pageSize: number = 10;
  isEdit: boolean;
  error_popup = {};
  currentSection = 1;
  url = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  communicationsLibraryGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  communications: any;
  sortColumn: string = "";
  sortOrder: string = "";
  communicationPopup: MatDialogRef<CommunicationsPopupComponent>;
  deleteRequest: any = {};
  id: number = 0;

  create_popup = {
    display: false,
    title: "",
    field: [],
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    }
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        {name: "Active", value: "Active"},
        {name: "Inactive", value: "Inactive"},
      ],
    }
  ];

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public usersService: UsersService,
    private communicationService: CommunicationService,
    protected dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
    this.getCommunicationsForLibraryList();
    this.route.params.subscribe(params => {
      this.id = params["id"];
    });
  }

  communicationsLibraryColumnData: GleacColumnDef[] = [
    {
      displayName: "Title",
      data: "title",
      render: (data, row) => {
        return `<span class="float-left">${row.title ? row.title : '-'}</span>`;
      }
    },
    {
      displayName: "Created Date",
      data: "createdOn",
      // render: (data, row) => {
      //   return `<span class="float-left" style="width:80px;">${row.createdOn ? row.createdOn : '-'}</span>`;
      // }
      render: (data, row) => {
        var date = new Date(this.convertUTCDateToLocalDate(row.createdOn));
        return (
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear() +
          ", " +
          (date.getHours() % 12
            ? date.getHours() % 12 < 10
              ? "0" + (date.getHours() % 12)
              : date.getHours() % 12
            : 12) +
          ":" +
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) +
          " " +
          (date.getHours() >= 12 ? "PM" : "AM")
        );
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Communication_Add'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Communication_Delete'
        },
        {
          label: "Assign Communication",
          url: "#",
          functionType: "assign",
          image: "assets/images/add_icon_goals.svg",
          permission: 'Communication_Add'
        }
      ]
    }
  ];

  convertUTCDateToLocalDate(date) {
    if (typeof date.getMonth !== 'function') {
      date = new Date(date);
    }
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  getCommunicationsForLibraryList() {
    this.configsService.loading(true);
    this.communicationService.getCommunicationsForLibrary(
      this.pageNumber,
      this.pageSize
    ).subscribe(
      (res) => {
        if (res) {
          if (res.results.length == 0 && this.pageNumber > 1) {
            this.pageNumber--
            this.getCommunicationsForLibraryList();
            return;
          }

          let communicationsData = {
            list: res.results,
            totalCount: res.totalCount
          }
          this.communicationsLibraryGridData = communicationsData;
          this.openTemplate();
        } else {
          this.communicationsLibraryGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getSearchResults(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    this.configsService.loading(true);
    this.communicationService.searchCommunicationsLibrary(
      encodeURIComponent(searchString), pageNumber, pageSize, sortColumn, sortOrder).subscribe(
      (res) => {
        if (res) {
          let communicationsData = {
            list: res.results,
            totalCount: res.totalCount
          }
          this.communicationsLibraryGridData = communicationsData;
        } else {
          this.communicationsLibraryGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  searchData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, '', '');
  }

  pageChange(event) {
    this.pageNumber = event;

    if ((this.searchString !== undefined && this.searchString !== '') || (this.sortColumn !== undefined && this.sortColumn !== '')) {
      this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
    } else {
      this.getCommunicationsForLibraryList();
    }
  }

  exportToExcel() {
    this.configsService.loading(true);
    var date = new Date();
    var timeDiff = date.getTimezoneOffset();
    this.communicationService.exportCommunicationsLibrary(
      encodeURIComponent(this.searchString), this.sortColumn, this.sortOrder, timeDiff).subscribe(
      (res) => {
        saveAs(res, "Communication_Library" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.searchString = this.searchString;

    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);

  }

  editCommunicationTemplate(event) {
    console.log("edit goal call.::::", event);
    console.log("row.::::", event.data);

    this.communicationPopup = this.dialog.open(CommunicationsPopupComponent, {
      disableClose: true,
      width: "90%",
      height: "auto",
      data: {
        CommunicationLibraryId: event.data.id,
        isEdit: true,
        fileName: event.data.title,
        isTemplate: true
      }
    });

    this.communicationPopup.beforeClosed().subscribe(result => {
      this.pageNumber = 1;
      this.getCommunicationsForLibraryList();
    });
  }

  deleteCommunicationTemplate(event) {
    console.log(event.data);
    this.deleteRequest.id = event.data.id;

    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Communication Library",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.communicationService.deleteCommunicationsLibrary(this.deleteRequest).subscribe(
          (data) => {
            if (data.has_error) {
              this.showToast(true, data.errors[0]);
              return;
            } else {
              this.showToast(false, "Communication Deleted Successfully!");
              this.searchString = "";
            }
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: err.error.errors[0],
            };
          },
          () => {
            this.configsService.loading(false);
            this.pageNumber = 1;
            this.getCommunicationsForLibraryList();
          }
        );
        this.confirm.display = false;
      }
    };
  }

  assignCommunicationTemplate(event) {
    this.communicationPopup = this.dialog.open(CommunicationsPopupComponent, {
      disableClose: true,
      width: "90%",
      height: "auto",
      data: {
        CommunicationLibraryId: event.data.id,
        // fileName: event.data.title,
        title: event.data.title,
        isEdit: false,
        isTemplate: true,
        isShowDropdown: false,
        isAssign: true
      }
    });
  }

  actionClick(event) {
    if (event.action === "assign") {
      console.log("Assign Communication Event");
      this.assignCommunicationTemplate(event);
    } else if (event.action === "delete") {
      console.log("Delete Communication Event");
      this.deleteCommunicationTemplate(event);
    } else {
      console.log("Edit Communication Event");
      this.editCommunicationTemplate(event);
    }
  }

  showToast(isError, message) {
    if (isError) {
      this.iserrorPopup = true;
      this.error_popup = {display: true, message: message};
      this.iscreatePopup = true;
    } else {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.iscreatePopup = false;
          this.success_popup.display = false;
        },
      };
    }
  }

  openTemplate() {
    if (this.id) {
      let data = this.communicationsLibraryGridData.list.filter(x => x.id == this.id);
      this.id = 0;
      if (data && data.length > 0) {
        console.log(data);
        this.router.navigateByUrl('communication/communication-library', {replaceUrl : true});

        this.communicationPopup = this.dialog.open(CommunicationsPopupComponent, {
          disableClose: true,
          width: "90%",
          height: "auto",
          data: {
            CommunicationLibraryId: data[0].id,
            isEdit: true,
            fileName: data[0].title,
            isTemplate: true
          }
        });

        this.communicationPopup.beforeClosed().subscribe(result => {
          this.pageNumber = 1;
          this.getCommunicationsForLibraryList();
        });
      }
    }
  }
}
