import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.less']
})
export class PaymentErrorComponent implements OnInit {
  showLoginLink: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params != {} && params["status"] != undefined) {
        this.showLoginLink = false;
      }     
    });
  }

  goBack($event) {
    window.close();
  }
}
