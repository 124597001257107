import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
//import { TransactionsService } from 'src/app/services/transactions.service';
import { CommonService } from "../../services/common.service";
import { GleacColumnDef } from "../data-grid-new-dashboard/data-grid-new-dashboard.model";
import { ConfigsService } from "../../services/configs.service";
import { BadgeComponent } from "../badge/badge.component";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
  FormControl
} from "@angular/forms";
import * as XLSX from 'xlsx';
import { UsersService } from "../../services/users.service";
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { EventsQuery, EventStatus } from "../../models/events-query";
import { EventService } from "../../services/event.service";
import { LessonsService } from "../../services/lessons.service";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-in", style({ transform: "translateX(70%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class EventsComponent implements OnInit {
  page_title = "Events";
  eventQuery = new EventsQuery();
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  today = new Date();
  create_popup = {
    display: false,
    title: "",
    createrName: '',
    isUpdate : false,
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any, data) {
    },
  };
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  error_popup = {};
  filter: string = "ALL";
  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "UPCOMING", value: "1" },
        { name: "PAST", value: "2" },
      ],
    }
  ];
  jobsList: any;
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    {
      data: "title",
      displayName: "Event Name",
      render: (data, row) => {
        if (!row.image) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        if (row.image && !row.image.startsWith("http")) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        return '<div> <img height="30" width="30" style="border-radius: 50%;margin-right: 5px " src="' + row.image + '"  alt="" /> <span style="color:#0065AD">' + row.title + '</span></div>'
      }
    },
    {
      data: "from",
      displayName: "From",
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(row.from)), 'MMM d, y, h:mm a');
      }
    },
    {
      data: "to",
      displayName: "To",
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(row.to)), 'MMM d, y, h:mm a');
      }
    },
    {
      data: "status",
      displayName: "Status",
      disableSort: true,
      render: (data, row) => {
        if (new Date(row.from).getTime() > this.getCurrentUtcTime()) {
          return '<div><span class="dot gleac-badge-text success mr-2"></span><span>' + 'UPCOMING' + '</span></div>';
        } else {
          return '<div><span class="dot gleac-badge-text danger mr-2"></span><span>' + 'PAST' + '</span></div>';
        }
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        },
      ],
    },
  ];
  pageIndex: number = 1;
  pageSize: number = 10;
  searchString = "";
  status = "";
  breadCrumsArray: any = [];
  visible: boolean = false;
  inputData: any;
  enableRow: boolean = true;
  queryParam: any = [];
  query;
  companyId;
  url = "";
  userUrl = "";

  creatFields: any = [
    {
      label: "Event Name",
      key: "eventName",
      placeholder: "Enter the Event name",
      type: "input",
      innertype: "text",
      pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
      required: "required",
      value: "",
      maxlength: 1000,
    },
    {
      label: "Description",
      key: "description",
      placeholder: "Enter the description",
      type: "input",
      innertype: "text",
      value: "",
      maxlength: 1000,
    },
    {
      label: "Image",
      key: "imageFile",
      placeholder:
        "Maximum file size 2mb, and type of file must be PNG or JPG. Minimum resolution is 500 X 500.",
      type: "image_upload",
      required: "required",
      cssClass: 'col-7',
      value: ''
    },
    {
      label: "Registration link",
      key: "registrationLink",
      placeholder: "Enter the Registration link",
      type: "input",
      innertype: "text",
      required: "required",
      value: "",
      maxlength: 3000,
      pattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    },
    {
      label: "Event Recording link",
      key: "eventRecording",
      placeholder: "Enter the Event Recording link",
      type: "input",
      innertype: "text",
      value: "",
      maxlength: 3000,
    }
  ];

  constructor(
    private configsService: ConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public usersService: UsersService,
    private datepipe: DatePipe,
    private eventService: EventService,
  ) {
    // this.getUserList();
  }

  ngOnInit() {
    this.usersService.isDevelop = (localStorage.getItem('develop') == 'true');
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.getEventList();
  }

  getEventList() {
    this.configsService.loading(true);
    this.eventService.getAllEvents(this.eventQuery).subscribe(
      (data) => {
        // console.log(data,this.query);
        if (data.result.results.length <= 0 && data.result.results.totalRecords != 0 && this.eventQuery.pageIndex > 1) {
          this.eventQuery.pageIndex--;
          this.getEventList();
        } else {

          let eventData = {
            list: data.result.results,
            totalCount: data.result.totalCount
          }

          this.transactionsGridData = eventData;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.eventQuery.pageIndex = event;
    this.getEventList();
  }

  filterData(event) {
    this.eventQuery.searchText = event ? event[0] : this.searchString;
    this.eventQuery.pageIndex = 1;
    this.eventQuery.pageSize = 10;
    this.getEventList();
  }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      this.eventQuery.status = data.status == "1" ? EventStatus.UPCOMING : EventStatus.PAST;
    } else {
      this.eventQuery.status = null;
    }
    this.eventQuery.pageIndex = 1;
    this.getEventList();
  }

  sortChange(event) {
    this.eventQuery.sortColumn = event.active;
    this.eventQuery.sortOrder = event.direction;
    this.getEventList();
  }

  changeSearchText(event) {
  }

  editUser(event) {
    if (event.action == "edit") {
      this.createEvent(event.data);
    } else if (event.action == "delete") {
      this.deleteUser(event.data.id);
    }
  }

  createEvent(data) {
    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: false,
      bulkInfo: null,
      createrName: "eventform",
      title: data ? "Edit Event" : "Create Event",
      buttonPositive: data ? "Update" : "Create",
      isUpdate : data ? true : false,
      field: [
        {
          label: "Event Name",
          key: "title",
          placeholder: "Enter the Event name",
          type: "input",
          innertype: "text",
          pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
          required: "required",
          cssClass: 'col-12',
          maxlength: 1000,
          value: data.title ? data.title : '',
        },
        {
          label: "Description",
          key: "description",
          placeholder: "Enter the description",
          type: "textarea",
          cssClass: 'col-12',
          height: "100px",
          maxlength: 1000,
          value: data.description ? data.description : '',
        },
        {
          label: "Image",
          key: "imageFile",
          placeholder:
            "Maximum file size 2mb, and type of file must be PNG or JPG. Minimum resolution is 500 X 500.",
          type: "image_upload",
          required: "required",
          cssClass: 'col-8',
          autoUpload: true,
          height: "400px",
          value: data.image ? data.image : '',
        },
        {
          label: "Start Date",
          key: "from",
          type: "date_time_picker_start",
          value: data.from ? this.convertUTCDateToLocalDate(new Date(data.from)) : new Date(),
          required: "required",
        },
        {
          label: "End Date",
          key: "to",
          type: "date_time_picker_end",
          required: "required",
          value: data.to ? this.convertUTCDateToLocalDate(new Date(data.to)) : new Date(),
        },
        {
          label: "Registration link",
          key: "registrationLink",
          placeholder: "Enter the Registration link",
          type: "input",
          innertype: "text",
          pattern: "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
          required: "required",
          maxlength: 1000,
          value: data.registrationLink ? data.registrationLink : '',
        },
        {
          label: "Event Recording link",
          key: "mediaLink",
          maxlength: 1000,
          placeholder: "Enter the Event Recording link",
          type: "input",
          innertype: "text",
          pattern: "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
          value: data.mediaLink ? data.mediaLink : '',
          disabled: data ? new Date(data.to).getTime() > this.getCurrentUtcTime() : true,
        },
        {
          label: "Total Users Registered",
          key: "totalUsersRegistered",
          placeholder: "Enter the Total Users Registered",
          type: "input",
          innertype: "number",
          min: 0,
          max: 6,
          pattern: "^[0-9]{1,7}$",
          value: data ? data.totalUsersRegistered : 0,
          errorMsg: "Enter valid number below 1000000"
        },
        {
          label: "Total Users Attended",
          key: "totalUsersAttended",
          placeholder: "Enter the Total Users Attended",
          type: "input",
          innertype: "number",
          min: 0,
          max: 6,
          pattern: "^[0-9]{1,7}$",
          value: data ? data.totalUsersAttended : 0,
          errorMsg: "Enter valid number below 1000000",
          disabled: data ? new Date(data.to).getTime() > this.getCurrentUtcTime() : true,
        },
        {
          label: "Speakers",
          key: "speakerDetails",
          cssClass: 'col-12',
          type: "speaker_details",
          value: data && data.speakerDetails ? data.speakerDetails : null
        },
      ],
      refreshData: () => {
        this.getEventList();
      },
      closeModal: () => {
        this.create_popup.display = false;
        this.iscreatePopup = false;
      },
      createrCall: (form: NgForm, responseData) => {
        if (form.valid) {
          let ListData: any = [];
          ListData = { ...form.value };

          let request: any = {};
          request.id = data && data.id ? data.id : 0;
          request.title = ListData.title;
          request.description = ListData.description;
          request.image = ListData.image;
          request.to = ListData.endDate1;
          request.from = ListData.startDate1;
          request.registrationLink = ListData.registrationLink;
          request.mediaLink = ListData.mediaLink;
          request.totalUsersRegistered = ListData.totalUsersRegistered ? ListData.totalUsersRegistered : 0;
          request.totalUsersAttended = ListData.totalUsersAttended ? ListData.totalUsersAttended : 0;
          request.speakerDetails = JSON.stringify(responseData);

          this.configsService.loading(true);

          this.eventService
            .addOrUpdateEvent(request)
            .subscribe(
              (data) => {
                this.postStatus(data);
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
              }
            );


          // this.create_popup.display = false;
          // this.configsService.loading(true);
          // let ListData = [];
          // ListData = {...form.value};
          // if (data) {
          //   let groups = [];
          //   if (form.value.groupId) {
          //     form.value.groupId.map((f, i) => {
          //       if (f.id) {
          //         groups.push(f.id);
          //       } else if (f.groupId) {
          //         groups.push(f.groupId);
          //       } else {
          //         groups.push(f);
          //       }
          //     });
          //   }
          //   ListData["groupId"] = groups;
          //
          //   ListData["companyId"] = ListData["companyId"] ? ListData["companyId"].id : null;
          //   ListData["userId"] = form.value.userId;
          //
          //   this.commonService
          //     .updateDataV2("Users", ListData)
          //     .subscribe(
          //       (data) => {
          //         this.postStatus(data);
          //       },
          //       (err) => {
          //         this.configsService.loading(false);
          //         this.iserrorPopup = true;
          //         this.error_popup = {
          //           display: true,
          //           message: err.error.errors[0],
          //         };
          //       },
          //       () => {
          //         this.configsService.loading(false);
          //         form.reset();
          //         this.iscreatePopup = false;
          //       }
          //     );
          // }
          // else {
          //   let listdata = [];
          //   listdata.push(ListData);
          //
          //   if (listdata[0].companyId) {
          //     listdata[0].companyId = listdata[0].companyId.id;
          //   }
          //
          //   this.commonService
          //     .createDataV2("Users", listdata[0])
          //     .subscribe(
          //       (data) => {
          //         this.postStatus(data);
          //       },
          //       (err) => {
          //         this.configsService.loading(false);
          //         this.iserrorPopup = true;
          //         this.error_popup = {
          //           display: true,
          //           message: err.error.errors[0],
          //         };
          //       },
          //       () => {
          //         this.configsService.loading(false);
          //         form.reset();
          //         this.iscreatePopup = false;
          //       }
          //     );
          // }
        }
      },
    };
  }

  toggleSlide(e) {
    this.visible = e;
  }

  showDetails(event) {
    if (this.enableRow) {
      if (this.visible) {
        this.toggleSlide(false);
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      } else {
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      }
    } else {
      this.enableRow = true;
    }
  }

  /** Delete User
   -- @params Transaction ID --
   **/

  deleteUser(uId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        this.eventService.deleteEvent(uId).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
   **/

  postStatus(data) {
    if (!data.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: data.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.eventQuery.pageIndex = 1;
          this.getEventList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.eventService
      .exportEvents(this.eventQuery)
      .subscribe(
        data => saveAs(data, "Events" + '.xlsx'),
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  getCurrentUtcTime() {
    return new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime()
  }

  convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }
}
