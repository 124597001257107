import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {

  @Input('search') searchKey;
  @Input('searchPlaceholder') searchplaceHolder;
  @Output('changeSearchText') changeSearchText = new EventEmitter();
  @Output('onSearch') onSearch = new EventEmitter();
  @Input("placeholder") placeholder;

  constructor() {

  }


  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder = "Search by name";
    }
  }

  startSearch(value) {
    if (value.length > 2) {
      this.onSearch.emit(arguments);
    }
    if (!value) {
      this.onSearch.emit(arguments);
    }
  }
}
