import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild
} from "@angular/core";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {ConfigsService} from "../../services/configs.service";
import {GleacColumnDef} from "../data-grid/data-grid.model";
import {CommonService} from "../../services/common.service";

import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import {formatDate} from "@angular/common";
import {Subject} from "rxjs";
import {UsersService} from "../../services/users.service";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.less"],
})
export class GroupsComponent implements OnInit {
  page_title = "Groups";
  groupDetailsTitle = "Add Group";
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @ViewChild('editGroupUser', {static: false}) paginator: any;

  groupData: any = {};
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  addGroupFlag: boolean = false;
  errorMessage = '';
  users = [];
  newUsers = [];
  updateUsers = [];
  assignUsers = {};
  pageLimit: number = 10;
  pageNumber = 1;
  isDisplay = false;

  create_popup = {
    display: false,
    title: "",
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    enable_email: false,
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    },
  };
  error_popup = {};
  breadCrumsArray: any = [];
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  filterArray: any = [
    {
      label: "Status ",
      type: "static_select",
      key: "status",
      options: [
        {name: "Active", value: "Active"},
        {name: "Inactive", value: "Deactive"},
      ],
    },
  ];
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  groupsGridData: any;
  groupsColData: GleacColumnDef[] = [
    {displayName: "Group Name", data: "groupName"},
    {displayName: "Total Participants", data: "totalParticipants"},
    {
      data: "isActive",
      displayName: "Status",
      disableSort: true,
      isExpandable: true,
      render: (data, row) => {
        if (row.isActive === true) {
          return '<div><span class="dot gleac-badge-text success mr-2"></span><span>Active</span></div>';
        } else if (row.isActive === false) {
          return '<div><span class="dot gleac-badge-text danger mr-2"></span><span>InActive</span></div>';
        }
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        }
      ],
    },
  ];
  url = "";
  searchString = "";
  searchStringEdit = "";
  pageIndex: number = 1;
  pageSize: number = 10;
  form;
  queryParam: any = [];
  query;
  groupUrl = "";
  companyId = "";
  companyIdFromRoute = "";
  groupId = "";
  totalCount: any;

  companyDetails = {
    label: "",
    key: "company",
    placeholder: "Select the company",
    type: "dropdown",
    bindLabel: "name",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "Partner",
  }

  status = {
    label: "Status",
    key: "Is_Active",
    required: "required",
    placeholder: "0",
    cssClass: 'col-6',
    value: true,
    type: "button_select",
    options: [
      {label: "Active", value: true},
      {label: "Inactive", value: false},
    ]
  }

  constructor(
    private configsService: ConfigsService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public usersService: UsersService,
  ) {
  }

  ngOnInit() {

    this.usersService.isUsers = true;

    this.breadCrumsArray = this.configsService.getBreadcrumb();

    this.route.params.subscribe((params) => {
      this.companyIdFromRoute = params["companyId"];
      if (this.companyIdFromRoute) {
        this.queryParam["companyId"] = this.companyIdFromRoute;
      }
    });

    this.queryParam["pageIndex"] = this.pageIndex;
    this.queryParam["pageSize"] = this.pageSize;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupList();
  }

  getGroupList() {
    this.configsService.loading(true);
    this.url = "Groups/";
    this.groupUrl = "Groups/";
    this.commonService.getListDataGridForGroups(this.url, this.query).subscribe(
      (data) => {
        if (data.result.list.length <= 0 && data.result.list.totalCount != 0 && this.queryParam["pageIndex"] > 1) {
          this.queryParam["pageIndex"] = --this.queryParam["pageIndex"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getGroupList();
        } else {
          this.groupsGridData = data.result;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        this.queryParam[key] = data[key];
      }
    } else {
      delete this.queryParam.status;
    }
    this.queryParam['pageIndex'] = 1;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Groups/search/" : "Groups/";
    this.configsService.loading(true);
    this.commonService.getListDataGridForGroups(this.url, this.query).subscribe(
      (data) => {
        this.groupsGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["pageIndex"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupList();
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.queryParam["pageIndex"] = 1;
    this.queryParam["searchText"] = this.searchString;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Groups/search/" : "Groups/";
    this.configsService.loading(true);
    this.commonService.getListDataGridForGroups(this.url, this.query).subscribe(
      (data) => {
        this.groupsGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  changeSearchText(event) {
  }

  innerGroups(event) {
    let breadcrumb = {
      pathName: "Groups",
      pathUrl: this.router.url,
    };
    this.breadCrumsArray.push(breadcrumb);
    this.queryParam = {};

    if (!this.companyIdFromRoute) {
      this.router.navigate([
        "groups/inner/" +
        event.data.groupId +
        "/" +
        event.data.groupName,
      ]);
    } else {
      this.router.navigate([
        "companies/groups/" + this.companyIdFromRoute + "/" + event.data.groupId
      ]);
    }
  }

  editGroups(event) {
    if (event.action == "edit") {
      this.editGroup(event);
    } else if (event.action == "delete") {
      this.deleteGroup(event.data.groupId);
    }
  }

  createGroupV2() {
    this.addGroupFlag = true;
  }

  editGroup(event) {
    this.isDisplay = true;
    this.groupDetailsTitle = "Edit Group";
    this.groupData.groupName = event.data.groupName;
    this.groupData.isActive = event.data.isActive;
    this.groupId = event.data.groupId;
    this.companyId = '';
    this.pageNumber = 1;
    this.newUsers = [];
    this.updateUsers = [];
    this.errorMessage = '';
    this.searchString = '';
    this.searchStringEdit = '';

    this.getCompanyData();
    this.fetchGroupUsers();
  }

  deleteGroup(gId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Groups?groupId=" + gId;
        this.commonService.deleteDataV2(url).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
   **/

  postStatus(result) {
    if (!result.value.hasError) {
      this.isDisplay = false;
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.queryParam["pageIndex"] = 1;
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getGroupList();
        },
      };
    } else {
      this.error_popup = {display: true, message: result.value.message};
      this.iserrorPopup = true;
    }
  }

  userClose() {
    this.searchString = '';
    this.addGroupFlag = false;
    this.queryParam["pageIndex"] = 1;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupList();
  }

  getGroupById(groupId) {
    this.configsService.loading(true);
  }

  getCompanyData() {
    this.configsService.loading(false);
    this.commonService.getCompanyData(this.companyDetails.apiUrl).subscribe((res) => {
        let context = this;
        context.companyDetails.options = [];
        context.companyDetails.value = [];
        res.result.forEach(function (item) {
          context.companyDetails.options.push({name: item.companyName, value: item.companyId});
        });
      },
      (err) => {
        console.log(err);
      });
  }


  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d === user.userId);

    if (index < 0) {
      let userIndex = this.users.findIndex(d => d.userId === user.userId);
      if (userIndex >= 0 && !this.users[userIndex].isChecked) {
        this.updateUsers.push(user.userId);
      } else {
        this.newUsers.push(user.userId);
      }
    }
  }


  fetchGroupUsers() {
    this.configsService.loading(true);
    this.url = "Groups/AllUsers";
    let query = "pageIndex=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchStringEdit;

    this.commonService.getListDataGridV2(this.url, query).subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.list.length > 0) {
            this.users = data.result.list;

            let context = this;
            this.users.forEach(function (item) {
              if (item.groupIds && item.groupIds.length > 0) {
                item.isChecked = item.groupIds.includes(context.groupId);
              }
            });

            this.users.forEach(function (item) {
              if (!item.isChecked) {
                item.isChecked = context.newUsers.includes(item.userId);
              }
            });

            this.totalCount = data.result.totalCount;
          } else {
            this.users = [];
            this.totalCount = 0;
          }
          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  closeModel(ent) {
    this.searchString = '';
    this.isDisplay = false;
  }

  updateGroup() {
    if (this.groupData.groupName) {
      this.groupData.groupName = this.groupData.groupName.trim();
    }
    if (!this.groupData.groupName) {
      this.errorMessage = 'Group Name is required.';
      return;
    } else if (!/^[a-zA-Z ]*$/.test(this.groupData.groupName.trim())) {
      this.errorMessage = 'Group Name is invalid.';
      return;
    }
    this.groupUrl = "Groups/"
    this.groupData.users = this.newUsers;
    this.groupData.groupId = this.groupId;
    this.groupData.users = this.updateUsers;
    this.groupData.newUsers = this.newUsers;

    this.commonService.updateDataV2(this.groupUrl, this.groupData).subscribe(
      (data) => {
        this.postStatus(data);
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {
          display: true,
          message: err.error.errors[0]
        };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.fetchGroupUsers();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  customFilterData(params) {
    this.searchStringEdit = params[0];
    //this.pageNumber = 1;
    this.paginator.changePage(0);
    //this.fetchGroupUsers();
  }

  onGroupNameChange() {
    this.errorMessage = '';
  }
}
