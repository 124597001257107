import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {LessonsService} from "src/app/services/lessons.service";
import {ConfigsService} from 'src/app/services/configs.service';
import {SkillTags, SkillType} from '../common/common.model';
import {NgForm} from '@angular/forms';
import {CommonService} from 'src/app/services/common.service';
import {from} from 'rxjs';
import {appSettings} from 'src/environments/environment';
import {Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {RequestsService} from 'src/app/services/requests.service';
import {saveAs} from 'file-saver';
import {MentorService} from "../../services/mentor.service";

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.less']
})
export class RequestsComponent implements OnInit {

  pageUrl: string = "lessons";
  page_title = "Requests";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  error_popup = {};
  url = "";
  remarks: any;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  requestsGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  invalidRemark: boolean = false;
  status = "";
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        {name: "Approved", value: "1"},
        {name: "Decline ", value: "2"},
        {name: "Hold ", value: "3"},
      ],
    }
  ];
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    cssClass: '',
    createrName: '',
    buttonPositive: "",
    field: [],
    closeModal: function () {
    },
    createrCall: function (form, skillData, uploadFileName) {
    },
  };
  imageStorageUrl: string = appSettings.imageStoreUrl;
  currentTime: Date;
  skillTypeOptions = [];
  skillTagsOptions = [];
  specializationOptions = [];
  isDecline: boolean;
  requestPopupTilte: string;
  requestAction: any;
  requestUserId: any;
  requestId : any;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  lessonReportData;
  displayLessonReport = false;
  asDataMentor = false;

  constructor(
    private lessonsService: LessonsService,
    private configsService: ConfigsService,
    private mentorService: MentorService,
    protected router: Router,
    private commonService: CommonService,
    private usersService: UsersService,
    private requestService: RequestsService
  ) {
  }

  ngOnInit() {
    this.usersService.isMentor = true;
    this.getSpecializationsList();
  }

  requestsColumnData: GleacColumnDef[] = [
    {
      displayName: "User Name",
      data: "username"
    },
    {
      displayName: "Email",
      data: "email"
    },
    {
      displayName: "Phone",
      data: "phone"
    },
    {
      displayName: "LinkedIn Url",
      data: "linkedIn"
    },
    {
      displayName: "Company",
      data: "company"
    },
    {
      displayName: "Mentoring Topics",
      data: "MentoringTopics",
      render: (data, row) => {
        let spe = [];

        if (row.specializations) {
          row.specializations.forEach(item => {
            let speDetails = this.specializationOptions.filter(x => x.value == item.specializationId);

            if (speDetails && speDetails.length > 0) {
              spe.push(speDetails[0].name);
            }
          });

          return spe.join(", ");
        } else {
          return "";
        }
      },
    },
    {
      displayName: "Remarks",
      data: "remarks"
    },
    {
      data: "status",
      displayName: "Status",
      disableSort: true,
      render: (data, row) => {
        if (row.status === 1) {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span>Approve</span></div>'
          );
        } else if (row.status === 2) {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span> Decline</span></div>'
          );
        } else if (row.status === 3) {
          return (
            '<div><span class="dot gleac-badge-text hold mr-2"></span><span> Hold</span></div>'
          );
        } else {
          return (
            '<div><span class="dot gleac-badge-text ongoing mr-2"></span><span> Pending</span></div>'
          );
        }
      },
    },
    {
      displayName: "Date",
      data: "createdOn",
      isDate: true
    },
    {
      displayName: "Lessons",
      data: "lessonTitles",
      isMRLessonsAction: true,
      disableSort: true,
      render: (data, row) => {
        let lesson = (row.lessonTitles && row.lessonTitles.length > 0) ? row.lessonTitles[0] : ''
        return `
        <span class="float-left" style="height : 17px;width:70px; overflow: hidden" >${lesson}</span>`;
      },
    },
    {
      displayName: "Lesson Report",
      data: "answers",
      isClickable: true,
      actionName: "LessonReport",
      render: (data, row) => {
        return `<span   style="color:#0065AD">Lesson Report</span>`
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      cssClass: true,
      disableSort: true,
      dropDownLabels: [
        // {
        //   label: "Approved",
        //   url: "#",
        //   functionType: "approve",
        //   image: "assets/images/approve.png",
        // },
        {
          label: "As Data Mentor",
          url: "#",
          functionType: "approveDataMentor",
          image: "assets/images/approve.png",
        },
        {
          label: "Decline",
          url: "#",
          functionType: "decline",
          image: "assets/images/decline.png",
        }
      ],
    },
  ];

  reArrangeChallenge(requests) {
    let newRequests = requests.map((item, key) => {
      return {
        userId: item.gleacUser.userId,
        userName: item.gleacUser.firstName + " " + item.gleacUser.lastName,
        email: item.gleacUser.email,
        linkedinUrl: item.mentorsRequest.linkedIn,
        remarks: item.mentorsRequest.remarks,
        status: item.mentorsRequest.status,
        date: item.mentorsRequest.createdOn
      }
    })
    return newRequests;
  }

  getSpecializationsList() {
    this.mentorService.getSpecializationsList().subscribe((Specializations) => {
      Specializations.result.forEach(Specialization => {
        this.specializationOptions.push({
          value: Specialization.id,
          name: Specialization.title
        });
      });

      this.getRequestList();
    }, (err) => {

    });
  }

  getRequestList() {
    this.configsService.loading(true);
    this.requestService.getRequestList(
      this.pageNumber,
      this.status,
      encodeURIComponent(this.searchString)
    ).subscribe(
      (res) => {
        if (res) {
          if (res) {
            let requestData = res.result.data;
            let newRequestData = {
              list: requestData,
              totalCount: res.result.totalCount
            }
            this.requestsGridData = newRequestData;
          } else {
            this.requestsGridData = [];
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }


  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getRequestList();
  }

  customFilter(params) {
    let queryParam;
    let query;
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        queryParam = data[key];
      }
    } else {
      queryParam = params[0];
    }
    this.status = queryParam;
    this.pageNumber = 1;
    this.getRequestList();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getRequestList();
  }

  actionClick(event) {
    console.log(event);
    if (event.action === "edit") {
      this.addSkill(event.data);
    } else if (event.actionName === "LessonReport") {
      this.configsService.loading(true);
      this.lessonsService.getMentorRequestLessonResults(event.data.gleacUserId, event.data.lessons).subscribe(data => {
        this.lessonReportData = data.result;
        this.displayLessonReport = true;
        this.requestId = event.data.mentorRequestId;
      }, () => {
        this.configsService.loading(false);
      }, () => {
        this.configsService.loading(false);
      });

    } else if (event.action === "decline" || event.action === "approve" || event.action === "approveDataMentor" || event.action === "hold") {
      this.isDecline = true;
      this.requestAction = event.action == "approveDataMentor" ? "approve" : event.action;
      this.asDataMentor = event.action == "approveDataMentor";
      this.requestUserId = event.data.mentorRequestId;
      this.requestPopupTilte = event.action == "approveDataMentor" ? "Approve Request As DataMentor" : event.action.charAt(0).toUpperCase() + event.action.slice(1) + " Request";
    }
  }

  sendRemainder() {
    this.configsService.loading(true);
    this.mentorService.sendRemainder(this.requestId).subscribe(data => {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: "Reminder Sent Successfully.",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
        },
      };
    }, () => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  deletelessonCategory(data) {
    console.log("data", data);
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Lesson",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.lessonsService.deletelessonCategory(data.lessonCategoryId).subscribe(
          (data) => {
            // this.postStatus(data);
            this.getRequestList();
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true, message: err.error.errors[0]};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
        // this.configService.invokeDataPassing(this.list);
      },
    };
  }

  requestDetails(event) {
    console.log("event", event);
    let route = "lessons/" +
      event.data.lessonCategoryId +
      "/new";
    if (event.data.skillType === 4) {
      route = "challenge/" +
        event.data.lessonCategoryId + "/new";
    }
    this.router.navigate([route]);
  }

  setSkillType() {
    for (var item in SkillType) {
      if (isNaN(Number(item))) {
        this.skillTypeOptions.push({
          value: SkillType[item],
          name: item
        });
      }
    }
  }

  setTags() {
    for (var item in SkillTags) {
      if (isNaN(Number(item))) {
        this.skillTagsOptions.push({
          value: SkillTags[item],
          name: SkillTags[item]
        });
      }
    }
  }

  addSkill(data) {

    let skillType = {};
    let tagsFromBackend = [];


    this.skillTagsOptions.forEach(item => {
      item.isChecked = false;
    });

    this.skillTypeOptions.forEach(item => {
      item.isChecked = false;
    });

    if (data) {
      this.skillTypeOptions.forEach(item => {
        if (item.value === data.skillType) {
          item.isChecked = true;
        }
      });

      skillType = {isChecked: true, name: SkillType[data.skillType], value: data.skillType};
    }

    if (data) {
      if (data.tags) {
        let tagString = String(data.tags);
        if (tagString) {
          let tagsSplit = tagString.split(",");
          tagsFromBackend = [];
          tagsSplit.forEach(tag => {

            tagsFromBackend.push({isChecked: true, name: tag.trim(), value: tag.trim()});

            this.skillTagsOptions.forEach(item => {
              if (item.value.trim() === tag.trim()) {
                item.isChecked = true;
              }
            });
          });
        }
      }
    }

    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: false,
      cssClass: 'col-12',
      createrName: "CreateSkillForm",
      title: data ? "Edit Skill" : "Create Skill",
      buttonPositive: data ? "Update" : "Create",
      field: [
        {
          label: "Skill Name",
          key: "lessonCategoryName",
          placeholder: "Enter the category name",
          type: "input",
          cssClass: 'col-6',
          innertype: "text",
          required: "required",
          value: data ? data.lessonCategoryName : "",
        },
        {
          label: "Skill Image",
          key: "imageFile",
          placeholder:
            "Maximum file size 2mb, and type of file must be PNG or JPG. Minimum resolution is 500 X 500.",
          type: "image_upload",
          required: "required",
          cssClass: 'col-6',
          value: data ? data.imageSrc : ''

        },
        {
          label: "Skill Summary",
          key: "skillSummary",
          placeholder: "Type Summary",
          type: "textarea",
          innertype: "text",
          cssClass: 'col-6 setPosition',
          height: "142px",
          required: "required",
          value: data ? data.skillSummary : "",
        },

        {
          label: "Skill Type",
          key: "SkillType",
          placeholder: "Select Skill",
          type: "dropdown",
          bindLabel: "name",
          multiple: false,
          cssClass: 'col-6',
          value: data ? skillType : {name: "GENERIC", value: 0, isChecked: true},
          required: "required",
          options: this.skillTypeOptions,
          apiUrl: "",
        },

        {
          label: "Lesson per day",
          key: "PerDayLessons",
          placeholder: "0",
          type: "input",
          cssClass: 'col-3',
          innertype: "number",
          required: "required",
          value: data.perDayLessons ? data.perDayLessons : ''
        },
        {
          label: "Status",
          key: "IsActive",
          required: "required",
          placeholder: "0",
          cssClass: 'col-3',
          value: data.isActive ? data.isActive : false,
          type: "switchButton",
        },
        {
          label: "Tags",
          key: "Tags",
          placeholder: "Type your keywords",
          type: "dropdown",
          bindLabel: "name",
          required: "required",
          multiple: true,
          cssClass: 'col-12',
          value: tagsFromBackend,
          options: this.skillTagsOptions,
          apiUrl: "",
        },
      ],
      closeModal: () => {
        this.iscreatePopup = false;
      },
      createrCall: (form: any, skillData, uploadedFileName) => {
        let lessonCategory = Object.assign(form.value);
        lessonCategory.SkillType = form.value.SkillType.value;
        lessonCategory.companyId = "6C6CB0C8-4A3D-48A9-9049-71867E26B6DA";
        lessonCategory.Tags = form.value.Tags.map((item) => {
          return item.value;
        });

        if (!lessonCategory.imageFile.name) {
          lessonCategory.imageSrc = data.imageSrc;
          lessonCategory.imageFile = null;
        } else {
          lessonCategory.imageSrc = this.imageStorageUrl + lessonCategory.imageFile.name;
        }

        lessonCategory.lessonCategoryId = data ? data.lessonCategoryId : "";

        if (form.valid) {

          if (data) {
            this.configsService.loading(false);
            this.lessonsService.updateSkillDetails(lessonCategory).subscribe(
              (data) => {
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
                this.getRequestList();
              }
            );

          } else {

            this.configsService.loading(false);
            this.lessonsService.addLessonCategory(lessonCategory).subscribe(
              (data) => {
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
                this.getRequestList();
              }
            );
          }
        }
      },
    };
  }

  closeModel() {
    this.isDecline = false;
  }

  closeLessonReportModel() {
    this.displayLessonReport = false;
    this.requestId = 0;
  }


  exportToExcel() {
    this.configsService.loading(true);
    this.requestService.exportAllMentorRequests(
      encodeURIComponent(this.searchString), this.status
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "MentorRequests" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  isSave() {
    if (!this.remarks) {
      this.invalidRemark = true;
      return;
    }
    let action;
    if (this.requestAction === 'approve') {
      action = 1;
    } else if (this.requestAction === 'decline') {
      action = 2;
    } else if (this.requestAction === 'hold') {
      action = 3;
    }
    this.invalidRemark = false;
    let remark = {
      status: action,
      mentorRequestId: this.requestUserId,
      remarks: this.remarks,
      asDataMentor : this.asDataMentor
    }
    this.configsService.loading(true);
    this.requestService.sendRemark(
      remark
    ).subscribe(
      (res) => {
        this.getRequestList();
        this.closeModel();
        this.remarks = "";
        this.issuccessPopup = true;
        this.success_popup = {
          display: true,
          message: this.requestPopupTilte + "  Successfully.",
          buttonPositive: "Okay",
          positiveButton: function () {
            this.issuccessPopup = false;
            this.display = false;
          },
        };
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}

