import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  baseUrl: string = appSettings.dashboardUrl;

  constructor(private http: HttpClient) {}

  getCandidateLessonsForDashboard(candidateId: string) {
    let url: string = this.baseUrl + "candidate/" + candidateId;
    return this.http.get<any>(url);
  }

  getCandidateLesson(candidateId: string, lessonSkillId: string) {
    let url: string = this.baseUrl + "candidate/" + candidateId + "/" + lessonSkillId;
    return this.http.get<any>(url);
  }
}
