import { Component, OnInit ,Input} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.less']
})
export class SubmenuComponent implements OnInit {

  isCompany : boolean = false;
  @Input('urlId') id: any; 
  pageUrl=''; 
  constructor(public usersService: UsersService, private router : Router){}

  ngOnInit() {
    this.pageUrl = this.id;
  }

}
