import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lesson-campaign-new-dashboard',
  templateUrl: './lesson-campaign-new-dashboard.component.html',
  styleUrls: ['./lesson-campaign-new-dashboard.component.less']
})
export class LessonCampaignNewDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
