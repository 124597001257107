import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Login,
  UserRegistration,
  UserPasswordReset,
  SocialGleacUser,
  UserVerification
} from '../components/login/login.model';
import { appSettings } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  currentUser: {
    authRole: string;
    email: string;
    userId: string;
    company?: string,
    permissions?: string[],
    firstName?: string,
    lastName?: string
  }

  constructor(private http: HttpClient) {
  }

  baseUrl = appSettings.authUrl;
  isAdmin: boolean = false;
  isAdminDashboard: boolean = false;
  isMeasure: boolean = false;
  isDevelop: boolean = false;
  isMentor: boolean = false;
  isUsers: boolean = false;
  isCommunication: boolean = false;

  isAllowed(permission: string) {
    if (!permission) {
      return true;
    }

    if (this.currentUser.permissions && this.currentUser.permissions.length > 0 && this.currentUser.permissions.includes(permission)) {
      return true;
    }
    return false;
  }

  userLogin(userDetails: Login) {
    return this.http.post<any>(this.baseUrl + "login", userDetails).pipe(map(data => {
      this.startRefreshTokenTimer();
      return data;
    }));
  }

  requestOtp(email: string) {
    return this.http.post<any>(this.baseUrl + "Login/RequestEmailLoginCode", { email: email }).pipe(map(data => {
      return data;
    }));
  }

  otpLogin(email: string, otp: string) {
    return this.http.post<any>(this.baseUrl + `Login/EmailOtpLogin/${email}/${otp}`, null).pipe(map(data => {
      this.startRefreshTokenTimer();
      return data;
    }));
  }

  refreshToken() {
    let refreshToken = {
      token: localStorage.getItem("GRTk")
    }
    var response = this.http.post<any>(this.baseUrl + "login/refresh", refreshToken);
    return response;
  }

  registerUser(newUser: UserRegistration) {
    var url = this.baseUrl + "users";
    var response = this.http.post<any>(url, newUser);
    return response;
  }

  emailAvailable(email: string) {
    var url = this.baseUrl + "users/check";
    var response = this.http.post<any>(url, { email: email }, { headers: new HttpHeaders().set('Content-Type', 'application/json') });
    return response;
  }

  sendForgotPasswordEmail(email: string) {
    var url = this.baseUrl + "users/password/forgot/" + email;
    var response = this.http.get<any>(url);
    return response;
  }

  confirmForgotPasswordKey(key: string) {
    var url = this.baseUrl + "users/password/confirm";
    var response = this.http.post<any>(url, { key });
    return response;
  }

  resetPassword(user: UserPasswordReset) {
    var url = this.baseUrl + "users/password/reset";
    var response = this.http.post<any>(url, user)
    return response;
  }

  acceptPrivacyPolicy(email: string) {
    var url = this.baseUrl + "login/policy/" + email;
    var response = this.http.get<any>(url);
    return response;
  }

  confirmAccountVerificationKey(key: string) {
    var url = this.baseUrl + "users/account/verify";
    var response = this.http.post<any>(url, { key });
    return response;
  }

  getTokenForPreAuth(key: string) {
    var url = this.baseUrl + "login/company";
    var response = this.http.post<any>(url, { key });
    return response;
  }

  getUserDetails(userId: string) {
    var url = this.baseUrl + "users/" + userId;
    var response = this.http.get<any>(url);
    return response;
  }

  joinTribe(email: string) {
    var url = this.baseUrl + "users/join/" + email;
    var response = this.http.get<any>(url);
    return response;
  }

  authorizeSocialUser(socialUser: SocialGleacUser) {
    var url = this.baseUrl + "login/social";
    var response = this.http.post<any>(url, socialUser);
    return response;
  }

  verifyUser(verification: UserVerification) {
    var url = this.baseUrl + "users/account/verify";
    var response = this.http.post<any>(url, verification);
    return response;
  }

  resendVerificationCode(email: string) {
    var url = this.baseUrl + "users/verify/resend/" + email;
    var response = this.http.get<any>(url);
    return response;
  }

  getRolesForUsers(
    role: string,
    pageLimit?: number,
    pageNumber?: number,
    filterString = "") {
    let url = this.baseUrl + "users/GetUsersByRole?roleId=" + role +
      "&filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageLimit;
    return this.http.get<any>(url);
  }

  getIpAddress() {
    var url = "https://freegeoip.app/json/";
    var response = $.getJSON(url);
    return response;
  }

  changeRole(userId, roleId) {
    let url = this.baseUrl + "users/ChangeRole/" + userId +
      "/" + roleId;
    return this.http.put<any>(url, null);
  }

  private refreshTokenTimeout;

  startRefreshTokenTimer() {
    this.refreshTokenTimeout = setInterval(
      () => this.refreshToken().subscribe(data => {
        if (!data.hasError) {
          localStorage.setItem("GATk", data.result.accessToken);
          localStorage.setItem("GRTk", data.result.refreshToken);
          let token = localStorage.getItem("GATk");
          let jwtData = token.split(".")[1];
          let decodedJwtJsonData = window.atob(jwtData);
          let decodedJwtData = JSON.parse(decodedJwtJsonData);
          let decodedJwtDataExpiration = new Date(decodedJwtData.exp * 1000);
          if (decodedJwtDataExpiration.getTime() >= Date.now()) {
            this.currentUser = {
              authRole: decodedJwtData.auth_role,
              email: decodedJwtData.email,
              userId: decodedJwtData.sub,
              permissions: decodedJwtData.permissions
            };
            this.isAdmin = true;
            if (decodedJwtData.company) this.currentUser.company = decodedJwtData.company;
          }
        }
      }),
      30 * 60000);
  }

  stopRefreshTokenTimer() {
    clearInterval(this.refreshTokenTimeout);
  }

  getUsers(filterString: string, pageSize?: number, pageNumber?: number) {
    var url = this.baseUrl + "Users?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    var response = this.http.get<any>(url);
    return response;
  }

  submitHubSpotForm(formId: string, payload: any) {
    var url = this.baseUrl + "HubSpot/submitForm/" + formId;
    var response = this.http.post<any>(url, payload);
    return response;
  }

  registerUserForWhatsAppLessons(payload: any) {
    var url = this.baseUrl + "Users/RegisterUserForWhatsAppLessons";
    var response = this.http.post<any>(url, payload);
    return response;
  }

  checkIfUserIsRegisteredForWhatsAppLessons(userId: string) {
    var url = this.baseUrl + "Users/CheckUserForWhatsAppLessons?userId=" + userId;
    var response = this.http.post<any>(url, userId);
    console.log("response", response.toPromise());
    return response;
  }
}
