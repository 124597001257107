import { Component, OnInit, Input, Output, ViewChild, ElementRef } from "@angular/core";
import {
  Lesson,
  Section,
  SectionElement,
  LessonReviewQuestion,
  QuestionType,
  SkillType
} from "../common/common.model";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from "@angular/forms";
import { LessonsService } from "src/app/services/lessons.service";
import { LessonSkill } from "../lessons/lessons.model";
import { SectionControlTypes, SectionTagTypes } from "../common/common.constant";
import { SectionElementType } from "../common/common.model";
import { EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appSettings } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationComponent } from 'src/app/dialogs/delete-confirmation/delete-confirmation.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigsService } from '../../services/configs.service';
import { InvitePopupComponent } from "src/app/dialogs/invite-popup/invite-popup.component";

declare var jQuery: any;
@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.less']
})
export class ChallengeComponent implements OnInit {
  allSectionControlTypes = JSON.parse(JSON.stringify(SectionControlTypes));
  allSectionTagTypes = JSON.parse(JSON.stringify(SectionTagTypes));
  success_popup =
    {
      display: false, message: "", buttonPositive: "", positiveButton: function () { },
    };
  @Input() lessonDetails:any;
  imageStorageUrl: string = appSettings.imageStoreUrl;
  lessonFormErrors: string[] = [];
  @ViewChild("lessonEditTab", { static: false }) lessonEditTab: ElementRef;
  sectionFormErrors: string[] = [];
  lessonForm: FormGroup;
  lessonSections: Section[] =[];
  lessonCategoryNames: { categoryName: string; categoryId: string }[] = [];
  sectionForm: FormGroup;
  sectionControlsTypes = JSON.parse(JSON.stringify(this.allSectionControlTypes));
  sectionTagsTypes = JSON.parse(JSON.stringify(this.allSectionTagTypes));
  selectedControlType: FormControl = new FormControl();
  controlForm: FormGroup;
  newSectionControls: FormArray = new FormArray([], Validators.minLength(1));
  selfReviewForm: FormArray = new FormArray([]);
  sectionToEdit: Section;
  levelNumber: 1;
  sectionEditForm: FormGroup;
  mentorListAnswer = [];
  selectedMentor = [];
  questionType : any;
  issuccessPopup: boolean = false;
  sectionDeleteDialog: MatDialogRef<DeleteConfirmationComponent>;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() done: EventEmitter<any> = new EventEmitter();
  @Output() previewChange: EventEmitter<any> = new EventEmitter();
  imageUploadArray: File[] = [];
  newControl;
  inputMentorAnswer = "";
  importLessonFlag: boolean = false;
  techAnswer = '';
  mentorSearch: string = "";
  TechnialAnswer = [
    { SectionName: 'Section 1', SectionAnswer: ["here answer1", "here answer2"] },
    { SectionName: 'Section 2', SectionAnswer: [] },
    { SectionName: 'Section 3', SectionAnswer: [] },
    { SectionName: 'Section 4', SectionAnswer: [] },
    { SectionName: 'Section 5', SectionAnswer: [] },
    { SectionName: 'Section 6', SectionAnswer: [] },
  ];
  mentorAnswerList = [
    { SectionName: 'Section 1', SectionAnswer: [] },
    { SectionName: 'Section 2', SectionAnswer: [] },
    { SectionName: 'Section 3', SectionAnswer: [] },
    { SectionName: 'Section 4', SectionAnswer: [] },
    { SectionName: 'Section 5', SectionAnswer: [] },
    { SectionName: 'Section 6', SectionAnswer: [] },
  ]
  skillTypes: [];
  mentorData = [];
  mentorAnswer: any;
  filterdMentorData = [];

  addMentorAnswerList = [];
  allowedFileTypes: string[] = ["image/jpeg", "image/jpg", "image/png"];
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link'],
      ],
    }
  }
  answerSection: number = 1;
  quesitionType = [];
  skillTypeOptions = [];
  lessons = [];
  lessonPreviewData:any;
  previewLessonFlag: any;
  filterLesson: any;
  selectedSection : number =0;
  previewLessonData : any;
  categoryId: string;
  mentorChallengeAnswers: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected lessonsService: LessonsService,
    protected route: ActivatedRoute,
    protected router: Router,
    private configsService : ConfigsService,
    protected usersService: UsersService,
    protected dialog: MatDialog,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {

    this.addSection();
    var lessonCategory = "";
    this.addQuestionType();
    this.getMentorAnswer();
    this.setSkillType();

    this.route.params.subscribe(params => {
      this.categoryId = params["categoryId"];
      lessonCategory = params["categoryId"];
      this.lessonsService.categoryChangedObserver$.next(params["categoryId"]);
    });
    this.lessonsService.getLessonCategories().subscribe(res => {
      let lessonCategories: LessonSkill[] = res.result;
      this.lessonCategoryNames = lessonCategories.map(x => {
        return {
          categoryName: x.lessonCategoryName,
          categoryId: x.lessonCategoryId
        };
      });
    });
    this.lessonsService.getChallenge(this.categoryId).subscribe((data)=>{
      if(data.result){
        this.lessonDetails = data.result;
        let lessonDetail= this.lessonDetails;
        lessonDetail.sections=[this.lessonDetails.section];
        lessonDetail.title = this.lessonDetails.challengeTitle;
        lessonDetail.lessonSummary = this.lessonDetails.challengeDescription;
        this.previewLessonData = lessonDetail;
        this.fillLessonDetail();
     
        this.onCreateGroupFormValueChange();
      }
    })
    this.lessonsService.getLesson(lessonCategory).subscribe((data) => {
      if (data) {
        this.lessons = data.result;
        this.filterLesson = data.result;
      }
    })
    if (this.lessonDetails) {
      this.fillLessonDetail();
    } else {
      var newLesson = {
        lessonCategoryId: lessonCategory,
        lessonSummary: "",
        level: 1,
        isTrail: false,
        levelTitle: "",
        title: "",
        duration: 0
      };
      let reviewQuesion = {
        maxScale: 10,
        minScale: 0,
        question: ""
      };
      this.selfReviewForm = this.formBuilder.array([
        this.formBuilder.control(reviewQuesion.question, [Validators.required]),
        this.formBuilder.control(reviewQuesion.question, [Validators.required]),
        this.formBuilder.control(reviewQuesion.question, [Validators.required])
      ]);
      this.lessonForm = this.formBuilder.group(newLesson);
    }
    this.lessonPreviewData = newLesson;
    this.setupLessonFormValidations();
    this.onCreateGroupFormValueChange();
  }
  onCreateGroupFormValueChange(){
    this.lessonForm.controls.title.valueChanges.subscribe(value => {
      let  lesson=this.lessonPreviewData;
      lesson.title= value;
      lesson.lessonSummary = this.lessonForm.controls.lessonSummary.value;
      if(this.lessonSections.length>0){
         lesson.sections= this.lessonSections;
      }
      this.previewLessonData = lesson;  
    });
    this.lessonForm.controls.lessonSummary.valueChanges.subscribe(value => {
      let lesson=this.lessonPreviewData;
      lesson.lessonSummary= value;
      lesson.title = this.lessonForm.controls.title.value;
      if(this.lessonSections.length>0){
        lesson.sections= this.lessonSections;
      }
      this.previewLessonData = lesson;  
    });
  
  }
 
  getMentorForChallenge(challengeId : any){
    this.lessonsService.getMentorChallenge(challengeId).subscribe((data) => {
      if (data) {
        this.filterdMentorData.push(...data.result);
        this.mentorData = this.filterdMentorData;
      }
    })
  }
  fillLessonDetail() {
    let lessonEditDetails = {
      challengeSkillId :this.lessonDetails.challengeSkillId,
      challengeId: this.lessonDetails.challengeId,
      lessonSummary: this.lessonDetails.challengeDescription,
      title: this.lessonDetails.challengeTitle,
      duration: this.lessonDetails.challengeDuration
    };
    this.lessonSections.push(this.lessonDetails.section);
    this.lessonForm = this.formBuilder.group(lessonEditDetails);
    let sections = this.lessonDetails.sections;
    if( sections[0].mentorAnswers){
      this.mentorAnswerList[0].SectionAnswer = sections[0].mentorAnswers ;
      this.filterdMentorData.push(this.mentorAnswerList[0].SectionAnswer) ;
    }
    this.getMentorForChallenge(this.lessonDetails.challengeId);
  }
  changeLevel(value) {
    this.levelNumber = value + 1;
    this.handleFilterLesson("");
  }
  handleFilterLesson(searchKeyword) {
    let lesson;
    this.filterLesson = this.lessons.filter((item) => {
      if (searchKeyword.length)
        lesson = (item.level === this.levelNumber) &&
          (item.title.toLowerCase().includes(searchKeyword.toLowerCase()));
      else
        lesson = (item.level === this.levelNumber);
      return lesson;
    });
    console.log("filter lesson", this.filterLesson);
  }
  setSkillType() {
    for (var item in SkillType) {
      if (isNaN(Number(item))) {
        this.skillTypeOptions.push({
          value: SkillType[item],
          name: item
        });
      }
    }
  }
  setupLessonFormValidations() {
    this.lessonForm.controls["isTrail"].setValidators([Validators.required]);
    this.lessonForm.controls["lessonSummary"].setValidators([
      Validators.required
    ]);
    this.lessonForm.controls["title"].setValidators([Validators.required]);
    this.lessonForm.controls["level"].setValidators([Validators.min(1)]);
    this.lessonForm.controls["lessonCategoryId"].setValidators([
      Validators.required
    ]);
    this.lessonForm.controls["duration"].setValidators([
      Validators.required
    ]);
  }
  addQuestionType() {
    for (var question in QuestionType) {
      if (isNaN(Number(question))) {
        this.quesitionType.push({
          value: Number(QuestionType[question]),
          name: question
        });
      }
    }
  }
  addSection() {
    let newSection = {
      sectionOrder:0,
      sectionControls: [],
      sectionId: undefined,
      sectionTitle: "",
      sectionTag: "",
      isChallenge: false,
      questionType:1
    };
    this.sectionForm = this.formBuilder.group(newSection);
    this.setupSectionFormValidations();
    this.resetSectionElementTypes();
    this.selectedControlType.setValue(1);
  }

  editChallenge(value) {
    this.lessonDetails = this.lessons[value];
    this.fillLessonDetail();
  }
  handleLessonPreview(lesson) {
    this.lessonPreviewData = lesson;
    this.previewLessonFlag = true;
  }
  setupSectionFormValidations() {
    this.sectionForm.controls["sectionTitle"].setValidators([
      Validators.required
    ]);
    this.sectionForm.controls["sectionTag"].setValidators([
      Validators.required
    ]);
    this.sectionForm.controls["isChallenge"].setValidators([
      Validators.required
    ]);
  }

  addControlToSection(event) {
    this.newControl = {
      sectionElementType: parseInt(this.selectedControlType.value),
      sectionElementContent: "",
      sectionElementOptions: undefined,
      sectionElementImageName: undefined
    };
    // if (
    //   this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_SINGLE || this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_MULTI
    // ) {
    //   this.newControl.sectionElementOptions = this.formBuilder.array(
    //     [],
    //     Validators.required
    //   );
    // }
    switch (parseInt(this.selectedControlType.value)) {
      case SectionElementType.TEXTBOX_SINGLE:
        this.selectedControlType.setValue(SectionElementType.TEXTBOX_DESCRIPTION);
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.TEXTBOX_MULTI:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.SELECTION_BUTTONS_MULTI:
      case SectionElementType.SELECTION_BUTTONS_SINGLE:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.newControl.sectionElementOptions = this.formBuilder.array([]);
        break;
      default:
        break;
    }
    this.controlForm = this.formBuilder.group(this.newControl);
    //this.controlForm.controls["sectionElementContent"].setValidators([Validators.required]);
    this.setupControlFormValidations(this.controlForm);
    this.newSectionControls.push(this.controlForm);
  }

  setupControlFormValidations(form: FormGroup) {
    form.controls["sectionElementContent"].setValidators([Validators.required]);
  }

  addElementOption($event, index) {
    ((this.newSectionControls.controls[index] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.push(
      this.formBuilder.control("", [Validators.required])
    );
  }

  deleteOption(event, controlIndex, optionIndex) {
    ((this.newSectionControls.controls[controlIndex] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.splice(optionIndex, 1);
  }

  deleteControl(event, index, controlType) {
    this.newSectionControls.controls.splice(index, 1);
    if (parseInt(controlType) == SectionElementType.TEXTBOX_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    }
    else if (parseInt(controlType) == SectionElementType.TEXTBOX_MULTI) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    }
    else if (parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_MULTI || parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
    }
  }

  saveSection(event) {
    this.sectionFormErrors = [];
    this.lessonFormErrors = [];
    this.sectionForm.markAllAsTouched();
    if (!this.validateNewSectionSave()) {
      this.sectionFormErrors.push("Atleast one input control is required");
    }
    else if (!this.validateNewSectionOptionsSave()) {
      this.sectionFormErrors.push("Atleast one option is required");
    }
    else if (this.sectionForm.valid && this.newSectionControls.valid && this.newSectionControls.length > 0 && this.validateNewSection()) {
      var section = this.populateSection();
      this.lessonSections.push(section);
      this.discardNewSection();
    }
    else {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push(
        "There are invalid values in new section. Please correct them."
      );
    }
   this.fillPreviewLessonSection();
  }
  fillPreviewLessonSection(){
    if(this.previewLessonData){
      let lesson = this.previewLessonData;
      lesson.sections = this.lessonSections;
      this.previewLessonData = lesson;
    }else{
      let section={
        sections : this.lessonSections
      }
      this.previewLessonData = section;
    }
  }

  discardNewSection(event?) {
    this.sectionForm.reset();
    this.newSectionControls.reset();
    this.newSectionControls = new FormArray([]);
    this.sectionForm = undefined;
  }

  populateSection() {
    let section: Section = {
      sectionControls: [],
      sectionId: undefined,
      sectionTitle: this.sectionForm.value["sectionTitle"],
      sectionOrder: 0,
      sectionTag: this.sectionForm.value["sectionTag"],
      questionType: parseInt(this.sectionForm.value["questionType"]),
      isChallenge: this.sectionForm.value["isChallenge"]
    };
    for (let i = 0; i < this.newSectionControls.length; i++) {
      let control: SectionElement = {
        sectionElementContent: this.newSectionControls.controls[i].value[
          "sectionElementContent"
        ],
        sectionElementId: undefined,
        sectionElementType: this.newSectionControls.controls[i].value[
          "sectionElementType"
        ],
        sectionElementOptions: undefined,
        sectionElementOrder: i
      };
      if (
        this.newSectionControls.controls[i].value["sectionElementType"] ==
        SectionElementType.SELECTION_BUTTONS_SINGLE || this.newSectionControls.controls[i].value["sectionElementType"] ==
        SectionElementType.SELECTION_BUTTONS_MULTI
      ) {
        control.sectionElementOptions = ((this.newSectionControls.controls[
          i
        ] as FormGroup).controls[
          "sectionElementOptions"
        ] as FormArray).controls.map(x => x.value);
      }
      section.sectionControls.push(control);
    }
    return section;
  }

  editSection(event, index) {
    this.resetSectionElementTypes();
    this.selectedControlType.setValue(1);
    this.sectionToEdit = this.lessonSections[index];
    this.removeExtraSectionElementTypes(this.sectionToEdit);
    let sectionControlsEditForm = this.formBuilder.array(
      this.sectionToEdit.sectionControls.map(x => {
        let element = {
          sectionElementContent: this.formBuilder.control(x.sectionElementContent, Validators.required),
          sectionElementType: x.sectionElementType,
          sectionElementOptions: undefined,
          sectionElementImageName: undefined,

        };
        if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI) {
          element.sectionElementOptions = this.formBuilder.array(
            x.sectionElementOptions,
            Validators.required
          );
        }
        return this.formBuilder.group(element, Validators.required);
      }),
    );
    this.sectionEditForm = this.formBuilder.group({
      sectionControls: sectionControlsEditForm,
      sectionTitle: this.formBuilder.control(
        this.sectionToEdit.sectionTitle,
        Validators.required
      ),
      sectionTag: this.formBuilder.control(
        this.sectionToEdit.sectionTag,
        Validators.required
      ),
      isChallenge: this.formBuilder.control(
        this.sectionToEdit.isChallenge,
        Validators.required
      ),

      questionType: this.formBuilder.control(
        this.sectionToEdit.questionType,
        Validators.required
      ),
    });
  }

  deleteEditSectionOption(event, controlIndex, optionIndex) {
    (((this.sectionEditForm.controls.sectionControls as FormArray).controls[
      controlIndex
    ] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.splice(optionIndex, 1);
    this.sectionEditForm.value.sectionControls[controlIndex].sectionElementOptions.splice(optionIndex, 1);
  }

  deleteEditSectionControl(event, index, controlType) {
    (this.sectionEditForm.controls
      .sectionControls as FormArray).controls.splice(index, 1);
    (this.sectionEditForm.controls.sectionControls as FormArray).value.splice(
      index,
      1
    );
    if (parseInt(controlType) == SectionElementType.TEXTBOX_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    }
    else if (parseInt(controlType) == SectionElementType.TEXTBOX_MULTI) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    }
    else if (parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_MULTI || parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
    }
  }

  discardEditSection(event?) {
    this.sectionEditForm = undefined;
    this.sectionToEdit = undefined;
  }

  saveEditedSection(event, index) {
    this.sectionFormErrors = [];
    this.sectionEditForm.markAllAsTouched();
    if (!this.validateEditedSectionSave()) {
      this.sectionFormErrors.push("Atleast 1 input control is required")
    }
    else if (!this.validateEditedSectionOptionsSave()) {
      this.sectionFormErrors.push("Atleast one option is required")
    }
    else if (this.sectionEditForm.valid && this.sectionEditForm.value.sectionControls.length > 0) {

      this.lessonSections[index].isChallenge = this.sectionEditForm.value.isChallenge;
      this.lessonSections[index].sectionControls = this.sectionEditForm.value.sectionControls;
      this.lessonSections[index].sectionTag = this.sectionEditForm.value.sectionTag;
      this.lessonSections[index].sectionTitle = this.sectionEditForm.value.sectionTitle;
      this.lessonSections[index].questionType = this.sectionEditForm.value.questionType;
      //this.lessonSections[index] = this.sectionEditForm.value;
      this.discardEditSection();
    }
    this.fillPreviewLessonSection();
  }

  addControlToSectionEdit(event) {
    let newControl = {
      sectionElementType: parseInt(this.selectedControlType.value),
      sectionElementContent: "",
      sectionElementOptions: undefined,
      sectionElementImageName: undefined
    };
    // if (
    //   this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_MULTI || this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_SINGLE
    // ) {

    // }
    switch (parseInt(this.selectedControlType.value)) {
      case SectionElementType.TEXTBOX_SINGLE:
        this.selectedControlType.setValue(SectionElementType.TEXTBOX_DESCRIPTION);
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.TEXTBOX_MULTI:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.SELECTION_BUTTONS_MULTI:
      case SectionElementType.SELECTION_BUTTONS_SINGLE:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        newControl.sectionElementOptions = this.formBuilder.array([]);
        break;
      default:
        break;
    }
    let newControlForm = this.formBuilder.group(newControl);
    this.setupControlFormValidations(newControlForm);
    (this.sectionEditForm.controls.sectionControls as FormArray).push(
      newControlForm
    );
  }

  addElementOptionEdit(event, index) {
    (((this.sectionEditForm.controls.sectionControls as FormArray).controls[
      index
    ] as FormGroup).controls["sectionElementOptions"] as FormArray).push(
      this.formBuilder.control("", Validators.required)
    );
  }
  updateSection() {
    for (var i = 0; i < this.lessonSections.length; i++) {
      this.lessonSections[i].questionType = Number(this.lessonSections[i].questionType);
      this.lessonSections[i].mentorAnswers = this.mentorAnswerList[i].SectionAnswer;
      this.lessonSections[i].technicalAnswers = this.TechnialAnswer[i].SectionAnswer;
    }
  }
  arrageSection() {
    let lesson = this.lessonSections[0]
    lesson.questionType = lesson.questionType;
    console.log(lesson.questionType);
  }
  addChallenge(event) {
    this.lessonFormErrors = [];
    this.lessonForm.markAllAsTouched();
    this.selfReviewForm.markAllAsTouched();

    if (
      this.lessonForm.valid && !this.sectionToEdit && this.lessonSections.length > 0
    ) {

      this.lessonSections[0].questionType = Number(this.lessonSections[0].questionType);

      let challenge = {
        challengeTitle: this.lessonForm.value["title"],
        challengeDescription: this.lessonForm.value["lessonSummary"],
        section: this.lessonSections[0],
        challengeDuration: this.lessonForm.value["duration"],
        mentorAnswers: this.selectedMentor,
        challengeSkillId : this.categoryId
      }
      console.log("challenge data", challenge);
      this.configsService.loading(true);
      this.lessonsService.addChallenge(challenge).subscribe(
        res => {
          this.configsService.loading(false);
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: "Challange added successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.issuccessPopup = false;

              var addChallengeContext = this;
              setTimeout(function () {
                addChallengeContext.router.navigate(["challenge/" + addChallengeContext.categoryId + "/new"]);
              }, 1000);
            },
          };
        },
        err => {
          this.configsService.loading(false);
          this.lessonEditTab.nativeElement.scrollIntoView();
          this.lessonFormErrors.push("Something went wrong. Please try again");
        }
      );
      this.lessonsService.uploadSectionImagesOfChallenge(this.imageUploadArray).subscribe(res => {
      }, err => {
        this.configsService.loading(false);
        this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
      });
    }
    else if (this.lessonSections.length === 0) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Atleast 1 section is required");
    } else if (this.sectionToEdit) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");

    }
  }

  cancelLessonUpsert() {
    this.route.params.subscribe(params => {
      let lessonCategory = params["categoryId"];
      this.router.navigate(["challenge/" + lessonCategory + "/new"]);
    });
  }

  changePreview(event, i) {
    this.previewChange.emit({ event, i });
  }

  dropSection(event) {
    let section = this.lessonDetails.sections[event.previousIndex];
    this.lessonDetails.sections.splice(event.previousIndex, 1);
    this.lessonDetails.sections.splice(event.currentIndex, 0, section);
  }

  dropControl(event, sectionIndex) {
    let section = this.lessonSections[sectionIndex];
    let control = section.sectionControls[event.previousIndex];
    section.sectionControls.splice(event.previousIndex, 1);
    section.sectionControls.splice(event.currentIndex, 0, control)
  }
  getTrimAnswer(value) {
    let val = value;
    if (val.length >= 20) {
      val = val.slice(0, 30) + "...";
    }
    return val;
  }
  enableCarousel() {
    (function ($) {
      $(document).ready(function () {
        $('#mentor-overall-container').slick({
          dots: false,
          autoplay: false,
          infinite: false,
          arrows: false
        });
      });
    })(jQuery);
  }
  updateChallenge(event) {
    if (
      this.lessonForm.valid && !this.sectionToEdit && this.lessonSections.length > 0
    ) {
      this.updateSelectedSection();
      this.lessonFormErrors=[];
      this.lessonSections[0].questionType = Number(this.lessonSections[0].questionType);
      let challenge = {
        challengeTitle: this.lessonForm.value["title"],
        challengeDescription: this.lessonForm.value["lessonSummary"],
        section: this.lessonSections[0],
        challengeDuration: this.lessonForm.value["duration"],
        mentorAnswers: this.selectedMentor,
        challengeSkillId : this.categoryId,
        challengeId: this.lessonDetails.challengeId
      }
      this.lessonsService.uploadSectionImagesOfChallenge(this.imageUploadArray).subscribe(res => {
      }, err => {
        this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
      });
      this.lessonsService.updateChallenge(challenge).subscribe(
        res => {
          if(res.statusCode === 200){
            this.issuccessPopup = true;
            this.success_popup = {
              display: true,
              message: "Challenge updated successfully",
              buttonPositive: "Okay",
              positiveButton: () => {
                this.success_popup.display = false;
                this.done.emit(res.result.lessonId);
              },
            };
          }
        },
        err => {
          this.lessonEditTab.nativeElement.scrollIntoView();
          this.lessonFormErrors.push("Something went wrong. Please try again");
        }
      );
    } else if (this.lessonSections.length === 0) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Atleast 1 section is required");
    } else if (this.sectionToEdit) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    } else if (this.sectionForm) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    }
  }

  uploadEditSectionImage(event, elementIndex) {
    let image: File = event.target.files[0];
    if (image.size > 1000000) {
      this.sectionFormErrors.push("Size must be less than 1 MB.")
    }
    else if (!this.allowedFileTypes.some(type => image.type === type)) {
      this.sectionFormErrors.push("Unsupported image file type.")
    }
    else {
      image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });
      this.imageUploadArray.push(image);
      ((this.sectionEditForm.controls.sectionControls as FormArray).controls[elementIndex] as FormGroup).controls["sectionElementImageName"].setValue(image.name);
      ((this.sectionEditForm.controls.sectionControls as FormArray).controls[elementIndex] as FormGroup).controls["sectionElementContent"].setValue(this.imageStorageUrl + image.name);
    }
  }

  uploadSectionImage(event, elementIndex) {
    let image: File = event.target.files[0];
    if (image.size > 1000000) {
      this.sectionFormErrors.push("Size must be less than 1 MB.")
    }
    else if (!this.allowedFileTypes.some(type => image.type === type)) {
      this.sectionFormErrors.push("Unsupported image file type.")
    }
    else {
      image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });
      this.imageUploadArray.push(image);
      ((this.newSectionControls.controls[elementIndex] as FormGroup).controls.sectionElementImageName as FormControl).setValue(image.name);
      ((this.newSectionControls.controls[elementIndex] as FormGroup).controls.sectionElementContent as FormControl).setValue(this.imageStorageUrl + image.name);
    }
  }

  getTimestamp() {
    let currentDate = new Date();
    return currentDate.getUTCFullYear().toString() + currentDate.getUTCMonth().toString() + currentDate.getUTCDate().toString()
      + currentDate.getUTCHours().toString() + currentDate.getUTCMinutes().toString() + currentDate.getUTCSeconds().toString();
  }

  deleteConfirmation(event, index) {
    this.sectionDeleteDialog = this.dialog.open(DeleteConfirmationComponent, {
      width: "30%",
      disableClose: false
    });
    this.sectionDeleteDialog.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.lessonSections.splice(index, 1);
      }
    })
  }

  resetSectionElementTypes() {
    this.sectionControlsTypes = JSON.parse(JSON.stringify(this.allSectionControlTypes));
  }

  validateNewSectionSave() {
    return this.newSectionControls.length > 0 && this.newSectionControls.controls.some(x =>
      x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.value.sectionElementType == SectionElementType.TEXTBOX_MULTI || x.value.sectionElementType == SectionElementType.TEXTBOX_SINGLE
    );
  }

  validateNewSection() {
    var optionControls = this.newSectionControls.controls.filter(x => x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    var allOptiosnValid = optionControls.every(x => {
      return ((x as FormGroup).controls.sectionElementOptions as FormArray).controls.every(y => y.valid);
    })
    return allOptiosnValid;
  }

  validateNewSectionOptionsSave() {
    var optionsControls = this.newSectionControls.controls.filter(x => x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (optionsControls.length == 0)
      return true;
    else if (optionsControls.every(optionControl => {
      return ((optionControl as FormGroup).controls.sectionElementOptions as FormArray).length > 0
    }))
      return true;
    else
      return false;
  }

  validateEditedSectionSave() {
    return this.sectionEditForm.value.sectionControls.length > 0 && this.sectionEditForm.value.sectionControls.some(x =>
      x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType == SectionElementType.TEXTBOX_MULTI || x.sectionElementType == SectionElementType.TEXTBOX_SINGLE
    );
  }

  validateEditedSectionOptionsSave() {
    var optionsControls = this.sectionEditForm.value.sectionControls.filter(x => x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (optionsControls.length == 0)
      return true;
    else if (optionsControls.every(optionControl => {
      return optionControl.sectionElementOptions.length > 0
    }))
      return true;
    else
      return false;
  }

  removeExtraSectionElementTypes(section: Section) {
    var sectionType = section.sectionControls.filter(x => x.sectionElementType == SectionElementType.TEXTBOX_SINGLE || x.sectionElementType == SectionElementType.TEXTBOX_MULTI
      || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (sectionType && sectionType.length > 0) {
      sectionType.forEach(x => {
        switch (x.sectionElementType) {
          case SectionElementType.TEXTBOX_SINGLE:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
            break;
          case SectionElementType.TEXTBOX_MULTI:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
            break;
          case SectionElementType.SELECTION_BUTTONS_MULTI:
          case SectionElementType.SELECTION_BUTTONS_SINGLE:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
            break;
          default:
            break;
        }
        return;
      })
    }
  }

  getControlLabel(typeId: number) {
    var element = this.allSectionControlTypes.find(x => x.controlTypeId == typeId)
    return element.controlType;
  }
  showAnswerSection(value: number) {
    this.answerSection = value;

  }
  showInvitePopup(event) {
    this.lessonSections =  this.lessonDetails.sections;
    this.updateSelectedSection();
    this.lessonDetails.sections = this.lessonSections;
    const dialogRef = this.dialog.open(InvitePopupComponent, {
      width: "70%",
      disableClose: true,
      data: {
        selectedLesson:this.lessonDetails,
        challenge: true
      }
    })
  }
  updateSelectedSection() {
    for (let i = 0; i < this.lessonSections.length; i++) {
      this.lessonSections[i].questionType = Number(this.lessonSections[i].questionType);
      this.lessonSections[i].mentorAnswers = this.mentorAnswerList[i].SectionAnswer;
      this.lessonSections[i].technicalAnswers = this.TechnialAnswer[i].SectionAnswer;
      for(let j=0 ;j<this.lessonSections[i].sectionControls.length;j++){
        if(this.lessonSections[i].sectionControls[j].sectionElementType === 5){
          if( this.lessonSections[i].sectionControls[j].sectionElementContent.changingThisBreaksApplicationSecurity){
            this.lessonSections[i].sectionControls[j].sectionElementContent=
            this.lessonSections[i].sectionControls[j].sectionElementContent.changingThisBreaksApplicationSecurity;
          }
        }
      }
    }
  }
  getMentorAnswer() {
    // this.lessonsService.getMentorAnswer().subscribe((data) => {
    //   if (data) {
    //     this.mentorData = data.result;
    //   }
    // })
  }
  addTechnicalAnswer(i) {
    this.TechnialAnswer[i].SectionAnswer.push(this.techAnswer);
  }

  importlesson(value) {
    this.importLessonFlag = value;
  }
  previewClose(value) {
    this.importLessonFlag = !value;
    this.previewLessonFlag = value;
  }
  addInputAnswer(value) {
    this.inputMentorAnswer = value;
  }
  addAnswerList(mentorIndex) {
    this.addMentorAnswerList.push(this.inputMentorAnswer);
    this.mentorData[mentorIndex].answers.push(this.inputMentorAnswer);
    console.log("mentor data", this.mentorData);
  }

  searchMentor(searchString: string) {
    if (searchString) {
      searchString = searchString.toLowerCase();
      let filteredMentors = this.mentorData.filter(
        m => m.name.toLowerCase().includes(searchString));
      this.filterdMentorData = filteredMentors.slice(0, 5);
    } else {
      this.filterdMentorData = this.mentorData;
    }
  }

  isMentorAdded(mentorId) {
    let mentor = this.selectedMentor.filter((m) => {
      if (m.mentorId == mentorId)
        return m;
    });

    if (mentor.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  addAnswerToMentor(j) {
    this.selectedMentor.push(this.filterdMentorData[j]);
    console.log("mentod daata", this.selectedMentor)
    this.issuccessPopup = true;
    this.success_popup = {
      display: true,
      message: "Mentor add successfully",
      buttonPositive: "Okay",
      positiveButton: () => {
        this.success_popup.display = false;
        this.issuccessPopup = false;
      }
    }
  }

  removeMentorAnswer(mentorIndex) {
    let mentor = this.filterdMentorData[mentorIndex];

    let answers = this.selectedMentor.filter((m) => {
      if (m.mentorId != mentor.mentorId)
        return m;
    })
    this.selectedMentor = answers;

    if (!this.mentorSearch) {
      this.filterdMentorData = this.selectedMentor;
    }

    this.issuccessPopup = true;
    this.success_popup = {
      display: true, message: "Mentor remove successfully",
      buttonPositive: "Okay",
      positiveButton: () => {
        this.success_popup.display = false;
        this.issuccessPopup = false;
      }
    }
  }
}
