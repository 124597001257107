import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {LessonsService} from "src/app/services/lessons.service";
import {ConfigsService} from 'src/app/services/configs.service';
import {SkillTags, SkillType} from '../common/common.model';
import {NgForm} from '@angular/forms';
import {CommonService} from 'src/app/services/common.service';
import {from} from 'rxjs';
import {appSettings} from 'src/environments/environment';
import {Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {MentorService} from 'src/app/services/mentor.service';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import {FeaturedMentorAnswerService} from "../../services/featured-mentor-answer.service";
import {FileUploadService} from "../../services/file-upload.service";
import {F} from "@angular/cdk/keycodes";
import {saveAs} from 'file-saver';
import {NftBundleService} from "../../services/nft-bundle.service";


@Component({
  selector: 'app-mentors-bundles',
  templateUrl: './nft-bundle.component.html',
  styleUrls: ['./nft-bundle.component.less']
})
export class NftBundleComponent implements OnInit {

  pageUrl: string = "mentor/nft-bundles";
  page_title = "NFT Bundles";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  sortColumn: string = '';
  sortOrder: string = '';

  bundleGridData: any;

  addNftBundleFlag = false;

  selectedBundle: any;

  isconfirmPopup = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    public usersService: UsersService,
    private nftBundleService: NftBundleService
  ) {
    this.getBundleList();
  }

  ngOnInit() {
    this.usersService.isMentor = true;
  }

  nftColumnData: GleacColumnDef[] = [
    {
      displayName: "Bundle Name",
      data: "name",
      isExpandable: true,
    },
    {
      displayName: "OpenSea Url",
      data: "openSeaLink",
      disableSort: true,
    },
    {
      displayName: "Mentors",
      data: "bundleMentors",
      disableSort: true,
      render: (data, row) => {
        let mentorList = [];

        row.bundleMentors.forEach((item) => {
          mentorList.push(item.name);
        })

        return `<span >${mentorList.sort().join(", ")}</span>`;
      },
    },
    {
      displayName: "Date",
      data: "createdAt",
      isDate: true
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        }
      ],
    },
  ];

  getBundleList() {
    this.configsService.loading(true);
    this.nftBundleService.getNftBundles(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder
    ).subscribe(
      (res) => {
        if (res) {
          this.bundleGridData = {
            list: res.result.data,
            totalCount: res.result.totalCount
          };
          //this.mentors = res.result.data;
        } else {
          this.bundleGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getBundleList();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getBundleList();
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.getBundleList()
  }

  addNftBundle(data: any) {
    this.addNftBundleFlag = true;
    this.selectedBundle = data;
  }

  onClose() {
    this.addNftBundleFlag = false;
    this.pageNumber = 1;
    this.getBundleList();
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.nftBundleService.exportNftBundles(1,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "NFT Bundles" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  deleteBundle(bundleId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        this.nftBundleService.deleteNftBundle(bundleId).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  actionClick(event) {
    if (event.action === "edit") {
      let data: any = {};
      data.name = event.data.name
      data.id = event.data.id;
      data.openSeaLink = event.data.openSeaLink;
      data.bundleMentors = [];

      event.data.bundleMentors.forEach((item) => {
        data.bundleMentors.push(item.mentorId);
      })

      this.selectedBundle = data;
      this.addNftBundleFlag = true;
    } else if (event.action === "delete") {
      this.deleteBundle(event.data.id)
    }
  }


  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: "Deleted Successfully",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getBundleList();
        },
      };
    } else {
      this.iserrorPopup = true;
    }
  }
}

