import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostAnonymousDiscussionRequest, PostDiscussionRequest } from 'src/app/models/requests/post-comment-request';
import { ConfigsService } from 'src/app/services/configs.service';
import { LessonsService } from 'src/app/services/lessons.service';
import { MentorService } from 'src/app/services/mentor.service';

@Component({
  selector: 'app-discussion-reply',
  templateUrl: './discussion-reply.component.html',
  styleUrls: ['./discussion-reply.component.less']
})
export class DiscussionReplyComponent implements OnInit {
  threadID: string;
  replyString: string = "";
  userId: string;
  showErrorText: boolean = false;
  isSubmitted: boolean = false;

  constructor(protected configsService: ConfigsService, private route: ActivatedRoute, protected mentorService: MentorService, protected router: Router, protected skillService: LessonsService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params["id"] === undefined || params["userId"] === undefined) {
        this.router.navigate(["invalid"]);
      }
      this.threadID = params["id"];
      this.userId = params["userId"];
    })
  }

  postReply() {
    this.configsService.loading(true);
    if (!this.replyString || this.replyString.trim() === '') {
      this.showErrorText = true;
      return;
    }
    let request: PostAnonymousDiscussionRequest = {
      commentId: this.threadID,
      comment: this.replyString,
      userId: this.userId
    }
    this.skillService.postAnonymousComment(request).subscribe((res) => {
      this.isSubmitted = true;
    });
    this.configsService.loading(false);
  }
}
