import { Component, OnInit, Input, OnChanges, ViewChild, ViewChildren, QueryList, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LessonsService } from "src/app/services/lessons.service";
import { Lesson, Section } from "../common/common.model";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { MatDialog } from "@angular/material";
import { InvitePopupComponent } from "src/app/dialogs/invite-popup/invite-popup.component";
import { UsersService } from 'src/app/services/users.service';
import { appSettings } from 'src/environments/environment';
import { ConfigsService } from '../../services/configs.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: "app-lesson-detail",
  templateUrl: "./lesson-detail.component.html",
  styleUrls: ["./lesson-detail.component.less"],
})
export class LessonDetailComponent implements OnInit {
  lessonId: string;
  selectedLesson: Lesson;
  showAddLessonTemplate: boolean = false;
  previewSection: Section;
  inviteEmails: string[];
  emailContent: string;
  bucketUrl: string;
  s3BucketUrl: string = appSettings.s3BucketUrl;
  iserrorPopup:boolean = false;
  issuccessPopup:boolean = false;
  success_popup ={ 
    display: false,
    message: '',
    buttonPositive: '',
    positiveButton: function(){}
  }
  error_popup = {};
  url='';
  @Output('campaignSkills') campaignSkills = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    public lessonsService: LessonsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    public usersService: UsersService,
    private configsService : ConfigsService,
    private commonService : CommonService

  ) { }

  ngOnInit() {
    this.lessonsService.selectedCategoryObserver$.subscribe(skill => {
      this.selectedLesson.lessonCategory = skill.lessonCategoryName;
    });
    this.loadLessonDetails();
  }

  loadLessonDetails() {
    this.route.params.subscribe(params => {
      this.lessonId = params["lessonId"];
      if (params["categoryId"]) {
        this.lessonsService.categoryChangedObserver$.next(params["categoryId"]);
      }
      if (this.lessonId) {
        this.selectLesson();
        this.showAddLessonTemplate = false;
      }
    });
  }

  selectLesson() {
    this.lessonsService.lessonChangedObserver$.next(this.lessonId);
    //this.configsService.loading(true);
    this.lessonsService.lessonSeletedObserver$.subscribe(lesson => {
      this.selectedLesson = lesson;
      if (
        this.selectedLesson.sections &&
        this.selectedLesson.sections.length > 0
      ) {
        this.previewSection = this.selectedLesson.sections[0];
        this.sanitinzeSection(this.previewSection);
      }
    },err => {

    },()=> {
        //this.configsService.loading(false);
    });
  }

  sanitinzeSection(section: Section) {
    section.sectionControls.forEach(
      x =>
        (x.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          x.sectionElementContent
        ))
    );
  }

  sanitinzeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  cancelLessonUpsert(event) {
    this.loadLessonDetails();
  }

  lessonUpsertDone(event) {
    this.loadLessonDetails();
  }

  showSkillDetails(event) {
    this.route.params.subscribe(params => {
      let categoryId = params["categoryId"];
      this.router.navigate(["lessons/" + categoryId]);
    });
  }

  changePreview(event, index) {
    if (event.i !== undefined) {
      this.sanitinzeSection(this.selectedLesson.sections[event.i]);
      this.previewSection = this.selectedLesson.sections[event.i];
    } else {
      this.sanitinzeSection(this.selectedLesson.sections[index]);
      this.previewSection = this.selectedLesson.sections[index];
    }
  }

  showInvitePopup(event) {
    const dialogRef = this.dialog.open(InvitePopupComponent, {
      width: "70%",
      disableClose: true,
      data: {
        selectedLesson: this.selectedLesson
      }
    })
  }

  getImagefromS3(event, index) {
    let originalUrl = "";
    if (event.i !== undefined) {
      originalUrl = this.previewSection.sectionControls[event.i].sectionElementContent;
    } else {
      originalUrl = this.previewSection.sectionControls[index].sectionElementContent;
    }
    if (this.previewSection) {
      this.bucketUrl = this.s3BucketUrl + (originalUrl.split('.com')[1]);
      event.target.src = this.bucketUrl;
    }
  }


  addSkillCampaign(data){
    this.lessonsService.sendData(data);
    let sendingData={};
    let formedData = { lessonCategoryName: data.lessonCategory,lessonCategoryId: data.lessonCategoryId,lessons: [data] }
    sendingData["lessonSkill"]=formedData;
    this.url="LessonCart";
    this.configsService.loading(true);
    this.commonService.createData(this.url, sendingData, false).subscribe(data => {
      this.configsService.loading(false);
      this.postStatus(data);
    },err => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
    },()=> {
        this.configsService.loading(false);
    });
  }


  /** CallBack Fucntion
   -- @params result --
  **/ 
  postStatus(result){
     if(!result.value.hasError){
        this.issuccessPopup = true;
        this.success_popup ={ 
            display: true,
            message: result.value.message,
            buttonPositive: 'Okay',
            positiveButton:()=>{
              this.success_popup.display = false;
            }
          }
     }else{
       this.iserrorPopup = true
       // this.error_popup.display = true;
     }

  }



}
