import { Component, OnInit } from "@angular/core";
import { LessonsService } from "src/app/services/lessons.service";
import { LessonSkill, LessonCartSkills } from "./lessons.model";
import { Lesson } from "../common/common.model";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { ConfigsService } from "../../services/configs.service";
import { Subscription } from "rxjs/_esm2015";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-lessons",
  templateUrl: "./lessons.component.html",
  styleUrls: ["./lessons.component.less"],
})
export class LessonsComponent implements OnInit {
  pageUrl: string = "lessons";
  lessonCategories: LessonSkill[];
  selectedCategory: LessonSkill;
  selectedLesson: Lesson;
  newCategory: boolean = false;
  hideSubNavbar : boolean = false;
  subscription: Subscription;
  cartSkills: any = [];
  url = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () { },
  };
  error_popup = {};
  lessonSkill: LessonCartSkills[];
  ngOnInit() {
    this.usersService.isDevelop = true;
    // if(this.router.url==='/lessons' || this.router.url =="/lessons/library"){
    //   this.hideSubNavbar =  ;
    // }
    // else {
    //   this.hideSubNavbar =true;

    // }
    let breadCrumsArray = [
      {
        pathName: "Lessons",
        pathUrl: this.router.url,
      },
    ];
    localStorage.removeItem("c_l");
    this.configsService.setBreadcrumb(breadCrumsArray);

    this.lessonsService.cateogoriesChangedObserver$.next();
    //this.configsService.loading(true);
    this.lessonsService.cateogoriesObserver$.subscribe(
      (categories) => {
        this.lessonCategories = categories.sort((x, y) =>
          x.lessonCategoryOrder > y.lessonCategoryOrder ? 1 : -1
        );
      },
      (err) => {
        //this.configsService.loading(false);
      },
      () => {
        //this.configsService.loading(false);
      }
    );
    //this.configsService.loading(true);
    this.lessonsService.selectedCategoryObserver$.subscribe(
      (category) => {
        this.selectedCategory = category;
        if (!this.selectedCategory.levels) {
          this.selectedCategory.levels = [1];
          this.selectedCategory.selectedLevel = 1;
        }
      },
      (err) => { },
      () => {
        //this.configsService.loading(false);
      }
    );

    //this.configsService.loading(true);
    this.lessonsService.lessonSeletedObserver$.subscribe(
      (lesson) => {
        this.selectedLesson = lesson;
      },
      (err) => { },
      () => {
        //this.configsService.loading(false);
      }
    );
    //this.cartSkillsList();
  }

  selectLessonCategory(event, index) {
    let lessonCategoryId = this.lessonCategories[index].lessonCategoryId;
    this.selectedCategory = this.lessonCategories[index];
    this.lessonsService.seletedLevelIndex = 0;
    this.router.navigate([this.pageUrl + "/" + lessonCategoryId]);
  }

  addNewSkillTemplate(event) {
    this.router.navigate([this.pageUrl + "/new"]);
    this.newCategory = true;
  }

  selectLesson(event, index) {
    let lessonId = this.selectedCategory.leveledLessons[index].lessonId;
    this.router.navigate([
      this.pageUrl +
      "/" +
      this.selectedCategory.lessonCategoryId +
      "/" +
      lessonId,
    ]);
  }

  addNewLessonTemplate(event) {
    this.router.navigate([
      this.pageUrl + "/" + this.selectedCategory.lessonCategoryId + "/new",
    ]);
  }

  addLessons(data) {
    console.log(data);
    this.configsService.loading(true);
    data.leveledLessons.map((x) => {
      let found = this.cartSkills.some((ele) => ele.lessonId === x.lessonId);
      if (!found) {
        this.cartSkills.push(x);
        //this.cartSkills.push({lessonId:x.lessonId,lessonTitle:x.lessonTitle,lessonSummary:x.lessonSummary,title:x.title,level:x.level,subLevel:x.subLevel,lessonCategoryId:x.lessonCategoryId});
      }
    });
    data.lessons = this.cartSkills;
    let sendingData = {};
    // let formedData = { lessonCategoryName: data.lessonCategoryName,lessonCategoryId: data.lessonCategoryId,imageSrc: data.imageSrc, lessons: data.lessons }
    sendingData["lessonSkill"] = data;
    this.url = "LessonCart";
    this.commonService.createData(this.url, sendingData, false).subscribe(
      (data) => {
        this.configsService.loading(false);
        this.postStatus(data);
        this.cartSkillsList();
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  showCategories(event) {
    this.newCategory = false;
    this.selectedCategory = undefined;
    this.router.navigate([this.pageUrl]);
  }

  setupLevel(selectedLevel) {
    this.lessonsService.seletedLevelIndex = this.selectedCategory.levels.indexOf(
      parseInt(selectedLevel)
    );
    this.selectedCategory.leveledLessons = this.selectedCategory.lessons.filter(
      (x) => x.level == selectedLevel
    );
  }

  /** CallBack Fucntion
   -- @params result --
  **/

  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
        },
      };
    } else {
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  dropCategory(event) {
    let category = this.lessonCategories[event.previousIndex];
    this.lessonCategories.splice(event.previousIndex, 1);
    this.lessonCategories.splice(event.currentIndex, 0, category);
    for (var i = 0; i < this.lessonCategories.length; i++) {
      this.lessonCategories[i].lessonCategoryOrder = i;
    }
    this.lessonsService.updateLessonCategories(this.lessonCategories).subscribe(
      (res) => {
        this.lessonsService.cateogoriesChangedObserver$.next();
      },
      (err) => { }
    );
  }

  constructor(
    public lessonsService: LessonsService,
    private router: Router,
    public usersService: UsersService,
    private configsService: ConfigsService,
    private commonService: CommonService,
    private activeRoute : ActivatedRoute
  ) {
    this.subscription = this.lessonsService.getData().subscribe((x) => {
      let found = this.cartSkills.some((ele) => ele.lessonId === x.lessonId);
      if (!found) {
        this.cartSkills.push(x);
      }
      // if(!this.cartSkills.includes(x.lessonId)){
      //   this.cartSkills.push(x);
      // }
    });
  }

  cartSkillsList(querytoPass?: any) {
    this.configsService.loading(true);
    let cartUrl = "LessonCart";
    // let apiLink = this.commonService.encodeQueryData(this.queryParam);
    this.commonService.getListAlone(cartUrl, querytoPass).subscribe(
      (data) => {
        this.cartSkills = data.result.list;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }
}
