import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfigsService} from 'src/app/services/configs.service';
import {CommonService} from "../../services/common.service";
import {data, param} from 'jquery';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-assign-user-to-group',
  templateUrl: './assign-user-to-group.component.html',
  styleUrls: ['./assign-user-to-group.component.less']
})
export class AssignUserToGroupComponent implements OnInit {
  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @Output() userClose: EventEmitter<any> = new EventEmitter();
  @ViewChild('ag', {static: false}) paginator: any;
  @Input() companyIdFromRoute?: any = '';

  title = "Users";
  userHover: number = null;
  users = [];
  newUsers = [];
  groupId;
  groupData: any = {};
  groupUrl = "";
  updateUsers = [];
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };

  onApiCall = false;

  constructor(private configsService: ConfigsService, private commonService: CommonService, protected route: ActivatedRoute) {
    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.groupId = params["groupId"];
    });

    this.getUser();
  }

  getUser() {
    this.configsService.loading(true);
    let url = "Groups/AllUsers";
    let query = "pageIndex=" + this.pageNumber + "&pageSize=" + this.pageLimit + "&searchText=" + (this.searchString ? encodeURIComponent(this.searchString) : '');

    if(this.companyIdFromRoute)
    {
      query = "pageIndex=" + this.pageNumber + "&pageSize=" + this.pageLimit + "&companyId=" + this.companyIdFromRoute + "&searchText=" + (this.searchString ? encodeURIComponent(this.searchString) : '');
    }

    this.commonService.getListDataGridV2(url, query).subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.list.length > 0) {
            this.users = data.result.list;

            let context = this;
            this.users.forEach(function (item) {
              if (item.groupIds && item.groupIds.length > 0) {
                item.isChecked = item.groupIds.includes(context.groupId);
                item.isNewChecked = item.groupIds.includes(context.groupId);
              }
            });

            this.users.forEach(function (item) {
              if (!item.isChecked) {
                item.isNewChecked = context.newUsers.includes(item.userId);
              }
            });

            this.totalCount = data.result.totalCount;
          } else {
            this.users = [];
            this.totalCount = 0;
          }
          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  closeModel(ent) {
    this.isDisplay = false;
    this.userClose.emit();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getUser();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  mouseEnter(value) {
    this.userHover = value;
  }

  mouseleave() {
    this.userHover = null;
  }

  filterData(params) {
    this.searchString = params[0];
    //this.pageNumber = 1;
    //this.getUser();
    this.paginator.changePage(0);
  }

  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d === user.userId);

    if (index < 0) {
      this.newUsers.push(user.userId);
    } else {
      this.newUsers.splice(index, 1);
    }
  }

  assignUser() {
    this.groupUrl = "Groups/user"
    this.groupData.userIds = this.newUsers;
    this.groupData.groupId = this.groupId;

    this.configsService.loading(true);
    this.commonService.createDataV2(this.groupUrl, this.groupData).subscribe((data) => {
        this.configsService.loading(false);
        this.isDisplay = false;
        this.issuccessPopup = true;
        this.success_popup = {
          display: true,
          message: "User added successfully",
          buttonPositive: "Okay",
          positiveButton: () => {
            this.success_popup.display = false;
            this.issuccessPopup = false;
            this.userClose.emit();
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      })
  }
}
