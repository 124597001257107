import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { AuthService } from 'angular-6-social-login';

declare var jQuery: any;

@Component({
    selector: 'whatsapp-lesson-subscription-popup',
    templateUrl: './whatsapp-lesson-subscription-popup.component.html',
    styleUrls: ['./whatsapp-lesson-subscription-popup.component.less']
})
export class WhatsappLessonSubscriptionPopupComponent implements OnInit {
    registerForm = this.createForm();
    timerHeader: string;
    isMobile: boolean;
    requestOTPLoader: boolean = false;
    isOTPRequested: boolean = false;
    showThanksPage = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, protected usersService: UsersService, protected formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<WhatsappLessonSubscriptionPopupComponent>,
        protected socialService: AuthService) {
        if (environment.isMobile) {
            this.isMobile = true;
        }

        (function ($) {
            $.fn.randomize = function (childElem) {
                return this.each(function () {
                    var $this = $(this);
                    var elems = shuffle($(childElem));
                    $this.remove(childElem);
                    for (var i = 0; i < elems.length; i++)
                        $this.append(elems[i]);
                });
            }
        })(jQuery);

        jQuery(function ($) {
            $("div.random-mentor-profile").randomize("div.mentor-profile");
            $(".mentor-profile:gt(4)").hide();
        });

        function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }
    }

    createForm() {
      return this.formBuilder.group({
        firstName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        lastName: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        email: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.email]],
        phone: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      });
    }

    inCorrectName(): any {
      const nameRegexp = /^[A-Za-z]+$/;
      return nameRegexp.test(this.registerForm.controls.firstName.value) ? { "inCorrectName": false } : { "inCorrectName": true };
    }

    ngOnInit() {
      this.patchFormData();
    }

  patchFormData() {
    const formControls = this.registerForm.controls;
    if (this.data.firstName) {
      formControls.firstName.setValue(this.data.firstName);
    }
    if (this.data.lastName) {
      formControls.lastName.setValue(this.data.lastName);
    }
    if (this.data.email) {
      formControls.email.setValue(this.data.email);
    }
  }

    close() {
      this.dialogRef.close();
    }

    submit() {
      this.usersService.registerUserForWhatsAppLessons({
        firstName: this.registerForm.controls.firstName.value,
        lastName: this.registerForm.controls.lastName.value,
        email: this.registerForm.controls.email.value,
        phone: this.registerForm.controls.phone.value
      }).subscribe(() => {
        this.close();
      });
    }
}
