import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {
  canActivate(): boolean {
    if(this.usersService.isAdmin) {
      return true;
    }
    else {
      this.router.navigate(["/users"]);
      return false;
    }
  }

  constructor(private usersService: UsersService, private router: Router) { }
}
