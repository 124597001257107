import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {


  baseUrl: string = appSettings.authUrl;

  constructor(private http: HttpClient) { }
  getAllChallenge(pageNumber, searchString?, status?) {
    let url = this.baseUrl + "Challenge/GetAllChallenges?PageNumber=" + pageNumber;
    if (searchString) {
      url += "&ChallengeName=" + searchString
    }
    if (status) {
      url += "&Status=" + status
    }
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  exportChallenges(isExport, searchString?, status?) {
    var url = this.baseUrl + "Challenge/ChallengesExport?isExport=" + isExport;

    if (searchString) {
      url += "&ChallengeName=" + searchString
    }
    if (status) {
      url += "&Status=" + status
    }
    return this.http.get(url, { responseType: 'blob' });
  }
}
