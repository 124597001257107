import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef, AfterViewInit, HostListener,
} from "@angular/core";
import {
    FormBuilder,
    NgForm,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { ConfigsService } from "src/app/services/configs.service";
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
} from "@angular/cdk/drag-drop";
import { LessonsService } from "../../services/lessons.service";
import { appSettings } from "../../../environments/environment";
import { NFTMentorResponse } from "src/app/models/NFTMentor/nft-mentor-response";
import { MentorService } from "src/app/services/mentor.service";

@Component({
    selector: "edit-popup",
    templateUrl: "./edit-popup.component.html",
    styleUrls: ["./edit-popup.component.less"],
})
export class EditPopupComponent implements OnInit, OnDestroy, AfterViewInit {
    files: any;
    newFiles: File | null;
    uploadfile = "";
    imageFileFlag: boolean = false;

    nftMentorFiles: any;
    nftMentorNewFiles: File | null;
    nftMentorUploadfile = "";
    nftMentorImageFileFlag: boolean = false;

    nftMentorArtFiles: any;
    nftMentorArtNewFiles: File | null;
    nftMentorArtUploadfile = "";
    nftMentorArtImageFileFlag: boolean = false;

    nftMentorsArtFiles: any;
    nftMentorsArtNewFiles: File | null;
    nftMentorsArtUploadfile = "";
    nftMentorsArtImageFileFlag: boolean = false;

    nftMentorsArtV1Files: any;
    nftMentorsArtV1NewFiles: File | null;
    nftMentorsArtV1Uploadfile = "";
    nftMentorsArtV1ImageFileFlag: boolean = false;

    nftMentorsArtV2Files: any;
    nftMentorsArtV2NewFiles: File | null;
    nftMentorsArtV2Uploadfile = "";
    nftMentorsArtV2ImageFileFlag: boolean = false;

    nftMentorsArtV3Files: any;
    nftMentorsArtV3NewFiles: File | null;
    nftMentorsArtV3Uploadfile = "";
    nftMentorsArtV3ImageFileFlag: boolean = false;

    selectedSkill: any = null;
    featuredAnswers: any = [];
    imageStorageUrl: string = appSettings.imageStoreUrl;
    today = new Date();
    showTimePicker: boolean = true;
    showNftFields: boolean = false;

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Input("create_popup") create: any = [];
    @Input("select_user_popup") userGroup: any = [];
    @Input("success_popup") success: any = [];
    @Input("share_popup") share: any = [];
    @Input("report_popup") bulk_report: any = [];
    @Input("select_popup") select: any = [];
    @Input("error_popup") error: any = [];
    @Input("confirm") confirm_alert: any = [];
    @Input("nftCategoriesOptions") nftCategoriesOptions: any = [];

    public lottieConfig: Object;
    public lottieConfigSuccess: Object;

    @ViewChild('createForm', { static: true }) cForm: NgForm;

    private anim: any;
    private animationSpeed: number = 1;
    message;
    error_message = "";
    active_count = 0;
    fileName = "";
    form: NgForm;
    enableBreadcrumb = false;
    createElement;
    tabElement;
    dropdown_breadcrumb;
    userSelectedList = [];
    groupSelectedList = [];
    userGroupSelectedList = [];
    active_index = 0;
    companyLogo = "";
    sendData = [];
    blogArticles = [];
    nftMentor: NFTMentorResponse = new NFTMentorResponse();
    searchText = "";
    isSelectAll = true;
    selectedFile: File;
    isResolutionError: boolean = false;
    dragData = [];
    nftSelectedCategory = {};
    // menuActive = [];
    menuOptions = ['User', 'Industry', 'Onboarding', 'Media', 'NFT', 'White-Labelled'];
    menuActive = 0;

    constructor(
        private commonService: CommonService,
        private configsService: ConfigsService,
        private fb: FormBuilder,
        private ref: ChangeDetectorRef,
        protected route: ActivatedRoute,
        protected router: Router,
        private lessonService: LessonsService,
        private mentorService: MentorService,
    ) {
        // this.menuActive = ['3px', '0px', '0px', '0px', '0px'];
    }

    todayDate: Date = new Date();

    public model = {
        start_date: "",
        end_date: ""
    }

    ngOnInit() {
        if (this.success.length > 0) {
            this.postStatus(this.success);
        }
        this.lottieConfig = {
            path: "assets/images/warning2.json",
            autoplay: true,
            loop: false,
        };
        this.lottieConfigSuccess = {
            path: "assets/images/success_json.json",
            autoplay: true,
            loop: false,
        };
        this.message =
            "Your session has expired. <br/> Please login to proceed.";
        this.createElement = this.create;
        this.tabElement = this.userGroup;
        if (this.createElement) {
            this.getPopupApiList(this.createElement);
        }
        if (this.tabElement) {
            this.getPopupTabList(this.tabElement.tabFields, false);
        }
        this.sendData = [];
        this.blogArticles = [];

        if (this.create.createrName == 'globalBlogArticles') {
            let blogArticlesJSON = this.create.field.filter(item => {
                return item.key == 'blogArticlesJSON'
            });

            if (blogArticlesJSON && blogArticlesJSON.length > 0) {
                this.blogArticles = blogArticlesJSON[0].value;
            } else {
                this.blogArticles.push([]);
            }
        }

        if (this.create.createrName == 'updateMentorData') {
            let requiredField = this.create.field.filter(item => {
                return item.key == 'featuredAnswers'
            });

            let requiredField2 = this.create.field.filter(item => {
                return item.key == 'MediaJSON'
            });

            let requiredField3 = this.create.field.filter(item => {
                return item.key == 'onRequest'
            });

            let requiredField4 = this.create.field.filter(item => {
                return item.key == 'blogArticlesJSON'
            });

            let requiredField5 = this.create.field.filter(item => {
                return item.key == 'nftMentorJSON'
            });

            if (requiredField5 && requiredField5.length > 0) {
                this.nftMentor = requiredField5[0].value;
                if (this.nftMentor) {
                    this.showNftFields = this.nftMentor.isNFTMentor;
                    this.nftSelectedCategory = this.nftCategoriesOptions.filter(item => {
                        return item.value == this.nftMentor.nftCategory
                    })[0];
                } else {
                    this.nftMentor = new NFTMentorResponse();
                }
            }

            if (requiredField3 && requiredField3.length > 0) {
                this.showTimePicker = requiredField3[0].value;
            }

            if (requiredField && requiredField.length > 0) {
                this.sendData.push(requiredField[0].featuredAnswers);
            } else {
                this.sendData.push([]);
            }

            if (requiredField2 && requiredField2.length > 0) {
                this.sendData.push(requiredField2[0].value);
            } else {
                this.sendData.push([]);
            }

            if (requiredField4 && requiredField4.length > 0) {
                this.blogArticles = requiredField4[0].value;
            } else {
                this.blogArticles.push([]);
            }
        }

        if (this.create.createrName == 'updateMeeting') {
            this.showTimePicker = false;
        }

        if (this.create.createrName == 'updateBooking') {
            this.showTimePicker = false;
        }

        if (this.create.createrName == 'eventform') {
            let requiredField = this.create.field.filter(item => {
                return item.type == 'speaker_details'
            });

            if (requiredField && requiredField.length > 0) {
                if (!requiredField[0].value) {
                    this.sendData.push({});
                } else {
                    this.sendData = JSON.parse(requiredField[0].value);
                }
            } else {
                this.sendData.push({});
            }
        }
    }

    ngAfterViewInit() {
        var context = this;
        setTimeout(function () {
            if (context.create) {
                if (context.create.field && context.create.field[1]) {

                    let imageField = context.create.field.filter(item => {
                        return item.type == 'image_upload'
                    });

                    if (imageField && imageField.length > 0 && imageField[0].value) {
                        var image = document.getElementById("imageUploadBackground");

                        if (image) {
                            context.imageFileFlag = true;
                            image["src"] = imageField[0].value;
                            context.newFiles = new File([], "");
                        }

                    }

                    let nftMentorImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorImageField && nftMentorImageField.length > 0 && nftMentorImageField[0].value) {
                        var image = document.getElementById("nftMentorImageUploadBackground");

                        if (image && nftMentorImageField[0].value.nftMentorImage) {
                            context.nftMentorImageFileFlag = true;
                            image["src"] = nftMentorImageField[0].value.nftMentorImage;
                            context.nftMentorNewFiles = new File([], "");
                        }
                    }

                    let nftMentorArtImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorArtImageField && nftMentorArtImageField.length > 0 && nftMentorArtImageField[0].value) {
                        var image = document.getElementById("nftMentorArtImageUploadBackground");

                        if (image && nftMentorArtImageField[0].value.nftMentorArtWork) {
                            context.nftMentorArtImageFileFlag = true;
                            image["src"] = nftMentorArtImageField[0].value.nftMentorArtWork;
                            context.nftMentorArtNewFiles = new File([], "");
                        }
                    }

                    let nftMentorsArtImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorsArtImageField && nftMentorsArtImageField.length > 0 && nftMentorsArtImageField[0].value) {
                        var image = document.getElementById("nftMentorsArtImageUploadBackground");

                        if (image && nftMentorsArtImageField[0].value.nftMentorsArtWork) {
                            context.nftMentorsArtImageFileFlag = true;
                            image["src"] = nftMentorsArtImageField[0].value.nftMentorsArtWork;
                            context.nftMentorsArtNewFiles = new File([], "");
                        }
                    }

                    let nftMentorsArtV1ImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorsArtV1ImageField && nftMentorsArtV1ImageField.length > 0 && nftMentorsArtV1ImageField[0].value) {
                        var image = document.getElementById("nftMentorsArtV1ImageUploadBackground");

                        if (image && nftMentorsArtV1ImageField[0].value.nftMentorsArtV1Work) {
                            context.nftMentorsArtV1ImageFileFlag = true;
                            image["src"] = nftMentorsArtV1ImageField[0].value.nftMentorsArtV1Work;
                            context.nftMentorsArtV1NewFiles = new File([], "");
                        }
                    }

                    let nftMentorsArtV2ImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorsArtV2ImageField && nftMentorsArtV2ImageField.length > 0 && nftMentorsArtV2ImageField[0].value) {
                        var image = document.getElementById("nftMentorsArtV2ImageUploadBackground");

                        if (image && nftMentorsArtV2ImageField[0].value.nftMentorsArtV2Work) {
                            context.nftMentorsArtV2ImageFileFlag = true;
                            image["src"] = nftMentorsArtV2ImageField[0].value.nftMentorsArtV2Work;
                            context.nftMentorsArtV2NewFiles = new File([], "");
                        }
                    }

                    let nftMentorsArtV3ImageField = context.create.field.filter(item => {
                        return item.type == 'nft_mentor'
                    });

                    if (nftMentorsArtV3ImageField && nftMentorsArtV3ImageField.length > 0 && nftMentorsArtV3ImageField[0].value) {
                        var image = document.getElementById("nftMentorsArtV3ImageUploadBackground");

                        if (image && nftMentorsArtV3ImageField[0].value.nftMentorsArtV3Work) {
                            context.nftMentorsArtV3ImageFileFlag = true;
                            image["src"] = nftMentorsArtV3ImageField[0].value.nftMentorsArtV3Work;
                            context.nftMentorsArtV3NewFiles = new File([], "");
                        }
                    }
                }
            }
        },
            500);

        setTimeout(() => {
            if (this.create.createrName == "updateCommunicationsData") {
                let ele = document.querySelector<HTMLInputElement>('.ui-dialog-content');
                if (ele) {
                    ele.style.overflow = "visible";
                }
            } else {
                let ele = document.querySelector<HTMLInputElement>('.ui-dialog-content');
                if (ele) {
                    ele.style.overflow = "";
                }
            }
        }, 200)
    }

    ngOnDestroy() {
        this.ref.detach();
    }

    onFileDropped($event, field) {
        console.log("event", $event);
        this.prepareFilesList($event, field);
    }

    fileBrowseHandler(files, field) {
        console.log("files", files);
        this.prepareFilesList(files[0], field);
    }

    prepareFilesList(file: any, field) {
        console.log("file", file);
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.newFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.newFiles = file;
                    this.imageFileFlag = true;
                    var image = document.getElementById("imageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    if (field.autoUpload) {
                        this.uploadImage(file, field);
                    }
                } else {
                    this.nftMentorFiles = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorFilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorNewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorFiles = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorNewFiles = file;
                    this.nftMentorImageFileFlag = true;
                    var image = document.getElementById("nftMentorImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorNewFiles, "nftMentor");
                } else {
                    this.nftMentorFiles = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorArtFilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorArtNewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorArtFiles = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorArtNewFiles = file;
                    this.nftMentorArtImageFileFlag = true;
                    var image = document.getElementById("nftMentorArtImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorArtNewFiles, "nftMentorArt");
                } else {
                    this.nftMentorArtFiles = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorsArtFilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorsArtNewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorsArtFiles = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorsArtNewFiles = file;
                    this.nftMentorsArtImageFileFlag = true;
                    var image = document.getElementById("nftMentorsArtImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorsArtNewFiles, "nftMentorsArt");
                } else {
                    this.nftMentorsArtFiles = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorsArtV1FilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorsArtV1NewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorsArtV1Files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorsArtV1NewFiles = file;
                    this.nftMentorsArtV1ImageFileFlag = true;
                    var image = document.getElementById("nftMentorsArtV1ImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorsArtV1NewFiles, "nftMentorsArtV1");
                } else {
                    this.nftMentorsArtV1Files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorsArtV2FilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorsArtV2NewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorsArtV2Files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorsArtV2NewFiles = file;
                    this.nftMentorsArtV2ImageFileFlag = true;
                    var image = document.getElementById("nftMentorsArtV2ImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorsArtV2NewFiles, "nftMentorsArtV2");
                } else {
                    this.nftMentorsArtV2Files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    prepareNFTMentorsArtV3FilesList(file: any, field) {
        let maxFileSize = 1024 * 1024 * 2;
        if (file.size > maxFileSize) {
            this.nftMentorsArtV3NewFiles = null;
            this.error = {
                display: true,
                message: "Maximum file size supported is 2mb",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            file.type != "image/jpeg" &&
            file.type != "image/png" &&
            file.type != "image/jpg"
        ) {
            this.nftMentorsArtV3Files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(file);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    this.nftMentorsArtV3NewFiles = file;
                    this.nftMentorsArtV3ImageFileFlag = true;
                    var image = document.getElementById("nftMentorsArtV3ImageUploadBackground");
                    image["src"] = URL.createObjectURL(file);
                    this.uploadNFTMentorImage(this.nftMentorsArtV3NewFiles, "nftMentorsArtV3");
                } else {
                    this.nftMentorsArtV3Files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    uploadImage(image: File, field) {
        image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });
        let images = [];
        images.push(image);
        this.lessonService.uploadSectionImages(images).subscribe(res => {
            this.create.field.filter(x => x.key == field.key)[0].value = this.imageStorageUrl + image.name;
        },
            err => {
            });
    }

    uploadNFTMentorImage(image: File, nftMentorFileType: string) {
        this.configsService.loading(true);
        image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });
        let images = [];
        images.push(image);
        this.mentorService.uploadNFTMentorImages(images).subscribe(res => {
            if (nftMentorFileType === "nftMentorArt") {
                this.nftMentor.nftMentorArtWork = res.result[0];
            } else if (nftMentorFileType === "nftMentorsArt") {
                this.nftMentor.nftMentorsArtWork = res.result[0];
            } else if (nftMentorFileType === "nftMentorsArtV1") {
                this.nftMentor.nftMentorsArtV1Work = res.result[0];
            } else if (nftMentorFileType === "nftMentorsArtV2") {
                this.nftMentor.nftMentorsArtV2Work = res.result[0];
            } else if (nftMentorFileType === "nftMentorsArtV3") {
                this.nftMentor.nftMentorsArtV3Work = res.result[0];
            } else {
                this.nftMentor.nftMentorImage = res.result[0];
            }
            this.configsService.loading(false);
        },
            err => {
                this.configsService.loading(false);
            });
    }

    getTimestamp() {
        let currentDate = new Date();
        return currentDate.getUTCFullYear().toString() + currentDate.getUTCMonth().toString() + currentDate.getUTCDate().toString()
            + currentDate.getUTCHours().toString() + currentDate.getUTCMinutes().toString() + currentDate.getUTCSeconds().toString();
    }

    onImageLoad(event) {
        let height = event.currentTarget.naturalHeight;
        let width = event.currentTarget.naturalWidth;

        if (height * width < 500 * 500) {
            this.isResolutionError = true;
        } else
            this.isResolutionError = false;
    }

    AddUserViaEmail(event, fields, form) {
        if (event != null) {
            fields.map((f, i) => {
                if (event != "") {
                    f.required = false;
                } else {
                    f.required = "required";
                }
            });
        }
    }

    getPopupApiList(data) {
        if (data.field) {
            data.field.map((f, i) => {
                if (f.selectApi) {
                    f.options = [];
                    if (f.paramsNeeded) {
                        for (var key in f.paramsNeeded) {
                            if (f.paramsNeeded[key]) {
                                f.selectApi =
                                    f.selectApi + "?" + key + "=" + f.paramsNeeded[key];
                            } else {
                                f.selectApi = f.selectApi;
                            }
                        }
                    }
                    // f.options=[{name: "Leadership", id: "736b3d13-0d2b-4964-b77f-d74b150e3601"}];
                    this.configsService.loading(true);
                    this.commonService.getCommonApi(f.selectApi, "get", "").subscribe(
                        (data) => {
                            if (f.key == "skill") {
                                // data.result.map(skill=>{
                                //   f.options.push({name:"skill.lessonCategoryName",id:skill.lessonCategoryId})
                                // })
                                f.options = data.result;
                            } else {
                                f.options = data.result.list;
                            }

                            if (f.key == "CompanySkillType") {
                                if (f.value) {
                                    this.sendData = f.value;
                                    let data = [];
                                    f.value.map((f, i) => {
                                        if (f.skillSubType) {
                                            f.skillSubType.map((m, n) => {
                                                if (m) {
                                                    data.push(m);
                                                }
                                            });
                                        }
                                    });
                                    f.options.map((f, i) => {
                                        this.sendData.map((x, y) => {
                                            if (x) {
                                                if (x.skillTypeId == f.skillTypeId) {
                                                    f.isChecked = true;
                                                }
                                            }
                                        });
                                    });
                                    f.value = [];
                                    f.value = data;
                                }
                            }
                        },
                        (err) => {
                            this.configsService.loading(false);
                        },
                        () => {
                            this.configsService.loading(false);
                        }
                    );
                }
            });
        }
    }

    getPopupTabList(data, isSingle) {
        if (data) {
            data.map((f, i) => {
                if (f.api) {
                    this.configsService.loading(true);
                    let query: any = [];
                    let tabList = [];
                    query[f.queryId] = f.queryData;
                    this.commonService
                        .getListAlone(f.api, this.commonService.encodeQueryData(query))
                        .subscribe(
                            (res) => {
                                tabList = [];
                                tabList = res.result.list.map((x) => {

                                    if (x.users) {
                                        x.users.map((f) => {
                                            f['name'] = f.userName;
                                            f['id'] = f.userId;
                                            f['mail'] = f.userEmail;
                                            f['groupId'] = x.groupId;
                                            f['key'] = 'group-user';
                                        });
                                    }

                                    return {
                                        name: x[f.keyName],
                                        id: x[f.keyId],
                                        mail: x[f.keyMail],
                                        key: f.key,
                                        totalmembers: x[f.keyMembers],
                                        isChecked: false,
                                        users: x.users ? x.users : [],
                                    };
                                });
                                f.list = tabList;
                                this.handleTabChange(0, this.tabElement.tabFields, this.tabElement);
                                this.UGcheckSelectAll(f);
                            },
                            (err) => {
                                this.configsService.loading(false);
                            },
                            () => {
                                this.configsService.loading(false);
                            }
                        );
                }
            });
        }
    }

    InOutList(event, list, mainList) {
        let c = 0;
        mainList.map((f, i) => {
            if (f.id == list.id) {
                if (event) {
                    c = 1;
                } else {
                    mainList.splice(i, 1);
                }
            }
        });
        if (!c && event) {
            mainList.push(list);
        }
    }

    userGroupSelection(event, list, tab, selectAll) {
        list.isChecked = event;
        if (event) {
            if (list.key == 'user') {
                this.InOutList(event, list, tab.value);

            } else if (list.key == 'group') {
                list.users.map((x, y) => {
                    x['isChecked'] = list.isChecked;
                    this.InOutList(event, x, tab.value);
                });
                this.checkGroupCount(list);
            } else if (list.key == 'group-user') {
                if (tab.groupList) {
                    tab.groupList.users.map((x, y) => {
                        if (x.id == list.id) {
                            x['isChecked'] = list.isChecked;
                            this.InOutList(event, x, tab.groupTab.value);
                        }
                    });
                }
            }
        } else {
            if (list.key == 'user') {
                this.InOutList(event, list, tab.value);
            }
            if (list.key == 'group') {
                list.users.map((x, y) => {
                    x['isChecked'] = list.isChecked;
                    this.InOutList(event, x, tab.value);
                });
                this.checkGroupCount(list);
            } else if (list.key == 'group-user') {
                if (tab.groupList) {
                    tab.groupList.users.map((x, y) => {
                        if (x.id == list.id) {
                            x['isChecked'] = list.isChecked;
                            this.InOutList(event, x, tab.groupTab.value);
                        }
                    });
                }
            }
        }
        this.UGcheckSelectAll(tab);
    }

    UGcheckSelectAll(tab) {
        if (tab.list.length) {
            let c = 0;
            tab.list.map((f, i) => {
                if (f.isChecked) {
                    c = c + 1;
                }
                if (c == tab.list.length) {
                    tab.isSelectAll = true;
                } else {
                    tab.isSelectAll = false;
                }
            });
        }
    }

    removeNftMentorFiles() {
        this.nftMentorFiles = "";
        this.nftMentorUploadfile = "";
        this.nftMentorNewFiles = null;
        this.nftMentorImageFileFlag = false;
        this.nftMentor.nftMentorImage = "";
    }

    removeNftMentorArtFiles() {
        this.nftMentorArtFiles = "";
        this.nftMentorArtUploadfile = "";
        this.nftMentorArtNewFiles = null;
        this.nftMentorArtImageFileFlag = false;
        this.nftMentor.nftMentorArtWork = "";
    }

    removeNftMentorsArtFiles() {
        this.nftMentorsArtFiles = "";
        this.nftMentorsArtUploadfile = "";
        this.nftMentorsArtNewFiles = null;
        this.nftMentorsArtImageFileFlag = false;
        this.nftMentor.nftMentorsArtWork = "";
    }

    removeNftMentorsArtV1Files() {
        this.nftMentorsArtV1Files = "";
        this.nftMentorsArtV1Uploadfile = "";
        this.nftMentorsArtV1NewFiles = null;
        this.nftMentorsArtV1ImageFileFlag = false;
        this.nftMentor.nftMentorsArtV1Work = "";
    }

    removeNftMentorsArtV2Files() {
        this.nftMentorsArtV2Files = "";
        this.nftMentorsArtV2Uploadfile = "";
        this.nftMentorsArtV2NewFiles = null;
        this.nftMentorsArtV2ImageFileFlag = false;
        this.nftMentor.nftMentorsArtV2Work = "";
    }

    removeNftMentorsArtV3Files() {
        this.nftMentorsArtV3Files = "";
        this.nftMentorsArtV3Uploadfile = "";
        this.nftMentorsArtV3NewFiles = null;
        this.nftMentorsArtV3ImageFileFlag = false;
        this.nftMentor.nftMentorsArtV3Work = "";
    }

    removeFiles() {
        this.files = "";
        this.uploadfile = "";
        this.newFiles = null;
        this.imageFileFlag = false;
    }

    /** CallBack Fucntion
     -- @params result --
     **/

    postStatus(result) {
        if (!result.value.hasError) {
            this.success = {
                display: true,
                message: result.value.message,
                buttonPositive: "Okay",
                positiveButton: () => {
                    this.success.display = false;
                },
            };
        } else {
        }
    }

    loadFile(event) {
        let maxFileSize = 1024 * 1024 * 2;
        if (event.target.files[0].size > maxFileSize) {
            this.files = "";
            this.error = {
                display: true,
                message: "Maximum file size supported is 2MB",
            };
            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }
        } else if (
            event.target.files[0].type != "image/jpeg" &&
            event.target.files[0].type != "image/png" &&
            event.target.files[0].type != "image/jpg"
        ) {
            this.files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };
            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }
        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(event.target.files[0]);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    var image = document.getElementById("uploadImage");
                    image["src"] = URL.createObjectURL(event.target.files[0]);
                    this.companyLogo = event.target.files[0].name;
                    this.selectedFile = event.target.files[0];
                    this.commonService
                        .fileUpload("Company/Upload", this.selectedFile, true)
                        .subscribe(
                            (data) => {
                                this.postStatus(data);
                            },
                            (err) => {
                                this.configsService.loading(false);
                            },
                            () => {
                                this.configsService.loading(false);
                            }
                        );
                } else {
                    this.files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };
                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }

                }
            }
        }
    }

    loadFileSpeaker(event, i) {
        let maxFileSize = 1024 * 1024 * 2;
        if (event.target.files[0].size > maxFileSize) {
            this.files = "";
            this.error = {
                display: true,
                message: "Maximum file size supported is 2MB",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            event.target.files[0].type != "image/jpeg" &&
            event.target.files[0].type != "image/png" &&
            event.target.files[0].type != "image/jpg"
        ) {
            this.files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(event.target.files[0]);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    var image = document.getElementById("uploadImageSp-" + (i + 1));
                    image["src"] = URL.createObjectURL(event.target.files[0]);

                    let imageObj = new File([event.target.files[0]], event.target.files[0].name.split('.')[0] + this.getTimestamp() + '.' + event.target.files[0].name.split('.')[1], { type: event.target.files[0].type });
                    let images = [];
                    images.push(imageObj);
                    this.configsService.loading(true);
                    this.lessonService.uploadSectionImages(images).subscribe(res => {
                        this.sendData[i].image = this.imageStorageUrl + imageObj.name;
                    },
                        err => {
                            this.sendData[i].image = '';
                        },
                        () => {
                            this.configsService.loading(false);
                        });
                } else {
                    this.files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    loadFileBlogArticles(event, i) {
        let maxFileSize = 1024 * 1024 * 2;
        if (event.target.files[0].size > maxFileSize) {
            this.files = "";
            this.error = {
                display: true,
                message: "Maximum file size supported is 2MB",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else if (
            event.target.files[0].type != "image/jpeg" &&
            event.target.files[0].type != "image/png" &&
            event.target.files[0].type != "image/jpg"
        ) {
            this.files = "";
            this.error = {
                display: true,
                message: "Type of file must be - .png or .jpg",
            };

            if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                this.cForm.controls.imageFile.setValue('');
            }

        } else {
            const imageCheck = new Image();
            imageCheck.src = URL.createObjectURL(event.target.files[0]);
            imageCheck.onload = (e: any) => {
                const height = e.currentTarget.height;
                const width = e.currentTarget.width;

                if (height >= 500 && width >= 500) {
                    var image = document.getElementById("uploadImageSp-" + (i + 1));
                    image["src"] = URL.createObjectURL(event.target.files[0]);

                    let imageObj = new File([event.target.files[0]], event.target.files[0].name.split('.')[0] + this.getTimestamp() + '.' + event.target.files[0].name.split('.')[1], { type: event.target.files[0].type });
                    let images = [];
                    images.push(imageObj);
                    this.configsService.loading(true);
                    this.lessonService.uploadSectionImages(images).subscribe(res => {
                        this.blogArticles[i].image = this.imageStorageUrl + imageObj.name;
                    },
                        err => {
                            this.blogArticles[i].image = '';
                        },
                        () => {
                            this.configsService.loading(false);
                        });
                } else {
                    this.files = "";
                    this.error = {
                        display: true,
                        message: "Minimum file resolution should be 500 X 500.",
                    };

                    if (this.cForm && this.cForm.form && this.cForm.controls && this.cForm.controls.imageFile) {
                        this.cForm.controls.imageFile.setValue('');
                    }
                }
            }
        }
    }

    removeSpeakerDetails(i) {
        if (i === 0 && this.sendData.length == 1) {
            this.sendData[i] = {};
        } else {
            this.sendData.splice(i, 1);
        }
    }

    addSpeakerDetails() {
        this.sendData.push({});
    }

    removeBlogArticles(i) {
        if (i === 0 && this.blogArticles.length == 1) {
            this.blogArticles[i] = {};
        } else {
            this.blogArticles.splice(i, 1);
        }
    }

    addBlogArticles() {
        this.blogArticles.push({});
    }

    loadBulkFile(event, popupData) {
        let maxFileSize = 1024 * 1024 * 10;
        if (event.target.files[0].size > maxFileSize) {
            this.uploadfile = "";
            this.error = {
                display: true,
                message: "Maximum file size supported is 10 MB",
            };
        } else if (
            event.target.files[0].type !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            event.target.files[0].type != "application/vnd.ms-excel"
        ) {
            this.uploadfile = "";
            this.error = { display: true, message: "Invalid File Type" };
        } else {
            this.configsService.loading(true);
            let bulkFile = event.target.files[0];
            let url = popupData.bulkInfo.url + popupData.bulkInfo.queryId;
            this.commonService.fileUpload(url, bulkFile, true).subscribe(
                (data) => {
                    this.bulkUploadResult(data);
                    popupData.refreshData();
                },
                (err) => {
                    this.error = { display: true, message: err.error.errors[0] };
                    this.configsService.loading(false);
                },
                () => {
                    this.configsService.loading(false);
                }
            );
        }
    }

    bulkUploadResult(result) {
        if (!result.value.hasError) {
            if (!result.value.result) {
                this.error = { display: true, message: result.value.message };
            } else {
                this.create.display = false;
                this.uploadfile = "";
                this.bulk_report.display = true;
                this.bulk_report["count_list"] = [];
                let errorData = [];
                for (var key in result.value.result) {
                    this.bulk_report["count_list"].push({
                        label: key,
                        value: result.value.result[key],
                    });
                    if (key == "errorData") {
                        errorData = result.value.result[key];
                    }
                }
                if (errorData) {
                    let data = [];
                    errorData.map((f, i) => {
                        if (f) {
                            data.push({ name: f.split(",")[0], message: f.split(",")[1] });
                        }
                    });
                    this.bulk_report["errorData"] = data;
                }
            }
        } else {
            this.error = true;
            // this.error_popup.display = true;
        }
    }

    backToOptions(field) {
        // console.log("Dd",field);
        field.innerListEnable = false;
        field.innerOptions = [];
        field.bindLabel = "skillTypeName";
        // console.log("Dd",field,this.sendData);

    }

    openSkillsDrop(data, field) {
        // console.log("Ddd");
        //this.sendData = data;
        field.options.map((f, i) => {
            this.sendData.map((x, y) => {
                if (x.skillTypeId == f.skillTypeId) {
                    f.skillSubType.map((m, n) => {
                        m.isChecked = false;
                        x.skillSubType.map((k, l) => {
                            if (k.lessonCategoryId == m.lessonCategoryId) {
                                m.isChecked = true;
                            }
                        });
                    });
                }
            });
        });
    }

    selectAllInnerSkills(field) {
        // console.log(field);
        if (field.innerOptions) {
            field.options.map((f, i) => {
                if (f.skillTypeId == field.innerOptions.skillTypeId) {
                    field.innerOptions["selectAll"] = true;
                    f.skillSubType.map((x, y) => {
                        x.isChecked = true;
                    });
                    this.sendData.map((l, m) => {
                        if (l.skillTypeId == f.skillTypeId) {
                            l.skillSubType = [];
                            l.skillSubType = f.skillSubType;
                        }
                    });
                }
            });
        }
    }

    UnselectAllInnerSkills(field) {
        // console.log(field);
        if (field.innerOptions) {
            field.options.map((f, i) => {
                if (f.skillTypeId == field.innerOptions.skillTypeId) {
                    field.innerOptions["selectAll"] = false;
                    f.skillSubType.map((x, y) => {
                        x.isChecked = false;
                    });
                    this.sendData.map((l, m) => {
                        // console.log(this.sendData,l);
                        if (l.skillTypeId == f.skillTypeId) {
                            l.skillSubType = [];
                            this.sendData.splice(m, 1);
                            this.clearSkill(l, field);
                        }
                    });
                    // console.log(this.sendData);
                }
            });
        }
    }

    individualSelect(skill) {
        // console.log("ddddd");
        if (skill.innerOptions.skillSubType) {
            let c = 0;
            skill.innerOptions.skillSubType.map((f, i) => {
                if (!f.isChecked) {
                    c = 0;
                } else {
                    c = c + 1;
                }
            });

            if (c == skill.innerOptions.skillSubType.length) {
                skill.innerOptions['selectAll'] = true;
            } else {
                skill.innerOptions['selectAll'] = false;
            }

        }

    }

    skillCheckBox(item) {
        item.isChecked = !item.isChecked;
    }

    addinnerSkills(e, field) {
        //e['isChecked'] = !e.isChecked;
        // console.log(e, field);
        let innerOptions = [];
        if (field.innerOptions) {

            e["isChecked"] = !e.isChecked;
            if (!field.innerListEnable && field.innerOptions.length == 0) {
                field.options.map((f, i) => {
                    if (f.skillTypeId == e.skillTypeId) {
                        field.value = [];
                        field.value.push(e);
                        innerOptions = e;
                        this.dropdown_breadcrumb = f.skillTypeName;
                    }
                });
                if (innerOptions) {
                    field.innerOptions = [];
                    field.innerOptions = innerOptions;
                    field.bindLabel = "lessonCategoryName";
                    field.innerListEnable = true;
                } else {
                    field.innerListEnable = false;
                    field.innerOptions = [];
                }
            }
        }
        if (field.innerOptions) {
            field.value.map((f, i) => {
                if (f.skillTypeId) {
                    // console.log("one1");
                    f.isChecked = true;
                }
                if (!f.isChecked) {
                    // console.log("one");
                    this.sendData.map((x, y) => {
                        if (x.skillSubType) {
                            x.skillSubType.map((l, m) => {
                                if (l.lessonCategoryId == f.lessonCategoryId) {
                                    x.skillSubType.splice(m, 1);
                                }
                            });
                        }
                    });
                }
                if (this.sendData.length > 0) {
                    let inC = 0;
                    this.sendData.map((k, j) => {
                        if (field.innerOptions.skillTypeId == k.skillTypeId) {
                            if (k.skillSubType.length > 0) {
                                let insert = 0;
                                k.skillSubType.map((m, n) => {
                                    if (m.lessonCategoryId != f.lessonCategoryId) {
                                        insert = insert + 1;
                                    }
                                    if (
                                        insert == k.skillSubType.length &&
                                        f.lessonCategoryId &&
                                        f.isChecked
                                    ) {
                                        k.skillSubType.push({
                                            lessonCategoryId: f.lessonCategoryId,
                                            lessonCategoryName: f.lessonCategoryName,
                                        });
                                    }
                                });
                            } else {
                                if (f.lessonCategoryId && f.isChecked) {
                                    k.skillSubType.push({
                                        lessonCategoryId: f.lessonCategoryId,
                                        lessonCategoryName: f.lessonCategoryName,
                                    });
                                }
                            }
                        } else {
                            inC = inC + 1;
                            if (inC == this.sendData.length) {
                                this.sendData.push({
                                    skillTypeId: field.innerOptions.skillTypeId,
                                    skillTypeName: field.innerOptions.skillTypeName,
                                    skillSubType: [],
                                });
                            }
                        }
                    });
                } else {
                    this.sendData.push({
                        skillTypeId: field.innerOptions.skillTypeId,
                        skillTypeName: field.innerOptions.skillTypeName,
                        skillSubType: [],
                    });
                }
            });
        }
        // console.log("addinnerSkills", e, field, this.sendData);
        this.individualSelect(field);
    }

    removeinnerSkills(e, field) {
        // console.log(this.sendData, "removeinnerSkills", e, field);
        e.value.isChecked = false;

        if (e.value) {
            if (e.value.skillTypeId) {
                e.value.isChecked = true;
            }
        }
        if (field.innerOptions) {
            this.sendData.map((f, i) => {
                if (f.skillTypeId == field.innerOptions.skillTypeId) {
                    if (f.skillSubType) {
                        f.skillSubType.map((m, n) => {
                            if (m.lessonCategoryId == e.value.lessonCategoryId) {
                                f.skillSubType.splice(n, 1);
                            }
                        });
                    }
                }
            });
        }
        this.individualSelect(field);
    }

    clearSkill(item, field) {
        // console.log(item,field);
        this.sendData.map((f, i) => {
            if (f.skillTypeId == item.skillTypeId) {
                this.sendData.splice(i, 1);
            }
        });
        if (field.options) {
            field.options.map((x, y) => {
                if (x.skillTypeId == item.skillTypeId) {
                    x.isChecked = false;
                    x.skillSubType.map((m, n) => {
                        m.isChecked = false;
                    });
                }
            });
        }
    }

    clearSkillAll(data, field) {
        // console.log("clearSkillAll", data, field);
        field.value = [];
        this.sendData = [];
        field.options.map((x, y) => {
            x.isChecked = false;
            x.skillSubType.map((m, n) => {
                m.isChecked = false;
            });
        });
    }

    getSkillClassList(event, isChecked) {
        // console.log(event,isChecked);
        if (isChecked) {
            if (event.target.parentNode.classList) {
            }
        }
    }

    addDropdownItem(e, field) {
        if (e) {
            e.isChecked = true;

            if (field.key == "nftMentorJSON") {
                this.nftMentor.nftCategory = e.value;
            } else if (field.key == "skill_level") {
                this.create.field.map((subSkill) => {
                    if (subSkill.key == "lessons") {
                        subSkill.lessons = e.lesson;
                        subSkill.isShow = true;
                    }
                });
            } else {
                this.create.field.map((subSkill) => {
                    if (subSkill.key == field.key && field.multiple) {
                        if (subSkill.value == undefined || subSkill.value == null) {
                            subSkill.value = [];
                            subSkill.value.push(e.id);
                        } else {
                            subSkill.value.map((f, i) => {
                                if (f.id) {
                                    subSkill.value.splice(i, 1, f.id.toString());
                                }
                            });
                        }
                    } else if (
                        subSkill.key == field.key &&
                        !field.multiple &&
                        field.isdependent
                    ) {
                        let parentUrl =
                            field.selectApi.split("?")[0] + "/" + e.lessonCategoryId;
                        this.updateRelatedField(parentUrl, field);
                    }
                });
            }
        } else {
            this.create.field.map((subSkill) => {
                if (subSkill.key == field.dependentParam) {
                    if (subSkill.key == "lessons") {
                        subSkill.lessons = [];
                        subSkill.isShow = false;
                    } else {
                        subSkill.value = [];
                        subSkill.options = [];
                    }
                }
                // this.create.field.map((subSkill)=>{
                //   if(subSkill.key=="skill_level"){
                //     subSkill.options=[];
                //   }
                //   if(subSkill.key=="lessons"){
                //     subSkill.lessons=[];
                //     subSkill.isShow = false;
                //   }
            });
        }
    }

    updateRelatedField(url, parentField) {
        this.configsService.loading(true);
        /*if (parentField.apiUrl == 'Partner')
        {
          parentField.selectApi = parentField.value[0]['id'];
        }*/
        this.commonService.getCommonApi(url, "get", "").subscribe(
            (data) => {
                this.create.field.map((subSkill) => {
                    if (subSkill.key == parentField.dependentParam) {
                        subSkill.value = [];
                        // data.result.lessonGroup.map((level,index)=>{
                        //   subSkill.options.push({"title":"level"+(index+1),lessons:level});
                        // });
                        var groups = data.result.lessons.reduce(function (obj, item) {
                            obj[item.level] = obj[item.level] || [];
                            obj[item.level].push(item);
                            return obj;
                        }, {});
                        var groupedArray = Object.keys(groups).map(function (key) {
                            return { name: "level" + key, lesson: groups[key] };
                        });
                        subSkill.options = groupedArray;
                    }
                });
            },
            (err) => {
                this.configsService.loading(false);
            },
            () => {
                this.configsService.loading(false);
            }
        );
    }

    removeDropdownItem(e, field) {
        e.value.isChecked = false;
        this.create.field.map((subSkill) => {
            if (subSkill.key == field.key) {
                subSkill.value.map((skill, index) => {
                    subSkill.value.splice(index, 1, skill.id.toString());
                    if (skill.id == e.value.id) {
                        subSkill.value.splice(index, 1);
                    }
                });
            }
        });
    }

    handleTabChange(index, tab, tabData) {
        this.active_index = index;
        tab[index]["isSelectAll"] = tab[index]["isSelectAll"];
        if (tab[index].list) {
            let c = 0;
            tab[index].list.map((f, i) => {
                if (f.key == 'group') {
                    if (f.users) {
                        let c = 0;
                        tab[index].value.map((g, i) => {
                            f.users.map((x, y) => {
                                if (g.id == x.id) {
                                    c = c + 1;
                                    x.isChecked = true;
                                }
                                if (c == f.users.length) {
                                    f.isChecked = true;
                                }
                            });
                        });
                    }
                    this.checkGroupCount(f);
                } else if (f.key == 'user') {
                    tab[index].value.map((g, i) => {
                        if (g.id == f.id) {
                            f.isChecked = true;
                        }
                    });
                }
            });
        }
        this.UGcheckSelectAll(tab[index]);
    }

    checkGroupCount(item) {
        let selected = 0;
        item.users.map((x, y) => {
            if (x.isChecked) {
                selected = selected + 1;
            }
        });
        item['selectedCount'] = selected;
    }

    selectToggle(isSelect, tab) {
        this.isSelectAll = isSelect;
        tab["isSelectAll"] = !isSelect;
        if (tab.list) {
            tab.list.map((f, i) => {
                f.isChecked = isSelect;
                this.userGroupSelection(!isSelect, f, tab, !isSelect);
            })
        }
    }

    copyToClipboard(val: string) {
        let selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
    }

    changeSwitchType(key, value) {
        if (this.create.createrName == "updateMentorData" && key == "onRequest") {
            this.showTimePicker = value;
        }
    }

    changeNftSwitchType(ent) {
        if (this.create.createrName == "updateMentorData") {
            this.showNftFields = ent;
        }
    }

    activeCount(data) {
        this.active_count = data;
    }

    isValidationRequiredForBlogArticles(blogArticles) {
        let response = false;
        blogArticles.forEach(element => {
            if ((element.title || element.linkToArticle || element.image)) {
                response = true;
            }
        });
        return response;
    }

    createrCall(createForm: any, sendData, companyLogo, blogArticles, nftMentor) {
        if (this.create.createrName === "CreateSkillForm" || this.create.createrName === "updateGoalsData" || this.create.createrName === "updateMentorData") {
            createForm.value.imageFile = this.newFiles;
        }

        if (this.create.field.filter(x => x.key == 'imageFile').length > 0
            && this.create.field.filter(x => x.key == 'imageFile')[0].value) {
            createForm.value.image = this.create.field.filter(x => x.key == 'imageFile')[0].value;
        }

        if (this.create.createrName == 'updateMentorData') {
            let timePickerStart = this.create.field.filter(x => x.type == 'time_picker_start');
            let timePickerEnd = this.create.field.filter(x => x.type == 'time_picker_end');

            if (timePickerStart.length > 0 && timePickerEnd.length > 0) {
                if (timePickerStart[0].value.hour > timePickerEnd[0].value.hour) {
                    this.error = {
                        display: true,
                        message: "Office Start time should be less then end time"
                    };
                    return;
                }

                if (timePickerStart[0].value.hour == timePickerEnd[0].value.hour && timePickerStart[0].value.minute > timePickerEnd[0].value.minute) {
                    this.error = {
                        display: true,
                        message: "Office Start time should be less then end time"
                    };
                    return;
                }
            }

            if (this.blogArticles && this.blogArticles.length > 0 && this.isValidationRequiredForBlogArticles(this.blogArticles)) {
                let message = '';
                let index = 1;
                this.blogArticles.forEach(item => {
                    if (!item.title || !item.title.trim()) {
                        message = "Blog Articles " + index + " title is required.";
                        index++;
                        return false;
                    }

                    if (!item.linkToArticle || !item.linkToArticle.trim()) {
                        message = "Blog Articles " + index + " link to article is required.";
                        index++;
                        return false;
                    }

                    if (!this.validateUrl(item.linkToArticle)) {
                        message = "Blog Articles " + index + " must be a url.";
                        index++;
                        return false;
                    }

                    if (!item.image) {
                        message = "Blog Articles " + index + " image is required.";
                        index++;
                        return false;
                    }

                    item.title = item.title.trim();
                    item.linkToArticle = item.linkToArticle.trim();
                    index++;
                });


                if (message) {
                    this.error = {
                        display: true,
                        message: message,
                    };
                    return;
                }
            }

            if (this.nftMentor && this.nftMentor.isNFTMentor) {
                let message = '';

                if (!this.nftMentor.nftMentorImage) {
                    message = "NFT mentor image is not selected.";
                }

                if (!this.nftMentor.nftMentorArtWork) {
                    message = "NFT mentor art work image is not selected.";
                }

                if (!this.nftMentor.nftCategory) {
                    message = "NFT category is not selected.";
                }

                if (!this.nftMentor.nftDescription) {
                    message = "NFT description field cannot be empty.";
                }

                if (!this.nftMentor.nftMentorGalleryURL) {
                    message = "NFT mentor gallery URL cannot be empty.";
                } else if (!this.validateUrl(this.nftMentor.nftMentorGalleryURL)) {
                    message = "NFT mentor gallery value must be a url.";
                }

                if (message) {
                    this.error = {
                        display: true,
                        message: message,
                    };
                    return;
                }
            }

            let mediaJSON = this.create.field.filter(x => x.key == 'MediaJSON')[0].value;
            if (mediaJSON && mediaJSON.length > 0 && mediaJSON[0].value !== '') {
                let message = '';
                mediaJSON.forEach(element => {
                    if (!this.matchYoutubeUrl(element.value)) {
                        message = "Talk Shows value must be a valid youtube url.";
                    }
                });
                if (message) {
                    this.error = {
                        display: true,
                        message: message,
                    };
                    return;
                }
            }
        }

        if (this.create.createrName == 'updateMeeting') {
            let timePickerStart = this.create.field.filter(x => x.type == 'time_picker_start');
            let timePickerEnd = this.create.field.filter(x => x.type == 'time_picker_end');
            let date = this.create.field.filter(x => x.type == 'date_picker_date');

            if (timePickerStart.length > 0 && timePickerEnd.length > 0) {
                if (timePickerStart[0].value.hour > timePickerEnd[0].value.hour) {
                    this.error = {
                        display: true,
                        message: "Start time should be less then end time"
                    };
                    return;
                }

                if (timePickerStart[0].value.hour == timePickerEnd[0].value.hour && timePickerStart[0].value.minute > timePickerEnd[0].value.minute) {
                    this.error = {
                        display: true,
                        message: "Start time should be less then end time"
                    };
                    return;
                }
            }

            let todayDate = new Date().getTime();
            let startDate = new Date(date[0].value).getTime();
            if (todayDate > startDate) {
                this.error = {
                    display: true,
                    message: "Scheduled Date must be greater than today date.",
                };
                return;
            }
        }

        if (this.create.createrName == 'updateBooking') {
            let date = this.create.field.filter(x => x.type == 'date_picker_date');
            let todayDate = new Date().getTime();
            let startDate = new Date(date[0].value).getTime();
            if (todayDate > startDate) {
                this.error = {
                    display: true,
                    message: "Scheduled Date must be greater than today date.",
                };
                return;
            }
        }

        if (this.create.createrName == 'globalBlogArticles') {
            if (this.blogArticles && this.blogArticles.length > 0 && this.isValidationRequiredForBlogArticles(this.blogArticles)) {
                let message = '';
                let index = 1;
                this.blogArticles.forEach(item => {
                    if (!item.title || !item.title.trim()) {
                        message = "Blog Articles " + index + " title is required.";
                        index++;
                        return false;
                    }

                    if (!item.linkToArticle || !item.linkToArticle.trim()) {
                        message = "Blog Articles " + index + " link to article is required.";
                        index++;
                        return false;
                    }

                    if (!this.validateUrl(item.linkToArticle)) {
                        message = "Blog Articles " + index + " must be a url.";
                        index++;
                        return false;
                    }

                    if (!item.image) {
                        message = "Blog Articles " + index + " image is required.";
                        index++;
                        return false;
                    }

                    item.title = item.title.trim();
                    item.linkToArticle = item.linkToArticle.trim();
                    index++;
                });


                if (message) {
                    this.error = {
                        display: true,
                        message: message,
                    };
                    return;
                }
            }
        }

        if (this.create.createrName == 'eventform') {
            let timePickerStart = this.create.field.filter(x => x.type == 'date_time_picker_start');
            let timePickerEnd = this.create.field.filter(x => x.type == 'date_time_picker_end');


            let todayDate = new Date().getTime();
            let startDate = new Date(timePickerStart[0].value).getTime();
            if (todayDate > startDate && !this.create.isUpdate) {
                this.error = {
                    display: true,
                    message: "Start Date and time must be greater than today date and time.",
                };
                return;
            }

            if (new Date(timePickerEnd[0].value).getTime() < new Date(timePickerStart[0].value).getTime()) {
                this.error = {
                    display: true,
                    message: "Start Date must be less than end date.",
                };
                return;
            }

            if (this.sendData && this.sendData.length > 0) {
                let message = '';
                let index = 1;

                this.sendData.forEach(item => {
                    if (!item.name || !item.name.trim()) {
                        message = "Speaker " + index + " name is required.";
                        return false;
                    }

                    if (!item.designation || !item.name.trim()) {
                        message = "Speaker " + index + " designation is required.";
                        return false;
                    }

                    if (!item.image) {
                        message = "Speaker " + index + " image is required.";
                        return false;
                    }

                    item.name = item.name.trim();
                    item.designation = item.designation.trim();

                    index++;
                });


                if (message) {
                    this.error = {
                        display: true,
                        message: message,
                    };
                    return;
                }
            }
        }

        this.create.createrCall(createForm, sendData, companyLogo, blogArticles, nftMentor);
    }

    memberGroup(list, field, tab) {
        this.enableBreadcrumb = true;
        let listUsers = [];
        if (list.users) {
            list.users.map((f) => {
                f.isChecked = f.isChecked ? f.isChecked : list.isChecked;
            });
        }

        let members = [
            {
                label: "Members",
                api: "Groups/user",
                queryId: "groupId",
                queryData: list.id,
                key: "group-user",
                keyName: "userName",
                keyMail: "userEmail",
                keyId: "userId",
                groupTab: tab,
                groupList: list,
                list: list.users ? list.users : [],
            },
        ];
        field.splice(1, 0, members[0]);
        this.UGcheckSelectAll(members[0]);
    }

    backToGroup(field, item) {
        field.map((x, y) => {
            if (x.key == 'group') {
                x.list.map((m, n) => {
                    if (m.users) {
                        let c = 0;
                        m.users.map((l, k) => {
                            if (l.isChecked) {
                                c = c + 1;
                            }
                        });
                        if (c == m.users.length) {
                            m.isChecked = true;
                        } else {
                            m.isChecked = false;
                        }
                    }
                    this.checkGroupCount(m);
                });
                this.UGcheckSelectAll(x);
            }
        });

        setTimeout(() => {
            this.enableBreadcrumb = false;
            field.splice(1, 1);
        }, 10);
    }

    customValidation(validateInput, allData, formData) {
        let validQuery;
        if (
            validateInput.customValidation &&
            validateInput.customValidationData != validateInput.value
        ) {
            allData.map((data) => {
                if (data.key == validateInput.validParams) {
                    validQuery =
                        validateInput.validUrl + data.value + "/" + validateInput.value;
                    this.commonService.getListAlone(validQuery).subscribe((data) => {
                        if (data.value) {
                            if (!data.value.result) {
                                validateInput.isError = true;
                                formData.controls[validateInput.key].status = "INVALID";
                                // this.error.display=true;
                                // this.error.message = data.value.message;
                                validateInput.errorMsg = data.value.message;
                            } else {
                                validateInput.isError = false;
                                validateInput.errorMsg = "";
                            }
                        }
                    });
                }
            });
        } else {
            validateInput.isError = false;
            validateInput.errorMsg = "";
        }
    }

    closeRedirect(url) {

        this.share.display = false;
        //setTimeout(() => {

        // if (!this.ref['destroyed']) {
        //   this.ref.detectChanges();
        // }
        //if (this.ref && !(this.ref as ViewRef).destroyed) {
        //this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //this.router.navigate([currentUrl]);
        //this.ref.detectChanges();
        // });
        //}
        // }, 250);
        if (!this.ref['destroyed']) {
            this.ref.detectChanges();
            window.location.href = url;
        }

        //this.router.navigate([url]);
    }

    /**
     [Drag and Drop Event listener]
     @param {Dropped Element}
     **/
    drop(event: CdkDragDrop<any[]>, create) {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        this.dragData = event.container.data;
        let data = [];
        if (create.value.length) {
            event.container.data.map((f, i) => {
                if (f) {
                    create.value.map((x, y) => {
                        if (x.lessonId == f.lessonId) {
                            data.push(f);
                        }
                    });
                }
            });
            create["value"] = data;
        }
    }

    validateDate(field) {
        if (field.value !== "" && field.value < this.model.start_date) {
            this.model_errors.end_date.display = true;
            this.model_errors.end_date.message = "End date cannot be less than start date";
        } else {
            this.model_errors.end_date.display = false;
            this.model_errors.end_date.message = "";
        }
    }

    validateStartDate(field) {
        let context = this;
        var endDate = context.create.field[1].value;

        if (field.value === undefined || field.value === "") {
            this.model_errors.start_date.display = true;
            this.model_errors.start_date.message = "Start date cannot be empty";
        } else if (field.value !== "" && field.value > endDate && field.value.toLocaleDateString() !== endDate.toLocaleDateString()) {
            this.model_errors.start_date.display = true;
            this.model_errors.start_date.message = "Start date cannot be greater than end date";
        } else {
            this.model.start_date = field.value;
            this.model_errors.start_date.display = false;
            this.model_errors.start_date.message = "";
        }
    }

    public model_errors = {
        start_date: { display: false, message: "" },
        end_date: { display: false, message: "" }
    }

    goToLogin() {
        // localStorage.clear();
        // setTimeout(() => {
        //   location.reload();
        // }, 1000);
    }

    onFeaturedAnswerSkillChange(ent, field) {
        if (field.featuredAnswers && field.featuredAnswers.length > 0) {
            this.featuredAnswers = this.sendData[0].filter(item => {
                return item.skillId == ent.skillId
            });
        }
    }

    removeFeaturedAnswer(fa, field) {
        if (field.featuredAnswers && field.featuredAnswers.length > 0) {
            this.sendData[0] = this.sendData[0].filter(item => {
                return item.sectionId !== fa.sectionId
            });

            this.featuredAnswers = this.sendData[0].filter(item => {
                return item.skillId == this.selectedSkill
            });
        }
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    clearDropDownItems(event, field) {
        if (field.options) {
            field.options.forEach(x => {
                x.isChecked = false;
            })
        }
    }

    clearNFTMentorDropDown(event) {
        this.nftMentor.nftCategory = 0;
    }

    validateUrl(url) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    }

    matchYoutubeUrl(url) {
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
            return true
        }
        return false;
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        console.log('Back button pressed');
        this.create.closeModal();
    }

    setActiveMenuIndex(index: number) {
        // for (let i = 0; i < this.menuActive.length; i++) {
        //     this.menuActive[i] = i === index ? '3px' : '0px';
        // }
        this.menuActive = index;
    }
}
