import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { CompaniesService } from "src/app/services/companies.service";
import { GleacColumnDef } from "../data-grid/data-grid.model";
import { ConfigsService } from "../../services/configs.service";
import { CommonService } from "../../services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { appSettings } from "src/environments/environment";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { UsersService } from "src/app/services/users.service";

@Component({
  selector: "app-companies",
  templateUrl: "./companies.component.html",
  styleUrls: ["./companies.component.less"],
})
export class CompaniesComponent implements OnInit {
  page_title = "Companies";
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  isconfirmPopup = false;
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    buttonPositive: "",
    field: [],
    closeModal: function () { },
    createrCall: function (form, skillData, uploadFileName) { },
  };
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () { },
  };
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () { },
    negativeButton: function () { },
  };
  error_popup = {};
  filter: string = "ALL";
  pageNumber: number = 1;
  pageLimit: number = 10;
  searchString = "leadership";
  filterArray: any = [
    {
      label: "Status ",
      type: "static_select",
      key: "status",
      options: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Deactive" },
      ],
    },
  ];

  form;
  companiesGridData: any;
  currentTime: Date;
  imageStorageUrl: string = appSettings.lessonCampaignImageStoreUrl;
  editImageUrl;
  companiesColumnData: GleacColumnDef[] = [
    {
      displayName: "Company Name",
      data: "companyName",
      isExpandable: true,
      render: (data, row) => {
        return `<div class="company_section"><img class="logo" width="29px" src="${this.imageStorageUrl + row.companyLogo
          }"/><span class="comanyname ml-2">${row.companyName}</span></div>`;
      },
    },
    {
      displayName: "Lesson Limits",
      data: "usedCredits",
      render: (data, row) => {
        var completePercentage = (row.usedCredits / row.totalCredits) * 100;
        return `<div class="" style="display: table-caption;overflow:hidden" ><div class="user_credits_outer" style="width:100px !important; ">
        <div style="width:${completePercentage}%; height: 8px; background: linear-gradient(0deg, #24CDB9, #24CDB9)"></div>
        </div>
        <div class="user_credits" style="display:inline-block;">${row.usedCredits}/${row.totalCredits} Used</div></div>`;
      },
    },
    { displayName: "Primary Contact", data: "primaryContactName" },
    { displayName: "Email", data: "primaryContactEmail", isClickable: true },
    { displayName: "Allowed Credits", data: "totalCredits", isVisible: false },
    {
      displayName: "Created On",
      data: "onboardingDateTime",
      render: (data, row) => {
        var onboardedDateTime = new Date(row.onboardingDateTime);
        return `${onboardedDateTime.getDate()} ${onboardedDateTime.toLocaleString(
          "default",
          { month: "long" }
        )} ${onboardedDateTime.getFullYear()}`;
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        //{label:'View Users',functionType: 'users'},
        //{label:'View Groups',functionType: 'groups'},
        //{label:'View Campaigns',functionType: 'campaigns'},
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        },
      ],
    },
  ];
  url = "";
  status = "";
  constructor(
    private commonService: CommonService,
    private compainesService: CompaniesService,
    private configsService: ConfigsService,
    private changeDetectorRef: ChangeDetectorRef,
    protected router: Router,
    private usersService: UsersService
  ) {
    this.getCompanyList();
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
    let breadCrumsArray = [
      {
        pathName: "Company",
        pathUrl: this.router.url,
      },
    ];
    this.configsService.setBreadcrumb(breadCrumsArray);
  }

  getCompanyList() {
    this.configsService.loading(true);
    this.url = "Company/";
    this.compainesService
      .getCompanyDataGrid(
        this.searchString,
        this.pageNumber,
        this.pageLimit,
        this.status
      )
      .subscribe(
        (data) => {
          // console.log(data.result.list.length,data.result.list.totalCount)
          if (data.result.list.length <= 0 && data.result.list.totalCount != 0 && this.pageNumber > 1) {
            --this.pageNumber;
            this.getCompanyList();
          } else {
            this.companiesGridData = data.result;
            console.log("companies data", this.companiesGridData);
          }

        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getCompanyList();
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.configsService.loading(true);
    this.compainesService
      .getCompanyDataGrid(
        this.searchString,
        this.pageNumber,
        this.pageLimit,
        this.status
      )
      .subscribe(
        (data) => {
          this.companiesGridData = data.result;
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  customFilter(params) {
    let queryParam;
    let query;
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        queryParam = data[key];
      }
    } else {
      // delete queryParam.status;
      queryParam = params[0];
    }
    this.status = queryParam;
    this.getCompanyList();
  }

  changeSearchText(event) { }

  editCompany(event) {
    if (event.action == "edit") {
      event.data["editImageUrl"] =
        this.imageStorageUrl + event.data.companyLogo;
      this.addCompany(event.data);
    } else if (event.action == "users") {
      this.companyUsers(event);
    } else if (event.action == "groups") {
      this.companyGroup(event);
    } else if (event.action == "campaigns") {
      this.companyCampaign(event);
    } else if (event.action == "delete") {
      this.deleteCompany(event.data.companyId);
    }
  }

  /** Add Company
   -- @params Company Details --**/

  addCompany(data) {
    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: false,
      title: data ? "Edit Company" : "Create Company",
      buttonPositive: data ? "Update" : "Create",
      field: [
        {
          label: "Organization Id",
          key: "organizationId",
          placeholder: "Enter Organization Id",
          type: "input",
          innertype: "text",
          required: "required",
          value: data ? data.organizationId : "",
        },
        {
          label: "Company Name",
          key: "companyName",
          placeholder: "Enter the company name",
          type: "input",
          innertype: "text",
          required: "required",
          value: data ? data.companyName : "",
        },
        {
          label: "Benchmark Credits",
          key: "benchmarkCredits",
          placeholder: "Enter the amount of credits",
          type: "input",
          innertype: "number",
          min: 0,
          pattern: "[0-9]+",
          required: "required",
          value: data ? data.benchmarkCredits : "",
        },
        {
          label: "Primary Contact Name",
          key: "primaryContactName",
          placeholder: "Enter the primary user name",
          type: "input",
          innertype: "text",
          required: "required",
          pattern: "^[a-zA-Z_ ]*$",
          value: data ? data.primaryContactName : "",
        },
        {
          label: "Primary Contact Email ID",
          key: "primaryContactEmail",
          placeholder: "Enter the primary contact email ID",
          type: "input",
          innertype: "email",
          required: "required",
          pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
          value: data ? data.primaryContactEmail : "",
        },
        {
          label: "Skill Types",
          key: "CompanySkillType",
          placeholder: "Select the skill types",
          type: "innerdropdown",
          bindLabel: "skillTypeName",
          innerListEnable: false,
          multiple: "true",
          selectApi: "SkillType",
          value: data ? data.compneySkillType : [],
          innerOptions: [],
          required: "required",
        },
        // {label:'Skill Types',key:"SkillTypeId",placeholder:'Select the skill types',type:'dropdown',multiple:'true',value:[],options:[
        //     {name: 'GENERIC' , id: 0 },
        //     {name: 'INDUSTRY' , id: 1 },
        //     {name: 'CUSTOM' , id: 2 },
        //     {name: 'TRIAL' , id: 3 }
        // ]},
        {
          label: "Company Status",
          key: "isActive",
          placeholder: "",
          type: "button_select",
          options: [
            { label: "Active", value: true },
            { label: "InActive", value: false },
          ],
          required: "required",
          value: data ? data.isActive : true,
        },
        {
          label: "Number of users",
          key: "userCredits",
          placeholder: "Enter the allowed number of users",
          type: "input",
          innertype: "number",
          min: 0,
          max: 6,
          pattern: "^[0-9]{1,7}$",
          required: "required",
          value: data ? data.userCredits : "",
          errorMsg: "Enter valid number of users below 1000000"
        },
        {
          label: "Lesson Limit",
          key: "lessionCredits",
          placeholder: "Enter the allowed number of lessons",
          type: "input",
          innertype: "number",
          min: 0,
          max: 6,
          pattern: "^[0-9]{1,7}$",
          required: "required",
          value: data ? data.lessionCredits : "",
          errorMsg: "Enter valid lesson limits below 1000000"
        },
        {
          label: "Company Logo",
          key: "companyLogo",
          placeholder:
            "Maximum file size supported is 2MB, and type of file must be - .png or .jpg. Minimum resolution is 300 X 300.",
          type: "image_upload",
          required: "required",
          value: data ? data.companyLogo : "",
          valueUrl: data ? data.editImageUrl : "",
        },
      ],
      closeModal: () => {
        this.iscreatePopup = false;
      },
      createrCall: (form: NgForm, skillData, uploadedFileName) => {
        console.log(skillData, form);
        let isSubskill = false;
        isSubskill = skillData.some((skill) => {
          return skill.skillSubType.length > 0;
        });
        console.log(isSubskill);
        if (!isSubskill) {
          form.form.controls["CompanySkillType"].setErrors({ incorrect: true });
        }
        if (form.valid) {
          this.configsService.loading(true);
          this.form = form;
          this.currentTime = new Date();
          let ListData = [];
          ListData = { ...form.value };
          this.iscreatePopup = false;
          ListData["CompanySkillType"] = [];
          if (data) {
            ListData["companyId"] = data.companyId;
            ListData["CompanySkillType"] = skillData ? skillData : [];
            ListData["companyLogo"] = uploadedFileName
              ? uploadedFileName
              : data.companyLogo;
            this.commonService.updateData(this.url, ListData, false).subscribe(
              (data) => {
                this.postStatus(data);
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
              }
            );
          } else {
            ListData["CompanySkillType"] = skillData ? skillData : [];
            ListData["companyLogo"] = uploadedFileName;
            ListData["benchmarkCredits"] = Number(ListData["benchmarkCredits"]);
            this.commonService.createData(this.url, ListData, false).subscribe(
              (data) => {
                this.postStatus(data);
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
              }
            );
          }
        }
      },
    };
  }

  companyUsers(event) {
    this.router.navigate(["companies/users/" + event.data.companyId]);
  }
  companyGroup(event) {
    this.router.navigate(["companies/groups/" + event.data.companyId]);
  }
  companyCampaign(event) {
    this.router.navigate(["companies/campaign/" + event.data.companyId]);
  }

  companyDetails(event) {
    this.router.navigate(["companies/users/" + event.data.companyId]);
  }

  /** CallBack Fucntion
   -- @params result --
  **/

  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.getCompanyList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  /** Delete Company
   -- @params Company ID --
  **/

  deleteCompany(cId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.configsService.loading(true);
        let url = "Company?companyId=";
        this.commonService.deleteData(url, cId).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
        // this.configService.invokeDataPassing(this.list);
      },
    };
  }
}
