import { Component, ElementRef, HostListener, Injectable, OnInit, ViewChild } from '@angular/core';
import { CandidateLessonService } from 'src/app/services/candidate-lesson.service';
import {
  CandidateLessonAnswer,
  Lesson,
  LessonReviewAnswer,
  PeerAnswer,
  QuestionType,
  Section,
  SectionElement,
  SectionElementAnswer,
  SectionElementType,
  Discussions
} from '../common/common.model';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { SeoService } from 'src/app/services/seo.service';
import { ConfigsService } from '../../services/configs.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LessonFinishedComponent } from 'src/app/dialogs/lesson-finished/lesson-finished.component';
import { TimerComponent } from '../common/timer/timer.component';
import { LessonsService } from 'src/app/services/lessons.service';
import { MentorService } from 'src/app/services/mentor.service';
import { LoginPopupComponent } from 'src/app/dialogs/login-popup/login-popup.component';
import { UserLessonRating } from 'src/app/dialogs/answer-popup/answer.model';
import { LocationStrategy } from '@angular/common';
import { environment } from "../../../environments/environment";
import { Track } from "ngx-audio-player";
import { OnPageHidden, OnPageVisible } from "angular-page-visibility";
import { OpenAiService } from 'src/app/services/open-ai.service';
import { ChatCompletionsRequest } from 'src/app/models/open-ai/chat-completions-request';
import { ChatCompletionsResponse } from 'src/app/models/open-ai/chat-completions-response';
import { Message } from 'src/app/models/open-ai/message';
import { DemoPopupComponent } from 'src/app/dialogs/demo-popup/demo-popup.component';
import { WhatsappLessonSubscriptionPopupComponent } from 'src/app/dialogs/whatsapp-lesson-subscription-popup/whatsapp-lesson-subscription-popup.component';
import { PostDiscussionRequest } from 'src/app/models/requests/post-comment-request';
import { MenuItem } from 'primeng/api';
import { MatTooltipModule } from "@angular/material";
import { appSettings } from '../../../environments/environment';

declare var jQuery: any;

@Component({
  selector: 'app-candidate-lesson',
  templateUrl: './candidate-lesson.component.html',
  styleUrls: ['./candidate-lesson.component.less']
})

@Injectable({
  providedIn: 'root'
})

export class CandidateLessonComponent implements OnInit {

  mentorsUrl = environment.production ? "https://mentors.gleac.com" : "http://mentors.gleactest.com";
  @ViewChild('audio_player', { static: false })
  audioPlayer: any;
  introMenuActive = 0;
  lessonTabIndex = 0;
  sectionActive = 0;
  isFirstClick: boolean = false;
  isSongPlaying = false;
  showCongratsPage = false;
  sectionImage = '';
  sectionVideo = '';
  randomLessons = [];
  industries = [];
  mentors = [];
  streak = 0;
  searchLessonsQuery = '';
  menuShown = false;
  skills: any[] = ["Critical Thinking", "Creativity", "Leadership", "Communication", "Judgement & Decision Making", "Mindfulness", "Emotional Intelligence", "Collaboration", "Self-Direction", "Entrepreneurship"];
  mentorAnswerLikes: any[];
  leftMenuItems = ['Getting started', 'Self review', 'Review', 'Congratulations'];
  volumeMenuItems: MenuItem[] = [
    {
      label: 'Play music',
      command: () => {
        this.playSong();
      }
    },
    {
      label: 'Mute this time',
      command: () => {
        this.pauseSong();
        localStorage.setItem('muteAlways', '0');
      }
    },
    {
      label: 'Mute always',
      command: () => {
        this.muteAlways();
      }
    }
  ];
  isVolumeMenuShown = false;

  msaapPlaylist: Track[] = [
    {
      title: 'Audio One Title',
      link: 'https://gleac-assets.s3.us-east-2.amazonaws.com/audio/lesson-background.mp3',
      artist: 'Audio One Artist',
      duration: 186
    }
  ];


  issuccessPopup = false;
  success_popup = {
    display: false,
    message: '',
    buttonPositive: '',
    positiveButton: function () {
    }
  };
  iserrorPopup = false;
  error_popup = {
    display: false,
    message: '',
    positiveButton: function () {
    }
  };
  answersForm: FormGroup = new FormGroup({});
  hideButtons: boolean = false;
  lessonRemainingTime: number = 10;
  candidateLesson: Lesson;
  currentSection: any;
  currentSectionIndex: number = 0;
  selfReviewDone: boolean = false;
  userAnswer: FormControl = new FormControl();
  lessonDone: boolean = false;
  routeSub: any;
  lessonId: string;
  candidateAnswer: CandidateLessonAnswer;
  candidateSectionAnswers: Map<string, Map<string, any>> = new Map();
  lessonFinished: boolean = false;
  candidateId: string;
  perfectAnswerPage: boolean = false;
  lessonIntroPage: boolean = true;
  errors: string[] = [];
  currentYear: number = new Date().getFullYear();
  showLessonEndDialog: boolean = true
  lessonEndDialog: MatDialogRef<LessonFinishedComponent>;
  loginPopup: MatDialogRef<LoginPopupComponent>;
  demoPopup: MatDialogRef<DemoPopupComponent>;
  whatsAppLessonSubscriptionPopup: MatDialogRef<WhatsappLessonSubscriptionPopupComponent>;
  timeRanOut: boolean = false;
  userAnswerDisabled: boolean = false;
  userEmailId: string;
  lessonUrl: string;
  candidateScore: number;
  lessonTime: number = 1800;
  @ViewChild("lessonTimer", { static: false }) lessonTimer: TimerComponent;
  companyId: string;
  peerAnswers: PeerAnswer[];
  showPeerAnswer: boolean = true;
  showLoader: boolean = false;
  activatedRoute;
  preAuth: boolean = false;
  userAnswerAll = '';
  answerPageErrors: string[];
  userName: string;
  ratingOneSelected: boolean = false;
  ratingTwoSelected: boolean = false;
  ratingThreeSelected: boolean = false;
  ratingFourSelected: boolean = false;
  ratingFiveSelected: boolean = false;
  ratingDone: boolean = false;
  joinTribeEmailSent: boolean = false;
  isShown: boolean = false;
  stars: number[] = [1, 2, 3, 4, 5];
  lessonRating: number;
  currentPlayerStatus: boolean;
  currentPlayerStatusVideo: boolean;
  totalPages = [];
  chatButtonVisible: boolean = true;
  chatWindowVisible: boolean = false;
  messages: Message[] = [
    {
      role: "system",
      content: "You are MentorGPT. A helpful AI that will assist learners. You return response of upto 3 sentences in html formatting and only one <br> tag is allowed consecutively."
    }
  ];
  newMessage: string = '';
  AiChatBotThinking: boolean = false;
  protected introMenu = ['Overview', 'Getting started', 'Learning concepts'];
  lessonTabs = ['Micro-practice', 'Community'];
  discussions: Discussions[];
  discussionString: string = '';
  taggedUserId: string | null = null;
  taggedUserName: string | null = null;
  showCommentBox: boolean = false;
  replyString: string = '';
  currentReplyId: number = null;
  isLoggedIn: boolean = true;
  hoverText: string = '';
  userIsRegisteredForDailyLessons: boolean = false;
  gleacWebsite = appSettings.gleacWebsite;
  @ViewChild('chatSection', { static: false }) chatSection: ElementRef;

  constructor(protected configsService: ConfigsService, protected candidateLessonService: CandidateLessonService, protected sanitizer: DomSanitizer,
    protected route: ActivatedRoute, protected usersService: UsersService, protected mentorService: MentorService, protected router: Router, protected formBuilder: FormBuilder,
    protected dialog: MatDialog, protected meta: Meta, protected skillService: LessonsService, protected seoService: SeoService, private locationStrategy: LocationStrategy, protected openAiService: OpenAiService, protected matToolTip: MatTooltipModule) {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    this.skills = this.skills.map(x => ({ title: x.toUpperCase(), checked: false }));
  }

  ngOnInit() {
    //var url = location.href;
    this.companyId = this.route.snapshot.queryParams["cid"];
    //this.seoService.updateTitle('Andrei Lucian Moraru - Register');

    //this.candidateId = this.usersService.currentUser.userId;
    this.route.queryParams.subscribe(params => {
      if (params["preauth"]) {
        localStorage.removeItem("GATk");
        localStorage.removeItem("GRTk");
        this.usersService.currentUser = null;
        this.preAuth = true;
        this.usersService.getTokenForPreAuth(params["preauth"]).subscribe(res => {
          localStorage.setItem("GATk", res.result.accessToken);
          localStorage.setItem("GRTk", res.result.refreshToken);

          let url = new URL(location.href);
          let search_params = url.searchParams;
          search_params.delete("preauth");
          window.location.replace(url.href);

        }, err => {
        })
      }
    })
    this.routeSub = this.route.params.subscribe(params => {
      if (params["id"]) {
        this.lessonUrl = params["id"];
        let lessonUrl: string = atob(params["id"]);
        this.lessonId = lessonUrl.substr(0, lessonUrl.indexOf("+"))
        this.userEmailId = lessonUrl.substr(lessonUrl.indexOf("+") + 1, lessonUrl.length - 1);
        this.configsService.loading(true);
        this.candidateLessonService.getLessonById(this.lessonId, this.userEmailId).subscribe(data => {
          if (data.hasError) {
            this.router.navigate(["invalid"]);
          }
          this.candidateLesson = data.result;
          this.leftMenuItems.splice(1, 0, ...this.candidateLesson.sections.map(x => x.sectionTitle));
          this.lessonRating = this.candidateLesson.lessonRating;
          if (this.candidateLesson) {
            this.seoService.updateTitle(this.candidateLesson.title);
            this.seoService.setSocialMediaTags(
              'http://communities.gleacreviews.com',
              this.candidateLesson.title,
              this.candidateLesson.lessonSummary,
              'share_image.png');
            //this.seoService.updateMeta(, this.candidateLesson.lessonSummary);
            // this.meta.updateTag({ name: 'title', content: this.candidateLesson.title });
            // this.meta.updateTag({ name: 'description', content: this.candidateLesson.lessonSummary });
            this.candidateLesson.sections = this.candidateLesson.sections.sort((x, y) => {
              return x.sectionOrder - y.sectionOrder;
            });
            this.candidateLesson.sections.forEach(x => x.isDone = false);
            this.candidateLesson.selfReviewQuestions.forEach(x => x.selectedValue = 0);
            this.skillService.getLessonCategoryDetailsById(this.candidateLesson.lessonCategoryId).subscribe(skill => {
              if (skill.result) {
                this.seoService.setSocialMediaTags(
                  'http://communities.gleacreviews.com',
                  this.candidateLesson.title,
                  this.candidateLesson.lessonSummary,
                  skill.result.imageSrc);
                //this.seoService.updateOgUrl(skill.result.imageSrc);
              }
            });
            this.totalPages = new Array(this.candidateLesson.sections.length + 4);
          }
        }, err => {
          this.configsService.loading(false);
          this.router.navigate(["invalid"]);
        }, () => {
          this.configsService.loading(false);
        });
      } else if (params["title"]) {
        let lessonTitle: string = params["title"];
        this.configsService.loading(true);
        this.candidateLessonService.getLessonIdByTitle(lessonTitle).subscribe(data => {
          if (data.hasError) {
            this.router.navigate(["invalid"]);
          }
          this.router.navigate(["lesson", btoa(data.result + "+d9525f3d-c521-44f8-8eba-1275cee34766").slice(0, -2)])
        }, err => {
          this.configsService.loading(false);
          this.router.navigate(["invalid"]);
        }, () => {
          this.configsService.loading(false);
        });
      } else if (params["lessonId"]) {
        this.lessonId = params["lessonId"];
        this.configsService.loading(true);
        this.candidateLessonService.getLesson(this.lessonId).subscribe(data => {
          if (data.hasError) {
            this.router.navigate(["invalid"]);
          }
          this.candidateLesson = data.result;
          this.leftMenuItems.splice(1, 0, ...this.candidateLesson.sections.map(x => x.sectionTitle));
          if (this.candidateLesson) {
            this.seoService.updateTitle(this.candidateLesson.title);
            this.seoService.setSocialMediaTags(
              'http://communities.gleacreviews.com',
              this.candidateLesson.title,
              this.candidateLesson.lessonSummary,
              'share_image.png');
            this.candidateLesson.sections = this.candidateLesson.sections.sort((x, y) => {
              return x.sectionOrder - y.sectionOrder;
            });
            this.candidateLesson.sections.forEach(x => x.isDone = false);
            this.candidateLesson.selfReviewQuestions.forEach(x => x.selectedValue = 0);
          }
        }, err => {
          this.configsService.loading(false);
          if (!this.preAuth) {
            this.router.navigate(["invalid"]);
          } else this.configsService.loading(true);
        }, () => {
          this.configsService.loading(false);
        });
      } else if (params["demoLessonId"]) {
        var lessonIdEncoded = params["demoLessonId"];
        this.lessonId = atob(lessonIdEncoded);
        this.configsService.loading(true);
        this.candidateLessonService.getDemoLesson(lessonIdEncoded).subscribe(data => {
          if (data.hasError) {
            this.router.navigate(["invalid"]);
          }
          this.candidateLesson = data.result;
          if (this.candidateLesson) {
            this.seoService.updateTitle(this.candidateLesson.title);
            this.seoService.setSocialMediaTags(
              'http://communities.gleacreviews.com',
              this.candidateLesson.title,
              this.candidateLesson.lessonSummary,
              'share_image.png');
            this.candidateLesson.sections = this.candidateLesson.sections.sort((x, y) => {
              return x.sectionOrder - y.sectionOrder;
            });
            this.candidateLesson.sections.forEach(x => x.isDone = false);
            this.candidateLesson.selfReviewQuestions.forEach(x => x.selectedValue = 0);
          }
        }, err => {
          this.configsService.loading(false);
          this.router.navigate(["invalid"]);
        }, () => {
          this.configsService.loading(false);
        });
      }
      if (!this.usersService.currentUser) {
        this.isLoggedIn = false;
        this.hoverText = "Please Login to Continue!";
      } else {
        this.isLoggedIn = true;
        this.hoverText = "";
      }
      this.updateRegistrationStatusForWhatsapp();
    });

    this.candidateAnswer = {
      userEmailId: undefined,
      candidateId: undefined,
      lessonId: this.lessonId,
      candidateSectionAnswers: [],
      selfReviewAnswers: [],
      lessonTimeInSeconds: 0,
      companyId: undefined
    };
    this.isShown = false;
  }

  playAudioFileEnded(event) {
    this.audioPlayer.play();
  }

  public getBasePlayer(): any {
    return this.audioPlayer.player.nativeElement;
  }

  public setPlayerVolume(value: number) {
    this.getBasePlayer().volume = value / 100
  }

  playSong() {
    this.audioPlayer.play();
    this.isSongPlaying = true;
    localStorage.setItem('muteAlways', '0');
  }

  pauseSong() {
    this.audioPlayer.player.nativeElement.pause();
    this.isSongPlaying = false;
  }

  pauseMusicOnVideo() {
    this.currentPlayerStatusVideo = this.audioPlayer && this.audioPlayer.isPlaying;
    this.pauseSong();
  }

  resumeMusicOnVideo() {
    if (this.currentPlayerStatusVideo) {
      this.playSong();
    }
    this.currentPlayerStatusVideo = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.pauseSong();
  }

  @OnPageVisible()
  whenPageVisible(): void {
    if (this.currentPlayerStatus && !this.currentPlayerStatusVideo) {
      this.playSong();
    }
    this.currentPlayerStatus = false;
  }

  @OnPageHidden()
  whenPageHidden(): void {
    this.currentPlayerStatus = this.audioPlayer && this.audioPlayer.isPlaying;
    this.pauseSong();
  }

  @HostListener('click')
  onClick(btn) {
    if (!this.isFirstClick) {
      this.isFirstClick = true;
      // this.audioPlayer.play();
      let muteAlways = localStorage.getItem('muteAlways');
      if (muteAlways && muteAlways === '1') {
        return;
      }
      this.playSong();
    }
  }

  nextSection() {
    let isValid: boolean = false;
    this.candidateLesson.sections[this.currentSectionIndex].sectionControls.forEach(control => {
      if (control.sectionAnswerControl) {
        control.sectionAnswerControl.markAllAsTouched();
      }
    });
    isValid = this.candidateLesson.sections[this.currentSectionIndex].sectionControls.filter(x => x.sectionAnswerControl).every(x => x.sectionAnswerControl.valid);
    if (!isValid && this.lessonTabIndex === 1) {
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Please answer all questions.",
        positiveButton: () => {
          this.error_popup.display = false;
        }
      }
      return;
    }
    if (isValid) {
      this.addUserAnswer();
      this.candidateLesson.sections[this.currentSectionIndex].isDone = true;
      this.currentSectionIndex = ++this.currentSectionIndex;
      this.setupSection(this.currentSectionIndex);
      window.scrollTo(0, 0);
      document.getElementById("lesson-main").scrollTop = 0;
      this.setActiveSectionIndex(this.sectionActive + 1);
    }
    let audio: HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = true;
    this.getAllComments();
  }

  showLessonValidationErrors() {
    this.errors = []
    if (this.userAnswer.errors) {
      this.errors.push("Please enter an answer.")
    } else if (!this.sectionOptionValidator()) {
      this.errors.push("Please select all options.")
    }
    setTimeout(() => {
      this.errors = [];
    }, 3500);
  }

  previousSection() {
    this.addUserAnswer();
    this.userAnswer.setValue('');
    if (this.candidateLesson.sections[this.currentSectionIndex]) {
      this.currentSectionIndex = --this.currentSectionIndex;
    } else {
      this.currentSectionIndex = this.candidateLesson.sections.length - 1;
    }
    this.setupSection(this.currentSectionIndex);
    let audio: HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = false;
    this.setActiveSectionIndex(this.sectionActive - 1);
    this.getAllComments();
  }

  setupSection(index: number) {
    this.getUserAnswer();
    this.currentSection = this.candidateLesson.sections[index];
    if (this.currentSection && this.currentSection.sectionControls) {

      let containsVideo = this.currentSection.sectionControls.filter(x => x.sectionElementType === SectionElementType.VIDEO_URL);

      if (containsVideo && containsVideo.length > 0) {
        setTimeout(() => {
          this.pauseMusicOnVideo();
        }, 500);
      } else {
        setTimeout(() => {
          this.resumeMusicOnVideo();
        }, 500);
      }

      this.currentSection.sectionControls.forEach(control => {
        if ((this.currentSection.questionType === QuestionType.Reordering)
          && control.sectionElementOptions.length > 0
        ) {
          let elements = JSON.parse(JSON.stringify(control.sectionElementOptions));
          control.sectionAnswerControl = this.formBuilder.control(elements, [Validators.required]);
        } else {
          switch (control.sectionElementType) {
            case SectionElementType.TEXTBOX_SINGLE:
            case SectionElementType.TEXTBOX_MULTI:
              control.sectionAnswerControl = this.formBuilder.control(control.sectionAnswer, [Validators.required]);
              break;
            case SectionElementType.SELECTION_BUTTONS_SINGLE:
              control.sectionAnswerControl = this.formBuilder.control(control.sectionAnswer, [Validators.required]);
              break;
            // case SectionElementType.SELECTION_BUTTONS_SINGLE:
            case SectionElementType.SELECTION_BUTTONS_MULTI:
              let options = control.sectionElementOptions.map(x => {
                return false
              })
              control.sectionAnswerControl = this.formBuilder.array(options, [this.requiredOneTrue.bind(this)]);
              break;
            case SectionElementType.IMAGE_URL:
            case SectionElementType.IMAGE_UPLOAD:
            case SectionElementType.VIDEO_URL:
              control.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(control.sectionElementContent);
              break;
            default:
              break;
          }
        }
      });
    } else {
      this.currentSection = this.candidateLesson.selfReviewQuestions;
      // this.selfReviewDone = true;
      this.resumeMusicOnVideo();
    }
    if (this.candidateLesson.sections.every(x => {
      if (x && x.isDone == true) {
        return true
      }
    }) && this.selfReviewDone) {
      this.lessonDone = true;
    } else {
      this.lessonDone = false;
    }
  }

  requiredOneTrue(control: FormArray) {
    if (control.controls.some(x => x.value))
      return null;
    else return { "required": true }
  }

  // isDisabled() {
  //   return this.userAnswerDisabled ? true : false
  // }

  goToSection(index: number) {
    if (this.candidateLesson.sections[index].isDone || this.selfReviewDone) {
      this.currentSectionIndex = index;
      this.setupSection(this.currentSectionIndex);
    }
  }

  sectionOptionValidator() {

    if ((this.currentSection as Section).sectionControls) {
      let optionControls: SectionElement[] = (this.currentSection as Section).sectionControls.filter(x => x.sectionElementType === SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType === SectionElementType.SELECTION_BUTTONS_MULTI);
      if (optionControls && optionControls.length > 0) {
        return optionControls.every(
          x => x.selectedOption !== undefined);
      }
    }
  }

  selectOption(event, i) {
    let sectionElement = (this.currentSection as Section).sectionControls[i];
    sectionElement.selectedOption = sectionElement.sectionElementOptions.find(x => x.trim() === event.srcElement.innerText);
    this.candidateLesson.sections[this.currentSectionIndex].userAnswer = sectionElement.selectedOption;
  }

  setupAnswerRequiredValidator() {
    this.userAnswer.setValidators([Validators.required]);
  }

  setupOptionRequiredValidator() {
    // this.sectionSelectValue.setValidators([Validators.required]);
  }

  disableAnswerRequiredValidator() {
    this.userAnswer.setValidators([Validators.required]);
  }

  disableValidators() {
    // this.sectionSelectValue.clearValidators();
    //this.userAnswer.clearValidators();
  }

  addUserAnswer() {
    let section = this.currentSection = this.candidateLesson.sections[this.currentSectionIndex];
    if (section && section.sectionControls) {
      var candidateSectionIndex = this.candidateAnswer.candidateSectionAnswers.findIndex(x => x.sectionId == section.sectionId)
      if (candidateSectionIndex !== -1) {
        this.candidateAnswer.candidateSectionAnswers.splice(candidateSectionIndex, 1);
      }
      let sectionElementAnswers: SectionElementAnswer[] = [];
      let answersMap: Map<string, any> = new Map();
      section.sectionControls.forEach(x => {
        if (x.sectionAnswerControl != undefined) {
          switch (x.sectionElementType) {
            case SectionElementType.SELECTION_BUTTONS_MULTI:
              var selectedOptions = x.sectionAnswerControl.value.map((y, i) => {
                if (y)
                  return i;
              }).filter(y => y !== undefined).map(y => x.sectionElementOptions[y]);
              answersMap.set(x.sectionElementId, selectedOptions);
              sectionElementAnswers.push({ sectionElementId: x.sectionElementId, answer: selectedOptions });
              break;
            case SectionElementType.SELECTION_BUTTONS_SINGLE:
            case SectionElementType.TEXTBOX_MULTI:
            case SectionElementType.TEXTBOX_SINGLE:
              answersMap.set(x.sectionElementId, x.sectionAnswerControl.value);
              sectionElementAnswers.push({ sectionElementId: x.sectionElementId, answer: x.sectionAnswerControl.value });
              break;
          }
        }
      })
      this.candidateAnswer.candidateSectionAnswers.push({
        sectionId: section.sectionId,
        sectionElementAnswers: sectionElementAnswers
      });
      this.candidateSectionAnswers.set(section.sectionId, answersMap);
    } else {
      this.candidateAnswer.selfReviewAnswers = this.candidateLesson.selfReviewQuestions.map(x => {
        let selfReviewAnswer: LessonReviewAnswer = { reviewQuestionId: x.questionId, reviewRating: x.selectedValue };
        return selfReviewAnswer;
      })
      this.candidateAnswer.lessonTimeInSeconds = this.lessonTime - this.lessonTimer.startTime
    }
  }

  getUserAnswer() {
    let section = this.currentSection = this.candidateLesson.sections[this.currentSectionIndex];
    if (this.currentSection) {
      var sectionsAnswers = this.candidateSectionAnswers.get(this.currentSection.sectionId);
      if (sectionsAnswers) {
        this.currentSection.sectionControls.forEach(x => {
          if (sectionsAnswers.has(x.sectionElementId)) {
            if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI) {
              setTimeout(() => {
                var selectedOptions: [] = sectionsAnswers.get(x.sectionElementId);
                // (x.sectionAnswerControl as FormArray).controls.forEach(x =>   {
                //   if (selectedOptions.some(y => y === x.value.text)) {
                //     x.value.selected.setValue(true);
                //   }
                // })
                var selectedOptionsIndex = selectedOptions.map(y => x.sectionElementOptions.indexOf(y));
                selectedOptionsIndex.forEach(y => (x.sectionAnswerControl as FormArray).controls[y].setValue(true))
              }, 10);
            } else
              setTimeout(() => {
                x.sectionAnswerControl.setValue(sectionsAnswers.get(x.sectionElementId));
              }, 10);
          }
        })
      }
    }
    this.isShown = true;
  }

  finishLesson() {
    this.addUserAnswer();
    this.lessonFinished = true;
    this.showLoader = true;
    this.lessonTimer.pause();
    if (!this.usersService.currentUser) {
      this.loginPopup = this.dialog.open(LoginPopupComponent, {
        width: '35vw',
        disableClose: true,
        autoFocus: false,
        data: {
          dataKey: this.timeRanOut
        },
        closeOnNavigation: false
      });
      this.loginPopup.afterClosed().subscribe(data => {
        this.sendAccessKeysToMentorsUrl();
        this.candidateAnswer.userEmailId = this.userEmailId;
        this.candidateAnswer.LessonCategoryId = this.candidateLesson.lessonCategoryId;
        this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
        this.candidateAnswer.companyId = this.usersService.currentUser.company;
        this.candidateLessonService.insertLessonAnswer(this.candidateAnswer).subscribe(res => {
          if (res.hasError) {
            this.showLoader = false;
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: res.errors[0],
              positiveButton: () => {
                this.error_popup.display = false;
                //this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
              }
            }
            this.answerPageErrors = res.errors;
            this.showLoader = false;
            this.configsService.loading(false);
          } else {
            this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(user => {
              this.userName = user.result.firstName + " " + user.result.lastName;
              this.usersService.currentUser.firstName = user.result.firstName;
              this.usersService.currentUser.lastName = user.result.lastName;
              this.usersService.currentUser.email = user.result.email;
              this.usersService.currentUser.company = user.result.company;
              this.isLoggedIn = true;
              this.updateRegistrationStatusForWhatsapp();
            });
            this.candidateScore = res.result.candidateAnswerScore.candidateScore;
            this.peerAnswers = res.result.peerAnswers;
            this.peerAnswers.forEach(element => {
              this.usersService.getUserDetails(element.candidateId).subscribe(name => {
                element.candidateName = name.result.firstName + " " + name.result.lastName;
                element.designation = name.result.designation;
                element.headline = name.result.headline;
                element.image = name.result.image;
                element.linkedInUrl = name.result.linkedinUrl;
              });
            });
            this.goToScoreSection();
            this.showLoader = false;
            this.configsService.loading(false);
            this.getMentorAnswerLikesByLesson();
            this.setActiveSectionIndex(this.sectionActive + 1);
          }
        },
          err => {
            this.showLoader = false;
            this.configsService.loading(false);
          });
      });
    } else {
      this.configsService.loading(true);
      this.candidateAnswer.userEmailId = this.userEmailId;
      this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
      this.candidateAnswer.companyId = this.usersService.currentUser.company;
      this.candidateAnswer.LessonCategoryId = this.candidateLesson.lessonCategoryId;
      this.candidateLessonService.insertLessonAnswer(this.candidateAnswer).subscribe(res => {
        if (res.hasError) {
          this.showLoader = false;
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {
            display: true,
            message: res.errors[0],
            positiveButton: () => {
              this.error_popup.display = false;
              //this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
            }
          }
          this.answerPageErrors = res.errors;
          this.showLoader = false;
          this.configsService.loading(false);
        } else {
          this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(user => {
            this.userName = user.result.firstName + " " + user.result.lastName;
          });
          this.candidateScore = res.result.candidateAnswerScore.candidateScore;
          this.peerAnswers = res.result.peerAnswers;
          this.peerAnswers.forEach(element => {
            this.usersService.getUserDetails(element.candidateId).subscribe(name => {
              element.candidateName = name.result.firstName + " " + name.result.lastName;
              element.designation = name.result.designation;
              element.headline = name.result.headline;
              element.image = name.result.image;
              element.linkedInUrl = name.result.linkedinUrl;
            });
          });
          if (!this.preAuth) {
            this.goToScoreSection();
          } else {
            this.hideButtons = true;
            if (res.result.hasCallbackUrl) {
              location.href = res.result.callbackUrl;
            }
          }
          this.showLoader = false;
          this.configsService.loading(false);
          this.getMentorAnswerLikesByLesson();
          this.setActiveSectionIndex(this.sectionActive + 1);
        }
      },
        err => {
          this.showLoader = false;
          this.configsService.loading(false);
        });
    }
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  goToScoreSection() {
    this.selfReviewDone = true;
    this.perfectAnswerPage = true;
    opener && opener.postMessage('{"lessonId":"' + this.lessonId + '"}', '*');
  }

  sanitizeHtml(html: string) {
    let newHtml;
    if (html.length > 0) {
      newHtml = this.sanitizer.bypassSecurityTrustHtml(html);
    } else {
      newHtml = 'Technical answer is not available for this section';
    }
    return newHtml;
  }

  sanitizeUrl(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  forceFinishLesson(event) {
    this.candidateLesson.sections.forEach(section => section.isDone = true);
    this.userAnswer.clearValidators();
    this.timeRanOut = true
    this.candidateAnswer.lessonTimeInSeconds = this.lessonTime;
    this.candidateAnswer.selfReviewAnswers = this.candidateLesson.selfReviewQuestions.map(x => {
      let selfReviewAnswer: LessonReviewAnswer = { reviewQuestionId: x.questionId, reviewRating: 0 };
      return selfReviewAnswer;
    })
    this.finishLesson();
    this.isShown = true;
  }

  startLesson(event) {
    this.setShowMA(this.candidateLesson);
    this.lessonIntroPage = false;
    this.setupSection(this.currentSectionIndex);
    window.scrollTo(0, 0);
    //this.userAnswer.enable();
    let audio: HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = true;
    this.setActiveSectionIndex(this.sectionActive + 1);
    this.getAllComments();
    this.getRandomMentors();
  }

  gotoDashboard(event) {
    this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
  }

  setRating(rating: number) {
    var userRating: UserLessonRating = {
      LessonId: this.lessonId,
      Rating: rating,
      UserId: this.usersService.currentUser.userId
    };
    this.skillService.saveLessonRating(userRating).subscribe(res => {
    });
    if (rating == 1) {
      this.ratingOneSelected = true;
    } else if (rating == 2) {
      this.ratingTwoSelected = true;
    } else if (rating == 3) {
      this.ratingThreeSelected = true;
    } else if (rating == 4) {
      this.ratingFourSelected = true;
    } else if (rating == 5) {
      this.ratingFiveSelected = true;
    }
    setTimeout(function () {
      $('.feedback-section').fadeOut('fast');
      this.ratingDone = true;
    }, 3000);
  }

  joinTribe($event) {
    this.joinTribeEmailSent = true;
    this.usersService.joinTribe(this.usersService.currentUser.email).subscribe(res => {
      if (res.hasError) {
        console.log(res);
      }
    });
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  sendAccessKeysToMentorsUrl() {
    if (localStorage.getItem("GATk") && localStorage.getItem("GRTk")) {
      let mentorIframe = document.getElementById('MentorsFrame');
      if (mentorIframe == null)
        return;

      let iWindow = (<HTMLIFrameElement>mentorIframe).contentWindow;
      iWindow.postMessage({
        "accessToken": localStorage.getItem("GATk"),
        "refreshToken": localStorage.getItem('GRTk')
      }, '*');
    }
  }

  isSectionContainsTextBox(section: Section) {
    let sectionControls = section.sectionControls.map(x => x);
    let response = false;
    sectionControls.forEach(ctrl => {
      if (!response && (ctrl.sectionElementType == SectionElementType.TEXTBOX_SINGLE || ctrl.sectionElementType == SectionElementType.TEXTBOX_MULTI)) {
        response = true;
      }
    })

    return response;
  }

  setShowMA(lesson: Lesson) {
    lesson.sections.forEach(sec => {
      sec.showMA = this.isSectionContainsTextBox(sec);
    })
  }

  openChatBot() {
    this.chatButtonVisible = false;
    this.chatWindowVisible = true;
    this.scrollToBottom();
  }

  closeChatBot() {
    this.chatButtonVisible = true;
    this.chatWindowVisible = false;
  }

  insertText(text: string): void {
    this.newMessage = text.trim();
    this.sendMessage();
  }

  scrollToBottom() {
    setTimeout(() => {
      const chatSection = this.chatSection.nativeElement;
      chatSection.scrollTop = chatSection.scrollHeight - chatSection.clientHeight;
    });
  }

  sendMessage() {
    if (this.newMessage.trim().length == 0) {
      this.newMessage = '';
      return;
    };
    this.AiChatBotThinking = true;
    this.messages.push({ role: "user", content: this.newMessage.trim() });
    this.scrollToBottom();
    this.newMessage = '';
    let chatCompletionsRequest: ChatCompletionsRequest = {
      model: "gpt-4o-mini",
      messages: this.messages,
      temperature: 1,
      top_p: 1,
      n: 1,
      stream: false,
      max_tokens: 250,
      presence_penalty: 0,
      frequency_penalty: 0
    };
    this.openAiService.sendOpenAiRequest(chatCompletionsRequest)
      .subscribe((response: ChatCompletionsResponse) => {
        let completion = response.choices[0].message.content.trim();
        this.messages.push({ role: "assistant", content: completion });
        this.scrollToBottom();
        this.AiChatBotThinking = false;
      });
    this.scrollToBottom();
  }

  setActiveIntroMenuIndex(index: number) {
    this.introMenuActive = index;
  }

  setActiveLessonTabIndex(index: number) {
    this.lessonTabIndex = index;
  }

  setActiveSectionIndex(index: number) {
    this.sectionActive = index;
    this.sectionImage = this.sectionVideo = '';
    try {
      this.sectionImage = this.currentSection.sectionControls.filter(x => x.sectionElementType === 4)[0].sanitizedUrl;
    } catch {
      try {
        this.sectionVideo = this.currentSection.sectionControls.filter(x => x.sectionElementType === 5)[0].sanitizedUrl;
      } catch { }
    }
  }

  showLoginPopup(initialView = 'login') {
    this.loginPopup = this.dialog.open(LoginPopupComponent, {
      width: '35vw',
      disableClose: false,
      autoFocus: false,
      data: {
        dataKey: this.timeRanOut,
        initialView: initialView
      },
      closeOnNavigation: false
    });
  }

  toggleSong() {
    if (this.isSongPlaying) {
      this.pauseSong();
    } else {
      this.playSong();
    }
  }

  nextPage(event) {
    if (this.lessonIntroPage) {
      this.startLesson(event);
    } else if (this.currentSection && !this.currentSection.sectionControls) {
      this.finishLesson();
    } else if (this.perfectAnswerPage) {
      this.gotoCongratsPage();
    } else {
      this.nextSection();
    }
  }

  getAllComments() {
    this.configsService.loading(true);
    this.skillService.getAllComments(this.currentSection.sectionId, this.lessonId).subscribe((res) => {
      res.forEach(x => {
        x.comment = x.comment.replace(/\n/g, '<br/>');
        if (x.subThreads && x.subThreads.length > 0) {
          x.subThreads.forEach(y => {
            y.comment = y.comment.replace(/\n/g, '<br/>');
          })
        }
      });
      this.discussions = res;
    }, err => {
      this.configsService.loading(false);
    }, () => {
      this.discussionString = '';
      this.configsService.loading(false);
    });
  }

  hasInteracted: boolean = false;
  hasReplyInteracted: boolean = false;

  postComment() {
    if (!this.isLoggedIn) {
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Please login to share your thoughts.",
        positiveButton: () => {
          this.error_popup.display = false;
        }
      }
    }
    if (!this.discussionString || this.discussionString.trim() === '') {
      this.hasInteracted = true;
      return;
    }
    let request: PostDiscussionRequest = {
      lessonId: this.lessonId,
      sectionId: this.currentSection.sectionId,
      comment: this.discussionString,
      mentionedUserId: this.taggedUserId
    }
    this.skillService.postComment(request).subscribe((res) => {
      this.getAllComments();
    });
  }

  postReply() {
    if (!this.replyString || this.replyString.trim() === '') {
      this.hasReplyInteracted = true;
      return;
    }
    let request: PostDiscussionRequest = {
      lessonId: this.lessonId,
      sectionId: this.currentSection.sectionId,
      comment: this.replyString,
      mentionedUserId: null,
      parentThreadId: this.currentReplyId
    }
    this.skillService.postComment(request).subscribe((res) => {
      this.showCommentBox = false;
      this.getAllComments();
    });
  }

  getRandomMentors() {
    this.mentorService.getRandomWebsiteMentors().subscribe(response => {
      this.mentors = response.result.data;
    });
  }

  tagUser(userId: string, name: string) {
    this.taggedUserId = userId;
    this.taggedUserName = name;
  }

  removeTag() {
    this.taggedUserId = null;
    this.taggedUserName = null;
  }

  previousPage() {
    if (this.showCongratsPage) {
      this.showCongratsPage = false;
      this.perfectAnswerPage = true;
      this.setActiveSectionIndex(this.sectionActive - 1);
    } else {
      this.previousSection();
    }
  }

  getRandomLessons() {
    this.skillService.getAllLessons(Math.ceil(Math.random() * 10), 4)
      .subscribe(response => {
        this.randomLessons = response.result.data;
      });
  }

  // searchLessons(event) {
  //   if (event.keyCode === 13) {
  //     this.skillService.getAllLessons(1, 4, this.searchLessonsQuery)
  //       .subscribe(response => {
  //         this.randomLessons = response.result.data;
  //       });
  //   }
  // }

  gotoCongratsPage() {
    this.perfectAnswerPage = false;
    this.showCongratsPage = true;
    this.setActiveSectionIndex(this.sectionActive + 1);
    this.getOverallLessonStreak();
    this.getRandomLessons();
    this.getIndustries();
    this.getWebsiteMentors();
  }

  getIndustries() {
    this.mentorService.getIndustriesList(true)
      .subscribe(response => {
        this.industries = response.result.filter(x => x.industryId !== 10)
          .map(x => ({ ...x, title: x.title.toUpperCase(), selected: false }))
          .sort((a, b) => {
            // const titleA = a.title.toUpperCase();
            // const titleB = b.title.toUpperCase();

            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          });
      });
  }

  toggleIndustry(index: number) {
    this.industries[index].checked = !this.industries[index].checked;
    this.getWebsiteMentors();
  }

  getWebsiteMentors() {
    this.mentorService.getWebsiteMentors(this.industries.filter(x => x.checked === true).map(x => x.industryId)).subscribe(response => {
      this.mentors = response.result.data;
    });
  }

  getMentorImage(userId: string) {
    return 'https://gleac-mentors-splash-images.s3.ap-south-1.amazonaws.com/' + userId + '.png';
  }

  handleMentorImageError(event) {
    event.target.src = 'https://www.gleac.com/_next/image?url=%2Fimg%2Fmentors%2Ffallback-mentor.webp&w=640&q=75';
  }

  getOverallLessonStreak() {
    this.skillService.getOverallLessonStreak(this.usersService.currentUser.userId)
      .subscribe(res => {
        this.streak = res.result;
      });
  }

  protected showWhatsAppLessonSubscriptionPopup() {
    let userData = {};
    if (this.isLoggedIn) {
      userData = {
        firstName: this.usersService.currentUser.firstName,
        lastName: this.usersService.currentUser.lastName,
        email: this.usersService.currentUser.email
      };
    }
    this.whatsAppLessonSubscriptionPopup = this.dialog.open(WhatsappLessonSubscriptionPopupComponent, {
      disableClose: false,
      autoFocus: true,
      data: userData,
      closeOnNavigation: true
    });

    this.whatsAppLessonSubscriptionPopup.afterClosed().subscribe(() => {
      this.updateRegistrationStatusForWhatsapp();
    });
  }

  toggleMenu() {
    this.menuShown = !this.menuShown;
  }

  toggleSkill(index: number) {
    this.skills[index].checked = !this.skills[index].checked;
    this.skillService.getAllLessons(1, 4, this.skills.filter(x => x.checked === true).map(x => x.title))
      .subscribe(response => {
        this.randomLessons = response.result.data;
      });
  }

  handleCommentBox(index: number) {
    if (!this.isLoggedIn) {
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Please login to share your thoughts.",
        positiveButton: () => {
          this.error_popup.display = false;
        }
      }
      return
    };
    this.currentReplyId = index;
    this.showCommentBox = true;
  }

  upsertMentorAnswerLike(peerAnswer: PeerAnswer, i: number) {
    this.skillService
      .upsertMentorAnswerLike(peerAnswer.answers[i].sectionId, peerAnswer.lessonId, peerAnswer.candidateId, peerAnswer.answers[i].isLiked ? 0 : 1)
      .subscribe(res => {
        if (res.statusCode === 200) {
          peerAnswer.answers[i].isLiked = !peerAnswer.answers[i].isLiked;
          this.getMentorAnswerLikesByLesson();
        }
      });
  }

  getMentorLikesBySection(sectionId: string, mentorUserId: string) {
    return this.mentorAnswerLikes ? this.mentorAnswerLikes.filter(x => x.sectionId === sectionId && x.mentorUserId === mentorUserId && x.likeStatus === 1).length : 0;
  }

  getMentorAnswerLikesByLesson() {
    this.skillService
      .getMentorAnswerLikesByLesson(this.lessonId)
      .subscribe(res => {
        this.mentorAnswerLikes = res.result;
        this.peerAnswers.forEach(x => {
          x.answers.forEach(y => {
            let mentorLike = this.mentorAnswerLikes.filter(z => y != null && z.sectionId === y.sectionId && z.mentorUserId === x.candidateId && z.createdBy === this.usersService.currentUser.userId)[0];
            if (mentorLike) {
              y.isLiked = mentorLike.likeStatus === 0 ? false : true;
            }
          });
        });
      });
  }

  isPreviousDisabled() {
    if (this.sectionActive < 2 || this.perfectAnswerPage) {
      return true;
    }
    return false;
  }

  getLeftMenuItemImage(i) {
    if (i < this.sectionActive) {
      return 'assets/images/lessons/check.png';
    }
    else if (i === this.sectionActive) {
      return 'assets/images/lessons/eye.png';
    } else {
      return '';
    }
  }

  muteAlways() {
    this.pauseSong();
    localStorage.setItem('muteAlways', '1');
  }

  getVolumeIcon() {
    if (this.isVolumeMenuShown) {
      return 'assets/images/lessons/cross.png';
    } else if (this.isSongPlaying) {
      return 'assets/images/lessons/volume.png';
    } else {
      return 'assets/images/lessons/muted.png';
    }
  }

  setVolumeMenuStatus(status) {
    this.isVolumeMenuShown = status;
  }

  handleLessonDiscussionLike(commentId: number, likeStatus: number, index: number, subIndex: number) {
    if (!this.isLoggedIn) {
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Please login to interact with the comments.",
        positiveButton: () => {
          this.error_popup.display = false;
        }
      }
      return
    };
    if (subIndex !== -1) {
      this.skillService.upsertLessonDiscussionLike(commentId, likeStatus === 0 ? 1 : 0).subscribe(res => {
        if (res.statusCode === 200) {
          this.discussions[index].subThreads[subIndex].isLiked = likeStatus === 0 ? 1 : 0;
          this.discussions[index].subThreads[subIndex].likes = likeStatus === 0 ? this.discussions[index].subThreads[subIndex].likes + 1 : this.discussions[index].subThreads[subIndex].likes - 1;
        }
      });
    }
    else {
      this.skillService.upsertLessonDiscussionLike(commentId, likeStatus === 0 ? 1 : 0).subscribe(res => {
        if (res.statusCode === 200) {
          this.discussions[index].isLiked = likeStatus === 0 ? 1 : 0;
          this.discussions[index].likes = likeStatus === 0 ? this.discussions[index].likes + 1 : this.discussions[index].likes - 1;
        }
      });
    }
  }

  handleImageError(event: any) {
    event.target.src = 'assets/images/lessons/default-profile.webp';
  }

  updateRegistrationStatusForWhatsapp(): boolean {
    if (!this.isLoggedIn) {
      return false;
    }

    this.usersService.checkIfUserIsRegisteredForWhatsAppLessons(this.usersService.currentUser.userId).subscribe(data => {
      if (!data.hasError && data.result == 'Registered') {
        this.userIsRegisteredForDailyLessons = true;
      } else {
        this.userIsRegisteredForDailyLessons = false;
      }
    });
  }
}
