import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid/data-grid.model';
import { ReportService } from 'src/app/services/report.service';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from '../../services/configs.service';

@Component({
  selector: 'app-benchmark-user-report',
  templateUrl: './benchmark-user-report.component.html',
  styleUrls: ['./benchmark-user-report.component.less']
})
export class BenchmarkUserReportComponent implements OnInit {
  
  error_popup = {display: false};
  statusFilter: string;
  dataFilter: string;
  jobsList: any;
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    { data: "userEmail", displayName: "User Email" },
    { data: "assessmentId", displayName: "Benchmark ID" },
    {
      data: "transactionId", displayName: "Report Download", disableSort: true, render: (data, row) => {
        return '<a target="_blank" href="https://dashboardtest.gleacreviews.com/api/dashboard/report/' + row.transactionId + '"><i class="fas fa-file"></i> DOWNLOAD</a>';
      }
    },
    {
      data: "assessmentStatus", displayName: "Status", disableSort: true, render: (data, row) => {
        if (row.assessmentStatus === "PENDING") {
          return '<center><div class="gleac-badge-text danger">' + row.assessmentStatus + '</div></center>';
        }
        else if (row.assessmentStatus === "COMPLETED") {
          return '<center><div class="gleac-badge-text success">' + row.assessmentStatus + '</div></center>';
        }
      }
    },
    {
      data: "badge", displayName: "Badge", disableSort: true, isExpandable: true, render: (data, row) => {
        return '<center><i class="fas fa-caret-down"></i></center>';
      }
    }
  ];

  constructor(private reportService: ReportService, private userService: UsersService,private configsService : ConfigsService) {
    this.configsService.loading(true);
    this.reportService.getBenchmarkReportGrid(this.dataFilter, this.statusFilter, this.userService.currentUser.company).subscribe(data => {
      this.jobsList = data.result.jobsList
      this.transactionsGridData = data.result.usersData;
    },err => {
            this.configsService.loading(false);
            this.error_popup = {display: true};
    },()=> {
            this.configsService.loading(false);
    });
  }

  ngOnInit() { }

  filterData($event) {
    this.dataFilter = $event.srcElement.value;
    this.configsService.loading(true);
    this.reportService.getBenchmarkReportGrid(this.dataFilter, this.statusFilter, this.userService.currentUser.company).subscribe(data => {
      this.transactionsGridData = data.result.usersData;
    },err => {
            this.configsService.loading(false);
            this.error_popup = {display: true};
    },()=> {
            this.configsService.loading(false);
    });
  }

  filterStatus($event) {
    this.statusFilter = $event.srcElement.value;
    this.configsService.loading(true);
    this.reportService.getBenchmarkReportGrid(this.dataFilter, this.statusFilter, this.userService.currentUser.company).subscribe(data => {
      this.transactionsGridData = data.result.usersData;
    },err => {
            this.configsService.loading(false);
            this.error_popup = {display: true};
    },()=> {
            this.configsService.loading(false);
    });
  }
}
