import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { CandidateLessonService } from 'src/app/services/candidate-lesson.service';
import { Lesson, Section, LessonReviewQuestion, CandidateLessonAnswer, LessonReviewAnswer, SectionElement, PeerAnswer, SectionElementAnswer, SectionAnswer } from '../common/common.model';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd, } from '@angular/router';
import { SectionElementType } from '../common/common.model';
import { UsersService } from 'src/app/services/users.service';
import { SeoService } from 'src/app/services/seo.service';
import { ConfigsService } from '../../services/configs.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LessonFinishedComponent } from 'src/app/dialogs/lesson-finished/lesson-finished.component';
import { TimerComponent } from '../common/timer/timer.component';
import { LessonsService } from 'src/app/services/lessons.service';
import { LoginPopupComponent } from 'src/app/dialogs/login-popup/login-popup.component';
import { ContentObserver } from '@angular/cdk/observers';
import { timeout } from 'rxjs/operators';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserLessonRating } from 'src/app/dialogs/answer-popup/answer.model';
import { CandidateChallengeService } from 'src/app/services/candidate-challenge.service';
declare var jQuery: any;

@Component({
  selector: 'app-candidate-challenge',
  templateUrl: './candidate-challenge.component.html',
  styleUrls: ['./candidate-challenge.component.less']
})

@Injectable({
  providedIn: 'root'
})
export class CandidateChallengeComponent implements OnInit {

  issuccessPopup = false;
  success_popup :any;
  iserrorPopup = false;
  error_popup :any;
  answersForm: FormGroup = new FormGroup({});
  hideButtons: boolean = false;
  lessonRemainingTime: number = 10;
  candidateLesson: any;
  currentSection:any;
  currentSectionIndex: number = 0;
  selfReviewDone: boolean = false;
  userAnswer: FormControl = new FormControl();
  lessonDone: boolean = false;
  routeSub: any;
  lessonId: string;
  username;
  gleacVideo: any;
  candidateAnswer: CandidateLessonAnswer;
  candidateSectionAnswers: Map<string, Map<string, any>> = new Map();
  lessonFinished: boolean = false;
  candidateId: string;
  perfectAnswerPage: boolean = false;
  lessonIntroPage: boolean = true;
  errors: string[] = [];
  currentYear: number = new Date().getFullYear();
  showLessonEndDialog: boolean = true
  lessonEndDialog: MatDialogRef<LessonFinishedComponent>;
  loginPopup: MatDialogRef<LoginPopupComponent>;
  timeRanOut: boolean = false;
  userAnswerDisabled: boolean = false;
  userEmailId: string;
  lessonUrl: string;
  candidateScore: number;
  lessonTime: number = 300;
  @ViewChild("lessonTimer", { static: false }) lessonTimer: TimerComponent;
  companyId: string;
  peerAnswers: PeerAnswer[];
  showPeerAnswer: boolean = true;
  showLoader: boolean = false;
  activatedRoute;
  preAuth: boolean = false;
  userAnswerAll = '';
  answerPageErrors: string[];
  userName: string;
  ratingOneSelected: boolean = false;
  ratingTwoSelected: boolean = false;
  ratingThreeSelected: boolean = false;
  ratingFourSelected: boolean = false;
  ratingFiveSelected: boolean = false;
  ratingDone: boolean = false;
  joinTribeEmailSent: boolean = false;
  isShown: boolean = false ;
  constructor(protected configsService: ConfigsService, protected candidateLessonService: CandidateLessonService, protected sanitizer: DomSanitizer,
    protected route: ActivatedRoute, protected usersService: UsersService, protected router: Router, protected formBuilder: FormBuilder,
    protected dialog: MatDialog, protected meta: Meta, protected skillService: LessonsService, protected seoService: SeoService,
    protected candidateChallengeService : CandidateChallengeService ) {

  }


  ngOnInit() {
    var url = location.href;
    let accessToken = localStorage.getItem("GATk");
    this.gleacVideo = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/sIwXegPFDNE");
    if(accessToken !== null){
      let jwtData = accessToken.split(".")[1];
      let decodedJwtJsonData = window.atob(jwtData);
      let decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.usersService.currentUser = {
        authRole: decodedJwtData.auth_role,
        email: decodedJwtData.email,
        userId: decodedJwtData.sub,
        permissions : decodedJwtData.permissions
      };
      this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(res => {
        if(res.result.firstName.length > 0){
          this.username = res.result.firstName + " " + res.result.lastName;
        }
        else{
          localStorage.removeItem('GATk');
        }
      },
      (error) => {
        localStorage.removeItem('GATk');
      });
    }

    this.companyId = this.route.snapshot.queryParams["cid"];
    //this.seoService.updateTitle('Andrei Lucian Moraru - Register');

    //this.candidateId = this.usersService.currentUser.userId;
    this.route.queryParams.subscribe(params => {
      if (params["preauth"] && !this.usersService.currentUser) {
        this.preAuth = true;
        this.usersService.getTokenForPreAuth(params["preauth"]).subscribe(res => {
          localStorage.setItem("GATk", res.result.accessToken);
          localStorage.setItem("GRTk", res.result.refreshToken);
          window.location.replace(url);
        }, err => { })
      }
    })
    this.routeSub = this.route.params.subscribe(params => {
      if (params["id"]) {
        this.lessonUrl = params["id"];
        let lessonUrl: string = atob(params["id"]);
        this.lessonId = lessonUrl.substr(0, lessonUrl.indexOf("+"))
        this.userEmailId = lessonUrl.substr(lessonUrl.indexOf("+") + 1, lessonUrl.length - 1);
        this.configsService.loading(true);
        this.getLesson(this.lessonId);
      }
    });
    this.candidateAnswer = {
      userEmailId: undefined,
      candidateId: undefined,
      lessonId: this.lessonId,
      candidateSectionAnswers: [],
      selfReviewAnswers: [],
      lessonTimeInSeconds: 0,
      companyId: undefined
    };
    this.isShown = false;
  };
  getLesson(lessonId){
    this.candidateChallengeService.getChallengeById(lessonId).subscribe(data => {
      this.configsService.loading(false);
      if (data.hasError) {
        this.router.navigate(["invalid"]);
      }
      this.candidateLesson = data.result;
      if (this.candidateLesson) {
        this.seoService.updateTitle(this.candidateLesson.title);
        this.seoService.setSocialMediaTags(
          'http://communities.gleacreviews.com',
          this.candidateLesson.challengeTitle,
          this.candidateLesson.challengeDescription,
          'share_image.png');
          this.candidateLesson.sections = [this.candidateLesson.section];
        //this.seoService.updateMeta(, this.candidateLesson.lessonSummary);
        // this.meta.updateTag({ name: 'title', content: this.candidateLesson.title });
        // this.meta.updateTag({ name: 'description', content: this.candidateLesson.lessonSummary });
        this.candidateLesson.sections = this.candidateLesson.sections.sort((x, y) => {
          return x.sectionOrder - y.sectionOrder;
        });
        this.candidateLesson.sections.forEach(x => x.isDone = false);
        this.candidateLesson.selfReviewQuestions.forEach(x => x.selectedValue = 0);
        this.skillService.getLessonCategoryDetailsById(this.candidateLesson.lessonCategoryId).subscribe(skill => {
          if (skill.result) {
            this.seoService.setSocialMediaTags(
              'http://communities.gleacreviews.com',
              this.candidateLesson.title,
              this.candidateLesson.lessonSummary,
              skill.result.imageSrc);
            //this.seoService.updateOgUrl(skill.result.imageSrc);
          }
        });
      }
    }, err => {
      this.configsService.loading(false);
      this.router.navigate(["invalid"]);
    }, () => {
      this.configsService.loading(false);
    });
  }
  nextSection() {
    let isValid: boolean = false;
    this.candidateLesson.sections[this.currentSectionIndex].sectionControls.forEach(control => {
      if (control.sectionAnswerControl) {
        control.sectionAnswerControl.markAllAsTouched();
      }
    })
    isValid = this.candidateLesson.sections[this.currentSectionIndex].sectionControls.filter(x => x.sectionAnswerControl).every(x => x.sectionAnswerControl.valid);
    if (isValid) {
      this.addUserAnswer();
      this.candidateLesson.sections[this.currentSectionIndex].isDone = true;
      this.currentSectionIndex = ++this.currentSectionIndex;
      this.setupSection(this.currentSectionIndex);
      window.scrollTo(0, 0);
      document.getElementById("lesson-main").scrollTop = 0
      this.finishLesson();
    }
    let audio:HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = true;
  }

  showLessonValidationErrors() {
    this.errors = []
    if (this.userAnswer.errors) {
      this.errors.push("Please enter an answer.")
    }
    else if (!this.sectionOptionValidator()) {
      this.errors.push("Please select all options.")
    }
    setTimeout(() => {
      this.errors = [];
    }, 3500);
  }

  previousSection() {
    this.addUserAnswer();
    this.userAnswer.setValue('');
    if (this.candidateLesson.sections[this.currentSectionIndex]) {
      this.currentSectionIndex = --this.currentSectionIndex;
    }
    else {
      this.currentSectionIndex = this.candidateLesson.sections.length - 1;
    }
    this.setupSection(this.currentSectionIndex);
    let audio:HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = false;
  }

  setupSection(index: number) {
    this.getUserAnswer();
    this.currentSection = this.candidateLesson.sections[index];
    if (this.currentSection && this.currentSection.sectionControls) {
      this.currentSection.sectionControls.forEach(control => {
        switch (control.sectionElementType) {
          case SectionElementType.TEXTBOX_SINGLE:
          case SectionElementType.TEXTBOX_MULTI:
            control.sectionAnswerControl = this.formBuilder.control(control.sectionAnswer, [Validators.required]);
            break;
          case SectionElementType.SELECTION_BUTTONS_SINGLE:
            control.sectionAnswerControl = this.formBuilder.control(control.sectionAnswer, [Validators.required]);
            break;
          // case SectionElementType.SELECTION_BUTTONS_SINGLE:
          case SectionElementType.SELECTION_BUTTONS_MULTI:
            let options = control.sectionElementOptions.map(x => { return false })
            control.sectionAnswerControl = this.formBuilder.array(options, [this.requiredOneTrue.bind(this)]);
            break;
          case SectionElementType.IMAGE_URL:
          case SectionElementType.IMAGE_UPLOAD:
          case SectionElementType.VIDEO_URL:
            control.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(control.sectionElementContent);
            break;
          default: break;
        }
      });
    }
    else {
      this.currentSection = this.candidateLesson.selfReviewQuestions;
      // this.selfReviewDone = true;
    }
    if (this.candidateLesson.sections.every(x => {
      if (x && x.isDone == true) { return true }
    }) && this.selfReviewDone) {
      this.lessonDone = true;
    }
    else {
      this.lessonDone = false;
    }
  }

  requiredOneTrue(control: FormArray) {
    if (control.controls.some(x => x.value))
      return null;
    else return { "required": true }
  }

  // isDisabled() {
  //   return this.userAnswerDisabled ? true : false
  // }

  goToSection(index: number) {
    if (this.candidateLesson.sections[index].isDone || this.selfReviewDone) {
      this.currentSectionIndex = index;
      this.setupSection(this.currentSectionIndex);
    }
  }

  sectionOptionValidator() {

    if ((this.currentSection as Section).sectionControls) {
      let optionControls: SectionElement[] = (this.currentSection as Section).sectionControls.filter(x => x.sectionElementType === SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType === SectionElementType.SELECTION_BUTTONS_MULTI);
      if (optionControls && optionControls.length > 0) {
        return optionControls.every(
          x => x.selectedOption !== undefined);
      }
    }
  }

  selectOption(event, i) {
    let sectionElement = (this.currentSection as Section).sectionControls[i];
    sectionElement.selectedOption = sectionElement.sectionElementOptions.find(x => x.trim() === event.srcElement.innerText);
    this.candidateLesson.sections[this.currentSectionIndex].userAnswer = sectionElement.selectedOption;
  }

  setupAnswerRequiredValidator() {
    this.userAnswer.setValidators([Validators.required]);
  }

  setupOptionRequiredValidator() {
    // this.sectionSelectValue.setValidators([Validators.required]);
  }

  disableAnswerRequiredValidator() {
    this.userAnswer.setValidators([Validators.required]);
  }

  disableValidators() {
    // this.sectionSelectValue.clearValidators();
    //this.userAnswer.clearValidators();
  }

  addUserAnswer() {
    let section = this.currentSection = this.candidateLesson.sections[this.currentSectionIndex];
    if (section && section.sectionControls) {
      var candidateSectionIndex = this.candidateAnswer.candidateSectionAnswers.findIndex(x => x.sectionId == section.sectionId)
      if (candidateSectionIndex !== -1) {
        this.candidateAnswer.candidateSectionAnswers.splice(candidateSectionIndex, 1);
      }
      let sectionElementAnswers: SectionElementAnswer[] = [];
      let answersMap: Map<string, any> = new Map();
      section.sectionControls.forEach(x => {
        if (x.sectionAnswerControl != undefined) {
          switch (x.sectionElementType) {
            case SectionElementType.SELECTION_BUTTONS_MULTI:
              var selectedOptions = x.sectionAnswerControl.value.map((y, i) => {
                if (y)
                  return i;
              }).filter(y => y !== undefined).map(y => x.sectionElementOptions[y]);
              answersMap.set(x.sectionElementId, selectedOptions);
              sectionElementAnswers.push({ sectionElementId: x.sectionElementId, answer: selectedOptions });
              break;
            case SectionElementType.SELECTION_BUTTONS_SINGLE:
            case SectionElementType.TEXTBOX_MULTI:
            case SectionElementType.TEXTBOX_SINGLE:
              answersMap.set(x.sectionElementId, x.sectionAnswerControl.value);
              sectionElementAnswers.push({ sectionElementId: x.sectionElementId, answer: x.sectionAnswerControl.value });
              break;
          }
        }
      })
      this.candidateAnswer.candidateSectionAnswers.push({ sectionId: section.sectionId, sectionElementAnswers: sectionElementAnswers });
      this.candidateSectionAnswers.set(section.sectionId, answersMap);
    }
  }

  getUserAnswer() {
    let section = this.currentSection = this.candidateLesson.sections[this.currentSectionIndex];
    if (this.currentSection) {
      var sectionsAnswers = this.candidateSectionAnswers.get(this.currentSection.sectionId);
      if (sectionsAnswers) {
        this.currentSection.sectionControls.forEach(x => {
          if (sectionsAnswers.has(x.sectionElementId)) {
            if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI) {
              setTimeout(() => {
                var selectedOptions: [] = sectionsAnswers.get(x.sectionElementId);
                // (x.sectionAnswerControl as FormArray).controls.forEach(x =>   {
                //   if (selectedOptions.some(y => y === x.value.text)) {
                //     x.value.selected.setValue(true);
                //   }
                // })
                var selectedOptionsIndex = selectedOptions.map(y => x.sectionElementOptions.indexOf(y));
                selectedOptionsIndex.forEach(y => (x.sectionAnswerControl as FormArray).controls[y].setValue(true))
              }, 10);
            }
            else
              setTimeout(() => {
                x.sectionAnswerControl.setValue(sectionsAnswers.get(x.sectionElementId));
              }, 10);
          }
        })
      }
    }
    this.isShown = true;
  }

  finishLesson() {
    this.addUserAnswer();
    this.lessonFinished = true;
    this.showLoader = true;
    this.lessonTimer.pause();

    let accessToken = localStorage.getItem("GATk");

    if (this.usersService.currentUser  === undefined || accessToken === null) {
      this.loginPopup = this.dialog.open(LoginPopupComponent, {
        disableClose: true,
        data: {
          dataKey: this.timeRanOut
        }
      });
      this.loginPopup.afterClosed().subscribe(data => {
        this.candidateAnswer.userEmail = this.userEmailId;
        this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
        this.candidateAnswer.LessonCategoryId = this.candidateLesson.challengeSkillId;
        this.candidateAnswer.companyId = this.usersService.currentUser.company;
        this.candidateAnswer.lessonTimeInSeconds = this.candidateLesson.challengeDuration*60;
        this.candidateChallengeService.insertChallengeAnswer(this.candidateAnswer).subscribe(res => {
          this.showLoader = false;
          if (res.hasError) {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: res.errors[0],
              // positiveButton: () => {
                // this.error_popup.display = false;
                //this.router.navigate(["candidate/" + this.usersService.currentUser.userId])

            }
            this.answerPageErrors = res.errors;
            this.showLoader = false;
            this.configsService.loading(false);
          }
          else {
            this.configsService.loading(false);
            // this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(user => {
            //   this.userName = user.result.firstName + " " + user.result.lastName;
            // });
            // this.candidateScore = res.result.candidateAnswerScore.candidateScore;
            // this.peerAnswers = res.result.peerAnswers;
            // this.peerAnswers.forEach(element => {
            //   this.usersService.getUserDetails(element.candidateId).subscribe(name => {
            //     element.candidateName = name.result.firstName + " " + name.result.lastName;
            //   });
            // });
            // this.goToScoreSection();
            // this.showLoader = false;
            this.issuccessPopup = true;
            this.success_popup ={
              display : true,
              message : "Thank you for taking GLEAC challenge",
              // buttonPositive:'',
              // positiveButton: () =>{
              //   // this.success_popup.display = false;
              //   // var addChallengeContext = this;
              //   // setTimeout(function () {
              //   //   addChallengeContext.router.navigate(['lessons']);
              //   // }, 1000);
              // }
            }
          }

        },
          err => {
            this.showLoader = false;
            this.configsService.loading(false);
          });
      });
    }
    else {
      this.configsService.loading(true);
      this.candidateAnswer.userEmail = this.userEmailId;
      this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
      this.candidateAnswer.LessonCategoryId = this.candidateLesson.challengeSkillId;
      this.candidateAnswer.companyId = this.usersService.currentUser.company;
      this.candidateAnswer.lessonTimeInSeconds = this.candidateLesson.challengeDuration*60;
      this.candidateChallengeService.insertChallengeAnswer(this.candidateAnswer).subscribe(res => {
        this.showLoader = false;
        if (res.hasError) {
          this.showLoader = false;
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {
            display: true,
            message: res.errors[0],
            // positiveButton: () => {
            //   this.error_popup.display = false;
            //   //this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
            // }
          }
          this.answerPageErrors = res.errors;
          this.showLoader = false;
          this.configsService.loading(false);
        }
        else {
          // this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(user => {
          //   this.userName = user.result.firstName + " " + user.result.lastName;
          // });
          // this.candidateScore = res.result.candidateAnswerScore.candidateScore;
          // this.peerAnswers = res.result.peerAnswers;
          // this.peerAnswers.forEach(element => {
          //   this.usersService.getUserDetails(element.candidateId).subscribe(name => {
          //     element.candidateName = name.result.firstName + " " + name.result.lastName;
          //   });
          // });
          // if (!this.preAuth) {
          //   this.goToScoreSection();
          // }
          // else {
          //   this.hideButtons = true;
          //   if (res.result.hasCallbackUrl) {
          //     location.href = res.result.callbackUrl;
          //   }
          // }
          // this.showLoader = false;
          this.configsService.loading(false);
          this.issuccessPopup = true;
          this.success_popup ={
            display : true,
            message : "Thank you for taking GLEAC challenge",
            // buttonPositive:"",
            // positiveButton: () =>{
            //   // this.success_popup.display = false;
            //   // var addChallengeContext = this;
            //   // setTimeout(function () {
            //   //   addChallengeContext.router.navigate(['lessons']);
            //   // }, 1000);
            // }
          }
        }
      },
        err => {
          this.showLoader = false;
          this.configsService.loading(false);
        });
    }
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  goToScoreSection() {
    this.selfReviewDone = true;
    this.perfectAnswerPage = true;
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  forceFinishLesson(event) {
    this.candidateLesson.sections.forEach(section => section.isDone = true);
    this.userAnswer.clearValidators();
    this.timeRanOut = true
    this.candidateAnswer.lessonTimeInSeconds = this.lessonTime;
    this.candidateAnswer.selfReviewAnswers = this.candidateLesson.selfReviewQuestions.map(x => {
      let selfReviewAnswer: LessonReviewAnswer = { reviewQuestionId: x.questionId, reviewRating: 0 };
      return selfReviewAnswer;
    })
    this.finishLesson();
    this.isShown = true;
  }

  startLesson(event) {
    this.lessonIntroPage = false;
    this.setupSection(this.currentSectionIndex);
    window.scrollTo(0, 0);
    //this.userAnswer.enable();
    let audio:HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
    this.isShown = true;
  }

  gotoDashboard(event) {
    this.router.navigate(["candidate/" + this.usersService.currentUser.userId])
  }

  setRating(rating: number) {
    var userRating: UserLessonRating = {
      LessonId: this.lessonId,
      Rating: rating,
      UserId: this.usersService.currentUser.userId
    };
    this.skillService.saveLessonRating(userRating).subscribe(res => {
    });
    if (rating == 1) {
      this.ratingOneSelected = true;
    }
    else if (rating == 2) {
      this.ratingTwoSelected = true;
    }
    else if (rating == 3) {
      this.ratingThreeSelected = true;
    }
    else if (rating == 4) {
      this.ratingFourSelected = true;
    }
    else if (rating == 5) {
      this.ratingFiveSelected = true;
    }
    setTimeout(function () {
      $('.feedback-section').fadeOut('fast');
      this.ratingDone = true;
    }, 3000);
  }

  joinTribe($event) {
    this.joinTribeEmailSent = true;
    this.usersService.joinTribe(this.usersService.currentUser.email).subscribe(res => {
      if (res.hasError) {
        console.log(res);
      }
    });
  }

  toggleShow() {
    this.isShown = ! this.isShown;
  }
}
