import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigsService } from 'src/app/services/configs.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-verify-client-account',
  templateUrl: './verify-client-account.component.html',
  styleUrls: ['./verify-client-account.component.less']
})
export class VerifyClientAccountComponent implements OnInit {
  isKeyValid: boolean = false;
  showLoader: boolean = true;

  constructor(private configsService: ConfigsService, private route: ActivatedRoute, private userService: UsersService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let key = params["key"];
      this.configsService.loading(true);
      this.userService.confirmAccountVerificationKey(key).subscribe(res => {
        if (res.hasError) {
          this.isKeyValid = false;
        }
        else {
          this.isKeyValid = true;
        }
        this.showLoader = false;
        this.configsService.loading(false);
      }, err => {
        this.configsService.loading(false);
        this.isKeyValid = false;
        this.showLoader = false;
      }, () => {
        this.configsService.loading(false);
        this.showLoader = false;
      });
    });
  }
}