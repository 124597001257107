import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LessonsService} from "../../services/lessons.service";
import {ConfigsService} from "../../services/configs.service";

@Component({
  selector: 'new-scoring-test',
  templateUrl: './new-scoring-test.component.html',
  styleUrls: ['./new-scoring-test.component.less']
})
export class NewScoringTestComponent implements OnInit {

  lessons = [];
  selectedLesson = null;
  selectedSection = null;
  selectedSectionIndex = null;
  response = null;
  showTopAnswers = false;

  userAnswer = null;
  mentorLessonAnswers = null;

  lessonCategories = {
    label: "",
    key: "LessonCategory",
    placeholder: "Lesson Category",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }

  levels = {
    label: "",
    key: "level",
    placeholder: "Select Level",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }

  lesson = {
    label: "",
    key: "lesson",
    placeholder: "Lesson",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }

  sections = {
    label: "",
    key: "section",
    placeholder: "Section",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }

  constructor(private lessonService: LessonsService, protected configsService: ConfigsService) {
  }

  ngOnInit() {
    this.getAllLessoncategories();
  }

  getAllLessoncategories() {
    this.lessonService.getLessonCategories().subscribe(data => {
      this.clearLessonCategory();
      this.lessonCategories.options = [];
      let reqData = data.result.filter(lc => lc.skillType != 4);
      reqData.forEach((item) => {
        this.lessonCategories.options.push({name: item.lessonCategoryName, value: item.lessonCategoryId});
      })
    })
  }

  getLessons() {
    let lessonCategory = JSON.parse(JSON.stringify(this.lessonCategories.value));

    this.lessonService.getLesson(lessonCategory.value).subscribe(data => {
      if (data && data.result.length > 0) {

        this.clearLevels();
        this.levels.options = [];

        this.lessons = data.result;
        let levelsNumbers = [];

        this.lessons.forEach((item) => {
          levelsNumbers.push(item.level);
        })

        let uniqueLevels = [...new Set(levelsNumbers)]
        uniqueLevels = uniqueLevels.sort((n1, n2) => n1 - n2);

        uniqueLevels.forEach((item) => {
          this.levels.options.push({name: item, value: item});
        })
      }
    })
  }

  getLessonsForLevel() {
    this.clearLesson();
    this.lesson.options = [];
    let level = JSON.parse(JSON.stringify(this.levels.value));
    let lessonsForLevel = this.lessons.filter(x => x.level == level.value);

    lessonsForLevel.forEach((item) => {
      this.lesson.options.push({name: item.title, value: item.lessonId});
    })
  }

  getSections() {
    this.clearSection();
    this.sections.options = [];
    let lessonId = JSON.parse(JSON.stringify(this.lesson.value));
    this.selectedLesson = this.lessons.filter(x => x.lessonId == lessonId.value)[0];
    let sections = this.selectedLesson.sections.filter(x => x.questionType == 6);

    sections.forEach((item) => {
      this.sections.options.push({name: item.sectionTitle, value: item.sectionId});
    })

    this.getMentorForLesson(lessonId.value);
  }

  selectSection() {
    this.response = null;
    let sectionId = JSON.parse(JSON.stringify(this.sections.value));
    this.selectedSection = this.selectedLesson.sections.filter(x => x.sectionId == sectionId.value)[0];
    this.selectedSectionIndex = this.selectedLesson.sections.indexOf(this.selectedSection);

    this.selectedSection.mentorAnswers = [];

    if (this.mentorLessonAnswers) {
      this.mentorLessonAnswers.forEach(item => {
        let reqSectionAnswers = item.sectionAnswers.filter(x => x.sectionId == sectionId.value);

        if (reqSectionAnswers && reqSectionAnswers.length > 0) {

          reqSectionAnswers.forEach(an => {
            this.selectedSection.mentorAnswers.push({
              firstName: item.firstName,
              lastName: item.lastName,
              designation: item.designation,
              name: item.name,
              profileAvatar: item.profileAvatar,
              answers: an.answers
            })
          })
        }
      });
    }
  }

  score(val) {
    this.configsService.loading(true);
    this.lessonService.testNewScoringAlgo(this.selectedSection.sectionId, val).subscribe(res => {
      this.response = res.result;
    }, () => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }


  clearLessonCategory() {
    this.lessonCategories.value = null;

    this.levels.options = [];
    this.levels.value = null;

    this.lesson.options = [];
    this.lesson.value = null;

    this.sections.options = [];
    this.sections.value = null;

    this.lessons = [];
    this.userAnswer = null;
    this.mentorLessonAnswers = null;
    this.response = null;
    this.selectedLesson = null;
    this.selectedSection = null;
    this.selectedSectionIndex = null;
  }

  clearLevels() {
    this.levels.value = null;

    this.lesson.options = [];
    this.lesson.value = null;

    this.sections.options = [];
    this.sections.value = null;
    this.userAnswer = null;
    this.mentorLessonAnswers = null;
    this.response = null;
    this.selectedLesson = null;
    this.selectedSection = null;
    this.selectedSectionIndex = null;
  }

  clearLesson() {
    this.lesson.value = null;
    this.selectedLesson = null;
    this.selectedSection = null;
    this.sections.options = [];
    this.sections.value = null;
    this.userAnswer = null;
    this.mentorLessonAnswers = null;
    this.response = null;
    this.selectedLesson = null;
    this.selectedSection = null;
    this.selectedSectionIndex = null;
  }

  clearSection() {
    this.sections.value = null;
    this.selectedSection = null;
    this.selectedSectionIndex = null;
    this.userAnswer = null;
    this.response = null;
    this.selectedSection = null;
    this.selectedSectionIndex = null;
  }

  getMentorForLesson(lessonId) {
    this.configsService.loading(true);
    this.lessonService.getMentorAnswer(lessonId).subscribe((data) => {
      if (data) {
        this.mentorLessonAnswers = data.result;
      }
    }, () => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    })
  }

}
