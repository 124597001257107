import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appSettings } from 'src/environments/environment';
import { MiniBenchmarkQuestionAnswer } from '../components/mini-benchmark/mini-benchmark.model';

@Injectable({
    providedIn: "root",
})
export class MiniBenchmarkService {
    constructor(private http: HttpClient) { }

    miniBenchmarkUrl: string = appSettings.miniBenchmarkUrl + "MiniBenchmarks/";
    gleacKey: string = appSettings.miniBenchmarkKey;

    getAllRoles() {
        var apiUrl = this.miniBenchmarkUrl + "lookup/job-role" + "?key=" + this.gleacKey;
        var response = this.http.get<any>(apiUrl);
        return response;
    }

    getQuestions(jobRoleId: string) {
        var apiUrl = this.miniBenchmarkUrl + jobRoleId + "?key=" + this.gleacKey;
        var response = this.http.get<any>(apiUrl);
        return response;
    }

    saveAnswer(answerDetails: MiniBenchmarkQuestionAnswer) {
        var url = this.miniBenchmarkUrl + "answer" + "?key=" + this.gleacKey;
        return this.http.post<any>(url, answerDetails);
    }
}