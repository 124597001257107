import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-similarity-index',
  templateUrl: './similarity-index.component.html',
  styleUrls: ['./similarity-index.component.less']
})
export class SimilarityIndexComponent implements OnInit {

  @Input() similarityScore: number;
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  private cx
  private cy
  private radius
  private total = 2 * Math.PI
  constructor() {

  }

  screenWidth: number = window.innerWidth;
  screenHeight: number = window.innerHeight;
  ratio: number = window.devicePixelRatio * .2 || 1;

  ngOnInit() {
    if (environment.isMobile) {

      this.ctx = this.canvas.nativeElement.getContext('2d');
      var style = this.canvas.nativeElement.style;
      style.width = "68%";
      style.height = "100px";
      this.cx = this.canvas.nativeElement.width / 2;
      this.cy = this.canvas.nativeElement.height ;
      this.radius = this.canvas.nativeElement.width / 3
      var grd = this.ctx.createLinearGradient(this.cx - this.radius, this.cy, this.cx * 2, 0);
      grd.addColorStop(0, "#ccdeff");
      grd.addColorStop(1, "#005CFF");
      var finalValue = this.similarityScore * 100;
      var value = 0
      setInterval(() => {
        this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
        var progress = this.total - Math.PI * (1 - value / 100)
        var text
        if (value < finalValue - 1) {
          text = `${value}%`
        } else {
          text = `${value + Number((finalValue % 1).toFixed(2))}%`
        }
        //unfilled progress
        this.ctx.strokeStyle = "#ccdeff"
        this.ctx.lineWidth = 24
        this.ctx.beginPath();
        this.ctx.arc(this.cx, this.cy, this.radius, Math.PI, this.total, false)
        this.ctx.stroke()

        //filled progress
        this.ctx.strokeStyle = grd
        this.ctx.lineWidth = 24
        this.ctx.beginPath()
        this.ctx.arc(this.cx, this.cy, this.radius, Math.PI, progress, false)
        this.ctx.stroke()

        //filled progress bg
        this.ctx.fillStyle = "#ccdeff"
        this.ctx.beginPath()
        this.ctx.arc(this.cx, this.cy, this.radius - 12, Math.PI, progress, false)
        this.ctx.lineTo(this.cx, this.cy)
        this.ctx.fill()

        //text
        this.ctx.fillStyle = "#005CFF"
        this.ctx.textAlign = "center"
        this.ctx.font = "bold 24px Montserrat"
        this.ctx.fillText(this.convertToText(text), this.cx, this.cy - 10)

        if (value < finalValue - 1) {
          value += 1
        }
      }, 10)
    }
    else {
      var style = this.canvas.nativeElement.style;
      style.width = "50%";
      style.height = "auto";
      this.canvas.nativeElement.width = this.screenWidth;
      this.canvas.nativeElement.height = this.screenHeight;
      this.ctx = this.canvas.nativeElement.getContext('2d');
      this.cx = (this.canvas.nativeElement.width / 2);
      this.cy = this.canvas.nativeElement.height;
      this.radius = (this.canvas.nativeElement.width / 2) - 100;

      var grd = this.ctx.createLinearGradient(this.cx - this.radius, this.cy, this.cx * 2, 0);
      grd.addColorStop(0, "#ccdeff");
      grd.addColorStop(1, "#005CFF");
      var finalValue = this.similarityScore * 100;
      var value = 0
      setInterval(() => {
        this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
        var progress = this.total - Math.PI * (1 - value / 100)
        var text
        if (value < finalValue - 1) {
          text = `${value}%`
        } else {
          text = `${value + Number((finalValue % 1).toFixed(2))}%`
        }
        //unfilled progress
        this.ctx.strokeStyle = "#ccdeff"
        this.ctx.lineWidth = 120
        this.ctx.beginPath()
        this.ctx.arc(this.cx, this.cy, this.radius, Math.PI, this.total, false)
        this.ctx.stroke()

        //filled progress
        this.ctx.strokeStyle = grd
        this.ctx.lineWidth = 120
        this.ctx.beginPath()
        this.ctx.arc(this.cx, this.cy, this.radius, Math.PI, progress, false)
        this.ctx.stroke()

        //filled progress bg
        this.ctx.fillStyle = "#ccdeff"
        this.ctx.beginPath()
        this.ctx.arc(this.cx, this.cy, this.radius - 60, Math.PI, progress, false)
        this.ctx.lineTo(this.cx, this.cy)
        this.ctx.fill()

        //text
        this.ctx.fillStyle = "#005CFF"
        this.ctx.textAlign = "center"
        this.ctx.font = "bold 180px Montserrat"
        this.ctx.fillText(this.convertToText(text), this.cx, this.cy - 50)


        if (value <= finalValue - 1) {
          value += 1
        }
      }, 10)
    }
  }

  convertToText(text : string)
  {
     let score = Number(text.replace('%', '').trim());


     if(score > 65)
     {
       return "High"
     }
     else if(score >= 40 && score <=65)
     {
       return "Medium"
     }
     else
     {
       return "Low"
     }
  }
}
