import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { UserType } from 'src/app/components/common/common.model';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { MentorService } from 'src/app/services/mentor.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.less']
})
export class HomeLayoutComponent implements OnInit {
  constructor(private router: Router,
    public usersService: UsersService,
    public socialService: AuthService,
    private configsService: ConfigsService,
    private mentorService: MentorService,) {

  }


  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;

  ngOnInit() {
    this.usersService.isAdmin = true;
    // if (this.usersService.currentUser.authRole.toLowerCase() === UserType.SA.toLowerCase()) {
    //   this.usersService.isAdmin = true;
    // }
  }

  ngOnChanges() {
  }

  globalBlogArticlesPopup() {
    this.configsService.loading(true);
    this.mentorService.getGlobalBlogArticles().subscribe(response => {
      this.iscreatePopup = true;
      this.create_popup = {
        display: true,
        bulk_upload: false,
        cssClass: 'col-15',
        createrName: "globalBlogArticles",
        title: "Global Blog Articles",
        buttonPositive: "Update",
        field: [
          {
            label: "Blog Articles",
            key: "blogArticlesJSON",
            cssClass: 'col-12',
            type: "blog_articles",
            value: response && response.result.data && response.result.data.length > 0 ? response.result.data : [{}]
          },
        ],
        closeModal: () => {
          this.iscreatePopup = false;
        },
        createrCall: (form: any, skillData, uploadedFileName, blogArticles, nftMentor) => {
          let mentorPost: any = { globalBlogArticlesList: [] };

          mentorPost.globalBlogArticlesList = blogArticles[0].title ? blogArticles : null;
          this.mentorService.addGlobalBlogArticles(mentorPost).subscribe(response => {
            this.postStatus(response);
          },
            error => {
              this.configsService.loading(false);
            });

        }
      }
      this.configsService.loading(false);

    }, error => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });

  }

  logoutUser() {
    this.socialService.signOut();
    this.usersService.currentUser = null;
    localStorage.clear();
    this.usersService.stopRefreshTokenTimer();
    this.router.navigate(['/login']);
  }

  goToUserReport() {
    this.router.navigate(['/user/report']);
  }

  goToMiniBenchmarkReport() {
    this.router.navigate(['/mini-benchmark-report',]);
  }

  goToRoles() {
    this.router.navigate(['/roles',]);
  }

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.result ? "Global blog articles updated successfully." : "Error occurred.",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  iscreatePopup: boolean = false;
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    cssClass: '',
    createrName: '',
    buttonPositive: "",
    field: [],
    closeModal: function () {
    },
    createrCall: function (form, skillData, uploadFileName, blogArticles, nftMentor) {
    },
  };
}
