import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {ConfigsService} from 'src/app/services/configs.service';
import {CommunicationService} from 'src/app/services/communication.service';
import {UsersService} from 'src/app/services/users.service';
import {CommonService} from "../../services/common.service";
import {EmailEditorComponent} from 'angular-email-editor';
import {event} from 'jquery';

@Component({
  selector: 'app-communications-popup',
  templateUrl: './communications-popup.component.html',
  styleUrls: ['./communications-popup.component.less']
})
export class CommunicationsPopupComponent implements OnInit {

  CommunicationLibraryId: any;
  isEdit: boolean = false;
  title: any;
  emailContent: any;
  id: any;
  isTemplate: any;
  isAssign: any;

  library: [];
  pageNumber: number = 1;
  pageSize: number = 10;

  onApiCall = false;

  htmlContent: string = "";
  fileName: string = "";

  scheduleDate: any;

  excludeList = [
    {label: 'Mobile App Users', value: 'Mobile App Users'},
    {label: 'Minibenchmark Users', value: 'Minibenchmark Users'},
    {label: 'Masterbenchmark Users', value: 'Masterbenchmark Users'},
    {label: 'Mentors', value: 'Mentors'}
  ]

  @ViewChild(EmailEditorComponent, null)
  private emailEditor: EmailEditorComponent;

  @ViewChild('groupsPag', {static: false}) groupsPaginator: any;
  @ViewChild('usersPag', {static: false}) usersPaginator: any;
  @ViewChild('organizationsPag', {static: false}) organizationsPaginator: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private configsService: ConfigsService, router: Router,
              dialog: MatDialog, private CommunicationService: CommunicationService, public dialogRef: MatDialogRef<CommunicationsPopupComponent>,
              private usersService: UsersService,
              private commonService: CommonService) {
    this.CommunicationLibraryId = data.CommunicationLibraryId;
    this.isEdit = data.isEdit;
    this.fileName = data.fileName;
    this.title = data.title;
    this.emailContent = data.content;
    this.model.emailSubject = data.emailSubject;
    this.isShowDropdown = data.isShowDropdown;
    this.model.id = data.id;
    this.isAssign = data.isAssign;
    if (data.scheduleDate !== undefined) {
      this.scheduleDate = this.convertUTCDateToLocalDate(data.scheduleDate);
    }
    this.id = data.id;
    this.isTemplate = data.isTemplate;
    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })

    this.model.emailBranding = data.emailBranding ? data.emailBranding : "GLEAC";
    this.model.fromEmail = data.fromEmail ? data.fromEmail : "oliver@gleac.com";
    this.model.replyEmail = data.replyEmail ? data.replyEmail : "oliver@gleac.com";
    this.excludeListValues = data.excludeList ? data.excludeList.split(",") : [];
  }

  public show = [];
  todayDate: Date = new Date();

  excludeListValues: any = []

  public model = {
    id: 0,
    status: false,
    emailSubject: "",
    fileName: "",
    htmlContent: "",
    scheduleDate: "",
    groupIds: [],
    organizationIds: [],
    userIds: [],
    externalEmails: [],
    editorJSON: "",
    communicationLibraryId: 0,
    emailBranding: "GLEAC",
    fromEmail: "oliver@gleac.com",
    replyEmail: "oliver@gleac.com",
    excludeList: ''
  }

  error: any = {
    subject: {display: false, message: ""},
    fileName: {display: false, message: ""},
    scheduleDate: {display: false, message: ""},
    Assignees: {display: false, message: ""},
    fromEmail: {display: false, message: ""},
    replyEmail: {display: false, message: ""}
  };

  validateScheduleDateDate() {
    if (this.model.scheduleDate === undefined || this.model.scheduleDate === "") {
      this.error.scheduleDate.display = true;
      this.error.scheduleDate.message = "Schedule date cannot be empty";
    } else {
      this.error.scheduleDate.display = false;
      this.error.scheduleDate.message = "";
    }

    if (this.model.fromEmail) {
      if (!this.model.fromEmail.match('^[\\w-\\.]+@gleac.com$')) {
        this.error.fromEmail.display = true;
        this.error.fromEmail.message = "Invalid From Email.";
      }
    }

    if (this.model.replyEmail) {
      if (!this.model.replyEmail.match('^[\\w-\\.]+@gleac.com$')) {
        this.error.replyEmail.display = true;
        this.error.replyEmail.message = "Invalid Reply Email.";
      }
    }
  }

  selectScreen: boolean = true;
  firstScreen: boolean = false;
  secondScreen: boolean = false;

  toggleScreens(selectScreen: boolean, firstScreen: boolean, secondScreen: boolean) {
    this.selectScreen = selectScreen;
    this.firstScreen = firstScreen;
    this.secondScreen = secondScreen;
  }

  @ViewChild("fileDropRef", {static: false}) fileDropEl: ElementRef;

  organizations: any = [];
  organizationsPageNumber: any = 1;
  organizationsPageSize: any = 10;
  searchOrganizationsString: string = "";

  groups: any = [];
  groupsPageNumber: any = 1;
  groupsPageSize: any = 10;
  searchGroupsString: string = "";

  users: any = [];
  usersPageNumber: any = 1;
  usersPageSize: any = 10;
  searchUsersString: string = "";

  isClosedFlag: boolean = false;
  isResolutionError: boolean = false;
  iserrorPopup: boolean = false;
  editRequest: any = {};
  error_popup = {};
  issuccessPopup: boolean = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  isShowDropdown = false;

  ngOnInit() {
    this.getGroups();
    this.getUsers();
    this.getOrganizations();
    this.getAllCommunicationTemplates();
    // this.isShowDropdown = true;
    if (this.isEdit) {
      this.toggleScreens(false, true, false);
      this.model.fileName = this.fileName;
      this.model.scheduleDate = this.scheduleDate;
      if (this.isTemplate) {
        this.loadTemplate();
      } else {
        this.loadTemplateByName(this.fileName + ".html", this.isTemplate);
      }

    } else if (this.isAssign) {
      this.toggleScreens(true, false, false);
      if (this.isTemplate) {
        this.loadTemplate();
      } else {
        this.loadTemplateByName(this.fileName + ".html", true);
      }
    } else if (this.isShowDropdown) {
      this.toggleScreens(true, false, false);
    } else {
      this.toggleScreens(true, false, false);
      this.model.fileName = this.fileName;
      this.loadTemplateByName(this.fileName + ".html", this.isTemplate);
      this.isShowDropdown = false;
    }
  }

  convertUTCDateToLocalDate(date) {
    if (typeof date.getMonth !== 'function') {
      date = new Date(date);
    }
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  getAllCommunicationTemplates() {
    this.CommunicationService.getAllCommunicationTemplates().subscribe(
      (res) => {
        if (res) {
          let communicationsData = {
            list: res.results,
            totalCount: res.totalCount
          }
          this.library = communicationsData.list;
        } else {
          this.library = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  continueSelectTemplate() {
    if (!this.model.emailSubject || !this.model.emailSubject.trim()) {
      this.error.subject.display = true;
      this.error.subject.message = "Title is required.";
      return;
    } else {
      this.error.subject.display = false;
      this.error.subject.message = "";
      this.model.emailSubject = this.model.emailSubject.trim()
    }

    this.emailEditor.editor.exportHtml((data) => {
      this.model.htmlContent = data.html;
      this.model.editorJSON = JSON.stringify(data);
      this.toggleScreens(false, false, true);
    });

  }

  saveTemplate() {

    if (!this.isEdit) {
      if (this.model.emailSubject === "" || this.model.emailSubject === undefined) {
        this.error.subject.display = true;
        this.error.subject.message = "Title is required.";
        return;
      } else {
        this.error.subject.display = false;
        this.error.subject.message = "";
        this.model.emailSubject = this.model.emailSubject.trim()
      }
    }

    this.configsService.loading(true);
    this.emailEditor.editor.exportHtml((data) => {
      this.model.htmlContent = data.html;
      this.model.editorJSON = JSON.stringify(data);

      let CommunicationLibraryData = {
        title: this.model.fileName !== "" ? this.model.fileName : this.title,
        editorJSON: JSON.stringify(data),
        id: this.model.communicationLibraryId === 0 ? this.CommunicationLibraryId : this.model.communicationLibraryId
      };

      this.CommunicationService.createCommunicationLibrary(CommunicationLibraryData).subscribe((res) => {
          if (res.existingTemplate) {
            this.showToast(false, "Template with title '" + CommunicationLibraryData.title + "' already exist.", false);
          } else if (!this.isTemplate || this.isAssign || this.isShowDropdown) {
            this.toggleScreens(false, false, true);
          } else {
            this.showToast(false, "Template updated successfully", true);
            this.isClosedFlag = true;
          }
        }, (err) => {
          console.log("err", err);
        },
        () => {
          this.configsService.loading(false);
        }
      );
    });
  }

  closeModal() {
    this.dialogRef.close({
      confirm: false
    });
  }

  selectChangeHandler(event) {
    console.log('event: ', event.target['value']);
    if (event.target.value != 'Select template') {
      this.CommunicationLibraryId = event.target['value'].split('-')[0];
      this.title = event.target['value'].split('-')[1];
      this.isTemplate = true;
      this.loadTemplate();
    }
  }

  save() {

    this.validateScheduleDateDate();

    if (this.error.scheduleDate.display) return;
    if (this.error.fromEmail.display) return;
    if (this.error.replyEmail.display) return;

    if (this.model.id === 0 || this.model.id === undefined) {
      if (this.model.groupIds.length === 0 && this.model.organizationIds.length === 0 && this.model.externalEmails.length === 0 && this.model.userIds.length === 0) {
        this.error.Assignees.display = true;
        this.error.Assignees.message = "Select at least one assignee from tabs";
        return;
      } else {
        this.error.Assignees.display = false;
        this.error.Assignees.message = "";
      }

      if (this.error.scheduleDate.display) return;
    }

    if (this.model.htmlContent === "") {
      this.emailEditor.editor.exportHtml((data) => {
        this.model.htmlContent = data.html;
        this.model.editorJSON = JSON.stringify(data);
        this.model.fileName = this.fileName;
        this.AddCommunication();
      });
    } else {
      this.AddCommunication();
    }
  }

  AddCommunication() {

    if (this.excludeListValues && this.excludeListValues.length > 0) {
      this.model.excludeList = this.excludeListValues.join(',');
    }

    this.CommunicationService.createCommunication(this.model).subscribe((res) => {
        if (res.hasError) {
          this.showToast(true, res.errors[0], true);
          return;
        } else {
          this.showToast(false, "Communication scheduled successfully", true);
          this.isClosedFlag = true;
        }
      },
      (err) => {
        console.log("err", err);
      },
      () => {
        this.configsService.loading(false);
      });
  }

  SetupCreateCommunicationScreen() {
    this.model.emailSubject = '';
    this.model.fileName = '';
    this.model.communicationLibraryId = 0;
    this.model.fromEmail = '';
    this.model.replyEmail = '';
    this.model.emailBranding = '';
    this.model.excludeList = '';
    this.excludeListValues = [];
    // this.emailEditor.editor.loadDesign({});
    this.isShowDropdown = false;
  }

  isSuccessPopup: boolean = false;
  isErrorPopup: boolean = false;
  isConfirmPopup = false;
  errorMessage = '';


  showToast(isError, message, closeModel) {
    if (isError) {
      this.isErrorPopup = true;
      this.error_popup = {display: true, message: message};
    } else {
      this.isSuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          if (closeModel) {
            this.closeModal();
          }
        },
      };
    }
  }

  // called when the editor is created
  editorLoaded() {
    this.configsService.loading(true);
    console.log('editorLoaded');
    // load the design json here
    // this.emailEditor.editor.loadDesign({});
    if (this.isEdit) {
      this.loadTemplate();
    }
  }

  // called when the editor has finished loading
  editorReady() {
    console.log('editorReady');
    this.configsService.loading(false);
  }

  loadTemplate() {
    console.log("loadTemplate called");
    this.CommunicationService.getHtmlContent(this.CommunicationLibraryId, this.isTemplate).subscribe(
      (res) => {
        this.emailEditor.editor.loadDesign(JSON.parse(res.editorJSON).design);
        console.log(res);
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  loadTemplateByName(name, isTemplate) {
    console.log("loadTemplateByName called");
    this.CommunicationService.getHtmlContentByName(name, isTemplate).subscribe(
      (res) => {
        this.emailEditor.editor.loadDesign(JSON.parse(res.editorJSON).design);
        if (isTemplate) {
          this.model.communicationLibraryId = res.communicationLibraryId;
        } else {
          this.model.id = res.communicationLibraryId;
        }
        console.log(res);
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  //popup list methods
  getGroups() {
    this.CommunicationService.getGroups(this.searchGroupsString, this.groupsPageSize, this.groupsPageNumber).subscribe((res) => {
        res.result.results.forEach((value, index) => {
          if (this.model.groupIds.indexOf(value.group_id) !== -1) {
            res.result.results[index].is_checked = true;
          }
        });

        this.groups = res.result;
      },
      (err) => {
        console.log(err);
      });
  }

  filterGroupsData(params) {
    this.searchGroupsString = params[0];
    //this.groupsPageNumber = 1;
    //this.getGroups();
    this.groupsPaginator.changePage(0);
  }

  paginateGroups(event) {
    this.groupsPageNumber = (event.page + 1);
    this.getGroups();
  }

  addGroupToModel(group, $event) {
    if (group.is_checked && this.model.groupIds.indexOf(group.group_id) === -1) {
      this.model.groupIds.push(group.group_id);
    } else if (!group.is_checked && this.model.groupIds.indexOf(group.group_id) !== -1) {
      this.model.groupIds.forEach((value, index) => {
        if (value == group.group_id) this.model.groupIds.splice(index, 1);
      });
    }
  }

  //organizations
  getOrganizations() {
    this.CommunicationService.getCompanies(this.searchOrganizationsString, this.organizationsPageSize, this.organizationsPageNumber).subscribe((res) => {
        res.result.results.forEach((value, index) => {
          if (this.model.organizationIds.indexOf(value.company_id) !== -1) {
            res.result.results[index].is_checked = true;
          }
        });
        this.organizations = res.result;
      },
      (err) => {
        console.log(err);
      });
  }

  filterOrganizationsData(params) {
    this.searchOrganizationsString = params[0];
    //this.organizationsPageNumber = 1;
    //this.getOrganizations();
    this.organizationsPaginator.changePage(0);
  }

  paginateOrganization(event) {
    this.organizationsPageNumber = (event.page + 1);
    this.getOrganizations();
  }

  addOrganizationToModel(organization, $event) {
    if (organization.is_checked && this.model.organizationIds.indexOf(organization.company_id) === -1) {
      this.model.organizationIds.push(organization.company_id);
    } else if (!organization.is_checked && this.model.organizationIds.indexOf(organization.company_id) !== -1) {
      this.model.organizationIds.forEach((value, index) => {
        if (value == organization.company_id) this.model.organizationIds.splice(index, 1);
      });
    }
  }

  //Users
  getUsers() {
    this.usersService.getUsers(this.searchUsersString, this.usersPageSize, this.usersPageNumber).subscribe((res) => {
        res.result.results.forEach((value, index) => {
          if (this.model.userIds.indexOf(value.userId) !== -1) {
            res.result.results[index].isChecked = true;
          }
        });
        this.users = res.result;
      },
      (err) => {
        console.log(err);
      });
  }

  filterUsersData(params) {
    this.searchUsersString = params[0];
    //this.usersPageNumber = 1;
    //this.getUsers();
    this.usersPaginator.changePage(0);
  }

  paginateUsers(event) {
    this.usersPageNumber = (event.page + 1);
    this.getUsers();
  }

  addUserToModel(user, $event) {
    if (user.isChecked && this.model.userIds.indexOf(user.userId) === -1) {
      this.model.userIds.push(user.userId);
    } else if (!user.is_checked && this.model.userIds.indexOf(user.userId) !== -1) {
      this.model.userIds.forEach((value, index) => {
        if (value == user.userId) this.model.userIds.splice(index, 1);
      });
    }
  }

  selectBrandingChangeHandler(event) {
    this.model.emailBranding = event.target['value'];
  }

  selectFromEmailChangeHandler(event) {
    this.model.fromEmail = event.target['value'];
  }

  selectReplyEmailChangeHandler(event) {
    this.model.replyEmail = event.target['value'];
  }


  //Bulk upload

  files;
  bulkInfo = {
    url: "Communication/bulkupload/communicationUsers/",
    templateUrl:
      "https://gleac-bulk-template.s3.us-west-2.amazonaws.com/Communication-User-Template.xlsx",
  };
  isreportPopup = false;
  report_popup = {
    display: false,
  };

  removeFiles() {
    this.files = "";
  }

  loadFile(event) {
    let maxFileSize = 1024 * 1024 * 10;
    if (event.target.files[0].size > maxFileSize) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Maximum file size supported is 10 MB",
      };
    } else if (
      event.target.files[0].type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.target.files[0].type != "application/vnd.ms-excel"
    ) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = {display: true, message: "Invalid File Type"};
    } else {
      this.configsService.loading(true);
      let bulkFile = event.target.files[0];
      let url = this.bulkInfo.url;
      this.commonService.fileUpload(url, bulkFile, true).subscribe(
        (data) => {
          this.bulkUploadResult(data);
          // this.getEditData(this.campaignId);
        },
        (err) => {
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err[0]};
          this.configsService.loading(false);
        },
        () => {
          this.configsService.loading(false);
        }
      );
    }
  }


  bulkUploadResult(result) {
    if (!result.value.hasError) {
      if (!result.value.result) {
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: result.value.message};
      } else {
        // this.files = "";
        this.isreportPopup = true;
        this.report_popup.display = true;
        this.report_popup["count_list"] = [];
        let errorData = [];
        this.model.userIds = result.value.result.userIds;
        this.model.externalEmails = result.value.result.externalEmails;
        for (var key in result.value.result) {
          if (key != 'userIds' && key != 'externalEmails') {
            this.report_popup["count_list"].push({
              label: key,
              value: result.value.result[key],
            });
          }
          if (key == "errorData") {
            errorData = result.value.result[key];
          }
        }
        if (errorData) {
          let data = [];
          errorData.map((f, i) => {
            if (f) {
              data.push({name: f.split(",")[0], message: f.split(",")[1]});
            }
          });
          this.report_popup["errorData"] = data;
        }
      }
    } else {
      this.iserrorPopup = true;
      this.error_popup = {display: true, message: result.value.message};
    }
  }
}


