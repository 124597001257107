import {Injectable} from "@angular/core";
import {appSettings} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NftBundleService {
  baseUrl: string = appSettings.mentorsUrl;

  constructor(private http: HttpClient) {
  }

  getNftBundles(pageNumber, filterString, sortColumn = '', sortOrder = '') {
    let url = this.baseUrl + "NftBundle?PageNumber=" + pageNumber + "&PageSize=10";
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  exportNftBundles(pageNumber, filterString, sortColumn = '', sortOrder = '') {
    let url = this.baseUrl + "ExportAllNftBundles?PageNumber=" + pageNumber + "&PageSize=10";
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType: 'blob'
    })
  }

  createNftBundles(data) {
    let url = this.baseUrl + "NftBundle";
    return this.http.post<any>(url, data);
  }

  updateNftBundles(data) {
    let url = this.baseUrl + "NftBundle";
    return this.http.put<any>(url, data);
  }

  deleteNftBundle(id) {
    let url = this.baseUrl + "NftBundle/" + id;
    return this.http.delete<any>(url);
  }

}
