import {MatSortHeaderIntl} from "@angular/material/sort";

export class EventsQuery {
  searchText?: string;
  pageIndex: number = 1;
  pageSize: number = 10;
  sortColumn?: string;
  sortOrder?: string;
  status?: EventStatus;

  toQueryString(): string {
    let query = "?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;

    if (this.searchText) {
      query = query + "&searchText=" + encodeURIComponent(this.searchText);
    }
    if (this.sortColumn) {
      query = query + "&sortColumn=" + this.sortColumn;
    }

    if (this.sortOrder) {
      query = query + "&sortOrder=" + this.sortOrder;
    }

    if (this.status != null) {
      query = query + "&status=" + this.status;
    }

    return query;
  }
}


export enum EventStatus {
  UPCOMING,
  PAST
}
