import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from "@angular/core";
import { LessonsService } from "src/app/services/lessons.service";
import { CommonService } from "../../services/common.service";
import { LessonSkill } from "../../components/lessons/lessons.model";
import { GoalsService } from "src/app/services/goal.service";
import { Subscription, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.less"],
})
export class FilterComponent implements OnInit, OnDestroy {
  // items = [{name:'User1'},{name:'User2'},{name:'User3'},{name:'User4'},{name:'User5'}];
  @Input("filters") filters: any;
  private eventsSubscription: Subscription;

  @Input() events: Observable<void>;
  // @Input('companyId') companyId: any;
  @Output("dataFilter") dataFilter = new EventEmitter();

  @ViewChild('filterForm', { static: false}) filterform: NgForm;

  display = false;
  lessonCategoryNames = [];
  userGroupNames = [];
  companies = [];
  constructor(
    public lessonsService: LessonsService,
    private commonService: CommonService,
    public goalsService: GoalsService
  ) {}

  ngOnInit() {
    //return this.items;
    this.eventsSubscription = this.events.subscribe(() => this.clearFilters());

    this.lessonsService.getLessonCategories().subscribe((res) => {
      let lessonCategories: LessonSkill[] = res.result;
      this.lessonCategoryNames = lessonCategories.map((x) => {
        return {
          name: x.lessonCategoryName,
          id: x.lessonCategoryId,
        };
      });
    });

    this.goalsService.getUserGroups("").subscribe((res) => {
      let groupNames = res.result.results;
      this.userGroupNames = groupNames.map((x) => {
        return {
          name: x.group_name,
          value: x.group_id
        };
      });
    });

    this.goalsService.getUserCompanies("").subscribe((res) => {
      let companyNames = res.result.results;
      this.companies = companyNames.map((x) => {
        return {
          name: x.company_name,
          value: x.company_id
        };
      });
    });
  }
  closePopup(){
    this.display = false;
  }
  displayFilter(filters) {
    //this.items = [{name:'User1'},{name:'User2'},{name:'User3'},{name:'User4'},{name:'User5'}];
    if (filters) {
      this.display = true;
    } else {
      this.display = true;
    }
  }

  customFilter(event, form) {
    // this.display = false;
    if (event) {
      if (form.valid) {
        this.dataFilter.emit(arguments);
        this.display = false;
        // form.reset();
      }
    } else {
      this.dataFilter.emit(arguments);
      this.display = false;
      form.reset();
      form.submitted = false;
    }
  }

  customFilterGoals(event, form) {
    if (event) {
      if (form.valid) {
        this.dataFilter.emit(arguments);
        this.display = true;
      }
    } else {
      this.dataFilter.emit(arguments);
      this.display = true;
      form.submitted = false;
    }
  }

  clearFilters() {
    console.log('Goal filters cleared');
    this.filterform.reset();
    console.log(this.filterform);
  }

  preventClose(event) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    if (this.eventsSubscription)
      this.eventsSubscription.unsubscribe();
  }
}
