import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appSettings } from 'src/environments/environment';
import { PurchaseInfo } from '../dialogs/benchmark-popup/benchmark-popup.model';

@Injectable({
    providedIn: "root",
})
export class BenchmarkService {
    constructor(private http: HttpClient) { }

    billingUrl: string = appSettings.billingUrl;
    benchmarkUrl: string = appSettings.benchmarkUrl;

    getAllPacks() {
        var apiUrl = this.billingUrl + "Packs";
        var response = this.http.get<any>(apiUrl);
        return response;
    }

    initiatePurchase(purchaseInfo: PurchaseInfo) {
        let url = this.billingUrl + "Billing";
        return this.http.post<any>(url, purchaseInfo);
    }

    updatePurchase(sessionId: string) {
        var apiUrl = this.billingUrl + "Billing/update/stripe/" + sessionId;
        var response = this.http.get<any>(apiUrl);
        return response;
    }

    getUserBenchmarks(userId: string) {
        var apiUrl = this.benchmarkUrl + "IdentityBenchmarks/" + userId;
        var response = this.http.get<any>(apiUrl);
        return response;
    }
}