import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
  selector: '[appLessonSection]'
})
export class LessonSectionDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }
  @Input() sections: any;
}
