import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
//import { TransactionsService } from 'src/app/services/transactions.service';
import { CommonService } from "../../services/common.service";
import { GleacColumnDef } from "../data-grid-new-dashboard/data-grid-new-dashboard.model";
import { ConfigsService } from "../../services/configs.service";
import { BadgeComponent } from "../badge/badge.component";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { UsersService } from "../../services/users.service";
import { Sort } from '@angular/material/sort';
import { saveAs } from 'file-saver';


@Component({
  selector: "app-expo-analytics",
  templateUrl: "./expo-analytics.component.html",
  styleUrls: ["./expo-analytics.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-in", style({ transform: "translateX(70%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class ExpoAnalyticsComponent implements OnInit {
  page_title = "Expo Analytics";
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  today = new Date();
  repFromDate = new Date();
  repToDate = new Date();
  create_popup = {
    display: false,
    title: "",
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    },
  };
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  error_popup = {};
  filter: string = "ALL";
  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "Status",
      options: [
        { name: "Active", value: "1" },
        { name: "Deleted", value: "2" },
      ],
    },
  ];
  jobsList: any;
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    {
      data: "bio",
      displayName: "User Name",
      render: (data, row) => {
        if (!row.image) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        if (row.image && !row.image.startsWith("http")) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        return '<div> <img height="30" width="30" style="border-radius: 50%;margin-right: 5px " src="' + row.image + '"  alt="" /> <span style="color:#0065AD">' + row.bio + '</span></div>'
      }
    },
    { data: "email", displayName: "Email" },
    { data: "age", displayName: "Age" },
    {
      data: "createdOn",
      displayName: "Created On",
      render: (data, row) => {
        if (row.createdOn) {
          return this.datepipe.transform(row.createdOn, 'dd-M-yyyy');
        }
        return ' ';
      }
    },
    {
      data: "signedUp",
      displayName: "App Signup",
      render: (data, row) => {
        if (row.signedUp) {
          return this.datepipe.transform(row.signedUp, 'dd-M-yyyy');
        }

        return ' ';
      }
    },
    {
      data: "benchmark",
      displayName: "Benchmark",
      render: (data, row) => {
        if (row.benchmark) {
          return row.benchmark;
        }

        return ' ';
      }
    },
    {
      data: "score",
      displayName: "Score",
      render: (data, row) => {
        if (row.score > 0) {
          return row.score.toFixed(2);
        }

        return ' ';
      }
    },
    {
      data: "futureJobs",
      isJobsSummary: true,
      disableSort: true,
      displayName: "Jobs of the future",
      render: (data, row) => {
        if (row.futureJobs) {
          return '<span class="float-left" style="overflow: hidden" >' + '' + '</span>';
          //return row.futureJobs;
        }

        return ' ';
      }
    },
    {
      data: "gleacUserId",
      displayName: "App User",
      render: (data, row) => {
        if (row.gleacUserId != '00000000-0000-0000-0000-000000000000') {
          return 'Yes';
        }

        return 'No';
      }
    },
  ];
  pageIndex: number = 1;
  pageSize: number = 10;
  searchString = "";
  status = "";
  breadCrumsArray: any = [];
  visible: boolean = false;
  inputData: any;
  enableRow: boolean = true;
  queryParam: any = [];
  query;
  companyId;
  url = "";
  userUrl = "";
  creatFields: any = [
    {
      label: "First Name",
      key: "firstName",
      placeholder: "Enter the first name",
      type: "input",
      innertype: "text",
      pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
      required: "required",
      value: "",
    },
    {
      label: "Last Name",
      key: "lastName",
      placeholder: "Enter the last name",
      type: "input",
      innertype: "text",
      pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
      required: "required",
      value: "",
    },
    {
      label: "Email ID",
      key: "email",
      placeholder: "Enter the email ID",
      type: "input",
      innertype: "email",
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
      required: "required",
      value: "",
    },
    {
      label: "Company (Optional)",
      key: "companyId",
      placeholder: "Select a company",
      type: "dropdown",
      bindLabel: "name",
      multiple: false,
      value: {},
      options: [],
      apiUrl: "Company"
    },
    {
      label: "Group (Optional)",
      key: "groupId",
      placeholder: "Select a group",
      type: "dropdown",
      bindLabel: "name",
      multiple: true,
      value: [],
      options: []
    },
    { label: "User ID", key: "userId", type: "hidden", value: "" },
  ];

  totalExpoUsers = 0;
  totalEngagedUsers = 0;
  usersWithEmail = 0;
  usersWithBenchMark = 0;
  usersWithoutBenchmark = 0;

  constructor(
    private configsService: ConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public usersService: UsersService,
    private datepipe: DatePipe
  ) {
    // this.getUserList();
  }

  ngOnInit() {

    this.usersService.isMeasure = true;

    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
      if (this.companyId) {
        this.queryParam["companyId"] = this.companyId;
      }
    });
    this.queryParam["PageNumber"] = this.pageIndex;
    this.queryParam["PageSize"] = this.pageSize;
    this.queryParam["fromDate"] = this.datepipe.transform(this.today, 'M/d/yyyy');
    this.queryParam["toDate"] = this.datepipe.transform(this.today, 'M/d/yyyy');
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getUserList();
    // this.loadOptions(this.creatFields);
  }

  loadOptions(dataInput) {
    dataInput.map((field) => {
      if (field.apiUrl) {
        let requestData = [];
        this.configsService.loading(true);
        //requestData[field.paramsNeeded] = this.companyId;
        this.commonService
          .getListAlone(
            field.apiUrl,
            this.commonService.encodeQueryData(requestData)
          )
          .subscribe(
            (data) => {

              if (field.key == "companyId") {
                field.options = data.result.list.map((x) => {
                  return {
                    name: x.companyName,
                    id: x.companyId,
                    isChecked: false,
                  };
                });
              }
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
            }
          );
      }
    });
  }

  getUserList() {
    this.configsService.loading(true);
    this.url = "Expo/expousers/";
    this.userUrl = "Expo/expousers/";
    this.commonService.getExpoUsers(this.url, this.query).subscribe(
      (data) => {
        // console.log(data,this.query);
        if (data.result.exposUsers.length <= 0 && data.result.totalRecords != 0 && this.queryParam["PageNumber"] > 1) {
          // console.log(data,this.query['pageIndex']);
          this.queryParam["PageNumber"] = --this.queryParam["PageNumber"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getUserList();
        } else {

          this.totalExpoUsers = data.result.totalRecords;
          this.totalEngagedUsers = data.result.totalEngageUsers;
          this.usersWithEmail = data.result.usersWithEmails;
          this.usersWithBenchMark = data.result.userWithBenchmark;
          this.usersWithoutBenchmark = data.result.userWithoutBenchmark;

          let usersData = {
            list: data.result.exposUsers,
            totalCount: data.result.totalRecords
          }

          //this.jobsList = data.result.jobIndex;
          this.transactionsGridData = usersData;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  sortChange(event) {
    this.queryParam["sortColumn"] = event.active;
    this.queryParam["sortOrder"] = event.direction;
    this.queryParam["filterString"] = this.searchString;
    this.queryParam["fromDate"] = this.datepipe.transform(this.repFromDate, 'M/d/yyyy');
    this.queryParam["toDate"] = this.datepipe.transform(this.repToDate, 'M/d/yyyy');
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = "Expo/expousers/";
    this.configsService.loading(true);
    this.commonService.getExpoUsers(this.url, this.query).subscribe(
      (data) => {
        //this.jobsList = data.result.jobIndex;

        this.totalExpoUsers = data.result.totalRecords;
        this.totalEngagedUsers = data.result.totalEngageUsers;
        this.usersWithEmail = data.result.usersWithEmails;
        this.usersWithBenchMark = data.result.userWithBenchmark;
        this.usersWithoutBenchmark = data.result.userWithoutBenchmark;

        let usersData = {
          list: data.result.exposUsers,
          totalCount: data.result.totalRecords
        }

        this.transactionsGridData = usersData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["PageNumber"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getUserList();
  }

  filterData(event) {
    this.searchString = event ? event[0] : this.searchString;
    this.pageIndex = 1;
    this.queryParam["PageNumber"] = 1;
    this.queryParam["filterString"] = this.searchString.trim();
    this.queryParam["fromDate"] = this.datepipe.transform(this.repFromDate, 'M/d/yyyy');
    this.queryParam["toDate"] = this.datepipe.transform(this.repToDate, 'M/d/yyyy');
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = "Expo/expousers/";
    this.configsService.loading(true);
    this.commonService.getExpoUsers(this.url, this.query).subscribe(
      (data) => {
        //this.jobsList = data.result.jobIndex;

        this.totalExpoUsers = data.result.totalRecords;
        this.totalEngagedUsers = data.result.totalEngageUsers;
        this.usersWithEmail = data.result.usersWithEmails;
        this.usersWithBenchMark = data.result.userWithBenchmark;
        this.usersWithoutBenchmark = data.result.userWithoutBenchmark;

        let usersData = {
          list: data.result.exposUsers,
          totalCount: data.result.totalRecords
        }

        this.transactionsGridData = usersData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        this.queryParam[key] = data[key];
      }
    } else {
      delete this.queryParam.status;
    }
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = "Users/";
    this.configsService.loading(true);
    this.commonService.getListDataGridV2(this.url, this.query).subscribe(
      (data) => {

        let usersData = {
          list: data.result.data,
          totalCount: data.result.totalRecords
        }

        //this.jobsList = data.result.jobIndex;
        this.transactionsGridData = usersData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  changeSearchText(event) {
  }

  editUser(event) {
    if (event.action == "edit") {
      this.creatFields.map((f, i) => {
        if (event.data[f.key]) {
          f.value = event.data[f.key];
        }
        if (f.key == "groupId") {
          f.value = event.data["groups"].map((x) => {
            return {
              name: x.groupName,
              id: x.groupId,
            };
          });
        }
        if (f.key == "userId") {
          f.value = event.data.userId;
        }

        if (f.key == "companyId" && event.data.company) {
          f.value = { name: event.data.company, id: event.data.companyId, isChecked: true }
        }

      });
      this.createUser(event.data);
    } else if (event.action == "delete") {
      this.deleteUser(event.data.userId);
    }
  }

  createUser(data) {
    this.loadOptions(this.creatFields);
    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: true,
      bulkInfo: {
        url: "Users/bulkupload/user/",
        queryId: this.companyId,
        templateUrl:
          "https://gleac-bulk-template.s3.us-west-2.amazonaws.com/User-Template.xlsx",
      },
      title: data ? "Edit User" : "Create Users",
      buttonPositive: data ? "Update" : "Create",
      field: this.creatFields,
      refreshData: () => {
        this.getUserList();
      },
      closeModal: () => {
        this.create_popup.display = false;
        this.iscreatePopup = false;
      },
      createrCall: (form: NgForm) => {
        if (form.valid) {
          this.create_popup.display = false;
          this.configsService.loading(true);
          let ListData = [];
          ListData = { ...form.value };
          if (data) {
            let groups = [];
            if (form.value.groupId) {
              form.value.groupId.map((f, i) => {
                if (f.id) {
                  groups.push(f.id);
                } else if (f.groupId) {
                  groups.push(f.groupId);
                } else {
                  groups.push(f);
                }
              });
            }
            ListData["groupId"] = groups;

            ListData["companyId"] = ListData["companyId"].id;
            ListData["userId"] = form.value.userId;

            this.commonService
              .updateDataV2("Users", ListData)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          } else {
            let listdata = [];
            listdata.push(ListData);

            if (listdata[0].companyId) {
              listdata[0].companyId = listdata[0].companyId.id;
            }


            this.commonService
              .createDataV2("Users", listdata[0])
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          }
        }
      },
    };
  }

  toggleSlide(e) {
    this.visible = e;
  }

  showDetails(event) {
    if (this.enableRow) {
      if (this.visible) {
        this.toggleSlide(false);
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      } else {
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      }
    } else {
      this.enableRow = true;
    }
  }

  /** Delete User
   -- @params Transaction ID --
   **/

  deleteUser(uId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Users?userId=" + uId;
        this.commonService.deleteDataV2(url).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
   **/

  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.result,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.getUserList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  exportToExcel() {

    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.configsService.loading(true);
    this.commonService
      .exportExpoUsers("Expo/ExportExpoUsers", this.query)
      .subscribe(
        data => saveAs(data, "ExpoUsers" + '.xlsx'),
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }
}
