import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import {trigger, transition, animate, style} from "@angular/animations";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {GleacColumnDef} from "../data-grid/data-grid.model";
import {ConfigsService} from "../../services/configs.service";
import {CommonService} from "../../services/common.service";
import {formatDate} from "@angular/common";
import * as _moment from 'moment';
import {UsersService} from "../../services/users.service";

@Component({
  selector: "app-lesson-campaign",
  templateUrl: "./lesson-campaign.component.html",
  styleUrls: ["./lesson-campaign.component.less"],
})
export class LessonCampaignComponent implements OnInit {
  page_title = "Campaigns";
  breadCrumsArray: any = [];
  filterArray: any = [
    {label: "Skill", type: "select", key: "lessonSkill"},
    {label: "Start Date", type: "datePickerStart", key: "fromDate"},
    {label: "End Date", type: "datePickerEnd", key: "toDate"},
  ];
  form;
  pageIndex: number = 1;
  pageSize: number = 10;
  searchString = "";
  campaignGridData: any;
  currentTime: Date;
  campaignColData: GleacColumnDef[] = [
    {
      displayName: "Campaign Name",
      data: "lessonCampaignName",
      isExpandable: true,
      render: (data, row) => {
        if (row.isReplicated === true) {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span>' +
            row.lessonCampaignName +
            "</span></div>"
          );
        } else if (row.isReplicated === false) {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span>' +
            row.lessonCampaignName +
            "</span></div>"
          );
        }
      },
    },
    {
      displayName: "Skill",
      data: "lessonSkill",
      tooltip: true,
      render: (data, row) => {
        var lessonskills = row.lessonSkill.map((skill) => {
          return skill.lessonCategoryName;
        });
        row.tooltipData = lessonskills ? lessonskills : null;
        if (lessonskills.length > 1) {
          return (
            `<div>` +
            lessonskills[0] +
            `<span class="ml-2 text_blue">+` +
            (lessonskills.length - 1) +
            ` more</span></div>`
          );
        } else {
          return `<div>` + lessonskills + `</div>`;
        }
      },
    },
    {displayName: "Completion Rate", data: "completionRate"},
    {displayName: "Engaged users", data: "engagedUsers"},
    {
      displayName: "Start date",
      data: "lessonCampaignStartDate",
      render: (data, row) => {
        var campaignStartDate = _moment.utc(row.lessonCampaignStartDate).format("DD MMMM YYYY");
        // var onboardedDateTime = new Date(row.lessonCampaignStartDate);
        // return `${onboardedDateTime.getDate()}/${
        //   onboardedDateTime.getMonth() + 1
        // }/${onboardedDateTime.getFullYear()}`;
        return campaignStartDate;
      },
    },
    {
      displayName: "End date",
      data: "lessonCampaignEndDate",
      render: (data, row) => {
        var campaignEnddate = _moment.utc(row.lessonCampaignEndDate).format("DD MMMM YYYY");
        return campaignEnddate;
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          lessonCampaignCheck : true
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
        },
        {
          label: "Replicate",
          url: "#",
          functionType: "replicate",
          image: "assets/images/replicate_icon.svg",
        },
      ],
    },
  ];
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  isconfirmPopup: boolean = false;
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    buttonPositive: "",
    field: [],
    closeModal: function () {
    },
    createrCall: function (form) {
    },
  };
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  url;
  error_popup = {};
  queryParam: any = [];
  query;
  companyId;

  constructor(
    private configsService: ConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    public usersService: UsersService,
    private commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.usersService.isUsers = true;

    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
      if (this.companyId) {
        this.queryParam["companyId"] = this.companyId;
      }
    });
    this.queryParam["pageIndex"] = this.pageIndex;
    this.queryParam["pageSize"] = this.pageSize;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getCampaignList();
  }

  newCampaign() {
    let breadcrumb = {
      pathName: "Campaign",
      pathUrl: this.router.url,
    };
    this.breadCrumsArray.push(breadcrumb);
    this.configsService.setBreadcrumb(this.breadCrumsArray);
    this.router.navigate(["companies/campaign/" + this.companyId + "/new"]);
  }

  getCampaignList() {
    this.configsService.loading(true);
    this.url = "Campaigns/";
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        if (data.result.list.length <= 0 && data.result.list.totalCount != 0 && this.queryParam["pageIndex"] > 1) {
          this.queryParam["pageIndex"] = --this.queryParam["pageIndex"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getCampaignList();
        } else {
          this.campaignGridData = data.result;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["pageIndex"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getCampaignList();
  }

  changeSearchText(event) {
  }

  campaignDetails(event) {
    let breadcrumb = {
      pathName: "Campaign",
      pathUrl: this.router.url,
    };
    this.breadCrumsArray.push(breadcrumb);
    this.configsService.setBreadcrumb(this.breadCrumsArray);
    this.router.navigate([
      "companies/campaign/" +
      event.data.companyId +
      "/detail/" +
      event.data.lessonCampaignId,
    ]);
  }

  filterData(params) {
    this.searchString = params[0];
    this.queryParam["searchText"] = this.searchString;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Campaigns/search/" : "Campaigns/";
    this.configsService.loading(true);
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        this.campaignGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      let listData = {};
      listData["companyId"] = this.companyId;
      if (data["skill"]["id"])
        listData["lessonCategoryId"] = data["skill"]["id"];
      listData["startDate"] = formatDate(
        data["startdate"],
        "yyyy-MM-dd",
        "en-US"
      );
      listData["endDate"] = formatDate(data["enddate"], "yyyy-MM-dd", "en-US");
      listData["pageIndex"] = this.pageIndex;
      listData["pageSize"] = this.pageSize;
      let sendingData = this.commonService.encodeQueryData(listData);
      let apiUrl = this.searchString
        ? "Campaigns/skill/search/"
        : "Campaigns/skill/";
      this.configsService.loading(true);
      this.commonService.getListDataGrid(apiUrl, sendingData).subscribe(
        (data) => {
          this.campaignGridData = data.result;
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
    } else {
      // let data = params[1]["value"];
      // let listData = {};
      // for (var key in data) {
      //   if(data[key]!=null){
      //     if(key=="startdate" || "enddate")
      //     {
      //     listData[key]=
      //     formatDate(
      //       data[key],
      //       "yyyy-MM-dd",
      //       "en-US"
      //     );
      //     }else{
      //       listData[key]= data[key];
      //     }
      //   }
      //   listData["pageIndex"] = this.pageIndex;
      //   listData["pageSize"] = this.pageSize;
      // }
      // console.log(listData);
      // let sendingData = this.commonService.encodeQueryData(listData);
      // let apiUrl = this.searchString
      //   ? "Campaigns/skill/search/"
      //   : "Campaigns/skill/";
      // this.configsService.loading(true);
      // this.commonService.getListDataGrid(apiUrl, sendingData).subscribe(
      //   (data) => {
      //     this.campaignGridData = data.result;
      //   },
      //   (err) => {
      //     this.configsService.loading(false);
      //     this.iserrorPopup = true;
      //     this.error_popup = { display: true, message: err.error.errors[0] };
      //   },
      //   () => {
      //     this.configsService.loading(false);
      //   }
      // );
      this.getCampaignList();

    }
  }

  editCampaign(event) {
    if (event.action == "edit") {
      let breadcrumb = {
        pathName: "Campaign",
        pathUrl: this.router.url,
      };
      this.breadCrumsArray.push(breadcrumb);
      this.configsService.setBreadcrumb(this.breadCrumsArray);
      this.router.navigate([
        "companies/campaign/" +
        this.companyId +
        "/" +
        event.data.lessonCampaignId +
        "/edit",
      ]);
    } else if (event.action == "delete") {
      this.deleteCampaign(event.data.lessonCampaignId);
    } else if (event.action == "replicate") {
      this.replicateCampaign(event.data.lessonCampaignId);
    }
  }

  /** Delete Campaign
   -- @params lessonCampaign ID --
   **/

  deleteCampaign(cId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Campaigns?lessonCampaignId=";
        this.commonService.deleteData(url, cId).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true, message: err.error.errors[0]};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** Replicate Campaign
   -- @params lessonCampaign ID --
   **/

  replicateCampaign(cId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Replicate this Campaign?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Campaigns/Replicate/" + cId;
        this.commonService.createData(url, "", false).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true, message: err.error.errors[0]};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
   **/

  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.getCampaignList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }
}
