import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserType} from '../components/common/common.model';
import {UsersService} from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class TokenGuardService implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
  }

  constructor(private usersService: UsersService, private router: Router) {

  }
}
