import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.less']
})
export class QuestionCardComponent implements OnInit , OnChanges {
  @Input("question") question;
  safeUrl: any;
  constructor(private sanitizer: DomSanitizer) { }
   
  ngOnChanges(changes: SimpleChanges){
    if(changes.question){
      this.question = changes.question.currentValue;
      if(this.question && this.question.media_type ==="video"){
        this.safeUrl= this.sanitize(this.question.media_url);
      }
    }
  }
  ngOnInit() {
  }
  sanitize(url:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
 }
}
