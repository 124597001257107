import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  communicationUrl: string = appSettings.dashboardUrl;
  goalUrl: string = appSettings.mobileUrl;

  constructor(private http: HttpClient) { }

  getAllCommunications(pageNumber, pageSize) {
    var url = this.communicationUrl + "Communication/communications?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  createCommunication(communicationData) {
    var url = this.communicationUrl + "Communication/AddCommunication";
    var response = this.http.post<any>(url, communicationData);
    return response;
  }

  createCommunicationLibrary(communicationLibraryData) {
    var url = this.communicationUrl + "Communication/AddCommunicationsToLibrary";
    var response = this.http.post<any>(url, communicationLibraryData);
    return response;
  }

  getCommunicationsForLibrary(pageNumber, pageSize) {
    var url = this.communicationUrl + "Communication/CommunicationLibrary?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getAllCommunicationTemplates() {
    var url = this.communicationUrl + "Communication/AllCommunicationLibrary";
    return this.http.get<any>(url);
  }

  search(searchString, pageNumber, pageSize, sortColumn, sortOrder, status, date) {
    var url = this.communicationUrl + "Communication/Search?filterString=" + searchString + "&pageNumber="
      + pageNumber + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&status=" + status + "&date=" + date;
    return this.http.get<any>(url);
  }

  exportCommunications(searchString, sortColumn, sortOrder, status, date, timeDiff) {
    var url = this.communicationUrl + "Communication/CommunicationsExport?filterString=" + searchString
      + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&status=" + status + "&date=" + date + "&timeDiff=" + timeDiff;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportOpenedEmailsToExcel(communicationId) {
    var url = this.communicationUrl + "Communication/CommunicationsOpenedEmailsExport/" + communicationId;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportClickedEmailsToExcel(communicationId) {
    var url = this.communicationUrl + "Communication/CommunicationsClickedEmailsExport/" + communicationId;
    return this.http.get(url, { responseType: 'blob' });
  }

  exportCommunicationsLibrary(searchString, sortColumn, sortOrder, timeDiff) {
    var url = this.communicationUrl + "Communication/CommunicationsLibraryExport?filterString=" + searchString + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&timeDiff=" + timeDiff;
    return this.http.get(url, { responseType: 'blob' });
  }

  searchCommunicationsLibrary(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    var url = this.communicationUrl + "Communication/CommunicationLibrary?filterString=" + searchString + "&pageNumber="
      + pageNumber + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder;
    return this.http.get<any>(url);
  }

  getUserGroups(filterString: string, pageSize?: number, pageNumber?: number) {
    if (pageSize === undefined) pageSize = 10000;
    if (pageNumber === undefined) pageNumber = 1;

    var url = this.communicationUrl + "Goals/UserGroups?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getUserCompanies(filterString: string, pageSize?: number, pageNumber?: number) {
    if (pageSize === undefined) pageSize = 10000;
    if (pageNumber === undefined) pageNumber = 1;

    var url = this.communicationUrl + "Goals/UserOrganisations?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  updateCommunications(communicationData) {
    var url = this.communicationUrl + "Communication/EditCommunication";
    var response = this.http.post<any>(url, communicationData);
    return response;
  }

  deleteCommunications(communicationData) {
    var url = this.communicationUrl + "Communication/DeleteCommunication";
    var response = this.http.post<any>(url, communicationData);
    return response;
  }

  deleteCommunicationsLibrary(communicationData) {
    var url = this.communicationUrl + "Communication/DeleteCommunicationLibrary";
    var response = this.http.post<any>(url, communicationData);
    return response;
  }

  getHtmlContent(id, isTemplate) {
    var url = this.communicationUrl + "Communication/template?id=" + id + "&isTemplate=" + isTemplate;
    return this.http.get<any>(url);
  }

  getHtmlContentByName(name, isTemplate) {
    var url = this.communicationUrl + "Communication/templateByName?name=" + name + "&isTemplate=" + isTemplate;
    return this.http.get<any>(url);
  }

  getGroups(filterString: string, pageSize?: number, pageNumber?: number) {
    if (pageSize === undefined) pageSize = 10;
    if (pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/Groups?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getCompanies(filterString: string, pageSize?: number, pageNumber?: number) {
    if (pageSize === undefined) pageSize = 10;
    if (pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/Organisations?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getViewEmailHtml(fileName : string) {
    var url = this.communicationUrl + "Communication/GetHtmlContentForEmailView/" + fileName;
    return this.http.get<any>(url);
  }

  duplicateTemplate(id : number, title : string) {
    var url = this.communicationUrl + "Communication/DuplicateTemplate/" + id;
    return this.http.post<any>(url, {title : title});
  }
}
