import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SectionElementAnswer } from 'src/app/components/common/common.model';
import { CandidatePeerAnswer } from './answer.model';
import { ConfigsService } from 'src/app/services/configs.service';
import { LessonsService } from 'src/app/services/lessons.service';

@Component({
  selector: 'app-answer-popup',
  templateUrl: './answer-popup.component.html',
  styleUrls: ['./answer-popup.component.less']
})
export class AnswerPopupComponent implements OnInit {

  errorMessage : string;
  answers: any;
  error_popup = { display: false };
  answerId: any;
  lessonId: any;
  isPeerAnswer: any;
  candidateId : any;

  constructor(private configsService: ConfigsService, private lessonService: LessonsService, public dialogRef: MatDialogRef<AnswerPopupComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.answers = data.answers.map(x => {
      return x.sectionAnswer ? x.sectionAnswer : x.sectionElementAnswers
    })
    this.answerId = data.answerId;
    this.lessonId = data.lessonId;
    this.isPeerAnswer = data.isPeerAnswer;
    this.candidateId = data.candidateId;
    // this.answers = data.answers.sectionAnswer || data.answers.map(x => x.sectionElementAnswers);
  }

  ngOnInit() {
  }

  reduceElementAnswers = (sectionElementAnswers: SectionElementAnswer[]) => {
    if (typeof (sectionElementAnswers) !== "string") {
      return sectionElementAnswers.map(x => x.answer).join("\n");
    }
    else return sectionElementAnswers;
  }

  closeModal(data?) {
    this.dialogRef.close({
      confirm: false
    });
  }

  managePeerAnswer() {
    this.configsService.loading(true);
    let peerAnswer: CandidatePeerAnswer = {
      LessonId: this.lessonId,
      CandidateAnswerId: this.answerId,
      CandidateId : this.candidateId,
      PeerAnswerId: null
    };

    this.lessonService.managePeerAnswer(peerAnswer).subscribe(data => {
      this.configsService.loading(false);
      if(data.hasError)
      {
        this.error_popup = { display: true };
        this.errorMessage = (data.errors && data.errors[0] ? data.errors[0] : "Something went wrong. Please try again.");

        let context = this;
        setTimeout(function ()
        {
          context.errorMessage = '';
        }, 5000)
      }
      else
      {
        if (data.result == true) {
          this.dialogRef.close({
            confirm: true
          });
        }
      }
    }, err => {
      this.configsService.loading(false);
      this.error_popup = { display: true };
      this.dialogRef.close({
        confirm: false
      });
    });
  }
}
