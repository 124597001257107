import {Injectable} from "@angular/core";
import {appSettings} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  baseUrl: string = appSettings.mentorsUrl;

  constructor(private http: HttpClient) {
  }

  getCoupons(pageNumber, filterString, sortColumn = '', sortOrder = '') {
    let url = this.baseUrl + "Coupon?PageNumber=" + pageNumber + "&PageSize=10";
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  addCoupon(data) {
    let url = this.baseUrl + "Coupon";
    return this.http.post<any>(url, data);
  }

  updateCoupon(data) {
    let url = this.baseUrl + "Coupon";
    return this.http.put<any>(url, data);
  }

  deleteCoupon(data) {
    let url = this.baseUrl + "Coupon/" + data.id;
    return this.http.delete<any>(url);
  }

  exportCoupons(pageNumber, filterString, sortColumn = '', sortOrder = '') {
    let timeDiff = new Date().getTimezoneOffset();
    let url = this.baseUrl + "Coupon/ExportAllCouponCodes?PageNumber=" + pageNumber + "&PageSize=10&timeDiff=" + timeDiff;
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType: 'blob'
    })
  }
}
