import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { ConfigsService } from "../../services/configs.service";
import { LessonsService } from "src/app/services/lessons.service";
import { CommonService } from "src/app/services/common.service";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";

@Component({
  selector: "app-skill-add",
  templateUrl: "./skill-add.component.html",
  styleUrls: ["./skill-add.component.less"],
})
export class SkillAddComponent implements OnInit {
  breadCrumsArray: any = [];
  selectedSkills: any = [];
  companyArray: any = [];
  campaignList: any;
  companyId;
  company;
  campaign;
  lessonCampaignId;
  queryParam: any = [];
  query;
  url = "";
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  isconfirmPopup = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {},
  };
  error_popup = {};
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    value: [],
    buttonPositive: "",
    field: [],
    changedCheck: function (e, i, skills, lesson, dragData) {},
    closeModal: function () {},
    createrCall: function (form, skillData) {},
  };
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {},
    negativeButton: function () {},
  };
  selectedSkillData = [];
  constructor(
    public lessonsService: LessonsService,
    private router: Router,
    public usersService: UsersService,
    private configsService: ConfigsService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    // this.selectedSkills = this.lessonsService.getCampaignLessons();
    this.getCartList();
    this.getCompanyList();
    this.cartSkills();
  }

  getCartList() {}

  removeSkill(skill, index) {
    // this.selectedSkills.splice(index, 1);
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
       this.configsService.loading(true);
      let url = "LessonCart?lessonCartId=";
      this.commonService.deleteData(url, skill.lessonCartId).subscribe(
        (data) => {
          this.postStatus(data, true);
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true };
        },
        () => {
          this.configsService.loading(false);
        }); 
        this.confirm.display = false;
      }
    }
  }

  getCompanyList() {
    this.configsService.loading(true);
    this.url = "Company/";
    this.commonService.getListAlone(this.url).subscribe(
      (data) => {
        this.companyArray = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  listCampaign(e) {
    if (e) {
      this.companyId = e.companyId;
      this.queryParam["companyId"] = e.companyId;
      this.configsService.loading(true);
      this.url = "Campaigns/draft";
      this.query = this.commonService.encodeQueryData(this.queryParam);
      this.commonService.getListAlone(this.url, this.query).subscribe(
        (data) => {
          this.campaignList = data.result.list;
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true };
        },
        () => {
          this.configsService.loading(false);
        }
      );
      this.cartSkills(this.query);
    } else {
      this.cartSkills();
      this.campaignList = [];
    }
  }

  cartSkills(querytoPass?: any) {
    this.configsService.loading(true);
    let cartUrl = "LessonCart";
    // let apiLink = this.commonService.encodeQueryData(this.queryParam);
    this.commonService.getListAlone(cartUrl, querytoPass).subscribe(
      (data) => {
        this.selectedSkills = data.result.list;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  addLessons() {
    let sendSkill = [];
    this.selectedSkillData = [];
    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: false,
      title: "Add New Lessons",
      value: this.selectedSkillData,
      buttonPositive: "Add",
      field: [
        {
          label: "Skill",
          key: "skill",
          placeholder: "Select Skill",
          type: "dropdown",
          bindLabel: "lessonCategoryName",
          innertype: "text",
          required: "required",
          value: [],
          options: [],
          selectApi: "LessonCategories",
          paramsNeeded: { companyId: this.companyId },
          isdependent: true,
          dependentParam: "skill_level",
        },
        {
          label: "Skill Level",
          key: "skill_level",
          placeholder: "Select Skill Level",
          type: "dropdown",
          bindLabel: "name",
          innertype: "text",
          required: "required",
          value: [],
          options: [],
          paramsNeeded: [{ companyId: this.companyId, categoryId: "skill" }],
          dependentParam: "lessons",
        },
        {
          label: "Lessons",
          key: "lessons",
          type: "lessonsList",
          lessons: [],
          isShow: false,
        },
      ],
      closeModal: () => {
        //this.create_popup.display = false;
        this.iscreatePopup = false;
      },
      changedCheck: (e, i, skills, lesson) => {
        if (e) {
          skills["active_item"] = true;
        } else {
          skills["active_item"] = false;
        }
        if (sendSkill.length > 0) {
          let insert = 0;
          sendSkill.map((m, n) => {
            if (m.lessonId != skills.lessonId) {
              insert = insert + 1;
            } else {
              if (!e) {
                sendSkill.splice(n, 1);
              }
            }
            if (insert == sendSkill.length) {
              if (e) {
                sendSkill.push({
                  lessonId: skills.lessonId,
                  lessonTitle: skills.lessonTitle,
                  lessonSummary: skills.lessonSummary,
                  title: skills.title,
                  level: skills.level,
                  subLevel: skills.subLevel,
                });
              }
            }
          });
        } else {
          if (e) {
            sendSkill.push({
              lessonId: skills.lessonId,
              lessonTitle: skills.lessonTitle,
              lessonSummary: skills.lessonSummary,
              title: skills.title,
              level: skills.level,
              subLevel: skills.subLevel,
            });
          }
        }

        this.selectedSkillData = sendSkill;
        this.create_popup["value"] = this.selectedSkillData;
      },
      createrCall: (form: NgForm, skillData) => {
        if (form.valid) {
          let sendingData = {};
          sendingData["lessonSkill"] = form.value.skill;
          sendingData["lessonSkill"]["lessons"] = this.create_popup.value
            ? this.create_popup.value
            : this.selectedSkillData;
          this.url = "LessonCart";
          this.configsService.loading(true);
          this.commonService.createData(this.url, sendingData, false).subscribe(
            (data) => {
              this.configsService.loading(false);
              this.postStatus(data, true);
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
              form.reset();
              this.iscreatePopup = false;
            }
          );
        }
      },
    };
  }

  addtoCampaign(form) {

    if (form.valid) {
      this.configsService.loading(true);
      let submitParams = [];
      submitParams["companyId"] = this.companyId;
      submitParams["lessonCampaignId"] = this.lessonCampaignId;
      let sendingData = this.commonService.encodeQueryData(submitParams);
      let url = "Campaigns/lessoncart?" + sendingData;
      this.commonService.createData(url, "", false).subscribe(
        (data) => {
          this.postStatus(data, false);
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true };
        },
        () => {
          this.configsService.loading(false);
        }
      );
    }
    if(form.invalid)
    {  
    // Got focus to the error field
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    invalidFields[1].focus();  
    invalidFields[0].scrollIntoView({ behavior: 'smooth' });
    }
  }

  /** CallBack Fucntion
	   -- @params result --
	  **/

  postStatus(result, loadSkills) {
    console.log(result, loadSkills);
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          if (loadSkills) {
            this.cartSkills(this.query);
          } else {
            this.issuccessPopup = false;
            this.router.navigate(["companies/campaign/" + this.companyId]);
            // window.location.href='companies/campaign/' + this.companyId;
          }
        },
      };
    } else {
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  selectCampaign(campaign) {
    if (campaign) {
      this.lessonCampaignId = campaign.lessonCampaignId;
    }
  }
}
