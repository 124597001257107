import {
  OnInit,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  FormArray,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { SelectItem } from "primeng/api";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { LessonsService } from "src/app/services/lessons.service";
import { LessonSkill } from "../lessons/lessons.model";
import { UsersService } from "src/app/services/users.service";
import { MatDialogRef, MatDialog } from "@angular/material";
import { appSettings } from "src/environments/environment";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { ConfigsService } from "../../services/configs.service";
import { CommonService } from "../../services/common.service";
import { formatDate } from "@angular/common";
import * as _moment from "moment";
import { MessageService } from "primeng/api";
import { AppComponent } from "../../app.component";
@Component({
  selector: "app-add-campaign",
  templateUrl: "./add-campaign.component.html",
  styleUrls: ["./add-campaign.component.less"],
  providers: [MessageService],
})
export class AddCampaignComponent implements OnInit {
  files = "";
  iscreatePopup: boolean = false;
  breadCrumsArray: any = [];
  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    search: new FormControl(),
  });
  from_date;
  to_date;
  lesson_credit: SelectItem[];
  campaign_lesson_credit;
  campaignNote;
  today = new Date();
  skillList = [];
  dropdownSettings = {};
  selectedSkills: any;
  skill_item = [
    { name: "Nirosha" },
    { name: "Nirosha" },
    { name: "Nirosha" },
    { name: "Nirosha" },
    { name: "Nirosha" },
  ];
  lessonCategories: LessonSkill[];
  lessonCategoryNames: { categoryName: string; categoryId: string }[] = [];
  tabCount = 0;
  tabContent: any = [];
  levelList: any = [];
  dropList: any = [];
  selectLevelList: [];
  singleClass: boolean = false;
  active;
  sessions;
  active_session = "";
  totalLevelDisplay = [];
  answer;
  slider_visible: boolean = false;
  previewSection: any;
  selectedLesson: any;
  prevLessons: any;
  prevLevels: any;
  items;
  selectedLessons = "";
  selectedLevels = "";
  bucketUrl: string;
  s3BucketUrl: string = appSettings.s3BucketUrl;
  selectedlessonSkill = [];
  campName;
  lessonCampaignEndDate;
  lessonCredit;
  internalNote;
  lessonSkill;
  lessonCampaignStartDate;
  lessonsPerDay;
  companyId;
  campaignId;
  url;
  issuccessPopup: boolean = false;
  iserrorPopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {},
  };
  sendSkill = [];
  editData;
  lessontoastCredits = 0;
  isEdit : boolean = false;
  constructor(
    protected lessonsService: LessonsService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    private sanitizer: DomSanitizer,
    protected usersService: UsersService,
    private configsService: ConfigsService,
    protected dialog: MatDialog,
    private commonService: CommonService,
    private ref: ChangeDetectorRef,
    private messageService: MessageService,
    private app: AppComponent
  ) {
    this.lesson_credit = [
      { label: "Enable", value: { id: 1, name: "Enable", item: true } },
      { label: "Disable", value: { id: 0, name: "Disable", item: false } },
    ];
    this.campaign_lesson_credit = "";

    this.dropdownSettings = {
      singleSelection: false,
      idField: "item_id",
      textField: "item_text",
      enableCheckAll: false,
      itemsShowLimit: 2,
      allowSearchFilter: true,
    };
  }

  ngOnInit() {
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
      if (params["campaignId"]) {
        this.campaignId = params["campaignId"];
        this.getEditData(this.campaignId);
      }
    });
    this.getSkillDataList();
  }

  getEditData(campaignId) {
    this.configsService.loading(true);
    this.url = "Campaigns/" + campaignId;
    this.commonService.getCommonApi(this.url, "get", "").subscribe(
      (data) => {
        this.editData = data.result;
        if (this.editData) {
          if(!this.editData.isReplicated){
            this.isEdit= true;
          }
          // if (this.editData.lessonCampaignStartDate) {
          //   this.lessonCampaignStartDate = _moment
          //     .utc(this.editData.lessonCampaignStartDate)
          //     .format("DD MMMM YYYY");
          // } else {
          //   this.lessonCampaignStartDate = "";
          // }
          // if (this.editData.lessonCampaignEndDate) {
          //   this.lessonCampaignEndDate = _moment
          //     .utc(this.editData.lessonCampaignEndDate)
          //     .format("DD MMMM YYYY");
          // } else {
          //   this.lessonCampaignEndDate = "";
          // }
          this.campName = this.editData.lessonCampaignName;
          this.lessonCampaignStartDate = this.editData.lessonCampaignStartDate
            ? new Date(this.editData.lessonCampaignStartDate)
            : "";
          this.lessonCampaignEndDate = this.editData.lessonCampaignEndDate
            ? new Date(this.editData.lessonCampaignEndDate)
            : "";
          // console.log(this.lessonCampaignStartDate, this.lessonCampaignEndDate);
          this.internalNote = this.editData.internalNote;
          this.lessonCredit = this.editData.lessonCredit
            ? this.lesson_credit[0].value
            : this.lesson_credit[1].value;
          this.lessonsPerDay = this.editData.lessonsPerDay
            ? this.editData.lessonsPerDay
            : 0;
          if (this.editData.lessonSkill) {
            this.lessonSkill = this.editData.lessonSkill.map((x) => {
              return {
                item_text: x.lessonCategoryName,
                item_id: x.lessonCategoryId,
                item_summary: x.skillSummary,
                item_imgSrc: x.imageSrc,
                lessons: x.lessons,
              };
            });
          }
          this.lessonSkill.map((f, i) => {
            this.getSubskills(f);
          });
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getSkillDataList() {
    this.lessonsService
      .getLessonCategoriesbyCompany(this.companyId)
      .subscribe((res) => {
        let lessonCategories: LessonSkill[] = res.result;
        this.skillList = lessonCategories.map((x) => {
          return {
            item_text: x.lessonCategoryName,
            item_id: x.lessonCategoryId,
            item_summary: x.skillSummary,
            item_imgSrc: x.imageSrc,
          };
        });
      });
  }

  removeFiles() {
    this.files = "";
  }

  changeStartDate(e) {
    this.lessonCampaignEndDate = "";
  }
  /**
  }
	[Get SubSkills of selected Category]
	@param {Category}
  	**/
  getSubskills(e) {
    let lessonCategory = e.item_id;
    this.lessonsService
      .getLessonCategoryDetailsById(e.item_id)
      .subscribe((skill) => {
        this.levelList = [];
        this.dropList = [];
        this.tabContent.push(skill.result);
        let level = [];
        let dropItem = [];
        this.levelList = [...new Set(skill.result.lessons.map((x) => x.level))];
        let lessonList = [];
        this.levelList.map((f, i) => {
          lessonList = [];
          skill.result.lessons.map((g, j) => {
            if (g.level == f) {
              g.active_item = false;
              lessonList.push(g);
            }
            this.levelList.splice(i, 1, lessonList);
            this.dropList;
          });
          dropItem.push(i + 1);
        });

        this.selectedlessonSkill.push({
          lessonCategoryId: e.item_id,
          lessonCategoryName: e.item_text,
          levelWiseLessons: this.levelList,
          dropList: dropItem,
        });
        this.skillList.map((f, i) => {
          if (e.item_id == f.item_id) {
            this.sendSkill.push({
              lessonCategoryId: f.item_id,
              lessonCategoryName: f.item_text,
              skillSummary: f.item_summary,
              imageSrc: f.item_imgSrc,
              lessons: e.lessons ? e.lessons : [],
            });
            if (e.lessons) {
              e.lessons.map((k, l) => {
                this.selectedlessonSkill.map((h, p) => {
                  h.levelWiseLessons.map((m, n) => {
                    m.map((x, y) => {
                      if (x.lessonId == k.lessonId) {
                        x["active_item"] = true;
                      }
                    });
                  });
                });
              });
            }
          }
        });
      });
  }
  /**
	[Remove SubSkills of selected Category]
	@param {Category}
  	**/
  removeSubskills(e) {
    this.tabContent.map((list, index) => {
      if (list.lessonCategoryName == e.item_text) {
        this.tabContent.splice(index, 1);
      }
    });
    this.sendSkill.map((list, index) => {
      if (list.lessonCategoryId == e.item_id) {
        this.sendSkill.splice(index, 1);
      }
    });
    this.selectedlessonSkill.map((list, index) => {
      if (list.lessonCategoryName == e.item_text) {
        this.selectedlessonSkill.splice(index, 1);
      }
    });
  }

  /**
	[Drag and Drop Event listener]
	@param {Dropped Element}
  	**/
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    let dataContainer = event.container.data;
    if (this.sendSkill) {
      this.sendSkill.map((f, i) => {
        if (f) {
          event.container.data.map((x, y) => {
            if (x.lessonCategoryId == f.lessonCategoryId) {
              if (f.lessons) {
                f.lessons.map((l, m) => {
                  if (l.lessonId == x.lessonId) {
                    var tmp = f.lessons[m];
                    f.lessons[m] = f.lessons[y];
                    f.lessons[y] = tmp;
                  }
                });
              }
            }
          });
        }
      });
    }
  }

  addClass(event, skills, single, cSkills, aa) {
    // if(cSkills)
    //this.singleClass=!this.singleClass;
  }
  changedCheck(e, i, skills, lesson) {
    console.log(e, this.lessontoastCredits);
    if (e) {
      this.lessontoastCredits++;
      this.active = i;
      this.singleClass = true;
      skills.active_item = true;
      this.messageService.clear();
      this.messageService.add({
        severity: "info",
      });
    } else {
      if (this.lessontoastCredits > 0) {
        this.lessontoastCredits--;
      } else {
        this.lessontoastCredits = 0;
      }
      this.messageService.clear();
      this.messageService.add({
        severity: "info",
      });

      this.active = i;
      this.singleClass = false;
      skills.active_item = false;
    }
    this.sendSkill.map((f, i) => {
      if (lesson.lessonCategoryId == f.lessonCategoryId) {
        if (f.lessons.length > 0) {
          let insert = 0;
          f.lessons.map((m, n) => {
            if (m.lessonId != skills.lessonId) {
              insert = insert + 1;
            } else {
              if (!e) {
                f.lessons.splice(n, 1);
              }
            }
            if (insert == f.lessons.length) {
              if (e) {
                f.lessons.push({
                  lessonId: skills.lessonId,
                  lessonTitle: skills.lessonTitle,
                  lessonSummary: skills.lessonSummary,
                  title: skills.title,
                  level: skills.level,
                  subLevel: skills.subLevel,
                });
              }
            }
          });
        } else {
          if (e) {
            f.lessons.push({
              lessonId: skills.lessonId,
              lessonTitle: skills.lessonTitle,
              lessonSummary: skills.lessonSummary,
              title: skills.title,
              level: skills.level,
              subLevel: skills.subLevel,
            });
          }
        }
      }
    });
  }

  sanitizeSection(section) {
    section.sectionControls.forEach(
      (x) =>
        (x.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          x.sectionElementContent
        ))
    );
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getImagefromS3(event, index) {
    let originalUrl = "";
    if (event.i !== undefined) {
      originalUrl = this.previewSection.sectionControls[event.i]
        .sectionElementContent;
    } else {
      originalUrl = this.previewSection.sectionControls[index]
        .sectionElementContent;
    }
    if (this.previewSection) {
      this.bucketUrl = this.s3BucketUrl + originalUrl.split(".com")[1];
      event.target.src = this.bucketUrl;
    }
  }

  previewSlider(data, currentLevel, topLevels, totalLevel) {
    console.log(data, currentLevel, topLevels, totalLevel)
    this.slider_visible = true;
    this.previewSection = "";
    this.selectedLesson = data;
    this.sessions = data.sections;
    this.selectedLessons = data.title;
    this.sessionPreview(data.sections[0]);
    this.prevLessons = currentLevel;
    let level = [];
    topLevels.map((f, i) => {
      if (i <= totalLevel) {
        f["level"] = "Level " + (i + 1);
        level.push(f);
      }
    });
    this.prevLevels = level ? level : "";
    this.selectedLevels = this.prevLessons.level;
  }

  sessionPreview(lesson) {
    console.log(lesson)
    if (lesson) {
      this.active_session = lesson.sectionTitle;
      this.previewSection = lesson;
      this.sanitizeSection(this.previewSection);
    } else {
      this.active_session = "";
      this.previewSection = "";
    }
  }

  prevLessonChange(event) {
    if (event) {
      this.selectedLesson = event;
      this.sessions = event.sections;
      this.selectedLessons = event.title;
      this.sessionPreview(event.sections[0]);
    }
  }

  prevLevelChange(event) {
    this.prevLessons = event;
    this.selectedLessons = null;
  }

  createCampaign(form, navData) {
    if (this.sendSkill.length > 0) {
      let isSubskill = false;
      isSubskill = this.sendSkill.some((skill) => {
        return skill.lessons.length > 0;
      });
      if (!isSubskill) {
        form.form.controls["lessonSkill"].setErrors({ incorrect: true });
      } else {
        form.form.controls["lessonSkill"].setErrors(null);
      }
    }
    form.submitted = true;
    if (form.valid) {
      let ListData = {};
      ListData["companyId"] = this.companyId;
      ListData["lessonCampaignName"] = form.value.lessonCampaignName;
      ListData["lessonCredit"] = form.value.lessonCredit
        ? form.value.lessonCredit.item
        : false;
      if (form.value.lessonCampaignStartDate) {
        let strdate = new Date(form.value.lessonCampaignStartDate);
        ListData["lessonCampaignStartDate"] = formatDate(
          strdate,
          "yyyy-MM-dd",
          "en-US"
        );
      } else {
        if(this.isEdit){
          ListData["lessonCampaignStartDate"] = this.editData.lessonCampaignStartDate;
        }else{
        ListData["lessonCampaignStartDate"] = "";
        }
      }
      // ListData["lessonCampaignStartDate"] = form.value.lessonCampaignStartDate
      //   ? form.value.lessonCampaignStartDate.toISOString()
      //   : "";
      if (form.value.lessonCampaignEndDate) {
        let enddate = new Date(form.value.lessonCampaignEndDate);
        ListData["lessonCampaignEndDate"] = formatDate(
          enddate,
          "yyyy-MM-dd",
          "en-US"
        );
      } else {
        ListData["lessonCampaignEndDate"] = "";
      }
      // ListData["lessonCampaignEndDate"] = form.value.lessonCampaignEndDate
      //   ? form.value.lessonCampaignEndDate.toISOString()
      //   : "";
      ListData["internalNote"] = form.value.internalNote;
      ListData["lessonsPerDay"] = form.value.lessonsPerDay;
      ListData["lessonSkill"] = this.sendSkill;
      this.configsService.loading(true);
      if (this.editData) {
        ListData["lessonCampaignId"] = this.editData.lessonCampaignId;
        this.url = "Campaigns/";
        this.commonService.updateData(this.url, ListData, false).subscribe(
          (data) => {
            this.postStatus(data.value, navData);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
            form.submitted = false;
          }
        );
      } else {
        this.url = "Campaigns/continue/";
        this.commonService.createData(this.url, ListData, false).subscribe(
          (data) => {
            this.postStatus(data.value, navData);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
            form.submitted = false;
          }
        );
      }
    }
  }

  /** CallBack Fucntion
   -- @params result --
  **/

  postStatus(result, navData) {
    if (!result.hasError) {
      if (navData == "save") {
        this.breadCrumsArray = this.breadCrumsArray.filter((item) => {
          return item.pathName != "Campaign";
        });
        this.configsService.setBreadcrumb(this.breadCrumsArray);
        this.router.navigate(["companies/campaign/" + this.companyId]);
      } else {
        let breadcrumb = {
          pathName: "Create Campaign",
          pathUrl: this.router.url,
        };
        this.breadCrumsArray.push(breadcrumb);
        if (this.editData) {
          this.router.navigate([
            "companies/campaign/" +
              this.editData.lessonCampaignId +
              "/" +
              this.companyId +
              "/assign-user",
          ]);
        } else {
          this.router.navigate([
            "companies/campaign/" +
              result.result +
              "/" +
              this.companyId +
              "/assign-user",
          ]);
        }
      }
      // this.issuccessPopup = true;
      // this.success_popup ={
      //    display: true,
      //    message: result.message,
      //    buttonPositive: 'Okay',
      //    positiveButton:()=>{
      //       setTimeout (() => {
      //            this.router.navigate(['companies/campaign/' + this.companyId]);
      //       },10000);
      //    }
      //  }
    } else {
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  // redirect(campId){
  //   this.router.navigate(['companies/campaign/' + campId +"/"+this.companyId+ '/assign-user']);
  // }

  showInfo() {
    this.messageService.add({
      severity: "info",
      summary: "Info Message",
      detail: "PrimeNG rocks",
      life: 3000000,
    });
  }
}
