import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigsService } from 'src/app/services/configs.service';
import { LessonsService } from 'src/app/services/lessons.service';
// import * as uuid from 'uuid';
@Component({
  selector: 'app-import-lesson',
  templateUrl: './import-lesson.component.html',
  styleUrls: ['./import-lesson.component.less']
})
export class ImportLessonComponent implements OnInit {
  skillCategoryId: any;
  lessonsList = [];
  importLevel: any;
  importLevelError: boolean = false;
  lessonListError: boolean = false;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {},
  };
  constructor(protected lessonsService: LessonsService,
    private configsService: ConfigsService ,
    protected route: ActivatedRoute 
    ) { }
  options=["sdf","sdf"];
  skillType="skill";
  iserrorPopup: boolean = false;
  error_message ="";
  lessonCategoryId ="";
  level :Number=0;
  lessonName:string;
  isError : boolean =false;
  lessonData : any;
  skillOptions : any;
  skillList : any;
  skills : any;
  @Input("display") display=false;
  @Output() isClose: EventEmitter<any> = new EventEmitter();
  @Output() lessonDataHandle : EventEmitter<any> = new EventEmitter();
  levels={
    label: "",
    key: "level",
    placeholder: "Select Level",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [{name:1, value:1},{name:2, value:2},
      {name:3, value:3},{name:4,value:4},{name:5,value:5}],
    apiUrl: "",
  } 
  selectLevel={
    label: "Select the level in which you want to import lessons",
    key: "level",
    placeholder: "Select the level in which you want to import lessons",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [{name:1, value:1},{name:2, value:2},
      {name:3, value:3},{name:4,value:4},{name:5,value:5}],
    apiUrl: "",
  } 
  ngOnInit() {
    this.getSkill();
    // this.getLesson();
  }
  getLesson(){
    var reqParameter ="?";
    if(this.lessonCategoryId && this.level){
      reqParameter += "LessonCategoryId="+this.lessonCategoryId+"&level="+ this.level;
    }
    if(this.lessonCategoryId && this.level && this.lessonName){
      reqParameter += "&";
    }
    if(this.lessonName){
      reqParameter += "lessonName="+this.lessonName;
    }
    this.configsService.loading(true);
    this.lessonsService.getImportLesson(
      reqParameter
     ).subscribe((data)=>{
      if(data){ 
        this.lessonData = data.result;
      }
      this.configsService.loading(false);
    }),  (err) => {
      this.configsService.loading(false);
     
    },
    () => {
      this.configsService.loading(false);
    }
  }
  checkBox(sLesson : any){
    let flag =  false;
    this.skillList.find((lesson)=>{
      if(lesson.lessonId === sLesson){
        flag= true;
      }
    });
    return flag;
  }

  getSkill(){
    this.lessonsService.getLessonCategories().subscribe((data)=>{
      if(data){
        this.skillList = data.result;
        this.skillOptions = this.skillList.map((data)=>{
          return {
            value : data.lessonCategoryId,
            name : data.lessonCategoryName
          }
        });
        this.setSkillSelect();
      }
    })
  }
  setSkillSelect(){
    this.skills={
      label: "Quick Filter",
      key: "skills",
      placeholder: "Select Skill",
      type: "dropdown",
      bindLabel: "name",
      required: "required",
      multiple: false,
      value: [],
      options: this.skillOptions,
      apiUrl: "",
    } 
  }
  searchLesson(value : any ){
    this.lessonName = value;
    this.getLesson();
  }
  changeSelectLevel(level){
    this.importLevel = level.value;
  }
  saveLessonToList(index,lesson){
    console.log("index", index,lesson);
    var flag =1;
    var newLesson= this.lessonsList.filter((data)=>{
      if(data.lessonId === lesson.lessonId){
        flag =0;
      }
      else{
        return data;
      }
    });
    if(flag){
      newLesson.push(lesson);
    }
    this.lessonsList = newLesson
    console.log("call ", this.lessonsList);
  }
  changeSkill(skill : any){
   this.lessonCategoryId = skill.value;
   if(this.level === 0){
     this.isError = true;
     this.error_message = "Please select level";
     return;
   }
     this.isError = false;
     this.getLesson();
  }
  changeLevel(skill){
    this.level = skill.value;
    if(!this.lessonCategoryId){
      this.isError = true;
      this.error_message = "Please select Skill";
      return;
    }
    this.isError = false;
    this.getLesson();
  }
  closeModel(){
    this.isClose.emit(false);
  }
  saveImportLesson(){
    let flag = 0;
    if(!this.importLevel ){
      this.importLevelError = true;
      flag = 1;
    }
  else if(!this.lessonsList.length){
      this.lessonListError = true;
      flag = 1;
    }
    if(flag === 1 )
      return;
    
    this.route.params.subscribe(params => {
      this.skillCategoryId = params["categoryId"];
    });
            
    var data={
      lessonCategoryId : this.skillCategoryId,
      lessons : this.lessonsList,
      level : this.importLevel
    }
    this.lessonsService.postImportLesson(data).subscribe((data)=>{
      if(data){
        this.issuccessPopup = true;
         this.success_popup ={
             display: true,
             message: "Import Lesson Successfully",
             buttonPositive: 'Okay',
             positiveButton:()=>{
                    this.issuccessPopup = false;
                    this.isClose.emit(false);
             }
           }
      }
    })
  }
  previewLessonData(lesson: any){
    this.isClose.emit(false);
    this.lessonDataHandle.emit(lesson);

  }
}
