export class NFTMentorResponse {
  isNFTMentor: boolean;
  isFixedPriceNFT: boolean;
  nftMentorImage: string;
  nftMentorArtWork: string;
  nftCategory: number;
  nftDescription: string;
  nftMentorGalleryURL: string;
  nftMentorsArtWork: string;
  nftMentorsArtV1Work: string;
  nftMentorsArtV2Work: string;
  nftMentorsArtV3Work: string;


  /**
   *
   */
  constructor() {
    this.isNFTMentor = false;
    this.isFixedPriceNFT = false;
    this.nftCategory = 0;
    this.nftDescription = "";
    this.nftMentorArtWork = "";
    this.nftMentorGalleryURL = "";
    this.nftMentorImage = "";
    this.nftMentorsArtWork = "";
    this.nftMentorsArtV1Work = "";
    this.nftMentorsArtV2Work = "";
    this.nftMentorsArtV3Work = "";
  }
}
