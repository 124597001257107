import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchComponent } from "./search/search.component";
import { PopupComponent } from "./popup/popup.component";
import { EditPopupComponent } from "./edit-popup/edit-popup.component";
import { FilterComponent } from "./filter/filter.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { LottieAnimationViewModule } from "ng-lottie";
import { SelectButtonModule } from "primeng/selectbutton";
import { TabViewModule } from "primeng/tabview";
import { CheckboxModule } from "primeng/checkbox";
import { FilterPipe } from "./../pipes/filter.pipe";
import { ShareModule } from "@ngx-share/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ModalComponent } from './modal/modal.component';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SafePipeModule} from "safe-pipe";
import {NgModelChangeDebouncedDirective} from "../directives/ng-model-change-debounced";

@NgModule({
  declarations: [
    SearchComponent,
    PopupComponent,
    EditPopupComponent,
    FilterComponent,
    BreadcrumbComponent,
    FilterPipe,
    ModalComponent,
    DragAndDropComponent,
    NgModelChangeDebouncedDirective
  ],
  imports: [
    CommonModule,
    DialogModule,
    DropdownModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    LottieAnimationViewModule,
    SelectButtonModule,
    TabViewModule,
    CheckboxModule,
    ShareModule,
    DragDropModule,
    NgbModule,
    SafePipeModule
  ],
  exports: [
    SearchComponent,
    BreadcrumbComponent,
    PopupComponent,
    EditPopupComponent,
    ModalComponent,
    FilterComponent,
    DragAndDropComponent,
    DialogModule,
    DropdownModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    LottieAnimationViewModule,
    SelectButtonModule,
    TabViewModule,
    CheckboxModule,
    FilterPipe,
    ShareModule,
    DragDropModule
  ],
})
export class SharedModule {}
