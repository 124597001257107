import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { GleacColumnDef } from 'src/app/components/data-grid/data-grid.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';

@Component({
  selector: "app-invite-popup",
  templateUrl: "./invite-popup.component.html",
  styleUrls: ["./invite-popup.component.less"]
})

export class InvitePopupComponent {
  toggled: boolean = false;
  emailList: string[] = [];
  email: FormControl = new FormControl();
  search: FormControl = new FormControl();
  searchLog: FormControl = new FormControl();
  emailTemplate: FormControl = new FormControl();
  usageCount: FormControl = new FormControl(1, [Validators.required])
  emailSubject: FormControl = new FormControl();
  selectedLesson: any;
  popupTab: string[] = ['Choose Emails', 'Select Content', 'Preview Email', 'Status'];
  selectedTab = this.popupTab[0];
  csvRecords: any[] = [];
  status: string = "";
  emailError: string = "";
  csvError: string = "";
  emailDuplicateError: string = "";
  @ViewChild('fileImportInput', { static: false }) fileImportInput: ElementRef;
  emailRecords: string[] = [];
  filteredEmails: string[] = [];
  filteredEmailLog: string[] = [];
  inviteDetails: {
    emails: string[],
    emailContent: string
  };
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike']
      ]
    }
  };
  emailLogGridData: any;
  emailLogColumnData: GleacColumnDef[] = [
    {
      displayName: "S. No.", data: "sequenceNumber"
    },
    { displayName: "Email", data: "email" },
    { displayName: "Skill", data: "skill" },
    { displayName: "Lesson", data: "lesson" },
    {
      displayName: "Email Sent On", data: "emailSentOn", render: (data, row) => {
        if (row.emailSentOn == '0001-01-01T00:00:00') {
          return "-";
        } else {
          var mailSentDateTime = new Date(row.emailSentOn);
          return `${mailSentDateTime.getDate()}/${mailSentDateTime.getMonth() + 1}/${mailSentDateTime.getFullYear()}`;
        }
      }
    },
    {
      displayName: "Email Sent", data: "status"
    }
  ];
  challenge: boolean = false;

  constructor(
    private lessonsService: LessonsService, public dialogRef: MatDialogRef<InvitePopupComponent>, @Inject(MAT_DIALOG_DATA) data, public configService: ConfigsService
  ) {
    this.selectedLesson = data.selectedLesson;
    this.challenge = data.challenge;
  }

  ngOnInit() {
    this.setupValidations();
    this.emailSubject.setValue(`Hello lovely human, here\'s your 5-10 min ${this.challenge ? 'challenge' : 'lesson'} today`);
    let templateText = `<p style="font-family: sans-serif; ">Hi there,</p>
       <br /><p style="font-family: sans-serif;">We hope you are doing great!</p>
       <br /><p style="font-family: sans-serif;">Here is your ${this.challenge ? 'challenge' : 'lesson'} for the day. Kindly click on the button below to access the ${this.challenge ? 'challenges' : 'lessons'}.</p>
       <br /><p style="font-family: sans-serif;"><i>Please note, the ${this.challenge ? 'challenge' : 'lesson'} link is only valid for the next 7 days.</i></p>
       <br /><p style="font-family: sans-serif;">Why not set aside 5-10 minutes and upskill yourself now :)</p>
       <br /><p style="font-family: sans-serif;">Happy Learning :)</p>`;
    this.emailTemplate.setValue(templateText);
  }

  handleSelection(event) {
    var emailSubject = this.emailSubject.value;
    this.emailSubject.setValue(emailSubject + event.char);
  }

  addEmail(event) {
    this.email.markAllAsTouched();
    if (this.emailRecords.indexOf(this.email.value.trim().toLocaleLowerCase()) == -1) {
      if (this.email.valid && this.isEmail(this.email.value)) {
        this.emailError = "";
        this.emailList.push(this.email.value.toLocaleLowerCase());
        this.filteredEmails.push(this.email.value.toLocaleLowerCase());
        this.emailRecords.push(this.email.value.toLocaleLowerCase());
        this.email.reset();
      } else if (this.email.value.split(",").length > 1) {
        let dataArr = [];
        dataArr = this.email.value.split(",");
        for (let i = 0; i < dataArr.length; i++) {
          if (dataArr[i].length > 0 && this.isEmail(dataArr[i].trim()) && this.emailRecords.indexOf(dataArr[i].trim().toLocaleLowerCase()) == -1) {
            this.emailError = "";
            this.filteredEmails.push(dataArr[i].trim().toLocaleLowerCase());
            this.emailRecords.push(dataArr[i].trim().toLocaleLowerCase());
          } else {
            this.emailError = dataArr[i].trim().toLocaleLowerCase();
          }
        }
        this.email.reset();
      } else if (this.email.value.split(" ").length > 1) {
        let dataArr = [];
        dataArr = this.email.value.split(" ");
        for (let i = 0; i < dataArr.length; i++) {
          if (dataArr[i].length > 0 && this.isEmail(dataArr[i].trim()) && this.emailRecords.indexOf(dataArr[i].trim().toLocaleLowerCase()) == -1) {
            this.emailError = "";
            this.filteredEmails.push(dataArr[i].trim().toLocaleLowerCase());
            this.emailRecords.push(dataArr[i].trim().toLocaleLowerCase());
          } else {
            this.emailError = dataArr[i].trim().toLocaleLowerCase();
          }
        }
        this.email.reset();
      } else {
        if (this.emailError.length > 0
          && this.emailError.toLocaleLowerCase().indexOf(this.email.value.trim().toLocaleLowerCase()) == -1) {
          this.emailError = this.emailError + ", " + this.email.value.trim().toLocaleLowerCase();
        } else {
          this.emailError = this.email.value.trim().toLocaleLowerCase();
        }
      }
    } else {
      if (this.emailDuplicateError.length > 0
        && this.emailDuplicateError.toLocaleLowerCase().indexOf(this.email.value.trim().toLocaleLowerCase()) == -1) {
        this.emailDuplicateError = this.emailDuplicateError + ", " + this.email.value.trim().toLocaleLowerCase();
      } else {
        this.emailDuplicateError = this.email.value.trim().toLocaleLowerCase();
      }
    }
  }

  setupValidations() {
    this.email.setValidators([Validators.email]);
  }

  removeEmail(event, index) {
    let item = this.filteredEmails[index];
    this.filteredEmails.splice(index, 1);
    let emailIndex = this.emailRecords.indexOf(item.toLocaleLowerCase());
    this.emailRecords.splice(emailIndex, 1);

    if (this.emailDuplicateError.length > 0 && this.emailDuplicateError.includes(item.toLocaleLowerCase())) {
      this.emailDuplicateError = ''
    }
  }

  closeModal(data?) {
    this.dialogRef.close(data);
  }

  fileChangeListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (files && files.length > 0 && this.isCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
      };

      reader.onerror = function () {
        alert('Unable to read ' + input.files[0]);
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let data = (<string>csvRecordsArray[i]).split(',');

      if (data[0].length > 0) {
        if (this.isEmail(data[0].trim())) { //check if data is a valid email
          dataArr.push(data[0].trim());
          if (this.emailRecords.indexOf(data[0].trim().toLocaleLowerCase()) == -1) {
            this.filteredEmails.push(data[0].trim().toLocaleLowerCase());
            this.emailRecords.push(data[0].trim().toLocaleLowerCase());
          } else {
            if (this.emailDuplicateError.length > 0
              && this.emailDuplicateError.toLocaleLowerCase().indexOf(data[0].trim().toLocaleLowerCase()) == -1) {
              this.emailDuplicateError = this.emailDuplicateError + ", " + data[0].trim().toLocaleLowerCase();
            } else {
              this.emailDuplicateError = data[0].trim().toLocaleLowerCase();
            }
          }
        } else {
          if (this.csvError.length > 0 && this.csvError.toLocaleLowerCase().indexOf(data[0].trim().toLocaleLowerCase()) == -1) {
            this.csvError = this.csvError + ", " + data[0].trim().toLocaleLowerCase();
          } else {
            this.csvError = data[0].trim().toLocaleLowerCase();
          }
        }
      }
    }
    return dataArr;
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  fileReset() {
    if (this.fileImportInput) {
      this.fileImportInput.nativeElement.value = "";
      this.csvRecords = [];
    }
  }

  isEmail(search: string): boolean {
    var searchfind: boolean;
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    searchfind = regexp.test(search);
    return searchfind;
  }

  searchEmail($event) {
    if (this.search.value != null && this.search.value.trim().length > 0) {
      this.filteredEmails = this.emailRecords.filter(email => email.toLocaleLowerCase().includes(this.search.value.toLocaleLowerCase()))
    } else {
      this.filteredEmails = [...this.emailRecords];
    }
  }

  setInviteDetails($event) {
    if (this.challenge) {
      this.challengeInvite();
      return;
    }
    this.configService.loading(true);
    this.lessonsService.sendInvites(this.selectedLesson, this.emailRecords, this.emailTemplate.value, this.emailSubject.value, this.usageCount.value)
      .subscribe(res => {
        if (res.result.failedCount > 0) {
          this.status = res.result.failedCount;
        }
        this.getEmailLog(true);
        this.configService.loading(false);
        setTimeout(function () {
          this.status = "";
        }, 500);
      });
  }

  challengeInvite() {
    console.log("challenge");
    this.configService.loading(true);
    this.lessonsService.challengeSendInvites(this.selectedLesson, this.emailRecords, this.emailTemplate.value, this.emailSubject.value, this.usageCount.value)
      .subscribe(res => {
        if (res.result.failedCount > 0) {
          this.status = res.result.failedCount;
        }
        this.getEmailLog(true);
        this.configService.loading(false);
        setTimeout(function () {
          this.status = "";
        }, 500);
      });
  }

  getEmailLog(history: boolean) {
    if (history) {
      this.configService.loading(true);
      this.lessonsService.getEmailLog()
        .subscribe(res => {
          this.filteredEmailLog = res.result;
          this.emailLogGridData = res.result;
          this.configService.loading(false);
        });
    }
  }

  clearEmails($event) {
    this.emailRecords = [];
    this.filteredEmails = [];
    this.csvRecords = [];
    this.emailError = "";
    this.csvError = "";
    this.emailDuplicateError = "";
    this.fileReset();
  }

  onEmailChange(ent) {
    if (!ent) {
      this.emailDuplicateError = "";
    }
  }

  searchEmailLog($event) {
    if (this.searchLog.value != null && this.searchLog.value.trim().length > 0) {
      this.filteredEmailLog = this.emailLogGridData.filter(log => log.email.toLocaleLowerCase().includes(this.searchLog.value.toLocaleLowerCase())
        || log.lesson.toLocaleLowerCase().includes(this.searchLog.value.toLocaleLowerCase()));
    } else {
      this.filteredEmailLog = this.emailLogGridData;
    }
  }
}
