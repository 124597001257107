import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';
import { ConfigsService } from '../../services/configs.service';

import { LessonSkill } from '../lessons/lessons.model';

@Component({
  selector: 'app-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.less']
})
export class CandidateDashboardComponent implements OnInit {
  
  isErrorPopup = false;
  error_popup = {display: false};
  candidateId: string;
  availableSkills: LessonSkill[];
  hiddenCards: any;

  constructor(private route: ActivatedRoute, private candidateService: CandidateService, private router: Router, private configsService : ConfigsService ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.candidateId = params["id"];
      this.getCandidateDashboardLessons();
    })
  }
 
  getCandidateDashboardLessons() {
    this.configsService.loading(true);
    this.candidateService.getCandidateLessonsForDashboard(this.candidateId).subscribe(res => {
      this.availableSkills = res.result;
      let hiddenCardsLength = this.availableSkills.length <= 3 ? 3 - this.availableSkills.length : this.availableSkills.length % 3;
      this.hiddenCards = Array(hiddenCardsLength).fill(undefined);
    },err => {
            this.configsService.loading(false);
            this.isErrorPopup = true;
            this.error_popup = {display: true};
    },()=> {
            this.configsService.loading(false);
    });
  }

  getCandidateLesson(event, i) {
    let selectedCategory = this.availableSkills[i];
    let selectedSkillId = selectedCategory.lessonCategoryId;
    this.configsService.loading(true);
    this.candidateService.getCandidateLesson(this.candidateId, selectedSkillId).subscribe(res => {
      this.router.navigate(["candidate/" + this.candidateId + "/" + res.result]);
    },err => {
            this.configsService.loading(false);
            this.error_popup = {display: true};
    },()=> {
            this.configsService.loading(false);
    });
  }

}
