import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.less']
})
export class LoginLayoutComponent implements OnInit {
  isMobileLesson: boolean = false;
  dynamicImageUrl: string = "Gleac-dance.jpg";
  imgArray: string[] = ["Gleac-dance.jpg", "Gleac-fullcircle.jpg", "Gleac-perfecthire.jpg", "Gleac-tongue.jpg", "Gleac-zen.jpg"];

  constructor() { }

  ngOnInit() {

    (function ($) {
      $(document).ready(function () {
        $('.login_slider').slick({
          dots: true,
          autoplay: true,
          arrows: false
        });
      });
    })(jQuery);

    if (environment.isMobile) {
      this.isMobileLesson = true;
    }

    //this.dynamicImageUrl = this.imgArray[Math.floor(Math.random() * this.imgArray.length)];
  }

}
