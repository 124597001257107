import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.less']
})
export class CookiePopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CookiePopupComponent>, @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit() {
  }

  acceptPolicy(event) {
    this.dialogRef.close({
      confirm: true
    });
  }
}