import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { BenchmarkLoginComponent } from 'src/app/components/benchmark-login/benchmark-login.component';
import { BenchmarkService } from 'src/app/services/benchmark.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-mini-benchmark-login-popup',
  templateUrl: './mini-benchmark-login-popup.component.html',
  styleUrls: ['./mini-benchmark-login-popup.component.less']
})
export class MiniBenchmarkLoginPopupComponent extends BenchmarkLoginComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, configsService: ConfigsService, usersService: UsersService, router: Router, formBuilder: FormBuilder,
    dialog: MatDialog, route: ActivatedRoute, benchmarkService: BenchmarkService, private dialogRef: MatDialogRef<MiniBenchmarkLoginPopupComponent>,
    protected socialService: AuthService) {
    super(configsService, usersService, router, formBuilder, dialog, route, benchmarkService, socialService);
  }

  ngOnInit() {
    this.loadGsiScript();
    this.initializeFormValidations();
  }

  gotoUserDashboard(data) {
    this.showLoginLoader = false;
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions : decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.configsService.loading(true);
    this.dialogRef.close();
  }

  gotoUserDashboardAfterVerification(data) {
    this.showLoginLoader = false;
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions : decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.configsService.loading(false);
  }

  gotoForgotPassword(event) {
    window.open("https://community.gleac.com/password/forgot", "_blank")
  }

  close($event) {
    this.configsService.loading(true);
    this.dialogRef.close();
  }

  resetForm(){
    this.verificationForm.reset();
    this.verificationError = false;
  }

  trackSkipLoginEvent() {
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("yoma_skip_login"));
      gtag("event", "yoma_skip_login");
    }
  }
}
