import { Component, OnInit } from "@angular/core";
import { ConfigsService } from "../../services/configs.service";
import { GleacColumnDef } from "../data-grid/data-grid.model";
import { CommonService } from "../../services/common.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import {UsersService} from "../../services/users.service";

@Component({
  selector: "app-group-inner",
  templateUrl: "./group-inner.component.html",
  styleUrls: ["./group-inner.component.less"],
})
export class GroupInnerComponent implements OnInit {
  page_title = "Group-User";
  iscreatePopup: boolean = false;
  create_popup = {};
  isreportPopup: boolean = false;
  report_popup = {};
  breadCrumsArray: any = [];
  filterArray: any = [
    {
      label: "Status ",
      type: "static_select",
      key: "status",
      options: [
        { name: "Completed", value: "Completed" },
        { name: "Pending", value: "Pending" },
      ],
    },
  ];
  groupsUserGridData: any;
  groupsUserColData: GleacColumnDef[] = [
    { displayName: "User Name", data: "userName" },
    { displayName: "Lessons Completed", data: "lessonsCompleted" },
    { displayName: "Email", data: "email" },
    {
      displayName: "Actions",
      isSingleAction: true,
      actionName: "Remove",
      disableSort: true,
    },
  ];
  pageIndex: number = 1;
  pageSize: number = 10;
  queryParam: any = [];
  query;
  groupId;
  assignUserToGroupFlag: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () { },
  };
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () { },
    negativeButton: function () { },
  };
  url = "";
  searchString = "";
  groupName = "";
  companyId;
  creatFields: any = [
    {
      label: "Add Users",
      key: "users",
      placeholder: "Select the users for the group",
      type: "dropdown",
      multiple: true,
      value: "",
      required: "required",
      bindLabel: "name",
      options: [],
      apiUrl: "Users",
      paramsNeeded: ["companyId"],
    },
  ];
  constructor(
    private commonService: CommonService,
    private configsService: ConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    public usersService: UsersService,
  ) { }

  ngOnInit() {

    this.usersService.isUsers = true;
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.configsService.setBreadcrumb(this.breadCrumsArray);
    this.route.params.subscribe((params) => {
      this.companyId = params["companyId"];
      this.groupId = params["groupId"];
      this.groupName = params["groupName"];
      if (this.groupId) {
        this.queryParam["groupId"] = this.groupId;
        this.queryParam["companyId"] = this.companyId;
      }
    });
    this.queryParam["pageIndex"] = this.pageIndex;
    this.queryParam["pageSize"] = this.pageSize;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupUserList();
  }

  getGroupUserList() {
    this.configsService.loading(true);
    this.url = "Groups/user/";
    this.commonService.getListDataGridV2(this.url, this.query).subscribe(
      (data) => {
        if (data.result.list.length <= 0 && data.result.list.totalCount != 0 && this.queryParam["pageIndex"] > 1) {
          this.queryParam["pageIndex"] = --this.queryParam["pageIndex"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getGroupUserList();
        } else {
          this.groupsUserGridData = data.result;
        }
        this.groupsUserGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  changeSearchText(event) { }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        this.queryParam[key] = data[key];
      }
    } else {
      delete this.queryParam.status;
    }
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Groups/user/search/" : "Groups/user/";
    this.configsService.loading(true);
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        this.groupsUserGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(params) {
    this.searchString = params[0];
    this.queryParam["searchText"] = this.searchString;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupUserList();
    // this.query = this.commonService.encodeQueryData(this.queryParam);
    // this.url = this.searchString ? "Groups/user/search/" : "Groups/user/";
    // this.configsService.loading(true);
    // this.commonService.getListDataGrid(this.url, this.query).subscribe(
    //   (data) => {
    //     this.groupsUserGridData = data.result;
    //   },
    //   (err) => {
    //     this.configsService.loading(false);
    //     this.iserrorPopup = true;
    //     this.error_popup = { display: true };
    //   },
    //   () => {
    //     this.configsService.loading(false);
    //   }
    // );
  }

  deleteUserGroups(event) {
    if (event.action == "delete") {
      let params = [];
      params["groupId"] = event.data.groupId;
      params["userId"] = event.data.userId;
      params["email"] = event.data.email;
      let deleteData = this.commonService.encodeQueryData(params);
      this.deleteGroup(deleteData);
    }
  }

  pageChange(event) {
    this.queryParam["pageIndex"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getGroupUserList();
  }

  deleteGroup(data) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Groups/user?" + data;
        this.commonService.deleteDataV2(url).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
  **/

  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.getGroupUserList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
    }
  }

  assignUserToGroup() {
    this.assignUserToGroupFlag = true;
  }

  userClose() {
    this.assignUserToGroupFlag = false;
    this.getGroupUserList();
  }
}
