import {
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {trigger, transition, animate, style} from "@angular/animations";
import {GleacColumnDef} from "../data-grid-new-dashboard/data-grid-new-dashboard.model";
import {CommonService} from "../../services/common.service";
import {ConfigsService} from "../../services/configs.service";
import {UsersService} from "../../services/users.service";
import {Router} from '@angular/router';
import {TreeviewItem, TreeviewConfig} from 'ngx-treeview';
import {ToastService} from "../../services/toast-service";


@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({transform: "translateX(100%)"}),
        animate("200ms ease-in", style({transform: "translateX(70%)"})),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({transform: "translateX(100%)"})),
      ]),
    ]),
  ],
})
export class RolesComponent implements OnInit {
  page_title = "Roles";
  showRoleDetails = false;
  roleDetailsTitle = "Add Role";
  roleData: any = {};
  selectedPermissions = [];
  assignRole: boolean = false;
  assignRoleId: string = '';
  issuccessPopup: boolean = false;
  iserrorPopup: boolean = false;
  error_popup = {};
  isconfirmPopup = false;
  errorMessage = '';
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    {
      isExpandable: true,
      data: "roleName",
      displayName: "Role Name",
      render: (data, row) => {
        return '<span class="comanyname ml-2" style="color:#0065AD">' + row.roleName + '</span>'
      }
    },
    {data: "roleDescription", displayName: "Description"},
    {data: "createdAt", displayName: "Created On"},
    {
      data: "isActive",
      displayName: "Status",
      render: (data, row) => {
        if (row.isActive) {
          return '<div><span class="dot gleac-badge-text success mr-2"></span><span>' + 'Active' + '</span></div>';
        } else {
          return '<div><span class="dot gleac-badge-text danger mr-2"></span><span>' + 'InActive' + '</span></div>';
        }
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Role_Add'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Role_Delete'
        },
      ],
    },
  ];
  pageIndex: number = 1;
  pageSize: number = 10;
  searchString = "";
  queryParam: any = [];
  query;
  url;

  permissions: TreeviewItem[] = [];

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 300
  });


  constructor(
    private configsService: ConfigsService,
    private commonService: CommonService,
    protected router: Router,
    public userService: UsersService,
    private toastService: ToastService
  ) {
    this.queryParam["PageNumber"] = this.pageIndex;
    this.queryParam["PageSize"] = this.pageSize;
    this.getAllRoles();
  }

  ngOnInit() {

  }

  actionClick(event) {
    if (event.action === "edit") {
      if (!this.userService.isAllowed('Role_Add'))
        return;

      this.showRoleDetails = true;
      this.roleDetailsTitle = "Edit Role";
      this.getRoleByID(event.data.roleId);
    } else if (event.action === "delete") {
      this.deleteRole(event.data);
    } else if (event.action === "assignRole") {
      this.assignRoleId = event.roleId;
      this.assignRole = true;
    }
  }

  assignUsers() {
    this.assignRole = true;
  }

  createRole() {
    this.errorMessage = '';
    this.showRoleDetails = true;
    this.roleDetailsTitle = "Add Role";
    this.getPermissions();
  }

  roleDetails(event) {
    this.errorMessage = '';
    this.showRoleDetails = true;
    this.roleDetailsTitle = "Edit Role";
    this.getRoleByID(event.data.roleId);
  }

  deleteRole(data) {
    console.log("data", data);
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Role",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.commonService.deleteDataUsersApi('Roles/DeleteRole/', data.roleId).subscribe(
          (data) => {
            // this.postStatus(data);
            if (data.hasError) {
              this.showToast(true, data.errors[0]);
              return;
            }

            this.showToast(false, "Role Deleted Successfully.");
            //this.getAllRoles();
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true, message: err.error.errors[0]};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  getRoleByID(roleId) {
    this.configsService.loading(true);
    this.commonService.getCommonApiNew("Roles/GetRolesById/" + roleId).subscribe(
      (data) => {
        this.configsService.loading(false);
        this.roleData = data.result.role;
        this.permissions = [];
        let context = this;
        data.result.permissions.forEach(function (item) {
          let pGridModule = new TreeviewItem({text: item.moduleName, value: item.moduleId});
          let pGridModulePermissions: TreeviewItem[] = [];
          item.permissions.forEach(function (pItem) {
            pGridModulePermissions.push(new TreeviewItem({
              text: pItem.permissionName,
              value: pItem.simplifiedName,
              checked: pItem.checked
            }));
          });
          pGridModule.children = pGridModulePermissions;
          context.permissions.push(pGridModule);
        });
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getPermissions() {
    this.configsService.loading(true);
    this.commonService.getCommonApiNew("Roles/PermissionGrid").subscribe(
      (data) => {
        this.configsService.loading(false);
        let context = this;
        this.permissions = [];
        if (data.result) {
          data.result.forEach(function (item) {
            let pGridModule = new TreeviewItem({text: item.moduleName, value: item.moduleId});
            let pGridModulePermissions: TreeviewItem[] = [];
            item.permissions.forEach(function (pItem) {
              pGridModulePermissions.push(new TreeviewItem({
                text: pItem.permissionName,
                value: pItem.simplifiedName,
                checked: pItem.checked
              }));
            });
            pGridModule.children = pGridModulePermissions;
            context.permissions.push(pGridModule);
          });
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getAllRoles() {
    this.configsService.loading(true);
    this.url = "Roles/GetRoles";
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.commonService.getListDataGridNew(this.url, this.query).subscribe(
      (data) => {
        //this.jobsList = data.result.jobIndex;

        if (data.result.data && data.result.data.length == 0 && this.queryParam["PageNumber"] && this.queryParam["PageNumber"] > 1) {
          this.queryParam["PageNumber"] = this.queryParam["PageNumber"] - 1;
          this.getAllRoles();
          return;
        }

        let rolesData = {
          list: data.result.data,
          totalCount: data.result.totalRecords
        }

        this.transactionsGridData = rolesData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["PageNumber"] = event;
    this.getAllRoles();
  }

  sortChange(event) {
    this.queryParam["SortColumn"] = event.active;
    this.queryParam["SortOrder"] = event.direction;
    this.queryParam["SearchText"] = this.searchString;
    this.getAllRoles();
  }

  filterData(event) {
    this.searchString = event ? event[0] : this.searchString;
    this.pageIndex = 1;
    this.queryParam["PageNumber"] = 1;
    this.queryParam["SearchText"] = this.searchString;
    this.getAllRoles();
  }

  onSelectedChange(event) {
    this.selectedPermissions = event
  }

  saveRole() {
    if (!this.roleData.roleName) {
      this.errorMessage = 'Role Name is required.';
      return;
    }

    this.roleData.permissions = this.selectedPermissions;

    if (!this.roleData.roleId || this.roleData.roleId == '00000000-0000-0000-0000-000000000000') {
      this.configsService.loading(true);
      this.commonService.createDataV3("Roles/AddRole", this.roleData).subscribe(
        (data) => {
          this.configsService.loading(false);
          if (data.hasError) {
            this.showToast(true, data.errors[0]);
            return;
          }
          this.showToast(false, "Role Created Successfully.");
          this.showRoleDetails = false;
          this.roleData = {};
          //this.getAllRoles();
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
    } else {
      this.configsService.loading(true);
      this.commonService.createDataV3("Roles/UpdateRole", this.roleData).subscribe(
        (data) => {
          this.configsService.loading(false);
          if (data.hasError) {
            this.showToast(true, data.errors[0]);
            return;
          }
          this.showToast(false, "Role Updated Successfully.")
          this.showRoleDetails = false;
          this.roleData = {};
          //this.getAllRoles();
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
    }
  }

  closeModel() {
    this.showRoleDetails = false;
    this.roleData = {};
  }

  closeAssignRole(event) {
    this.assignRole = false;
  }

  showToast(isError, message) {
    if (isError) {
      this.iserrorPopup = true;
      this.error_popup = {display: true, message: message};
    } else {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.getAllRoles();
        },
      };
    }
  }

}
