import { Injectable } from "@angular/core";
import { appSettings } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class BadgeService {
  constructor(private http: HttpClient) {}

  dashboardUrl: string = appSettings.dashboardUrl;
  jobIndexUrl: string = appSettings.jobIndexUrl;

  version2Api = {
    headers: new HttpHeaders({
      "x-api-version": "2"
    })
  };


  getUserSkillsIndex(job: string, assessmentId: string) {
    var apiUrl = this.jobIndexUrl + assessmentId + "/" + job;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getUserTopSkills(assessmentId: string) {
    var apiUrl = this.jobIndexUrl + assessmentId;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getUserDetails(companyId: string, email: string) {
    var apiUrl = this.dashboardUrl + "Users/" + companyId + "/" + email;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getUserReport(userId : string)
  {
    var apiUrl = this.dashboardUrl + "UserReport?userId=" + userId;
    var response = this.http.get<any>(apiUrl, this.version2Api);
    return response;
  }

  getReportBlob(url : string)
  {
    return this.http.get(url,
      {responseType: 'blob'});
  }
}
