import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { LessonSkill } from "../lessons/lessons.model";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { LessonsService } from "src/app/services/lessons.service";
import { ActivatedRoute, Router } from "@angular/router";
import { appSettings } from 'src/environments/environment';
import { ConfigsService } from '../../services/configs.service';

@Component({
  selector: "app-lesson-skill-upsert",
  templateUrl: "./lesson-skill-upsert.component.html",
  styleUrls: ["./lesson-skill-upsert.component.less"]
})
export class LessonSkillUpsertComponent implements OnInit {

  error_popup = { display: false };
  success_popup = { display: false, message: "", buttonPositive: "", positiveButton: function () { }, };
  @Input() lessonCategory: LessonSkill;
  lessonCategoryForm: FormGroup;
  lessonCategories: LessonSkill[];
  formErrors: string[] = [];
  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  imageFile: File;
  allowedFileTypes: string[] = ["image/jpeg", "image/jpg", "image/png"];
  imageStorageUrl: string = appSettings.imageStoreUrl;
  imageSrc: any;
  showSkillLoader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private lessonsService: LessonsService,
    private router: Router,
    private configsService: ConfigsService,
  ) { }

  ngOnInit() {
    this.lessonSkillSetupData();
  }

  lessonSkillSetupData() {
    if (this.lessonCategory) {
      var newCategory: LessonSkill = {
        companyId: this.lessonCategory.companyId,
        imageSrc: this.lessonCategory.imageSrc,
        isIndustrySkill: this.lessonCategory.isIndustrySkill == null ? false : this.lessonCategory.isIndustrySkill,
        lessonCategoryId: this.lessonCategory.lessonCategoryId,
        lessonCategoryName: this.lessonCategory.lessonCategoryName,
        skillSummary: this.lessonCategory.skillSummary,
        perDayLessons: this.lessonCategory.perDayLessons,
        isActive: this.lessonCategory.isActive == null ? false : this.lessonCategory.isActive,
        skillType: this.lessonCategory.skillType
      };
    } else {
      var newCategory: LessonSkill = {
        companyId: undefined,
        imageSrc: "",
        isIndustrySkill: false,
        lessonCategoryId: undefined,
        lessonCategoryName: "",
        skillSummary: "",
        perDayLessons: 1,
        isActive: true,
        skillType: 0
      };
    }
    this.lessonCategoryForm = this.formBuilder.group(newCategory);
    this.setupLessonTemplateValidations();
  }

  setupLessonTemplateValidations() {
    this.lessonCategoryForm.controls["lessonCategoryName"].setValidators([
      Validators.required
    ]);
    this.lessonCategoryForm.controls["skillSummary"].setValidators([
      Validators.required
    ]);
    // this.lessonCategoryForm.controls["imageSrc"].setValidators([
    //   Validators.required,
    //   Validators.pattern(
    //     "(http[s]?://){1}(www.){0,1}([a-zA-Z0-9-])+.([a-zA-Z0-9-])+[/a-zA-Z0-9-._]*"
    //   )
    // ]);
    // this.lessonCategoryForm.controls["perDayLessons"].setValidators([
    //   Validators.required,
    //   Validators.min(1)
    // ]);
    // this.lessonCategoryForm.controls["isIndustrySkill"].setValidators([
    //   Validators.required
    // ]);
  }

  addNewSkill($event) {
    this.showSkillLoader = true;
    this.formErrors = [];
    this.lessonCategoryForm.markAllAsTouched();
    if (this.imageFile === undefined) {
      this.formErrors.push("Image is required to setup skill.");
      this.showSkillLoader = false;
    }
    else if (this.lessonCategoryForm.valid) {
      let newLessonCategory = this.setModelFromForm();
      newLessonCategory.companyId = "6C6CB0C8-4A3D-48A9-9049-71867E26B6DA";
      newLessonCategory.lessonCategoryId = "";
      this.configsService.loading(true);
      this.lessonsService.addLessonCategory(newLessonCategory).subscribe(
        res => {
          //this.success.emit(res.result.lessonCategoryId);
          this.lessonsService.cateogoriesChangedObserver$.next();
          this.router.navigate(["lessons/" + res.result.lessonCategoryId]);
        },
        err => {
          this.configsService.loading(false);
          this.error_popup = { display: true };
        }
        , () => {
          this.configsService.loading(false);
          this.showSkillLoader = false;
        });
    } else {
      this.formErrors.push(
        "There are invalid values entered. Please correct them."
      );
      this.showSkillLoader = false;
    }
  }

  updateSkill($event) {
    this.showSkillLoader = true;
    this.formErrors = [];
    this.lessonCategoryForm.markAllAsTouched();
    if (this.lessonCategoryForm.valid) {
      let lessonCategory = this.setModelFromForm();
      this.configsService.loading(true);
      this.lessonsService.updateSkillDetails(lessonCategory).subscribe(
        res => {
          this.success_popup = {
            display: true,
            message: "Skill updated successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.success.emit();
            },
          };
        },
        err => {
          this.configsService.loading(false);
          this.error_popup = { display: true };
        }
        , () => {
          this.showSkillLoader = false;
          this.configsService.loading(false);
        });
    } else {
      this.formErrors.push(
        "There are invalid values entered. Please correct them."
      );
      this.showSkillLoader = false;
    }
  }

  cancelAddSkill($event) {
    if (this.lessonCategory) {
      this.cancel.emit();
    }
    else {
      this.router.navigate(["lessons"])
    }
  }

  setModelFromForm() {
    let lessonCategory: LessonSkill = { ...this.lessonCategoryForm.value };
    if (this.imageFile) {
      lessonCategory.imageFile = this.imageFile;
      lessonCategory.imageSrc = this.imageStorageUrl + lessonCategory.imageFile.name;
    }
    return lessonCategory;
  }

  uploadSkillImage(event) {
    this.imageFile = (<File>event.target.files[0]);
    if (this.imageFile) {
      if (this.imageFile.size > 1000000) {
        this.imageFile = undefined;
        this.formErrors.push(
          "Image must be less than 1 MB in size."
        );
      }
      else if (!this.allowedFileTypes.some(type => this.imageFile.type === type)) {
        this.imageFile = undefined;
        this.formErrors.push(
          "Unsupported image file type."
        );
      }
      else {
        this.imageFile = new File([this.imageFile], this.imageFile.name.split('.')[0] + this.getTimestamp() + '.' + this.imageFile.name.split('.')[1], { type: this.imageFile.type });
        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(this.imageFile);
      }
    }
  }

  getTimestamp() {
    let currentDate = new Date();
    return currentDate.getUTCFullYear().toString() + currentDate.getUTCMonth().toString() + currentDate.getUTCDate().toString()
      + currentDate.getUTCHours().toString() + currentDate.getUTCMinutes().toString() + currentDate.getUTCSeconds().toString();
  }
}
