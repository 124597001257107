import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {appSettings} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeaturedMentorAnswerService {
  baseUrl: string =  appSettings.mentorsUrl + 'SetFeaturedMentorAnswer';

  constructor(private http: HttpClient) {

  }

  setFeaturedMentorAnswer(request) {
    let url =  this.baseUrl + "";
    return this.http.put<any>(url, request);
  }

  getAllFeaturedMentorAnswers(request) {
    let url =  this.baseUrl + "?UserId=" + request.userId +"&SkillId=" + request.skillId + "&LessonId=" + request.lessonId;
    return this.http.get<any>(url);
  }
}
