import { Component, OnInit } from "@angular/core";
import { LessonsService } from "src/app/services/lessons.service";
import { LessonSkill } from "../lessons/lessons.model";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { UsersService } from 'src/app/services/users.service';
import { appSettings } from 'src/environments/environment';

@Component({
  selector: "app-lesson-skill-detail",
  templateUrl: "./lesson-skill-detail.component.html",
  styleUrls: ["./lesson-skill-detail.component.less"]
})
export class LessonSkillDetailComponent implements OnInit {
  categoryId: string;
  selectedCategory: LessonSkill;
  showAddCategoryTemplate: boolean = false;
  bucketUrl: string;
  s3BucketUrl: string = appSettings.s3BucketUrl;

  constructor(
    private lessonsService: LessonsService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private usersService: UsersService,
  ) {

    this.lessonsService.selectedCategoryObserver$.subscribe(category => {
      this.selectedCategory = category;
      this.showAddCategoryTemplate = false;      
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.categoryId = params["categoryId"];
      if (this.categoryId) {
        this.lessonsService.categoryChangedObserver$.next(this.categoryId);
      }
    });
  }

  sanitizeUrl(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  editSkillTemplate(event) {
    this.showAddCategoryTemplate = true;
  }

  categoryUpsertDone(event) {
    this.lessonsService.categoryChangedObserver$.next(this.categoryId);
  }

  cancelCategoryUpsert(event) {
    this.showAddCategoryTemplate = false;
  }

  getImagefromS3() {
    if (this.selectedCategory) {
      this.bucketUrl = this.s3BucketUrl + (this.selectedCategory.imageSrc.split('.com')[1]);
      this.selectedCategory.imageSrc = this.bucketUrl;
    }    
  }
}
