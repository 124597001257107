import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { CommonService } from 'src/app/services/common.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { GoalsService } from 'src/app/services/goal.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import * as XLSX from 'xlsx';
import { GoalsPopupComponent } from 'src/app/dialogs/goal-popup/goals-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.less']
})
export class GoalsComponent implements OnInit {
  pageUrl: string = "goals";
  page_title = "Goals";
  searchString: string = "";
  pageNumber: number = 1;
  pageSize: number = 10;
  isEdit: boolean;
  error_popup = {};
  currentSection = 1;
  url = "";
  isErrorPopup: boolean = false;
  isSuccessPopup: boolean = false;
  goalGridData: any;
  isCreatePopup: boolean = false;
  isConfirmPopup = false;
  form: any;
  goals: any;
  status: string = "";
  userGroup: string = "";
  userGroups: any;
  companyName: string = "";
  sortColumn: string = "";
  sortOrder: string = "";
  goalPopup: MatDialogRef<GoalsPopupComponent>;
  eventsSubject: Subject<void> = new Subject<void>();
  isFilterCleared: boolean = false;
  isResolutionError: boolean = false;
  deleteRequest: any = {};
  editRequest: any = {}

  create_popup: any = {
    display: false,
    title: "",
    field: [],
    buttonPositive: "",
    closeModal: function () {
    },
    createrCall: function (form, goalsData, uploadedFileName) {
    }
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    public usersService: UsersService,
    private goalService: GoalsService,
    protected dialog: MatDialog,
    private uploadGoalImage: FileUploadService
  ) {
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
  }

  filterArray: any = [
    {
      label: "Status",
      type: "static_select_goals",
      key: "status",
      options: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ]
    },
    {
      label: "User Group",
      type: "select_group",
      key: "group_name",
      options: []
    },
    {
      label: "Organization",
      type: "select_company",
      key: "company_name",
      options: []
    }
  ];

  goalColumnData: GleacColumnDef[] = [
    {
      displayName: "Title",
      data: "title",
      render: (data, row) => {
        return `
              <span >${row.title ? row.title : '-'}</span>`;
      }
    },
    {
      displayName: "Task Name",
      disableSort: true,
      data: "tasks",
      isTasksToPerform: true,
      render: (data, row) => {
        let tasksToDisplay = ""

        if (row.tasks && row.tasks.length > 0) {
          // row.tasks.sort(function (a, b) {
          //   return a.task_name.localeCompare(b.task_name, 'en', {numeric: true})
          // });

          row.tasks.forEach(function (item) {
            tasksToDisplay = tasksToDisplay + ", " + item.task_name;
          });
        }

        tasksToDisplay = tasksToDisplay.trim();
        tasksToDisplay = tasksToDisplay.substring(1);

        let task = (row.tasks && row.tasks.length > 0) ? tasksToDisplay : ''
        return `<span class="float-left" style="height: 17px; width:270px; overflow: hidden" >${task}</span>`;
      }
    },
    {
      displayName: "User Group",
      data: "group_name",
      render: (data, row) => {
        return `
              <span >${row.group_name ? row.group_name : '-'}</span>`;
      }
    },
    {
      displayName: "Organization",
      data: "organization_name",
      render: (data, row) => {
        return `
              <span >${row.organization_name ? row.organization_name : '-'}</span>`;
      }
    },
    {
      displayName: "Duration",
      disableSort: true,
      data: "goal_duration",
      render: (data, row) => {
        return `
              <span >${row.goal_duration ? row.goal_duration : '-'}</span>`;
      }
    },
    {
      displayName: "Status",
      disableSort: true,
      data: "is_active",
      render: (data, row) => {
        if (row.is_active == true) {
          return '<div><span class="dot gleac-badge-text success mr-2"></span><span>Active</span></div>';
        } else {
          return '<div><span class="dot gleac-badge-text hold mr-2"></span><span>Inactive</span></div>';
        }
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Goal_Add'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Goal_Delete'
        }
      ]
    }
  ];

  getGoalsList() {
    this.configsService.loading(true);
    this.goalService.getAllGoals(
      this.pageNumber,
      this.pageSize
    ).subscribe(
      (res) => {
        if (res) {

          if (res.result.results.length == 0 && this.pageNumber > 1) {
            this.pageNumber--
            this.getGoalsList();
            return;
          }

          let goalsData = {
            list: res.result.results,
            totalCount: res.result.total_count
          }

          let context = this;
          goalsData.list.forEach(function (item) {
            var startDate = context.convertUTCDateToLocalDate(new Date(item.start_date));
            var endDate = context.convertUTCDateToLocalDate(new Date(item.end_date));

            item.goal_duration = startDate.toLocaleDateString() + " " + endDate.toLocaleDateString();
          });

          this.goalGridData = goalsData;

        } else {
          this.goalGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getSearchResults(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    this.configsService.loading(true);
    this.goalService.search(
      encodeURIComponent(searchString), pageNumber, pageSize, sortColumn, sortOrder, this.status ? this.status : '', this.userGroup ? this.userGroup : '', this.companyName ? this.companyName : '').subscribe(
        (res) => {
          if (res) {
            let goalsData = {
              list: res.result.results,
              totalCount: res.result.total_count
            }

            this.goalGridData = goalsData;
          } else {
            this.goalGridData = [];
          }
        },
        (err) => {
          this.configsService.loading(false);
          this.isErrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  getFilteredResults(status, userGroup, companyName, pageNumber, pageSize) {
    //this.configsService.loading(true);
    this.getSearchResults(this.searchString, pageNumber, pageSize, this.sortColumn, this.sortOrder);
    // this.goalService.filterData(status, userGroup, companyName, pageNumber, pageSize).subscribe(
    //   (res) => {
    //     if (res) {
    //       let goalsData = {
    //         list: res.result.results,
    //         totalCount: res.result.total_count
    //       }
    //       this.goalGridData = goalsData;
    //     } else {
    //       this.goalGridData = [];
    //     }
    //   },
    //   (err) => {
    //     this.configsService.loading(false);
    //     this.isErrorPopup = true;
    //     this.error_popup = { display: true, message: err.error.errors[0] };
    //   },
    //   () => {
    //     this.configsService.loading(false);
    //   }
    // );
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.goalService.exportGoals(
      encodeURIComponent(this.searchString), this.sortColumn, this.sortOrder, this.status ? this.status : '', this.userGroup ? this.userGroup : '', this.companyName ? this.companyName : '').subscribe(
      (res) => {
        saveAs(res, "Goals" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.pageNumber = event;

    if (this.isFilterCleared) {
      this.isFilterCleared = false;
      this.userGroup = '';
      this.status = '';
      this.companyName = '';
      this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
    }

    if ((this.userGroup !== undefined && this.userGroup !== '') ||
      (this.status !== undefined && this.status !== '') || (this.companyName !== undefined && this.companyName !== '')) {
      this.getFilteredResults(this.status, this.userGroup, this.companyName,
        this.pageNumber, this.pageSize);
    } else if ((this.searchString !== undefined && this.searchString !== '') || (this.sortColumn !== undefined && this.sortColumn !== '')) {
      this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
    } else {
      this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
    }
  }

  searchData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, '', '');
  }

  filterData(params) {
    this.pageNumber = 1;

    if(params && params[1] && params[1].form && params[1].form.value)
    {
      this.status = params[1].form.value.status;
      this.userGroup = params[1].form.value.group_name ? params[1].form.value.group_name.name : '';
      this.companyName = params[1].form.value.company_name ? params[1].form.value.company_name.name : '';
    }

    if (!this.status && !this.userGroup && !this.companyName) {
      this.getFilteredResults(this.status, this.userGroup, this.companyName, this.pageNumber, this.pageSize);
    } else {
      this.getFilteredResults(this.status, this.userGroup, this.companyName, this.pageNumber, this.pageSize);
    }
  }

  createGoal() {
    this.goalPopup = this.dialog.open(GoalsPopupComponent, {
      disableClose: true,
      width: "50%",
      height: "auto",
      data: {
        dataKey: 'test'
      }
    });

    this.goalPopup.beforeClosed().subscribe(result => {
      this.pageNumber = 1;
      this.getFilteredResults(this.status, this.userGroup, this.companyName, this.pageNumber, this.pageSize);
      //this.eventsSubject.next();
      this.isFilterCleared = true;
    });
  }

  actionClick(event) {
    if (event.action === "edit") {
      console.log(event.data);
      var startDate = this.convertUTCDateToLocalDate(new Date(event.data.start_date));
      var endDate = this.convertUTCDateToLocalDate(new Date(event.data.end_date));
      this.isCreatePopup = true;
      this.create_popup = {
        display: true,
        bulk_upload: false,
        cssClass: 'col-15',
        createrName: "updateGoalsData",
        title: "Update Goal Data",
        buttonPositive: "Update",
        field: [
          {
            label: "Start Date",
            key: "startDate",
            type: "date_picker_start",
            cssClass: 'col-6',
            required: "required",
            value: startDate ? new Date(startDate) : ""
          },
          {
            label: "End Date",
            key: "endDate",
            type: "date_picker_end",
            required: "required",
            cssClass: 'col-6',
            value: endDate ? new Date(endDate) : ""
          },
          {
            label: "Status",
            key: "Is_Active",
            required: "required",
            placeholder: "0",
            cssClass: 'col-6',
            value: event.data.is_active ? event.data.is_active : false,
            type: "button_select",
            options: [
              { label: "Active", value: true },
              { label: "Inactive", value: false },
            ]
          }
        ],
        closeModal: () => {
          this.isCreatePopup = false;
        },
        createrCall: (form: any, uploadedFileName) => {
          let goalData = Object.assign(form.value);

          if (goalData.endDate < goalData.startDate)
            return;

          this.editRequest.goals_library_id = event.data && event.data.goals_library_id ? event.data.goals_library_id : "";
          this.editRequest.is_active = goalData.Is_Active;
          this.editRequest.start_date = goalData.startDate;
          this.editRequest.end_date = goalData.endDate;
          this.editRequest.group_id = event.data && event.data.group_id ? event.data.group_id : "";
          this.editRequest.organisation_id = event.data && event.data.organization_id ? event.data.organization_id : "";
          this.editRequest.Source = "Goals";

          if (form.valid) {
            if (event.data) {
              console.log(goalData, "new data");
              this.configsService.loading(false);

              this.goalService.updateGoals(this.editRequest)
                .subscribe(
                  (data) => {
                    if (data.has_error) {
                      this.showToast(true, data.errors[0]);
                      return;
                    } else {
                      this.showToast(false, "Goal Updated Successfully!");
                    }
                  },
                  (err) => {
                    this.configsService.loading(false);
                    this.isErrorPopup = true;
                    this.error_popup = {
                      display: true,
                      message: err.error.errors[0],
                    };
                  },
                  () => {
                    this.configsService.loading(false);
                    //this.getGoalsList();
                  }
                );
            }
          }
        }
      }
    } else {
      console.log("Delete event");
      console.log(event.data);

      this.deleteRequest.goal_id = event.data.goals_library_id;
      this.deleteRequest.group_id = event.data.group_id;
      this.deleteRequest.organization_id = event.data.organization_id;
      this.deleteRequest.Source = "Goals";

      this.isConfirmPopup = true;
      this.confirm = {
        display: true,
        message: "Are you sure you want to Delete?",
        buttonPositive: "Yes",
        buttonNegative: "No",
        isTopic: "Goals",
        imageUrl: "",
        negativeButton: () => {
          this.confirm.display = false;
        },
        positiveButton: () => {
          this.configsService.loading(true);
          this.goalService.deleteGoal(this.deleteRequest).subscribe(
            (data) => {
              if (data.has_error) {
                this.showToast(true, data.errors[0]);
                return;
              } else {
                this.showToast(false, "Goal Deleted Successfully!");
              }
            },
            (err) => {
              this.configsService.loading(false);
              this.isErrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
            }
          );
          this.confirm.display = false;
        }
      };
    }
  }

  convertUTCDateToLocalDate(date) {
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.searchString = this.searchString;

    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);

  }

  showToast(isError, message) {
    if (isError) {
      this.isErrorPopup = true;
      this.error_popup = { display: true, message: message };
      this.isCreatePopup = true;
    } else {
      this.isSuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.isCreatePopup = false;
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
          //this.eventsSubject.next();
          this.isFilterCleared = true;
        },
      };
    }
  }
}
