import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {capitalCase} from 'capital-case';
import {
  MiniBenchmarkLoginPopupComponent
} from 'src/app/dialogs/mini-benchmark-login-popup/mini-benchmark-login-popup.component';
import {ConfigsService} from 'src/app/services/configs.service';
import {MiniBenchmarkService} from 'src/app/services/mini-benchmark.service';
import {UsersService} from 'src/app/services/users.service';
import {
  GleacSpiderWebModel,
  JobRoles,
  MiniBenchmarkAnswer,
  MiniBenchmarkQuestion,
  MiniBenchmarkQuestionAnswer,
  MiniBenchmarkScore
} from './mini-benchmark.model';
import * as Highcharts from "highcharts";
import {yoma_courses} from '../../../assets/data/yoma_courses.js';
import { environment } from 'src/environments/environment';
import { LessonsService } from 'src/app/services/lessons.service';

declare let html2canvas: any;
declare var require: any;

require('highcharts/highcharts-more')(Highcharts);

@Component({
  selector: 'app-mini-benchmark',
  templateUrl: './mini-benchmark.component.html',
  styleUrls: ['./mini-benchmark.component.less']
})
export class MiniBenchmarkComponent implements OnInit {
  introPage: boolean = true;
  jobRolePage: boolean = false;
  jobRoles: JobRoles[] = [];
  selectedJobRole: string;
  resultPage: boolean = false;
  miniBenchmarkQuestions: MiniBenchmarkQuestion[];
  currentQuestionIndex: number;
  miniBenchmarkAnswer: MiniBenchmarkAnswer[];
  miniBenchmarkQuestionAnswer: MiniBenchmarkQuestionAnswer = new MiniBenchmarkQuestionAnswer();
  selectedAnswer: string;
  loginPopup: MatDialogRef<MiniBenchmarkLoginPopupComponent>;
  miniBenchmarkStartTime: Date;
  userScore: MiniBenchmarkScore = new MiniBenchmarkScore();
  gleacSpiderWebData: GleacSpiderWebModel = new GleacSpiderWebModel();
  updateFlag: boolean = true;
  oneToOneFlag: boolean = true; // optional boolean, defaults to false
  Highcharts: any = Highcharts; // required
  chartConstructor: string = "chart"; // optional string, defaults to 'chart'
  progressTracking: any;
  tspanElement: HTMLElement;
  imgElement: HTMLElement;
  hasError: boolean = false;
  errorMessage: string;
  isPreauth: boolean = false;
  isYoma: boolean = false;
  skillName: string;
  displayedCourses: any[] = [];
  className = 'role-section';
  btnTheme = '';
  selectedTheme: string;
  resultGradient: string;
  getEnvironment(): string {
    if (environment.production) {
      return 'https://gleac.com/'
    }
    return 'https://gleactest.com/'
  }

  getGleacUrl() {
    window.location.href = this.getEnvironment();
  }

  getYomaPath(): void {
    window.location.href = this.getEnvironment() + 'yoma';
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("retention_click"));
      gtag("event", "retention_click", {"retention_path": "Retake benchmark"});
    }
  }

  getYomaLink(): void {
    window.open('https://yoma.africa/', '_blank');
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("retention_click"));
      gtag("event", "retention_click", {"retention_path": "Go back to yoma"});
    }
  }

  trackEvent(courseName: String) {
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("course_clicked", {detail: courseName}));
      gtag("event", "course_clicked", {"course_name": courseName, "skill_name": this.skillName});
    }
  }

  trackDownloadEvent(skillName: string) {
    if (typeof window !== "undefined") {
      window.dispatchEvent(new CustomEvent("report_downloaded", {detail: skillName}));
      gtag("event", "report_downloaded", {"skill_name": skillName});
    }
  }

  getTooltipText(isYomaCourse:boolean): string {
    if (isYomaCourse) {
      return 'Swing by the Yoma platform upon completion for your certificate, collect skills in your Digital Wallet and earn "ZITO" to earn rewards.';
    }
    return "";
  }

  constructor(private configsService: ConfigsService, private usersService: UsersService, private miniBenchmarkService: MiniBenchmarkService,
              private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private lessonsService: LessonsService,) {
  }

  ngOnInit() {
    var url = location.href;
    let accessToken = localStorage.getItem("GATk");
    if (accessToken && !this.usersService.currentUser) {
      let jwtData = accessToken.split(".")[1];
      let decodedJwtJsonData = window.atob(jwtData);
      let decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.usersService.currentUser = {
        authRole: decodedJwtData.auth_role,
        email: decodedJwtData.email,
        userId: decodedJwtData.sub,
        permissions: decodedJwtData.permissions
      };
      this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(res => {
          if (res.result.firstName.length > 0) {
            //this.username = res.result.firstName + " " + res.result.lastName;
          } else {
            localStorage.removeItem('GATk');
          }
        },
        (error) => {
          localStorage.removeItem('GATk');
        });
    }

    this.route.queryParams.subscribe(params => {
      if (params["preauth"] && !this.usersService.currentUser) {
        this.usersService.getTokenForPreAuth(params["preauth"]).subscribe(res => {
          localStorage.setItem("GATk", res.result.accessToken);
          localStorage.setItem("GRTk", res.result.refreshToken);
          window.location.replace(url);
        }, err => {
        })
      }

      if (params["preauth"]) {
        this.isPreauth = true;
      }
    });

    this.route.params.subscribe(params => {
      if (params["jobRoleId"] !== undefined) {
        this.selectedJobRole = params["jobRoleId"];
        this.setQuestions();
        this.introPage = false;
      }
    })

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id == 'yoma') {
        this.isYoma = true;
        this.skillName = params['benchmark'];
        this.applyYomaTheme();
      }
    });

    if (this.isYoma) {
        const filteredCourses = yoma_courses[this.skillName];
        // Randomly select 5 courses from yoma
        this.displayedCourses = [...filteredCourses].sort(() => 0.5 - Math.random()).slice(0, 5);
        //Add a random lesson from Gleac course library
        if (!environment.production && this.skillName === 'Mindfulness') {
          this.skillName = 'Collaboration'; //Just to show something if we're in test server
        }
        this.lessonsService.getLessonBySkillName(this.skillName).subscribe(data => {
          if (!data.hasError && data.result.lessons.length > 0) {
            var gleacLesson = this.getRandomLesson(data.result.lessons);
            var lessonImage = gleacLesson.image;
            if (lessonImage == null || lessonImage == undefined || lessonImage == '') {
              gleacLesson.image = data.result.skillImage; //Fallback method in case image is not available on lesson
            }
            this.displayedCourses.push(gleacLesson);
          }
        })
    }
  }

  private applyYomaTheme() {
    this.className = 'role-section-yoma';
    this.btnTheme = 'yoma-btn';
    this.selectedTheme = 'selected-yoma';
    this.resultGradient = 'yoma-result';
  }

  getRandomLesson(lessons) {
    const randomIndex = Math.floor(Math.random() * lessons.length);
    const randomLesson = lessons[randomIndex];
    const mappedLesson = {
      name: randomLesson.lessonTitle,
      desc: randomLesson.lessonSummary,
      link: randomLesson.shLink,
      image: randomLesson.lessonImage
    };
    return mappedLesson;
  }


  startBenchmark() {
    this.introPage = false;
    this.setJobRoles();
  }

  setJobRoles() {
    this.jobRolePage = true;
    this.configsService.loading(true);
    this.miniBenchmarkService.getAllRoles().subscribe(data => {
      data.result.forEach((item, index) => {
        if (item.name != "ALL_ROLES") {
          this.jobRoles.push({name: capitalCase(item.name), value: item.jobId});
        }
        this.configsService.loading(false);
      });
    });
  }

  unselectRole() {
    this.selectedJobRole = "";
  }

  selectRole(jobId) {
    this.selectedJobRole = jobId;
  }

  setQuestions() {
    this.configsService.loading(true);
    this.miniBenchmarkService.getQuestions(this.selectedJobRole).subscribe(data => {
      this.miniBenchmarkQuestions = data.result.questions;
      let questionImages = data.result.questionImages;
      this.jobRolePage = false;
      this.currentQuestionIndex = 0;
      this.miniBenchmarkAnswer = [];
      this.miniBenchmarkStartTime = new Date();
      this.configsService.loading(false);
    });
  }

  setAnswer(option: string, questionIndex: number) {
    var existingAnswerIndex = this.miniBenchmarkAnswer.findIndex(x => x.questionId === this.miniBenchmarkQuestions[questionIndex].questionId);
    if (existingAnswerIndex !== -1) {
      this.miniBenchmarkAnswer.splice(existingAnswerIndex, 1);
    }
    this.miniBenchmarkAnswer.push({
      questionId: this.miniBenchmarkQuestions[questionIndex].questionId,
      answer: option,
      answeredOnDate: new Date()
    });
    if (this.currentQuestionIndex < this.miniBenchmarkQuestions.length - 1) {
      this.currentQuestionIndex = ++this.currentQuestionIndex;
      var existingAnswer = this.miniBenchmarkAnswer.find(x => x.questionId === this.miniBenchmarkQuestions[this.currentQuestionIndex].questionId);
      if (existingAnswer != undefined) {
        this.selectedAnswer = existingAnswer.answer;
      } else {
        this.selectedAnswer = "";
      }
    } else {
      this.selectedAnswer = option;
    }
    let audio: HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
  }

  previousQuestion() {
    this.currentQuestionIndex = --this.currentQuestionIndex;
    var existingAnswer = this.miniBenchmarkAnswer.find(x => x.questionId === this.miniBenchmarkQuestions[this.currentQuestionIndex].questionId);
    if (existingAnswer != undefined) {
      this.selectedAnswer = existingAnswer.answer;
    } else {
      this.selectedAnswer = "";
    }
  }

  nextQuestion() {
    this.currentQuestionIndex = ++this.currentQuestionIndex;
    var existingAnswer = this.miniBenchmarkAnswer.find(x => x.questionId === this.miniBenchmarkQuestions[this.currentQuestionIndex].questionId);
    if (existingAnswer != undefined) {
      this.selectedAnswer = existingAnswer.answer;
    } else {
      this.selectedAnswer = "";
    }
  }

  finishMiniBenchmark() {
    if (!this.usersService.currentUser) {
      this.loginPopup = this.dialog.open(MiniBenchmarkLoginPopupComponent, {
        data: {
         isYoma: this.isYoma
        },
        disableClose: true
      });
      this.loginPopup.afterClosed().subscribe(data => {
        this.generateReport();
      });
    } else {
      this.configsService.loading(true);
      this.generateReport();
    }
    let audio: HTMLAudioElement = new Audio("assets\\audio\\gleac-discord-leave.mp3");
    audio.play();
  }

  private generateReport() {
    this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(res => {
      this.fillAnswersDetails(res);
      this.miniBenchmarkService.saveAnswer(this.miniBenchmarkQuestionAnswer).subscribe(output => {
        this.showResults(output);
      });
    });
  }

  private showResults(output: any) {
    if (output.hasError || output.result == null || output.result.score == null) {
      this.hasError = true;
      this.errorMessage = 'You have taken this mini benchmark already.';
      this.configsService.loading(false);
    } else {
      this.resultPage = true;
      this.userScore = output.result.score;
      this.showResult();
      this.configsService.loading(false);
    }
  }

  private fillAnswersDetails(res) {
    this.miniBenchmarkQuestionAnswer.jobId = this.selectedJobRole;
    this.miniBenchmarkQuestionAnswer.userId = this.usersService.currentUser.userId;
    this.miniBenchmarkQuestionAnswer.startTime = this.miniBenchmarkStartTime;
    this.miniBenchmarkQuestionAnswer.endTime = new Date();
    this.miniBenchmarkQuestionAnswer.questions = this.miniBenchmarkAnswer;
    this.miniBenchmarkQuestionAnswer.firstName = res.result.firstName;
    this.miniBenchmarkQuestionAnswer.lastName = res.result.lastName;
    this.miniBenchmarkQuestionAnswer.email = this.usersService.currentUser.email;
  }

  showResult() {
    this.userScore.candidateScores.forEach(score => {
      switch (score.skillName) {
        case "Persuasiveness":
          score.skillDescription = "Persuasiveness is the ease with which an individual is convincing and influencing others by using tact and adapting their speech for an audience.";
          break;
        case "Extraversion":
          score.skillDescription = "Extraversion is the tendency to be outgoing and lively, seeking to develop many friendly contacts.";
          break;
        case "Need For Action":
          score.skillDescription = "Need For Action refers to the tendency to be dynamic and to prefer short term projects that deliver quick and concrete results.";
          break;
        case "Ambition":
          score.skillDescription = "Ambition is the competitive drive towards success and achievement, setting high and risky goals.";
          break;
        case "Emotional Distance":
          score.skillDescription = "Emotional Distance is the tendency to stay calm and composed even when faced with stressful or unsettling situations.";
          break;
        case "Improvisation":
          score.skillDescription = "Improvisation is being spontaneous and acting without clear planification, being at ease when facing unexpected situations.";
          break;
        case "Flexibility":
          score.skillDescription = "Flexibility is the willingness to be open to make concessions when dealing with others in order to seek consensus and avoid conflict.";
          break;
        case "Desire to Lead":
          score.skillDescription = "Desire to Lead is the inclination to take charge of situations and to seek roles that require leading people.";
          break;
        case "Novelty Seeking":
          score.skillDescription = "Novelty Seeking is defined as being inquisitive with an inclination to explore and experiment with new ways of doing things.";
          break;
        case "Intuition":
          score.skillDescription = "Intuition is relying more on gut feeling over logic.";
          break;
        case "Free Thinking":
          score.skillDescription = "Freethinker prefers where rules are flexible and expandable.";
          break;
        case "Altruism":
          score.skillDescription = "Altruism is the inclination to be concerned about people and generous with one's time and resources, wanting to contribute to the greater good.";
          break;
        case "Straightforwardness":
          score.skillDescription = "Straightforwardness refers to being authentic and direct in communication, with a need to remain transparent.";
          break;
        case "Structure":
          score.skillDescription = "Structure refers to a preference for being organised, methodical and referring to procedures.";
          break;
        case "Need for reflection":
          score.skillDescription = "Need for Reflection refers to a preference for complex, long term projects that yield results in the future and require more strategy, patience and reflection.";
          break;
        case "Focus on facts":
          score.skillDescription = "Focus on Facts is defined as the tendency to be objective, logical, analytical and rational in evaluating information and making decisions.";
          break;
        case "Involvement at Work":
          score.skillDescription = "Involvement at Work refers to the importance given to work and one's emotional bonding with the organization.";
          break;
        case "Rule following":
          score.skillDescription = "Rule-Following is the tendency to respect and comply with established guidelines and standards set by the organisation and society.";
          break;
        case "Firmness":
          score.skillDescription = "Firmness refers to a tendency to defend one's own point of view and remain determined during exchange with others.";
          break;
      }
    });

    switch (this.userScore.jobRole) {
      case "All Roles":
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Extraversion", "Need for Action", "Ambition", "Desire to Lead", "Rule-Following", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Improvisation", "Emotional Distance", "Flexibility"];
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Extraversion")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need For Action")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Ambition")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Sales":
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Extraversion", "Need for Action", "Ambition", "Desire to Lead", "Rule-Following", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Improvisation", "Emotional Distance", "Flexibility"];
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Extraversion")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need For Action")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Ambition")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Customer Oriented":
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Emotional Distance", "Need for Action", "Improvisation", "Desire to Lead", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Extraversion", "Flexibility"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Emotional Distance")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need For Action")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Improvisation")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Tech Support":
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Emotional Distance", "Need for Action", "Improvisation", "Desire to Lead", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Extraversion", "Flexibility"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Emotional Distance")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need For Action")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Improvisation")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Team Management":
        this.gleacSpiderWebData.categories = ["Flexibility", "Emotional Distance", "Need for Action", "Desire to Lead", "Persuasiveness", "Improvisation", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Extraversion"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Flexibility")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Emotional Distance")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need For Action")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Desire to Lead")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Creative Professional":
        this.gleacSpiderWebData.categories = ["Improvisation", "Novelty Seeking", "Intuition", "Free Thinking", "Need for Action", "Desire to Lead", "Persuasiveness", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Focus on Facts", "Extraversion"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Improvisation")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Novelty Seeking")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Intuition")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Free Thinking")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Data Scientist":
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Structure", "Need for reflection", "Focus on facts", "Need for Action", "Desire to Lead", "Free Thinking", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Improvisation", "Extraversion"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Structure")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need for reflection")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Focus on facts")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Marketing":
        this.gleacSpiderWebData.categories = ["Extraversion", "Persuasiveness", "Novelty seeking", "Structure", "Need for Action", "Desire to Lead", "Improvisation", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Focus on Facts", "Free Thinking"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Extraversion")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Novelty seeking")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Structure")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Teacher":
        this.gleacSpiderWebData.categories = ["Extraversion", "Structure", "Altruism",  "Straight Forwardness", "Novelty seeking",  "Need for Action", "Desire to Lead", "Improvisation", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy",  "Focus on Facts", "Free Thinking"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Extraversion")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Structure")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Altruism")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Straightforwardness")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Ethical Roles":
        this.gleacSpiderWebData.categories = ["Rule-Following", "Structure", "Firmness",  "Need for Reflection",  "Straight Forwardness", "Novelty seeking",  "Need for Action", "Desire to Lead", "Improvisation",  "Ambition", "Involvement at Work", "Autonomy",  "Focus on Facts", "Free Thinking"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Rule following")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Structure")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Firmness")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Need for Reflection")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Investigative Roles":
        this.gleacSpiderWebData.categories = ["Emotional Distance", "Involvement at Work", "Rule-Following", "Firmness",  "Need for Reflection",  "Straight Forwardness", "Novelty seeking",  "Need for Action", "Desire to Lead", "Improvisation",  "Ambition", "Autonomy",  "Focus on Facts", "Free Thinking"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Emotional Distance")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Involvement at work")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Rule following")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Firmness")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      case "Leadership":
        this.gleacSpiderWebData.categories = ["Desire to Lead", "Ambition", "Involvement at Work", "Persuasiveness",  "Rule-Following", "Firmness",  "Need for Reflection",  "Straight Forwardness", "Novelty seeking",  "Need for Action",  "Improvisation", "Autonomy",  "Focus on Facts", "Free Thinking"]
        this.gleacSpiderWebData.optimalZones = [4, 4, 4, 4, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Desire to Lead")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Ambition")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Involvement at Work")).map(x => x.calculatedScore)[0]),
          parseFloat(this.userScore.candidateScores.filter(score => score.skillName.includes("Persuasiveness")).map(x => x.calculatedScore)[0]),
          0, 0, 0, 0, 0, 0];
        break;
      default:
        this.gleacSpiderWebData.categories = ["Persuasiveness", "Emotional Distance", "Need for Action", "Extraversion", "Desire to Lead", "Rule-Following", "Ambition", "Involvement at Work", "Autonomy", "Altruism", "Novelty Seeking", "Focus on Facts", "Improvisation", "Flexibility"]
        this.gleacSpiderWebData.optimalZones = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.gleacSpiderWebData.benchmarkScores = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        break;
    }

    this.progressTracking = {
      chart: {
        polar: true,
        type: "line"
      },

      title: {
        text: "",
        x: 0
      },

      pane: {
        size: "55%"
      },

      credits: {
        enabled: false
      },

      legend: {
        enabled: false,
        width: '100px',
        x: '50%',
        y: '50%'
      },

      xAxis: {
        categories: this.gleacSpiderWebData.categories,
        tickmarkPlacement: "on",
        lineWidth: 0,
        labels: {
          style: {
            fontSize: '9px'
          },
          useHTML: true,
          formatter: function () {
            if (this.pos > 3) {
              return '<div class="locked-skill"><img src="assets/images/lock-icon.svg" class="img-responsive" /></div>' + this.value;
            } else {
              return '<b>' + this.value + '</b>';
            }
          }
        }
      },

      yAxis: {
        gridLineInterpolation: "polygon",
        tickInterval: 1,
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true,
        split: true,
        useHTML: true,
        padding: 0.9,
        pointFormatter: function () {
          if (this.index < 4) {
            if (Number(this.y) === this.y && this.y % 1 !== 0) {
              return '<span style="color:' + this.series.color + ';">' + this.series.name + ' (<b>' + Highcharts.numberFormat(this.y, 2) + '</b>)</span><br/>';
            } else {
              return '<span style="color:' + this.series.color + ';">' + this.series.name + ' (<b>' + this.y + '</b>)</span><br/>';
            }
          } else {
            return '';
          }
        }
      },

      series: [
        {
          showInLegend: false,
          name: 'Optimal Zone',
          data: this.gleacSpiderWebData.optimalZones,
          color: '#1162fb',
          pointPlacement: "on"
        },
        {
          showInLegend: false,
          name: 'Your Strength',
          data: this.gleacSpiderWebData.benchmarkScores,
          color: '#A7A9AC',
          pointPlacement: "on"
        }
      ]
    };
  }

  download($event) {
    if (this.isYoma) {
      document.getElementById('yoma-courses-div').style.display = 'none';
    }
    document.getElementById('download-section-div').style.display = 'none';
    document.getElementById('outer-panel-div').style.display = 'none';

    var body = document.body, html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    document.body.style.height = `${height}px`;

    html2canvas(document.body).then(canvas => {
      var imgData = canvas.toDataURL("image/png");
      var a = document.createElement("a");
      a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      a.download = "benchmark-report.jpg";
      a.click();
      if (this.isYoma) {
        document.getElementById('yoma-courses-div').style.display = 'block';
      }
      document.getElementById('download-section-div').style.display = 'flex';
      document.getElementById('outer-panel-div').style.display = 'block';
    });

    if (this.isYoma) {
      this.trackDownloadEvent(this.skillName);
    }
  }

  calculateDifference(percentageScore) {
    var result = Math.round(percentageScore) - 67;
    if (result % 1 === 0)
      return result;
    return result.toFixed(2);
  }
}
