import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'angular-6-social-login';
import { UsersService } from 'src/app/services/users.service';
import { Track } from "ngx-audio-player";
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoginPopupComponent } from 'src/app/dialogs/login-popup/login-popup.component';

class MatBasicAudioPlayerComponent {
}

@Component({
  selector: 'app-candidate-layout',
  templateUrl: './candidate-layout.component.html',
  styleUrls: ['./candidate-layout.component.less']
})
export class CandidateLayoutComponent implements OnInit {
  loginPopup: MatDialogRef<LoginPopupComponent>;
  userName: string;
  timeRanOut: boolean = false;
  isFirstClick: boolean = false;


  constructor(private router: Router, public usersService: UsersService, public socialService: AuthService, protected dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.usersService.currentUser) {
      this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(res => {
        this.userName = res.result.firstName + " " + res.result.lastName;
        this.usersService.currentUser.firstName = res.result.firstName;
        this.usersService.currentUser.lastName = res.result.lastName;
      });
    }
  }

  logoutUser() {
    this.socialService.signOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  showLoginPopup(initialView = 'login') {
    this.loginPopup = this.dialog.open(LoginPopupComponent, {
      width: '35vw',
      disableClose: false,
      autoFocus: false,
      data: {
        dataKey: this.timeRanOut,
        initialView: initialView
      },
      closeOnNavigation: false
    });
  }
}