import {appSettings} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EventsQuery} from "../models/events-query";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  eventsUrl: string = appSettings.dashboardUrl;

  constructor(private http: HttpClient) {

  }

  getAllEvents(query: EventsQuery) {
    let url = this.eventsUrl + "Event/GetFilteredEvents" + query.toQueryString();
    return this.http.get<any>(url);
  }

  exportEvents(query: EventsQuery) {
    let url = this.eventsUrl + "Event/ExportEvents" + query.toQueryString();
    var response = this.http.get(url, {responseType: 'blob'});
    return response;
  }

  deleteEvent(id) {
    let url = this.eventsUrl + "Event/DeleteEvent?eventId=" + id;
    return this.http.delete<any>(url);
  }

  addOrUpdateEvent(data) {
    var url = this.eventsUrl + "Event/AddOrUpdateEvent";
    return this.http.post<any>(url, data);
  }
}
