import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {CommonService} from 'src/app/services/common.service';
import {ConfigsService} from 'src/app/services/configs.service';
import {Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {GoalsService} from 'src/app/services/goal.service';
import {FileUploadService} from 'src/app/services/file-upload.service';
import * as XLSX from 'xlsx';
import {GoalsPopupComponent} from 'src/app/dialogs/goal-popup/goals-popup.component';
import {MatDialog, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {saveAs} from 'file-saver';
import {CommunicationService} from 'src/app/services/communication.service';
import {CommunicationsPopupComponent} from 'src/app/dialogs/communication-popup/communications-popup.component';
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.less']
})
export class CommunicationComponent implements OnInit {
  pageUrl: string = "communication";
  page_title = "Communication";
  searchString: string = "";
  pageNumber: number = 1;
  pageSize: number = 10;
  isEdit: boolean;
  error_popup = {};
  currentSection = 1;
  url = "";
  isErrorPopup: boolean = false;
  isSuccessPopup: boolean = false;
  communicationGridData: any;
  isCreatePopup: boolean = false;
  isConfirmPopup = false;
  form: any;
  goals: any;
  status: string = "";
  date: string = "";
  userGroup: string = "";
  userGroups: any;

  sortColumn: string = "";
  sortOrder: string = "";
  communicationPopup: MatDialogRef<CommunicationsPopupComponent>;
  eventsSubject: Subject<void> = new Subject<void>();
  isFilterCleared: boolean = false;
  isResolutionError: boolean = false;
  deleteRequest: any = {};
  editRequest: any = {}
  viewEmail: boolean = false;
  viewLog: boolean = false;
  logData = {list: [], totalCount: 0};
  viewEmailFile: string = '';

  create_popup: any = {
    display: false,
    title: "",
    field: [],
    buttonPositive: "",
    closeModal: function () {
    },
    createrCall: function (form, communicationData, uploadedFileName) {
    }
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    public usersService: UsersService,
    private communicationService: CommunicationService,
    protected dialog: MatDialog,
    private uploadGoalImage: FileUploadService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
  }

  filterArray: any = [
    {
      label: "Status",
      type: "static_select_goals",
      key: "status",
      options: [
        {name: "Sent", value: "Sent"},
        {name: "Scheduled", value: "Scheduled"},
      ]
    },
    {
      label: "Scheduled",
      type: "datePickerCommunication",
      key: "date",
    }
  ];

  logColumnData: GleacColumnDef[] = [
    {
      displayName: "Name",
      data: "name",
    },
    {
      displayName: "Email",
      data: "email",
    },
    {
      displayName: "Date",
      data: "date",
      render: (data, row) => {
        //var date = new Date(this.convertUTCDateToLocalDate(row.date));
        return this.GetFormattedDate(new Date(row.date));
      },
    }
  ];

  goalColumnData: GleacColumnDef[] = [
    {
      displayName: "Email Subject",
      data: "emailSubject",
      render: (data, row) => {
        return `
              <span>${row.emailSubject ? row.emailSubject : '-'}</span>`;
      }
    },
    {
      displayName: "Group",
      data: "group",
      render: (data, row) => {
        return `
            <span>${row.group ? row.group : '-'}</span>`;
      }
    },
    {
      displayName: "Organization",
      data: "organization",
      render: (data, row) => {
        return `
            <span>${row.organization ? row.organization : '-'}</span>`;
      }
    },
    {
      displayName: "Exclude",
      data: "excludeList",
      render: (data, row) => {
        return `
            <span>${row.excludeList ? row.excludeList.replace(",", ", ") : '-'}</span>`;
      }
    },
    {
      displayName: "Status",
      data: "status",
      render: (data, row) => {
        return `
              <span >${row.status ? "sent" : "scheduled"}</span>`;
      }
    },
    {
      displayName: "Scheduled Date",
      data: "scheduledDate",
      render: (data, row) => {
        var date = new Date(this.convertUTCDateToLocalDate(row.scheduleDate));
        return this.GetFormattedDate(date);
      },
    },
    {
      displayName: "Sent To/Open By",
      data: "count",
      disableSort: true,
      render: (data, row) => {
        return `
              <span >${row.count ? row.count + '/' + row.open : '0/0'}</span>`;
      }
    },
    {
      displayName: "Clicks",
      data: "clicks",
      disableSort: true,
      render: (data, row) => {
        return `
              <span >${row.clicks ? row.clicks : 0}</span>`;
      }
    },
    {
      displayName: "Unsubscribes",
      data: "unsubscribes",
      disableSort: true,
      render: (data, row) => {
        return `
             <span >${row.unsubscribes ? row.unsubscribes : 0}</span>`;
      }
    },
    {
      displayName: "Bounces",
      data: "bounces",
      disableSort: true,
      render: (data, row) => {
        return `
             <span >${row.bounces ? row.bounces : 0}</span>`;
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Communication_Add'
        },
        {
          label: "Duplicate As Template",
          url: "#",
          functionType: "duplicate",
          image: "assets/images/add_icon_goals.svg",
          permission: 'Communication_Add'
        },
        {
          label: "View",
          url: "#",
          functionType: "view",
          image: "assets/images/eye.png",
          permission: 'Communication_View'
        },
        {
          label: "Log",
          url: "#",
          functionType: "log",
          image: "assets/images/eye.png",
          permission: 'Communication_View'
        },
        {
          label: "Opened Emails",
          url: "#",
          functionType: "exportOpenedEmails",
          permission: 'Communication_Export'
        },
        {
          label: "Clicks",
          url: "#",
          functionType: "exportClickedEmails",
          permission: 'Communication_Export'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Communication_Delete'
        }
      ]
    }
  ];

  GetFormattedDate(date) {
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      ", " +
      (date.getHours() % 12
        ? date.getHours() % 12 < 10
          ? "0" + (date.getHours() % 12)
          : date.getHours() % 12
        : 12) +
      ":" +
      (date.getMinutes() < 10
        ? "0" + date.getMinutes()
        : date.getMinutes()) +
      " " +
      (date.getHours() >= 12 ? "PM" : "AM")
    );
  }

  getCommunicationsList() {
    this.configsService.loading(true);
    this.communicationService.getAllCommunications(
      this.pageNumber,
      this.pageSize
    ).subscribe(
      (res) => {
        if (res) {

          if (res.results.length == 0 && this.pageNumber > 1) {
            this.pageNumber--
            this.getCommunicationsList();
            return;
          }

          let communicationData = {
            list: res.results,
            totalCount: res.totalCount
          }

          let context = this;
          communicationData.list.forEach(function (item) {
            var startDate = context.convertUTCDateToLocalDate(new Date(item.start_date));
            var endDate = context.convertUTCDateToLocalDate(new Date(item.end_date));

            item.goal_duration = startDate.toLocaleDateString() + " " + endDate.toLocaleDateString();
          });

          this.communicationGridData = communicationData;

        } else {
          this.communicationGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getSearchResults(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    this.configsService.loading(true);
    this.communicationService.search(
      encodeURIComponent(searchString), pageNumber, pageSize, sortColumn, sortOrder, this.status ? this.status : '', this.date).subscribe(
      (res) => {
        if (res) {
          let communicationData = {
            list: res.results,
            totalCount: res.totalCount
          }

          this.communicationGridData = communicationData;
        } else {
          this.communicationGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getFilteredResults(searchString, status, date, pageNumber, pageSize, sortColumn, sortOrder) {
    this.configsService.loading(true);
    // this.getSearchResults(this.searchString, pageNumber, pageSize, this.sortColumn, this.sortOrder);
    this.communicationService.search(encodeURIComponent(searchString), pageNumber, pageSize, sortColumn, sortOrder, status, date).subscribe(
      (res) => {
        if (res) {
          let communicationData = {
            list: res.results,
            totalCount: res.totalCount
          }
          this.communicationGridData = communicationData;
        } else {
          this.communicationGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  exportToExcel() {
    this.configsService.loading(true);
    var date = new Date();
    var timeDiff = date.getTimezoneOffset();
    this.communicationService.exportCommunications(
      encodeURIComponent(this.searchString), this.sortColumn, this.sortOrder, this.status ? this.status : '', this.date ? this.date : '', timeDiff).subscribe(
      (res) => {
        saveAs(res, "Communications" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  exportOpenedEmailsToExcel(communicationId) {
    this.configsService.loading(true);
    this.communicationService.exportOpenedEmailsToExcel(communicationId).subscribe(
      (res) => {
        saveAs(res, "Opened Emails" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  exportClickedEmailsToExcel(communicationId) {
    this.configsService.loading(true);
    this.communicationService.exportClickedEmailsToExcel(communicationId).subscribe(
      (res) => {
        saveAs(res, "Cliked Emails" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.pageNumber = event;

    if (this.isFilterCleared) {
      this.isFilterCleared = false;
      this.date = '';
      this.status = '';

      if (this.userGroup && this.status && this.date) {
        this.getFilteredResults(this.searchString, this.status, this.date, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
      } else {
        this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
      }
    } else {
      if (this.userGroup && this.status && this.date) {
        this.getFilteredResults(this.searchString, this.status, this.date, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
      } else {
        this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
      }
    }
  }

  searchData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, '', '');
  }

  filterData(params) {
    this.pageNumber = 1;
    if (params && params[1] && params[1].form && params[1].form.value) {
      this.status = params[1].form.value.status ? params[1].form.value.status : '';
      this.date = params[1].form.value.scheduledDate ? params[1].form.value.scheduledDate.toJSON() : '';
    }
    this.getFilteredResults(this.searchString, this.status, this.date, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
  }

  createCommunication() {
    this.communicationPopup = this.dialog.open(CommunicationsPopupComponent, {
      disableClose: true,
      width: "90%",
      height: "auto",
      data: {
        dataKey: 'test',
        isTemplate: false,
        isShowDropdown: true
      }
    });

    this.communicationPopup.beforeClosed().subscribe(result => {
      this.pageNumber = 1;
      this.getFilteredResults(this.searchString, this.status, this.date, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
      //this.eventsSubject.next();
      this.isFilterCleared = true;
    });
  }

  actionClick(event) {
    if (event.action === "edit") {
      this.communicationPopup = this.dialog.open(CommunicationsPopupComponent, {
        disableClose: true,
        width: "90%",
        height: "auto",
        data: {
          dataKey: 'test',
          id: event.data.id,
          fileName: event.data.fileName,
          emailSubject: event.data.emailSubject,
          scheduleDate: event.data.scheduleDate,
          emailBranding: event.data.emailBranding,
          fromEmail: event.data.fromEmail,
          replyEmail: event.data.replyEmail,
          isEdit: true,
          isTemplate: false,
          excludeList: event.data.excludeList
        }
      });

      this.communicationPopup.beforeClosed().subscribe(result => {
        this.pageNumber = 1;
        this.getFilteredResults(this.searchString, this.status, this.date, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
        //this.eventsSubject.next();
        this.isFilterCleared = true;
      });
      // console.log(event.data, 'this is full event');
      // var startDate = this.convertUTCDateToLocalDate(new Date(event.data.startDate));
      // this.isCreatePopup = true;
      // this.create_popup = {
      //   display: true,
      //   bulk_upload: false,
      //   cssClass: 'col-15',
      //   createrName: "updateCommunicationsData",
      //   title: "Update Communication Data",
      //   buttonPositive: "Update",
      //   field: [
      //     {
      //       label: "Email Subject",
      //       key: "emailSubject",
      //       type: "input",
      //       cssClass: "col-12",
      //       required: "required",
      //       value: event.data.emailSubject
      //     },
      //     {
      //       label: "Scheduled Date",
      //       key: "scheduledDate",
      //       type: "date_time_picker_start",
      //       cssClass: 'col-6',
      //       value: startDate ? startDate : ""
      //     }
      //   ],
      //   closeModal: () => {
      //     this.isCreatePopup = false;
      //   },
      //   createrCall: (form: any, uploadedFileName) => {
      //     let CommunicationData = Object.assign(form.value);

      //     if (CommunicationData.CommunicationData < CommunicationData.startDate1)
      //       return;
      //     this.editRequest.communicationLibraryId = event.data && event.data.communicationLibraryId ? event.data.communicationLibraryId : "";
      //     this.editRequest.status = CommunicationData.status;
      //     this.editRequest.startDate = CommunicationData.startDate1;
      //     this.editRequest.emailSubject = CommunicationData.emailSubject;
      //     this.editRequest.id = event.data.id;


      //     if (event.data) {
      //       console.log(CommunicationData, "new data");
      //       this.configsService.loading(false);

      //       this.communicationService.updateCommunications(this.editRequest)
      //         .subscribe(
      //           (data) => {
      //             if (data.has_error) {
      //               this.showToast(true, data.errors[0]);
      //               return;
      //             } else {
      //               this.showToast(false, "Email Updated Successfully!");
      //             }
      //           },
      //           (err) => {
      //             this.configsService.loading(false);
      //             this.isErrorPopup = true;
      //             this.error_popup = {
      //               display: true,
      //               message: err.error.errors[0],
      //             };
      //           },
      //           () => {
      //             this.configsService.loading(false);
      //             //this.getGoalsList();
      //           }
      //         );
      //     }
      //   }
      // }
    } else if (event.action === "view") {
      this.configsService.loading(true);
      this.communicationService.getViewEmailHtml(event.data.fileName).subscribe(res => {
        this.viewEmail = true;
        this.viewEmailFile = res.result.html;
      }, (error => {
        this.configsService.loading(false);
      }), () => {
        this.configsService.loading(false);
      });
    } else if (event.action === "duplicate") {

      // let CommunicationLibraryData = {
      //   title: this.model.fileName !== "" ? this.model.fileName : this.title,
      //   editorJSON: JSON.stringify(data),
      //   id: this.model.communicationLibraryId === 0 ? this.CommunicationLibraryId : this.model.communicationLibraryId
      // };


      this.configsService.loading(true);
      this.communicationService.duplicateTemplate(event.data.id, event.data.emailSubject + "_Duplicate").subscribe(res => {

        this.router.navigateByUrl('communication/communication-library/' + res.libraryId);

      }, (error => {
        this.configsService.loading(false);
      }), () => {
        this.configsService.loading(false);
      });
    } else if (event.action === "exportOpenedEmails") {
      this.exportOpenedEmailsToExcel(event.data.id);
    } else if (event.action === "exportClickedEmails") {
      this.exportClickedEmailsToExcel(event.data.id);
    } else if (event.action === "log") {
      this.viewLog = true;
      this.logData = event.data.log ? {list: event.data.log, totalCount: event.data.log.length} : {
        list: [],
        totalCount: 0
      }
    } else {
      console.log("Delete event");
      console.log(event.data);


      this.deleteRequest.communicationLibraryId = event.data.communicationLibraryId;
      this.deleteRequest.id = event.data.id;

      this.isConfirmPopup = true;
      this.confirm = {
        display: true,
        message: "Are you sure you want to Delete?",
        buttonPositive: "Yes",
        buttonNegative: "No",
        isTopic: "Communication",
        imageUrl: "",
        negativeButton: () => {
          this.confirm.display = false;
        },
        positiveButton: () => {
          this.configsService.loading(true);
          this.communicationService.deleteCommunications(this.deleteRequest).subscribe(
            (data) => {
              if (data.has_error) {
                this.showToast(true, data.errors[0]);
                return;
              } else {
                this.showToast(false, "Communication Deleted Successfully!");
              }
            },
            (err) => {
              this.configsService.loading(false);
              this.isErrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
            }
          );
          this.confirm.display = false;
        }
      };
    }
  }

  convertUTCDateToLocalDate(date) {
    if (typeof date.getMonth !== 'function') {
      date = new Date(date);
    }
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.searchString = this.searchString;
    this.pageNumber = 1;

    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);

  }

  showToast(isError, message) {
    if (isError) {
      this.isErrorPopup = true;
      this.error_popup = {display: true, message: message};
      this.isCreatePopup = true;
    } else {
      this.isSuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.isCreatePopup = false;
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
          //this.eventsSubject.next();
          this.isFilterCleared = true;
        },
      };
    }
  }

  closeViewEmail() {
    this.viewEmail = false;
    this.viewEmailFile = '';
  }

  closeViewLog() {
    this.viewLog = false;
    this.logData = {list: [], totalCount: 0};
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(this.viewEmailFile);
  }
}
