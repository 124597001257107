import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { SimpleChanges } from "@angular/core";
import { GleacColumnDef } from "./data-grid.model";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import { ComponentLoaderService } from "src/app/services/component-loader.service";
import { ExpandableRowDirective } from "src/app/directives/expandable-row.directive";
import { UsersService } from "../../services/users.service";

import { Paginator } from "primeng/paginator"

@Component({
  selector: "app-data-grid",
  templateUrl: "./data-grid.component.html",
  styleUrls: ["./data-grid.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-in", style({ transform: "translateX(70%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class DataGridComponent implements OnChanges, OnInit {
  @Input() columnData: GleacColumnDef[] = [];
  @Input() tableData: any;
  gridData: MatTableDataSource<any>;
  displayCols: string[] = [];
  errors: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginatorp', { static: false }) paginatorp: Paginator

  isExpandable: boolean = false;
  expandedRow: any;
  @Input() extraData: any;
  @Input() data: any;
  @Input() pageTitle: any;
  @Input() pageLimit: any;
  @Input() pageIndex: any;
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Output() colClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ExpandableRowDirective) expandableRowContent: QueryList<ExpandableRowDirective>;
  visible: boolean = false;
  inputData: any;
  enableRow: boolean = true;
  isPageChange: boolean = false;
  stopPageChange: boolean = false;

  constructor(private componentLoaderService: ComponentLoaderService, public userService: UsersService) {
  }

  ngOnInit(): void {
    let activeCols = this.columnData.filter((col) => {
      if (col.isVisible === undefined || col.isVisible == true) return true;
    });
    this.displayCols = activeCols.map((x) => x.data);
    this.isExpandable =
      this.columnData.filter((col) => {
        return col.isExpandable;
      }).length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridData = new MatTableDataSource(
      this.tableData ? this.tableData.list : ""
    );
    if (
      this.tableData !== undefined && this.tableData.list
        ? this.tableData.list.length > 0
        : ""
    ) {
      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;

      if (!this.isPageChange) {
        this.stopPageChange = true;
        setTimeout(() => {
          this.stopPageChange = true;
          this.paginatorp.changePage(0);
        });
      }

      this.isPageChange = false;
    } else if (
      this.tableData && this.tableData.list
        ? this.tableData.list.length == 0
        : ""
    ) {
      // =======
      //     this.gridData = new MatTableDataSource(this.tableData);
      //     if (this.tableData && this.tableData.length > 0) {
      //       this.paginator.pageSize = 25;
      //       this.paginator.hidePageSize = true;
      //       this.gridData.paginator = this.paginator;
      //       this.gridData.sort = this.sort;
      //     }
      //     else if(!this.tableData || this.tableData.length == 0) {
      //       this.tableData = [];
      //       this.gridData = new MatTableDataSource(this.tableData);
      //       this.paginator.pageSize = 25;
      //       this.paginator.hidePageSize = true;
      // >>>>>>> develop
      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;
      // console.log(this.tableData)
    }

  }

  columnClick(event, data, index) {
    // console.log(event, data, index);
    event.stopPropagation();
    this.enableRow = false;
    this.colClickEvent.emit({ click: event, data: data });
  }

  actionClick(event, data, action, index) {
    // console.log(event, data, index);

    event.stopPropagation();
    this.colClickEvent.emit({ click: event, data: data, action: action });
  }

  expandRow(event, data, action, i) {
    // console.log(event, data, action,i)
    if (event.target.className.includes("no_navigate")) {
      this.colClickEvent.emit({ click: event, data: data, action: action });
    } else {
      this.rowClickEvent.emit({ click: event, data: data });
    }
  }

  logger(object: any) {
  }

  paginate(event) {

    if (this.stopPageChange) {
      this.stopPageChange = false;
      return;
    }

    this.isPageChange = true;
    this.pageEvent.emit(event.page + 1);
  }
}
