import {UsersService} from "./services/users.service";

export function appInitializer(usersService: UsersService) {
  return () => new Promise(resolve => {
    // attempt to refresh token on app start up to auto authenticate
    usersService.refreshToken()
      .subscribe(data => {
        if (!data.hasError) {
          localStorage.setItem("GATk", data.result.accessToken);
          localStorage.setItem("GRTk", data.result.refreshToken);
          let token = localStorage.getItem("GATk");
          let jwtData = token.split(".")[1];
          let decodedJwtJsonData = window.atob(jwtData);
          let decodedJwtData = JSON.parse(decodedJwtJsonData);
          let decodedJwtDataExpiration = new Date(decodedJwtData.exp * 1000);
          if (decodedJwtDataExpiration.getTime() >= Date.now()) {
            usersService.currentUser = {
              authRole: decodedJwtData.auth_role,
              email: decodedJwtData.email,
              userId: decodedJwtData.sub,
              permissions: decodedJwtData.permissions
            };
            usersService.isAdmin = true;
            if (decodedJwtData.company) usersService.currentUser.company = decodedJwtData.company;
            usersService.startRefreshTokenTimer();
          }
        }
      })
      .add(resolve);
  });
}
