import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import {LessonsService} from "src/app/services/lessons.service";
import {ConfigsService} from 'src/app/services/configs.service';
import {SkillTags, SkillType} from '../common/common.model';
import {NgForm} from '@angular/forms';
import {CommonService} from 'src/app/services/common.service';
import {from} from 'rxjs';
import {appSettings} from 'src/environments/environment';
import {Router} from '@angular/router';
import {UsersService} from 'src/app/services/users.service';
import {MentorService} from 'src/app/services/mentor.service';
import {DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import {FeaturedMentorAnswerService} from "../../services/featured-mentor-answer.service";
import {FileUploadService} from "../../services/file-upload.service";
import {F} from "@angular/cdk/keycodes";
import {saveAs} from 'file-saver';
import {MentorBookingsService} from "../../services/mentor-bookings.service";


@Component({
  selector: 'app-nft-mentors-booking',
  templateUrl: './nft-mentor-bookings.component.html',
  styleUrls: ['./nft-mentor-bookings.component.less']
})
export class NftMentorBookingsComponent implements OnInit {

  pageUrl: string = "mentor/nft-bookings";
  page_title = "NFT Bookings";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  sortColumn: string = '';
  sortOrder: string = '';
  status: number;

  gridData: any;

  updateMeetingFlag = false;

  meeting: any;

  isconfirmPopup = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  iscreatePopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  create_popup = {
    display: false,
    title: "",
    createrName: '',
    isUpdate: false,
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any, data) {
    },
  };

  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        {name: "Pending", value: 0},
        {name: "Accepted", value: 1},
        {name: "Completed", value: 2},
      ],
    }
  ];

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    public usersService: UsersService,
    private mentorBookings: MentorBookingsService,
    private datepipe: DatePipe,
  ) {
    this.getBookings();
  }

  ngOnInit() {
    this.usersService.isMentor = true;
  }

  nftColumnData: GleacColumnDef[] = [
    {
      displayName: "Username",
      data: "username",
      isExpandable: true,
    },
    {
      displayName: "Mentor Name",
      data: "mentorName",
    },
    {
      displayName: "Is Intro Meeting",
      data: "isIntroMeeting",
      render: (data, row) => {
        return row.isIntroMeeting ? "Yes" : "No";
      }
    },
    {
      displayName: "Date",
      data: "date",
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${row.date} ${row.startTime}`)), 'MMM d, y');
      }
    },
    {
      displayName: "Start Time",
      data: "startTime",
      disableSort: true,
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${row.date} ${row.startTime}`)), 'HH:mm');
      }
    },
    {
      displayName: "EndTime",
      data: "endTime",
      disableSort: true,
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${row.date} ${row.endTime}`)), 'HH:mm');
      }
    },
    {
      displayName: "Status",
      data: "status",
      disableSort: true,
      render: (data, row) => {

        let status = '';

        if (row.status == 0) {
          status = "Pending";
        } else if (row.status == 1) {
          status = "Accepted";
        } else {
          status = "Completed";
        }

        if (row.status != 0) {
          return '<div style="display: flex;align-items:center"><span class="dot gleac-badge-text success mr-2"></span><span>' + status + '</span></div>';
        }
        return '<div style="display: flex;align-items:center"><span class="dot gleac-badge-text danger mr-2"></span><span>' + status + '</span></div>';
      }
    },
    {
      displayName: "Created At",
      data: "createdAt",
      isDate: true
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Confirm",
          url: "#",
          functionType: "confirm",
          image: "assets/images/edit_icon.svg",
        }
      ],
    },
  ];

  getBookings() {
    this.configsService.loading(true);
    this.mentorBookings.getNftBookings(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder,
      this.status
    ).subscribe(
      (res) => {
        if (res) {
          this.gridData = {
            list: res.result.data,
            totalCount: res.result.totalCount
          };
          //this.mentors = res.result.data;
        } else {
          this.gridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getBookings();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getBookings();
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.getBookings()
  }

  customFilter(params) {
    this.pageNumber = 1;
    if (params[1] && params[1].form && params[1].form.value && params[1].form.value.status != undefined) {
      this.status = params[1].form.value.status;
    } else {
      this.status = undefined;
    }
    this.getBookings();
  }

  addNftBundle(data: any) {
    this.updateMeetingFlag = true;
    this.meeting = data;
  }

  onClose() {
    this.updateMeetingFlag = false;
    this.pageNumber = 1;
    this.getBookings();
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.mentorBookings.exportNftBookings(1,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder,
      this.status
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "NFT Mentor Bookings" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  actionClick(event) {
    if (event.action === "confirm") {

      let st = this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${event.data.date} ${event.data.startTime}`)), 'HH:mm');
      let et = this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${event.data.date} ${event.data.endTime}`)), 'HH:mm');

      this.iscreatePopup = true;
      this.create_popup = {
        display: true,
        bulk_upload: false,
        bulkInfo: null,
        createrName: "updateMeeting",
        title: "Confirm Meeting",
        buttonPositive: "Update",
        isUpdate: true,
        field: [
          {
            label: "Username",
            key: "username",
            placeholder: "Enter the Event name",
            type: "input",
            innertype: "text",
            pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
            required: "required",
            cssClass: 'col-12',
            maxlength: 1000,
            value: event.data.username,
            disabled: true
          },
          {
            label: "Mentor Name",
            key: "mentorName",
            placeholder: "Enter the Event name",
            type: "input",
            innertype: "text",
            pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
            required: "required",
            cssClass: 'col-12',
            maxlength: 1000,
            value: event.data.mentorName,
            disabled: true
          },
          {
            label: "Is Intro Meeting",
            key: "onRequest",
            type: "switchButton",
            cssClass: 'col-6',
            value: event.data.isIntroMeeting ? true : false,
            disabled: true
          },
          {
            label: "Scheduled Date",
            key: "date",
            type: "date_picker_date",
            cssClass: 'col-12',
            value: this.convertUTCDateToLocalDate(new Date(`${event.data.date} ${event.data.startTime}`)),
            required: "required",
          },
          {
            label: "Start Time",
            key: "startTime",
            type: "time_picker_start",
            required: "required",
            cssClass: 'col-12',
            value: {
              hour: Number(st.split(':')[0]),
              minute: Number(st.split(':')[1])
            }
          },
          {
            label: "End Time",
            key: "endTime",
            type: "time_picker_end",
            required: "required",
            cssClass: 'col-12',
            value: {
              hour: Number(et.split(':')[0]),
              minute: Number(et.split(':')[1])
            }
          },
        ],
        refreshData: () => {
          this.getBookings();
        },
        closeModal: () => {
          this.create_popup.display = false;
          this.iscreatePopup = false;
        },
        createrCall: (form: NgForm, responseData) => {
          if (form.valid) {
            let ListData: any = [];
            ListData = {...form.value};
            console.log(ListData);

            let startTime = new Date(`${ListData.date.getMonth() + 1}/${ListData.date.getDate()}/${ListData.date.getFullYear()} ${ListData.startTime.hour}:${ListData.startTime.minute}:00`);
            let endTime = new Date(`${ListData.date.getMonth() + 1}/${ListData.date.getDate()}/${ListData.date.getFullYear()} ${ListData.endTime.hour}:${ListData.endTime.minute}:00`);


            let request: any = {};
            request.bookingId = event.data.bookingId;
            request.date = startTime.toISOString().split('T')[0];
            request.startTime = startTime.toISOString().split('T')[1].split('.')[0];
            request.endTime = endTime.toISOString().split('T')[1].split('.')[0];


            this.configsService.loading(true);

            this.mentorBookings
              .updateNftBooking(request)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          }
        },
      };


      // let data: any = {};
      // data.name = event.data.name
      // data.bookingId = event.data.id;
      // data.username = event.data.username;
      // data.mentorName = event.data.mentorName;
      // data.date = event.data.date;
      // data.startTime = event.data.startTime;
      // data.endTime = event.data.endTime;
      //
      // this.meeting = data;
      // this.updateMeetingFlag = true;
    }
  }


  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: "Updated Successfully",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getBookings();
        },
      };
    } else {
      this.iserrorPopup = true;
    }
  }

  convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }
}

