import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigsService } from "../../services/configs.service";
import { CommonService } from "../../services/common.service";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  FormArray,
} from "@angular/forms";

@Component({
  selector: "app-assign-user",
  templateUrl: "./assign-user.component.html",
  styleUrls: ["./assign-user.component.less"],
})
export class AssignUserComponent implements OnInit, OnDestroy {
  items = [];
  iscreatePopup: boolean = false;
  breadCrumsArray: any = [];
  templateUrl = "";
  files;
  userInvite;
  isselectUser: boolean = false;
  select_user_popup = {};
  companyId;
  campaignId;
  url;
  issuccessPopup: boolean = false;
  iserrorPopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {},
  };
  userSelected = [];
  groupSelected = [];
  userGroupSelected = [];
  userGroupFilterSelected = [];
  editData;
  isreportPopup = false;
  report_popup = {
    display: false,
  };
  viaEmailData;
  bulkInfo = {
    url: "AssignUser/bulkupload/assignuser/",
    templateUrl:
      "https://heptestbucket.s3.ap-south-1.amazonaws.com/gleac-template/AssignUser-Template.xlsx",
  };
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private configsService: ConfigsService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.campaignId = params["campaignId"];
      this.companyId = params["companyId"];
      if (this.campaignId) {
        this.getEditData(this.campaignId);
      }
    });
  }

  getEditData(campaignId) {
    this.configsService.loading(true);
    this.url = "Campaigns/details?lessonCampainId=" + campaignId;
    this.commonService.getCommonApi(this.url, "get", "").subscribe(
      (data) => {
        this.editData = data.result.list;
        console.log(data.result);
        if (this.editData) {
          this.userGroupSelected = this.editData.map((x) => {
            return {
              name: x.userName,
              id: x.userId,
              mail: x.userEmail,
              key: "user",
              listKey: 'userSelected',
            };
          });
          this.userGroupFilterSelected = this.userGroupSelected;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  createCampaign(form, navData) {
    form.submitted = true;
    if (form.valid) {
      let userData = [];
      let groupData = [];
      let sendingData = {};
      this.userGroupFilterSelected.map((f, i) => {
        if (f.mail && f.key == "user") {
          userData.push(f.mail.toString());
        } else if (f.key == "group") {
          groupData.push(f.id.toString());
        } else {
          userData.push(f.mail.toString());
        }
      });

      if (form.value.assign_invite) {
        this.viaEmailData = form.value.assign_invite.split(",");
      }

      if (this.viaEmailData) {
        if (userData) {
          this.viaEmailData.map((f, i) => {
            let insert = 0;
            if (userData.length > 0) {
              userData.map((m, n) => {
                if (m != f) {
                  insert = insert + 1;
                }
                if (insert == userData.length) {
                  userData.push(f);
                }
              });
            } else {
              userData = this.viaEmailData;
            }
          });
        } else {
          userData = this.viaEmailData;
        }
      }

      groupData = groupData ? groupData : [];
      userData = userData ? userData : [];
      sendingData = {
        lessonCampaignId: this.campaignId,
        groupId: groupData,
        users: userData,
      };
      
      this.configsService.loading(true);
      this.url = "AssignUser";
      this.commonService.createData(this.url, sendingData, false).subscribe(
        (data) => {
          this.postStatus(data, navData);
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
          form.submitted = false;
        }
      );
    }
  }
  validateInput(userInviteList, dataForm, field) {
    // console.log(userInviteList);
    if (userInviteList) {
      let isValid = this.inviteValidation(userInviteList);
      // console.log(isValid, dataForm, dataForm.valid);
      if (!isValid) {
        dataForm.controls[field].status = "INVALID";
        // dataForm.valid = false;
        dataForm.submitted = true;
        // dataForm.setErrors({ invalid: true });
        dataForm.form.controls[field].setErrors({ incorrect: true });
        // console.log(isValid, dataForm, dataForm.valid);
      } else {
      }
    }
  }
  inviteValidation(userInviteList) {
    // console.log(userInviteList);
    this.viaEmailData = userInviteList.split(",");
    for (var i = 0; i < this.viaEmailData.length; i++)
      if (!this.validateEmail(this.viaEmailData[i])) return false;
    return true;
  }

  validateEmail(field) {
    var regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i;
    return regex.test(field) ? true : false;
  }
  /** CallBack Fucntion
   -- @params result --
  **/

  postStatus(result, navData) {
    if (!result.value.hasError) {
      if (navData == "save") {
        this.router.navigate(["companies/campaign/" + this.companyId]);
      } else {
        let breadcrumb = {
          pathName: "Assign Users",
          pathUrl: this.router.url,
        };
        this.breadCrumsArray.push(breadcrumb);
        this.router.navigate([
          "companies/campaign/" +
            this.campaignId +
            "/schedule/" +
            this.companyId,
        ]);
        this.router.navigate([
          "companies/campaign/" +
            this.companyId +
            "/schedule/" +
            this.campaignId,
        ]);
      }
      // this.issuccessPopup = true;
      // this.success_popup ={
      //    display: true,
      //    message: result.value.message,
      //    buttonPositive: 'Okay',
      //    positiveButton:()=>{
      //      setTimeout (() => {
      //        this.issuccessPopup = false;

      //    }, 1000);
      //    }
      //  }
    } else {
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  selectUser() {
    this.userSelected = [];
    this.groupSelected = [];
    this.userGroupSelected.map((f,i)=>{
      if(f.listKey){
        this[f.listKey].push(f);
      }
    })
    console.log(this.userGroupSelected,this.userSelected,this.groupSelected);
    this.isselectUser = true;
    this.select_user_popup = {
      display: true,
      //value: this.userSelected ? this.userSelected : [],
      title: "Select Users",
      tabFields: [
        {
          label: "User List",
          api: "Users",
          key: "user",
          keyId: "transactionId",
          keyName: "userFullName",
          keyMail: "userEmail",
          queryId: "companyId",
          queryData: this.companyId,
          list: [],
          listKey: 'userSelected',
          value: this.userSelected ? this.userSelected : [],
        },
        {
          label: "Groups",
          api: "Groups",
          key: "group",
          keyId: "groupId",
          keyMembers: "totalParticipants",
          keyName: "groupName",
          queryId: "companyId",
          queryData: this.companyId,
          list: [],
          listKey: 'groupSelected',
          value: this.groupSelected ? this.groupSelected : [],
        },
      ],
      closeModal: () => {
        setTimeout(() => {
          this.isselectUser = false;
        }, 1000);
      },
      createrCall: (tabs) => {
        this.userSelected = [];
        this.groupSelected = [];
        if(tabs){
          tabs.map((f,h)=>{
            if(f.value){
              f.value.map((x,y)=>{
                if(this[f.listKey].length){
                  let c=0;
                  this[f.listKey].map((i,j)=>{
                    if(i.id == x.id){
                      c=1;
                    }
                  })
                  if(!c){
                    this[f.listKey].push({
                      id: x.id,
                      mail: x.mail,
                      name: x.name,
                      listKey: f.listKey,
                    });
                  }
                }else{
                  this[f.listKey].push({
                    id: x.id,
                    mail: x.mail,
                    name: x.name,
                    listKey: f.listKey,
                  });
                }
              })
            }
          });
        }
        
        this.userGroupSelected = [...this.userSelected,...this.groupSelected];
        this.userGroupFilterSelected = this.userGroupSelected.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.id === item.id && t.name === item.name
          ))
        )
        
        setTimeout(() => {
          this.isselectUser = false;
        }, 1000);
      },
    };
  }

  removeFiles() {
    this.files = "";
  }

  loadFile(event) {
    let maxFileSize = 1024 * 1024 * 10;
    if (event.target.files[0].size > maxFileSize) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Maximum file size supported is 10 MB",
      };
    } else if (
      event.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.target.files[0].type != "application/vnd.ms-excel"
    ) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: "Invalid File Type" };
    } else {
      this.configsService.loading(true);
      let bulkFile = event.target.files[0];
      let url = this.bulkInfo.url + this.campaignId;
      this.commonService.fileUpload(url, bulkFile, true).subscribe(
        (data) => {
          this.bulkUploadResult(data);
          this.getEditData(this.campaignId);
        },
        (err) => {
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err[0] };
          this.configsService.loading(false);
        },
        () => {
          this.configsService.loading(false);
        }
      );
    }
  }

  bulkUploadResult(result) {
    if (!result.value.hasError) {
      if (!result.value.result) {
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: result.value.message };
      } else {
        this.files = "";
        this.isreportPopup = true;
        this.report_popup.display = true;
        this.report_popup["count_list"] = [];
        let errorData = [];
        for (var key in result.value.result) {
          this.report_popup["count_list"].push({
            label: key,
            value: result.value.result[key],
          });
          if (key == "errorData") {
            errorData = result.value.result[key];
          }
        }
        if (errorData) {
          let data = [];
          errorData.map((f, i) => {
            if (f) {
              data.push({ name: f.split(",")[0], message: f.split(",")[1] });
            }
          });
          this.report_popup["errorData"] = data;
        }
      }
    } else {
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: result.value.message };
    }
  }

  ngOnDestroy() {
    // this.route.params.unsubscribe();
  }
}
