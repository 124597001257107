import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { SkillTags, SkillType } from '../common/common.model';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { from } from 'rxjs';
import { appSettings } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { MentorService } from 'src/app/services/mentor.service';
import { CompanyService } from "src/app/services/company.service";
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { FeaturedMentorAnswerService } from "../../services/featured-mentor-answer.service";
import { FileUploadService } from "../../services/file-upload.service";
import { F } from "@angular/cdk/keycodes";
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-mentors',
  templateUrl: './mentors.component.html',
  styleUrls: ['./mentors.component.less']
})
export class MentorsComponent implements OnInit {

  countryOptions = [];
  industryOptions = [];
  SpecializationOptions = [];
  skillOptions = [];
  availabilityOptions = [];
  mentorTypeOptions = [];
  partnersOptions = [];

  nftCategoriesOptions = [];

  featuredMentorAnswers = [];

  pageUrl: string = "mentors";
  page_title = "Mentors";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  //textAreaWithRowsValue = 5;
  isEdit: boolean;
  error_popup = {};
  currentSection = 1;
  url = "";
  assignMentorFlag: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  mentorGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  answerDisplay: boolean = false;
  status = "";
  dataMentor = "";
  lessons = [];
  skillLevels = [];
  levels = {
    label: "",
    key: "level",
    placeholder: "Select Level",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: null,
    options: [],
    apiUrl: "",
  }
  lessonType = {
    label: "",
    key: "lessonType",
    placeholder: "Select",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: { name: '', value: 0 },
    options: [{ name: "Lesson", value: 1 }, { name: "Challenge", value: 2 }],
    apiUrl: "",
  }
  lessonCategories = {
    label: "",
    key: "LessonCategory",
    placeholder: "Lesson Category",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }
  challengeCategories = {
    label: "",
    key: "ChallengeCategory",
    placeholder: "Challenge Category",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }
  lesson = {
    label: "",
    key: "lesson",
    placeholder: "Lesson",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  filterArray: any = [
    {
      label: "Nft Mentor",
      type: "static_select_nft_mentor",
      key: "nftMentor",
      options: [
        { name: "True", value: "True" },
        { name: "False", value: "False" },
      ],
    },
    {
      label: "Data Mentor",
      type: "static_select_data_mentor",
      key: "dataMentor",
      options: [
        { name: "True", value: "True" },
        { name: "False", value: "False" },
      ],
    }
  ];
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link'],
      ],
    }
  }
  sectionAnswer = "";
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    cssClass: '',
    createrName: '',
    buttonPositive: "",
    field: [],
    closeModal: function () {
    },
    createrCall: function (form, skillData, uploadFileName, blogArticles, nftMentor) {
    },
  };

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  getCountryList() {
    this.mentorService.getCountryList().subscribe((countries) => {
      countries.result.forEach(country => {
        this.countryOptions.push({
          value: country.id,
          name: country.name
        });
      });
    }, (err) => {

    });
  }

  getIndustriesList() {
    this.mentorService.getIndustriesList().subscribe((industries) => {
      industries.result.forEach(industry => {
        this.industryOptions.push({
          value: industry.industryId,
          name: industry.title
        });
      });
    }, (err) => {

    });
  }

  getSpecializationsList() {
    this.mentorService.getSpecializationsList().subscribe((Specializations) => {
      Specializations.result.forEach(Specialization => {
        this.SpecializationOptions.push({
          value: Specialization.id,
          name: Specialization.title
        });
      });
    }, (err) => {

    });
  }

  getSkillsList() {
    this.mentorService.getSkillsList().subscribe((skills) => {
      skills.result.forEach(skill => {
        this.skillOptions.push({
          value: skill.skillId,
          name: skill.title
        });
      });
    }, (err) => {

    });
  }

  getMentorTypes() {
    this.mentorService.getMentorTypes().subscribe((types) => {
      types.result.forEach(country => {
        this.mentorTypeOptions.push({
          value: country.id,
          name: country.type
        });
      });
    }, (err) => {

    });
  }

  getMentorAvailability() {
    this.mentorService.getMentorAvailability().subscribe((availability) => {
      availability.result.forEach(country => {
        this.availabilityOptions.push({
          value: country.id,
          name: country.availability
        });
      });
    }, (err) => {

    });
  }

  getNftMentorCategories() {
    this.mentorService.getNftMentorCategories().subscribe((categories) => {
      categories.result.forEach(category => {
        this.nftCategoriesOptions.push({
          value: category.id,
          name: category.name,
          icon: category.icon
        });
      });
    }, (err) => {

    });
  }

  getPartnersList() {
    this.companyService.getCompanyDataGrid("", 1, 1000, "").subscribe((companies) => {
      companies.result.list.forEach(company => {
        this.partnersOptions.push({
          value: company.companyId,
          name: company.companyName
        });
      });
    }, (err) => {
    });
  }

  imageStorageUrl: string = appSettings.imageStoreUrl;
  currentTime: Date;
  skillTypeOptions = [];
  skillTagsOptions = [];
  userId: any;
  userName: any;
  userImage: any;
  mentors: any;
  selectedCategory: any;
  candidateSectionAnswers: any;
  candidateAnswers: any;
  seletedElementAnswer: any;

  constructor(
    private lessonsService: LessonsService,
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    public usersService: UsersService,
    private mentorService: MentorService,
    private featuredMentorAnswerService: FeaturedMentorAnswerService,
    private fileuploadService: FileUploadService,
    private companyService: CompanyService
  ) {

  }

  ngOnInit() {
    this.usersService.isMentor = true;
    this.getMentorList();
    this.getCountryList();
    this.getIndustriesList();
    this.getSpecializationsList();
    this.getSkillsList();
    this.getMentorTypes();
    this.getMentorAvailability();
    this.getNftMentorCategories();
    this.getPartnersList();
  }

  mentorColumnData: GleacColumnDef[] = [
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Mentor_Add'
        }
      ],
    },
    {
      displayName: "Mentor Name",
      data: "name",
      isExpandable: true,
      render: (data, row) => {
        return (` <div> <img src="${row.image ? row.image : "https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png"}" width = 30px; height =30px; style="border-radius: 50%;margin-right: 5px "/> <span style="color:#0065AD">${row.name ? row.name : '-'}</span> </div>`
        );
      },
    },
    {
      displayName: "Country",
      data: "country",
      render: (data, row) => {
        return `
        <span >${row.country ? row.country : '-'}</span>`;
      },
    },
    {
      displayName: "Gender",
      data: "gender",
      render: (data, row) => {
        let gender = '-'
        switch (row.gender) {
          case 'M':
            gender = 'Male';
            break;
          case 'F':
            gender = 'Female';
            break;
          case 'O':
            gender = 'Other';
            break;
        }
        return `
        <span >${gender}</span>`;
      },
    },
    {
      displayName: "LinkedIn",
      data: "linkedIn",
      render: (data, row) => {
        return `
        <span >${row.linkedIn ? row.linkedIn : '-'}</span>`;
      },
    },
    {
      displayName: "LinkedIn Followers",
      data: "linkedInFollowers",
      render: (data, row) => {
        return `
        <span >${row.linkedInFollowers ? row.linkedInFollowers : '-'}</span>`;
      },
    },
    {
      displayName: "Answers",
      data: "answers",
      isClickable: true,
      actionName: "answers",
      render: (data, row) => {
        return `<span   style="color:#0065AD">${row.lessonsCount}</span>`
      }
    },
    {
      displayName: "Email",
      data: "email",
      render: (data, row) => {
        return `
        <span >${row.email ? row.email : '-'}</span>`;
      },
    },
    {
      displayName: "Alternative Emails",
      data: "alternativeEmails",
      render: (data, row) => {
        return `
        <span >${row.alternativeEmails ? row.alternativeEmails : '-'}</span>`;
      },
    },
    {
      displayName: "WhatsApp Number",
      data: "whatsAppNumber",
      render: (data, row) => {
        return `
        <span >${row.whatsAppNumber ? row.whatsAppNumber : '-'}</span>`;
      },
    },
    {
      displayName: "Gleac Industries",
      data: "industries",
      render: (data, row) => {
        return `
        <span >${row.industries ? row.industries : '-'}</span>`;
      },
    },
    {
      displayName: "Personal Expertise",
      data: "expertise",
      render: (data, row) => {
        return `
        <span >${row.expertise ? row.expertise : '-'}</span>`;
      },
    },
    {
      displayName: "Company",
      data: "company",
      render: (data, row) => {
        return `
        <span >${row.company ? row.company : '-'}</span>`;
      },
    },
    {
      displayName: "Job Title",
      data: "designation",
      render: (data, row) => {
        return `
        <span >${row.designation ? row.designation : '-'}</span>`;
      },
    },
    {
      displayName: "Job Level",
      data: "jobLevel",
      render: (data, row) => {
        return `
        <span >${row.jobLevel ? row.jobLevel : '-'}</span>`;
      },
    },
    {
      displayName: "WhatsApp Groups",
      data: "whatsAppGroups",
      render: (data, row) => {
        return `
        <span >${row.whatsAppGroups ? row.whatsAppGroups : '-'}</span>`;
      },
    },
    {
      displayName: "Lessons",
      data: "lessonsCount",
      render: (data, row) => {
        return `
        <span >${row.lessonsCount ? row.lessonsCount : 0}</span>`;
      },
    },
    {
      displayName: "Website Bookings",
      data: "websiteBookings",
      render: (data, row) => {
        return `
        <span >${row.websiteBookings ? row.websiteBookings : 0}</span>`;
      },
    },
    {
      displayName: "Other Bookings",
      data: "otherBookings",
      render: (data, row) => {
        return `
        <span >${row.otherBookings ? row.otherBookings : 0}</span>`;
      },
    },
    {
      displayName: "Signed T&C",
      data: "signedTnC",
      render: (data, row) => {
        return `
        <span >${row.signedTnC ? row.signedTnC : 'false'}</span>`;
      },
    },
    {
      displayName: "Joined Gleac",
      data: "createdAt",
      render: (data, row) => {
        return `
        <span >${row.createdAt ? row.createdAt.split('T')[0] : '-'}</span>`;
      },
    },
    {
      displayName: "Became a Mentor",
      data: "mentorCreatedAt",
      render: (data, row) => {
        return `
        <span >${row.mentorCreatedAt ? row.mentorCreatedAt.split('T')[0] : '-'}</span>`;
      },
    },
    {
      displayName: "Total Tokens",
      data: "totalTokens",
      render: (data, row) => {
        return `<span >${(row.tokensBookings ? row.tokensBookings : 0) + (row.tokensEngagement ? row.tokensEngagement : 0) + (row.tokensEvents ? row.tokensEvents : 0) + (row.tokensNft ? row.tokensNft : 0) + (row.tokensOnboarding ? row.tokensOnboarding : 0) + (row.tokensReferrals ? row.tokensReferrals : 0)}</span>`;
      },
    }
  ];

  reArrangeMentorData(mentor) {
    let mentors = mentor.map((item, key) => {
      return {
        mentorName: item.user.firstName + " " + item.user.lastName,
        designation: item.user.designation,
        bio: item.user.bio,
        likes: item.likesCount,
        answers: item.lessonsCount,
        challenges: item.challengesCount,
        date: item.user.createdOn,
        userId: item.user.userId,
        mentorImage: item.user.profileImage ? item.user.profileImage : item.user.image,
        headline: item.user.headline,
        firstName: item.user.firstName,
        lastName: item.user.lastName
      }
    })
    return mentors;
  }

  getLessonSkill(userId) {
    this.configsService.loading(true);
    this.mentorService.userSkill(userId).subscribe((response) => {
      this.configsService.loading(false);
      if (response.statusCode === 200) {
        let context = this;

        this.lessonCategories.options = [];
        this.levels.options = [];
        this.levels.value = null;
        this.lessons = [];

        response.result.forEach(function (item) {
          context.lessonCategories.options.push({ name: item.skillName, value: item.skillId });
          item.lessons.forEach(function (lesson) {
            context.skillLevels.push({ skillId: item.skillId, level: item.level });
            context.lessons.push({
              lessonId: lesson.lessonId,
              title: lesson.title,
              skillId: lesson.skillId,
              level: lesson.level
            });
          });
        });

      } else if (response.statusCode === 500) {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: "Something went wrong" };
      }
    }, (err) => {
      this.configsService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    },
      () => {
        this.configsService.loading(false);
      })
  }

  changeLessonType(event) {
    this.selectedCategory = event.value;
    this.candidateAnswers = {};
    this.candidateAnswers.candidateSectionAnswers = [];
    this.lessonCategories = {
      label: "",
      key: "LessonCategory",
      placeholder: "Lesson Category",
      type: "dropdown",
      bindLabel: "name",
      required: "required",
      multiple: false,
      value: [],
      options: [],
      apiUrl: "",
    }
    this.challengeCategories = {
      label: "",
      key: "ChallengeCategory",
      placeholder: "Challenge Category",
      type: "dropdown",
      bindLabel: "name",
      required: "required",
      multiple: false,
      value: [],
      options: [],
      apiUrl: "",
    }
    this.lesson.value = [];
    if (this.selectedCategory === 1) {
      this.getLessonSkill(this.userId);
    }
    if (this.selectedCategory === 2) {
      this.getChallengeSkill(this.userId);
    }
  }

  getChallengeSkill(userId) {
    this.configsService.loading(true);
    this.mentorService.userChallengeSkill(userId).subscribe((response) => {
      this.configsService.loading(false);
      if (response.statusCode === 200) {
        this.challengeCategories.options = response.result.map((item) => {
          return {
            name: item.skillName,
            value: item.skillLessonDetails.lessonId
          }
        });
      } else if (response.statusCode === 500) {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: "Something went wrong" };
      }
    }, (err) => {
      this.configsService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    },
      () => {
        this.configsService.loading(false);
      })
  }

  getLessonDetailBySkill() {
    let lessonCategory = JSON.parse(JSON.stringify(this.lessonCategories.value));
    let context = this;
    this.lesson.options = [];
    this.lesson.value = null;
    this.candidateAnswers.candidateSectionAnswers = [];
    this.currentSection = 1;

    this.lessons.forEach(function (item) {
      if (item.skillId === lessonCategory.value && item.level === context.levels.value.value) {
        context.lesson.options.push({ name: item.title, value: item.lessonId });
      }
    });
  }

  editAnswer(sectionElementIndex) {
    this.isEdit = true;
    this.seletedElementAnswer = sectionElementIndex;
    let candidateSections = this.candidateAnswers.candidateSectionAnswers;
    this.sectionAnswer = candidateSections[this.currentSection - 1].sectionElementAnswers[sectionElementIndex].answer;
  }

  saveAnswer(sectionElementIndex) {
    this.isEdit = false;
    this.seletedElementAnswer = null;
    let candidateSections = this.candidateAnswers.candidateSectionAnswers;
    let lessonValue = JSON.parse(JSON.stringify(this.lesson.value));
    let updateAnswer = {
      candidateId: this.userId,
      lessonId: this.selectedCategory === 2 ? lessonValue : lessonValue.value,
      sectionElementAnswer: {
        answer: candidateSections[this.currentSection - 1].sectionElementAnswers[sectionElementIndex].answer,
        sectionElementId: candidateSections[this.currentSection - 1].sectionElementAnswers[sectionElementIndex].sectionElementId
      }
    }
    this.configsService.loading(true);
    this.mentorService.updateAnswer(updateAnswer).subscribe((response) => {
      if (response.statusCode === 500) {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: "Something went wromg" };
      }
    }, (err) => {
      this.configsService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    },
      () => {
        this.configsService.loading(false);
      });
  }

  mentorAnswer(event) {
    if (this.selectedCategory === 2) {
      this.mentorChallengeAnswer(event.value);
    } else {
      this.levels.options = [];
      this.lesson.options = [];
      this.levels.value = null;
      this.lesson.value = null;
      this.candidateAnswers.candidateSectionAnswers = [];
      this.currentSection = 1;

      let context = this;
      let levelsNumbers = [];

      this.lessons.forEach(function (item) {
        if (item.skillId == event.value) {
          levelsNumbers.push(item.level);
        }
      });

      let uniqueLevels = [...new Set(levelsNumbers)]
      uniqueLevels = uniqueLevels.sort((n1, n2) => n1 - n2);
      uniqueLevels.forEach(function (item) {
        context.levels.options.push({ name: item, value: item });
      });
    }
  }

  mentorLessonAnswer() {
    if (this.lesson.value) {
      this.getAllFeaturedMentorAnswers();
      this.configsService.loading(true);
      let lessonValue = JSON.parse(JSON.stringify(this.lesson.value));
      this.mentorService.mentorLessonAnswer(this.userId, lessonValue.value).subscribe((response) => {
        if (response.statusCode === 200) {
          this.candidateAnswers = response.result;
        } else if (response.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: "Something went wrong" };
        }
      },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        });
    }
  }

  mentorChallengeAnswer(value) {
    if (value) {
      this.lesson.value = value;
      this.configsService.loading(true);
      let challengeId = JSON.parse(JSON.stringify(value));
      this.mentorService.mentorChallengeAnswer(this.userId, challengeId).subscribe((response) => {
        if (response.statusCode === 200) {
          this.candidateAnswers = response.result;
        } else if (response.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: "Something went wrong" };
        }
      },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        });
    }
  }

  nextPrevButton(value) {
    this.isEdit = false;
    if (value === false) {
      this.currentSection = this.currentSection === 1 ? 1 : --this.currentSection;
    }
    if (value === true) {
      this.currentSection = this.currentSection === this.candidateAnswers.candidateSectionAnswers.length ? this.candidateAnswers.candidateSectionAnswers.length : ++this.currentSection;
    }
  }

  getMentorList() {
    this.configsService.loading(true);
    this.mentorService.getAllMentorv2(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.status,
      this.dataMentor
    ).subscribe(
      (res) => {
        if (res) {
          if (res) {
            //let mentors = this.reArrangeMentorData(res.result.data);
            let mentorData = {
              list: res.result.data,
              totalCount: res.result.totalCount
            }
            this.mentorGridData = mentorData;
            this.mentors = res.result.data;
          } else {
            this.mentorGridData = [];

          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }


  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getMentorList();
  }

  customFilter(params) {
    this.pageNumber = 1;
    if (params[1] && params[1].form && params[1].form.value && params[1].form.value.status_nft_mentor != undefined) {
      this.status = params[1].form.value.status_nft_mentor;
    } else {
      this.status = '';
    }

    if (params[1] && params[1].form && params[1].form.value && params[1].form.value.status_data_mentor != undefined) {
      this.dataMentor = params[1].form.value.status_data_mentor;
    } else {
      this.dataMentor = '';
    }

    this.getMentorList();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getMentorList();
  }

  actionClick(event) {
    if (event.actionName === "answers") {
      this.userId = event.data.userId;
      this.userName = event.data.name;
      this.answerDisplay = true;
      this.selectedCategory = "";
      this.lessonType = {
        label: "",
        key: "lessonType",
        placeholder: "Select",
        type: "dropdown",
        bindLabel: "name",
        required: "required",
        multiple: false,
        value: { name: '', value: 0 },
        options: [{ name: "Lesson", value: 1 }, { name: "Challenge", value: 2 }],
        apiUrl: "",
      }

    } else if (event.action === "edit") {
      this.configsService.loading(true);
      this.mentorService.getMentorDetails(event.data.mentorId).subscribe(data => {
        let mentorDetails = data.result;
        this.SpecializationOptions.forEach(item => {
          item.isChecked = false;
        });
        this.industryOptions.forEach(item => {
          item.isChecked = false;
        });

        let industryDetails = [];
        mentorDetails.industries.forEach(x => {

          industryDetails.push({ isChecked: true, name: x.title.trim(), value: x.industryId });

          this.industryOptions.forEach(item => {
            if (item.value === x.industryId) {
              item.isChecked = true;
            }
          });
        });

        let specializationDetails = [];
        mentorDetails.specializations.forEach(x => {

          specializationDetails.push({ isChecked: true, name: x.title.trim(), value: x.id });

          this.SpecializationOptions.forEach(item => {
            if (item.value === x.id) {
              item.isChecked = true;
            }
          });
        });

        let skillDetails = this.skillOptions.filter(x => {
          return x.value == mentorDetails.skillId;
        })

        let countryDetails = this.countryOptions.filter(x => {
          return x.value == mentorDetails.countryId;
        })

        // let industryDetails = this.industryOptions.filter(x => {
        //   return x.value == mentorDetails.industryId;
        // })

        // let specializationDetails = this.SpecializationOptions.filter(x => {
        //   return x.value == mentorDetails.specializationId;
        // })

        let mentorType = this.mentorTypeOptions.filter(x => {
          return x.value == mentorDetails.mentorTypeId;
        })

        let mentorAvailability = this.availabilityOptions.filter(x => {
          return x.value == mentorDetails.mentorAvailabilityId;
        });

        let featuredAnswerSkillList = [];
        let media = [];

        mentorDetails.media.forEach(item => {
          media.push({ value: item });
        });

        if (mentorDetails.blogArticles.length === 0) {
          mentorDetails.blogArticles = [{}];
        }

        mentorDetails.mentorAnswers.forEach(item => {
          let isExits = featuredAnswerSkillList.filter(x => {
            return x.skillId == item.skillId
          });

          if (isExits.length == 0) {
            featuredAnswerSkillList.push({ skillId: item.skillId, skillName: item.skillName });
          }

        });

        let genders = [
          {
            name: 'Male',
            value: 'M'
          },
          {
            name: 'Female',
            value: 'F'
          },
          {
            name: 'Other',
            value: 'O'
          }
        ],
          categories = [
            {
              name: 'Expert',
              value: 'E'
            },
            {
              name: 'Mentor',
              value: 'M'
            },
            {
              name: 'Coach',
              value: 'C'
            }
          ],
          jobLevels = [
            {
              name: 'C-level',
              value: 'C-level'
            },
            {
              name: 'Consultancy',
              value: 'Consultancy'
            },
            {
              name: 'Employee',
              value: 'Employee'
            },
            {
              name: 'Managerial',
              value: 'Managerial'
            },
            {
              name: 'Student',
              value: 'Student'
            },
            {
              name: 'Upper management',
              value: 'Upper management'
            },
            {
              name: 'NA',
              value: 'NA'
            }
          ];

        let companyIndustryOptions = this.industryOptions.map(x => ({ name: x.name, value: x.name, isChecked: false }));
        let companyIndustries;
        try {
          let companyIndustriesArray = JSON.parse(mentorDetails.companyIndustries);
          companyIndustries = companyIndustriesArray.map(x => ({ name: x, value: x }));

          companyIndustryOptions.forEach(x => {
            if (companyIndustriesArray.includes(x.value)) {
              x.isChecked = true;
            }
          });
        } catch (e) {
        }

        let partnerDetails = [];
        mentorDetails.coachForCompanyId.forEach(x => {

          partnerDetails.push({ isChecked: true, name: x.companyName.trim(), value: x.companyId });

          this.partnersOptions.forEach(item => {
            if (item.value === x.companyId) {
              item.isChecked = true;
            }
          });
        });

        this.iscreatePopup = true;
        this.create_popup = {
          display: true,
          bulk_upload: false,
          cssClass: 'col-15',
          createrName: "updateMentorData",
          title: "Update Mentor Data",
          buttonPositive: "Update",
          field: [
            {
              label: "First Name",
              key: "firstName",
              placeholder: "Enter the First name",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              required: "required",
              maxlength: 100,
              pattern: "^[a-zA-Z.]+(?:\\s+[a-zA-Z]+)*$",
              value: mentorDetails.firstname ? mentorDetails.firstname : '',
              tabGroup: 0
            },
            {
              label: "Last Name",
              key: "lastName",
              placeholder: "Enter the Last name",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              required: "required",
              maxlength: 100,
              pattern: "^[a-zA-Z.]+(?:\\s+[a-zA-Z]+)*$",
              value: mentorDetails.lastname ? mentorDetails.lastname : '',
              tabGroup: 0
            },
            {
              label: "Bio",
              key: "headline",
              placeholder: "Type Mentor Bio",
              type: "textarea",
              innertype: "text",
              required: "required",
              height: "100px",
              maxlength: 800,
              message: "Enter maximum of 800 characters",
              value: mentorDetails.headline ? mentorDetails.headline : "",
              tabGroup: 1
            },
            {
              label: "Human Skill",
              key: "humanSkillId",
              required: "required",
              placeholder: "Select a human skill",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              value: skillDetails.length > 0 ? skillDetails[0] : null,
              options: this.skillOptions,
              apiUrl: "HumanSkill",
              tabGroup: 1
            },
            {
              label: "Country",
              key: "countryId",
              required: "required",
              placeholder: "Select a country",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              value: countryDetails.length > 0 ? countryDetails[0] : null,
              options: this.countryOptions,
              apiUrl: "Country",
              tabGroup: 0
            },
            {
              label: "Gender",
              key: "gender",
              placeholder: "Select gender",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              value: genders.filter(x => x.value === mentorDetails.gender)[0],
              options: genders,
              apiUrl: "Gender",
              tabGroup: 0
            },
            {
              label: "Email",
              key: "email",
              placeholder: "Enter the email",
              type: "input",
              cssClass: 'col-6',
              innertype: "email",
              required: "required",
              maxlength: 100,
              value: mentorDetails.email ? mentorDetails.email : "",
              tabGroup: 0
            },
            {
              label: "Alternative Emails",
              key: "alternativeEmails",
              placeholder: "Enter alternative emails",
              type: "textarea",
              innertype: "text",
              height: "100px",
              maxlength: 800,
              message: "Enter maximum of 800 characters",
              value: mentorDetails.alternativeEmails ? mentorDetails.alternativeEmails : "",
              tabGroup: 0
            },
            {
              label: "WhatsApp Number (without + symbol)",
              key: "whatsAppNumber",
              placeholder: "Enter the WhatsApp Number (without + symbol)",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              maxlength: 50,
              value: mentorDetails.whatsAppNumber ? mentorDetails.whatsAppNumber : "",
              tabGroup: 0
            },
            {
              label: "Gleac Industries",
              key: "industryId",
              placeholder: "Select an industry",
              type: "dropdown",
              required: "required",
              bindLabel: "name",
              multiple: true,
              value: industryDetails.length > 0 ? industryDetails : null,
              options: this.industryOptions,
              apiUrl: "Industry",
              tabGroup: 1
            },
            {
              label: "Personal Expertise",
              key: "expertise",
              placeholder: "Enter the expertise",
              type: "input",
              innertype: "text",
              maxlength: 200,
              value: mentorDetails.expertise ? mentorDetails.expertise : "",
              tabGroup: 1
            },
            {
              label: "Specialization",
              key: "specializationId",
              placeholder: "Select a specialization",
              type: "dropdown",
              bindLabel: "name",
              multiple: true,
              required: "required",
              value: specializationDetails.length > 0 ? specializationDetails : null,
              options: this.SpecializationOptions,
              apiUrl: "Specialization",
              tabGroup: 1
            },
            {
              label: "Availability",
              key: "AvailabilityId",
              placeholder: "Select an Availability",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              required: "required",
              value: mentorAvailability.length > 0 ? mentorAvailability[0] : null,
              options: this.availabilityOptions,
              apiUrl: "Industry",
              tabGroup: 0
            },
            {
              label: "Type",
              key: "mentorTypeId",
              placeholder: "Select an Mentor Type",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              required: "required",
              value: mentorType.length > 0 ? mentorType[0] : null,
              options: this.mentorTypeOptions,
              apiUrl: "Specialization",
              tabGroup: 0
            },
            {
              label: "Linkedin Url",
              key: "linkedin",
              placeholder: "Enter the Linkedin Url",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              required: "required",
              maxlength: 500,
              value: mentorDetails.linkedIn ? mentorDetails.linkedIn : "",
              tabGroup: 0
            },
            {
              label: "Linkedin Followers",
              key: "linkedInFollowers",
              placeholder: "Number of Linkedin Followers",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.linkedInFollowers ? mentorDetails.linkedInFollowers : null,
              tabGroup: 0
            },
            {
              label: "Website Url",
              key: "Website",
              placeholder: "Enter the Website Url",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              maxlength: 200,
              value: mentorDetails.website ? mentorDetails.website : "",
              tabGroup: 0
            },
            {
              label: "Company",
              key: "Company",
              placeholder: "Enter the Company",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              maxlength: 100,
              value: mentorDetails.company ? mentorDetails.company : "",
              tabGroup: 1
            },
            {
              label: "Job Title",
              key: "designation",
              placeholder: "Enter the Designation",
              type: "input",
              innertype: "text",
              required: "required",
              maxlength: 500,
              message: "Enter maximum of 500 characters",
              value: mentorDetails.designation ? mentorDetails.designation : "",
              tabGroup: 1
            },
            {
              label: "Company Industry",
              key: "companyIndustries",
              placeholder: "Select company industries",
              type: "dropdown",
              bindLabel: "name",
              multiple: true,
              value: companyIndustries ? companyIndustries : null,
              options: companyIndustryOptions,
              apiUrl: "companyIndustries",
              tabGroup: 1
            },
            {
              label: "Detailed Company Industry",
              key: "companyIndustryLabel",
              placeholder: "Enter the Detailed Company Industry",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              maxlength: 200,
              value: mentorDetails.companyIndustryLabel ? mentorDetails.companyIndustryLabel : "",
              tabGroup: 1
            },
            {
              label: "Job Level Bucket",
              key: "jobLevel",
              placeholder: "Select Job Level Bucket",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              value: jobLevels.filter(x => x.value === mentorDetails.jobLevel)[0],
              options: jobLevels,
              apiUrl: "JobLevel",
              tabGroup: 1
            },
            {
              label: "Twitter Url",
              key: "Twitter",
              placeholder: "Enter the Twitter Url",
              type: "input",
              cssClass: 'col-6',
              innertype: "text",
              maxlength: 200,
              value: mentorDetails.twitter ? mentorDetails.twitter : "",
              tabGroup: 0
            },
            // {
            //   label: "Is Featured",
            //   key: "isFeatured",
            //   type: "switchButton",
            //   cssClass: 'col-6',
            //   value: mentorDetails.isFeatured,
            // },
            {
              label: "Image",
              key: "imageFile",
              placeholder:
                "Maximum file size 2mb, and type of file must be PNG or JPG. Minimum resolution is 500 X 500.",
              type: "image_upload",
              cssClass: 'col-3',
              required: "required",
              value: mentorDetails.image ? mentorDetails.image : '',
              tabGroup: 0
            },
            {
              label: "On Request",
              key: "onRequest",
              type: "switchButton",
              cssClass: 'col-12',
              value: mentorDetails.onRequest ? true : false,
              tabGroup: 0
            },
            {
              label: "Office Start",
              key: "startDate",
              type: "time_picker_start",
              cssClass: 'col-6',
              required: "required",
              value: mentorDetails.officeStart ? {
                hour: Number(mentorDetails.officeStart.split(':')[0]),
                minute: Number(mentorDetails.officeStart.split(':')[1])
              } : { hour: 10, minute: 0 },
              tabGroup: 0
            },
            {
              label: "Office end",
              key: "endDate",
              type: "time_picker_end",
              required: "required",
              cssClass: 'col-6',
              value: mentorDetails.officeEnd ? {
                hour: Number(mentorDetails.officeEnd.split(':')[0]),
                minute: Number(mentorDetails.officeEnd.split(':')[1])
              } : { hour: 18, minute: 0 },
              tabGroup: 0
            },
            {
              label: "Data Mentor",
              key: "isDataMentor",
              type: "switchButton",
              cssClass: 'col-6',
              value: mentorDetails.isDataMentor ? true : false,
              tabGroup: 0
            },
            {
              label: "Equity Mentor",
              key: "isEquityMentor",
              type: "switchButton",
              cssClass: 'col-6',
              value: mentorDetails.isEquityMentor,
              tabGroup: 0
            },
            {
              label: "Spotlight Mentor",
              key: "isSpotlightMentor",
              type: "switchButton",
              cssClass: 'col-6',
              value: mentorDetails.isSpotlightMentor,
              tabGroup: 0
            },
            {
              label: "Category",
              key: "category",
              placeholder: "Select category",
              required: "required",
              type: "dropdown",
              bindLabel: "name",
              multiple: false,
              value: categories.filter(x => x.value === mentorDetails.category)[0],
              options: categories,
              apiUrl: "Category",
              tabGroup: 0
            },
            {
              label: "NFT Mentor",
              key: "nftMentorJSON",
              cssClass: 'col-12',
              type: "nft_mentor",
              value: mentorDetails && mentorDetails.nftMentor ? mentorDetails.nftMentor : null,
              tabGroup: 4
            },
            {
              label: "Blog Articles",
              key: "blogArticlesJSON",
              cssClass: 'col-12',
              type: "blog_articles",
              value: mentorDetails && mentorDetails.blogArticles ? mentorDetails.blogArticles : null,
              tabGroup: 3
            },
            {
              label: "Talk Shows",
              key: "MediaJSON",
              placeholder: "Enter multiple media links with comma separated values",
              type: "media_list",
              innertype: "text",
              cssClass: 'custom',
              required: "required",
              height: "100px",
              maxlength: 1000,
              message: "Enter maximum of 1000 characters",
              value: mentorDetails.media ? (mentorDetails.media.length == 0 ? [{ value: '' }] : media) : [{ value: '' }],
              tabGroup: 3
            },
            {
              label: "Featured Answers",
              key: "featuredAnswers",
              type: "featured_answers",
              required: "required",
              cssClass: 'col-12',
              value: featuredAnswerSkillList,
              featuredAnswers: mentorDetails.mentorAnswers,
              tabGroup: 3
            },
            {
              label: "WhatsApp Groups",
              key: "whatsAppGroups",
              placeholder: "WhatsApp Groups",
              type: "textarea",
              innertype: "text",
              height: "100px",
              maxlength: 800,
              message: "Enter maximum of 800 characters",
              value: mentorDetails.whatsAppGroups ? mentorDetails.whatsAppGroups : "",
              tabGroup: 2
            },
            {
              label: "Other Bookings",
              key: "otherBookings",
              placeholder: "Other Bookings",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.otherBookings ? mentorDetails.otherBookings : null,
              tabGroup: 2
            },
            {
              label: "Joining Reasons",
              key: "joiningReasons",
              placeholder: "3 Reasons for Joining the Community",
              type: "textarea",
              innertype: "text",
              height: "100px",
              maxlength: 800,
              message: "Enter maximum of 800 characters",
              value: mentorDetails.joiningReasons ? mentorDetails.joiningReasons : "",
              tabGroup: 2
            },
            {
              label: "Signed T&C",
              key: "signedTnC",
              type: "switchButton",
              cssClass: 'col-6',
              value: mentorDetails.signedTnC ? true : false,
              tabGroup: 2
            },
            {
              label: "Comments/Notes",
              key: "comments",
              placeholder: "Comments/Notes",
              type: "textarea",
              innertype: "text",
              height: "100px",
              maxlength: 800,
              message: "Enter maximum of 800 characters",
              value: mentorDetails.comments ? mentorDetails.comments : "",
              tabGroup: 2
            },
            {
              label: "Onboarding Hour Attended",
              key: "onboardingDateTime",
              placeholder: "Select Date",
              type: "date_picker_date",
              cssClass: 'col-6',
              value: mentorDetails.onboardingDateTime ? new Date(mentorDetails.onboardingDateTime) : "",
              tabGroup: 2
            },
            {
              label: "Onboarding Tokens",
              key: "tokensOnboarding",
              placeholder: "Onboarding Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensOnboarding ? mentorDetails.tokensOnboarding : null,
              tabGroup: 2
            },
            {
              label: "Engagement Tokens",
              key: "tokensEngagement",
              placeholder: "Engagement Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensEngagement ? mentorDetails.tokensEngagement : null,
              tabGroup: 2
            },
            {
              label: "Events Speaker & LinkedIn Q&A Tokens",
              key: "tokensEvents",
              placeholder: "Events Speaker & LinkedIn Q&A Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensEvents ? mentorDetails.tokensEvents : null,
              tabGroup: 2
            },
            {
              label: "Booking Tokens",
              key: "tokensBookings",
              placeholder: "Booking Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensBookings ? mentorDetails.tokensBookings : null,
              tabGroup: 2
            },
            {
              label: "Referral Tokens",
              key: "tokensReferrals",
              placeholder: "Referral Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensReferrals ? mentorDetails.tokensReferrals : null,
              tabGroup: 2
            },
            {
              label: "NFT Tokens",
              key: "tokensNft",
              placeholder: "NFT Tokens",
              type: "input",
              cssClass: 'col-6',
              innertype: "number",
              value: mentorDetails.tokensNft ? mentorDetails.tokensNft : null,
              tabGroup: 2
            },
            {
              label: "Available as a Coach For",
              key: "companyAvailableCoach",
              placeholder: "Select a Company",
              type: "dropdown",
              bindLabel: "name",
              multiple: true,
              value: partnerDetails.length > 0 ? partnerDetails : null,
              options: this.partnersOptions,
              apiUrl: "",
              tabGroup: 5
            }
          ],
          closeModal: () => {
            this.iscreatePopup = false;
          },
          createrCall: (form: any, skillData, uploadedFileName, blogArticles, nftMentor) => {
            let mentorData = Object.assign(form.value);
            let mentorPost: any = {};
            mentorPost.mentorId = event.data.mentorId;
            mentorPost.gleacUserID = event.data && event.data.userId ? event.data.userId : "";
            mentorPost.firstname = mentorData.firstName;
            mentorPost.lastname = mentorData.lastName;
            mentorPost.bio = mentorDetails.bio;
            mentorPost.industryIds = mentorData.industryId ? mentorData.industryId.map(x => x.value) : 0;
            mentorPost.headline = mentorData.headline;
            mentorPost.webDesignation = mentorDetails.webDesignation;
            mentorPost.linkedIn = mentorData.linkedin;
            mentorPost.mentorTypeId = mentorData.mentorTypeId ? mentorData.mentorTypeId.value : 0;
            mentorPost.countryId = mentorData.countryId ? mentorData.countryId.value : 0;
            mentorPost.skillId = mentorData.humanSkillId ? mentorData.humanSkillId.value : 0;
            mentorPost.designation = mentorData.designation;
            mentorPost.isFeatured = mentorData.isFeatured;
            mentorPost.onRequest = mentorData.onRequest;
            mentorPost.isDataMentor = mentorData.isDataMentor;
            mentorPost.isEquityMentor = mentorData.isEquityMentor;
            mentorPost.isSpotlightMentor = mentorData.isSpotlightMentor;
            mentorPost.officeStart = (mentorData.startTime.hour < 10 ? '0' + mentorData.startTime.hour : mentorData.startTime.hour) + ':' + (mentorData.startTime.minute < 10 ? '0' + mentorData.startTime.minute : mentorData.startTime.minute);
            mentorPost.officeEnd = (mentorData.endTime.hour < 10 ? '0' + mentorData.endTime.hour : mentorData.endTime.hour) + ':' + (mentorData.endTime.minute < 10 ? '0' + mentorData.endTime.minute : mentorData.endTime.minute);
            mentorPost.mentorAvailabilityId = mentorData.AvailabilityId ? mentorData.AvailabilityId.value : 0;
            mentorPost.specializationIds = mentorData.specializationId ? mentorData.specializationId.map(x => x.value) : 0;
            mentorPost.company = mentorData.Company;
            mentorPost.twitter = mentorData.Twitter;
            mentorPost.website = mentorData.Website;
            mentorPost.blogArticles = blogArticles[0].title ? blogArticles : null;
            mentorPost.nftMentor = nftMentor;
            mentorPost.media = skillData[1].filter(x => {
              return x.value.trim() !== "";
            });
            mentorPost.media = mentorPost.media.length != 0 ? mentorPost.media.map(x => x.value) : [];
            mentorPost.mentorAnswers = skillData[0];
            mentorPost.gender = mentorData.gender ? mentorData.gender.value : null;
            mentorPost.category = mentorData.category ? mentorData.category.value : 'M';
            mentorPost.linkedInFollowers = mentorData.linkedInFollowers ? mentorData.linkedInFollowers : null;
            mentorPost.email = mentorData.email;
            mentorPost.alternativeEmails = mentorData.alternativeEmails;
            mentorPost.whatsAppNumber = mentorData.whatsAppNumber;
            mentorPost.expertise = mentorData.expertise;
            mentorPost.companyIndustries = mentorData.companyIndustries ? JSON.stringify(mentorData.companyIndustries.map(x => x.value)) : null;
            mentorPost.companyIndustryLabel = mentorData.companyIndustryLabel;
            mentorPost.jobLevel = mentorData.jobLevel ? mentorData.jobLevel.value : null;
            mentorPost.whatsAppGroups = mentorData.whatsAppGroups;
            mentorPost.otherBookings = mentorData.otherBookings ? mentorData.otherBookings : null;
            mentorPost.signedTnC = mentorData.signedTnC;
            mentorPost.joiningReasons = mentorData.joiningReasons;
            let onboardingDateTime = mentorData.onboardingDateTime;
            if (onboardingDateTime) {
              onboardingDateTime.setHours(12);
            }
            mentorPost.onboardingDateTime = onboardingDateTime ? onboardingDateTime : null;
            mentorPost.comments = mentorData.comments;
            mentorPost.tokensOnboarding = mentorData.tokensOnboarding ? mentorData.tokensOnboarding : null;
            mentorPost.tokensEngagement = mentorData.tokensEngagement ? mentorData.tokensEngagement : null;
            mentorPost.tokensEvents = mentorData.tokensEvents ? mentorData.tokensEvents : null;
            mentorPost.tokensBookings = mentorData.tokensBookings ? mentorData.tokensBookings : null;
            mentorPost.tokensReferrals = mentorData.tokensReferrals ? mentorData.tokensReferrals : null;
            mentorPost.tokensNft = mentorData.tokensNft ? mentorData.tokensNft : null;
            let checkedCompanyIds = this.partnersOptions.filter(x => !!x.isChecked).map(x => x.value)
            mentorPost.coachForCompanyId = checkedCompanyIds.length > 0 ? checkedCompanyIds : [];

            if (form.valid) {
              if (event.data) {
                if (mentorData.imageFile && mentorData.imageFile.name) {
                  this.configsService.loading(true);
                  this.fileuploadService.uploadUserProfileImage(event.data.userId, mentorData.imageFile).subscribe(data => {
                    console.log(mentorData, "new data");
                    this.mentorService.updateMentorv2(mentorPost)
                      .subscribe(
                        (data) => {
                          data.result = "Updated Successfully."
                          this.postStatus(data);
                        },
                        (err) => {
                          this.configsService.loading(false);
                          this.iserrorPopup = true;
                          this.error_popup = {
                            display: true,
                            message: err.error.errors[0],
                          };
                        },
                        () => {
                          this.configsService.loading(false);
                          form.reset();
                          this.iscreatePopup = false;
                          this.pageNumber = 1;
                          this.getMentorList();
                        }
                      );
                  }, error => {
                    this.configsService.loading(false);
                    this.iserrorPopup = true;
                    this.error_popup = {
                      display: true,
                      message: error.error.errors[0],
                    };
                  }, () => {
                    this.configsService.loading(false);
                  });
                } else {
                  this.configsService.loading(true);
                  this.mentorService.updateMentorv2(mentorPost)
                    .subscribe(
                      (data) => {
                        data.result = "Updated Successfully."
                        this.postStatus(data);
                      },
                      (err) => {
                        this.configsService.loading(false);
                        this.iserrorPopup = true;
                        this.error_popup = {
                          display: true,
                          message: err.error.errors[0],
                        };
                      },
                      () => {
                        this.configsService.loading(false);
                        form.reset();
                        this.iscreatePopup = false;
                        this.pageNumber = 1;
                        this.getMentorList();
                      }
                    );
                }
              }
            }

          }
        }
      }, error => {
        this.configsService.loading(false);
      }, () => {
        this.configsService.loading(false);
      });
    } else if (event.action === "delete") {
      this.deleteMentor(event.data);
    } else if (event.actionName === "markFeatured") {
      this.configsService.loading(true);
      this.mentorService.setFeaturedMentor(event.data.mentorId, event.data.isFeatured).subscribe(data => {
        this.getMentorList();
      }, error => {
        this.configsService.loading(false);

      }, () => {
        this.configsService.loading(false);
      });
    }
  }

  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.result,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getMentorList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  exportToExcel(exportType: string) {
    this.configsService.loading(true);
    this.mentorService.exportAllMentor(
      encodeURIComponent(this.searchString),
      this.status,
      this.dataMentor,
      exportType
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "Mentors" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  mentorDetails(event) {
    // console.log(event);
    // let route = "lessons/" +
    //   event.data.lessonCategoryId +
    //   "/new";
    // if (event.data.skillType === 4) {
    //   route = "challenge/" +
    //     event.data.lessonCategoryId+"/new";
    // }
    // this.router.navigate([route]);
  }

  deleteMentor(data) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Lesson",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.lessonsService.deletelesson(data.lessonCategoryId).subscribe(
          (data) => {
            this.getMentorList();
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
        // this.configService.invokeDataPassing(this.list);
      },
    };
  }

  Details(event) {
    console.log("event", event);
    let route = "lessons/" +
      event.data.lessonCategoryId +
      "/new";
    if (event.data.skillType === 4) {
      route = "challenge/" +
        event.data.lessonCategoryId + "/new";
    }
    this.router.navigate([route]);
  }

  setSkillType() {
    for (var item in SkillType) {
      if (isNaN(Number(item))) {
        this.skillTypeOptions.push({
          value: SkillType[item],
          name: item
        });
      }
    }
  }

  setTags() {
    for (var item in SkillTags) {
      if (isNaN(Number(item))) {
        this.skillTagsOptions.push({
          value: SkillTags[item],
          name: SkillTags[item]
        });
      }
    }
  }

  addSkill(data) {


  }

  assignMentor() {
    this.assignMentorFlag = true;
  }

  mentorClose() {
    this.assignMentorFlag = false;
    this.pageNumber = 1;
    this.getMentorList();
  }

  closeModel(ent) {
    this.candidateAnswers = null;
    this.lessonCategories.value = [];
    this.lesson.value = [];
    this.answerDisplay = false;
  }

  getAllFeaturedMentorAnswers() {
    let request: any = {};

    let lessonValue = JSON.parse(JSON.stringify(this.lesson.value));
    let skillValue = JSON.parse(JSON.stringify(this.lessonCategories.value));

    request.userId = this.userId;
    request.skillId = lessonValue.value;
    request.lessonId = skillValue.value;

    this.configsService.loading(true);
    this.featuredMentorAnswerService.getAllFeaturedMentorAnswers(request).subscribe(data => {
      this.featuredMentorAnswers = data.result;
    }, error => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  setFeaturedMentorAnswer(section, isRemove) {
    let request: any = {};

    let lessonValue = JSON.parse(JSON.stringify(this.lesson.value));
    let sectionId = this.candidateAnswers.candidateSectionAnswers[section - 1].sectionId;
    let skillValue = JSON.parse(JSON.stringify(this.lessonCategories.value));

    request.userId = this.userId;
    request.skillId = skillValue.value;
    request.lessonId = lessonValue.value;
    request.sectionId = sectionId;
    request.remove = isRemove;

    this.configsService.loading(true);
    this.featuredMentorAnswerService.setFeaturedMentorAnswer(request).subscribe(data => {
      this.getAllFeaturedMentorAnswers();
    }, error => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  checkForFeaturedMentorAnswer(section) {
    let lessonValue = JSON.parse(JSON.stringify(this.lesson.value));

    let sectionId = this.candidateAnswers.candidateSectionAnswers[section - 1].sectionId;

    let isExists = this.featuredMentorAnswers.filter(item => {
      return item.lessonId == lessonValue.value && item.sectionId == sectionId
    });

    return isExists && isExists.length > 0;
  }
}

