import { Component, OnInit, Input, OnChanges, ViewChild, ViewChildren, QueryList, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { GleacColumnDef } from './data-grid-server-side-pagination.model';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ComponentLoaderService } from 'src/app/services/component-loader.service';
import { ExpandableRowDirective } from 'src/app/directives/expandable-row.directive';
import { BadgeComponent } from '../badge/badge.component';

@Component({
  selector: 'app-data-gird-server-side-pagination',
  templateUrl: './data-gird-server-side-pagination.component.html',
  styleUrls: ['./data-gird-server-side-pagination.component.less'],
  encapsulation: ViewEncapsulation.None
})

// clone of client side data grid in order to not change the current behaviour
// probably easy to merge into one

export class DataGirdServerSidePaginationComponent implements OnChanges, OnInit {

  @Input() columnData: GleacColumnDef[] = [];
  @Input() tableData: any;
  gridData: MatTableDataSource<any>;
  displayCols: string[] = [];
  errors: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isExpandable: boolean = false;
  expandedRow: any;
  @Input() extraData: any;
  @Input() data: any;
  @Output() colClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() pageChangedEvent: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ExpandableRowDirective) expandableRowContent: QueryList<ExpandableRowDirective>;
  noRecord: boolean;
  totalLength: any;

  constructor(private componentLoaderService: ComponentLoaderService) { }

  ngOnInit(): void {
    let activeCols = this.columnData.filter((col) => {
      if (col.isVisible === undefined || col.isVisible == true) return true;
    });
    this.displayCols = activeCols.map(x => x.data);
    this.isExpandable = this.columnData.filter((col) => {
      return col.isExpandable
    }).length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableData.results && this.tableData.results.length > 0) {
      this.gridData = new MatTableDataSource(this.tableData.results);
      this.paginator.pageSize = 25;
      this.paginator.length = this.tableData.totalCount;
      this.paginator.hidePageSize = true;
      this.gridData.sort = this.sort;
      this.noRecord = false;
    }
    else if (!this.tableData.results || this.tableData.results.length == 0) {
      this.gridData = new MatTableDataSource([]);
      this.paginator.pageSize = 25;
      this.paginator.hidePageSize = true;
      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;
      this.noRecord = true;
    }
  }

  columnClick(event, data) {
    this.colClickEvent.emit({ click: event, data: data });
  }

  pageChanged(event) {
    this.pageChangedEvent.emit();
  }

  expandRow(event, rowData) {
    this.expandedRow = rowData === this.expandedRow ? undefined : rowData;
    if (this.expandedRow !== undefined) {
      var selected = this.expandableRowContent.filter(x => x.rowExtraData.userEmail === this.expandedRow.userEmail);
      this.componentLoaderService.loadComponent(selected, BadgeComponent, this.expandedRow, this.extraData);
    }
  }

  logger(object: any) {
    console.log(object);
  }
}
