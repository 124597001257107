import { Injectable, ComponentFactoryResolver, ComponentFactory, QueryList } from '@angular/core';
import { ExpandableRowDirective } from '../directives/expandable-row.directive';

@Injectable({
  providedIn: 'root'
})
export class ComponentLoaderService {

  componentFactory: ComponentFactory<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  loadComponent(anchorElements: any, loadComponent: any, componentData: any, extra?: any) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(loadComponent);
    var containerAnchors = anchorElements.map(element => { return element.viewContainerRef });
    containerAnchors.forEach(element => {
      element.clear();
      var componentRef = element.createComponent(this.componentFactory);
      (<typeof loadComponent>componentRef.instance).data = componentData;
      if (extra !== undefined) {
        (<typeof loadComponent>componentRef.instance).extraData = extra;
      }
    });
  }
}
