import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfigsService} from 'src/app/services/configs.service';
import {VotingService} from 'src/app/services/voting.service';

@Component({
  selector: 'app-voting-paging',
  templateUrl: './voting-paging.component.html',
  styleUrls: ['./voting-paging.component.less']
})
export class VotingPagingComponent implements OnInit {
  likeClick: any;
  userAnswer = false;
  display = false;
  voteMessage: string = "Cast your vote for the best answer";
  challengeId: any;
  challenge: any;
  mentor: any;
  user: any;
  question: any;
  hours;
  minutes;
  seconds;
  tabUser: any;
  clapping = false;
  timeLeft: number = 3;
  interval;
  winner = 0;
  expire: boolean;
  votingStatus: string = "Voting Ends In";
  countDownDate: any;
  iserrorPopup: boolean;
  error_popup: { display: boolean; message: string; };
  clicked: boolean = false;

  constructor(private votingService: VotingService, private route: ActivatedRoute, private configsService: ConfigsService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.challengeId = params["challengeId"];
    });
    this.getVotingChallenge();
  }

  getTimer() {
    this.interval = setInterval(() => {
      let now = new Date().getTime();
      let distance = this.countDownDate - now;
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        this.voteMessage = "Cast your vote for the best answer";
        this.expire = true;
        this.votingStatus = "Voting Ended";
        if (this.expire) {
          this.voteMessage = "Are you ready to take a challenge?"
          if (this.mentor.vote_count > this.user.vote_count) {
            this.winner = 1;
          } else if (this.user.vote_count > this.mentor.vote_count) {
            this.winner = 2;
          } else {
            this.winner = 3;
          }
        }
      }
    }, 1000);

  }

  likeHandle(value: any) {
    if (!this.clicked) {
      let data = {
        challenge_id: this.challenge.challenge_id,
        user_id: String
      };
      if (value === 1) {
        data.user_id = this.mentor.user_id;
      } else if (value === 2) {
        data.user_id = this.user.user_id;
      }
      if (!this.expire)
        this.castVote(data, value);
    }


  }

  castVote(vote, value) {
    this.clicked = true;
    this.clapping = false;
    this.votingService.castVote(vote)
      .subscribe((data) => {
        if (data) {
          if (data.status_code === 200) {
            this.clicked = false;
            this.clapping = true;
            this.likeClick = value;
            this.voteMessage = "Thanks for your vote";
          }
          if (data.status_code === 100) {
            this.voteMessage = "You’ve already casted your vote!";
          }
          this.getVotingChallenge();
        }

      });

  }

  handleUserAnswer(value: any) {
    this.userAnswer = value;
    this.tabUser = this.userAnswer ? this.user : this.mentor;
  }

  handleQuestion(value) {
    this.display = value;
  }

  getVotingChallenge() {
    this.configsService.loading(true);
    this.votingService.getVotingChallenge(this.challengeId).subscribe((data) => {
        this.configsService.loading(false);
        if (data.status_code === 200) {
          this.challenge = data.result;
          this.mentor = this.challenge.user1.is_mentor ? this.challenge.user1 : this.challenge.user2;
          this.user = !this.challenge.user1.is_mentor ? this.challenge.user1 : this.challenge.user2;
          this.tabUser = this.mentor;
          this.question = this.challenge.question;
          this.countDownDate = this.convertUTCDateToLocalDate(new Date(this.challenge.expires_at));
          this.getTimer();
        } else if (data.status_code === 500) {
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Invalid Challenge Id"};
        }
      },
      err => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: "Something went wrong"};
      });
  }

  convertUTCDateToLocalDate(date) {
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }


}
