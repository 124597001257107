import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { trigger, transition, animate, style } from "@angular/animations";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
//import { TransactionsService } from 'src/app/services/transactions.service';
import { CommonService } from "../../services/common.service";
import { GleacColumnDef } from "../data-grid/data-grid.model";
import { ConfigsService } from "../../services/configs.service";
import { BadgeComponent } from "../badge/badge.component";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { data } from "jquery";
import {UsersService} from "../../services/users.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("200ms ease-in", style({ transform: "translateX(70%)" })),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class UserComponent implements OnInit {
  page_title = "Users";
  iscreatePopup: boolean = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  assignUserToCompanyFlag: boolean = false;
  create_popup = {
    display: false,
    title: "",
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    },
  };
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  error_popup = {};
  filter: string = "ALL";
  filterArray: any = [
    {
      label: " Assessment Status ",
      type: "static_select",
      key: "status",
      options: [
        { name: "Completed", value: "Completed" },
        { name: "Pending", value: "Pending" },
      ],
    },
  ];
  jobsList: any;
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    {
      data: "userName",
      displayName: "User Name",
      render: (data, row) => {
        if (!row.image) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        if (row.image && !row.image.startsWith("http")) {
          row.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
        }

        return '<div> <img height="30" width="30" style="border-radius: 50%;margin-right: 5px " src="' + row.image + '"  alt="" /> <span style="color:#0065AD">' + row.userName + '</span></div>'
      }
    },
    { data: "email", displayName: "User Email" },
    { data: "lessonsCount", displayName: "Lessons Completed", disableSort: true },
    {
      data: "assessment",
      displayName: "Assessment Status",
      disableSort: true,
      render: (data, row) => {
        let rowStr = 'Not Taken';

        if (row.masterBenchmarkDetails) {
          rowStr = "Master BM";

          if (row.miniBenchmarkDetails) {
            rowStr += ", " + "Mini BM";
          }
        } else if (row.miniBenchmarkDetails) {
          rowStr = "Mini BM";
        }

        return rowStr.trim();

        /*if (row.assessmentStatus === "PENDING") {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span>' +
            row.assessmentStatus +
            "</span></div>"
          );
          //return '<div class="gleac-badge-text danger">' + row.assessmentStatus + '</div>';
        } else if (row.assessmentStatus === "COMPLETED") {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span>' +
            row.assessmentStatus +
            "</span></div>"
          );
          //return '<div class="gleac-badge-text success">' + row.assessmentStatus + '</div>';
        }*/
      },
    },
    {
      displayName: "Badge",
      isVisible: false,
      disableSort: true,
      data: "badge",
      isExpandable: true,
      render: (data, row) => {
        return '<i class="fas fa-caret-down"></i>';
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Remove",
          url: "#",
          functionType: "Remove",
          image: "assets/images/delete_icon.svg",
        },
        // {
        //   label: "Delete",
        //   url: "#",
        //   functionType: "delete",
        //   image: "assets/images/delete_icon.svg",
        // },
      ],
    },
  ];
  pageIndex: number = 1;
  pageSize: number = 10;
  searchString = "";
  status = "";
  breadCrumsArray: any = [];
  visible: boolean = false;
  inputData: any;
  enableRow: boolean = true;
  queryParam: any = [];
  query;
  companyId;
  userId;
  url = "";
  userUrl = "";
  creatFields: any = [
    {
      label: "First Name",
      key: "firstName",
      placeholder: "Enter the first name",
      type: "input",
      innertype: "text",
      pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
      required: "required",
      value: "",
      maxlength: 90,
    },
    {
      label: "Last Name",
      key: "lastName",
      placeholder: "Enter the last name",
      type: "input",
      innertype: "text",
      pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
      required: "required",
      value: "",
      maxlength: 90,
    },
    {
      label: "Email",
      key: "email",
      placeholder: "Enter the email ID",
      type: "input",
      innertype: "email",
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
      required: "required",
      value: "",
      maxlength: 90,
    },
    { label: "Company ID", key: "companyId", type: "hidden", value: "" },
    { label: "User ID", key: "userId", type: "hidden", value: "" },
  ];

  constructor(
    private configsService: ConfigsService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public usersService: UsersService,
  ) {
    // this.getUserList();
  }

  ngOnInit() {

    this.usersService.isUsers = true;

    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
      if (this.companyId) {
        this.queryParam["companyId"] = this.companyId;
      }
    });
    this.queryParam["pageNumber"] = this.pageIndex;
    this.queryParam["pageSize"] = this.pageSize;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getUserList();
    // this.loadOptions(this.creatFields);
  }

  loadOptions(dataInput) {
    dataInput.map((field) => {
      if (field.apiUrl) {
        let requestData = [];
        this.configsService.loading(true);
        requestData[field.paramsNeeded] = this.companyId;
        this.commonService
          .getListAlone(
            field.apiUrl,
            this.commonService.encodeQueryData(requestData)
          )
          .subscribe(
            (data) => {
              field.options = data.result.list.map((x) => {
                return {
                  name: x.groupName,
                  id: x.groupId,
                  isChecked: false,
                };
              });
              field.options.map((f, i) => {
                if (field.value) {
                  field.value.map((x, y) => {
                    if (x.id == f.id) {
                      f.isChecked = true;
                    }
                  });
                }
              });
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
            }
          );
      }
      if (field.key == "companyId") {
        field.value = this.companyId;
      }
    });
  }

  getUserList() {
    this.configsService.loading(true);
    this.url = "Users/";
    this.userUrl = "Users/";
    this.commonService.getListDataGridV2(this.url, this.query).subscribe(
      (data) => {
        if (data.result.data.length <= 0 && data.result.data.totalCount != 0 && this.queryParam["pageNumber"] > 1) {
          this.queryParam["pageNumber"] = --this.queryParam["pageNumber"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getUserList();
        } else {
          let usersData = {
            list: data.result.data,
            totalCount: data.result.totalRecords
          }

          this.transactionsGridData = usersData;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["pageNumber"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getUserList();
  }

  sortChange(event) {
    this.queryParam["SortColumn"] = event.active;
    this.queryParam["SortOrder"] = event.direction;
    this.queryParam["searchText"] = this.searchString;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = "Users/";
    this.configsService.loading(true);
    this.commonService.getListDataGridV2(this.url, this.query).subscribe(
      (data) => {
        //this.jobsList = data.result.jobIndex;

        let usersData = {
          list: data.result.data,
          totalCount: data.result.totalRecords
        }

        this.transactionsGridData = usersData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(event) {
    this.searchString = event[0];
    this.queryParam["pageNumber"] = 1;
    this.queryParam["searchText"] = this.searchString;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Users/" : "Users/";
    this.configsService.loading(true);
    this.commonService.getListDataGridV2(this.url, this.query).subscribe(
      (data) => {
        // this.jobsList = data.result.jobIndex;
        //this.transactionsGridData = data.result;

        let usersData = {
          list: data.result.data,
          totalCount: data.result.totalRecords
        }

        this.transactionsGridData = usersData;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  customFilter(params) {
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        this.queryParam[key] = data[key];
      }
    } else {
      delete this.queryParam.status;
    }
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString ? "Users/search/" : "Users/";
    this.configsService.loading(true);
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        this.jobsList = data.result.jobIndex;
        this.transactionsGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  changeSearchText(event) {
  }

  editUser(event) {
    if (event.action == "edit") {
      this.creatFields.map((f, i) => {
        if (event.data[f.key]) {
          f.value = event.data[f.key];
        }
        if (f.key == "groupId") {
          f.value = event.data["groups"].map((x) => {
            return {
              name: x.groupName,
              id: x.groupId,
            };
          });
        }
        if (f.key == "userId") {
          f.value = event.data.transactionId;
        }
        if (f.key == "userName") {
          f.value = event.data.userFullName;
        }
      });
      this.createUser(event.data);
    } else if (event.action == "delete") {
      this.deleteUser(event.data.userId);
    }
    else if (event.action == "Remove") {
      this.removeUser(event.data.userId);
    }
  }

  createUser(data) {
    this.loadOptions(this.creatFields);
    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: !data,
      bulkInfo: {
        url: "Users/bulkupload/user/",
        queryId: this.companyId,
        templateUrl:
          "https://gleac-bulk-template.s3.us-west-2.amazonaws.com/User-Template.xlsx",
      },
      title: data ? "Edit User" : "Create Users",
      buttonPositive: data ? "Update" : "Create",
      field: this.creatFields,
      refreshData: () => {
        this.getUserList();
      },
      closeModal: () => {
        this.create_popup.display = false;
        this.iscreatePopup = false;
      },
      createrCall: (form: NgForm) => {
        if (form.valid) {
          this.create_popup.display = false;
          this.configsService.loading(true);
          let ListData = [];
          ListData = { ...form.value };
          if (data) {
            let groups = [];
            if (form.value.groupId) {
              form.value.groupId.map((f, i) => {
                if (f.id) {
                  groups.push(f.id);
                } else if (f.groupId) {
                  groups.push(f.groupId);
                } else {
                  groups.push(f);
                }
              });
            }
            ListData["groupId"] = groups;
            ListData["userId"] = data.userId;
            let userObject = {};
            userObject = Object.assign(ListData);
            this.commonService
              .updateDataV2(this.userUrl, userObject)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          } else {
            let userObject = {};
            userObject = Object.assign(ListData);
            this.commonService
              .createDataV2(this.userUrl, userObject)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          }
        }
      },
    };
  }

  toggleSlide(e) {
    this.visible = e;
  }

  showDetails(event) {
    if (this.enableRow) {
      if (this.visible) {
        this.toggleSlide(false);
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      } else {
        setTimeout(() => {
          this.inputData = event.data;
          this.visible = true;
        }, 1000);
      }
    } else {
      this.enableRow = true;
    }
  }

  /** Delete User
   -- @params Transaction ID --
   **/

  deleteUser(uId) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Users?userId=" + uId;
        this.commonService.deleteDataV2(url).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  removeUser(uId) {
    this.confirm.display = false;
    this.configsService.loading(true);
    let url = "Users/RemoveCompanyUser/" + uId;
    this.commonService.updateDataV2(url, null).subscribe(
      (data) => {
        this.postStatus(data);
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
    this.confirm.display = false;
  }

  /** CallBack Fucntion
   -- @params result --
   **/

  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.result,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.create_popup.display = false;
          this.success_popup.display = false;
          this.queryParam.pageIndex = 1;
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getUserList();
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
      // this.error_popup.display = true;
    }
  }

  assignUserToCompany() {
    this.assignUserToCompanyFlag = true;
  }

  userClose() {
    this.assignUserToCompanyFlag = false;
    this.getUserList();
  }
}
