import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA} from '@angular/material';
import {ExpandableRowDirective} from 'src/app/directives/expandable-row.directive';
import {ComponentLoaderService} from 'src/app/services/component-loader.service';
import {GleacColumnDef} from './data-grid-new-dashboard.model';
import {Sort} from '@angular/material/sort';
import {UsersService} from "../../services/users.service";
import {GoalsPopupComponent} from 'src/app/dialogs/goal-popup/goals-popup.component';
import {Paginator} from "primeng/paginator";


@Component({
  selector: 'app-data-grid-new-dashboard',
  templateUrl: './data-grid-new-dashboard.component.html',
  styleUrls: ['./data-grid-new-dashboard.component.less']
})
export class DataGridNewDashboardComponent implements OnInit {

  @Input() columnData: GleacColumnDef[] = [];
  @Input() tableData: any;
  gridData: MatTableDataSource<any>;
  displayCols: string[] = [];
  skillSummary: any;
  tags: any;
  mrLessons: any;
  errors: string[] = [];
  jobs: string[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginatorp', {static: false}) paginatorp: Paginator

  isExpandable: boolean = false;
  expandedRow: any;
  displayScore: boolean = false;
  @Input() extraData: any;
  @Input() data: any;
  @Input() pageTitle: any;
  @Input() pageLimit: any;
  @Input() pageIndex: any;
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();
  @Output() colClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() sortEvent: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ExpandableRowDirective) expandableRowContent: QueryList<ExpandableRowDirective>;
  visible: boolean = false;
  inputData: any;
  enableRow: boolean = true;
  showDetail: boolean;
  tasks: any;
  goalPopup: MatDialogRef<GoalsPopupComponent>;
  isPageChange: boolean = false;
  stopPageChange: boolean = false;

  constructor(private componentLoaderService: ComponentLoaderService, public userService: UsersService,
              protected dialog: MatDialog) {
  }

  ngOnInit(): void {
    let activeCols = this.columnData.filter((col) => {
      if (col.isVisible === undefined || col.isVisible == true) return true;
    });
    this.displayCols = activeCols.map((x) => x.data);
    this.isExpandable =
      this.columnData.filter((col) => {
        return col.isExpandable;
      }).length > 0;


  }

  ngAfterViewInit() {
    this.gridData.sort = this.sort;
    this.gridData.sortingDataAccessor = this._sortingDataAccessor;
  }

  sortData(sort: Sort) {
    this.sortEvent.emit(sort);
  }

  private _sortingDataAccessor = (item: any, property: string): any => {
    const element = item[property];
    switch (property) {
      default:
        return typeof (element) === 'string' ? element.toLowerCase() : element;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridData = new MatTableDataSource(
      this.tableData ? this.tableData.list : ""
    );
    this.gridData.sortingDataAccessor = this._sortingDataAccessor;
    if (
      this.tableData !== undefined && this.tableData.list
        ? this.tableData.list.length > 0
        : ""
    ) {
      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;

      if (!this.isPageChange) {
        this.stopPageChange = true;
        setTimeout(() => {
          this.stopPageChange = true;
          this.paginatorp.changePage(0);
        });
      }

      this.isPageChange = false;
    } else if (
      this.tableData && this.tableData.list
        ? this.tableData.list.length == 0
        : ""
    ) {

      this.gridData.paginator = this.paginator;
      this.gridData.sort = this.sort;

    }

  }


  display() {
    this.displayScore = !this.displayScore;
  }

  handleAnswer() {
    console.log("answer ")
  }

  changeSummary(data) {
    this.showDetail = true;
    this.skillSummary = data.skillSummary
  }

  changeMRLessons(data) {
    this.showDetail = true;
    this.mrLessons = data.lessonTitles.join(", ")
  }

  changeTasksToPerform(data) {
    this.showDetail = true;
    this.tasks = data.tasks[0].task_name;
  }

  changeTags(data) {
    this.showDetail = true;
    this.tags = data.tags.join();
  }

  changeJobs(data) {
    this.showDetail = true;
    this.jobs = data.futureJobs.split(',');
  }

  showCpdDetails(data) {
    this.showDetail = true;
  }

  columnClick(actionName, event, data, index) {
    event.stopPropagation();
    this.enableRow = false;
    this.colClickEvent.emit({click: event, data: data, actionName: actionName});
  }

  actionClick(event, data, action, index) {
    event.stopPropagation();
    this.colClickEvent.emit({click: event, data: data, action: action});
  }

  navigateToCreatelesson(catId, catName) {
    alert(catId);
  }

  expandRow(event, data, action, i) {
    if (this.showDetail) {
      this.showDetail = false;
      return;
    }
    if (event.target.className.includes("no_navigate")) {
      this.colClickEvent.emit({click: event, data: data, action: action});
    } else {
      this.rowClickEvent.emit({click: event, data: data});
    }
  }

  logger(object: any) {
  }

  paginate(event) {

    if (this.stopPageChange) {
      this.stopPageChange = false;
      return;
    }
    this.isPageChange = true;
    this.pageEvent.emit(event.page + 1);
  }

  assignGoal(event, row) {
    console.log("assign goal call.::::", event);
    console.log("row.::::", row);
    this.goalPopup = this.dialog.open(GoalsPopupComponent, {
      disableClose: true,
      width: "50%",
      height: "auto",
      data: {
        goalLibraryId: row.goal_library_id
      }
    });
  }

  checkForMentorRequestStatus(row, dropData) {
    if (row.mentorRequestId) {
      return row.status == 3;
    }

    if (row.emailSubject && dropData.label == 'Edit') {
      if (row.status) {
        return false;
      }
    }

    if (row.emailSubject && dropData.label == 'Opened Emails') {
      if (!row.status) {
        return false;
      }
    }

    if (row.emailSubject && dropData.label == 'View') {
      if (!row.status) {
        return false;
      }
    }

    if (row.startTime && row.endTime && dropData.label == 'Confirm') {
      if (row.status == 2) {
        return false;
      }
    }

    if (row.bookingDateTime && row.status == "booked" && dropData.label == 'Send Reminder') {
      return false;
    }

    if (row.bookingDateTime && row.status == "Accepted" && dropData.label == 'Send Reminder') {
      let date = this.convertUTCDateToLocalDate(row.bookingDateTime);
      if (new Date() > date) {
        return false;
      }
    }

    return true;
  }

  convertUTCDateToLocalDate(date) {
    if (typeof date.getMonth !== 'function') {
      date = new Date(date);
    }
    let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
  }
}
