import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (window.innerWidth <= 767) {
  environment.isMobile = true;
} else {
  environment.isMobile = false;
} 

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
