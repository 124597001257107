import {Component, OnInit, Inject} from '@angular/core';
import {UsersService} from 'src/app/services/users.service';
import {ConfigsService} from '../../services/configs.service';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {BenchmarkLoginComponent} from 'src/app/components/benchmark-login/benchmark-login.component';
import {BenchmarkService} from 'src/app/services/benchmark.service';
import {environment} from 'src/environments/environment';
import {AuthService} from 'angular-6-social-login';
import {Login, UserRegistration} from "../../components/benchmark-login/benchmark-login.model";
import jwt_decode from "jwt-decode";
import {UserType} from "../../components/common/common.model";

declare var jQuery: any;

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.less']
})
export class LoginPopupComponent extends BenchmarkLoginComponent implements OnInit {

  timerHeader: string;
  isMobile: boolean;
  requestOTPLoader: boolean = false;
  isOTPRequested: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, configsService: ConfigsService, usersService: UsersService, router: Router, formBuilder: FormBuilder,
              dialog: MatDialog, route: ActivatedRoute, benchmarkService: BenchmarkService, private dialogRef: MatDialogRef<LoginPopupComponent>,
              protected socialService: AuthService) {
    super(configsService, usersService, router, formBuilder, dialog, route, benchmarkService, socialService);
    if (data.dataKey)
      this.timerHeader = "Time Elapsed!";
    if(data.initialView && data.initialView === 'signup'){
      this.showUserRegistrationFrom(null);
    }
    if (environment.isMobile) {
      this.isMobile = true;
    }

    (function ($) {
      $.fn.randomize = function (childElem) {
        return this.each(function () {
          var $this = $(this);
          var elems = shuffle($(childElem));
          $this.remove(childElem);
          for (var i = 0; i < elems.length; i++)
            $this.append(elems[i]);
        });
      }
    })(jQuery);

    jQuery(function ($) {
      $("div.random-mentor-profile").randomize("div.mentor-profile");
      $(".mentor-profile:gt(4)").hide();
    });

    function shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    }


  }

  ngOnInit() {
    super.ngOnInit();
    this.loginForm.get("password").setValidators([Validators.required, Validators.pattern(/^[0-9]+$/)]);
  }


  requestOtp() {
    this.loginNotifs = [];
    this.loginForm.controls["email"].markAsTouched({onlySelf: true});
    if (this.loginForm.controls["email"].value && (!this.loginForm.controls["email"].invalid)) {
      this.requestOTPLoader = true;
      this.usersService.requestOtp(this.loginForm.controls["email"].value).subscribe(data => {
        if (data.hasError) {
          this.requestOTPLoader = false;
          this.isOTPRequested = false;
          this.errors = [];

          if (data.errors && data.errors[0]) {
            this.errors.push(data.errors[0]);
          } else {
            this.errors.push("Something went wrong, please try again.");
          }

          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);

        } else {
          this.loginNotifs.push("OTP Sent Successfully.");
          // Got focus to the error field
          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);

          this.requestOTPLoader = false;
          this.isOTPRequested = true;
          this.errors = [];
        }
      }, error => {
        this.requestOTPLoader = false;
        this.isOTPRequested = false;
        this.errors = [];
        this.errors.push("Something went wrong, please try again.");
        setTimeout(function () {
          let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
          invalidFields[0].focus();
          invalidFields[0].scrollIntoView({behavior: 'smooth'});
        }, 500);
      })
    }
  }

  loginUser($event) {
    this.loginNotifs = [];
    var loginDetails: Login = {
      userName: this.loginForm.controls["email"].value,
      password: this.loginForm.controls["password"].value
    };
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.showLoginLoader = true;
      this.loginInProgress = true;
      this.usersService.otpLogin(this.loginForm.controls["email"].value, this.loginForm.controls["password"].value).subscribe(
        data => {
          if (data.hasError) {
            this.errors = [];
            this.showLoginLoader = false;
            this.loginInProgress = false;

            if (data.errors && data.errors[0]) {
              this.errors.push(data.errors[0]);

              setTimeout(function () {
                let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                invalidFields[0].focus();
                invalidFields[0].scrollIntoView({behavior: 'smooth'});
              }, 500);
            } else {
              this.errors = [];
              this.showLoginLoader = false;
              this.loginInProgress = false;
              this.errors.push("Something went wrong, please try again.");
              // Got focus to the error field
              setTimeout(function () {
                let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
                invalidFields[0].focus();
                invalidFields[0].scrollIntoView({behavior: 'smooth'});
              }, 500);
            }
          } else {
            this.errors = [];
            this.showLoginLoader = false;
            this.loginInProgress = false;
            this.gotoUserDashboard(data);
          }
        },
        error => {
          this.errors = [];
          this.showLoginLoader = false;
          this.loginInProgress = false;
          this.errors.push("Something went wrong, please try again.");
          // Got focus to the error field
          setTimeout(function () {
            let invalidFields = [].slice.call(document.getElementsByClassName('gleac-error-box'));
            invalidFields[0].focus();
            invalidFields[0].scrollIntoView({behavior: 'smooth'});
          }, 500);
        }
      );
    } else {
      this.showLoginLoader = false;
      this.loginInProgress = false;
    }
  }

  gotoUserDashboard(data) {
    this.showLoginLoader = false;
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions: decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.configsService.loading(true);
    this.dialogRef.close();
  }

  gotoUserDashboardAfterVerification(data) {
    this.showLoginLoader = false;
    localStorage.setItem("GATk", data.result.accessToken);
    localStorage.setItem("GRTk", data.result.refreshToken);
    let jwtData = data.result.accessToken.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    this.usersService.currentUser = {
      authRole: decodedJwtData.auth_role,
      email: decodedJwtData.email,
      userId: decodedJwtData.sub,
      permissions: decodedJwtData.permissions
    };
    if (decodedJwtData.company) this.usersService.currentUser.company = decodedJwtData.company;
    this.configsService.loading(false);
  }

  gotoForgotPassword(event) {
    window.open("https://community.gleac.com/password/forgot", "_blank")
  }

  close($event) {
    this.configsService.loading(true);
    this.dialogRef.close();
  }

  resetForm() {
    this.loginNotifs = [];
    this.verificationForm.reset();
    this.verificationError = false;
  }
}
