import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {appSettings} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  baseUrl: string = appSettings.mobileUrl;
  userUrl: string = appSettings.authUrl;


  constructor(private http: HttpClient) {
  }

  uploadGoalImage(data) {
    let formData = new FormData();
    formData.append("files", data);
    let headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data")
      .delete("Content-Type");
    var url = this.baseUrl + "FileUpload/goalImage";
    var response = this.http.post<any>(url, formData, {headers: headers});
    return response;
  }

  uploadUserProfileImage(userId, data) {
    let formData = new FormData();
    formData.append("files", data);
    let headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data")
      .delete("Content-Type");
    var url = this.userUrl + "Users/UploadProfilePicture?userId=" + userId;
    var response = this.http.post<any>(url, formData, {headers: headers});
    return response;
  }
}
