import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ChatCompletionsRequest } from '../models/open-ai/chat-completions-request';
import { ChatCompletionsResponse } from '../models/open-ai/chat-completions-response';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenAiService {

  baseUrl: string = appSettings.lessonUrl;

  constructor(private httpClient: HttpClient) {}

  sendOpenAiRequest(chatCompletionsRequest: ChatCompletionsRequest) {    
    return this.httpClient.post<ChatCompletionsResponse>(this.baseUrl + 'OpenAI/chat/completions', chatCompletionsRequest);
  }  
}
