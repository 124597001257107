import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'util';

@Pipe({
  name: 'toTimer'
})
export class ToTimerPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(isNumber(value)) {
      let seconds = <number>value;
  
      let h = Math.floor(seconds / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 3600 % 60);
      let displayS = s < 10 ? s === 0 ? "00" : "0" + s : s;
      let displayM = m < 10 ? m === 0 ? "00" : "0" + m : m;

      return h === 0 ? displayM + ":" + displayS : h + ":" + displayM + ":" + displayS;
    }
  }
}
