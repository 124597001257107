import { Injectable } from "@angular/core";
import { appSettings } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient) { }
  lessonReportUrl: string = appSettings.lessonUrl;
  benchmarkReportUrl: string = appSettings.dashboardUrl;
  userActivityReportUrl: string = appSettings.lessonUrl + "reports/";
  userReportUrl: string = appSettings.authUrl;
  minibenchmarkUrl: string = appSettings.miniBenchmarkUrl;
  getBenchmarkReportGrid(
    dataFilter?: string,
    statusFilter?: string,
    companyFilter?: string
  ) {
    dataFilter = dataFilter ? dataFilter : "";
    statusFilter = statusFilter ? statusFilter : "COMPLETED";
    var apiUrl =
      this.benchmarkReportUrl +
      "users?companyId=" +
      companyFilter +
      "&filterString=" +
      dataFilter +
      "&status=" +
      statusFilter;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getUserActivityReportGrid(
    searchText?: string,
    fromDate?: Date,
    toDate?: Date,
    organizationId?: string,
    groupId?: string,
    lessonCampaignId?: {},
    minimumLessons?: number,
    pageNumber?: number, pageSize?: number,
    clearCache?: boolean
  ) {
    let data = {};
    data["searchText"] = searchText ? searchText : "";
    data["fromDate"] = fromDate ? new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toJSON() : "";
    data["toDate"] = toDate ? new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toJSON() : "";
    data["organizationId"] = organizationId ? organizationId : "";
    data["groupId"] = groupId ? groupId : "";
    data["lessonCampaignId"] = lessonCampaignId ? lessonCampaignId : [];
    data["minimumLessons"] = minimumLessons;
    var url = this.benchmarkReportUrl + "Reports/useractivity?" +
      "pageNumber=" + pageNumber +
      "&pageSize=" + pageSize + "&clearCache=" + (clearCache ? clearCache : false)
    // console.log(data);
    let headers = new HttpHeaders().append(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.post<any>(url, JSON.stringify(data), { headers: headers });
  }
  getUserReportGrid(dataFilter?: string, companyFilter?: string, pageNumber?: number, pageSize?: number) {
    dataFilter = dataFilter ? dataFilter : "";
    var apiUrl =
      this.userReportUrl +
      "users/paged?filterString=" +
      dataFilter +
      "&companyId=" +
      companyFilter +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getMiniBenchMarkReport(dataFilter?: string, fromDate?: string, toDate?: string, pageIndex: number = 1, pageSize: number = 25) {
    dataFilter = dataFilter ? dataFilter : "";
    let fdate = fromDate ? (new Date(fromDate)).toISOString() : "";
    let tdate = toDate ? (new Date(toDate)).toISOString() : "";

    var MiniBenchMarkApiUrl = this.minibenchmarkUrl + "user/report/activity?dataFilter=" + dataFilter + "&fromDate=" + fdate + "&toDate=" + tdate + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&key=" + appSettings.miniBenchmarkKey + "";
    var response = this.http.get<any>(MiniBenchMarkApiUrl);
    return response;
  }

  getMiniBenchMarkReportExport(dataFilter?: string, fromDate?: string, toDate?: string, pageIndex: number = 1, pageSize: number = 25) {
    dataFilter = dataFilter ? dataFilter : "";
    let fdate = fromDate ? (new Date(fromDate)).toISOString() : "";
    let tdate = toDate ? (new Date(toDate)).toISOString() : "";

    var MiniBenchMarkApiUrl = this.minibenchmarkUrl + "user/report/activity/export?dataFilter=" + dataFilter + "&fromDate=" + fdate + "&toDate=" + tdate + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&key=" + appSettings.miniBenchmarkKey + "";
    var response = this.http.get(MiniBenchMarkApiUrl, { responseType: 'blob' });
    return response;
  }

  getReadinessReport(dataFilter?: string, fromDate?: string, toDate?: string) {
    let lessonUrl = this.lessonReportUrl + 'performance/readinessreport';
    lessonUrl += dataFilter ? ("/" + dataFilter) : "";
    let fdate = fromDate ? (new Date(fromDate.replace(/-/g, "/"))).toISOString() : "";
    let tdate = toDate ? (new Date(toDate.replace(/-/g, "/"))).toISOString() : "";
    if (fdate !== "" && tdate !== "") {
      if (dataFilter === "" || dataFilter === null) {
        lessonUrl += "/ ";
      }
      lessonUrl += fdate !== "" ? "/" + fdate : "";
      lessonUrl += tdate !== "" ? "/" + tdate : "";
    }

    var response = this.http.get<any>(lessonUrl);
    return response;
  }

  getStreakReport(
    fromDate?: Date,
    toDate?: Date,
    minStreak?: string
  ) {
    var url = this.lessonReportUrl + "Reports/getStreakReport?" +
      "fromDate=" + (fromDate ? new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&toDate=" + (toDate ? new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&minStreak=" + (minStreak ? minStreak : 1);

    let headers = new HttpHeaders().append(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.get<any>(url, { headers: headers });
  }

  exportStreakReport(fromDate?: Date, toDate?: Date, minStreak?: string) {
    var url = this.lessonReportUrl + "Reports/exportStreakReport?" +
      "fromDate=" + (fromDate ? new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&toDate=" + (toDate ? new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&minStreak=" + (minStreak ? minStreak : 1);

    return this.http.get(url, {
      responseType: 'blob'
    })
  }

  getTopLessonTakerReport(
    fromDate?: Date,
    toDate?: Date,
    topRecords?: string
  ) {
    var url = this.lessonReportUrl + "Reports/getTopLessonTakersReport?" +
      "fromDate=" + (fromDate ? new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&toDate=" + (toDate ? new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&topRecords=" + (topRecords ? topRecords : 1);

    let headers = new HttpHeaders().append(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this.http.get<any>(url, { headers: headers });
  }

  exportTopLessonTakerReport(fromDate?: Date, toDate?: Date, topRecords?: string) {
    var url = this.lessonReportUrl + "Reports/exportTopLessonTakersReport?" +
      "fromDate=" + (fromDate ? new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&toDate=" + (toDate ? new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toJSON() : "") +
      "&topRecords=" + (topRecords ? topRecords : 1);

    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}