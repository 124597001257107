import { Component, OnInit, Input, Output, ViewChild, ElementRef } from "@angular/core";
import {
  Lesson,
  Section,
  SectionElement,
  LessonReviewQuestion,
  QuestionType,
  SkillType
} from "../common/common.model";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from "@angular/forms";
import { LessonsService } from "src/app/services/lessons.service";
import { LessonSkill } from "../lessons/lessons.model";
import { SectionControlTypes, SectionTagTypes } from "../common/common.constant";
import { SectionElementType } from "../common/common.model";
import { EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { appSettings } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import { DeleteConfirmationComponent } from 'src/app/dialogs/delete-confirmation/delete-confirmation.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { forEach } from "../../../assets/fonts/font-awesome/js/v4-shims";
import { InvitePopupComponent } from "src/app/dialogs/invite-popup/invite-popup.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MentorService } from "src/app/services/mentor.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { InvitePopupWhatsAppComponent } from "src/app/dialogs/invite-popup-whatsapp/invite-popup-whatsapp.component";
import { LessonInviteStatusComponent } from "src/app/dialogs/lesson-invite-status/lesson-invite-status.component";
import { __metadata } from "tslib";

declare var jQuery: any;

@Component({
  selector: "app-lesson-upsert",
  templateUrl: "./lesson-upsert.component.html",
  styleUrls: ["./lesson-upsert.component.less"]
})
export class LessonUpsertComponent implements OnInit {
  allSectionControlTypes = JSON.parse(JSON.stringify(SectionControlTypes));
  allSectionTagTypes = JSON.parse(JSON.stringify(SectionTagTypes));
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };
  @Input() lessonDetails: Lesson;
  imageStorageUrl: string = appSettings.imageStoreUrl;
  lessonFormErrors: string[] = [];
  @ViewChild("lessonEditTab", { static: false }) lessonEditTab: ElementRef;
  sectionFormErrors: string[] = [];
  lessonForm: FormGroup;
  lessonSections: Section[] = [];
  lessonCategoryNames: { categoryName: string; categoryId: string }[] = [];
  sectionForm: FormGroup;
  sectionControlsTypes = JSON.parse(JSON.stringify(this.allSectionControlTypes));
  sectionTagsTypes = JSON.parse(JSON.stringify(this.allSectionTagTypes));
  selectedControlType: FormControl = new FormControl();
  controlForm: FormGroup;
  newSectionControls: FormArray = new FormArray([], Validators.minLength(1));
  editSectionControls: FormArray = new FormArray([], Validators.minLength(1));
  selfReviewForm: FormArray = new FormArray([]);
  sectionToEdit: Section;
  levelNumber = 1;
  sectionEditForm: FormGroup;
  sectionDeleteDialog: MatDialogRef<DeleteConfirmationComponent>;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() done: EventEmitter<any> = new EventEmitter();
  @Output() previewChange: EventEmitter<any> = new EventEmitter();
  imageUploadArray: File[] = [];
  newControl;
  importLessonFlag: boolean = false;
  techAnswer: string = "";
  mentorSearch: string = "";
  selectedSection = 0;
  answerEditMentorName = "";
  answerEditMentorSection: any = "";
  mentorSectionAnswerEdit = "";
  selectedElementAnswer: any;
  mentorId = "";
  mentorIndex = 0;
  isErrorPopup: boolean = false;

  mentorAnswerDisplay: boolean = false;
  isEdit: boolean;

  editMentorAnswersList: any = {};
  mentorAnswerList = [];
  TechnialAnswer = [];
  skillTypes: [];
  mentorData = [];
  filterdMentorData = [];
  mentorAnswer: any;
  allowedFileTypes: string[] = ["image/jpeg", "image/jpg", "image/png"];
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link'],
      ],
    }
  }
  answerSection: number = 1;
  quesitionType = [];
  skillTypeOptions = [];
  lessons = [];
  lessonPreviewData: any;
  previewLessonFlag: any;
  filterLesson: any = [];
  previewLessonData: Lesson;
  issuccessPopup: boolean;
  categoryId: string;
  questionType: any;
  mentorLessonAnswers: any;
  selectedMentor: any;
  mentorFlag: any;
  error_popup = {};
  lessonCategoryDetails: any;
  uploadedFiles: any[] = [];
  isUploadingLessonImage: boolean = false;
  discussions: any[] = [];
  commentSearch: string = "";
  discussionStore: any[] = [];

  @ViewChild('fileUpload', { static: false }) fileUpload: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected lessonsService: LessonsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected usersService: UsersService,
    protected dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    protected mentorService: MentorService,
    protected configsService: ConfigsService
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit() {
    var lessonCategory = "";
    this.addQuestionType();
    this.getMentorAnswer();
    this.setSkillType();


    this.route.params.subscribe(params => {
      this.categoryId = params["categoryId"];
      lessonCategory = params["categoryId"];
      this.lessonsService.categoryChangedObserver$.next(params["categoryId"]);

      if (params["levelId"]) {
        this.levelNumber = Number(params["levelId"]);
      }

      this.lessonsService.getLessonCategoryDetailsById(params["categoryId"]).subscribe(data => {
        this.lessonCategoryDetails = data.result;
        if (!this.lessonCategoryDetails.appLink) {
          this.lessonsService.getSkillAppLinkForLesson(params["categoryId"]).subscribe(data => {
            this.lessonCategoryDetails.appLink = data.result;
          });
        }
      });


    });
    this.lessonsService.getLessonCategories().subscribe(res => {
      let lessonCategories: LessonSkill[] = res.result;
      this.lessonCategoryNames = lessonCategories.map(x => {
        return {
          categoryName: x.lessonCategoryName,
          categoryId: x.lessonCategoryId
        };
      });
    });
    this.lessonsService.getLesson(lessonCategory).subscribe((data) => {
      if (data) {
        this.lessons = data.result;
        //this.filterLesson = data.result;

        this.lessonForm.controls.level.setValue(this.levelNumber);
        this.handleFilterLesson("");

      }
    })
    if (this.lessonDetails) {
      this.fillLessonDetail();
    } else {
      var newLesson = {
        lessonCategoryId: lessonCategory,
        lessonSummary: "",
        lessonImage: "",
        level: 1,
        isTrail: false,
        levelTitle: "",
        title: ""
      };
      let reviewQuesion = {
        maxScale: 10,
        minScale: 0,
        question: ""
      };
      this.selfReviewForm = this.formBuilder.array([
        this.formBuilder.control(reviewQuesion.question, [Validators.required], null),
        this.formBuilder.control(reviewQuesion.question, [Validators.required]),
        this.formBuilder.control(reviewQuesion.question, [Validators.required])
      ]);
      this.lessonForm = this.formBuilder.group(newLesson);
    }
    this.setupLessonFormValidations();

    window.onclick = function (event: any) {
      if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        for (var i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }
  }

  getLessons(lessonId: string) {
    this.lessonsService.getLesson(this.categoryId).subscribe((data) => {
      if (data) {
        this.lessons = data.result;
        //this.filterLesson = data.result;

        this.lessonForm.controls.level.setValue(this.levelNumber);
        this.handleFilterLesson("");
        this.editLessonByLessonId(lessonId);
      }
    })
  }

  enableCarousel(value) {
    (function ($) {
      $(document).ready(function () {
        $('#mentor-overall-container' + value).slick({
          dots: false,
          autoplay: false,
          infinite: false,
          arrows: false
        });
      });
    })(jQuery);
  }

  addTechAnswer(value) {
    this.techAnswer = value;
  }

  navigateToLessonLibrary() {
    this.router.navigate([
      "lessons/library"
    ]);
  }

  fillNewLesson(mentorAnswerData) {

    this.mentorData = [];
    this.filterdMentorData = [];
    let mentorList = mentorAnswerData;
    let sections = this.lessonDetails.sections;
    this.TechnialAnswer.length = sections.length;
    this.mentorAnswerList.length = sections.length;
    let length = sections.length;
    for (let i = 0; i < length; i++) {

      this.TechnialAnswer[i].SectionAnswer = sections[i].technicalAnswers !== null ? sections[i].technicalAnswers : "";
      this.mentorAnswerList[i].SectionAnswer = sections[i].mentorAnswers ? sections[i].mentorAnswers : [];
      //this.filterdMentorData[i] = this.mentorAnswerList[i].SectionAnswer;

      let context = this;

      mentorAnswerData.forEach(function (item) {
        let mentor = Object.assign({}, item);

        if (mentor.sectionAnswers) {
          for (let j = 0; j < mentor.sectionAnswers.length; j++) {
            if (mentor.sectionAnswers[j].sectionId === sections[i].sectionId) {
              mentor.sectionId = sections[i].sectionId;
              mentor.answers = mentor.sectionAnswers[j].answers;

              if (!context.mentorData[i]) {
                context.mentorData[i] = [];
              }
              context.mentorData[i].push(mentor);

              if (!context.filterdMentorData[i]) {
                context.filterdMentorData[i] = [];
              }
              context.filterdMentorData[i].push(mentor);
              break;
            }
          }
        }
      });

      //this.mentorAnswerList[i].SectionAnswer.forEach(function(item) {
      //  if (context.mentorData[i]) {
      //    context.mentorData[i].forEach(function(item2) {
      //      if (item.mentorId === item2.mentorId) {
      //        if (!context.filterdMentorData[i]) {
      //          context.filterdMentorData[i] = [];
      //        }
      //        context.filterdMentorData[i].push(item2);
      //      }
      //    });
      //  }
      //});

    }


    //for (let i = 0; i < length; i++) {
    //  for (let j = 0; j < mentorList.length; j++) {
    //    if (mentorList[j].sectionAnswers) {
    //      for (let k = 0; k < mentorList[j].sectionAnswers.length; k++) {

    //        let sId = sections[i].sectionId;
    //        let mentorSectionId = mentorList[j].sectionAnswers[k].sectionId;
    //        if (sId === mentorSectionId) {
    //          let sectionIndex = i, mentor = mentorList[j], sectionId = this.mentorLessonAnswers[j].sectionAnswers[k].sectionId;

    //          this.addNewMentorToFilterData(sectionIndex, mentor, sectionId);
    //        }
    //      }
    //    }
    //  }
    //}

    //this.mentorData = mentorAnswerData;

  }

  fillLessonDetail() {

    this.fileUpload.clear();
    this.uploadedFiles = [];

    if (this.lessonDetails.lessonImage) {
      this.uploadedFiles.push(this.dummyFile());
    }

    let lessonEditDetails = {
      companyId: this.lessonDetails.companyId,
      lessonCategoryId: this.lessonDetails.lessonCategoryId,
      lessonId: this.lessonDetails.lessonId,
      lessonSummary: this.lessonDetails.lessonSummary,
      lessonImage: this.lessonDetails.lessonImage ? this.lessonDetails.lessonImage : '',
      level: this.lessonDetails.level,
      subLevel: this.lessonDetails.subLevel,
      title: this.lessonDetails.title,
      levelTitle: this.lessonDetails.levelTitle,
      isTrail: this.lessonDetails.isTrial,
      shLink: this.lessonDetails.shLink,
      appLink: this.lessonDetails.appLink
    };
    this.selfReviewForm = this.formBuilder.array(
      this.lessonDetails.selfReviewQuestions.map(x =>
        this.formBuilder.control(x.question, Validators.required)
      )
    );

    this.lessonSections = this.lessonDetails.sections;
    this.lessonForm = this.formBuilder.group(lessonEditDetails);

    this.levelNumber = this.lessonDetails.level;
    this.lessonForm.controls.level.setValue(this.levelNumber);
    let sections = this.lessonDetails.sections;
    this.TechnialAnswer.length = sections.length;
    this.mentorAnswerList.length = sections.length;
    this.discussionStore.length = sections.length;
    this.discussions.length = sections.length;
    let length = sections.length;
    for (let i = 0; i < length; i++) {

      this.TechnialAnswer[i] = { SectionAnswer: sections[i].technicalAnswers !== null ? sections[i].technicalAnswers : "" };
      this.mentorAnswerList[i] = { SectionAnswer: sections[i].mentorAnswers ? sections[i].mentorAnswers : [] };

    }
  }

  addNewMentorToFilterData(i: any, mentor, sectionId) {
    let m = mentor;
    for (let j = 0; j < this.filterdMentorData[i].length; j++) {
      if (this.filterdMentorData[i][j].mentorId === mentor.mentorId) {
        return;
      }
    }
    this.fillData(m, i);
  }

  fillData(mentor: any, index) {
    this.selectedMentor = { ...mentor };
    this.selectedMentor.sectionAnswers = this.selectedMentor.sectionAnswers[index].answers
    this.filterdMentorData[index].push(this.selectedMentor);
  }

  getTrimAnswer(value) {
    let val = value;
    if (val.length >= 20) {
      val = val.slice(0, 30) + "...";
    }
    return val;
  }

  changeLevel(value) {
    this.fileUpload.clear();
    this.uploadedFiles = [];
    this.levelNumber = value + 1;
    this.lessonForm.controls.level.setValue(this.levelNumber);
    this.router.navigate(["lessons/" + this.categoryId + "/" + this.levelNumber + "/new"]);
  }

  handleFilterLesson(searchKeyword) {
    let lesson;
    this.filterLesson = this.lessons.filter((item) => {
      if (searchKeyword.length)
        lesson = (item.level === this.levelNumber) &&
          (item.title.toLowerCase().includes(searchKeyword.toLowerCase()));
      else
        lesson = (item.level === this.levelNumber);
      return lesson;
    });


    this.handleLevelTitle();
  }

  handleLevelTitle() {
    let filteredLessons = this.lessons.filter(
      l => l.level === this.levelNumber);

    if (filteredLessons.length > 0) {
      this.lessonForm.controls["levelTitle"].setValue(filteredLessons[0].levelTitle);
    }
  }


  setSkillType() {
    for (var item in SkillType) {
      if (isNaN(Number(item))) {
        this.skillTypeOptions.push({
          value: SkillType[item],
          name: item
        });
      }
    }
  }

  setupLessonFormValidations() {
    this.lessonForm.controls["levelTitle"].setValidators([Validators.required]);
    this.lessonForm.controls["isTrail"].setValidators([Validators.required]);
    this.lessonForm.controls["lessonSummary"].setValidators([
      Validators.required
    ]);
    // this.lessonForm.controls["lessonImage"].setValidators([
    //   Validators.required
    // ]);
    this.lessonForm.controls["title"].setValidators([Validators.required]);
    this.lessonForm.controls["level"].setValidators([Validators.min(1)]);
    //this.lessonForm.controls["subLevel"].setValidators([Validators.min(1)]);
    this.lessonForm.controls["lessonCategoryId"].setValidators([
      Validators.required
    ]);
  }

  addQuestionType() {
    for (var question in QuestionType) {
      if (isNaN(Number(question))) {
        this.quesitionType.push({
          value: Number(QuestionType[question]),
          name: question
        });
      }
    }
  }


  addSection(event) {
    let newSection = {
      sectionOrder:
        this.lessonSections.length == 0 ? 0 : this.lessonSections.length,
      sectionControls: [],
      sectionId: undefined,
      sectionTitle: "",
      sectionTag: "",
      isChallenge: false,
      showMentorAnswer: false,
      questionType: ""
    };
    this.sectionForm = this.formBuilder.group(newSection);
    this.setupSectionFormValidations();
    this.resetSectionElementTypes();
    this.selectedControlType.setValue(1);
  }

  editLesson(value) {

    let filteredLessons = this.lessons.filter(
      l => l.level === this.levelNumber);
    this.lessonDetails = filteredLessons[value];
    this.previewLessonData = this.lessonDetails;
    this.fillLessonDetail();
    this.getMentorForLesson(this.lessonDetails);
    this.getDiscussionsForLesson(this.lessonDetails);
    this.getShortLinkForLesson();
    this.getAppLinkForLesson();
    this.discardEditSection();
  }

  getShortLinkForLesson() {
    if (!this.lessonDetails.shLink) {
      this.lessonsService.getShortLinkForLesson(this.lessonDetails.lessonId).subscribe((data) => {
        if (data && data.result) {
          this.getLessons(this.lessonDetails.lessonId);
        }
      })
    }
  }

  getAppLinkForLesson() {
    if (!this.lessonDetails.appLink) {
      this.lessonsService.getAppLinkForLesson(this.lessonDetails.lessonCategoryId,
        this.lessonDetails.level, this.lessonDetails.lessonId).subscribe((data) => {
          if (data && data.result) {
            this.getLessons(this.lessonDetails.lessonId);
          }
        })
    }
  }

  getMentorForLesson(lessonDetail: any) {
    this.lessonsService.getMentorAnswer(lessonDetail.lessonId).subscribe((data) => {
      if (data) {
        this.mentorLessonAnswers = data.result;
        this.fillNewLesson(data.result);

      }
    })
  }

  editLessonByLessonId(lessonId: string) {

    let filteredLessons = this.lessons.filter(
      l => l.level === this.levelNumber && l.lessonId === lessonId);
    this.lessonDetails = filteredLessons[0];
    this.previewLessonData = this.lessonDetails;
    this.fillLessonDetail();
  }

  handleLessonPreview(lesson) {
    this.lessonPreviewData = lesson;
    this.previewLessonFlag = true;
  }

  // New Section

  setupSectionFormValidations() {
    this.sectionForm.controls["sectionTitle"].setValidators([
      Validators.required
    ]);
    this.sectionForm.controls["sectionTag"].setValidators([
      Validators.required
    ]);
    this.sectionForm.controls["isChallenge"].setValidators([
      Validators.required
    ]);
  }

  addControlToSection(event) {
    this.newControl = {
      sectionElementType: parseInt(this.selectedControlType.value),
      sectionElementContent: "",
      sectionElementOptions: undefined,
      sectionElementImageName: undefined,
      sectionElementId: undefined
    };
    // if (
    //   this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_SINGLE || this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_MULTI
    // ) {
    //   this.newControl.sectionElementOptions = this.formBuilder.array(
    //     [],
    //     Validators.required
    //   );
    // }
    switch (parseInt(this.selectedControlType.value)) {
      case SectionElementType.TEXTBOX_SINGLE:
        this.selectedControlType.setValue(SectionElementType.TEXTBOX_DESCRIPTION);
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.TEXTBOX_MULTI:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.SELECTION_BUTTONS_MULTI:
      case SectionElementType.SELECTION_BUTTONS_SINGLE:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.newControl.sectionElementOptions = this.formBuilder.array([]);
        break;
      default:
        break;
    }
    this.controlForm = this.formBuilder.group(this.newControl);
    //this.controlForm.controls["sectionElementContent"].setValidators([Validators.required]);
    this.setupControlFormValidations(this.controlForm);
    this.newSectionControls.push(this.controlForm);
  }

  setupControlFormValidations(form: FormGroup) {
    form.controls["sectionElementContent"].setValidators([Validators.required]);
  }

  addElementOption($event, index) {
    ((this.newSectionControls.controls[index] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.push(
      this.formBuilder.control("", [Validators.required])
    );
  }

  deleteOption(event, controlIndex, optionIndex) {
    ((this.newSectionControls.controls[controlIndex] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.splice(optionIndex, 1);
  }

  deleteControl(event, index, controlType) {
    this.newSectionControls.controls.splice(index, 1);
    if (parseInt(controlType) == SectionElementType.TEXTBOX_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    } else if (parseInt(controlType) == SectionElementType.TEXTBOX_MULTI) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    } else if (parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_MULTI || parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
    }
  }

  saveSection(event) {
    this.sectionFormErrors = [];
    this.lessonFormErrors = [];
    this.sectionForm.markAllAsTouched();
    if (!this.validateNewSectionSave()) {
      this.sectionFormErrors.push("Atleast one input control is required");
    } else if (!this.validateNewSectionOptionsSave()) {
      this.sectionFormErrors.push("Atleast one option is required");
    } else if (this.sectionForm.valid && this.newSectionControls.valid && this.newSectionControls.length > 0 && this.validateNewSection()) {
      var section = this.populateSection();
      console.log("section section ", section);
      this.lessonSections.push(section);
      this.discardNewSection();
    } else {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push(
        "There are invalid values in new section. Please correct them."
      );
    }
  }

  discardNewSection(event?) {
    this.sectionForm.reset();
    this.newSectionControls.reset();
    this.newSectionControls = new FormArray([]);
    this.sectionForm = undefined;
  }

  populateSection() {
    let section: Section = {
      sectionControls: [],
      sectionId: undefined,
      sectionTitle: this.sectionForm.value["sectionTitle"],
      sectionOrder: 0,
      sectionTag: this.sectionForm.value["sectionTag"],
      questionType: Number(this.sectionForm.value["questionType"]),
      isChallenge: this.sectionForm.value["isChallenge"],
      showMentorAnswer: this.sectionForm.value["showMentorAnswer"]
    };
    for (let i = 0; i < this.newSectionControls.length; i++) {
      let control: SectionElement = {
        sectionElementContent: this.newSectionControls.controls[i].value[
          "sectionElementContent"
        ],
        sectionElementId: undefined,
        sectionElementType: this.newSectionControls.controls[i].value[
          "sectionElementType"
        ],
        sectionElementOptions: undefined,
        sectionElementOrder: i
      };
      if (
        this.newSectionControls.controls[i].value["sectionElementType"] ==
        SectionElementType.SELECTION_BUTTONS_SINGLE || this.newSectionControls.controls[i].value["sectionElementType"] ==
        SectionElementType.SELECTION_BUTTONS_MULTI
      ) {
        control.sectionElementOptions = ((this.newSectionControls.controls[
          i
        ] as FormGroup).controls[
          "sectionElementOptions"
        ] as FormArray).controls.map(x => x.value);
      }
      section.sectionControls.push(control);
    }
    return section;
  }

  changeMobilePreview(index) {
    this.selectedSection = index;
  }

  // EditSection

  editSection(event, index) {
    this.selectedSection = index;
    this.resetSectionElementTypes();
    this.selectedControlType.setValue(1);
    this.sectionToEdit = this.lessonSections[index];
    this.removeExtraSectionElementTypes(this.sectionToEdit);

    let sectionControlsEditForm = this.formBuilder.array(
      this.sectionToEdit.sectionControls.map(x => {
        let element = {
          sectionElementContent: this.formBuilder.control(x.sectionElementContent, Validators.required),
          sectionElementType: x.sectionElementType,
          sectionElementOptions: undefined,
          sectionElementImageName: undefined,
          sectionElementId: x.sectionElementId
        };
        if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI) {
          element.sectionElementOptions = this.formBuilder.array(
            x.sectionElementOptions,
            Validators.required
          );
        }
        return this.formBuilder.group(element, Validators.required);
      }),
    );

    this.sectionEditForm = this.formBuilder.group({
      sectionControls: sectionControlsEditForm,
      sectionTitle: this.formBuilder.control(
        this.sectionToEdit.sectionTitle,
        Validators.required
      ),
      sectionTag: this.formBuilder.control(
        this.sectionToEdit.sectionTag,
        Validators.required
      ),

      isChallenge: this.formBuilder.control(
        this.sectionToEdit.isChallenge,
        Validators.required
      ),

      showMentorAnswer: this.formBuilder.control(
        this.sectionToEdit.showMentorAnswer
      ),

      questionType: this.formBuilder.control(
        this.sectionToEdit.questionType,
        Validators.required
      ),

    });

  }

  onChange(deviceValue) {
    console.log(deviceValue);
  }

  deleteEditSectionOption(event, controlIndex, optionIndex) {
    (((this.sectionEditForm.controls.sectionControls as FormArray).controls[
      controlIndex
    ] as FormGroup).controls[
      "sectionElementOptions"
    ] as FormArray).controls.splice(optionIndex, 1);
    this.sectionEditForm.value.sectionControls[controlIndex].sectionElementOptions.splice(optionIndex, 1);
  }

  deleteEditSectionControl(event, index, controlType) {
    (this.sectionEditForm.controls
      .sectionControls as FormArray).controls.splice(index, 1);
    (this.sectionEditForm.controls.sectionControls as FormArray).value.splice(
      index,
      1
    );
    if (parseInt(controlType) == SectionElementType.TEXTBOX_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    } else if (parseInt(controlType) == SectionElementType.TEXTBOX_MULTI) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI));
    } else if (parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_MULTI || parseInt(controlType) == SectionElementType.SELECTION_BUTTONS_SINGLE) {
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE));
      this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? "" : this.sectionControlsTypes.push(this.allSectionControlTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI));
    }
  }

  addVideoToEdit(value, index) {
    if (value) {
      this.sectionEditForm.controls.sectionControls.value[index]['sectionElementContent'] = this.sanitize(value);
    }
  }

  sanitize(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  discardEditSection(event?) {
    this.sectionEditForm = undefined;
    this.sectionToEdit = undefined;
  }

  saveEditedSection(event, index) {
    this.sectionFormErrors = [];
    this.sectionEditForm.markAllAsTouched();
    if (!this.validateEditedSectionSave()) {
      this.sectionFormErrors.push("Atleast 1 input control is required")
    } else if (!this.validateEditedSectionOptionsSave()) {
      this.sectionFormErrors.push("Atleast one option is required")
    } else if (this.sectionEditForm.valid && this.sectionEditForm.value.sectionControls.length > 0) {
      this.lessonSections[index].isChallenge = this.sectionEditForm.value.isChallenge;
      this.lessonSections[index].showMentorAnswer = this.sectionEditForm.value.showMentorAnswer;
      this.lessonSections[index].sectionControls = this.sectionEditForm.value.sectionControls;
      this.lessonSections[index].sectionTag = this.sectionEditForm.value.sectionTag;
      this.lessonSections[index].sectionTitle = this.sectionEditForm.value.sectionTitle;
      this.lessonSections[index].questionType = parseInt(this.sectionEditForm.value.questionType);
      this.discardEditSection();
    }
  }

  addControlToSectionEdit() {
    let newControl = {
      sectionElementType: parseInt(this.selectedControlType.value),
      sectionElementContent: "",
      sectionElementOptions: undefined,
      sectionElementImageName: undefined,
      sectionElementId: undefined,
    };
    // if (
    //   this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_MULTI || this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_SINGLE
    // ) {

    // }
    switch (parseInt(this.selectedControlType.value)) {
      case SectionElementType.TEXTBOX_SINGLE:
        this.selectedControlType.setValue(SectionElementType.TEXTBOX_DESCRIPTION);
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.TEXTBOX_MULTI:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.SELECTION_BUTTONS_MULTI:
      case SectionElementType.SELECTION_BUTTONS_SINGLE:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        newControl.sectionElementOptions = this.formBuilder.array([]);
        break;
      default:
        break;
    }
    let newControlForm = this.formBuilder.group(newControl);
    this.setupControlFormValidations(newControlForm);
    (this.sectionEditForm.controls.sectionControls as FormArray).push(
      newControlForm
    );
  }

  changeQuestionType(event) {
    console.log("value", event.target.value);
  }

  addControlToSectionEdit2(data) {

    this.selectedControlType.setValue(data.sectionElementType);

    let newControl = {
      sectionElementType: parseInt(this.selectedControlType.value),
      sectionElementContent: "",
      sectionElementOptions: undefined,
      sectionElementImageName: undefined,
      sectionElementId: undefined,
    };
    // if (
    //   this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_MULTI || this.selectedControlType.value == SectionElementType.SELECTION_BUTTONS_SINGLE
    // ) {

    // }
    switch (parseInt(this.selectedControlType.value)) {
      case SectionElementType.TEXTBOX_SINGLE:
        this.selectedControlType.setValue(SectionElementType.TEXTBOX_DESCRIPTION);
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.TEXTBOX_MULTI:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
        break;
      case SectionElementType.SELECTION_BUTTONS_MULTI:
      case SectionElementType.SELECTION_BUTTONS_SINGLE:
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
        this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
        newControl.sectionElementOptions = this.formBuilder.array([]);
        break;
      default:
        break;
    }
    let newControlForm = this.formBuilder.group(newControl);
    this.setupControlFormValidations(newControlForm);
    (this.sectionEditForm.controls.sectionControls as FormArray).push(
      newControlForm
    );
  }

  pushExistingSectionControls(x) {

    let element = {
      sectionElementContent: this.formBuilder.control(x.sectionElementContent, Validators.required),
      sectionElementType: x.sectionElementType,
      sectionElementOptions: undefined,
      sectionElementImageName: undefined,
      sectionElementId: x.sectionElementId
    };
    if (x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI) {
      element.sectionElementOptions = this.formBuilder.array(
        x.sectionElementOptions,
        Validators.required
      );
    }

    let newControlForm = this.formBuilder.group(element, Validators.required);
    (this.sectionEditForm.controls.sectionControls as FormArray).push(
      newControlForm
    );
  }

  addElementOptionEdit(event, index) {
    (((this.sectionEditForm.controls.sectionControls as FormArray).controls[
      index
    ] as FormGroup).controls["sectionElementOptions"] as FormArray).push(
      this.formBuilder.control("", Validators.required)
    );
  }


  updateSection() {
    let length = this.lessonSections.length;
    for (var i = 0; i < length; i++) {
      this.lessonSections[i].questionType = Number(this.lessonSections[i].questionType);
      this.lessonSections[i].mentorAnswers = this.mentorAnswerList[i] ? this.mentorAnswerList[i].SectionAnswer : [];
      this.lessonSections[i].technicalAnswers = this.TechnialAnswer[i] ? this.TechnialAnswer[i].SectionAnswer : '';
    }
  }

  updateSelectedSection() {
    let length = this.lessonSections.length;
    for (let i = 0; i < length; i++) {
      this.lessonSections[i].questionType = Number(this.lessonSections[i].questionType);
      this.lessonSections[i].mentorAnswers = this.mentorAnswerList[i] ? this.mentorAnswerList[i].SectionAnswer : [];
      this.lessonSections[i].technicalAnswers = this.TechnialAnswer[i] ? this.TechnialAnswer[i].SectionAnswer : '';
      for (let j = 0; j < this.lessonSections[i].sectionControls.length; j++) {
        let newElement = this.lessonSections[i].sectionControls[j];
        if (newElement.sectionElementType === 5) {
          if (newElement.sectionElementContent) {
            if (newElement.sectionElementContent.changingThisBreaksApplicationSecurity) {
              this.lessonSections[i].sectionControls[j].sectionElementContent =
                this.lessonSections[i].sectionControls[j].sectionElementContent.changingThisBreaksApplicationSecurity;
            } else {
              this.lessonSections[i].sectionControls[j].sectionElementContent = newElement.sectionElementContent;
            }
          }
        }
      }
    }
  }


  addLessonToSkill(event) {

    if (!this.lessonForm.controls["levelTitle"].value) {
      this.lessonForm.controls["levelTitle"].setValue("Level " + this.levelNumber);
    }

    this.updateSection();
    this.lessonFormErrors = [];
    this.lessonForm.markAllAsTouched();
    this.selfReviewForm.markAllAsTouched();
    if (
      this.lessonForm.valid &&
      !this.sectionToEdit &&
      this.lessonSections.length > 0
    ) {
      this.lessonFormErrors = [];
      this.lessonDetails = {
        lessonCategoryId: this.lessonForm.value["lessonCategoryId"],
        title: this.lessonForm.value["title"],
        lessonSummary: this.lessonForm.value["lessonSummary"],
        lessonImage: this.lessonForm.value["lessonImage"],
        companyId: undefined,
        lessonId: undefined,
        level: this.lessonForm.value["level"],
        subLevel: this.lessonForm.value["subLevel"],
        sections: this.lessonSections,
        lessonTitle: this.lessonForm.value["title"],
        isTrial: this.lessonForm.value["isTrail"],
        levelTitle: this.lessonForm.value["levelTitle"],
        shLink: this.lessonForm.value["shLink"],
        appLink: this.lessonForm.value["appLink"],
        selfReviewQuestions: this.selfReviewForm.value.map(x => {
          let question: LessonReviewQuestion = {
            maxScale: 10,
            minScale: 0,
            question: x,
            questionId: undefined
          };
          return question;
        })
      };

      this.configsService.loading(true);
      this.lessonsService.addLesson(this.lessonDetails).subscribe(
        res => {
          this.configsService.loading(false);
          this.issuccessPopup = true;
          this.success_popup = {
            display: true, message: "Lesson added successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.issuccessPopup = false;

              this.lessonsService.getShortLinkForLesson(res.result.lessonId).subscribe((data) => {
                this.getLessons(res.result.lessonId);
              });

              this.lessonsService.getAppLinkForLesson(res.result.lessonCategoryId,
                res.result.level, res.result.lessonId).subscribe((data) => {
                  this.getLessons(res.result.lessonId);
                });

              //this.getLessons(res.result.lessonId);

            }
          }
        },
        err => {
          this.configsService.loading(false);
          this.lessonEditTab.nativeElement.scrollIntoView();
          this.lessonFormErrors.push("Something went wrong. Please try again");
        }
      );

    } else if (this.lessonSections.length === 0) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Atleast 1 section is required");
    } else if (this.sectionToEdit) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");

    }
  }

  showEmailInvitePopup(event) {
    this.lessonSections = this.lessonDetails.sections;
    this.updateSelectedSection();
    this.lessonDetails.sections = this.lessonSections;
    const dialogRef = this.dialog.open(InvitePopupComponent, {
      width: "70%",
      disableClose: true,
      data: {
        selectedLesson: this.lessonDetails
      }
    });
    return false;
  }

  showWhatsAppInvitePopup(event) {
    this.lessonSections = this.lessonDetails.sections;
    this.updateSelectedSection();
    this.lessonDetails.sections = this.lessonSections;
    const dialogRef = this.dialog.open(InvitePopupWhatsAppComponent, {
      width: "70%",
      disableClose: true,
      data: {
        selectedLesson: this.lessonDetails
      }
    });
    return false;
  }

  showLessonInviteStatusPopup(event) {
    this.lessonSections = this.lessonDetails.sections;
    this.updateSelectedSection();
    this.lessonDetails.sections = this.lessonSections;
    const dialogRef = this.dialog.open(LessonInviteStatusComponent, {
      width: "70%",
      disableClose: true,
      data: {
        selectedLesson: this.lessonDetails
      }
    });
    return false;
  }

  toggleInviteMenu() {
    document.getElementById("myDropdown").classList.toggle("show");
  }

  cancelLessonUpsert(ent) {

    this.route.params.subscribe(params => {
      let lessonCategory = params["categoryId"];
      let levelId = params["levelId"];
      if (levelId) {
        this.router.navigate(["lessons/" + lessonCategory + "/" + levelId + "/new"]);
      } else {
        this.router.navigate(["lessons/" + lessonCategory + "/new"]);
      }

    });

    //if (this.lessonDetails) {
    //  this.cancel.emit();
    //} else

  }

  changePreview(event, i) {
    this.previewChange.emit({ event, i });
  }

  dropSection(event) {
    let section = this.lessonDetails.sections[event.previousIndex];
    this.lessonDetails.sections.splice(event.previousIndex, 1);
    this.lessonDetails.sections.splice(event.currentIndex, 0, section);

    let technicalAnswer = this.TechnialAnswer[event.previousIndex];
    this.TechnialAnswer.splice(event.previousIndex, 1);
    this.TechnialAnswer.splice(event.currentIndex, 0, technicalAnswer);

    let mentorAnswer = this.mentorAnswerList[event.previousIndex];
    this.mentorAnswerList.splice(event.previousIndex, 1);
    this.mentorAnswerList.splice(event.currentIndex, 0, mentorAnswer);

    let filteredMentorAnswer = this.filterdMentorData[event.previousIndex];
    this.filterdMentorData.splice(event.previousIndex, 1);
    this.filterdMentorData.splice(event.currentIndex, 0, filteredMentorAnswer);
  }

  dropControl(event, sectionIndex) {
    let section = this.lessonSections[sectionIndex];
    let control = section.sectionControls[event.previousIndex];
    section.sectionControls.splice(event.previousIndex, 1);
    section.sectionControls.splice(event.currentIndex, 0, control);

    let editFormControl = this.sectionEditForm.value.sectionControls[event.previousIndex];
    this.sectionEditForm.value.sectionControls.splice(event.previousIndex, 1);
    this.sectionEditForm.value.sectionControls.splice(event.currentIndex, 0, editFormControl);
    this.sectionEditForm.controls.sectionControls.setValue(this.sectionEditForm.value.sectionControls);
  }

  updateLesson(event) {
    if (
      this.lessonForm.valid && !this.sectionToEdit && this.lessonSections.length > 0
    ) {

      this.lessonSections.forEach((section, index) => {
        section.sectionControls.forEach((control) => {
          if (control.sectionElementId == null) {
            control.sectionElementId = undefined;
          }
        });
        section.sectionOrder = index + 1;
      })

      this.updateSelectedSection();
      this.lessonFormErrors = [];
      this.lessonDetails = {
        lessonCategoryId: this.lessonForm.value["lessonCategoryId"],
        title: this.lessonForm.value["title"],
        lessonSummary: this.lessonForm.value["lessonSummary"],
        lessonImage: this.lessonForm.value["lessonImage"],
        companyId: undefined,
        lessonId: this.lessonForm.value["lessonId"],
        level: this.lessonForm.value["level"],
        subLevel: this.lessonForm.value["subLevel"],
        sections: this.lessonSections,
        lessonTitle: this.lessonForm.value["title"],
        isTrial: this.lessonForm.value["isTrail"],
        levelTitle: this.lessonForm.value["levelTitle"],
        shLink: this.lessonForm.value["shLink"],
        appLink: this.lessonForm.value["appLink"],
        selfReviewQuestions: this.selfReviewForm.value.map((x, index) => {
          let question: LessonReviewQuestion = {
            maxScale: 10,
            minScale: 0,
            question: x,
            questionId: this.lessonDetails.selfReviewQuestions[index].questionId
          };
          return question;
        })
      };

      this.configsService.loading(true);

      this.lessonsService.updateLessonDetails(this.lessonDetails).subscribe(
        res => {
          //this.cancelLessonUpsert();
          this.configsService.loading(false);
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: "Lesson updated successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.done.emit(res.result.lessonId);

              this.getLessons(res.result.lessonId);

              //var updateLessonContext = this;
              //setTimeout(function () {
              //  updateLessonContext.router.navigate(["lessons/" + updateLessonContext.categoryId + "/new"]);
              //}, 1000);

            },
          };
        },
        err => {
          this.configsService.loading(false);
          this.lessonEditTab.nativeElement.scrollIntoView();
          this.lessonFormErrors.push("Something went wrong. Please try again");
        }
      );
    } else if (this.lessonSections.length === 0) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Atleast 1 section is required");
    } else if (this.sectionToEdit) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    } else if (this.sectionForm) {
      this.lessonEditTab.nativeElement.scrollIntoView();
      this.lessonFormErrors.push("Save all sections first");
    }
  }

  uploadEditSectionImage(event, elementIndex) {
    let image = (<File>event.target.files[0]);
    if (image.size > 1000000) {
      this.sectionFormErrors.push("Size must be less than 1 MB.")
    } else if (!this.allowedFileTypes.some(type => image.type === type)) {
      this.sectionFormErrors.push("Unsupported image file type.")
    } else {
      image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });

      let images = [];
      images.push(image);

      this.lessonsService.uploadSectionImages(images).subscribe(res => {
        ((this.sectionEditForm.controls.sectionControls as FormArray).controls[elementIndex] as FormGroup).controls["sectionElementImageName"].setValue(image.name);
        ((this.sectionEditForm.controls.sectionControls as FormArray).controls[elementIndex] as FormGroup).controls["sectionElementContent"].setValue(this.imageStorageUrl + image.name);
      },
        err => {
          this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
        });
    }
  }

  editQuestionType(sectionIndex, value) {
    this.questionType = value;
    let lesson = this.previewLessonData;
    lesson.sections[sectionIndex].questionType = parseInt(this.questionType);
    this.previewLessonData = lesson

  }

  uploadSectionImage(event, elementIndex) {
    let image: File = event.target.files[0];
    if (image.size > 1000000) {
      this.sectionFormErrors.push("Size must be less than 1 MB.")
    } else if (!this.allowedFileTypes.some(type => image.type === type)) {
      this.sectionFormErrors.push("Unsupported image file type.")
    } else {
      image = new File([image], image.name.split('.')[0] + this.getTimestamp() + '.' + image.name.split('.')[1], { type: image.type });

      let images = [];
      images.push(image);

      this.lessonsService.uploadSectionImages(images).subscribe(res => {
        ((this.newSectionControls.controls[elementIndex] as FormGroup).controls.sectionElementImageName as FormControl).setValue(image.name);
        ((this.newSectionControls.controls[elementIndex] as FormGroup).controls.sectionElementContent as FormControl).setValue(this.imageStorageUrl + image.name);
      },
        err => {
          this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
        });
    }
  }

  getTimestamp() {
    let currentDate = new Date();
    return currentDate.getUTCFullYear().toString() + currentDate.getUTCMonth().toString() + currentDate.getUTCDate().toString()
      + currentDate.getUTCHours().toString() + currentDate.getUTCMinutes().toString() + currentDate.getUTCSeconds().toString();
  }

  deleteConfirmation(event, sectionIndex) {
    this.sectionDeleteDialog = this.dialog.open(DeleteConfirmationComponent, {
      width: "30%",
      disableClose: false
    });
    this.sectionDeleteDialog.afterClosed().subscribe(data => {
      if (data.confirm) {
        this.TechnialAnswer.splice(sectionIndex, 1);
        this.mentorAnswerList[sectionIndex].SectionAnswer = [];
        this.mentorAnswerList.splice(sectionIndex, 1);
        this.filterdMentorData.splice(sectionIndex, 1);
        this.lessonSections.splice(sectionIndex, 1);

      }
    })
  }

  deleteLesson(event) {
    this.sectionDeleteDialog = this.dialog.open(DeleteConfirmationComponent, {
      width: "30%",
      disableClose: false,
      data: {
        message: 'Lesson'
      }
    });
    this.sectionDeleteDialog.afterClosed().subscribe(data => {
      if (data.confirm) {
        let lessonId = this.lessonDetails.lessonId;
        this.lessonsService.deletelesson(lessonId).subscribe(
          res => {
            this.issuccessPopup = true;
            this.success_popup = {
              display: true, message: res.result,
              buttonPositive: "Okay",
              positiveButton: () => {
                this.success_popup.display = false;
                this.issuccessPopup = false;
                //this.cancelLessonUpsert(null);
                this.router.navigate(["lessons/" + this.categoryId + "/" + this.levelNumber + "/new"]);
                window.location.reload();
                //this.getLessons(lessonId);
              }
            }
          },
          err => {
            this.lessonEditTab.nativeElement.scrollIntoView();
            this.lessonFormErrors.push("Something went wrong. Please try again");
          }
        );
      }
    })
  }

  resetSectionElementTypes() {
    this.sectionControlsTypes = JSON.parse(JSON.stringify(this.allSectionControlTypes));
  }

  validateNewSectionSave() {
    return this.newSectionControls.length > 0 && this.newSectionControls.controls.some(x =>
      x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.value.sectionElementType == SectionElementType.TEXTBOX_MULTI || x.value.sectionElementType == SectionElementType.TEXTBOX_SINGLE
    );
  }

  validateNewSection() {
    var optionControls = this.newSectionControls.controls.filter(x => x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    var allOptiosnValid = optionControls.every(x => {
      return ((x as FormGroup).controls.sectionElementOptions as FormArray).controls.every(y => y.valid);
    })
    return allOptiosnValid;
  }

  validateNewSectionOptionsSave() {
    var optionsControls = this.newSectionControls.controls.filter(x => x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.value.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (optionsControls.length == 0)
      return true;
    else if (optionsControls.every(optionControl => {
      return ((optionControl as FormGroup).controls.sectionElementOptions as FormArray).length > 0
    }))
      return true;
    else
      return false;
  }

  validateEditedSectionSave() {
    return this.sectionEditForm.value.sectionControls.length > 0 && this.sectionEditForm.value.sectionControls.some(x =>
      x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE || x.sectionElementType == SectionElementType.TEXTBOX_MULTI || x.sectionElementType == SectionElementType.TEXTBOX_SINGLE
    );
  }

  validateEditedSectionOptionsSave() {
    var optionsControls = this.sectionEditForm.value.sectionControls.filter(x => x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (optionsControls.length == 0)
      return true;
    else if (optionsControls.every(optionControl => {
      return optionControl.sectionElementOptions.length > 0
    }))
      return true;
    else
      return false;
  }

  removeExtraSectionElementTypes(section: Section) {
    var sectionType = section.sectionControls.filter(x => x.sectionElementType == SectionElementType.TEXTBOX_SINGLE || x.sectionElementType == SectionElementType.TEXTBOX_MULTI
      || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_MULTI || x.sectionElementType == SectionElementType.SELECTION_BUTTONS_SINGLE);
    if (sectionType && sectionType.length > 0) {
      sectionType.forEach(x => {
        switch (x.sectionElementType) {
          case SectionElementType.TEXTBOX_SINGLE:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
            break;
          case SectionElementType.TEXTBOX_MULTI:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.SELECTION_BUTTONS_MULTI), 1) : "";
            break;
          case SectionElementType.SELECTION_BUTTONS_MULTI:
          case SectionElementType.SELECTION_BUTTONS_SINGLE:
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_SINGLE), 1) : "";
            this.sectionControlsTypes.find(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI) ? this.sectionControlsTypes.splice(this.sectionControlsTypes.findIndex(x => x.controlTypeId == SectionElementType.TEXTBOX_MULTI), 1) : "";
            break;
          default:
            break;
        }
        return;
      })
    }
  }

  getControlLabel(typeId: number) {
    var element = this.allSectionControlTypes.find(x => x.controlTypeId == typeId)
    return element.controlType;
  }

  showAnswerSection(value: number) {
    this.answerSection = value;

  }

  getMentorAnswer() {
    // this.lessonsService.getMentorAnswer().subscribe((data) => {
    //   if (data) {
    //     this.mentorData = data.result;
    //   }
    // })
  }

  updateTechnicalAnswer(editor, html, i) {
    this.TechnialAnswer[i].SectionAnswer = html;
  }

  //addTechnicalAnswer(i) {

  //  if (!this.TechnialAnswer[i].SectionAnswer) {
  //    this.TechnialAnswer[i].SectionAnswer = [];
  //  }
  //  this.TechnialAnswer[i].SectionAnswer.push(this.techAnswer);
  //  this.techAnswer = "";
  //}

  //deleteTechnicalAnswer(event, i, j) {
  //  this.TechnialAnswer[i].SectionAnswer.splice(j, 1);
  //}

  searchMentor(sectionIndex, searchString: string) {
    if (searchString) {

      searchString = searchString.toLowerCase();

      let filteredMentors = this.mentorData[sectionIndex].filter(
        m => m.name.toLowerCase().includes(searchString));

      this.filterdMentorData[sectionIndex] = filteredMentors.slice(0, 5);
    } else {

      this.filterdMentorData[sectionIndex] = [];

      let context = this;

      this.mentorData[sectionIndex].forEach(function (mentors) {

        context.filterdMentorData[sectionIndex].push(mentors);

        //context.mentorAnswerList[sectionIndex].
        //  SectionAnswer.forEach(function (lessonMentors) {
        //    if (lessonMentors.mentorId == mentors.mentorId) {
        //      context.filterdMentorData[sectionIndex].push(mentors);
        //    }
        //  });
      });

      //this.filterdMentorData[sectionIndex] = this.mentorAnswerList[sectionIndex].
      //  SectionAnswer;
    }
  }

  isMentorAdded(sectionIndex, mentorId) {
    let mentor = this.mentorAnswerList[sectionIndex].SectionAnswer.filter((m) => {
      if (m.mentorId == mentorId)
        return m;
    });

    if (mentor.length > 0) {
      return true;

    } else {
      return false;
    }
  }

  isMentorQuotes(quotes) {
    if (quotes === null) {
      return true;

    } else {
      return false;
    }
  }

  saveMentorAnswer(sectionIndex, mentorIndex) {

    this.mentorAnswerList[sectionIndex].SectionAnswer.push(this.filterdMentorData[sectionIndex][mentorIndex]);
    this.issuccessPopup = true;
    this.success_popup = {
      display: true, message: "Mentor answers added successfully",
      buttonPositive: "Okay",
      positiveButton: () => {
        this.success_popup.display = false;
        this.issuccessPopup = false;
      }
    }
  }

  removeMentorAnswer(sectionIndex, mentorIndex) {
    let mentor = this.filterdMentorData[sectionIndex][mentorIndex];

    let answers = this.mentorAnswerList[sectionIndex].SectionAnswer.filter((m) => {
      if (m.mentorId != mentor.mentorId)
        return m;
    })
    this.mentorAnswerList[sectionIndex].SectionAnswer = answers;

    // if (!this.mentorSearch) {
    //   this.filterdMentorData[sectionIndex] = this.mentorAnswerList[sectionIndex].
    //     SectionAnswer;
    // }

    this.mentorFlag = sectionIndex;

    this.issuccessPopup = true;
    this.success_popup = {
      display: true, message: "Mentor answers remove successfully",
      buttonPositive: "Okay",
      positiveButton: () => {
        this.success_popup.display = false;
        this.issuccessPopup = false;
      }
    }
  }

  importlesson(value) {
    this.importLessonFlag = value;
  }

  previewClose(value) {
    this.importLessonFlag = !value;
    this.previewLessonFlag = value;
  }

  copyToClipboard() {
    let item = this.lessonDetails.shLink;
    let item1 = this.lessonDetails.appLink;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = item;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  copyAppLinkToClipboard() {
    let item = this.lessonDetails.appLink;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = item;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  actionClick(mentorId, sectionId, lessonId, mentorName, sectionName, mentorIndex) {
    this.answerEditMentorName = mentorName;
    this.mentorId = mentorId;
    this.mentorIndex = mentorIndex;
    this.mentorService.mentorLessonAnswer(mentorId, lessonId).subscribe((data) => {
      if (data) {
        this.editMentorAnswersList = data.result.candidateSectionAnswers.filter(x => x.sectionId === sectionId)[0];
        this.mentorAnswerDisplay = true;
        this.answerEditMentorSection = sectionName;
      }
    });
  }

  closeModel(ent) {
    this.editMentorAnswersList = {};
    this.mentorAnswerDisplay = false;
    this.answerEditMentorName = "";
    this.answerEditMentorSection = "";
    this.mentorId = "";
    this.mentorSectionAnswerEdit = "";
    this.isEdit = false;
  }

  editAnswer(sectionElementIndex) {
    this.isEdit = true;
    this.selectedElementAnswer = sectionElementIndex;
    this.mentorSectionAnswerEdit = this.editMentorAnswersList.sectionElementAnswers[sectionElementIndex].answer;
  }

  saveMentorAnswers(sectionElementIndex) {
    let updateAnswer = {
      candidateId: this.mentorId,
      lessonId: this.lessonDetails.lessonId,
      sectionElementAnswer: {
        answer: this.mentorSectionAnswerEdit,
        sectionElementId: this.editMentorAnswersList.sectionElementAnswers[sectionElementIndex].sectionElementId
      }
    }
    this.editMentorAnswersList.sectionElementAnswers[sectionElementIndex].answer = this.mentorSectionAnswerEdit;


    this.isEdit = false;
    this.configsService.loading(true);
    this.mentorService.updateAnswer(updateAnswer).subscribe((data) => {
      if (data.statusCode === 200) {
        this.configsService.loading(false);
        // this.mentorAnswerDisplay = false;
        this.issuccessPopup = true;
        this.success_popup = {
          display: true,
          message: "Answer updated successfully",
          buttonPositive: "Okay",
          positiveButton: () => {
            this.success_popup.display = false;
            this.issuccessPopup = false;

            this.getMentorForLesson(this.lessonDetails);
          }
        }
      }
    },
      (err) => {
        this.configsService.loading(false);
        this.isErrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      })
  }

  copySkillAppLinkToClipboard() {
    let item = this.lessonCategoryDetails.appLink;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = item;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  removeFile() {
    this.lessonForm.patchValue({
      lessonImage: ''
    });
  }

  uploadFile(event) {
    //console.log(this.uploadedFiles);
    // this.customLearningService.uploadCustomLearningImage(event.files[0]).subscribe(res => {
    //   this.customLearningForm.patchValue({
    //     image: res
    //   });
    // });

    let image = (<File>event.files[0]);
    image = new File([image], (new Date().getTime().toString()) + "_" + this.getRandomInt(111111, 999999) + "_" + this.getRandomInt(111111, 999999), { type: image.type });

    let images = [];
    images.push(image);

    this.isUploadingLessonImage = true;

    this.lessonsService.uploadSectionImages(images).subscribe(res => {
      this.isUploadingLessonImage = false;
      this.lessonForm.patchValue({
        lessonImage: this.imageStorageUrl + image.name
      });
    },
      err => {
        this.isUploadingLessonImage = false;
        this.lessonFormErrors.push("Something went wrong while uploading images. Please retry uploading images.");
      });
  }

  dummyFile(): File {
    let parts = [
      new Blob(['you construct a file...'], { type: 'text/plain' }),
      ' Same way as you do with blob',
      new Uint16Array([33])
    ];

    return new File(parts, 'sample.jpg', { type: "image/jpg" });
  }


  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getDiscussionsForLesson(lesson: any) {
    this.discussionStore = [];
    for (let i = 0; i < lesson.sections.length; i++) {
      this.lessonsService.getAllComments(lesson.sections[i].sectionId, lesson.lessonId).subscribe((data) => {
        let sectionComments = [];
        data.forEach(comment => {
          const { subThreads, ...withoutSubThread } = comment;
          withoutSubThread.isReply = false;
          sectionComments.push(withoutSubThread);
          if (comment.subThreads.length > 0) {
            comment.subThreads.forEach(reply => {
              reply.isReply = true;
              sectionComments.push(reply);
            });
          }
        });
        this.discussionStore[i] = sectionComments;
        this.discussions[i] = this.discussionStore[i];
      });
    }
  }

  // searchComment(sectionIndex, searchString: string) {
  //   if (searchString) {
  //     searchString = searchString.toLowerCase();
  //     let filteredComments = this.discussionStore[sectionIndex].filter(
  //       c => c.author.toLowerCase().includes(searchString) || c.comment.toLowerCase().includes(searchString));
  //     this.discussions[sectionIndex] = filteredComments.slice(0, 5);
  //   } else {
  //     this.discussions[sectionIndex] = this.discussionStore[sectionIndex];
  //   }
  // }
}