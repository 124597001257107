import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges,ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-user-answer-card',
  templateUrl: './user-answer-card.component.html',
  styleUrls: ['./user-answer-card.component.less']
})
export class UserAnswerCardComponent implements OnInit, OnChanges {
  readMore : boolean = false;
  answerClass: boolean = false;
  constructor() { }
  readMoreFlag : boolean = false;
  readMoreText ="Read More";
  @Input("user") user;
  @ViewChild('answerBox', {static: true}) answerBox: ElementRef;
 
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges){
    if(changes.user){
      this.user= changes.user.currentValue;
    }
  }
  ngAfterViewInit() {
    this.heightAnswerBox();
  }
  heightAnswerBox(){
    if(this.answerBox.nativeElement.offsetHeight > 272){
      this.readMoreFlag = true;
    }
    else{
      this.readMoreFlag = false;
    }
    this.answerClass = true;
  }
  readMoreHandle(){
    this.readMore =  !this.readMore;
    if(this.readMore === true){
      this.readMoreText = "Show less";
    }
    else{
      this.readMoreText = "Read More";
    }
  }
}
