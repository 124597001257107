import { Component, OnInit } from "@angular/core";
import { UsersService } from "src/app/services/users.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-task-select",
  templateUrl: "./task-select.component.html",
  styleUrls: ["./task-select.component.less"]
})
export class TaskSelectComponent implements OnInit {
  userName: string;

  constructor(public usersService: UsersService, private router: Router) { }

  ngOnInit() {
    this.usersService.isMeasure
      = this.usersService.isUsers
      = this.usersService.isDevelop
      = this.usersService.isMentor
      = this.usersService.isCommunication
      = false;
    this.usersService.isAdminDashboard = true;

    this.usersService.getUserDetails(this.usersService.currentUser.userId).subscribe(res => {
      this.userName = res.result.firstName + " " + res.result.lastName;
    });
  }

  goToCompanies(event) {
    this.usersService.isAdminDashboard = false;
    this.router.navigate(["/companies"]);
  }

  goToUserActivity(event) {
    this.usersService.isMeasure = true;
    this.router.navigate(["/activity/report"]);
  }

  goToLessons(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = true;
    this.router.navigate(["/lessons/library"]);
  }
  goToMentorActivity(event) {
    this.usersService.isDevelop = false;
    this.usersService.isMentor = true;
    this.usersService.isMeasure = false;
    this.router.navigate(["/mentors"]);
  }

  goToUsersActivity(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = false;
    this.usersService.isAdminDashboard = false;
    this.usersService.isUsers = true;
    this.router.navigate(["/users"]);
  }

  goToCompany(event) {
    this.usersService.isAdminDashboard = false;
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = false;
    this.usersService.isUsers = true;
    this.router.navigate(["/company"]);
  }

  goToGoals(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = true;
    this.router.navigate(["/goals"]);
  }

  goToGoalsLibrary(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = true;
    this.router.navigate(["/goalsLibrary"]);
  }

  goToGroups(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = false;
    this.usersService.isUsers = true;
    this.router.navigate(["/groups"]);
  }

  goToEvents(event) {
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isDevelop = true;
    this.router.navigate(["/events"]);
  }

  goToCommunication(event) {
    this.usersService.isDevelop = false;
    this.usersService.isMentor = false;
    this.usersService.isMeasure = false;
    this.usersService.isCommunication = true;
    this.router.navigate(["/communication"]);
  }
}
