import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  baseUrl: string = appSettings.mentorsUrl +  "MentorRequest";
  constructor(private http: HttpClient) { }

  getRequestList(
    pageNumber ?: number,
    status?,
    searchString?) {
    let url =  this.baseUrl + "?PageNumber="+pageNumber+ "&PageSize=10";
    if(status){
      url +="&Status="+status;
    }
    if(searchString){
      url +="&SearchText="+searchString;
    }
    return this.http.get<any>(url,{

    });
  }


  exportAllMentorRequests(filterString, status) {
    var url = appSettings.mentorsUrl + "ExportAllMentorRequests?SearchText=" + (filterString ? filterString : '') + "&status=" + (status ? status : '')

    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType : 'blob'
    })
  }

  sendRemark(remark){
    let url =  this.baseUrl + "";
    return this.http.put<any>(url, remark);
  }
}
