import {AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, Input} from '@angular/core';
import {AssignMentorService} from 'src/app/services/assign-mentor.service';
import {ConfigsService} from 'src/app/services/configs.service';
import {UsersService} from "../../services/users.service";
import {CommonService} from "../../services/common.service";
import {ToastService} from "../../services/toast-service";

@Component({
  selector: 'app-assign-role',
  templateUrl: './assign-role.component.html',
  styleUrls: ['./assign-role.component.less']
})
export class AssignRoleComponent implements OnInit {

  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @Output() mentorClose: EventEmitter<any> = new EventEmitter();
  @ViewChild('arc', {static: false}) paginator: any;
  title = "Assign Role";
  userHover: number = null;
  users = [];
  newUsers = []
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };
  queryParam: any = [];
  query;
  url;
  onApiCall = false;

  constructor(private assignMentorService: AssignMentorService, private commonService: CommonService,
              private configsService: ConfigsService, private userService: UsersService, private toastService: ToastService) {
    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })
  }

  userRole = {
    label: "",
    key: "level",
    placeholder: "Select",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "",
  }

  ngOnInit() {
    this.getUser();
    this.getAllRoles();
  }

  getUser() {

    this.configsService.loading(true);
    this.userService.getRolesForUsers('', this.pageLimit, this.pageNumber, this.searchString ? encodeURIComponent(this.searchString.trim()) : '').subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.results && data.result.results.length > 0) {
            this.users = data.result.results;
            this.totalCount = data.result.totalCount;

            let context = this;

            this.users.forEach(function (item) {
              item.roleDetails = {name: item.roleName, value: item.userRole}
            });

          } else {
            this.users = data.result.results;
            this.totalCount = data.result.totalCount;
          }
          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  getAllRoles() {
    this.queryParam["PageNumber"] = 1;
    this.queryParam["PageSize"] = 1000;
    this.configsService.loading(true);
    this.url = "Roles/GetRoles";
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.commonService.getListDataGridNew(this.url, this.query).subscribe(
      (data) => {
        this.userRole.options = data.result.data.map(function (item) {
          return {name: item.roleName, value: item.roleId}
        });
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  closeModel(ent) {
    this.isDisplay = false;
    this.mentorClose.emit();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getUser();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  mouseEnter(value) {
    this.userHover = value;
  }

  mouseleave() {
    this.userHover = null;
  }

  filterData(params) {
    this.searchString = params[0];
    this.paginator.changePage(0);
  }

  selecteChange(user, index) {
    console.log(user);

    this.configsService.loading(true);

    this.userService.changeRole(user.userId, user.roleDetails.value).subscribe(
      (data) => {
        this.configsService.loading(false);
        if (data.hasError) {
          this.showToast("bg-danger", data.errors[0]);
          return;
        }
        this.showToast("bg-success", "Role Assigned Successfully.");
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );

  }

  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d.userId === user.userId); //find index in your array

    if (index >= 0) {
      this.newUsers.splice(index, 1);//remove element from array
    } else {
      this.newUsers.push({userId: user.userId, MentorType: 1})
    }
  }


  saveMentor() {
    console.log(this.userRole);
  }

  showToast(type, message) {
    let toast = this.toastService.show(message, {classname: type + ' toast-custom text-light', delay: 5000});
    let context = this;
    setTimeout(function () {
      context.toastService.remove(toast);
    }, 5000)
  }
}
