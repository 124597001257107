import {Component, OnInit} from '@angular/core';
import {GleacColumnDef} from '../data-grid/data-grid.model';
import {ReportService} from 'src/app/services/report.service';
import {ConfigsService} from '../../services/configs.service';
import {FormControl, FormGroup} from '@angular/forms';
import {UsersService} from "../../services/users.service";
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-mini-benchmark-report',
  templateUrl: './mini-benchmark-report.component.html',
  styleUrls: ['./mini-benchmark-report.component.less']
})
export class MiniBenchmarkReportComponent implements OnInit {
  page_title = "Mini Benchmark Report";
  today = new Date();
  loading: boolean = false;
  GridData: any;
  totalCount: number;
  reportCSVData: any;
  ColumnData: GleacColumnDef[] = [
    {data: "firstName", displayName: "First Name"},
    {data: "lastName", displayName: "Last Name"},
    {data: "email", displayName: "Email"},
    {data: "jobRole", displayName: "Job Role"},
    {data: "socialDesirabilityInPercentageScore", displayName: "Social Desirability (%)"},
    {data: "readinessInPercentageScore", displayName: "Readiness (%)"},
    {
      data: "createdOn", displayName: "Created On", render: (data, row) => {
        if (row.createdOn != "0001-01-01T00:00:00") {
          var date = new Date(row.createdOn);
          return date.getDate() + "/"
            + (date.getMonth() + 1) + "/"
            + date.getFullYear() + ", "
            + (date.getHours() % 12 ? ((date.getHours() % 12) < 10 ? "0" + (date.getHours() % 12) : (date.getHours() % 12)) : 12) + ":"
            + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " "
            + (date.getHours() >= 12 ? "PM" : "AM");
        } else {
          return "-";
        }
      }
    }
  ];

  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    search: new FormControl()
  });

  constructor(private reportService: ReportService, private configsService: ConfigsService, public usersService: UsersService) {
    this.configsService.loading(true);
    this.reportService.getMiniBenchMarkReport(null, null, null).subscribe(data => {
      let minData = {
        list: data.result.reportUI.item1,
        totalCount: data.result.reportUI.item2
      }

      this.GridData = minData;

      this.reportCSVData = data.result.reportCSV;
    }, err => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  ngOnInit() {
  }

  pageChange(event) {
    this.reportService.getMiniBenchMarkReport(this.filterForm.controls.search.value, this.filterForm.controls.fromDate.value, this.filterForm.controls.toDate.value, event).subscribe(data => {
      let minData = {
        list: data.result.reportUI.item1,
        totalCount: data.result.reportUI.item2
      }

      this.GridData = minData;

      this.reportCSVData = data.result.reportCSV;
    }, err => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  filterData($event) {
    this.configsService.loading(true);
    this.reportService.getMiniBenchMarkReport(this.filterForm.controls.search.value, this.filterForm.controls.fromDate.value, this.filterForm.controls.toDate.value, 1).subscribe(data => {

      let minData = {
        list: data.result.reportUI.item1,
        totalCount: data.result.reportUI.item2
      }

      this.GridData = minData;

      this.reportCSVData = data.result.reportCSV;
    }, err => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  resetSearch() {
    this.filterForm.reset();
    this.reportService.getMiniBenchMarkReport("", this.filterForm.controls.fromDate.value, this.filterForm.controls.toDate.value, 1).subscribe(data => {

      let minData = {
        list: data.result.reportUI.item1,
        totalCount: data.result.reportUI.item2
      }

      this.GridData = minData;

      this.reportCSVData = data.result.reportCSV;
    }, err => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    });
  }

  downloadReport() {

    this.configsService.loading(true);
    this.reportService
      .getMiniBenchMarkReportExport(this.filterForm.controls.search.value, this.filterForm.controls.fromDate.value, this.filterForm.controls.toDate.value, 1)
      .subscribe(
        data => saveAs(data, "MiniBenchmarkReport" + '.xlsx'),
        (err) => {
          this.configsService.loading(false);
          //this.iserrorPopup = true;
          //this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  headerStyling(text: string) {
    let arr = text.split(/(?=[A-Z])/);
    arr[0] = arr[0][0].toLocaleUpperCase() + arr[0].substring(1, arr[0].length);
    let returnText = arr.join(' ');
    return returnText;
  }

  formateDate(date: string) {
    date = date + ' UTC';
    let currentDate = new Date(date.replace(/-/g, "/"));
    return currentDate.getDate() + "/"
      + (currentDate.getMonth() + 1) + "/"
      + currentDate.getFullYear() + " "
      + (currentDate.getHours() % 12 ? ((currentDate.getHours() % 12) < 10 ? "0" + (currentDate.getHours() % 12) : (currentDate.getHours() % 12)) : 12) + ":"
      + (currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes()) + " "
      + (currentDate.getHours() >= 12 ? "PM" : "AM");
  }

  convertToCSV(objArray, headerList) {
    let array =
      typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No, ';
    for (let index in headerList) {
      let text = this.headerStyling(headerList[index] + '');
      row += text + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        let cellText = '';
        if (head == 'createdOn')
          cellText = array[i][head] == '-' ? '-' : this.formateDate(array[i][head]);
        else
          cellText = array[i][head];
        line += ',' + cellText;
      }
      str += line + '\r\n';
    }
    return str;
  }
}
