import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { timer } from 'rxjs'

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.less']
})
export class TimerComponent implements OnInit {

  @Input() startTime: number;
  timerInstance = timer(0, 1000);
  timerPaused: boolean = false;
  @Output() timerEnd: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    var timerEvent = this.timerInstance.subscribe(val => {
      if (!this.timerPaused) {
        if (this.startTime > 0) {
          this.startTime = this.startTime - 1;
        }
        else {
          this.timerEnd.emit();
          timerEvent.unsubscribe();
        }
      }
    });
  }

  pause() {
    this.timerPaused = true;
  }
}
