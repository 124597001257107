import {AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ConfigsService} from 'src/app/services/configs.service';
import {AssignUserToCompanyService} from 'src/app/services/assign-user-to-company.service';
import {data, param} from 'jquery';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-assign-user-to-company',
  templateUrl: './assign-user-to-company.component.html',
  styleUrls: ['./assign-user-to-company.component.less']
})
export class AssignUserToCompanyComponent implements OnInit {
  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @Output() userClose: EventEmitter<any> = new EventEmitter();
  @ViewChild('auc', {static: false}) paginator: any;

  title = "Users";
  userHover: number = null;
  users = [];
  newUsers = [];
  assignUsers = {};
  companyId;
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };

  onApiCall = false;

  constructor(private configsService: ConfigsService, private assignUserToCompanyService: AssignUserToCompanyService, protected route: ActivatedRoute) {
    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })
  }

  ngOnInit() {
    this.newUsers = [];
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
    });

    this.getUser();
  }

  getUser() {
    this.configsService.loading(true);
    this.assignUserToCompanyService.getUser(this.pageLimit, this.pageNumber, this.searchString ? encodeURIComponent(this.searchString) : '').subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.results.length > 0) {
            this.users = data.result.results;

            let context = this;
            this.users.forEach(function (item) {
              let index = context.newUsers.findIndex(d => d === item.userId); //find index in your array

              item.isMentor = item.companyId == context.companyId;
              item.isNewMentor = item.companyId == context.companyId || index >= 0;
            });

            this.totalCount = data.result.totalCount;
          } else {
            this.users = data.result.results;
            this.totalCount = data.result.totalCount;
          }

          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  /*getCompanyUser(){
    this.configsService.loading(true);
    this.assignUserToCompanyService.getCompanyUser(this.pageLimit,this.pageNumber, this.companyId).subscribe((data)=>{
      if(data.statusCode === 200){
        if(data.result.results.length){
          this.users = data.result.results;

          this.users.forEach(function (item)
          {
            item.isNewMentor = item.isMentor ? true : false;
          });

          this.totalCount = data.result.totalCount;
        }
        this.configsService.loading(false)
      }
      if(data.statusCode === 500){
          this.configsService.loading(false);
          this.iserrorPopup = true;
        this.error_popup = { display: true, message: "Server is not responding" };
      }
    },
    (err) => {
      this.configsService.loading(false);
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: err.error.errors[0] };
    },
    () => {
      this.configsService.loading(false);
    })
  }*/

  closeModel(ent) {
    this.isDisplay = false;
    this.userClose.emit();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getUser();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  mouseEnter(value) {
    this.userHover = value;
  }

  mouseleave() {
    this.userHover = null;
  }

  filterData(params) {
    this.searchString = params[0];
    this.paginator.changePage(0);
    //this.pageNumber = 1;
    //this.getUser();
  }

  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d === user.userId); //find index in your array

    if (index >= 0) {
      this.newUsers.splice(index, 1);//remove element from array
    } else {
      this.newUsers.push(user.userId);
    }
  }

  assignUser() {
    this.assignUsers = Object.assign({userIds: this.newUsers, companyId: this.companyId});

    this.configsService.loading(true);
    this.assignUserToCompanyService.assignUser(this.assignUsers).subscribe((data) => {
        this.configsService.loading(false);
        this.isDisplay = false;
        this.issuccessPopup = true;
        this.success_popup = {
          display: true,
          message: "Saved successfully",
          buttonPositive: "Okay",
          positiveButton: () => {
            this.success_popup.display = false;
            this.issuccessPopup = false;
            this.userClose.emit();
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      })
  }
}
