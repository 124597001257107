import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { ConfigsService } from "../../services/configs.service";
import { CommonService } from "../../services/common.service";

@Component({
  selector: 'app-communication-layout',
  templateUrl: './communication-layout.component.html',
  styleUrls: ['./communication-layout.component.less']
})
export class CommunicationLayoutComponent implements OnInit {
  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    private usersService: UsersService
  ) {
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
  }
}
