import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { CommonService } from 'src/app/services/common.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { from } from 'rxjs';
import { appSettings } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { GoalsService } from 'src/app/services/goal.service';
import { GoalsPopupComponent } from 'src/app/dialogs/goal-popup/goals-popup.component';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

import { saveAs } from 'file-saver';


@Component({
  selector: 'app-goalsLibrary',
  templateUrl: './goals-library.component.html',
  styleUrls: ['./goals-library.component.less']
})
export class GoalsLibraryComponent implements OnInit {
  pageUrl: string = "goalsLibrary";
  page_title = "Goals Library";
  searchString: string = "";
  pageNumber: number = 1;
  pageSize: number = 10;
  isEdit: boolean;
  error_popup = {};
  currentSection = 1;
  url = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  goalsLibraryGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  goals: any;
  sortColumn: string = "";
  sortOrder: string = "";
  goalPopup: MatDialogRef<GoalsPopupComponent>;
  deleteRequest: any = {};

  create_popup = {
    display: false,
    title: "",
    field: [],
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any) {
    }
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ],
    }
  ];

  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    public usersService: UsersService,
    private goalService: GoalsService,
    protected dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.usersService.isDevelop = true;
    this.getGoalsForLibraryList();
  }

  goalsLibraryColumnData: GleacColumnDef[] = [
    {
      displayName: "Title",
      data: "title",
      render: (data, row) => {
        return `
            <span class="float-left" style="width:80px;">${row.title ? row.title : '-'}</span>`;
      }
    },
    {
      displayName: "Task",
      disableSort: true,
      data: "tasks",
      isTasksToPerform: true,
      render: (data, row) => {
        let tasksToDisplay = ""

        if (row.tasks && row.tasks.length > 0) {
          // row.tasks.sort(function(a,b) {
          //   if (a.task_name !== null && b.task_name !== null) {
          //     return a.task_name.localeCompare(b.task_name, 'en', { numeric: true })
          //   }
          // });

          row.tasks.forEach(function (item) {
            tasksToDisplay = tasksToDisplay + ", " + item.task_name;
          });
        }

        tasksToDisplay = tasksToDisplay.trim();
        tasksToDisplay = tasksToDisplay.substring(1);

        let task = (row.tasks && row.tasks.length > 0) ? tasksToDisplay : ''
        return `<span class="float-left" style="height: 17px; width:100px; overflow: hidden" >${task}</span>`;
      }
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Goal_Add'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Goal_Add'
        },
        {
          label: "Assign Goal",
          url: "#",
          functionType: "assign",
          image: "assets/images/add_icon_goals.svg",
          permission: 'Goal_Add'
        }
      ]
    }
  ];

  getGoalsForLibraryList() {
    this.configsService.loading(true);
    this.goalService.getGoalsForLibrary(
      this.pageNumber,
      this.pageSize
    ).subscribe(
      (res) => {
        if (res) {

          if (res.result.results.length == 0 && this.pageNumber > 1) {
            this.pageNumber--
            this.getGoalsForLibraryList();
            return;
          }

          let goalsData = {
            list: res.result.results,
            totalCount: res.result.total_count
          }
          this.goalsLibraryGridData = goalsData;
        } else {
          this.goalsLibraryGridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getSearchResults(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    this.configsService.loading(true);
    this.goalService.searchGoalsLibrary(
      encodeURIComponent(searchString), pageNumber, pageSize, sortColumn, sortOrder).subscribe(
        (res) => {
          if (res) {
            let goalsData = {
              list: res.result.results,
              totalCount: res.result.total_count
            }
            this.goalsLibraryGridData = goalsData;
          } else {
            this.goalsLibraryGridData = [];
          }
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  searchData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, '', '');
  }

  pageChange(event) {
    this.pageNumber = event;

    if ((this.searchString !== undefined && this.searchString !== '') || (this.sortColumn !== undefined && this.sortColumn !== '')) {
      this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);
    }
    else {
      this.getGoalsForLibraryList();
    }
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.goalService.exportGoalsLibrary(
      encodeURIComponent(this.searchString), this.sortColumn, this.sortOrder).subscribe(
      (res) => {
        saveAs(res, "Goals" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.searchString = this.searchString;

    this.getSearchResults(this.searchString, this.pageNumber, this.pageSize, this.sortColumn, this.sortOrder);

  }

  assignGoal(event) {
    console.log("assign goal call.::::", event);
    console.log("row.::::", event.data);
    this.goalPopup = this.dialog.open(GoalsPopupComponent, {
      disableClose: true,
      width: "50%",
      height: "auto",
      data: {
        goalLibraryId: event.data.goal_library_id
      }
    });
  }

  editGoal(event) {
    console.log("edit goal call.::::", event);
    console.log("row.::::", event.data);

    let taskData = event.data.tasks;
    let tasksForEdit = [];

    if (taskData && taskData.length > 0) {
      taskData.forEach(function (item) {
        let taskAdd = { task_id: "", task_name: "", task_url: "", action_type: "", cta_name: "" };

        taskAdd.task_id = item.task_id;
        taskAdd.task_name = item.task_name;
        taskAdd.task_url = item.task_url;
        taskAdd.action_type = item.action_type;
        taskAdd.cta_name = item.c_t_a_name;

        tasksForEdit.push(taskAdd);
      });
    }

    this.goalPopup = this.dialog.open(GoalsPopupComponent, {
      disableClose: true,
      width: "50%",
      height: "auto",
      data: {
        goalLibraryId: event.data.goal_library_id,
        isEdit: true,
        title: event.data.title,
        image: event.data.image,
        tasks: tasksForEdit
      }
    });

    this.goalPopup.beforeClosed().subscribe(result => {
      this.pageNumber = 1;
      this.getGoalsForLibraryList();
    });
  }

  deleteGoal(event) {
    console.log(event.data);
    this.deleteRequest.goal_id = event.data.goal_library_id;
    this.deleteRequest.Source = "Library";

    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Goals Library",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.goalService.deleteGoal(this.deleteRequest).subscribe(
          (data) => {
            if (data.has_error) {
              this.showToast(true, data.errors[0]);
              return;
            }
            else {
              this.showToast(false, "Goal Deleted Successfully!");
            }
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: err.error.errors[0],
            };
          },
          () => {
            this.configsService.loading(false);
            this.pageNumber = 1;
            this.getGoalsForLibraryList();
          }
        );
        this.confirm.display = false;
      }
    };
  }

  actionClick(event) {
    if (event.action === "assign") {
      console.log("Assign Goal Event");
      this.assignGoal(event);
    }
    else if (event.action === "delete") {
      console.log("Delete Goal Event");
      this.deleteGoal(event);
    }
    else {
      console.log("Edit Goal Event");
      this.editGoal(event);
    }
  }

  showToast(isError, message) {
    if (isError) {
      this.iserrorPopup = true;
      this.error_popup = { display: true, message: message };
      this.iscreatePopup = true;
    }
    else {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.iscreatePopup = false;
          this.success_popup.display = false;
        },
      };
    }
  }
}
