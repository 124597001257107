import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class AssignUserToCompanyService {
    baseUrl: string = appSettings.authUrl;
  
    constructor(private http : HttpClient) { }
    
    getUser(
      pageLimit ?: number,
      pageNumber ?: number,
      filterString="") {
      let url = this.baseUrl + "Users?PageNumber="+pageNumber+
      "&PageSize="+pageLimit+"&filterString="+filterString;
      return this.http.get<any>(url,{
        headers : new HttpHeaders({
          "x-api-version" : "2"
        })
      });
    }

    assignUser(assignUserRequest : object){
      let url = this.baseUrl + "Users/assign-user-to-company";
      return this.http.post<any>(url, assignUserRequest);
    }

    getCompanyUser(pageLimit ?: number,
      pageNumber ?: number,
      filterString="",
      companyId=""){
        let url = this.baseUrl + "Users?PageNumber=" + pageNumber + "&PageSize=" + pageLimit + "&filterString=" + filterString + "&companyId=" + companyId;
        //let url = this.baseUrl + "Users/companyusers";
      return this.http.get<any>(url,{
        headers : new HttpHeaders({
          "x-api-version" : "2"
        })
      });
    }
  }