import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { ConfigsService } from 'src/app/services/configs.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { MentorService } from 'src/app/services/mentor.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { MentorBookingsService } from "../../services/mentor-bookings.service";


@Component({
  selector: 'app-mentors-booking',
  templateUrl: './mentor-bookings.component.html',
  styleUrls: ['./mentor-bookings.component.less']
})
export class MentorBookingsComponent implements OnInit {

  pageUrl: string = "mentor/bookings";
  page_title = "Mentor Bookings";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  sortColumn: string = '';
  sortOrder: string = '';
  status: number;

  gridData: any;

  updateMeetingFlag = false;

  meeting: any;

  isconfirmPopup = false;
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  iscreatePopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };

  create_popup = {
    display: false,
    title: "",
    createrName: '',
    isUpdate: false,
    field: [],
    bulk_upload: false,
    bulkInfo: {},
    buttonPositive: "",
    refreshData: function () {
    },
    closeModal: function () {
    },
    createrCall: function (form: any, data) {
    },
  };

  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "Pending", value: "booked" },
        { name: "Accepted", value: "Accepted" },
        { name: "Reschedule", value: "Reschedule" },
        { name: "Completed", value: 2 },
      ],
    }
  ];

  constructor(
    private configsService: ConfigsService,
    protected router: Router,
    public usersService: UsersService,
    private mentorBookings: MentorBookingsService,
    private mentorService: MentorService,
    private datepipe: DatePipe,
  ) {
    this.getBookings();
  }

  ngOnInit() {
    this.usersService.isMentor = true;
  }

  nftColumnData: GleacColumnDef[] = [
    {
      displayName: "Booker Name",
      data: "bookerName",
      isExpandable: true,
    },
    {
      displayName: "Booker Email",
      data: "bookerEmail",
    },
    {
      displayName: "Mentors",
      data: "Mentors",
      disableSort: true,
      render: (data, row) => {
        return row.mentors ? row.mentors.join(", ") : "";
      }
    },
    {
      displayName: "Date",
      data: "bookingDateTime",
      render: (data, row) => {
        return this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${row.bookingDateTime}`)), 'MMM d, y HH:mm');
      }
    },
    {
      displayName: "Coupon",
      data: "coupon",
    },
    {
      displayName: "Source",
      data: "source",
    },
    {
      displayName: "Status",
      data: "status",
      disableSort: true,
      render: (data, row) => {

        let status = '';

        if (row.status == "booked") {
          status = "Pending";
        } else if (row.status == "Accepted") {
          status = "Accepted";
        } else if (row.status == "Reschedule") {
          status = "Reschedule";
        } else {
          status = "Completed";
        }

        if (row.status === "Accepted") {
          return '<div style="display: flex;align-items:center"><span class="dot gleac-badge-text success mr-2"></span><span>' + status + '</span></div>';
        }
        if (row.status === "Reschedule") {
          return '<div style="display: flex;align-items:center"><span class="dot gleac-badge-text ongoing mr-2"></span><span>' + status + '</span></div>';
        }
        return '<div style="display: flex;align-items:center"><span class="dot gleac-badge-text danger mr-2"></span><span>' + status + '</span></div>';
      }
    },
    {
      displayName: "Created At",
      data: "createdAt",
      isDate: true
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      cssClass: true,
      dropDownLabels: [
        {
          label: "Confirm",
          url: "#",
          functionType: "confirm",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Send Reminder",
          url: "#",
          functionType: "reminder",
          image: "assets/images/edit_icon.svg",
        },
        {
          label: "Enter Earnings",
          url: "#",
          functionType: "earning",
          image: "assets/images/edit_icon.svg",
        }
      ],
    },
  ];

  getBookings() {
    this.configsService.loading(true);
    this.mentorBookings.getBookings(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder,
      this.status
    ).subscribe(
      (res) => {
        if (res) {
          this.gridData = {
            list: res.result.data,
            totalCount: res.result.totalCount
          };
          //this.mentors = res.result.data;
        } else {
          this.gridData = [];
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getBookings();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getBookings();
  }

  sortChange(event) {
    this.pageNumber = 1;
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.getBookings()
  }

  customFilter(params) {
    this.pageNumber = 1;
    if (params[1] && params[1].form && params[1].form.value && params[1].form.value.status != undefined) {
      this.status = params[1].form.value.status;
    } else {
      this.status = undefined;
    }
    this.getBookings();
  }

  addNftBundle(data: any) {
    this.updateMeetingFlag = true;
    this.meeting = data;
  }

  onClose() {
    this.updateMeetingFlag = false;
    this.pageNumber = 1;
    this.getBookings();
  }

  sendReminder(bookingId) {
    this.configsService.loading(true);
    this.mentorBookings.sendRemainder(bookingId).subscribe((res) => {
      this.configsService.loading(false);
      this.reminderStatus(res);
    }, error => {
      this.configsService.loading(false);
    }, () => {
      this.configsService.loading(false);
    })
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.mentorBookings.exportBookings(1,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder,
      this.status
    ).subscribe(
      (res) => {
        if (res) {
          saveAs(res, "NFT Mentor Bookings" + '.xlsx');
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  actionClick(event) {
    if (event.action === "confirm") {

      //let st = this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${event.data.date} ${event.data.startTime}`)), 'HH:mm');
      let st = this.datepipe.transform(this.convertUTCDateToLocalDate(new Date(`${event.data.bookingDateTime}`)), 'HH:mm');

      this.iscreatePopup = true;
      this.create_popup = {
        display: true,
        bulk_upload: false,
        bulkInfo: null,
        createrName: "updateBooking",
        title: "Confirm Booking",
        buttonPositive: "Update",
        isUpdate: true,
        field: [
          {
            label: "Booker Name",
            key: "bookerName",
            placeholder: "Enter the Event name",
            type: "input",
            innertype: "text",
            pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
            required: "required",
            cssClass: 'col-12',
            maxlength: 1000,
            value: event.data.bookerName,
            disabled: true
          },
          {
            label: "Booker Email",
            key: "bookerEmail",
            placeholder: "Enter the Event name",
            type: "input",
            innertype: "text",
            pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
            required: "required",
            cssClass: 'col-12',
            maxlength: 1000,
            value: event.data.bookerEmail,
            disabled: true
          },
          {
            label: "Mentors",
            key: "mentorName",
            placeholder: "Enter the Event name",
            type: "input",
            innertype: "text",
            pattern: "^[a-zA-Z]+(?:\\s+[a-zA-Z]+)*$",
            required: "required",
            cssClass: 'col-12',
            maxlength: 1000,
            value: event.data.mentors.join(", "),
            disabled: true
          },
          {
            label: "Scheduled Date",
            key: "date",
            type: "date_picker_date",
            cssClass: 'col-12',
            value: this.convertUTCDateToLocalDate(new Date(`${event.data.bookingDateTime}`)),
            required: "required",
          },
          {
            label: "Start Time",
            key: "startTime",
            type: "time_picker_start",
            required: "required",
            cssClass: 'col-12',
            value: {
              hour: Number(st.split(':')[0]),
              minute: Number(st.split(':')[1])
            }
          }
        ],
        refreshData: () => {
          this.getBookings();
        },
        closeModal: () => {
          this.create_popup.display = false;
          this.iscreatePopup = false;
        },
        createrCall: (form: NgForm, responseData) => {
          if (form.valid) {
            let ListData: any = [];
            ListData = { ...form.value };
            console.log(ListData);

            let startTime = new Date(`${ListData.date.getMonth() + 1}/${ListData.date.getDate()}/${ListData.date.getFullYear()} ${ListData.startTime.hour}:${ListData.startTime.minute}:00`);

            let request: any = {};
            request.bookingId = event.data.id;
            request.date = startTime.toISOString();

            console.log(request);

            this.configsService.loading(true);

            this.mentorBookings
              .updateBooking(request)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          }
        },
      };
    } else if (event.action === "reminder") {
      this.sendReminder(event.data.id);
    } else if (event.action === "earning") {
      this.iscreatePopup = true;
      this.create_popup = {
        display: true,
        bulk_upload: false,
        bulkInfo: null,
        createrName: "updateEarning",
        title: "Enter Earnings",
        buttonPositive: "Update",
        isUpdate: true,
        field: [
          event.data.earnings && {
            label: "Previously Assigned Earnings",
            key: "previousAmount",
            type: "input",
            innertype: "text",
            required: "required",
            cssClass: 'col-12',
            value: `$${event.data.earnings}`,
            disabled: true
          },
          {
            label: "Mentor Earnings For This Booking (in USD)",
            key: "earningsAmount",
            placeholder: "Amount in USD",
            type: "input",
            innertype: "number",
            pattern: "^[0-9]{1,7}(\\.[0-9]{1,2})?$",
            required: "required",
            cssClass: 'col-12',
            value: null
          }
        ].filter(Boolean),
        refreshData: () => {
          this.getBookings();
        },
        closeModal: () => {
          this.create_popup.display = false;
          this.iscreatePopup = false;
        },
        createrCall: (form: NgForm, responseData) => {
          if (form.valid) {
            let ListData: any = [];
            ListData = { ...form.value };

            let request: any = {
              bookingId: event.data.id,
              amount: ListData.earningsAmount
            };

            this.configsService.loading(true);

            this.mentorService.updateMentorEarnings(request)
              .subscribe(
                (data) => {
                  this.postStatus(data);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = {
                    display: true,
                    message: err.error.errors[0],
                  };
                },
                () => {
                  this.configsService.loading(false);
                  form.reset();
                  this.iscreatePopup = false;
                }
              );
          }
        },
      };
    }
  }


  postStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: "Updated Successfully",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getBookings();
        },
      };
    } else {
      this.iserrorPopup = true;
    }
  }

  reminderStatus(result) {
    if (!result.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: "Reminder Sent Successfully",
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.pageNumber = 1;
          this.getBookings();
        },
      };
    } else {
      this.iserrorPopup = true;
    }
  }

  convertUTCDateToLocalDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }
}

