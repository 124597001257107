import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class DesktopGuardService implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
      let url = activatedRoute.url[1].path;
        let lessonId: string = activatedRoute.params["id"];
        let preauth: string = activatedRoute.params["preauth"];
        if (!environment.isMobile) {
            if (preauth != undefined)
                this.router.navigate([url +"/" + lessonId], { queryParams: { preauth: activatedRoute.queryParams.preauth } });
            else
                this.router.navigate([url +"/" + lessonId]);
        }
        return true;
    }
}
