import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { SkillTags, SkillType } from '../common/common.model';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { from } from 'rxjs';
import { appSettings } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { ChallengeService } from 'src/app/services/challenge.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-challenge-list',
  templateUrl: './challenge-list.component.html',
  styleUrls: ['./challenge-list.component.less']
})
export class ChallengeListComponent implements OnInit {

  pageUrl: string = "lessons";
  page_title = "Challenges";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  error_popup = {};
  url = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  challengeListGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  status = "";
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () { },
    negativeButton: function () { },
  };
  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "Open", value: "1" },
        { name: "Accepted", value: "2" },
        { name: "Ongoing", value: "3" },
        { name: "Completed", value: "4" },
        { name: "Expired", value: "5" },
        { name: "Decliened", value: "6" }
      ],
    }
  ];
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    cssClass: '',
    createrName: '',
    buttonPositive: "",
    field: [],
    closeModal: function () { },
    createrCall: function (form, skillData, uploadFileName) { },
  };
  imageStorageUrl: string = appSettings.imageStoreUrl;
  currentTime: Date;
  skillTypeOptions = [];
  skillTagsOptions = [];
  constructor(
    private lessonsService: LessonsService,
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    private usersService: UsersService,
    private challengeService: ChallengeService
  ) {
  }

  ngOnInit() {
    this.usersService.isMentor = true;
    this.getChallengeList();
  }
  challengeColumnDate: GleacColumnDef[] = [
    {
      displayName: "Skill",
      data: "skill",
      isSkillAction: true,
      render: (data, row) => {
        return `<div style='float:left'><span>${row.skill}</span></div>`
      }
    },
    {
      displayName: "Challenger",
      data: "challenger"
    },
    {
      displayName: "Challenge",
      data: "challenge"
    },
    {
      displayName: "Score",
      data: "score",
      info: true,
      render: (data, row) => {
        let challenger = row.score.challenger, challenge = row.score.challenge;

        return `<span ><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8327 11.375V10.2917C10.8327 9.71703 10.6044 9.16593 10.1981 8.7596C9.79175 8.35327 9.24065 8.125 8.66602 8.125H4.33268C3.75805 8.125 3.20695 8.35327 2.80062 8.7596C2.39429 9.16593 2.16602 9.71703 2.16602 10.2917V11.375" stroke="#0065AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.50065 5.95833C7.69727 5.95833 8.66732 4.98828 8.66732 3.79167C8.66732 2.59505 7.69727 1.625 6.50065 1.625C5.30403 1.625 4.33398 2.59505 4.33398 3.79167C4.33398 4.98828 5.30403 5.95833 6.50065 5.95833Z" stroke="#0065AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> <span class="${challenger > challenge ? 'text-success' : 'text-danger'}" style="font-weight:600;font-size:13px;margin:0 20.33px 0 8.3px;
       ">${challenger}</span>

        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8327 11.375V10.2917C10.8327 9.71703 10.6044 9.16593 10.1981 8.7596C9.79175 8.35327 9.24065 8.125 8.66602 8.125H4.33268C3.75805 8.125 3.20695 8.35327 2.80062 8.7596C2.39429 9.16593 2.16602 9.71703 2.16602 10.2917V11.375" stroke="#2E384D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.50065 5.95833C7.69727 5.95833 8.66732 4.98828 8.66732 3.79167C8.66732 2.59505 7.69727 1.625 6.50065 1.625C5.30403 1.625 4.33398 2.59505 4.33398 3.79167C4.33398 4.98828 5.30403 5.95833 6.50065 5.95833Z" stroke="#2E384D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg><span  style="font-weight:600;font-size:13px;margin:0 0 0 8.3px;"
        class="${challenger > challenge ? 'text-danger' : 'text-success'}" >${challenge}</span>
        </span>`
      }
    },
    {
      displayName: "Date",
      data: "date",
      isDate: true
    },
    {
      data: "status",
      displayName: "Status",

      render: (data, row) => {
        if (row.status === 1) {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span>Open</span></div>'
          );
        }
        else if (row.status === 2) {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span> Accepted</span></div>'
          );
        }
        else if (row.status === 3) {
          return (
            '<div><span class="dot gleac-badge-text ongoing mr-2"></span><span> Ongoing</span></div>'
          );
        }
        else if (row.status === 4) {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span> Completed</span></div>'
          );
        }
        else if (row.status === 5) {
          return (
            '<div><span class="dot gleac-badge-text expired mr-2"></span><span> Expired</span></div>'
          );
        }
        else if (row.status === 6) {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span> Declined</span></div>'
          );
        }
      },
    },
    {
      displayName: "Notify User",
      isNotify: true,
      disableSort: true
    },
  ];

  exportToExcel() {
    this.configsService.loading(true);
    this.challengeService.exportChallenges(true,
      encodeURIComponent(this.searchString), this.status ? this.status : '').subscribe(
        (res) => {
          saveAs(res, "Challenges" + '.xlsx');
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  reArrangeChallenge(challenge) {
    let newChallengeList = challenge.map((item, key) => {
      return {
        challengeId: item.challenge.challangeId,
        skill: item.challenge.skillName,
        challenger: item.challenge.userChallengeBy,
        challenge: item.challenge.userChallengeTo,
        score: {
          challenger: item.challengeByVotes,
          challenge: item.challengeToVotes,
        },
        status: item.challenge.status,
        date: item.challenge.expiryDate
      }
    })
    return newChallengeList;
  }
  getChallengeList() {
    this.configsService.loading(true);
    this.challengeService.getAllChallenge(
      this.pageNumber,
      encodeURIComponent(this.searchString),
      this.status
    ).subscribe(
      (res) => {
        if (res) {
          if (res) {
            let challengeList = this.reArrangeChallenge(res.result.data);
            let challenge = {
              list: challengeList,
              totalCount: res.result.totalRecords
            }
            this.challengeListGridData = challenge;
          }
          else {
            this.challengeListGridData = [];
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }


  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getChallengeList();
  }
  customFilter(params) {
    let queryParam;
    let query;
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        queryParam = data[key];
      }
    } else {
      queryParam = params[0];
    }
    this.status = queryParam;
    this.pageNumber = 1;
    this.getChallengeList();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getChallengeList();
  }
  actionClick(event) {
    if (event.action === "edit") {
    }
    else if (event.action === "delete") {
    }
  }



  lessonDetails(event) {
    console.log("event", event);
    let route = "lessons/" +
      event.data.lessonCategoryId +
      "/new";
    if (event.data.skillType === 4) {
      route = "challenge/" +
        event.data.lessonCategoryId + "/new";
    }
    this.router.navigate([route]);
  }



}
