import { Component, OnInit, Injectable } from "@angular/core";
import { CandidateChallengeComponent } from "../candidate-challenge/candidate-challenge.component";
import { FormBuilder } from "@angular/forms";
import { CandidateChallengeService } from "src/app/services/candidate-challenge.service";
import { CandidateLessonService } from 'src/app/services/candidate-lesson.service';
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { SeoService } from "src/app/services/seo.service";
import { ConfigsService } from "../../services/configs.service";

import { MatDialog } from "@angular/material";
import { LessonsService } from "src/app/services/lessons.service";
import { LoginPopupComponent } from "src/app/dialogs/login-popup/login-popup.component";
import { LocationStrategy } from "@angular/common";

@Component({
  selector: "app-candidate-challenge-mobile",
  templateUrl: "./candidate-challenge-mobile.component.html",
  styleUrls: ["./candidate-challenge-mobile.component.less"],
})
@Injectable({
  providedIn: "root",
})
export class CandidateChallengeMobileComponent extends CandidateChallengeComponent
  implements OnInit {
  finishPage: boolean = false;
  issuccessPopup = false;
  success_popup = {
    display: false,
    message: "",
  };

  constructor(
    configsService: ConfigsService,
    candidateLessonService: CandidateLessonService,
    sanitizer: DomSanitizer,
    route: ActivatedRoute,
    usersService: UsersService,
    router: Router,
    formBuilder: FormBuilder,
    dialog: MatDialog,
    meta: Meta,
    skillService: LessonsService,
    protected seo: SeoService,
    private location: LocationStrategy,
    protected candidateChallengeService: CandidateChallengeService,
  ) {
    super(
      configsService,
      candidateLessonService,
      sanitizer,
      route,
      usersService,
      router,
      formBuilder,
      dialog,
      meta,
      skillService,
      seo,
      candidateChallengeService
    );
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }
  finishLesson() {
    this.addUserAnswer();
    this.lessonFinished = true;
    this.showLoader = true;
    this.lessonTimer.pause();
    let accessToken = localStorage.getItem("GATk");

    if (this.usersService.currentUser  === undefined || accessToken === null) {
      this.loginPopup = this.dialog.open(LoginPopupComponent, {
        disableClose: true,
        data: {
          dataKey: this.timeRanOut
        }
      });
      this.loginPopup.afterClosed().subscribe(data => {
        this.candidateAnswer.userEmail = this.userEmailId;
        this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
        this.candidateAnswer.LessonCategoryId = this.candidateLesson.challengeSkillId;
        this.candidateAnswer.companyId = this.usersService.currentUser.company;
        this.candidateAnswer.lessonTimeInSeconds = this.candidateLesson.challengeDuration*60;
        this.candidateChallengeService.insertChallengeAnswer(this.candidateAnswer).subscribe(res => {
          this.showLoader = false;
          if (res.hasError) {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: res.errors[0],

            }
            this.answerPageErrors = res.errors;
            this.showLoader = false;
            this.configsService.loading(false);
          }
          else {
            this.configsService.loading(false);
            this.issuccessPopup = true;
            this.success_popup ={
              display : true,
              message : "Thank you for taking GLEAC challenge",
            }
          }

        },
          err => {
            this.showLoader = false;
            this.configsService.loading(false);
          });
      });
    }
    else {
      this.configsService.loading(true);
      this.candidateAnswer.userEmail = this.userEmailId;
      this.candidateAnswer.candidateId = this.usersService.currentUser.userId;
      this.candidateAnswer.LessonCategoryId = this.candidateLesson.challengeSkillId;
      this.candidateAnswer.companyId = this.usersService.currentUser.company;
      this.candidateAnswer.lessonTimeInSeconds = this.candidateLesson.challengeDuration*60;
      this.candidateChallengeService.insertChallengeAnswer(this.candidateAnswer).subscribe(res => {
        this.showLoader = false;
        if (res.hasError) {
          this.showLoader = false;
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {
            display: true,
            message: res.errors[0],
          }
          this.answerPageErrors = res.errors;
          this.showLoader = false;
          this.configsService.loading(false);
        }
        else {
          this.configsService.loading(false);
          this.issuccessPopup = true;
          this.success_popup ={
            display : true,
            message : "Thank you for taking GLEAC challenge",
          }
        }
      },
        err => {
          this.showLoader = false;
          this.configsService.loading(false);
        });
    }
  }
}
