import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { GleacColumnDef } from 'src/app/components/data-grid/data-grid.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { SearchMessagesResponse } from "src/app/models/whatsapp/search-messages-response";
import { WhatsAppService } from "src/app/services/whatsapp.service";

@Component({
    selector: "lesson-invite-status-popup",
    templateUrl: "./lesson-invite-status.component.html",
    styleUrls: ["./lesson-invite-status.component.less"]
})
export class LessonInviteStatusComponent {
    popupTab = ['Email', 'WhatsApp'];
    emailLogGridData: any;
    phoneNumberLogGridData: any;
    searchEmailLogControl = new FormControl();
    searchPhoneNumberLogControl = new FormControl();
    filteredEmailLog: string[] = [];
    filteredPhoneNumberLog: SearchMessagesResponse[];
    emailStatus = '';
    selectedLesson: any;
    selectedTab = this.popupTab[0];
    emailLogColumnData: GleacColumnDef[] = [
        {
            displayName: "S. No.", data: "sequenceNumber"
        },
        { displayName: "Email", data: "email" },
        { displayName: "Skill", data: "skill" },
        { displayName: "Lesson", data: "lesson" },
        {
            displayName: "Email Sent On", data: "emailSentOn", render: (data, row) => {
                if (row.emailSentOn == '0001-01-01T00:00:00') {
                    return "-";
                } else {
                    var mailSentDateTime = new Date(row.emailSentOn);
                    return `${mailSentDateTime.getDate()}/${mailSentDateTime.getMonth() + 1}/${mailSentDateTime.getFullYear()}`;
                }
            }
        },
        {
            displayName: "Email Sent", data: "status"
        }
    ];
    phoneNumberLogColumnData: GleacColumnDef[] = [
        {
            displayName: "#", data: "sequenceNumber"
        },
        { displayName: "Phone Number", data: "phoneNumber" },
        { displayName: "Body", data: "body" },
        {
            displayName: "Sent On", data: "sentDateTimeObject"
        },
        {
            displayName: "Status", data: "status", tooltipData: "errorText"
        }
    ];

    constructor(
        private lessonsService: LessonsService, private configService: ConfigsService, private whatsAppService: WhatsAppService, private dialogRef: MatDialogRef<LessonInviteStatusComponent>, @Inject(MAT_DIALOG_DATA) data
    ) {
        this.selectedLesson = data.selectedLesson;
    }

    ngOnInit() {
        this.getEmailLog(true);
    }

    getLog(selectedTab) {
        if (selectedTab === this.popupTab[0]) {
            this.getEmailLog(true);
        } else {
            this.getPhoneNumberLog(true);
        }
    }

    searchEmailLog($event) {
        if (this.searchEmailLogControl.value != null && this.searchEmailLogControl.value.trim().length > 0) {
            this.filteredEmailLog = this.emailLogGridData.filter(log => log.email.toLocaleLowerCase().includes(this.searchEmailLogControl.value.toLocaleLowerCase())
                || log.lesson.toLocaleLowerCase().includes(this.searchEmailLogControl.value.toLocaleLowerCase()));
        } else {
            this.filteredEmailLog = this.emailLogGridData;
        }
    }

    closeModal(data?) {
        this.dialogRef.close(data);
    }

    searchPhoneNumberLog($event) {
        const searchValue = this.searchPhoneNumberLogControl.value;
        if (searchValue && searchValue.trim().length > 0) {
            const lowerCaseSearchValue = searchValue.toLowerCase();
            this.filteredPhoneNumberLog = this.phoneNumberLogGridData.filter(log => log.phoneNumber.includes(searchValue) || log.body.toLowerCase().includes(lowerCaseSearchValue));
        }
        else {
            this.filteredPhoneNumberLog = this.phoneNumberLogGridData;
        }
    }

    getEmailLog(history: boolean) {
        if (history) {
            this.configService.loading(true);
            this.lessonsService.getEmailLog()
                .subscribe(res => {
                    this.filteredEmailLog = res.result;
                    this.emailLogGridData = res.result;
                    this.configService.loading(false);
                });
        }
    }

    getPhoneNumberLog(history: boolean) {
        if (history) {
            this.whatsAppService.searchMessages(this.selectedLesson.lessonId).subscribe(response => {
                let indexedResponse = response.map((message, index) => {
                    let sentDateTime = new Date(message.sentDateTime);
                    return {
                        ...message,
                        sequenceNumber: index + 1,
                        sentDateTimeObject: sentDateTime.toLocaleString()
                    };
                });
                this.filteredPhoneNumberLog = indexedResponse;
                this.phoneNumberLogGridData = indexedResponse;
            });
        }
    }
}