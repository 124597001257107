import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.less']
})
export class DeleteConfirmationComponent implements OnInit {
  message : String ='Section';
  constructor(public dialogRef: MatDialogRef<DeleteConfirmationComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.message = data && data.message ? data.message :this.message;
   }

  ngOnInit() {
  }

  closeModal(event) {
    this.dialogRef.close({
      confirm: false
    });
  }

  deleteSection(event) {
    this.dialogRef.close({
      confirm: true
    });
  }
}
