import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../services/common.service";
import { GleacColumnDef } from '../data-grid/data-grid.model';
import { ReportService } from 'src/app/services/report.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AnswerPopupComponent } from 'src/app/dialogs/answer-popup/answer-popup.component';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from '../../services/configs.service';
import { CandidateLessonService } from '../../services/candidate-lesson.service';
import { Filter } from 'src/app/components/user-activity-report/user-activity-report.model';
import { UserType } from "../common/common.model";

@Component({
  selector: 'app-readiness-report',
  templateUrl: './readiness-report.component.html',
  styleUrls: ['./readiness-report.component.less']
})
export class ReadinessReportComponent implements OnInit {
  error_popup = { display: false };
  loading: boolean = false;
  today = new Date();
  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    search: new FormControl(),
    //organization: new FormControl(),
    //campaign: new FormControl(),
  });
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    { data: "rank", displayName: "Rank" },
    { data: "userName", displayName: "User Name" },
    { data: "email", displayName: "Email" },
    { data: "lessonCount", displayName: "Completed Lessons", render: (data, row) => { return row.lessonCount > 0 ? row.lessonCount : "N/A"; } },
    { data: "timeSpent", displayName: "Time Spent" },
    { data: "averageScore", displayName: "Avg. Score", render: (data, row) => { return row.averageScore > 0 ? row.averageScore : "N/A"; } },
    { data: "cpdCredits", displayName: "CPD Credits", isHTMLPart: true, HTMLPart: "<a data-toggle='modal' data-target='#cpdModel'>CPD Credits&nbsp;<i style='cursor: pointer' class='fa fa-inf0-circle' aria-hidden='true'></i></a>", render: (data, row) => { return row.cpdCredits > 0 ? row.cpdCredits : "N/A"; } },
    { data: "rankPoint", displayName: "Lessons > 60%" },
    // { data: "rankPoint", displayName: "Lessons > 60%", isHTMLPart: true, HTMLPart: "<a data-toggle='modal' data-target='#rankModel'>Lessons > 60%&nbsp;<i style='cursor: pointer' class='fa fa-inf0-circle' aria-hidden='true'></i></a>", render: (data, row) => { return row.rankPoint > 0 ? row.rankPoint : "N/A"; } },
  ];
  constructor(private configsService: ConfigsService,
    private reportService: ReportService,
    private dialog: MatDialog,
    public userService: UsersService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private lesson: CandidateLessonService,
    private router: Router) { }

  ngOnInit() {
    this.userService.isMeasure = true;
    if (this.userService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.userService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
      return;
    }
    this.configsService.loading(true);
    this.reportService.getReadinessReport(null, null, null).subscribe(
      (data) => {
        this.transactionsGridData = data.result;
        this.configsService.loading(false);
      },
      (err) => {
        this.configsService.loading(false);
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }
  filterData(dataFilter) {
    if (this.userService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.userService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
      return;
    }
    this.configsService.loading(true);
    let filter = dataFilter;
    var data: Filter = {
      fromDate: filter.fromDate,
      toDate: filter.toDate,
      search: encodeURIComponent(filter.search ? filter.search.trim() : ''),
      campaign: [],
      organization: ""
    };
    if (data.fromDate !== null && data.toDate !== null) {
      let fromDate = new Date(data.fromDate);
      let toDate = new Date(data.toDate);
      // if (fromDate.getTime() == toDate.getTime()) {
      //
      //   fromDate.setHours(0);
      //   fromDate.setMinutes(0);
      //   fromDate.setSeconds(0);
      //
      //   toDate.setHours(0);
      //   toDate.setMinutes(0);
      //   toDate.setSeconds(0);
      //   toDate.setDate(toDate.getDate() + 1);
      //
      //   data.toDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
      //   data.fromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
      // }
      // else {
      //   data.toDate = toDate.getFullYear() + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
      //   data.fromDate = fromDate.getFullYear() + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
      // }
    }
    this.reportService.getReadinessReport(encodeURIComponent(data.search), data.fromDate.toDateString(), data.toDate.toDateString()).subscribe(
      (data) => {
        this.transactionsGridData = data.result;
        this.configsService.loading(false);
      },
      (err) => {
        this.configsService.loading(false);
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  downloadReport() {
    if (this.transactionsGridData === undefined) return;
    let keys = Object.keys(this.transactionsGridData[0]);
    let csvData = this.convertToCSV(this.transactionsGridData, keys, this.transactionsColumnData);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;'
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "readinessReport.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCSV(objArray, headerList, headerText) {
    let array =
      typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerText) {
      let text = this.headerStyling(headerText[index]["displayName"] + '');
      row += text + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ",";
      }
      str += line + '\r\n';
    }
    return str;
  }

  headerStyling(text: string) {
    let arr = text.split(/(?=[A-Z])/);
    arr[0] = arr[0][0].toLocaleUpperCase() + arr[0].substring(1, arr[0].length);
    let returnText = arr.join('');
    return returnText;
  }

  showAnswer(event) {

  }
}
