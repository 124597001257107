import { Component, Inject, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators } from "@angular/forms";
import { GleacColumnDef } from 'src/app/components/data-grid/data-grid.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { WhatsAppService } from 'src/app/services/whatsapp.service';
import { SendWhatsAppMessageRequest } from "src/app/models/whatsapp/send-whatsapp-message-request";
import { ScheduleWhatsAppMessageRequest } from "src/app/models/whatsapp/schedule-whatsapp-message-request";
import { SearchMessagesResponse } from "src/app/models/whatsapp/search-messages-response";

@Component({
  selector: "app-invite-popup-whatsapp",
  templateUrl: "./invite-popup-whatsapp.component.html",
  styleUrls: ["./invite-popup-whatsapp.component.less"]
})
export class InvitePopupWhatsAppComponent {
  toggled: boolean = false;
  phoneNumberList: string[] = [];
  phoneNumber: FormControl = new FormControl();
  search: FormControl = new FormControl();
  searchLog: FormControl = new FormControl();
  emailTemplate: FormControl = new FormControl();
  usageCount: FormControl = new FormControl(1, [Validators.required])
  emailSubject: FormControl = new FormControl();
  templateName = new FormControl();
  selectedLesson: any;
  popupTab: string[] = ['Enter Phone Numbers', 'Select Template', 'Status'];
  selectedTab = this.popupTab[0];
  csvRecords: any[] = [];
  status: string = "";
  phoneNumberError: string = "";
  csvError: string = "";
  phoneNumberDuplicateError: string = "";
  @ViewChild('fileImportInput', { static: false }) fileImportInput: ElementRef;
  phoneNumbers: string[] = [];
  filteredPhoneNumbers: string[] = [];
  filteredPhoneNumberLog: SearchMessagesResponse[];
  sendOrScheduleButtonDisabled = false;
  inviteDetails: {
    phoneNumbers: string[],
    phoneNumberContent: string
  };
  phoneNumberLogGridData: any;
  phoneNumberLogColumnData: GleacColumnDef[] = [
    {
      displayName: "#", data: "sequenceNumber"
    },
    { displayName: "Phone Number", data: "phoneNumber" },
    { displayName: "Body", data: "body" },
    {
      displayName: "Sent On", data: "sentDateTimeObject"
    },
    {
      displayName: "Status", data: "status", tooltipData: "errorText"
    }
  ];
  challenge: boolean = false;
  approvedTemplates: any;
  templateBody = '';
  imageUploadLabel = 'Click here to select image';
  selectedTemplate: any;
  selectedTemplateHasImage = false;
  parameters = new FormControl();
  parametersClass = 'col-md-12';
  isTemplateParameterized = false;
  isScheduled = new FormControl();
  scheduleYear = new FormControl();
  scheduleMonth = new FormControl();
  scheduleDay = new FormControl();
  scheduleHour = new FormControl();
  scheduleMinute = new FormControl();
  years = [];
  months = [];
  days = [];
  hours = [];
  minutes = [];
  sendOrScheduleButtonText = 'Send';
  imageUrl = new FormControl();
  iserrorPopup = false;
  issuccessPopup = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  error_popup = {};

  constructor(
    private lessonsService: LessonsService, public dialogRef: MatDialogRef<InvitePopupWhatsAppComponent>, @Inject(MAT_DIALOG_DATA) data, public configService: ConfigsService, public whatsAppService: WhatsAppService
  ) {
    this.selectedLesson = data.selectedLesson;
    this.challenge = data.challenge;
  }

  ngOnInit() {
    this.templateName.setValue("(Select Template)");
    this.getAllTemplates();
    let today = new Date();
    let currentYear = today.getFullYear();
    this.years = [currentYear, currentYear + 1];
    this.scheduleYear.setValue(currentYear);
    let date = new Date();
    for (let i = 0; i < 12; i++) {
      date.setMonth(i);
      this.months.push({ index: i, name: date.toLocaleString('default', { month: 'long' }) });
    }
    this.scheduleMonth.setValue(today.getMonth());
    this.getDays();
    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
    this.scheduleHour.setValue(today.getHours());
    for (let i = 0; i < 60; i++) {
      this.minutes.push(i);
    }
    this.scheduleMinute.setValue(today.getMinutes());
  }

  getDays() {
    this.days = [];
    let daysCount = new Date(this.scheduleYear.value, +this.scheduleMonth.value + 1, 0).getDate() + 1;
    for (let i = 1; i < daysCount; i++) {
      this.days.push(i);
    }
    this.scheduleDay.setValue(new Date().getDate());
  }

  getAllTemplates() {
    this.whatsAppService.getAllTemplates().subscribe(response =>
      this.approvedTemplates = response.data.filter(template => template.status === 'APPROVED' && (template.language === 'en' || template.language === 'en_US'))
    );
  }

  getTemplate() {
    let templateName = this.templateName.value;
    this.selectedTemplateHasImage = this.isTemplateParameterized = false;
    this.templateBody = '';
    this.parametersClass = 'col-md-12';
    if (templateName && templateName !== '(Select Template)') {
      this.selectedTemplate = this.approvedTemplates.filter(template => template.name === templateName)[0];
      this.templateBody = this.selectedTemplate.components.filter(c => c.type === 'BODY')[0].text;
      this.isTemplateParameterized = this.templateBody.includes('{{') && this.templateBody.includes('}}');
      let header = this.selectedTemplate.components.filter(component => component.type === 'HEADER');
      if (header.length > 0) {
        this.selectedTemplateHasImage = header[0].format === 'IMAGE';
        if (this.selectedTemplateHasImage) {
          this.parametersClass = 'col-md-8';
        }
      }
    }
  }

  changeLabelText() {
    this.imageUploadLabel = 'Click here to select image';
    if (this.fileImportInput.nativeElement.files.length > 0) {
      this.imageUploadLabel = this.fileImportInput.nativeElement.files[0].name;
    }
  }

  setSendOrScheduleButtonText() {
    this.sendOrScheduleButtonText = this.isScheduled.value ? 'Schedule' : 'Send';
  }

  sendOrScheduleMessage(event) {
    this.sendOrScheduleButtonDisabled = true;
    this.sendOrScheduleButtonText = 'Please wait...';
    let sendWhatsAppMessageRequest: SendWhatsAppMessageRequest = {
      lessonId: this.selectedLesson.lessonId,
      phoneNumbers: this.phoneNumbers,
      templateName: this.templateName.value,
      imageUrl: this.selectedTemplateHasImage ? this.imageUrl.value : null,
      parameters: this.isTemplateParameterized ? this.parameters.value.split('\n') : null,
      language: this.selectedTemplate.language
    };
    if (this.isScheduled.value) {
      let scheduleWhatsAppMessageRequest: ScheduleWhatsAppMessageRequest = sendWhatsAppMessageRequest;
      scheduleWhatsAppMessageRequest.scheduledFor = new Date(this.scheduleYear.value, this.scheduleMonth.value, this.scheduleDay.value, this.scheduleHour.value, this.scheduleMinute.value).getTime();
      this.whatsAppService.scheduleWhatsAppMessage(scheduleWhatsAppMessageRequest)
        .subscribe(response => {
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: 'Message scheduled successfully',
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.dialogRef.close(event);
            },
          };
        },
          error => {
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: error.error
            };
            this.sendOrScheduleButtonDisabled = false;
            this.setSendOrScheduleButtonText();
          });
    } else {
      this.whatsAppService.sendWhatsAppMessage(sendWhatsAppMessageRequest)
        .subscribe(response => {
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: 'Message sent successfully',
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.dialogRef.close(event);
            },
          };
        },
          error => {
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: error.error
            };
            this.sendOrScheduleButtonDisabled = false;
            this.setSendOrScheduleButtonText();
          });
    }
  }

  addPhoneNumber(event) {
    this.phoneNumber.markAllAsTouched();
    if (this.phoneNumbers.indexOf(this.phoneNumber.value.trim()) == -1) {
      if (this.phoneNumber.valid) {
        this.phoneNumberError = "";
        this.phoneNumberList.push(this.phoneNumber.value);
        this.filteredPhoneNumbers.push(this.phoneNumber.value);
        this.phoneNumbers.push(this.phoneNumber.value);
        this.phoneNumber.reset();
      } else if (this.phoneNumber.value.split(",").length > 1) {
        let dataArr = [];
        dataArr = this.phoneNumber.value.split(",");
        for (let i = 0; i < dataArr.length; i++) {
          if (dataArr[i].length > 0 && this.phoneNumbers.indexOf(dataArr[i].trim()) == -1) {
            this.phoneNumberError = "";
            this.filteredPhoneNumbers.push(dataArr[i].trim());
            this.phoneNumbers.push(dataArr[i].trim());
          } else {
            this.phoneNumberError = dataArr[i].trim();
          }
        }
        this.phoneNumber.reset();
      } else if (this.phoneNumber.value.split(" ").length > 1) {
        let dataArr = [];
        dataArr = this.phoneNumber.value.split(" ");
        for (let i = 0; i < dataArr.length; i++) {
          if (dataArr[i].length > 0 && this.phoneNumbers.indexOf(dataArr[i].trim()) == -1) {
            this.phoneNumberError = "";
            this.filteredPhoneNumbers.push(dataArr[i].trim());
            this.phoneNumbers.push(dataArr[i].trim());
          } else {
            this.phoneNumberError = dataArr[i].trim();
          }
        }
        this.phoneNumber.reset();
      } else {
        if (this.phoneNumberError.length > 0
          && this.phoneNumberError.indexOf(this.phoneNumber.value.trim()) == -1) {
          this.phoneNumberError = this.phoneNumberError + ", " + this.phoneNumber.value.trim();
        } else {
          this.phoneNumberError = this.phoneNumber.value.trim();
        }
      }
    } else {
      if (this.phoneNumberDuplicateError.length > 0
        && this.phoneNumberDuplicateError.indexOf(this.phoneNumber.value.trim()) == -1) {
        this.phoneNumberDuplicateError = this.phoneNumberDuplicateError + ", " + this.phoneNumber.value.trim();
      } else {
        this.phoneNumberDuplicateError = this.phoneNumber.value.trim();
      }
      this.phoneNumber.setValue('');
    }
  }

  removePhoneNumber(event, index) {
    let item = this.filteredPhoneNumbers[index];
    this.filteredPhoneNumbers.splice(index, 1);
    let phoneNumberIndex = this.phoneNumbers.indexOf(item);
    this.phoneNumbers.splice(phoneNumberIndex, 1);
  }

  closeModal(data?) {
    this.dialogRef.close(data);
  }

  fileChangeListener($event: any): void {
    let text = [];
    let files = $event.srcElement.files;

    if (files && files.length > 0 && this.isCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
        this.csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
      };

      reader.onerror = function () {
        alert('Unable to read ' + input.files[0]);
      };

    }
    else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    let dataArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let data = (<string>csvRecordsArray[i]).split(',');

      if (data[0].length > 0) {
        if (true) {
          dataArr.push(data[0].trim());
          if (this.phoneNumbers.indexOf(data[0].trim()) == -1) {
            this.filteredPhoneNumbers.push(data[0].trim());
            this.phoneNumbers.push(data[0].trim());
          }
          else {
            if (this.phoneNumberDuplicateError.length > 0
              && this.phoneNumberDuplicateError.indexOf(data[0].trim()) == -1) {
              this.phoneNumberDuplicateError = this.phoneNumberDuplicateError + ", " + data[0].trim();
            }
            else {
              this.phoneNumberDuplicateError = data[0].trim();
            }
          }
        }
        else {
          if (this.csvError.length > 0 && this.csvError.indexOf(data[0].trim()) == -1) {
            this.csvError = this.csvError + ", " + data[0].trim();
          }
          else {
            this.csvError = data[0].trim();
          }
        }
      }
    }
    return dataArr;
  }

  isCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  fileReset() {
    if (this.fileImportInput) {
      this.fileImportInput.nativeElement.value = "";
      this.csvRecords = [];
    }
  }

  searchPhoneNumber($event) {
    if (this.search.value && this.search.value.trim().length > 0) {
      this.filteredPhoneNumbers = this.phoneNumbers.filter(phoneNumber => phoneNumber.includes(this.search.value))
    }
    else {
      this.filteredPhoneNumbers = [...this.phoneNumbers];
    }
  }

  setInviteDetails($event) {
    if (this.challenge) {
      this.challengeInvite();
      return;
    }
    this.configService.loading(true);
    this.lessonsService.sendInvites(this.selectedLesson, this.phoneNumbers, this.emailTemplate.value, this.emailSubject.value, this.usageCount.value)
      .subscribe(res => {
        if (res.result.failedCount > 0) {
          this.status = res.result.failedCount;
        }
        this.getPhoneNumberLog(true);
        this.configService.loading(false);
        setTimeout(function () {
          this.status = "";
        }, 500);
      });
  }

  challengeInvite() {
    this.configService.loading(true);
    this.lessonsService.challengeSendInvites(this.selectedLesson, this.phoneNumbers, this.emailTemplate.value, this.emailSubject.value, this.usageCount.value)
      .subscribe(res => {
        if (res.result.failedCount > 0) {
          this.status = res.result.failedCount;
        }
        this.getPhoneNumberLog(true);
        this.configService.loading(false);
        setTimeout(function () {
          this.status = "";
        }, 500);
      });
  }

  getPhoneNumberLog(history: boolean) {
    if (history) {
      this.whatsAppService.searchMessages(this.selectedLesson.lessonId).subscribe(response => {
        let indexedResponse = response.map((message, index) => {
          let sentDateTime = new Date(message.sentDateTime);
          return {
            ...message,
            sequenceNumber: index + 1,
            sentDateTimeObject: sentDateTime.toLocaleString()
          };
        });
        this.filteredPhoneNumberLog = indexedResponse;
        this.phoneNumberLogGridData = indexedResponse;
      });
    }
  }

  clearPhoneNumbers($event) {
    this.phoneNumbers = [];
    this.filteredPhoneNumbers = [];
    this.csvRecords = [];
    this.phoneNumberError = "";
    this.csvError = "";
    this.phoneNumberDuplicateError = "";
    this.fileReset();
  }

  searchPhoneNumberLog($event) {
    const searchValue = this.searchLog.value;
    if (searchValue && searchValue.trim().length > 0) {
      const lowerCaseSearchValue = searchValue.toLowerCase();
      this.filteredPhoneNumberLog = this.phoneNumberLogGridData.filter(log => log.phoneNumber.includes(searchValue) || log.body.toLowerCase().includes(lowerCaseSearchValue)).map((e, i) => ({ ...e, sequenceNumber: i + 1 }));
    }
    else {
      this.filteredPhoneNumberLog = this.phoneNumberLogGridData;
    }
  }
}