import { Component, OnInit } from "@angular/core";
import { ConfigsService } from "../../services/configs.service";
import { CommonService } from "../../services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { appSettings } from "src/environments/environment";

@Component({
  selector: "app-lesson-schedule",
  templateUrl: "./lesson-schedule.component.html",
  styleUrls: ["./lesson-schedule.component.less"],
})
export class LessonScheduleComponent implements OnInit {
  issharePopup: boolean = false;
  share_popup = {};
  breadCrumsArray: any = [];
  emailHeadPlaceholder = "Heading";
  toggledEmail: boolean = false;
  toggledSocial: boolean = false;
  emailBodyPlaceholder =
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab recusandae, ad totam placeat, inventore dolores eaque! Praesentium quae iure, dolore, corporis tempore tempora at distinctio maiores aliquid assumenda voluptatum consectetur! Ab recusandae, ad totam placeat, inventore dolores eaque! ";
  emailButtonPlaceholder = "Start Learning";
  metaHeadPlaceholder = "Sample Heading";
  metaDescPlaceholder =
    "This will be the sample description of the post. This will be the sample description of the post.";
  mailHead = "";
  mailBody = "";
  mailButton = "";
  metaDesc = "";
  metaHead = "";
  mail_Notification = false;
  push_Notification = false;
  mailTime;
  url;
  issuccessPopup: boolean = false;
  iserrorPopup: boolean = false;
  error_popup = {};
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () { },
  };
  campaignId;
  companyId;
  files;
  editData;
  fileData;
  fileName;
  imageStorageUrl: string = appSettings.imageStoreUrl;
  imageUrl;
  isNotification = false;
  enableTemplate: boolean = true;
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private configsService: ConfigsService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.route.params.subscribe((params) => {
      this.campaignId = params["campaignId"];
      this.companyId = params["companyId"];
      if (this.campaignId) {
        this.getEditData(this.campaignId);
      }
    });
  }

  getEditData(campaignId) {
    this.configsService.loading(true);
    this.url = "CampaignSchedule/" + campaignId;
    this.commonService.getCommonApi(this.url, "get", "").subscribe(
      (data) => {
        this.editData = data.result;
        if (this.editData) {
          this.enableTemplate = false;
          let date = new Date(this.editData.notificationTime);
          date.setHours(date.getHours() - 5);
          date.setMinutes(date.getMinutes() - 30);
          this.mailTime = date;
          this.toggleCheck(
            this.editData.emailNotification,
            "mail_Notification"
          );
          this.toggleCheck(this.editData.pushNotification, "push_Notification");
          this.mailHead = this.editData.emailHeading;
          // if (this.mailHead && this.mailBody && this.mailButton) {
          //   this.enableTemplate = false;
          // }
          this.valuechange(this.mailHead, "mail_head");
          this.mailBody = this.editData.emailBody;
          this.valuechange(this.mailBody, "mail_body");
          this.mailButton = this.editData.emailButton;
          this.valuechange(this.mailButton, "mail_button");
          this.metaHead = this.editData.campaignHeading;
          this.valuechange(this.metaHead, "meta_head");
          this.metaDesc = this.editData.campaignDescription;
          this.valuechange(this.metaDesc, "meta_desc");
          this.fileName = this.editData.campaignCoverImage;
          this.files = this.editData.campaignCoverImage;
          this.imageUrl =
            this.imageStorageUrl + this.editData.campaignCoverImage;
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  valuechange(item, id) {
    if (id == "mail_head") {
      this.emailHeadPlaceholder = item;
    } else if (id == "mail_body") {
      this.emailBodyPlaceholder = item;
    } else if (id == "mail_button") {
      this.emailButtonPlaceholder = item;
    } else if (id == "meta_head") {
      this.metaHeadPlaceholder = item;
    } else if (id == "meta_desc") {
      this.metaDescPlaceholder = item;
    }
  }

  toggleCheck(e, key) {
    this[key] = e;
  }

  loadFile(event) {
    this.fileName = "";
    if (event.target.files[0]) {
      let maxFileSize = 1024 * 1024 * 2;
      if (event.target.files[0].size > maxFileSize) {
        this.files = "";
        this.fileName = "";
        this.iserrorPopup = true;
        this.error_popup = {
          display: true,
          message: "Maximum file size supported is 2MB",
        };
      } else if (
        event.target.files[0].type != "image/jpeg" &&
        event.target.files[0].type != "image/png" &&
        event.target.files[0].type != "image/jpg"
      ) {
        this.files = "";
        this.fileName = "";
        this.iserrorPopup = true;
        this.error_popup = {
          display: true,
          message: "Type of file must be - .png or .jpg",
        };
      } else {
        var image = document.getElementById("uploadImage");
        image["src"] = URL.createObjectURL(event.target.files[0]);
        this.fileData = event.target.files[0];
        this.configsService.loading(true);
        this.commonService
          .fileUpload("CampaignSchedule/Upload", this.fileData, true)
          .subscribe(
            (data) => {
              this.fileName = "";
              if (!data.value.hasError) {
                this.fileName = data.value.result;
                this.issuccessPopup = true;
                this.success_popup = {
                  display: true,
                  message: data.value.message,
                  buttonPositive: "Okay",
                  positiveButton: () => {
                    this.issuccessPopup = false;
                  },
                };
              } else {
                this.iserrorPopup = true;
                this.error_popup = { display: true, message: "Upload Failed" };
              }
            },
            (err) => {
              this.error_popup = { display: true, message: err[0] };
              this.configsService.loading(false);
            },
            () => {
              this.configsService.loading(false);
            }
          );
      }
    }
  }

  removeFiles() {
    this.files = "";
    this.fileName = "";
  }

  schedule(form) {
    // this.issharePopup = true;
    // this.share_popup = {
    // 	display: true,
    // 	title: 'Campaign Created Successfully',
    // 	title_img : 'assets/images/tick_green.svg',
    // 	copy_link : 'https://www.google.co.in/',
    // 	credits: 10,
    // 	share_to : [{img:'assets/images/whatsapp_icon.svg',link:''},
    // 				{img:'assets/images/facebook_icon.svg',link:''},
    // 				{img:'assets/images/slack_icon.svg',link:''},
    // 				{img:'assets/images/mail_icon.svg',link:''}],

    // }
    if (!this.mail_Notification && !this.push_Notification) {
      this.isNotification = true;
      form.submitted = false;
    } else {
      // if (this.fileName && this.editData) {
      //   form.controls.ImageFile.value = this.fileName;
      //   form.controls.ImageFile.errors = false;
      //   form.controls.ImageFile.status = "VALID";
      //   form.value.ImageFile = this.fileName;
      // }
      this.isNotification = false;
      form.submitted = true;
      // console.log(form, form.valid);
      if (form.valid) {
        let ListData = {};
        ListData["LessonCampaignId"] = this.campaignId;
        ListData["EmailHeading"] = form.value.email_head;
        ListData["EmailBody"] = form.value.mail_body;
        ListData["EmailButton"] = form.value.mail_button;
        ListData["EmailNotification"] = this.mail_Notification;
        ListData["PushNotification"] = this.push_Notification;
        if (form.value.note_time) {
          let date = new Date(form.value.note_time);
          ListData["NotificationTime"] = formatDate(
            date,
            "yyyy-MM-dd h:mm a",
            "en-US"
          );
        }
        ListData["CampaignHeading"] = form.value.meta_head;
        ListData["CampaignDescription"] = form.value.meta_desc;
        ListData["CampaignCoverImage"] = this.fileName;
        this.configsService.loading(true);
        this.url = "CampaignSchedule";
        if (this.editData) {
          ListData[
            "lessonCampaignScheduleId"
          ] = this.editData.lessonCampaignScheduleId;
          this.commonService.updateData(this.url, ListData, false).subscribe(
            (data) => {
              this.postStatus(data);
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = {
                display: true,
                message: err.error.errors[0],
              };
            },
            () => {
              this.configsService.loading(false);
              form.reset();
              form.submitted = false;
            }
          );
        } else {
          this.commonService.createData(this.url, ListData, false).subscribe(
            (data) => {
              this.postStatus(data);
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = { display: true, message: err[0] };
            },
            () => {
              this.configsService.loading(false);
              form.reset();
              form.submitted = false;
            }
          );
        }
      }
    }
  }

  /** CallBack Fucntion
    -- @params result --
    **/

  postStatus(result) {
    if (!result.value.hasError) {
      // this.router.navigate(["companies/campaign/" + this.companyId]);
      this.issharePopup = true;
      this.share_popup = {
        display: true,
        title: "Campaign Created Successfully",
        title_img: "assets/images/tick_green.svg",
        copy_link: "http://communities.gleacreviews.com/",
        credits: 10,
        share_to: [
          {
            shareButton: "whatsapp",
            img: "assets/images/whatsapp_icon.svg",
            link: "http://communities.gleacreviews.com/",
          },
          {
            shareButton: "facebook",
            img: "assets/images/facebook_icon.svg",
            link: "http://communities.gleacreviews.com/",
          },
          {
            shareButton: "twitter",
            img: "assets/images/twitter.png",
            link: "http://communities.gleacreviews.com/",
          },
          {
            shareButton: "email",
            img: "assets/images/mail_icon.svg",
            link: "http://communities.gleacreviews.com/",
          },
        ],
        redirectOnClose: true,
        redirectUrl: "companies/campaign/" + this.companyId,
      };
    } else {
      this.iserrorPopup = true;
    }
  }
  redirect() {
    this.router.navigate(["companies/campaign/" + this.companyId]);
  }

  shareCampaign() { }

  handleEmailSelection(event) {
    var emailSubject = this.mailHead;
    this.mailHead = emailSubject + event.char;
  }

  handleMetaSelection(event) {
    var emailSubject = this.metaHead;
    this.metaHead = emailSubject + event.char;
  }
}
