import {Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {GleacColumnDef} from "../data-grid/data-grid.model";
import {CommonService} from "../../services/common.service";
import {ConfigsService} from "../../services/configs.service";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import {UsersService} from "../../services/users.service";

@Component({
  selector: "app-campaign-detail",
  templateUrl: "./campaign-detail.component.html",
  styleUrls: ["./campaign-detail.component.less"],
})
export class CampaignDetailComponent implements OnInit {
  page_title = "Campaign";
  breadCrumsArray: any = [];
  filterArray: any = [
    {label: "skill", type: "select"},
    {label: "Start Date", type: "datePicker"},
    {label: "End Date", type: "datePicker"},
  ];
  detail_array = [];
  campaignDetails: any;
  campaignGridData = [];
  skillList = [];
  campaignColData: GleacColumnDef[] = [
    {displayName: "User Name", data: "userName"},
    {displayName: "Email", data: "userEmail"},
    {
      displayName: "Lessons  Completed",
      data: "lessionCompleted",
      render: (data, row) => {
        return row.lessionCompleted + " / " + row.lessonCount;
      },
    },
    {
      displayName: "Actions",
      isSingleAction: true,
      actionName: "Remove",
      disableSort: true,
    },
  ];
  url = "";
  searchString = "";
  pageIndex: number = 1;
  pageSize: number = 10;
  form;
  queryParam: any = [];
  query;
  campaignId;
  companyId;
  skills = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };
  error_popup = {};
  isconfirmPopup = false;
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  iscreatePopup: boolean = false;
  create_popup = {
    display: false,
    title: "",
    field: [],
    bulk_upload: false,
    buttonPositive: "",
    enable_email: false,
    closeModal: function () {
    },
    createrCall: function (form: any) {
    },
  };

  issharePopup: boolean = false;
  share_popup = {};
  creatFields: any = [
    {
      label: "Add Users",
      key: "users",
      placeholder: "Select the users for the campaign",
      type: "dropdown",
      bindLabel: "name",
      multiple: true,
      value: "",
      required: "required",
      options: [],
      apiUrl: "Users",
      paramsNeeded: ["companyId"],
    },
  ];
  assignUserToCampaignFlag: boolean = false;

  constructor(
    private configsService: ConfigsService,
    private commonService: CommonService,
    private router: Router,
    public usersService: UsersService,
    private route: ActivatedRoute,
    location: Location
  ) {
  }

  ngOnInit() {
    this.usersService.isUsers = true;

    this.breadCrumsArray = this.configsService.getBreadcrumb();
    this.detail_array = [
      {
        label: "Skill",
        key: "lessonSkill",
        data: "",
        info: "",
      },
      {
        label: "Start date",
        key: "lessonCampaignStartDate",
        data: "",
        info: "",
      },
      {
        label: "End date",
        key: "lessonCampaignEndDate",
        data: "",
        info: "",
      },
      {
        label: "Completion Rate",
        key: "completionRate",
        data: "",
        info: "",
      },
      {label: "Engaged User", key: "engagedUsers", data: "", info: ""},
      {
        label: "Credits Used",
        key: "creditsUsed",
        data: "",
        info: "",
      },
      {label: "Status", key: "status", data: "", info: ""},
    ];
    this.route.params.subscribe((params) => {
      this.campaignId = params["campid"];
      this.companyId = params["id"];
      if (this.campaignId) {
        this.queryParam["lessonCampainId"] = this.campaignId;
      }
    });
    this.queryParam["pageIndex"] = this.pageIndex;
    this.queryParam["pageSize"] = this.pageSize;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getCampaignList();
  }

  addUser() {
    // this.loadOptions(this.creatFields);
    // this.iscreatePopup = true;
    // this.create_popup = {
    //   display: true,
    //   bulk_upload: false,
    //   enable_email: true,
    //   title: "Add User",
    //   buttonPositive: "Create",
    //   field: this.creatFields,
    //   closeModal: () => {
    //     setTimeout(() => {
    //       this.iscreatePopup = false;
    //     }, 1000);
    //   },
    //   createrCall: (form: NgForm) => {
    //     if (form.valid) {
    //       this.configsService.loading(true);
    //       this.form = form;
    //       this.form.submitted = true;
    //
    //       let ListData = [];
    //       let viaEmailData = [];
    //       this.url = "AssignUser";
    //       ListData = {...form.value};
    //       if (ListData["viaEmail"]) {
    //         viaEmailData = ListData["viaEmail"].split(",");
    //       }
    //       if (viaEmailData) {
    //         if (ListData["users"]) {
    //           viaEmailData.map((f, i) => {
    //             let insert = 0;
    //             ListData["users"].map((m, n) => {
    //               if (m != f) {
    //                 insert = insert + 1;
    //               }
    //               if (insert == ListData["users"].length) {
    //                 ListData["users"].push(f);
    //               }
    //             });
    //           });
    //         } else {
    //           ListData["users"] = viaEmailData;
    //         }
    //       }
    //       let sendingData = {
    //         lessonCampaignId: this.campaignId,
    //         groupId: [],
    //         users: ListData["users"],
    //       };
    //       this.commonService.createData(this.url, sendingData, false).subscribe(
    //         (data) => {
    //           this.postStatus(data);
    //         },
    //         (err) => {
    //           this.configsService.loading(false);
    //           this.iserrorPopup = true;
    //           this.error_popup = {
    //             display: true,
    //             message: err.error.errors[0],
    //           };
    //         },
    //         () => {
    //           this.configsService.loading(false);
    //           setTimeout(() => {
    //             form.reset();
    //             this.form.submitted = false;
    //             this.iscreatePopup = false;
    //           }, 1000);
    //         }
    //       );
    //     }
    //   },
    // };
    this.assignUserToCampaignFlag = true;
  }

  userClose(event) {
    this.assignUserToCampaignFlag = false;
    this.getCampaignList();
  }

  loadOptions(dataInput) {
    dataInput.map((field) => {
      if (field.apiUrl) {
        let requestData = [];
        this.configsService.loading(true);
        requestData[field.paramsNeeded] = this.companyId;
        this.commonService
          .getListAloneV2(
            field.apiUrl,
            this.commonService.encodeQueryData(requestData)
          )
          .subscribe(
            (data) => {
              field.options = data.result.data.map((x) => {
                return {
                  name: x.userName,
                  id: x.email,
                };
              });
            },
            (err) => {
              this.configsService.loading(false);
              this.iserrorPopup = true;
              this.error_popup = {display: true};
            },
            () => {
              this.configsService.loading(false);
            }
          );
      }
    });
  }

  deleteCampaignUser(event) {
    if (event.action == "delete") {
      let params = [];
      params["lessonCampaignId"] = this.campaignId;
      params["userId"] = event.data.userId;
      let deleteData = this.commonService.encodeQueryData(params);
      this.deleteUser(deleteData);
    }
  }

  getCampaignList() {
    this.configsService.loading(true);
    this.url = "Campaigns/details/";
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        this.campaignDetails = data.result.lessonCampaign;
        for (var param in this.campaignDetails) {
          if (param == "lessonCampaignStartDate") {
            let date = new Date(this.campaignDetails[param]);
            this.campaignDetails["lessonCampaignStartDate"] =
              date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear();
          }
          if (param == "lessonCampaignEndDate") {
            let date = new Date(this.campaignDetails[param]);
            this.campaignDetails["lessonCampaignEndDate"] =
              date.getDate() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getFullYear();
          }
        }
        this.skillList = data.result.lessonCampaign.lessonSkill;
        this.skills = Array.prototype.map
          .call(this.skillList, function (item) {
            return item.lessonCategoryName;
          })
          .join(","); // "A,B,C"
        this.detail_array.map((item) => {
          for (var param in this.campaignDetails) {
            // if((param =='lessonCampaignStartDate') || (param=='lessonCampaignEndDate')){
            //   let date = new Date(this.campaignDetails[param]);
            //   this.campaignDetails[param] = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
            // }
            if (item.key == param) {
              item.data = this.campaignDetails[param];
            }
            if (item.key == "lessonSkill") {
              item.data = this.skills;
              item.info = this.skills;
            }
          }
        });

        if (data.result.list.length <= 0 && data.result.list.totalCount != 0 && this.queryParam["pageIndex"] > 1) {
          this.queryParam["pageIndex"] = --this.queryParam["pageIndex"];
          this.query = this.commonService.encodeQueryData(this.queryParam);
          this.getCampaignList();
        } else {
          this.campaignGridData = data.result;
        }

        // this.campaignGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  pageChange(event) {
    this.queryParam["pageIndex"] = event;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.getCampaignList();
  }

  filterData(event) {
    this.searchString = event[0];
    this.queryParam["searchText"] = this.searchString;
    this.queryParam["pageIndex"] = 1;
    this.query = this.commonService.encodeQueryData(this.queryParam);
    this.url = this.searchString
      ? "Campaigns/details/search/"
      : "Campaigns/details/";
    this.configsService.loading(true);
    this.commonService.getListDataGrid(this.url, this.query).subscribe(
      (data) => {
        this.campaignGridData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  changeSearchText(event) {
  }

  deleteUser(data) {
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Send Mail",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.confirm.display = false;
        this.configsService.loading(true);
        let url = "Campaigns/details/user?";
        this.commonService.deleteData(url, data).subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = {display: true, message: err.error.errors[0]};
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
      },
    };
  }

  /** CallBack Fucntion
   -- @params result --
   **/
  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          // this.create_popup.display = false;
          setTimeout(() => {
            this.success_popup.display = false;
            this.getCampaignList();
          }, 1000);
        },
      };
    } else {
      this.iscreatePopup = false;
      this.iserrorPopup = true;
    }
  }

  shareCampaign() {
    this.issharePopup = true;
    this.share_popup = {
      display: true,
      title: "Share Campaign",
      title_img: "assets/images/tick_green.svg",
      copy_link: location.href,
      credits: 10,
      share_to: [
        {
          shareButton: "whatsapp",
          img: "assets/images/whatsapp_icon.svg",
          link: location.href,
        },
        {
          shareButton: "facebook",
          img: "assets/images/facebook_icon.svg",
          link: location.href,
        },
        {
          shareButton: "twitter",
          img: "assets/images/twitter.png",
          link: location.href,
        },
        {shareButton: "email", img: "assets/images/mail_icon.svg", link: ""},
      ],
    };
  }
}
