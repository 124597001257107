import { ViewChild, Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid/data-grid.model';
import { ReportService } from 'src/app/services/report.service';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { UsersService } from 'src/app/services/users.service';
import { Filter } from '../user-activity-report/user-activity-report.model';
import { ConfigsService } from '../../services/configs.service';
import {DataGirdServerSidePaginationComponent} from '../data-grid-server-side-pagination/data-gird-server-side-pagination.component';
import { DesktopGuardService } from 'src/app/guards/desktop-guard.service';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.less']
})
export class UserReportComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  error_popup = {display: false};
  dataFilter: string;
  pageNumber: number = 1;
  pageSize: number = 1000;
  transactionsGridData: any;
  @ViewChild(DataGirdServerSidePaginationComponent, {static: false}) table: DataGirdServerSidePaginationComponent;
  transactionsColumnData: GleacColumnDef[] = [
    { data: "firstName", displayName: "First Name" },
    { data: "lastName", displayName: "Last Name" },
    { data: "email", displayName: "Email" },
    {data:"source", displayName:"Source",
      render: (data, row) => {
        if(!row.source){
          return "Desktop invite"
        }
        else{
          return row.source;
        }
      }
    },
    {
      data: "createdOn", displayName: "Created On", disableSort: true, render: (data, row) => {
        if (row.createdOn != "0001-01-01T00:00:00") {
          var date = new Date(row.createdOn);
          return date.getDate() + "/"
            + (date.getMonth() + 1) + "/"
            + date.getFullYear() + ", "
            + (date.getHours() % 12 ? ((date.getHours() % 12) < 10 ? "0" + (date.getHours() % 12) : (date.getHours() % 12)) : 12) + ":"
            + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " "
            + (date.getHours() >= 12 ? "PM" : "AM");
        }
        else {
          return "-";
        }
      }
    }
  ];

  constructor(private reportService: ReportService, private userService: UsersService, private configsService: ConfigsService) { }

  getData($event) {
    this.configsService.loading(true);
    this.dataFilter = $event == null || $event.srcElement == null ? null : $event.srcElement.value;
    let pageNumber = this.table == null ? 0 : this.table.paginator.pageIndex;
    let pageSize = this.table == null ? 25 : this.table.paginator.pageSize;
    this.reportService.getUserReportGrid(this.dataFilter, this.userService.currentUser.company, pageNumber + 1, pageSize).subscribe(data => {
      this.transactionsGridData = data.result;
    },err => {
      this.configsService.loading(false);
      this.error_popup = {display: true};
    },()=> {
        this.configsService.loading(false);
    });
  }

  ngOnInit() {
    this.getData(null);
  }
}
