import {Component, AfterContentChecked, OnInit, HostListener} from '@angular/core';
import {ConfigsService} from './services/configs.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title1 = 'communities-gleac';
  isLoading = [];
  loadingMessage = '';
  message;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;

  constructor(private title: Title, private meta: Meta, private config: ConfigsService) {

    this.config.invokeLoadingEvent.subscribe(value => {
      if (value.isLoading) {
        this.isLoading.push('1');
        if (value.msg) {
          this.loadingMessage = value.msg;
        }
      } else {
        this.isLoading.pop();
        this.loadingMessage = '';
      }
    });
  }

  ngOnInit() {
    this.lottieConfig = {
      path: 'assets/images/new_loader.json',
      autoplay: true,
      loop: true
    };
  }

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.data.accessToken && e.data.refreshToken
      && !localStorage.getItem("GATk")
      && !localStorage.getItem('GRTk')) {
      localStorage.setItem("GATk", e.data.accessToken);
      localStorage.setItem("GRTk", e.data.refreshToken);
    }
  }
}
