import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CookiePopupComponent } from '../cookie-popup/cookie-popup.component';
import { BenchmarkService } from 'src/app/services/benchmark.service';
import { BenchmarkPack, PurchaseInfo } from './benchmark-popup.model';
import { FormGroup, FormControl } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from 'src/app/services/configs.service';
import { DomSanitizer } from '@angular/platform-browser';
import { appSettings } from 'src/environments/environment';
import { loadStripe } from '@stripe/stripe-js';

@Component({
  selector: 'app-benchmark-popup',
  templateUrl: './benchmark-popup.component.html',
  styleUrls: ['./benchmark-popup.component.less']
})
export class BenchmarkPopupComponent implements OnInit {
  benchmarkPacks: BenchmarkPack[];
  showPayment: boolean;
  showDiv: boolean = false;
  candidateBenchmark: any;
  purchaseInfo: PurchaseInfo;
  paymentUrl: any;
  packForm: FormGroup = new FormGroup({
    benchmarkPlan: new FormControl()
  });
  stripePromise: any;
  stripe: any;

  constructor(private configsService: ConfigsService, private usersService: UsersService, private benchmarkService: BenchmarkService,
    public dialogRef: MatDialogRef<CookiePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) {
    this.showPayment = data.dataKey;
    this.usersService.getIpAddress().then(function (result) {
      localStorage.setItem('country_code', result.country_code);
      console.log(this.countryCode);
    });
  }

  ngOnInit() {
    this.stripePromise = loadStripe(appSettings.stripeKey);
    if (this.showPayment == true) {
      this.configsService.loading(true);
      this.benchmarkService.getAllPacks()
        .subscribe(res => {
          if (res.hasError == false) {
            this.benchmarkPacks = res.result.filter(x => x.unlockBenchmark == true);
            this.configsService.loading(false);
          }
        });
    }
    else {
      this.configsService.loading(true);
      this.benchmarkService.getUserBenchmarks(this.usersService.currentUser.userId)
        .subscribe(res => {
          if (res.hasError == false) {
            this.candidateBenchmark = res.result[0];
            this.candidateBenchmark.testUrl = appSettings.benchmarkUrl + "identitybenchmarks/assessment/" + this.candidateBenchmark.ctBenchmarkDetailId;
            this.showPayment = false;
            this.benchmarkPacks = [];
            this.configsService.loading(false);
          }
        });
    }

    this.purchaseInfo = {
      packId: undefined,
      userId: undefined,
      countryCode: undefined
    };
  }

  cancel(event) {
    this.dialogRef.close({
      confirm: true
    });
  }

  async purchase(event) {
    this.purchaseInfo.packId = this.packForm.value.benchmarkPlan; 
    if (this.purchaseInfo.packId != null){  
      this.showDiv = !this.showDiv;
      this.configsService.loading(true); 
      this.purchaseInfo.packId = this.packForm.value.benchmarkPlan;
      this.purchaseInfo.userId = this.usersService.currentUser.userId;
      this.purchaseInfo.countryCode = localStorage.getItem('country_code');
      this.stripe = await this.stripePromise;
      this.benchmarkService.initiatePurchase(this.purchaseInfo).subscribe(res => {
        if (res.hasError == false) {
          var sessionId = res.result;
          localStorage.setItem("sessionId", res.result);
          this.stripe.redirectToCheckout({
            sessionId: sessionId
          }).then(function (result) {
            console.log(result);
          });
        }
      });
    }
    else{
      this.showDiv = !this.showDiv;
    }
    /*this.configsService.loading(true);   
    this.purchaseInfo.packId = this.packForm.value.benchmarkPlan;
    this.purchaseInfo.userId = this.usersService.currentUser.userId;
    this.purchaseInfo.countryCode = localStorage.getItem('country_code');
    this.stripe = await this.stripePromise;
    this.benchmarkService.initiatePurchase(this.purchaseInfo).subscribe(res => {
      if (res.hasError == false) {
        var sessionId = res.result;
        localStorage.setItem("sessionId", res.result);
        this.stripe.redirectToCheckout({
          sessionId: sessionId
        }).then(function (result) {
          console.log(result);
        });
      }
    });*/
  }
}
