import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { appSettings } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CompaniesService {
  constructor(private http: HttpClient) {}

  jobs: string[];
  url: string = appSettings.dashboardUrl;

  // getCompanyDataGrid(filter?: string,pageIndex?: number, pageSize?: number) {
  //   var apiUrl = filter ? this.url + "companies?filter=" + filter : this.url + "/companies";
  //   var response = this.http.get<any>(apiUrl);
  //   return response;
  // }

  getCompanyDataGrid(
    filter?: string,
    pageIndex?: number,
    pageSize?: number,
    status?: string
  ) {
    var apiUrl;
    apiUrl = filter
      ? this.url +
        "Company/search/pagination?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize +
        "&searchText=" +
        filter
      : this.url +
        "Company/pagination?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize;
    if (status) {
      apiUrl = apiUrl + "&status=" + status;
    }
    var response = this.http.get<any>(apiUrl);
    return response;
  }
  getJobsList() {
    var apiUrl = this.url + "jobs";
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  createCompany(data) {
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    let headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    var url = this.url + "Company";
    return this.http.post<any>(url, formData, { headers: headers });
  }

  removeCompany() {}
}
