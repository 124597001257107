import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-preview-lesson',
  templateUrl: './preview-lesson.component.html',
  styleUrls: ['./preview-lesson.component.less']
})
export class PreviewLessonComponent implements OnInit {
  @Input("display") display=false;
  @Input("lessonData") lessonData ;
  @Output() isBack: EventEmitter<any> = new EventEmitter();
  selectedSection = 0;
  constructor() { }


  ngOnInit() {
  }
  isClose(){
    this.isBack.emit(false);
  }
  sectionTitle(value){
    let title=value;
    if(value.length>=15){
      title = value.slice(0,15)+"..."
    }
    return title;
  }
  changePreviewScreen(index){
    this.selectedSection = index;
  }
}
