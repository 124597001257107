import { Injectable } from "@angular/core";
import { appSettings } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SendWhatsAppMessageRequest } from "../models/whatsapp/send-whatsapp-message-request";
import { ScheduleWhatsAppMessageRequest } from "../models/whatsapp/schedule-whatsapp-message-request";
import { SearchMessagesResponse } from "../models/whatsapp/search-messages-response";

@Injectable({
    providedIn: "root",
})
export class WhatsAppService {
    baseUrl: string = appSettings.lessonUrl + 'whatsapp/';

    constructor(
        private httpClient: HttpClient
    ) { }

    getAllTemplates() {
        return this.httpClient.get<any>(this.baseUrl + 'getalltemplates');
    }

    sendWhatsAppMessage(sendWhatsAppMessageRequest: SendWhatsAppMessageRequest) {
        return this.httpClient.post<any>(this.baseUrl + 'sendwhatsappmessage', sendWhatsAppMessageRequest);
    }

    scheduleWhatsAppMessage(scheduleWhatsAppMessageRequest: ScheduleWhatsAppMessageRequest) {
        return this.httpClient.post<any>(this.baseUrl + 'schedulewhatsappmessage', scheduleWhatsAppMessageRequest);
    }

    searchMessages(lessonId?: string) {
        return this.httpClient.get<SearchMessagesResponse[]>(this.baseUrl + 'searchmessages' + (lessonId ? '?lessonid=' + lessonId : ''));
    }
}