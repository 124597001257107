export const SectionControlTypes: { controlType: string, controlTypeId: number }[] = [
    { controlType: "Description / Info", controlTypeId: 1 },
    { controlType: "Image", controlTypeId: 4 },
    { controlType: "Video", controlTypeId: 5 },
    { controlType: "-----------------------", controlTypeId: 0 },
    { controlType: "Text - Single Answer", controlTypeId: 7 },
    { controlType: "Text - Multiple Answers", controlTypeId: 8 },
    { controlType: "Radio Buttons", controlTypeId: 3 },
    { controlType: "Checkboxes", controlTypeId: 10 },
    // { controlType: "Slider", controlTypeId: 11 },
]

export const SectionTagTypes: { tagType: string, tagTypeId: string }[] = [
    { tagType: "Select Tag", tagTypeId: "" },
    { tagType: "REMEMBER", tagTypeId: "REMEMBER" },
    { tagType: "UNDERSTAND", tagTypeId: "UNDERSTAND" },
    { tagType: "APPLY", tagTypeId: "APPLY" },
    { tagType: "ANALYZE", tagTypeId: "ANALYZE" },
    { tagType: "EVALUATE", tagTypeId: "EVALUATE" },
    { tagType: "CREATE", tagTypeId: "CREATE" }
]