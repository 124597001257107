import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignMentorService {
  baseUrl: string = appSettings.authUrl;
  mentorUrl: string = appSettings.mentorsUrl;

  constructor(private http: HttpClient) { }
  getUser(
    pageLimit?: number,
    pageNumber?: number,
    filterString = "") {
    let url = this.baseUrl + "Users?PageNumber=" + pageNumber +
      "&PageSize=" + pageLimit + "&filterString=" + filterString;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }
  assignMentor(userList: any) {
    let url = this.mentorUrl + "Mentor";
    return this.http.post<any>(url, { gleacUserIds: userList.map(x => x.userId) });
  }
}
