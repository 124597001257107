import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoalsService {
  goalUrl: string = appSettings.mobileUrl;

  constructor(private http: HttpClient) { }

  getAllGoals(pageNumber, pageSize) {
    var url = this.goalUrl + "Goals/GoalsForDashboard?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  createGoal(goalData) {
    var url = this.goalUrl + "Goals/AddGoalsToLibrary";
    var response = this.http.post<any>(url, goalData);
    return response;
  }

  getGoalsForLibrary(pageNumber, pageSize) {
    var url = this.goalUrl + "Goals/GoalsLibrary?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  search(searchString, pageNumber, pageSize, sortColumn, sortOrder, status, userGroup, companyName) {
    var url = this.goalUrl + "Goals/Search?filterString=" + searchString + "&pageNumber="
              + pageNumber + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&status=" + status + "&userGroup=" + userGroup + "&companyName=" + companyName;
    return this.http.get<any>(url);
  }

  exportGoals(searchString, sortColumn, sortOrder, status, userGroup, companyName) {
    var url = this.goalUrl + "Goals/GoalsExport?filterString=" + searchString
      + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder + "&status=" + status + "&userGroup=" + userGroup + "&companyName=" + companyName;
    return this.http.get(url, {responseType : 'blob'});
  }

  exportGoalsLibrary(searchString, sortColumn, sortOrder) {
    var url = this.goalUrl + "Goals/GoalsLibraryExport?filterString=" + searchString + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder;
    return this.http.get(url, {responseType : 'blob'});
  }

  searchGoalsLibrary(searchString, pageNumber, pageSize, sortColumn, sortOrder) {
    var url = this.goalUrl + "Goals/SearchFromLibrary?filterString=" + searchString + "&pageNumber="
              + pageNumber + "&pageSize=" + pageSize + "&sortColumn=" + sortColumn + "&sortOrder=" + sortOrder;
    return this.http.get<any>(url);
  }

  filterData(status, userGroup, companyName, pageNumber, pageSize) {
    var url = this.goalUrl + "Goals/Filter?";

    if (status) {
      url += "status=" + status;
    }

    if (userGroup) {
      url += "&userGroup=" + userGroup;
    }

    if (companyName) {
      url += "&companyName=" + companyName;
    }

    url += "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;

    return this.http.get<any>(url);
  }

  getUserGroups(filterString:string, pageSize ?: number, pageNumber ?: number) {
    if(pageSize === undefined) pageSize = 10000;
    if(pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/UserGroups?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getGroups(filterString:string, pageSize ?: number, pageNumber ?: number) {
    if(pageSize === undefined) pageSize = 10;
    if(pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/Groups?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getCompanies(filterString:string, pageSize ?: number, pageNumber ?: number) {
    if(pageSize === undefined) pageSize = 10;
    if(pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/Organisations?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  getUserCompanies(filterString:string, pageSize ?: number, pageNumber ?: number) {
    if(pageSize === undefined) pageSize = 10000;
    if(pageNumber === undefined) pageNumber = 1;

    var url = this.goalUrl + "Goals/UserOrganisations?filterString=" + filterString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    return this.http.get<any>(url);
  }

  assignGoals(goalData) {
    var url = this.goalUrl + "Goals/AssignGoals";
    var response = this.http.post<any>(url, goalData);
    return response;
  }

  updateGoals(goalData) {
    var url = this.goalUrl + "Goals/EditGoal";
    var response = this.http.post<any>(url, goalData);
    return response;
  }

  deleteGoal(goalData) {
    var url = this.goalUrl + "Goals/DeleteGoal";
    var response = this.http.post<any>(url, goalData);
    return response;
  }
}
