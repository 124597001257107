import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CandidateChallengeService {

  userEmailId: string;
  // candidateLesson: Lesson;
  baseUrl: string = appSettings.lessonUrl;

  constructor(private http: HttpClient) { }
  getChallengeById(challengeId){
    let url = this.baseUrl + "ChallengeSkill/Challenge/" + challengeId ;
    return this.http.get<any>(url,{
      headers : new HttpHeaders({
        "x-api-version" : "2"
      })
    })
  }
  insertChallengeAnswer(lessonAnswer: any) {
    let url = this.baseUrl + "Answers";
    return this.http.post<any>(url, lessonAnswer,{
      headers : new HttpHeaders({
        "x-api-version" : "2"
      })
    });
  }
}
