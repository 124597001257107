import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {appSettings} from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient) {
  }

  jobs: string[];
  url: string = appSettings.dashboardUrl;
  usersUrl: string = appSettings.authUrl;

  version2Api = {
    headers: new HttpHeaders({
      "x-api-version": "2"
    })
  };

  getListDataGrid(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.url + apiLink + "pagination?" + query;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getListDataGridForGroups(apiLink: string, query) {
    var apiUrl = this.url + apiLink + "pagination?" + query;
    var response = this.http.get<any>(apiUrl, this.version2Api);
    return response;
  }

  getListDataGridV2(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.url + apiLink + "?" + query;
    var response = this.http.get<any>(apiUrl, this.version2Api);
    return response;
  }

  getListDataGridNew(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.usersUrl + apiLink + "?" + query;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getCommonApiNew(apiLink: string) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.usersUrl + apiLink;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getCompanyData(apiLink: string) {
    var apiUrl = this.url + apiLink;
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getCompanyUsers(pageLimit?: number,
                  pageNumber?: number,
                  filterString = "", companyId = "") {
    let url = this.usersUrl + "Users?PageNumber=" + pageNumber +
      "&PageSize=" + pageLimit + "&filterString=" + filterString + "&companyId=" + companyId;

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  exportUsers(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.url + apiLink + "?" + query;
    var response = this.http.get(apiUrl, {
      responseType: 'blob', headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
    return response;
  }

  getExpoUsers(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.usersUrl + apiLink + "?" + query;
    var response = this.http.post<any>(apiUrl, null);
    return response;
  }

  exportExpoUsers(apiLink: string, query) {
    // var apiUrl = filter ? this.url + apiLink+"/search/pagination?searchText=" + filter+"&pageIndex="+pageIndex+"&pageSize="+pageSize : this.url + apiLink+"/pagination?pageIndex="+pageIndex+"&pageSize="+pageSize;
    var apiUrl = this.usersUrl + apiLink + "?" + query;
    var response = this.http.get(apiUrl, {responseType: 'blob'});
    return response;
  }

  getJobsList() {
    var apiUrl = this.url + "jobs";
    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getCommonApiPost(apiLink: string, query) {
    var apiUrl = this.url + apiLink;
    var response = this.http.post<any>(apiUrl, query);
    return response;
  }

  getCommonApi(apiLink: string, methodType, query) {
    var apiUrl = this.url + apiLink;
    var response = this.http[methodType]<any>(apiUrl);
    return response;
  }

  getListAlone(apiLink: string, query?: string) {
    var apiUrl;
    if (query) {
      apiUrl = this.url + apiLink + "?" + query;
    } else {
      apiUrl = this.url + apiLink;
    }

    var response = this.http.get<any>(apiUrl);
    return response;
  }

  getListAloneV2(apiLink: string, query?: string) {
    var apiUrl;
    if (query) {
      apiUrl = this.url + apiLink + "?" + query;
    } else {
      apiUrl = this.url + apiLink;
    }
    var response = this.http.get<any>(apiUrl, this.version2Api);
    return response;
  }

  createData(apiLink, data, isMulti) {
    if (isMulti) {
      let headers;
      let formData = new FormData();
      for (var key in data) {
        if (typeof data[key] == "object") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
      headers = new HttpHeaders().append(
        "Content-Disposition",
        "multipart/form-data"
      );
      var url = this.url + apiLink;
      return this.http.post<any>(url, formData, {headers: headers});
    } else {
      var url = this.url + apiLink;
      return this.http.post<any>(url, data);
    }
  }

  createDataV2(apiLink, data) {
    var url = this.url + apiLink;
    return this.http.post<any>(url, data, this.version2Api);
  }

  createDataV3(apiLink, data) {
    var url = this.usersUrl + apiLink;
    return this.http.post<any>(url, data);
  }


  updateDataV2(apiLink, data) {
    var url = this.url + apiLink;
    return this.http.put<any>(url, data, this.version2Api);
  }


  updateDataV3(apiLink, data) {
    var url = this.usersUrl + apiLink;
    return this.http.put<any>(url, data);
  }

  deleteDataV2(apiLink) {
    var apiUrl = this.url + apiLink;
    var response = this.http.delete<any>(apiUrl, this.version2Api);
    return response;
  }

  fileUpload(apiLink, data, isMulti) {
    let headers;
    const formData: FormData = new FormData();
    formData.append("file", data, data.name);
    headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    var url = this.url + apiLink;
    return this.http.post<any>(url, formData, {headers: headers});
  }

  updateData(apiLink, data, isMulti) {
    if (isMulti) {
      let headers;
      let formData = new FormData();
      for (var key in data) {
        if (typeof data[key] == "object") {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }

      headers = new HttpHeaders().append(
        "Content-Disposition",
        "multipart/form-data"
      );
      var url = this.url + apiLink;
      return this.http.put<any>(url, formData, {headers: headers});
    } else {
      var url = this.url + apiLink;
      return this.http.put<any>(url, data);
    }
  }

  fileDownload(apiLink, data, isMulti, pageNumber, pageSize) {
    let headers;
    var url = this.url + apiLink + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    return this.http.post<any>(url, data, {headers: headers, responseType: 'blob' as 'json'});
  }

  /**
   Delete Function
   @@Param API , ID
   **/
  deleteData(apiLink, id) {
    var apiUrl = this.url + apiLink + id;
    var response = this.http.delete<any>(apiUrl);
    return response;
  }

  deleteDataUsersApi(apiLink, id) {
    var apiUrl = this.usersUrl + apiLink + id;
    var response = this.http.delete<any>(apiUrl);
    return response;
  }

  fileUploadWithData(link, file, data, isVersion2 = true) {
    let headers: any;
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("data", JSON.stringify(data));
    headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );

    if (isVersion2) {
      headers = new HttpHeaders().append(
        "Content-Disposition",
        "multipart/form-data"
      ).append("x-api-version", "2");
    }

    var url = this.url + link;
    return this.http.post<any>(url, formData, {headers: headers});
  }

  /**
   Query Formater
   **/
  encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }

  /**
   Query Decoder
   **/
  getQueryParamFromMalformedURL(name, url) {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(url)); // or window.location.href
    if (!results) {
      return 0;
    }
    return results[1] || 0;
  }
}
