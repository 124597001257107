import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-company-home',
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.less']
})
export class CompanyHomeComponent implements OnInit {

  urlId;
  isShowSubmenu = false;
  saveId;
  constructor(private router: Router,private route:ActivatedRoute) { }

  ngOnInit() {

  }

  onActivate(event){
     this.urlId= this.router.url.split('/');
     if(this.urlId[2]== 'users'){
       this.saveId = this.urlId[3]?this.urlId[3]:'';
     }
    if(this.urlId[2]== 'groups'){
      this.saveId = this.urlId[3]?this.urlId[3]:'';
    }
    // if(this.urlId[2]== 'campaign'){
    //   this.saveId = this.urlId[3]?this.urlId[3]:'';
    // }
  	 if(this.router.url=='/companies'){
       this.isShowSubmenu= true;
       this.saveId='';
     }else{
       this.isShowSubmenu = false;
     }
  }

}
