import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { data } from 'jquery';
import { ConfigsService } from 'src/app/services/configs.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GoalsService } from 'src/app/services/goal.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-goals-popup',
  templateUrl: './goals-popup.component.html',
  styleUrls: ['./goals-popup.component.less']
})
export class GoalsPopupComponent implements OnInit {

  goalLibraryId: any;
  isEdit: boolean = false;
  goalImage: any;
  title: any;

  onApiCall = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private configsService: ConfigsService, router: Router, formBuilder: FormBuilder,
    dialog: MatDialog, route: ActivatedRoute, private goalService: GoalsService, public dialogRef: MatDialogRef<GoalsPopupComponent>,
    private uploadGoalImage: FileUploadService, private usersService: UsersService) {
    this.goalLibraryId = data.goalLibraryId;
    this.isEdit = data.isEdit;
    this.goalTitle = data.title;
    this.title = data.title;
    this.goalImage = data.image;
    this.taskBlock = data.tasks && data.tasks.length > 0 ? data.tasks : this.taskBlock;


    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })
  }

  public show = [];
  todayDate: Date = new Date();

  public taskBlock = [
    { id: "taskInput0", task_id: "", task_name: "", task_url: "", action_type: "", cta_name: "" },
    { id: "taskInput1", task_id: "", task_name: "", task_url: "", action_type: "", cta_name: "" },
    { id: "taskInput2", task_id: "", task_name: "", task_url: "", action_type: "", cta_name: "" },
    { id: "taskInput3", task_id: "", task_name: "", task_url: "", action_type: "", cta_name: "" }
  ];

  public actionTypes: any = [
    { title: "Media", value: "media_url" },
    { title: "App Link", value: "deeplink" },
    { title: "Web Link", value: "external_url" }
  ];

  public model = {
    goal_library_id: "",
    group_ids: [],
    organisation_ids: [],
    user_ids: [],
    start_date: "",
    end_date: ""
  }

  public model_errors = {
    assign_Items: { display: false, message: "" },
    start_date: { display: false, message: "" },
    end_date: { display: false, message: "" }
  }

  validateDate() {
    if (this.model.end_date !== "" && this.model.end_date < this.model.start_date) {
      this.model_errors.end_date.display = true;
      this.model_errors.end_date.message = "End date cannot be less than start date";
    } else {
      this.model_errors.end_date.display = false;
      this.model_errors.end_date.message = "";
    }
  }

  validateStartDate() {
    if (this.model.start_date === undefined || this.model.start_date === "") {
      this.model_errors.start_date.display = true;
      this.model_errors.start_date.message = "Start date cannot be empty";
    } else {
      this.model_errors.start_date.display = false;
      this.model_errors.start_date.message = "";
    }
  }

  validateEndDate() {
    if (this.model.end_date === undefined || this.model.end_date === "") {
      this.model_errors.end_date.display = true;
      this.model_errors.end_date.message = "End date cannot be empty";
    } else {
      this.model_errors.end_date.display = false;
      this.model_errors.end_date.message = "";
    }
  }


  isValidateAssignModel() {
    if (this.model.group_ids.length === 0 &&
      this.model.organisation_ids.length === 0 &&
      this.model.user_ids.length === 0) {
      this.model_errors.assign_Items.display = true;
      this.model_errors.assign_Items.message = "Please select at least one option to assign the goal to."
    } else {
      this.model_errors.assign_Items.display = false;
      this.model_errors.assign_Items.message = ""
    }
  }


  firstScreen: boolean = true;
  secondScreen: boolean = false;
  thirdScreen: boolean = false;

  toggleScreens(firstScreen: boolean, secondScreen: boolean, thirdScreen: boolean) {
    this.firstScreen = firstScreen;
    this.secondScreen = secondScreen;
    this.thirdScreen = thirdScreen;
  }

  validateAssignments() {
    this.isValidateAssignModel();

    if (!this.model_errors.assign_Items.display) {
      this.toggleScreens(false, false, true);
    }
  }

  goalTitle: string = "";
  image: string = "";

  files: any;
  newFiles: File | null;
  uploadFile = "";
  imageFileFlag: boolean = false;
  error: any = {
    title: {
      display: false,
      message: ""
    },
    image: {
      display: false,
      message: ""
    },
    tasks: {
      display: false,
      message: ""
    }
  };
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  groups: any = [];
  groupsPageNumber: any = 1;
  groupsPageSize: any = 10;
  searchGroupsString: string = "";

  organizations: any = [];
  organizationsPageNumber: any = 1;
  organizationsPageSize: any = 10;
  searchOrganizationsString: string = "";

  users: any = [];
  usersPageNumber: any = 1;
  usersPageSize: any = 10;
  searchUsersString: string = "";

  isClosedFlag: boolean = false;
  isResolutionError: boolean = false;
  iserrorPopup: boolean = false;
  editRequest: any = {};
  error_popup = {};
  issuccessPopup: boolean = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () {
    },
  };

  ngOnInit() {
    this.getGroups();
    this.getOrganizations();
    this.getUsers();

    if (this.isEdit) {
      this.toggleScreens(true, false, false);
      this.model.goal_library_id = this.goalLibraryId;
    } else if (this.goalLibraryId !== undefined) {
      this.toggleScreens(false, true, false);
      this.model.goal_library_id = this.goalLibraryId;
    }
  }

  ngAfterViewInit() {
    var context = this;
    setTimeout(function () {
      if (context.isEdit) {
        var image = document.getElementById("imageUploadBackground");

        if (image) {
          context.imageFileFlag = true;
          image["src"] = context.goalImage;
          context.newFiles = new File([], "");
        }
      }
    },
      500);
  }

  getGroups() {
    this.goalService.getGroups(this.searchGroupsString, this.groupsPageSize, this.groupsPageNumber).subscribe((res) => {
      res.result.results.forEach((value, index) => {
        if (this.model.group_ids.indexOf(value.group_id) !== -1) {
          res.result.results[index].is_checked = true;
        }
      });

      this.groups = res.result;
    },
      (err) => {
        console.log(err);
      });
  }

  getOrganizations() {
    this.goalService.getCompanies(this.searchOrganizationsString, this.organizationsPageSize, this.organizationsPageNumber).subscribe((res) => {
      res.result.results.forEach((value, index) => {
        if (this.model.organisation_ids.indexOf(value.company_id) !== -1) {
          res.result.results[index].is_checked = true;
        }
      });
      this.organizations = res.result;
    },
      (err) => {
        console.log(err);
      });
  }

  getUsers() {
    this.usersService.getUsers(this.searchUsersString, this.usersPageSize, this.usersPageNumber).subscribe((res) => {
      res.result.results.forEach((value, index) => {
        if (this.model.user_ids.indexOf(value.userId) !== -1) {
          res.result.results[index].isChecked = true;
        }
      });
      this.users = res.result;
    },
      (err) => {
        console.log(err);
      });
  }

  addTaskBlock() {
    let nextIndex = this.taskBlock.length - 1;
    this.taskBlock.push({
      id: "taskInput" + nextIndex,
      task_id: "",
      task_name: "",
      task_url: "",
      action_type: "",
      cta_name: ""
    });
  }

  saveTemplate(isContinue) {
    let goalData = {
      title: this.goalTitle,
      image: "",
      tasks: this.taskBlock
    };
    goalData.tasks = this.filterTaskData();

    if (!this.goalTitle || !this.goalTitle.trim()) {
      this.error.title.display = true;
      this.error.title.message = "Title is required.";
      return;
    } else {
      this.error.title.display = false;
      this.error.title.message = "";
      this.goalTitle = this.goalTitle.trim()
    }

    if (goalData.tasks.length == 0) {
      this.error.tasks.display = true;
      this.error.tasks.message = "Add at least one task.";
      return;
    } else {
      this.error.tasks.display = false;
      this.error.tasks.message = "";
      this.error.task = [];

      for (let i = 0; i < goalData.tasks.length; i++) {
        this.error.task.push({});
      }

      let error = false;
      goalData.tasks.forEach((item, index) => {

        if (!item.task_name || !item.task_name.trim()) {
          //this.error.tasks.display = true;
          this.error.task[index].message = "Task Name is required.";
          error = true;
          return;
        }

        if (!item.task_url || !item.task_url.trim()) {
          //this.error.tasks.display = true;
          this.error.task[index].message = "Task Url is required.";
          error = true;
          return;
        }

        if (!item.cta_name || !item.cta_name.trim()) {
          //this.error.tasks.display = true;
          this.error.task[index].message = "Task CTA name is required.";
          error = true;
          return;
        }

        if (!item.action_type || !item.action_type.trim()) {
          ///this.error.tasks.display = true;
          this.error.task[index].message = "Task Action type is required.";
          error = true;
          return;
        }

        item.task_name = item.task_name.trim();
        item.task_url = item.task_url.trim();
        item.cta_name = item.cta_name.trim();
        item.action_type = item.action_type.trim();
      });

      if (error)
        return;

    }


    // if ((Object.keys(goalData).length > 0)) {
    //   if (!(Object.keys(goalData.tasks).length > 0)) {
    //     this.error.tasks.display = true;
    //     this.error.tasks.message = "Add at least one task.";
    //     return;
    //   }
    //
    //   if (goalData.tasks.some(x => x.task_url === '' || x.task_url === undefined)) {
    //     this.error.tasks.display = true;
    //     this.error.tasks.message = "Task Url is required.";
    //     return;
    //   }
    //
    //   if (goalData.tasks.some(x => x.cta_name === '' || x.cta_name === undefined)) {
    //     this.error.tasks.display = true;
    //     this.error.tasks.message = "CTA name is required.";
    //     return;
    //   }
    //
    //   if (goalData.tasks.some(x => x.action_type === '' || x.action_type === undefined)) {
    //     this.error.tasks.display = true;
    //     this.error.tasks.message = "Action type is required.";
    //     return;
    //   }
    //
    //   this.error.tasks.display = false;
    //   this.error.tasks.message = "";
    // } else return;

    if (this.newFiles !== undefined && this.newFiles !== null) {
      this.error.image.display = false;
      this.error.image.message = "";
    } else {
      this.error.image.display = true;
      this.error.image.message = "Please select an image for upload.";
      return;
    }

    if (this.isResolutionError) {
      this.error.image.display = true;
      this.error.image.message = "Minimum file resolution should be 500 X 500.";
      return;
    } else {
      this.error.image.display = false;
      this.error.image.message = "";
    }

    this.configsService.loading(true);

    if (this.isEdit) {
      this.editRequest.goals_library_id = this.goalLibraryId;
      this.editRequest.title = this.goalTitle;
      this.editRequest.image = this.goalImage;

      if (this.goalTitle !== this.title) {
        this.editRequest.is_title_changed = true;
      } else {
        this.editRequest.is_title_changed = false;
      }

      let taskData = goalData.tasks;
      let tasksForEdit = [];

      if (taskData && taskData.length > 0) {
        taskData.forEach(function (item) {
          let taskAdd = { task_id: "", task_name: "", task_url: "", action_type: "", c_t_a_name: "" };

          taskAdd.task_id = item.task_id ? item.task_id : "00000000-0000-0000-0000-000000000000";
          taskAdd.task_name = item.task_name;
          taskAdd.task_url = item.task_url;
          taskAdd.action_type = item.action_type;
          taskAdd.c_t_a_name = item.cta_name;

          tasksForEdit.push(taskAdd);
        });
      }

      this.editRequest.task_data = tasksForEdit;
      this.editRequest.is_image_changed = false;
      this.editRequest.Source = "Library";

      if (this.newFiles.name !== '') {
        this.uploadGoalImage.uploadGoalImage(this.newFiles).subscribe((res) => {
          this.editRequest.image = res.result.path;
          this.editRequest.is_image_changed = true;

          this.goalService.updateGoals(this.editRequest).subscribe((res) => {
            this.configsService.loading(false);
            if (res.has_error) {
              this.showToast(true, res.errors[0]);
              return;
            } else {
              this.showToast(false, "Goal template updated successfully.");
            }
          },
            (err) => {
              this.configsService.loading(false);
            });
        },
          (err) => {
            this.configsService.loading(false);
          });
      } else {
        this.goalService.updateGoals(this.editRequest).subscribe((res) => {
          this.configsService.loading(false);
          if (res.has_error) {
            this.showToast(true, res.errors[0]);
            return;
          } else {
            this.showToast(false, "Goal template updated successfully.");
          }
        },
          (err) => {
            this.configsService.loading(false);
          });
      }
    } else {
      this.uploadGoalImage.uploadGoalImage(this.newFiles).subscribe((res) => {
        goalData.image = res.result.path;

        if ((Object.keys(goalData).length === 0)) return;
        this.goalService.createGoal(goalData).subscribe((res) => {
          this.configsService.loading(false);
          if (res.has_error) {
            this.showToast(true, res.errors[0]);
            return;
          } else
            this.model.goal_library_id = res.result.goals_library_id;
          if (isContinue) {
            this.toggleScreens(false, true, false);
          } else {
            this.showToast(false, "Goal template created successfully.");
          }
        },
          (err) => {
            this.configsService.loading(false);
          });
      },
        (err) => {
          this.configsService.loading(false);
        });
    }
  }

  private filterTaskData() {
    let filteredGoals = [];
    this.taskBlock.forEach((value) => {
      if (value.task_name !== '')
        filteredGoals.push(value);
    });
    return filteredGoals;
  }

  taskUrlToggle(id: string) {
    if (!this.show.includes(id)) {
      this.show.push(id)
    } else {
      this.RemoveElementFromStringArray(id);
    }
  }

  RemoveElementFromStringArray(element: string) {
    this.show.forEach((value, index) => {
      if (value == element) this.show.splice(index, 1);
    });
  }

  closeModal() {
    this.dialogRef.close({
      confirm: false
    });
  }

  removeFiles() {
    this.files = "";
    this.uploadFile = "";
    this.newFiles = null;
    this.imageFileFlag = false;
    this.error.image.display = false;
    this.error.image.message = "";
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files[0]);
  }

  prepareFilesList(file: any) {
    if (file.size === undefined) {
      file = file[0];
    }
    let maxFileSize = 1024 * 1024 * 2;

    if (file.size > maxFileSize) {
      this.newFiles = null;
      this.error.image = {
        display: true,
        message: "Maximum file size supported is 2mb",
      };
    } else if (
      file.type != "image/jpeg" &&
      file.type != "image/png" &&
      file.type != "image/jpg"
    ) {
      this.files = "";
      this.error.image = {
        display: true,
        message: "Type of file must be - .png or .jpg",
      };
    } else {
      this.error.image = {
        display: false,
        message: "",
      };
      this.newFiles = file;
      this.imageFileFlag = true;
      var image = document.getElementById("imageUploadBackground");
      image["src"] = URL.createObjectURL(file);
    }
  }

  onImageLoad(event) {
    let height = event.currentTarget.naturalHeight;
    let width = event.currentTarget.naturalWidth;

    if (height * width < 500 * 500) {
      this.isResolutionError = true;
    } else
      this.isResolutionError = false;
  }

  addGroupToModel(group, $event) {
    if (group.is_checked && this.model.group_ids.indexOf(group.group_id) === -1) {
      this.model.group_ids.push(group.group_id);
    } else if (!group.is_checked && this.model.group_ids.indexOf(group.group_id) !== -1) {
      this.model.group_ids.forEach((value, index) => {
        if (value == group.group_id) this.model.group_ids.splice(index, 1);
      });
    }
    this.isValidateAssignModel();
  }

  addOrganizationToModel(organization, $event) {
    if (organization.is_checked && this.model.organisation_ids.indexOf(organization.company_id) === -1) {
      this.model.organisation_ids.push(organization.company_id);
    } else if (!organization.is_checked && this.model.organisation_ids.indexOf(organization.company_id) !== -1) {
      this.model.organisation_ids.forEach((value, index) => {
        if (value == organization.company_id) this.model.organisation_ids.splice(index, 1);
      });
    }

    this.isValidateAssignModel();
  }

  addUserToModel(user, $event) {
    if (user.isChecked && this.model.user_ids.indexOf(user.userId) === -1) {
      this.model.user_ids.push(user.userId);
    } else if (!user.is_checked && this.model.user_ids.indexOf(user.userId) !== -1) {
      this.model.user_ids.forEach((value, index) => {
        if (value == user.userId) this.model.user_ids.splice(index, 1);
      });
    }
    this.isValidateAssignModel();
  }

  // changeStartDate(){

  // }

  filterUsersData(params) {
    this.searchUsersString = params[0];
    this.usersPageNumber = 1;
    this.getUsers();
  }

  filterGroupsData(params) {
    this.searchGroupsString = params[0];
    this.groupsPageNumber = 1;
    this.getGroups();
  }

  filterOrganizationsData(params) {
    this.searchOrganizationsString = params[0];
    this.organizationsPageNumber = 1;
    this.getOrganizations();
  }

  paginateUsers(event) {
    this.usersPageNumber = (event.page + 1);
    this.getUsers();
  }

  paginateGroups(event) {
    this.groupsPageNumber = (event.page + 1);
    this.getGroups();
  }

  paginateOrganization(event) {
    this.organizationsPageNumber = (event.page + 1);
    this.getOrganizations();
  }

  save() {
    if (this.model.start_date === undefined || this.model.end_date === undefined ||
      this.model.start_date === "" || this.model.end_date === "") {
      this.validateStartDate();
      this.validateEndDate();
      return;
    }
    this.validateDate();


    if (this.model_errors.start_date.display || this.model_errors.end_date.display) return;

    this.goalService.assignGoals(this.model).subscribe((res) => {
      if (res.has_error) {
        this.showToast(true, res.errors[0]);
        return;
      } else {
        this.showToast(false, "Goal assigned successfully");
        this.isClosedFlag = true;
      }
    },
      (err) => {
      });
  }

  toggleToFirstScreens() {
    this.toggleScreens(true, false, false);
  }

  isSuccessPopup: boolean = false;
  isErrorPopup: boolean = false;
  isConfirmPopup = false;
  errorMessage = '';


  showToast(isError, message) {
    if (isError) {
      this.isErrorPopup = true;
      this.error_popup = { display: true, message: message };
    } else {
      this.isSuccessPopup = true;
      this.success_popup = {
        display: true,
        message: message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.closeModal();
        },
      };
    }
  }
}


