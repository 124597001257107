import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-lesson-finished',
  templateUrl: './lesson-finished.component.html',
  styleUrls: ['./lesson-finished.component.less']
})
export class LessonFinishedComponent implements OnInit {

  timeRanOut: boolean = false;

  constructor(public dialogRef: MatDialogRef<LessonFinishedComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.timeRanOut = data;
   }

  ngOnInit() {
  }

  closeModal(event) {
    this.dialogRef.close();
  }

}
