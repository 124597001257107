import { Component, OnInit } from "@angular/core";
import { GleacColumnDef } from '../data-grid/data-grid.model';
import { FormControl, FormGroup } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { UserType } from "../common/common.model";
import { ConfigsService } from '../../services/configs.service';
import { ReportService } from 'src/app/services/report.service';
import { saveAs } from 'file-saver';

@Component({
    selector: "streak-report",
    templateUrl: "./streak-report.component.html",
    styleUrls: ["./streak-report.component.less"]
})
export class StreakReportComponent implements OnInit {
    today = new Date();
    yesterday = new Date();
    filterForm: FormGroup = new FormGroup({
        fromDate: new FormControl(this.yesterday),
        toDate: new FormControl(this.today),
        minStreak: new FormControl(1)
    });
    reportData;
    error_popup = { display: false, message: null };
    transactionsGridData: any;
    transactionsColumnData: GleacColumnDef[] = [
        { data: "firstName", displayName: "First Name" },
        { data: "lastName", displayName: "Last Name" },
        { data: "email", displayName: "Email" },
        { data: "streak", displayName: "Streak" },
        { data: "streakDate", displayName: "Streak Date" }
    ];
    page_title = "";
    pageSize = 50;
    loading = false;

    constructor(public usersService: UsersService, private configsService: ConfigsService, private reportService: ReportService) {
    }

    ngOnInit() {
        this.yesterday.setDate(this.yesterday.getDate() - 1);
        this.usersService.isMeasure = true;

        if (this.usersService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.usersService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
            return;
        }
        this.filterData(this.filterForm.value);
    }

    filterData(filters) {
        if (this.usersService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.usersService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
            return;
        }
        this.reportData = filters;

        if (filters.toDate && filters.fromDate && filters.toDate <= filters.fromDate) {
            this.error_popup = { display: true, message: "Invalid Date Range" };
            return;
        }

        this.configsService.loading(true);
        this.usersService.currentUser.company = this.usersService.currentUser.company ? this.usersService.currentUser.company : null;

        this.reportService
            .getStreakReport(filters.fromDate, filters.toDate, filters.minStreak)
            .subscribe(
                (data) => {
                    let dataList = {
                        list: data.result,
                        totalCount: data.result.length
                    }
                    dataList.list = dataList.list.map(x => ({ ...x, streakDate: x.streakDate.split('T')[0] }));
                    this.transactionsGridData = dataList;
                    this.configsService.loading(false);
                },
                (err) => {
                    this.configsService.loading(false);
                    this.error_popup = { display: true, message: err.error.errors[0] };
                },
                () => {
                    this.configsService.loading(false);
                }
            );
    }

    downloadReport(filters) {
        this.configsService.loading(true);
        this.reportService.exportStreakReport(
            filters.fromDate, filters.toDate, filters.minStreak
        ).subscribe(
            (res) => {
                if (res) {
                    saveAs(res, "StreakReport" + '.xlsx');
                }
            },
            (err) => {
                this.configsService.loading(false);
                this.error_popup = { display: true, message: err.error.errors[0] };
            },
            () => {
                this.configsService.loading(false);
            }
        );
    }
}