import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfigsService} from 'src/app/services/configs.service';
import {AssignUserToCompanyService} from 'src/app/services/assign-user-to-company.service';
import {data, param} from 'jquery';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from "../../services/common.service";
import {MentorService} from "../../services/mentor.service";
import {NftBundleService} from "../../services/nft-bundle.service";

@Component({
  selector: 'app-add-nft-bundle',
  templateUrl: './add-nft-bundle.component.html',
  styleUrls: ['./add-nft-bundle.component.less']
})
export class AddNftBundleComponent implements OnInit {
  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('nftmentorsList', {static: true}) private myScrollContainer: ElementRef;
  @ViewChild('auca', {static: false}) paginator: any;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() nftBundleData?: any;
  title = "Add/Edit Nft Bundle";
  userHover: number = null;
  nftMentors = [];
  mentorList = [];
  assignUsers = {};
  companyId;
  lessonCampaign;
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };

  onApiCall = false;

  error: any = {};
  data: any = {
    id: '',
    name: '',
    openSeaLink: '',
    bundleMentors: []
  };


  constructor(private configsService: ConfigsService,
              private mentorService: MentorService,
              private nftService: NftBundleService,
              protected route: ActivatedRoute) {

    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })
  }

  ngOnInit() {
    this.mentorList = [];
    this.route.params.subscribe((params) => {
      this.companyId = params["id"];
      this.lessonCampaign = params["campid"];
    });

    if (this.nftBundleData) {
      this.data = this.nftBundleData;
    }

    this.getNftMentors();
  }

  getNftMentors() {
    this.configsService.loading(true);
    this.mentorService.getAllNftMentorv2(this.pageNumber, this.searchString ? encodeURIComponent(this.searchString) : '').subscribe((data) => {
        this.configsService.loading(false)
        if (data.statusCode === 200) {
          if (data.result.data.length > 0) {

            data.result.data.forEach((item) => {
              if (this.data.bundleMentors.includes(item.mentorId)) {
                item.isSelected = true;
              }
            });

            this.nftMentors = data.result.data;
            this.totalCount = data.result.totalCount;
          } else {
            this.nftMentors = [];
            this.totalCount = 0;
          }
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  closeModel(ent) {
    this.isDisplay = false;
    this.onClose.emit();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getNftMentors();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  mouseEnter(value) {
    this.userHover = value;
  }

  mouseleave() {
    this.userHover = null;
  }

  filterData(params) {
    this.searchString = params[0];
    this.paginator.changePage(0);
  }

  addMentor(mentor, event) {
    let index = this.data.bundleMentors.findIndex(d => d === mentor.mentorId); //find index in your array

    if (index >= 0) {
      this.data.bundleMentors.splice(index, 1);//remove element from array
    } else {
      this.data.bundleMentors.push(mentor.mentorId);
    }
  }

  save() {
    this.error = {};
    if (!this.data.name) {
      this.error.name = true;
      return;
    }

    if (!this.data.openSeaLink) {
      this.error.openSeaLink = true;
      return;
    }

    let regex = /^[a-zA-Z.]+(?:\s+[a-zA-Z]+)*$/g;

    if (!regex.test(this.data.name)) {
      this.error.name = true;
      return;
    }

    if (this.data.bundleMentors.length < 2) {
      this.error.bundleMentors = true;
      return;
    }

    this.configsService.loading(true);

    if (this.data.id) {
      this.nftService.updateNftBundles(this.data).subscribe(
        (data) => {
          this.configsService.loading(false);
          this.isDisplay = false;
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: "Saved successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.issuccessPopup = false;
              this.onClose.emit();
            }
          }
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
    } else {
      this.nftService.createNftBundles(this.data).subscribe(
        (data) => {
          this.configsService.loading(false);
          this.isDisplay = false;
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: "Saved successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.issuccessPopup = false;
              this.onClose.emit();
            }
          }
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
    }
  }
}
