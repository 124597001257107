import { Component, OnInit } from '@angular/core';
import { GleacColumnDef } from '../data-grid-new-dashboard/data-grid-new-dashboard.model';
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from 'src/app/services/configs.service';
import { SkillTags, SkillType } from '../common/common.model';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { from } from 'rxjs';
import { appSettings } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UsersService } from "../../services/users.service";
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-lesson-library',
  templateUrl: './lesson-library.component.html',
  styleUrls: ['./lesson-library.component.less']
})
export class LessonLibraryComponent implements OnInit {

  pageUrl: string = "lessons";
  page_title = "Lesson Library";
  pageLimit: number = 10;
  searchString: string = "";
  pageNumber: number = 1;
  error_popup = {};
  url = "";
  iserrorPopup: boolean = false;
  issuccessPopup: boolean = false;
  lessonGridData: any;
  iscreatePopup: boolean = false;
  isconfirmPopup = false;
  form: any;
  status = "";
  confirm = {
    display: false,
    message: "",
    buttonPositive: "",
    buttonNegative: "",
    isTopic: "",
    imageUrl: "",
    positiveButton: function () {
    },
    negativeButton: function () {
    },
  };
  filterArray: any = [
    {
      label: "Status",
      type: "static_select",
      key: "status",
      options: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
      ],
    }
  ];
  create_popup = {
    display: false,
    bulk_upload: false,
    title: "",
    cssClass: '',
    createrName: '',
    buttonPositive: "",
    field: [],
    closeModal: function () {
    },
    createrCall: function (form, skillData, uploadFileName) {
    },
  };
  imageStorageUrl: string = appSettings.imageStoreUrl;
  currentTime: Date;
  skillTypeOptions = [];
  skillTagsOptions = [];

  sortColumn: string = "";
  sortOrder: string = "";

  constructor(
    private lessonsService: LessonsService,
    private configsService: ConfigsService,
    protected router: Router,
    private commonService: CommonService,
    public userService: UsersService
  ) {
  }

  ngOnInit() {
    this.setSkillType();
    this.setTags();
    this.getLessonList();
  }

  lessonColumnData: GleacColumnDef[] = [
    {
      displayName: "Category Name",
      data: "lessonCategoryName",
      isExpandable: true,
      render: (data, row) => {
        return `<div class="lesson_section"><img class="logo" width="60px" height="30px" src="${row.imageSrc
          }"/> <span > <img class="ml-3 mr-2" style="color:#0065AD" src="assets/images/eye.png"/> </span><span class="comanyname ml-2" style="color:#0065AD">${row.lessonCategoryName}</span></div>`;
      },
    },
    {
      displayName: "Category Summary",
      data: "skillSummary",
      isSummeryAction: true,
      render: (data, row) => {
        return `
        <span class="float-left" style="height : 17px;width:340px; overflow: hidden" >${row.skillSummary}</span>`;
      },
    },
    {
      displayName: "Type", data: "skillType",
      render: (data, row) => {
        return `<span >${SkillType[row.skillType]}</span>`
      }
    },
    {
      displayName: "Tags", data: "tags",
      isTagsAction: true,
      disableSort: true,
      render: (data, row) => {
        let tag = (row.tags && row.tags.length > 0) ? row.tags[0] : ''
        return `
        <span class="float-left" style="height : 17px;width:70px; overflow: hidden" >${tag}</span>`;
      },
    },
    {
      data: "isActive",
      displayName: "Status",
      render: (data, row) => {
        if (row.isActive) {
          return (
            '<div><span class="dot gleac-badge-text success mr-2"></span><span>Active</span></div>'
          );
        } else {
          return (
            '<div><span class="dot gleac-badge-text danger mr-2"></span><span> InActive</span></div>'
          );
        }
      },
    },
    {
      displayName: "Actions",
      isAction: true,
      disableSort: true,
      dropDownLabels: [
        {
          label: "Edit",
          url: "#",
          functionType: "edit",
          image: "assets/images/edit_icon.svg",
          permission: 'Lesson_Add'
        },
        {
          label: "Delete",
          url: "#",
          functionType: "delete",
          image: "assets/images/delete_icon.svg",
          permission: 'Lesson_Delete'
        },
      ],
    },
  ];

  getLessonList() {
    this.configsService.loading(true);
    this.lessonsService.getLessonCategoriesNewDashboard(
      this.pageNumber,
      this.pageLimit,
      this.status,
      encodeURIComponent(this.searchString),
      this.sortColumn,
      this.sortOrder
    ).subscribe(
      (res) => {
        if (res) {
          if (res) {
            let lessonData = {
              list: res.result.data,
              totalCount: res.result.totalRecords
            }
            this.lessonGridData = lessonData;
          } else {
            this.lessonGridData = [];
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  filterData(params) {
    this.searchString = params[0].trim();
    this.pageNumber = 1;
    this.getLessonList();
  }

  sortChange(event) {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.pageNumber = 1;
    this.getLessonList();
  }

  customFilter(params) {
    let queryParam;
    let query;
    if (params[0]) {
      let data = params[1]["value"];
      for (var key in data) {
        queryParam = data[key];
      }
    } else {
      queryParam = params[0];
    }
    this.status = queryParam;
    this.pageNumber = 1;
    this.getLessonList();
  }

  pageChange(event) {
    this.pageNumber = event;
    this.getLessonList();
  }

  actionClick(event) {
    if (event.action === "edit") {
      this.addSkill(event.data);
    } else if (event.action === "delete") {
      this.deletelessonCategory(event.data);
    }
  }

  deletelessonCategory(data) {
    console.log("data", data);
    this.isconfirmPopup = true;
    this.confirm = {
      display: true,
      message: "Are you sure you want to Delete?",
      buttonPositive: "Yes",
      buttonNegative: "No",
      isTopic: "Lesson",
      imageUrl: "",
      negativeButton: () => {
        this.confirm.display = false;
        // this.configService.invokeDataPassing('');
      },
      positiveButton: () => {
        this.configsService.loading(true);
        this.lessonsService.deletelessonCategory(data.lessonCategoryId).subscribe(
          (data) => {
            // this.postStatus(data);
            this.pageNumber = 1;
            this.getLessonList();
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
          }
        );
        this.confirm.display = false;
        // this.configService.invokeDataPassing(this.list);
      },
    };
  }

  lessonDetails(event) {
    console.log("event", event);
    let route = "lessons/" +
      event.data.lessonCategoryId +
      "/new";
    if (event.data.skillType === 4) {
      route = "challenge/" +
        event.data.lessonCategoryId + "/new";
    }
    this.router.navigate([route]);
  }

  setSkillType() {
    for (var item in SkillType) {
      if (isNaN(Number(item))) {
        this.skillTypeOptions.push({
          value: SkillType[item],
          name: item
        });
      }
    }
  }

  setTags() {
    for (var item in SkillTags) {
      if (isNaN(Number(item))) {
        this.skillTagsOptions.push({
          value: SkillTags[item],
          name: SkillTags[item]
        });
      }
    }
  }

  addSkill(data) {

    let skillType = {};
    let tagsFromBackend = [];


    this.skillTagsOptions.forEach(item => {
      item.isChecked = false;
    });

    this.skillTypeOptions.forEach(item => {
      item.isChecked = false;
    });

    if (data) {
      this.skillTypeOptions.forEach(item => {
        if (item.value === data.skillType) {
          item.isChecked = true;
        }
      });

      skillType = { isChecked: true, name: SkillType[data.skillType], value: data.skillType };
    }

    if (data) {
      if (data.tags) {
        let tagString = String(data.tags);
        if (tagString) {
          let tagsSplit = tagString.split(",");
          tagsFromBackend = [];
          tagsSplit.forEach(tag => {

            tagsFromBackend.push({ isChecked: true, name: tag.trim(), value: tag.trim() });

            this.skillTagsOptions.forEach(item => {
              if (item.value.trim() === tag.trim()) {
                item.isChecked = true;
              }
            });
          });
        }
      }
    }

    this.iscreatePopup = true;
    this.create_popup = {
      display: true,
      bulk_upload: false,
      cssClass: 'col-12',
      createrName: "CreateSkillForm",
      title: data ? "Edit Skill" : "Create Skill",
      buttonPositive: data ? "Update" : "Create",
      field: [
        {
          label: "Skill Name",
          key: "lessonCategoryName",
          placeholder: "Enter the category name",
          type: "input",
          cssClass: 'col-6',
          innertype: "text",
          required: "required",
          value: data ? data.lessonCategoryName : "",
        },
        {
          label: "Skill Image",
          key: "imageFile",
          placeholder:
            "Maximum file size 2mb, and type of file must be PNG or JPG. Minimum resolution is 500 X 500.",
          type: "image_upload",
          required: "required",
          cssClass: 'col-6',
          value: data ? data.imageSrc : ''
        },
        {
          label: "Skill Summary",
          key: "skillSummary",
          placeholder: "Type Summary",
          type: "textarea",
          innertype: "text",
          cssClass: 'col-6 setPosition',
          height: "142px",
          required: "required",
          value: data ? data.skillSummary : "",
        },

        {
          label: "Skill Type",
          key: "SkillType",
          placeholder: "Select Skill",
          type: "dropdown",
          bindLabel: "name",
          multiple: false,
          cssClass: 'col-6',
          value: data ? skillType : { name: "GENERIC", value: 0, isChecked: true },
          required: "required",
          options: this.skillTypeOptions,
          apiUrl: "",
        },
        // {
        //   label: "Lesson per day",
        //   key: "PerDayLessons",
        //   placeholder: "0",
        //   type: "input",
        //   cssClass: 'col-3',
        //   innertype: "number",
        //   required: "required",
        //   value: data.perDayLessons ? data.perDayLessons : ''
        // },
        {
          label: "Status",
          key: "IsActive",
          //required: "required",
          placeholder: "0",
          cssClass: 'col-3',
          value: data.isActive ? data.isActive : false,
          type: "switchButton",
        },
        {
          label: "Tags",
          key: "Tags",
          placeholder: "Type your keywords",
          type: "dropdown",
          bindLabel: "name",
          //required: "required",
          multiple: true,
          cssClass: 'col-12',
          value: tagsFromBackend,
          options: this.skillTagsOptions,
          apiUrl: "",
        },
      ],
      closeModal: () => {
        this.iscreatePopup = false;
      },
      createrCall: (form: any, skillData, uploadedFileName) => {
        let lessonCategory = Object.assign(form.value);
        lessonCategory.SkillType = form.value.SkillType.value;
        lessonCategory.companyId = "6C6CB0C8-4A3D-48A9-9049-71867E26B6DA";


        lessonCategory.Tags = form.value.Tags.map((item) => {
          return item.value;
        });

        if (lessonCategory.Tags && lessonCategory.Tags.length == 0) {
          delete lessonCategory.Tags;
        }

        if (!lessonCategory.imageFile.name) {
          lessonCategory.imageSrc = data.imageSrc;
          lessonCategory.imageFile = null;
        } else {
          lessonCategory.imageSrc = this.imageStorageUrl + lessonCategory.imageFile.name;
        }

        lessonCategory.lessonCategoryId = data ? data.lessonCategoryId : "";

        if (form.valid) {

          if (data) {
            this.configsService.loading(false);
            this.lessonsService.updateSkillDetails(lessonCategory).subscribe(
              (data) => {
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
                this.pageNumber = 1;
                this.getLessonList();
              }
            );

          } else {

            this.configsService.loading(false);
            this.lessonsService.addLessonCategory(lessonCategory).subscribe(
              (data) => {
              },
              (err) => {
                this.configsService.loading(false);
                this.iserrorPopup = true;
                this.error_popup = {
                  display: true,
                  message: err.error.errors[0],
                };
              },
              () => {
                this.configsService.loading(false);
                form.reset();
                this.iscreatePopup = false;
                this.getLessonList();
              }
            );
          }
        }
      },
    };
  }

  exportToExcel() {
    this.configsService.loading(true);
    this.lessonsService.ExportLessonCategoriesNewDashboard(
      this.pageNumber,
      this.pageLimit,
      this.status,
      encodeURIComponent(this.searchString)
    ).subscribe(
      (res) => {
        saveAs(res, "LessonLibrary" + '.xlsx');
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }
}
