import {AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AssignMentorService} from 'src/app/services/assign-mentor.service';
import {ConfigsService} from 'src/app/services/configs.service';

@Component({
  selector: 'app-assign-mentor',
  templateUrl: './assign-mentor.component.html',
  styleUrls: ['./assign-mentor.component.less']
})
export class AssignMentorComponent implements OnInit {

  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @Output() mentorClose: EventEmitter<any> = new EventEmitter();
  @ViewChild('am', {static: false}) paginator: any;
  title = "Mentor";
  userHover: number = null;
  users = [];
  newUsers = []
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };

  onApiCall = false;

  constructor(private assignMentorService: AssignMentorService,
              private configsService: ConfigsService) {

    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    });
  }

  userRole = {
    label: "",
    key: "level",
    placeholder: "Select",
    type: "dropdown",
    bindLabel: "name",
    required: "required",
    multiple: false,
    value: [],
    options: [
      {name: "Global", value: 1},
      {name: "Comapny", value: 2}
    ],
    apiUrl: "",
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {

    this.configsService.loading(true);
    this.assignMentorService.getUser(this.pageLimit, this.pageNumber, this.searchString).subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.results.length) {
            this.users = data.result.results;

            let context = this;
            this.users.forEach(function (item) {
              let index = context.newUsers.findIndex(d => d.userId === item.userId);
              //find index in your array
              item.isNewMentor = item.isMentor ? true : false;

              if (index >= 0) {
                item.isNewMentor = true;

              }
            });

            this.totalCount = data.result.totalCount;
          } else {
            this.users = data.result.results;
            this.totalCount = data.result.totalCount;
          }

          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  closeModel(ent) {
    this.isDisplay = false;
    this.mentorClose.emit();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getUser();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  mouseEnter(value) {
    this.userHover = value;
  }

  mouseleave() {
    this.userHover = null;
  }

  filterData(params) {
    this.searchString = params[0];
    this.pageNumber = 1;
    //this.getUser();
    this.paginator.changePage(0);
  }

  selecteChange(user, index) {
    let found = this.newUsers.find((item) => {
      if (item.userId === user.userId) {
        return true;
      }
    })
    if (found) {
      let type = this.userRole.value[index].value;
      this.addNewUser(user, type);
    }
  }

  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d.userId === user.userId); //find index in your array

    if (index >= 0) {
      this.newUsers.splice(index, 1);//remove element from array
    } else {
      this.newUsers.push({userId: user.userId, MentorType: 1})
    }
  }


  saveMentor() {
    this.configsService.loading(true);
    this.assignMentorService.assignMentor(this.newUsers).subscribe((data) => {
        if (data.statusCode === 200) {
          this.configsService.loading(false);
          this.isDisplay = false;
          this.issuccessPopup = true;
          this.success_popup = {
            display: true,
            message: "Saved successfully",
            buttonPositive: "Okay",
            positiveButton: () => {
              this.success_popup.display = false;
              this.issuccessPopup = false;
              this.mentorClose.emit();
            }
          }
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      })
  }
}
