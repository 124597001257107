export const yoma_courses = {
  "Collaboration": [
      {
          "name": "\ud83d\udee1\ufe0f How to deal with Cyberbullying \ud83d\udeab",
          "desc": "\ud83d\udeab\ud83c\udf10 Brace yourself for a power-packed session on cyberbullying and how to combat it like a pro! Let's make the digital world a kinder and safer space for everyone! \ud83c\udf10 Baffled by what cyberbullying really is and how it differs from the traditional playground bullying? \ud83d\udcd6\ud83e\udd14 This course has got your back! You'll also be equipped with the tools to tackle any online bullying situation like a champ. \ud83d\udcaa\ud83d\udc41\u200d\ud83d\udde8 By the end of this course, you'll be a certified cyberbullying warrior, armed with these crucial skills: \ud83d\udcd6\ud83e\udd14 Clear understanding of what cyberbullying entails \ud83c\udf1f\ud83d\udc65 Knowledge of the nuances between online and offline bullying dynamics \ud83c\udd98\ud83d\udc41\u200d\ud83d\udde8 Confidence in knowing exactly what to do if you're a victim or a witness of cyberbullying \ud83d\udcaa\ud83d\udeab Let's unite and take a stand against cyberbullying, one click at a time! Join the incredible community at \"atingi\" and be part of the e-learning revolution! \ud83c\udf0d\ud83d\udca1 Here's your step-by-step guide to dive right in: Hit that \"Go to Opportunity\" button to teleport straight to atingi. \ud83d\ude80 Sign up using your email and a password, and be on the lookout for your verification email. \ud83d\udcdd Explore the wide array of atingi opportunities once you're in! Psst, all the courses are absolutely free. \ud83c\udd93 Conquer the course, grab that shiny atingi certificate, and flaunt it proudly on Yoma with a victorious shout-out \"I'm a cyberbullying fighter!\" \ud83d\udcaa\ud83c\udf93 Exchange your hard-earned Zlto rewards for some cool goodies wherever you can. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't let this chance slip away. Together, we can build a safer internet for all! \ud83d\ude80",
          "link": "https://online.atingi.org/enrol/index.php?id=4159",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Fundamentals of Housekeeping \ud83c\udfe0\ud83e\uddf9",
          "desc": "\ud83c\udfe8\ud83d\udd11 Get ready to master housekeeping! This self-paced course deepens your understanding of housekeeping operations, collaboration with other departments, and key processes. Embark on a professional journey, gain valuable insights, and earn an open badge upon course completion. \ud83c\udf93\ud83d\udcbc Part of the \"Fundamentals of Tourism and Hospitality\" pathway: Tourism & Hospitality, Food & Beverage Business, Soft Skills, Customer Service, and Housekeeping. \ud83c\udf1f\ud83d\udd11Choose the full series or individual courses! \ud83d\ude80 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join now: 1.Click \"Go to Opportunity\" to join atingi. \ud83d\ude80 2.Register with your email and password. Verify your email. \ud83d\udcdd 3.Explore all free atingi courses. \ud83c\udd93 4.Successfully complete the course, earn your atingi certificate, and showcase on Yoma with \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5.Redeem Zlto rewards for achievements. \ud83d\udcb0\ud83d\udcab Learning and earning made easy with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3961",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Soft Skills for Tourism Business \ud83c\udf1f\ud83d\uddfa\ufe0f",
          "desc": "\ud83c\udf1f Unlock the power of soft skills! This self-paced course explores key interpersonal skills that drive success in tourism and hospitality. Elevate teamwork, time management, empathy, and communication. Complete in 1 hour and earn an open badge. \ud83c\udf93\ud83d\udcbc Part of the \"Fundamentals of Tourism and Hospitality\" pathway: Tourism, Food & Beverage, Soft Skills, Customer Service, and Housekeeping. \ud83c\udf0d\ud83d\udd11 Choose full series or individual courses! \ud83d\ude80 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Click \"Go to Opportunity\" to redirect to atingi. \ud83d\ude80 Register and verify your email. \ud83d\udcdd Explore all free atingi courses. \ud83c\udd93 Complete the course, earn your atingi certificate, and showcase on Yoma. \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards for achievements. \ud83d\udcb0\ud83d\udcab Learning and earning made easy with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=4181",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\u2694\ufe0f\ud83e\udd1d Conflict Management \ud83d\udcbc\u2728",
          "desc": "\ud83c\udf1f\ud83d\udd04 Embrace the power of change! This course offers: \u2022 Understanding the fear of change. \ud83d\ude28\ud83d\udd04 \u2022 Real-world tools for navigating change in your business. \ud83d\udee0\ufe0f\ud83d\ude80 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=71",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcca\ud83d\udd0d Evaluation, Implementation & Monitoring \ud83d\ude80\ud83d\udd27",
          "desc": " \ud83d\udc69\u200d\ud83d\udcbc\ud83d\ude80 Join this course for young professionals and aspiring entrepreneurs! Topics include: \u2022 Setting up and managing an effective project team \ud83e\udd1d\u2728 \u2022 Monitoring a project's progress \ud83d\udcca\ud83d\udd0d \u2022 Evaluating a project's success \ud83c\udfaf\ud83d\udcc8 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=239",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd25\ud83e\udd1d Action Network \ud83c\udf10\ud83d\ude80",
          "desc": "Unlock your networking potential! Join our course to boost personalized learning, skill development, and career growth through meaningful connections. Create a personal vision \u2728, identify areas for growth \ud83d\udcc8, and build your action network \ud83e\udd1d. Together, let's turn your ideas into actions and create mutually beneficial opportunities for growth. Get ready to network your way to success! \ud83c\udf1f\ud83d\udcbc\ud83d\udd25\ud83d\udcaa Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Follow these simple steps: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=170",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "English for Information Technology part1\ud83c\udf10",
          "desc": "Unlock the Power of Stakeholder Mastery \ud83c\udf1f Gain real-world interpersonal skills, prioritize effectively, and engage like a pro. Equip for a successful career! \ud83c\udf1f Perks: Essential for a lasting professional journey. \ud83c\udfaf Hone Universal Skills: Master transferable stakeholder abilities across industries. \u26a0\ufe0f Avoid Common Pitfalls: Get educated on stakeholder pitfalls. \ud83d\udd27 Support students in practicing stakeholder interview techniques. Let's get you started with these simple steps: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" and be redirected to the exciting CISCO course portal. 2\ufe0f\u20e3 On the right side of the website, enter your credentials: First Name, Last Name, and Email. Then hit \"\"Next Account Details\"\" to provide more personal information! 3\ufe0f\u20e3 Check your Email Inbox for the Successful Enrollment Notification using the provided email address. 4\ufe0f\u20e3 Click on the Course Portal link and assign a password and start your learning journey! \ud83c\udf89 Congratulations! You now have access to the course. Get ready to dive into the class activities! \ud83d\ude80\ud83c\udf1f Your learning journey is secure and ready to begin! Happy exploring! \ud83d\ude0a\ud83d\udd10 \"",
          "link": "https://skillsforall.com/course/english-for-it1?courseLang=en-US&instance_id=2231a3ee-e098-47bc-b6eb-7194ecb91974",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "\ud83e\udd1d Engaging Stakeholders for Success \ud83d\ude80",
          "desc": "Unlock the Power of Stakeholder Mastery \ud83c\udf1f Gain real-world interpersonal skills, prioritize effectively, and engage like a pro. Equip for a successful career! \ud83c\udf1f Perks: Essential for a lasting professional journey. \ud83c\udfaf Hone Universal Skills: Master transferable stakeholder abilities across industries. \u26a0\ufe0f Avoid Common Pitfalls: Get educated on stakeholder pitfalls. \ud83d\udd27 Support students in practicing stakeholder interview techniques. Let's get you started with these simple steps: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" and be redirected to the exciting CISCO course portal. 2\ufe0f\u20e3 On the right side of the website, enter your credentials: First Name, Last Name, and Email. Then hit \"\"Next Account Details\"\" to provide more personal information! 3\ufe0f\u20e3 Check your Email Inbox for the Successful Enrollment Notification using the provided email address. 4\ufe0f\u20e3 Click on the Course Portal link and assign a password and start your learning journey! \ud83c\udf89 Congratulations! You now have access to the course. Get ready to dive into the class activities! \ud83d\ude80\ud83c\udf1f Your learning journey is secure and ready to begin! Happy exploring! \ud83d\ude0a\ud83d\udd10 \"",
          "link": "https://skillsforall.com/course/engaging-stakeholders?courseLang=en-US&instance_id=f0d92f04-8f68-4740-a1ca-7583ee30f2bd",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "Managing Conflicts\ud83c\udf10\ud83d\udca5",
          "desc": "Conflicts in the workplace are perfectly normal - all you need to know is how to manage and resolve them properly. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Google.org - ACN - Project Management",
          "desc": "Get started in the in-demand field of project management with a Professional Certificate from Google. Learn how to manage projects using traditional and agile methods, create project documentation, and develop strategic communication skills. Learn the skills you need to start a career as a: Project Manager, Project Coordinator, Project Assistant, Operations Manager, Operations Associate, Program Manager, Scrum Master, Project Associate, Project Analyst, Project Administrator, Technical Project Manager \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      }
  ],
  "Communication": [
      {
          "name": "How to use email \ud83d\udc8c\ud83d\ude0e\ud83d\ude0a",
          "desc": "You're in for a treat with this one! \ud83c\udf89 This study unit is part of the UNESCO ICT Competency Framework for Teachers (CFT) generic course, bringing you all the cool tricks for mastering ICT skills. Get ready to become an email ninja! \ud83c\udf10\ud83d\udcbb\ud83d\udce7This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2439",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "How to speak in front of an audience \ud83c\udfa4\ud83d\udc40\ud83d\udcac",
          "desc": "\ud83d\udde3\ufe0f\ud83c\udf1f Conquer your stage fright and speak with confidence! This course covers: \u2022 Overcoming barriers to public speaking. \ud83d\udeab\ud83d\ude28 \u2022 Techniques to alleviate stage fright. \ud83c\udfad\ud83c\udfaf \u2022 Tips and tricks for delivering an impactful speech. \ud83c\udfa4\ud83d\udd25 Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Follow these simple steps: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=209",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Successful Pitching for Entrepreneurs \ud83d\udca1\ud83d\ude80",
          "desc": "\ud83d\udce2 Master the art of pitching! \ud83c\udfa4\ud83d\udcca This self-paced course will guide you through the entire pitching process, step-by-step. From preparing a pitch to delivering it with confidence, and following up for success. By the end of the course, you'll have a polished pitch ready to conquer the real world! \ud83d\udcbc\u2728 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3231",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Digital Fluency \ud83d\udcbb\ud83c\udf10",
          "desc": "\ud83d\udcda\ud83c\udf10 Enhance your digital fluency! This exciting self-paced course by New World of Work will introduce you to the ethics of working digitally, collaborative digital tools, effective information searching, and using technology like computers, tablets, and smartphones. By the end, you'll grasp the concept of digital fluency and its key characteristics. \ud83d\udcbb\ud83d\udd0d\ud83d\udcf1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2671",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Using Social Media to Communicate \ud83d\udcf1\ud83c\udf10",
          "desc": "\ud83d\udcf1\u2709\ufe0f\ud83c\udf0d Get social media savvy! This self-paced course is all about using social media to communicate effectively. Discover popular platforms like Facebook, WhatsApp, Google Chat, Google Meet, Instagram, TikTok, and Twitter. By course end, you'll be familiar with these apps and have an understanding of cyberbullying. \ud83d\udcac\ud83d\udcf8\ud83d\udd12 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2806",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Fundamentals of Tourism & Hospitality \ud83c\udf34\ud83c\udfe8",
          "desc": "\ud83d\udde3\ufe0f\ud83d\udcbc Expand communication & management skills! This self-paced course empowers you to excel in business. Master verbal & non-verbal communication for guests, employees, and partners. \ud83c\udf1f\ud83d\udcde\ud83d\udcac Complete in about 1 hour and earn an open badge! \ud83c\udf93\ud83d\udd16 Part of the \"Fundamentals of Tourism and Hospitality\" pathway: Tourism & Hospitality, F&B Business, Soft Skills, Customer Service, Housekeeping. \ud83c\udf0d\ud83d\udd11 Choose the full series or individual courses! \ud83d\ude80 Join the e-learning revolution with \"atingi\"! \ud83c\udf0d\ud83d\udca1 1. Click \"Go to Opportunity\" to join atingi. \ud83d\ude80 2. Register with email & password. Verify your email. \ud83d\udcdd 3. Explore all free atingi courses. \ud83c\udd93 Complete, earn your atingi certificate, and showcase on Yoma as \"I have completed this\". \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards for achievements. \ud83d\udcb0\ud83d\udcab Don't miss out on learning and earning with atingi! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=3969",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Tour Guiding 1: Roles and Responsibilities \ud83d\udeb6\u200d\u2642\ufe0f\ud83d\udd0d",
          "desc": "\ud83c\udf0d\ud83d\udd0d Curious about being a tour guide? This self-paced course reveals the exciting world of tour guiding! Discover the roles and responsibilities of a tour guide and what it takes to excel in this profession. \ud83d\udeb6\u200d\u2640\ufe0f\ud83d\uddfa\ufe0f Part of the \"Introduction into Professions in the Tourism Sector\" pathway: Tourism, Hospitality, Tour Guiding, and Tourism Entrepreneurship. \ud83c\udf1f\ud83d\udd11 Choose the full series or individual courses! \ud83d\ude80 Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Click \"Go to Opportunity\" to embark on the course. \u2705 Register for free access to all atingi courses. \ud83c\udd93 Complete the course and earn your atingi certificate. Share it on social media! \ud83c\udf93\ud83d\udcf2 Redeem Zlto rewards for your achievements. \ud83d\udcaa\ud83d\udcb0 Let's unlock the world of tour guiding together! \ud83c\udf1f\ud83c\udf0d",
          "link": "https://online.atingi.org/enrol/index.php?id=2464",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Comment g\u00e9rer votre r\u00e9putation num\u00e9rique \ud83c\udf10\ud83d\udd0d",
          "desc": "\ud83d\udcbb\ud83d\udce2 L'e-r\u00e9putation, c'est tout ce qui se dit en ligne sur une personne ou une marque ! On le trouve sur les r\u00e9seaux sociaux, les blogs, les forums et les plateformes de partage. C'est l'image que les gens ont de toi ou de ta marque sur Internet. Que tu sois r\u00e9el(le) ou fictif(ve), \u00e7a compte ! \ud83e\udd14\ud83d\udc40 Alors, fais attention \u00e0 ton e-r\u00e9putation et sois le(la) meilleur(e) en ligne ! \ud83d\udcaa\u2728 Pour participer au cours en ligne, cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" et inscrivez-vous. Une fois inscrit, tu auras acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s et partagez-le sur Yoma. Utilisez Zlto si applicable. \ud83d\udcd5",
          "link": "https://online.atingi.org/enrol/index.php?id=1591",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "The Digital Divide \ud83c\udf10\ud83d\udd00",
          "desc": "\ud83c\udf10\u2728 Internet should be a basic human right! \ud83d\ude0e\ud83d\udcf2 Everyone deserves equal access to all the info and opportunities online. But there's a gap in access to computers and the Internet. Some have no devices, others enjoy high-speed Internet at home, and many fall in between. This gap is called the digital divide and it's influenced by factors like money, location, and demographics. Let's bridge the divide! \ud83e\udd1d\ud83d\udcaa This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2376",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udda5\ufe0f\ud83c\udf10 Webinars and Web Meetings \ud83d\udcbb\ud83e\udd1d",
          "desc": "Get ready to level up your webinar and web meeting game! \ud83c\udf1f\ud83d\udcbb In our self-study course, you'll learn everything you need for smooth and effective collaboration in the online world. Whether you work remotely or just want to make the most out of your virtual meetings, this course is for you! \ud83d\ude80\ud83c\udfaf Join us and unlock the secrets to successful online collaboration. Let's dive in and become online meeting masters! \ud83d\ude80\ud83d\udcaa This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=843",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udde3\ufe0f\ud83d\udca1 How to: Give and Receive Feedback \ud83d\udd04\ud83c\udf1f",
          "desc": "\ud83c\udf1f\ud83d\udcda Enhance your soft skills for career success! This self-study course covers: \u2022 Communication and leadership skills. \ud83d\udcac\ud83d\udc65 \u2022 Time and stress management. \u23f0\ud83d\ude13 \u2022 Conflict resolution. \ud83e\udd1d\u2696\ufe0f This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=110",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf1f\ud83d\udc54 Soft Skills Training for Employment \ud83d\udcda\ud83d\udcbc",
          "desc": "\ud83c\udf1f\ud83d\udcda Enhance your soft skills for career success! This self-study course covers: \u2022 Communication and leadership skills. \ud83d\udcac\ud83d\udc65 \u2022 Time and stress management. \u23f0\ud83d\ude13 \u2022 Conflict resolution. \ud83e\udd1d\u2696\ufe0f This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1319",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcb0\ud83c\udfe2 Financement d'entreprises et collecte de fonds \ud83e\udd1d\ud83c\udf31",
          "desc": "\ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=98",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfa5\ud83d\udcdd Vid\u00e9os explicatives et \u00e9criture du sc\u00e9nario \u270d\ufe0f\ud83c\udfac",
          "desc": "\ud83c\udfa5\ud83c\udf1f Pr\u00eat \u00e0 cr\u00e9er des vid\u00e9os anim\u00e9es de ouf ? Notre cours d'autoformation te forme pour d\u00e9velopper ta premi\u00e8re vid\u00e9o explicative ou ton premier doodle anim\u00e9 ! \ud83d\udcfd\ufe0f\ud83c\udfa8 Pour qui? Pour tous ceux qui veulent passer un message \u00e0 un groupe pr\u00e9cis gr\u00e2ce \u00e0 des vid\u00e9os anim\u00e9es. \ud83d\udce2\ud83d\udcbb Apr\u00e8s le cours, tu seras au top : vid\u00e9os explicatives, d\u00e9veloppement de sc\u00e9nario, storyboard de fou, musique de fond et voix off, choix des outils de production, et d\u00e9marrage de la phase de r\u00e9alisation de ta premi\u00e8re vid\u00e9o anim\u00e9e ! \ud83d\udcdd\ud83c\udfb5 Pr\u00e9pare-toi \u00e0 lib\u00e9rer ta cr\u00e9ativit\u00e9 et \u00e0 captiver ton public avec des vid\u00e9os sensationnelles ! Rejoins-nous dans cette aventure excitante pour devenir un v\u00e9ritable pro de l'animation ! \ud83d\udcaa\u2728 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=222",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83e\udd1d\ud83d\udde8\ufe0f Interpersonal Communication \ud83d\udcac\ud83c\udf1f",
          "desc": "\ud83d\udcda\ud83d\udde3\ufe0f Join our awesome self-study course and level up your communication skills! \ud83c\udf1f In just four fun learning units, you'll master the essentials of interpersonal communication! \ud83e\udd1d\ud83d\udca1 This course is perfect for anyone who wants to learn how to communicate respectfully and attentively! \ud83c\udfaf\ud83d\ude80 Don't miss out on this opportunity to gain practical knowledge! \ud83c\udf08\ud83d\udcac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/course/view.php?id=125",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "How to Write a Speech \ud83c\udfa4\ud83d\udca1",
          "desc": "\ud83c\udfa4\ud83d\udcdd Want to rock your speeches? Join our awesome course now! \ud83d\ude80\ud83c\udf1f\ud83d\udcda\ud83d\uddd2\ufe0f Discover the art of speech-writing - it's the key to success! \ud83d\udca1\u270d\ufe0f From start to finish, we got you covered!What's in store for you? \ud83e\udd14\ud83d\udc49 \u2022 Get the ultimate speech-writing tips! \ud83c\udf1f \u2022 Learn how to craft engaging openings and powerful conclusions! \ud83c\udf89\ud83c\udf99\ufe0f \u2022 Master the art of structuring your speeches like a pro! \ud83c\udfc6\ud83d\udcac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/course/view.php?id=211",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "English for Information Technology part1\ud83c\udf10",
          "desc": "Unlock the Power of Stakeholder Mastery \ud83c\udf1f Gain real-world interpersonal skills, prioritize effectively, and engage like a pro. Equip for a successful career! \ud83c\udf1f Perks: Essential for a lasting professional journey. \ud83c\udfaf Hone Universal Skills: Master transferable stakeholder abilities across industries. \u26a0\ufe0f Avoid Common Pitfalls: Get educated on stakeholder pitfalls. \ud83d\udd27 Support students in practicing stakeholder interview techniques. Let's get you started with these simple steps: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" and be redirected to the exciting CISCO course portal. 2\ufe0f\u20e3 On the right side of the website, enter your credentials: First Name, Last Name, and Email. Then hit \"\"Next Account Details\"\" to provide more personal information! 3\ufe0f\u20e3 Check your Email Inbox for the Successful Enrollment Notification using the provided email address. 4\ufe0f\u20e3 Click on the Course Portal link and assign a password and start your learning journey! \ud83c\udf89 Congratulations! You now have access to the course. Get ready to dive into the class activities! \ud83d\ude80\ud83c\udf1f Your learning journey is secure and ready to begin! Happy exploring! \ud83d\ude0a\ud83d\udd10 \"",
          "link": "https://skillsforall.com/course/english-for-it1?courseLang=en-US&instance_id=2231a3ee-e098-47bc-b6eb-7194ecb91974",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "English for Information Technology part2",
          "desc": "Level Up Your IT English \ud83c\udf10 Master grammar, tech lingo, and more. Ace the English for IT B2 / GSE 59-75 exam! \ud83d\udcbc \ud83d\udcbc Perks: 89% of IT employers demand English (Indeed, 2020). \ud83c\udf0d \ud83d\udc69\u200d\ud83c\udfeb Our Approach: Prepare for jobs with essentials. \ud83d\udcbc Refine expression with engaging grammar videos. \ud83d\udcf9 Nail idioms for everyday talk. \ud83d\udde3\ufe0f Boost understanding through fun exercises. \ud83c\udf93\ud83e\udd39\u200d\u2640\ufe0f Ready to dive into this amazing opportunity? Follow these simple steps to get started: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" to be whisked away to the exciting CISCO course. 2\ufe0f\u20e3 Hit that \"\"Get started\"\" button and sign up with your email, password, and a few personal details. We're almost there! 3\ufe0f\u20e3 Embrace the journey ahead by accepting the terms and conditions, and voila! Your learning adventure begins!",
          "link": "https://skillsforall.com/course/english-for-it2?courseLang=en-US&instance_id=4457e8ba-29ec-4008-8f67-14e4aa0969c1",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "Communicating Virtually\ud83d\udcaa\ud83c\udf93",
          "desc": "Learn about the importance of communicating virtually and the options for communicating in both work and personal life. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Getting Started with Microsoft 365",
          "desc": "\ud83c\udf1f Ready to boost your skills? UNICEF and the Ministry of Education are teaming up with Microsoft for something big! \ud83d\ude80\ud83c\udf93 Introducing P2E and Nigeria Learning Passport \u2013 your free pass to job-related skills if you're 15-24. \ud83d\udcbc\ud83c\udf08 Goal: Equip 50,000 young talents in Nigeria's 4 states by 2025. Let's own that transition from school to work! \ud83d\udcaa\ud83d\udc69\u200d\ud83d\udcbc\ud83d\udc68\u200d\ud83d\udcbc Feeling lost in the digital world? No worries! \ud83c\udf10\ud83d\udca5 Microsoft 365 is your productivity hack. Join our awesome course led by Courtney Hodge. \ud83d\ude4c\ud83d\udc69\u200d\ud83d\udcbb Master Word, Excel, OneNote, Outlook, and PowerPoint. \ud83d\udcdd\ud83d\udcca\ud83d\udc8c\ud83d\udce3 Become a digital pro and get things done like a champ. \ud83c\udfc6\ud83c\udf89 Ready for action? Here's your guide to jump in: \ud83d\udc63 How to Register on NLP (Nigeria Learning Passport) \ud83d\udc63 1a. Go to https://nigeria.learningpassport.org and hit \"Get Started.\" Or... 1b. Grab the \"Nigeria Learning Passport\" app from Google Play Store! \ud83d\udcf2 2a. Click \"Sign Up Now\" and use the email you've set up for the student (do this for each student). 2b. Hit \"Send Code.\" Check your email for a secret code. Pop that code into the NLP platform, hit \"Verify,\" and then set a unique password (remember it, folks!). Fill in your profile deets. \ud83d\udcdd Oh, and the best part? You can dive into this course without worrying about data usage! \ud83d\udce1 Use an Airtel data-enabled SIM and get 300MB daily for free learning. Let's do this! \ud83d\ude80\ud83d\udcda\ud83d\udd25",
          "link": "https://learningpassport.b2clogin.com/learningpassport.onmicrosoft.com/b2c_1_nigeria_signup_signin/oauth2/v2.0/authorize?client_id=7cb7d531-ce5e-479f-a2ba-d8a56f04161e&redirect_uri=https://nigeria.learningpassport.org/signin-b2c&response_type=code&scope=openid%20profile%207cb7d531-ce5e-479f-a2ba-d8a56f04161e%20offline_access&code_challenge=BFfadHWqMOVxb2tjxJW6CcNgfHTf_NAHI6UOV1W5dHs&code_challenge_method=S256&response_mode=form_post&nonce=638284581819138875.YTc4NWFjNmItYTRiZS00ODJhLThjMWQtMDEyMzc3NGEyNjQ3YmEwNzRiM2UtMGIwYy00N2FhLTg0MGQtNWQyY2VkMTgwMTA5&client_info=1&x-client-brkrver=IDWeb.1.3.0.0&state=CfDJ8Ew275PhYxJHkkUb7nPZEBPM-DrpQKGOYeCSVPdL6uLEkSbKfL0sEdSm3J9uaoxBX2lXRsv_QmISBJUpGe5Qmduj6ccgleFUTSaPypMOfnj_hdR1gVmcM-Z0xZ2S0u_Tm2sjldCYq0C681Vsns5heoG7LxJUjqoM-vZ44AyPwheclKcDuFp9iZk0hY7_7XS_TqNMOuERpvYOrLWdkpRWU_Ok1DSu4Tb-dyvFMaPE6B95bodJDsZV5Cj57qjx8S2w3hbvR6bcoZ5mGY0LgyD_rWZ8gugJ6jKnAODqJ4fy3Nsd-LnDvM1kGSIsnDLqOag34AmKQ3lGJbLtY6sMijMs_oPhXypmJYrEB23gNCof0KmrYtSk-6nZB_OeUjhwGpN4MVWI1PkscAMvfaoIKgxEohcDLAU5gVoMNsWRsKxYf5DEbORD5t3CTzBA2wrK0bxjDSx6twgS4jAn2qoPJGul2_Kx70_zJ8TxQS_x7K_9KSMG_PTGIslbSs62X9IrjMrKdTwn9MNSCzW5wqQz7_JSSQGKeGIIIR39VOs3nnol6SLIpEEZRoxNXDb-6GRWfOS1F2W3Hho4s_HPYM6iE_z2poU&x-client-SKU=ID_NET6_0&x-client-ver=6.21.0.0",
          "image": "../../../assets/images/yoma/GenU.webp"
      },
      {
          "name": "Facebook Marketing Analytics",
          "desc": "Learn the foundations of marketing analytics and how to extract and analyze data, conduct A/B tests, and derive and present insights that inform business decisions. Learn the skills you need to start a career as a: Marketing Analyst, Marketing Researcher, Business Intelligence Analyst, Growth Marketing Analyst, Marketing Science Analyst \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Facebook Social Media Marketing",
          "desc": "Learn the fundamentals of social media management and advertising and how to measure and optimize marketing campaigns across the major social media platforms. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - Digital Marketing and E-Commerce",
          "desc": "Learn to manage digital marketing campaigns, attract and engage customers, and measure performance through analytics. Learn the skills you need to start a career as a: Digital Marketer, Marketing Coordinator, Search Engine Optimization Specialist, Paid Search Specialist, Email Marketing Specialist, E-commerce Associate, Media Planner \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      }
  ],
  "Creativity": [
      {
          "name": "Women in Tech \ud83d\udcbb\ud83d\udc69\u200d\ud83d\udcbc\ud83d\udc69\u200d\ud83d\udcbb\ud83c\udf1f",
          "desc": "The tech world has been a bit of a boys' club for too long, but it's time for a major shake-up! Did you know that only 24 percent of IT jobs worldwide are held by women? Let's change that stat, shall we? \ud83d\udcaa In this quick and punchy learning nugget, get ready to meet some seriously cool women who are totally slaying it in the tech industry. By the time you're done, you'll see that the tech world is bursting with opportunities for all! This mind-blowing online course is brought to you by the awesome folks at \"atingi.\" \ud83c\udf0d\ud83d\udca1 Here's your chance to jump into the e-learning revolution: Hit that \"Go to Opportunity\" button and get zapped straight to atingi. \ud83d\ude80 Sign up using your email and a password. Look out for that verification email\u2014 it's on its way! \ud83d\udcdd Once you're in, get ready to explore all the amazing atingi opportunities. Psst, they're all totally free! \ud83c\udd93 Crush the course, snag that sweet atingi certificate, and show it off on Yoma with a proud shout-out \"I'm a tech superstar!\" \ud83d\udcaa\ud83c\udf93 Score some awesome Zlto rewards for all your hard work, wherever you can. \ud83d\udcb0\ud83d\udcab Let's make the tech world more diverse and exciting with atingi! Don't miss this chance to be inspired and carve your path in the tech universe! \ud83c\udf1f",
          "link": "https://online.atingi.org/enrol/index.php?id=943 ",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83e\udd16 How to Get Involved in Artificial Intelligence \ud83d\udda5\ufe0f",
          "desc": "\ud83c\udf1f Dive into AI basics and join the AI adventure! This course helps you spot AI examples, understand its pros and cons, find more info, and explore ways to get involved! \ud83e\udd16\ud83d\udca1\ud83d\udd0d\ud83c\udfc6\ud83d\udd25\ud83d\ude80This online course is offered by the global e-learning platform \"\"atingi\"\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"\"Go to Opportunity\"\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"\"Upload your completion certificate\"\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1384",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Launchpad to Fundamental Questions of AI - Part 4 \u2728\ud83d\ude80",
          "desc": "\ud83d\ude80\ud83c\udf1f Unlock the vast opportunities and outlook of Artificial Intelligence (AI) in this self-paced online course! Dive into the transformative potential of AI in various fields, emerging trends, ethical considerations, and practical strategies to leverage AI for innovation and success. \ud83e\udd16\ud83d\udca1 This course is for learners who are interested in Artificial Intelligence. We recommend completing Part 1: What is AI?, Part 2: Application of artificial intelligence in daily life, and Part 3: Risks before you start this course. \ud83d\udcda\ud83e\udd13 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=478",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude81\u2728 The Remotely Piloted Aircraft Technology",
          "desc": "\ud83d\ude80\ud83c\udf93 The Remotely Piloted Aircraft Technology (RPAT) course is an exciting self-paced learning module designed to teach students about the fundamentals of drone operations in areas such as aerodynamics, propulsion systems, flight operations, and more! \ud83d\udee9\ufe0f\ud83d\udca8 Get ready to dive into the fascinating world of drone technology with topics like Basics of Fixed-Wing Aircraft, Lift and Drag, Aircraft Structures, Propulsion Systems and Aircraft Design, Aircraft Performance, Stability, and Control, Safe Flight Operations, Organizational Procedures, Basics of Meteorology, Impact of Weather on Drone Flight, and Payload Integration! \ud83c\udfaf\ud83d\udcc8 Students are encouraged to have some background in engineering, mathematics, and physics. \ud83d\udcd0\ud83d\udcc8\ud83d\udcca This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Enroll now and embark on a thrilling journey into the world of drone technology! \ud83d\ude80\ud83d\udd0d\ud83d\udee9\ufe0f",
          "link": "https://online.atingi.org/enrol/index.php?id=791",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfa5\ud83d\udcdd Explainer Videos & Script Writing \ud83c\udf1f\ud83c\udfac",
          "desc": "\ud83c\udfa5\ud83d\udd8c\ufe0f Dive into animation! This self-study course teaches you how to create your first animated explainer or scribble video. Learn about explainer videos, video script development, storyboarding, background music and voice-overs, production tools, and initiating video production. Become an animation pro! \ud83c\udf1f\ud83c\udfac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: Click \"Go to Opportunity\" to join atingi. \ud83d\ude80 Register with your email and password. Verify your email. \ud83d\udcdd Explore all free atingi courses. \ud83c\udd93 Complete the course, earn your atingi certificate, and showcase on Yoma as \"I have completed this\". \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards for achievements. \ud83d\udcb0\ud83d\udcab Learning and earning made easy with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=94",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83c\udf0d Digital Transformation - Impact on our Lives \ud83c\udf1f\ud83d\udd25",
          "desc": "\ud83d\udcbb\ud83c\udf0d The digital revolution has totally changed our lives! \ud83d\ude2e\u2728 It's had a huge impact on people and communities worldwide. \ud83c\udf1f The effects can be both \ud83c\udf08positive and \ud83c\udf27\ufe0fnegative. In this course, we'll dig into the practical ways digital transformation affects us as individuals and society as a whole. Let's dive in and explore! \ud83d\udcda\ud83d\ude80 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2758",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udc64\ud83d\udca1 Human-Centered Design Crash Course \ud83d\ude80\ud83d\udcda",
          "desc": "\ud83d\udcda\ud83d\udca1 Ready to unleash your creativity? Join this course to master Human Centered Design (HCD) and apply its mindset to your daily job! \ud83c\udf1f\ud83d\udee0\ufe0f Complete it in just 1 hour \u23f0 (fast track) or take your time to explore tools and reflect on your learning (expert track). This course is perfect for all members of an SME! \ud83d\ude4c\u2728 From product developers to designers, salespeople to marketers, HCD benefits everyone! Let's dive in and revolutionize your work! \ud83d\ude80\ud83c\udfaf This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2716",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcb2\ud83d\udcc8 Explore Pricing & Promotion \ud83d\ude80\ud83c\udf1f",
          "desc": "Ready to turn your handicraft passion into a thriving business? \ud83c\udf1f\ud83d\udcbc Join our self-paced course and master the art of pricing and promotion for your products. Learn how to set the perfect price by understanding cost calculation and profit margins. Explore different promotion channels and discover the best fit for your unique business. Get ready to launch your brand and reach your target audience with confidence. Enjoy the journey to entrepreneurial success! \ud83d\ude80\ud83c\udfa8\ud83d\ude0a This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=4097",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcda\ud83d\udcbb Knowledge Production Online \ud83c\udf10\ud83d\ude80",
          "desc": "Step into the digital world of knowledge production with our course! \ud83c\udf10\ud83d\udca1 Explore the meaning of knowledge production, discover the power of open educational resources (OER) and open licensing, and understand the impact of copyright. Get ready to navigate the digital landscape of information and unlock the secrets of online knowledge creation. Let's embark on this enlightening journey together! \ud83d\ude80\ud83d\udcda\ud83c\udf0d This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2761",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83c\udf31 Digital Livelihoods Challenge Follow up Course: Testing Market Validation \ud83e\uddea\ud83d\udcca",
          "desc": "\ud83c\udf1f\ud83d\udca1 Ready to transform your business idea into a captivating success? Join our course designed for innovative minds like yours! Whether you're starting from scratch or struggling to find your target audience, we've got you covered. \ud83d\ude80\ud83c\udfaf In this exciting journey, we'll equip you with the tools and strategies to captivate your audience and make your business idea shine. \ud83c\udf1f\ud83d\udcbc Get ready to unleash your entrepreneurial spirit and turn your vision into a captivating reality! Let's make waves in the business world together! \ud83d\udcaa\ud83c\udf0a This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=1147",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 1: What is AI? \ud83c\udf1f\ud83d\udca1",
          "desc": "\ud83c\udf1f\ud83e\udd16 Dive into the exciting world of Artificial Intelligence (AI) with our elementary course! \ud83d\udcda\ud83d\udca1 This course is designed to demystify the basics of AI and help you overcome any barriers you may face. Part 1 provides a crisp introduction to key terms and topics, using real-life examples. \ud83c\udf93\u2728 The content is presented in a visually-rich format, divided into bite-sized sections with interactive elements and reflection exercises for social exchange. \ud83d\udcca\ud83d\udcac The curriculum is inspired by the innovative graphic novel \"We Need to Talk, AI.\" Join us on this thrilling journey to unlock the secrets of AI! \ud83d\ude80\ud83d\udd0d This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=468",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 2: Application of AI in daily life \ud83c\udf1f\ud83d\udcbb",
          "desc": "AI is taking over the world! \ud83c\udf10\ud83e\udd16 But understanding its true potential can be a mystery. That's where our course comes in! \ud83d\udcda\u2728 Get ready for an exciting journey into the basics of Artificial Intelligence, demystifying this fascinating field. \ud83e\udde0\ud83d\udca1 Part 2 of our course, \"Artificial Intelligence - Launchpad to Fundamental Questions,\" dives into real-world examples of AI in action. Join us and uncover the power of AI! \ud83d\ude80\ud83d\udd0d Let's explore together and conquer the barriers that may seem daunting. Get ready to embrace the future with confidence and unlock the secrets of Artificial Intelligence! \ud83c\udf1f\ud83e\udd16 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=472",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfa5\ud83d\udcdd Vid\u00e9os explicatives et \u00e9criture du sc\u00e9nario \u270d\ufe0f\ud83c\udfac",
          "desc": "\ud83c\udfa5\ud83c\udf1f Pr\u00eat \u00e0 cr\u00e9er des vid\u00e9os anim\u00e9es de ouf ? Notre cours d'autoformation te forme pour d\u00e9velopper ta premi\u00e8re vid\u00e9o explicative ou ton premier doodle anim\u00e9 ! \ud83d\udcfd\ufe0f\ud83c\udfa8 Pour qui? Pour tous ceux qui veulent passer un message \u00e0 un groupe pr\u00e9cis gr\u00e2ce \u00e0 des vid\u00e9os anim\u00e9es. \ud83d\udce2\ud83d\udcbb Apr\u00e8s le cours, tu seras au top : vid\u00e9os explicatives, d\u00e9veloppement de sc\u00e9nario, storyboard de fou, musique de fond et voix off, choix des outils de production, et d\u00e9marrage de la phase de r\u00e9alisation de ta premi\u00e8re vid\u00e9o anim\u00e9e ! \ud83d\udcdd\ud83c\udfb5 Pr\u00e9pare-toi \u00e0 lib\u00e9rer ta cr\u00e9ativit\u00e9 et \u00e0 captiver ton public avec des vid\u00e9os sensationnelles ! Rejoins-nous dans cette aventure excitante pour devenir un v\u00e9ritable pro de l'animation ! \ud83d\udcaa\u2728 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=222",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "How to Write a Speech \ud83c\udfa4\ud83d\udca1",
          "desc": "\ud83c\udfa4\ud83d\udcdd Want to rock your speeches? Join our awesome course now! \ud83d\ude80\ud83c\udf1f\ud83d\udcda\ud83d\uddd2\ufe0f Discover the art of speech-writing - it's the key to success! \ud83d\udca1\u270d\ufe0f From start to finish, we got you covered!What's in store for you? \ud83e\udd14\ud83d\udc49 \u2022 Get the ultimate speech-writing tips! \ud83c\udf1f \u2022 Learn how to craft engaging openings and powerful conclusions! \ud83c\udf89\ud83c\udf99\ufe0f \u2022 Master the art of structuring your speeches like a pro! \ud83c\udfc6\ud83d\udcac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/course/view.php?id=211",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Introduction to Web Development\ud83c\udf10",
          "desc": "Get an introduction to web development, including the differences between front-end and back-end development and the daily life of a web developer. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Artificial Intelligence\ud83d\udcbb\ud83d\udcf1",
          "desc": "Understand what Artificial Intelligence is, how it is applied, and why it matters.\ud83d\udcf1 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Software Development\ud83d\udcbb\ud83d\udcda",
          "desc": "Explore the basics of software development, including the tools, programs and languages used. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Umuzi Web Development Learnership",
          "desc": "Transform your passion into a profession by joining us at Umuzi! \ud83d\ude80 If successful, you could secure an invite to our immersive full-time 12-month Web-Development Learnership. \ud83d\udcbb\u2728 Plus, enjoy a stipend to support your journey! Requirements: \ud83c\uddff\ud83c\udde6 South African citizens \ud83d\udc69\u200d\ud83c\udf93 Ages 18-34 \ud83d\udcda Completed Grade 12 (or NQF 4 equivalent) \ud83d\udccd Based in/around Cape Town We're especially keen on learners interested in Full-Stack Web Development. \ud83c\udf10\ud83d\udd27 Apply now and kickstart your career with Umuzi! \ud83d\ude80\ud83d\udc69\u200d\ud83d\udcbb #WebDevelopment #UmuziOpportunity",
          "link": "https://www.umuzi.org/apply-now",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Front-End Developer Certificate",
          "desc": "\u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Gaming 4 Girls - African Coding Network",
          "desc": "\ud83c\udf1f\ud83d\udc69\u200d\ud83d\udcbb Exciting news, girls! \ud83c\udf1f\ud83d\udc69\u200d\ud83d\udcbb Umuzi & African Coding Network teamed up with UNICEF \ud83e\udd1d\ud83c\uddff\ud83c\udde6 to launch the Digital Skills 4 Girls Programme! \ud83d\ude80\ud83d\udcbb Unlock your coding & digital talents early on! \ud83c\udf08\ud83d\udcf1\ud83d\udcaa Don't miss this awesome opportunity, sign up now! \ud83c\udf1f\ud83d\ude80 No experience needed! \ud83c\udf1f\ud83d\ude80 Just bring your passion for \ud83d\udcbb coding, \ud83d\udcf1 digital marketing, or both! \ud83e\udd29\ud83d\udd25 Age: 14-19 years old \ud83d\udcc5\ud83d\udc67\ud83d\udc66 \ud83c\uddff\ud83c\udde6 South African citizens only \ud83c\uddff\ud83c\udde6 Dreaming of a digital career? \ud83c\udf08\ud83d\udcbc Be motivated, dedicated, and ready to rock! \ud83d\udcaa\ud83c\udf93 Apply now! Let's make it happen! \ud83d\udcdd\ud83d\ude80 #DigitalDreams \ud83c\udf20\ud83d\udcbb",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shroZTXQA4iia3VNi",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Facebook Social Media Marketing",
          "desc": "Learn the fundamentals of social media management and advertising and how to measure and optimize marketing campaigns across the major social media platforms. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Back-End Developer",
          "desc": "Gain the technical skills required to become a qualified back-end developer with a Professional Certificate from Meta. Learn to use programming systems including Python Syntax, Linux commands, Git, SQL, Version Control, Cloud Hosting, APIs, JSON, XML, and how to confidently use code to solve problems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - Digital Marketing and E-Commerce",
          "desc": "Learn to manage digital marketing campaigns, attract and engage customers, and measure performance through analytics. Learn the skills you need to start a career as a: Digital Marketer, Marketing Coordinator, Search Engine Optimization Specialist, Paid Search Specialist, Email Marketing Specialist, E-commerce Associate, Media Planner \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta React Native",
          "desc": "Learn how to create apps for \ud83d\udcf2 Android and \ud83d\uddb1\ufe0f iOS devices. learn to build and write code \ud83d\udc69\ud83c\udffc\u200d\ud83d\udcbb for apps that are hosted on mobile devices powered by multiple operating systems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta iOS developer",
          "desc": "This Professional Certificate will teach you how to build applications for iOS devices and start a new career as an iOS Developer. By the end of this program, you\u2019ll be able to create and run a mobile app powered by iOS operating systems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Android Developer",
          "desc": "Have you ever wanted to build your own mobile app? This certificate will teach you the top Android programming languages and frameworks used for building apps like Facebook and Instagram, and how to create your own Android application. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - UX Design",
          "desc": "Learn UX foundations and gain experience with the design process, including how to build wireframes and prototypes and conduct user research to test your designs. Learn the skills you need to start a career as a: User Experience (UX) Designer, UI Designer, Interaction Designer, Visual Designer, Product Designer \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      }
  ],
  "Critical Thinking": [
      {
          "name": "Data Awareness \ud83d\udcca\ud83d\udc40\ud83d\udce1",
          "desc": "\ud83d\udcca\ud83c\udf10 Get ready to level up your data game with this super informative microlearning session all about data awareness! Curious about who's snooping on your online moves and how your data is used? \ud83e\udd14\ud83d\udd0d Let's unveil the mysteries of data collection and arm you with the know-how to keep your digital footprint secure. \ud83d\udee1\ufe0f\ud83d\udd12 By the end of this course, you'll be a data ninja, equipped with the following skills: \ud83d\udc40\ud83d\udd0d Sharpened awareness of the data traces you're leaving behind \ud83d\udcc8\ud83e\udd14 Understanding of how your data is collected and used \ud83d\udee1\ufe0f\ud83d\udd12 Pro tips on protecting your precious data from prying eyes \ud83d\udcda\ud83e\uddd0 Empower yourself with the knowledge to safeguard your online presence! Get on board with the global e-learning squad at \"atingi\" \ud83c\udf0d\ud83d\udca1. Here's how to jump into this data-saving adventure: Click that \"Go to Opportunity\" button to warp straight to atingi. \ud83d\ude80 Sign up with your email and a password, then keep an eye out for your verification email. \ud83d\udcdd Dive into all the incredible atingi opportunities once you're in! All courses are totally free. \ud83c\udd93 Crush the course, bag that shiny atingi certificate, and flex it on Yoma with a proud shout-out \"I'm a data warrior!\" \ud83d\udcaa\ud83c\udf93 Rack up some sweet Zlto rewards for all your hard-earned achievements. \ud83d\udcb0\ud83d\udcab Ready to dive in and emerge as the data champ? Let's make learning and earning a breeze with atingi! Don't let this opportunity slip through your fingertips! \ud83d\ude80\ud83d\udcbb\ud83d\udd10",
          "link": "https://online.atingi.org/enrol/index.php?id=4286",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Fique longe de desinforma\u00e7\u00e3o on-line! \ud83d\udeab\ud83d\udcbb",
          "desc": "Les applications, les sites web et les r\u00e9seaux en ligne peuvent \u00eatre essentiels pour acc\u00e9der \u00e0 des actualit\u00e9s, \u00e0 des outils facilitant votre quotidien et \u00e0 des divertissements. Mais au milieu de tout ce contenu, il peut \u00eatre difficile de naviguer parmi ces distractions et de distinguer les faits de la fiction. Ce cours en ligne est propos\u00e9 par la plateforme mondiale d'apprentissage en ligne \"atingi\" \ud83c\udf0d\ud83d\udca1. Suivez ces \u00e9tapes pour rejoindre la r\u00e9volution de l'apprentissage en ligne d\u00e8s maintenant : Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd Explorez toutes les opportunit\u00e9s offertes par atingi une fois connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 R\u00e9ussissez le cours, obtenez votre certificat atingi et exhibez-le sur Yoma avec fiert\u00e9 en d\u00e9clarant \"J'ai termin\u00e9 cela\". \ud83d\udcaa\ud83c\udf93 \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations, l\u00e0 o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Rendons l'apprentissage et la r\u00e9compense faciles avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=1220",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udeab\ud83d\udce3 Stay Clear of Misinformation Online \ud83d\udd78\ufe0f",
          "desc": "Apps, websites, and online media are like your BFFs for news, life hacks, and entertainment. \ud83c\udf1f But in the middle of all that hype, it's tough to navigate the distractions and know what's real from what's just vibes. \ud83d\udcf1\ud83d\udcbb\ud83d\udcabThis online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=744",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83e\udd16 How to Get Involved in Artificial Intelligence \ud83d\udda5\ufe0f",
          "desc": "\ud83c\udf1f Dive into AI basics and join the AI adventure! This course helps you spot AI examples, understand its pros and cons, find more info, and explore ways to get involved! \ud83e\udd16\ud83d\udca1\ud83d\udd0d\ud83c\udfc6\ud83d\udd25\ud83d\ude80This online course is offered by the global e-learning platform \"\"atingi\"\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"\"Go to Opportunity\"\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"\"Upload your completion certificate\"\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1384",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Drones & Data \ud83d\udef0\ufe0f\ud83d\udcbb",
          "desc": "\ud83d\ude81 Discover the Power of Drones for Good! \ud83c\udf0d Explore their potential in various sectors. Learn about UASs, real-world applications, and the concept of Drones for Good.\ud83c\udf93 Expand Your Knowledge: Benefit from drones and data-driven practices. \u2699\ufe0f Unleash UAS Potential: Understand their functioning and components. \ud83d\udca1 Ignite Positive Impact: Explore how drones improve lives. \ud83c\udf1f Continue Your Journey: Dive deeper into drones and data. Enroll now and join the movement for positive change! \ud83d\ude80\u2728This online course is offered by the online learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" to redirect to atingi. \ud83d\ude80 2. Register with your email and password. Verify your email.\ud83d\udcdd 3. Explore all free atingi courses. \ud83c\udd93 4. Complete the course, earn your atingi certificate, and showcase it on Yoma as \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for applicable achievements. \ud83d\udcb0\ud83d\udcab Learning and earning made easy with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3911",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd2cAI - Perspectives from the Global South \ud83c\udf0d",
          "desc": "\ud83c\udf0d Explore AI's Global Impact: This course delves into crucial topics like infrastructure, connectivity, and cross-cultural comparisons, uncovering the profound significance of AI development worldwide. Join us to gain invaluable insights from the Global South's perspective! \ud83d\ude80This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2874",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd0d\ud83c\udf10 (Re)searching Online \ud83d\udcda\ud83d\udcbb",
          "desc": "\ud83d\udd0d\ud83d\udcda Unlock the truth! In this course, master the art of resource evaluation, fact-checking, and spotting fake news. Learn to navigate the internet with confidence, distinguishing reliable sources from unreliable ones. \ud83d\udd75\ufe0f\u200d\u2642\ufe0f\ud83d\udd0e\ud83d\udcf0 Become a savvy researcher! Join the course now. \ud83c\udf1f\ud83c\udf93 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2679",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd17\ud83d\udca1 Introduction to Blockchain Technology \ud83c\udf10\ud83d\ude80",
          "desc": "\ud83d\udca1\ud83d\udd17 Curious about blockchain? It's a revolutionary technology! A blockchain is like a secure, public ledger for transactions and asset tracking, enhancing trust and reducing costs. Dive into this self-paced course to grasp the basics of blockchain: its concept, functionality, and various applications, including bitcoin. \ud83c\udf10\ud83d\udcb0 Discover the power of blockchain now! \u26a1\ud83d\udd10 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3018",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\u26a1\ud83c\udf3f Energy Efficiency and Energy Saving Potentials for Industries \ud83c\udfed\ud83d\udca1",
          "desc": "\ud83c\udf0d\ud83d\udca1 Energy Efficiency and Energy Saving Potentials are crucial for everyone, not just industries! Join our course to discover the secrets of energy efficiency and learn how to save energy effectively. \ud83c\udf31\ud83d\udcaa This course explores various mechanisms, tools, and techniques to help you make a positive impact on the world. Get ready to unlock the power of energy conservation! \ud83d\ude80\ud83d\udd0c Let's create a sustainable future together! \ud83c\udf1f\ud83c\udf0e This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=1425",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83d\udd0d Becoming a Digital Enquirer - Part 1: Identifying and Responding to Misinformation \ud83e\udd14\ud83d\udd25",
          "desc": "Ready to combat misinformation and stay informed? \ud83d\udeab\ud83d\udce2 Join our self-study course and gain valuable insights into the world of misinformation. Learn how to identify and recognize misinformation, understand its spread, and discover effective strategies to tackle it while keeping yourself safe. Plus, explore the influence of technology on our beliefs and emotions, and discover what you can do to stay empowered in the digital age. Let's equip ourselves with knowledge and fight against misinformation together! \ud83d\udcaa\ud83c\udf10\ud83d\udd0d This online course is offered by the global e-learning platform Atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to Atingi. \ud83d\ude80 2. Register on Atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your Atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with Atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1161",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd0d\ud83d\udcc8 How to Research Markets & Sales Channels \ud83c\udf10\ud83d\udcbc",
          "desc": "\ud83d\udcda\ud83d\udc69\u200d\ud83d\udcbc Dive into this business startup course! Discover: \u2022 Analyzing your competitors \ud83d\udc65\ud83d\udd0e \u2022 Understanding your customers and their needs \ud83e\udd1d\ud83d\udca1 \u2022 Selecting a suitable sales channel for your craft product \ud83d\uded2\ud83c\udfa8 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1303",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83c\udf31 Digital Livelihoods Challenge Follow up Course: Testing Market Validation \ud83e\uddea\ud83d\udcca",
          "desc": "\ud83c\udf1f\ud83d\udca1 Ready to transform your business idea into a captivating success? Join our course designed for innovative minds like yours! Whether you're starting from scratch or struggling to find your target audience, we've got you covered. \ud83d\ude80\ud83c\udfaf In this exciting journey, we'll equip you with the tools and strategies to captivate your audience and make your business idea shine. \ud83c\udf1f\ud83d\udcbc Get ready to unleash your entrepreneurial spirit and turn your vision into a captivating reality! Let's make waves in the business world together! \ud83d\udcaa\ud83c\udf0a This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=1147",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 3: Risks \u26a0\ufe0f\ud83d\udd12",
          "desc": "\ud83c\udf1f\ud83e\udd16 AI is all around us, but understanding its depths can be a mystery. That's where our course comes in! Join us on this thrilling journey to unravel the basics of Artificial Intelligence and conquer the challenges that come with it. Part 3 of \"Artificial Intelligence - Launchpad to Fundamental Questions\" explores the risks and the path forward in the world of AI. Get ready to dive into the risks and discover the solutions ahead! \ud83d\ude80\ud83d\udd0d\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=473",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 1: What is AI? \ud83c\udf1f\ud83d\udca1",
          "desc": "\ud83c\udf1f\ud83e\udd16 Dive into the exciting world of Artificial Intelligence (AI) with our elementary course! \ud83d\udcda\ud83d\udca1 This course is designed to demystify the basics of AI and help you overcome any barriers you may face. Part 1 provides a crisp introduction to key terms and topics, using real-life examples. \ud83c\udf93\u2728 The content is presented in a visually-rich format, divided into bite-sized sections with interactive elements and reflection exercises for social exchange. \ud83d\udcca\ud83d\udcac The curriculum is inspired by the innovative graphic novel \"We Need to Talk, AI.\" Join us on this thrilling journey to unlock the secrets of AI! \ud83d\ude80\ud83d\udd0d This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=468",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcda COVID-19 training course for the general public and health professionals \ud83d\udc69\u200d\u2695\ufe0f\ud83d\udc68\u200d\u2695\ufe0f",
          "desc": "\ud83e\udda0\ud83d\udcda Equip yourself with COVID-19 essentials! Join our self-study course now! \ud83c\udf1f\ud83d\udc89 Discover virus facts, transmission, symptoms, diagnosis, treatment, and how to protect yourself. Spot misinformation too! \ud83c\udf0d\ud83d\udcaa For curious minds and healthcare pros! Here's what you'll learn: 1\ufe0f\u20e3 What is COVID-19? 2\ufe0f\u20e3 How it spreads. 3\ufe0f\u20e3 Diagnosis, treatment updates. 4\ufe0f\u20e3 Self & others protection. 5\ufe0f\u20e3 Unmasking false news. Fight the virus with knowledge! Stay informed! \ud83d\ude4c\ud83d\udc99 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=479",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 2: Application of AI in daily life \ud83c\udf1f\ud83d\udcbb",
          "desc": "AI is taking over the world! \ud83c\udf10\ud83e\udd16 But understanding its true potential can be a mystery. That's where our course comes in! \ud83d\udcda\u2728 Get ready for an exciting journey into the basics of Artificial Intelligence, demystifying this fascinating field. \ud83e\udde0\ud83d\udca1 Part 2 of our course, \"Artificial Intelligence - Launchpad to Fundamental Questions,\" dives into real-world examples of AI in action. Join us and uncover the power of AI! \ud83d\ude80\ud83d\udd0d Let's explore together and conquer the barriers that may seem daunting. Get ready to embrace the future with confidence and unlock the secrets of Artificial Intelligence! \ud83c\udf1f\ud83e\udd16 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=472",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcb0\ud83d\udcc8 Introduction to Financial Planning \ud83c\udf31\ud83c\udfe6",
          "desc": "Master the art of financial planning in our self-study course! \ud83d\udcbc\ud83d\udcb0 Ideal for young professionals and aspiring entrepreneurs, this course explores key aspects of financial planning for companies and organizations. By the end, you'll be equipped with essential tools and knowledge to confidently manage finances and make informed decisions. \ud83d\udcca\ud83c\udf93 Join us on this exciting journey to financial success! \ud83d\ude80\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=102",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcdd\ud83d\udcca How To Write and Use a Business Plan \ud83d\ude80\ud83d\udcbc",
          "desc": "Ready to unleash your inner business genius? \ud83c\udf1f\ud83d\udcc8 Join our self-study course and master the art of developing killer business plans! \ud83d\udcbc\ud83d\udca1 Perfect for young professionals and aspiring entrepreneurs, this course will equip you with the tools and strategies to create a roadmap for success. \ud83d\ude80\ud83d\uddfa\ufe0f Get ready to turn your dreams into reality and make waves in the business world! \ud83d\udcaa\ud83d\udcbc Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Follow these simple steps: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=67",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "English for Information Technology part2",
          "desc": "Level Up Your IT English \ud83c\udf10 Master grammar, tech lingo, and more. Ace the English for IT B2 / GSE 59-75 exam! \ud83d\udcbc \ud83d\udcbc Perks: 89% of IT employers demand English (Indeed, 2020). \ud83c\udf0d \ud83d\udc69\u200d\ud83c\udfeb Our Approach: Prepare for jobs with essentials. \ud83d\udcbc Refine expression with engaging grammar videos. \ud83d\udcf9 Nail idioms for everyday talk. \ud83d\udde3\ufe0f Boost understanding through fun exercises. \ud83c\udf93\ud83e\udd39\u200d\u2640\ufe0f Ready to dive into this amazing opportunity? Follow these simple steps to get started: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" to be whisked away to the exciting CISCO course. 2\ufe0f\u20e3 Hit that \"\"Get started\"\" button and sign up with your email, password, and a few personal details. We're almost there! 3\ufe0f\u20e3 Embrace the journey ahead by accepting the terms and conditions, and voila! Your learning adventure begins!",
          "link": "https://skillsforall.com/course/english-for-it2?courseLang=en-US&instance_id=4457e8ba-29ec-4008-8f67-14e4aa0969c1",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "Five Steps to Think Critically\ud83e\udde0",
          "desc": "Use this five-step approach to help you think critically when making a decision.\ud83e\udd29 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Cloud Computing\ud83d\udcda\ud83d\udcbb\ud83c\udf1f",
          "desc": "Learn what Cloud is and why it is one of the most innovative technologies of our time. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Data Interpretation Simplified\ud83d\ude80\ud83d\udcc8",
          "desc": "Learn what data is and what different types there are. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Skills for Critical Thinking \ud83e\udde0",
          "desc": "\ud83e\udde0\ud83d\udd0e Unleash your critical thinking skills with this course! Identify key skills for critical thinking and learn how to overcome barriers that hinder you from being incquisitive. Are you ready to kickstart this mind-bending adventure?\ud83d\ude80\ud83d\udea7 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Handling Problems Better\ud83d\udd75\ufe0f\u200d\u2642\ufe0f\ud83d\udd0d",
          "desc": "Learn about the four-step approach to problem solving and some common causes of problems. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Artificial Intelligence\ud83d\udcbb\ud83d\udcf1",
          "desc": "Understand what Artificial Intelligence is, how it is applied, and why it matters.\ud83d\udcf1 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Google Cybersecurity",
          "desc": "Understand the importance of cybersecurity practices and their impact for organizations. Identify common risks, threats, and vulnerabilities, as well as techniques to mitigate them. Protect networks, devices, people, and data from unauthorized access and cyberattacks using Security Information and Event Management (SIEM) tools. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - ACN - Data Analytics",
          "desc": "Master the skills of collecting, transforming, and organising data in order to help make informed business decisions. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude test\ud83d\udd17 below \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16 Here is an overview of the course: https://www.coursera.org/professional-certificates/google-data-analytics",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - ACN - IT Support",
          "desc": "Learn the foundations of networking and operating systems, providing end-to-end customer support, and how to solve problems using code. Learn the skills you need to start a career as a: Database Administrator, IT Specialist, Tech Support Specialist, Systems Analyst, Network Engineer, Help Desk Technician, IT Support Specialist, IT Technician, Computer User Specialist, IT Assistant. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Facebook Marketing Analytics",
          "desc": "Learn the foundations of marketing analytics and how to extract and analyze data, conduct A/B tests, and derive and present insights that inform business decisions. Learn the skills you need to start a career as a: Marketing Analyst, Marketing Researcher, Business Intelligence Analyst, Growth Marketing Analyst, Marketing Science Analyst \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Database Engineer",
          "desc": "Get started in the in-demand field of database engineering with a Professional Certificate from Meta. Learn the skills you need to design, deploy, and manage structured and unstructured data and gain experience with key tools through hands-on projects. Learn the skills you need to start a career as a: Database Engineer, Data Engineer, Data Architect, Database Administrator, Solutions Architect, Machine Learning Engineer, Software Engineer, Data Platform Engineer \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Back-End Developer",
          "desc": "Gain the technical skills required to become a qualified back-end developer with a Professional Certificate from Meta. Learn to use programming systems including Python Syntax, Linux commands, Git, SQL, Version Control, Cloud Hosting, APIs, JSON, XML, and how to confidently use code to solve problems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - IT Automation with Python",
          "desc": "This program builds on your IT foundations to help you take your career to the next level. It\u2019s designed to teach you how to program with Python and how to use Python to automate common system administration tasks. You'll also learn to use Git and GitHub, troubleshoot and debug complex problems, and apply automation at scale by using configuration management and the Cloud. This program prepare you for a variety of roles in IT, like more advanced IT Support Specialist or Junior Systems Administrator positions. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - UX Design",
          "desc": "Learn UX foundations and gain experience with the design process, including how to build wireframes and prototypes and conduct user research to test your designs. Learn the skills you need to start a career as a: User Experience (UX) Designer, UI Designer, Interaction Designer, Visual Designer, Product Designer \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Plastic Waste and the Basel Convention \ud83d\udeae\u267b\ufe0f",
          "desc": "\ud83c\udf0a\ud83d\udeaf Dive into this course to explore the impacts of marine plastic waste and international instruments tackling this issue! \ud83c\udf0e Given the alarming rise in marine litter, posing a significant environmental, social, and economic challenge worldwide, the focus is on The Basel Convention \u2013 the only global, legally binding instrument specifically addressing plastic waste. \ud83c\udf31 The course covers three key pillars: 1. \ud83d\udd04 Prevention and minimization of plastic waste generation, 2. \u267b\ufe0f Environmentally sound management of plastic waste, and 3. \ud83c\udf10 Control of transboundary movements of plastic waste. Join us in understanding and combating the plastic pollution crisis! \ud83c\udf0a\ud83c\udf3f\ud83e\udd1d",
          "link": "https://unccelearn.org/course/view.php?id=164&page=overview",
          "image": "../../../assets/images/yoma/Yoma .webp"
      },
      {
          "name": "Les d\u00e9chets plastiques et la Convention de B\u00e2le \ud83c\udf0a\ud83d\udd04\ud83d\udc9a",
          "desc": "Salut toi! \ud83c\udf89 Ce cours, c'est comme une plong\u00e9e profonde dans le monde des d\u00e9chets plastiques dans les oc\u00e9ans \ud83c\udf0a\ud83d\udeaf et les efforts mondiaux pour s'attaquer \u00e0 ce probl\u00e8me. Plonge avec nous pour d\u00e9couvrir l'impact des d\u00e9chets plastiques marins et les outils internationaux qui font face \u00e0 ce d\u00e9fi. Avec la pollution plastique qui explose, c'est pas seulement une menace environnementale, mais aussi sociale et \u00e9conomique \u00e0 l'\u00e9chelle mondiale. \ud83c\udf0e\ud83d\udc94 On mettra en lumi\u00e8re la Convention de B\u00e2le, le super-h\u00e9ros des instruments juridiques mondiaux ciblant sp\u00e9cifiquement les d\u00e9chets plastiques. \ud83e\uddb8\u200d\u2642\ufe0f Tout au long du cours, on se concentrera sur trois points principaux : 1) pr\u00e9venir et r\u00e9duire au minimum la production de d\u00e9chets plastiques, 2) la gestion \u00e9cologiquement rationnelle des d\u00e9chets plastiques, et 3) surveiller les mouvements transfrontaliers des d\u00e9chets plastiques. \ud83c\udf31\u267b\ufe0f Pr\u00eat(e) \u00e0 faire des vagues dans la lutte contre la pollution plastique ? \ud83c\udf0a\ud83c\udf0d Allons-y ! \ud83d\udcaa\ud83d\ude80",
          "link": "https://unccelearn.org/course/view.php?id=164&page=overview",
          "image": "../../../assets/images/yoma/Yoma .webp"
      }
  ],
  "Emotional IQ": [
      {
          "name": "\ud83d\udee1\ufe0f How to deal with Cyberbullying \ud83d\udeab",
          "desc": "\ud83d\udeab\ud83c\udf10 Brace yourself for a power-packed session on cyberbullying and how to combat it like a pro! Let's make the digital world a kinder and safer space for everyone! \ud83c\udf10 Baffled by what cyberbullying really is and how it differs from the traditional playground bullying? \ud83d\udcd6\ud83e\udd14 This course has got your back! You'll also be equipped with the tools to tackle any online bullying situation like a champ. \ud83d\udcaa\ud83d\udc41\u200d\ud83d\udde8 By the end of this course, you'll be a certified cyberbullying warrior, armed with these crucial skills: \ud83d\udcd6\ud83e\udd14 Clear understanding of what cyberbullying entails \ud83c\udf1f\ud83d\udc65 Knowledge of the nuances between online and offline bullying dynamics \ud83c\udd98\ud83d\udc41\u200d\ud83d\udde8 Confidence in knowing exactly what to do if you're a victim or a witness of cyberbullying \ud83d\udcaa\ud83d\udeab Let's unite and take a stand against cyberbullying, one click at a time! Join the incredible community at \"atingi\" and be part of the e-learning revolution! \ud83c\udf0d\ud83d\udca1 Here's your step-by-step guide to dive right in: Hit that \"Go to Opportunity\" button to teleport straight to atingi. \ud83d\ude80 Sign up using your email and a password, and be on the lookout for your verification email. \ud83d\udcdd Explore the wide array of atingi opportunities once you're in! Psst, all the courses are absolutely free. \ud83c\udd93 Conquer the course, grab that shiny atingi certificate, and flaunt it proudly on Yoma with a victorious shout-out \"I'm a cyberbullying fighter!\" \ud83d\udcaa\ud83c\udf93 Exchange your hard-earned Zlto rewards for some cool goodies wherever you can. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't let this chance slip away. Together, we can build a safer internet for all! \ud83d\ude80",
          "link": "https://online.atingi.org/enrol/index.php?id=4159",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\u267f Empower Accessibility \ud83c\udf1f",
          "desc": "\ud83c\udf10 Hey there! Ready to dive into the world of digital accessibility? Let's make the internet inclusive for everyone! \ud83d\ude4c\ud83d\udcf1 Join this super cool microlearning session that breaks down what digital accessibility is all about. \ud83d\udcda This epic online course is brought to you by the amazing global e-learning hub \"atingi\" \ud83c\udf0d\ud83d\udca1. Here's how you can get in on the action: Hit that \"Go to Opportunity\" button to warp straight to atingi. \ud83d\ude80 Sign up with your email and a password, then keep an eye out for your verification email. \ud83d\udcdd Once you're in, get ready to explore all the awesome atingi opportunities for free! \ud83c\udd93 Crush the course, grab your well-deserved atingi certificate, and flaunt it on Yoma with a shout-out \"I did it!\" \ud83d\udcaa\ud83c\udf93 Snag some sweet Zlto rewards for your achievements whenever you can. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't snooze on this, it's gonna be epic! \ud83c\udf1f",
          "link": "https://online.atingi.org/enrol/index.php?id=4106",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Customer Service for Tourism Businesses \ud83c\udfd6\ufe0f\ud83d\udece\ufe0f",
          "desc": "\ud83c\udf93 Explore customer satisfaction and learn how to meet their needs with this self-paced course! \ud83c\udf1f Discover strategies for responding to customer wishes and handling complaints effectively. \u23f0 Complete in 1 hour and earn an open badge upon passing the assessment. \ud83c\udf96\ufe0f This course is part of the \"Fundamentals of Tourism and Hospitality\" learning pathway, including modules like Tourism & Hospitality, Food & Beverage Business, Soft Skills, Customer Service, and Housekeeping. \u2728\ud83c\udfe8 Take this course individually or complete the whole series! \ud83d\ude80 This online course is offered by the e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" to join this course on atingi. \u2705 2. Register to access all atingi opportunities. It's free! \ud83c\udd93 3. Once you finish, upload your atingi certificate on Yoma and redeem Zlto rewards if applicable. \ud83d\udcaa\ud83d\udcb0 Don't miss out on learning with atingi! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=4189",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf0dDisability rights & inclusive development (Africa) \u267f\ufe0f",
          "desc": "\ud83c\udf0d\ud83e\udd1d Explore disability inclusive development in Africa! This self-paced course dives into existing disability rights mechanisms and frameworks on the continent. \ud83c\udf1f\u2728 Module 1: Discover African human rights mechanisms. Module 2: Achieve disability inclusive development through global partnerships. \ud83e\udd1d\ud83d\udcbc Join this course and make a difference! \ud83d\udcaa\ud83c\udf93 Join atingi and unlock your potential! \ud83c\udf0d\ud83d\udca1 Click \"Go to Opportunity\" to access the course. \ud83d\ude80 Register with your email and password. Verify your email. \ud83d\udcdd Explore all free atingi courses. \ud83c\udd93 Complete the course, earn your atingi certificate, and showcase on Yoma as \"I have completed this\". \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards for your achievements. \ud83d\udcb0\ud83d\udcab Don't miss out on this empowering journey! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=2500",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udc65\ud83d\udd27 Customer Care & Complaint Management \ud83c\udf1f\ud83d\udd0d",
          "desc": "Ready to level up your customer care skills? \ud83c\udf1f\ud83d\udcbc Join our self-paced course and discover the importance of delivering exceptional customer service. Learn how to satisfy your customers and effectively manage any complaints that may arise. Get ready to enhance your customer care game and create happy, loyal customers. Enjoy the journey! \ud83d\ude80\ud83d\udc65\ud83d\ude0a This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=4140",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udeab\ud83e\udde0 How to Get Rid of Self-Limiting Beliefs \ud83c\udf1f\ud83d\udcaa",
          "desc": "\ud83e\udde0\ud83d\udeab Break free from self-limiting beliefs! This course includes: \u2022 Understanding the impact of self-limiting beliefs. \ud83e\uddd8\u200d\u2640\ufe0f\ud83d\udd12 \u2022 Identifying and challenging your self-limiting beliefs. \ud83d\udd75\ufe0f\u200d\u2640\ufe0f\ud83d\udd0d \u2022 Practical tips to overcome barriers and unleash your potential. \ud83d\ude80\ud83d\udcaa This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=169",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcda COVID-19 training course for the general public and health professionals \ud83d\udc69\u200d\u2695\ufe0f\ud83d\udc68\u200d\u2695\ufe0f",
          "desc": "\ud83e\udda0\ud83d\udcda Equip yourself with COVID-19 essentials! Join our self-study course now! \ud83c\udf1f\ud83d\udc89 Discover virus facts, transmission, symptoms, diagnosis, treatment, and how to protect yourself. Spot misinformation too! \ud83c\udf0d\ud83d\udcaa For curious minds and healthcare pros! Here's what you'll learn: 1\ufe0f\u20e3 What is COVID-19? 2\ufe0f\u20e3 How it spreads. 3\ufe0f\u20e3 Diagnosis, treatment updates. 4\ufe0f\u20e3 Self & others protection. 5\ufe0f\u20e3 Unmasking false news. Fight the virus with knowledge! Stay informed! \ud83d\ude4c\ud83d\udc99 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=479",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83e\udd1d\ud83d\udde8\ufe0f Interpersonal Communication \ud83d\udcac\ud83c\udf1f",
          "desc": "\ud83d\udcda\ud83d\udde3\ufe0f Join our awesome self-study course and level up your communication skills! \ud83c\udf1f In just four fun learning units, you'll master the essentials of interpersonal communication! \ud83e\udd1d\ud83d\udca1 This course is perfect for anyone who wants to learn how to communicate respectfully and attentively! \ud83c\udfaf\ud83d\ude80 Don't miss out on this opportunity to gain practical knowledge! \ud83c\udf08\ud83d\udcac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/course/view.php?id=125",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Communicating Virtually\ud83d\udcaa\ud83c\udf93",
          "desc": "Learn about the importance of communicating virtually and the options for communicating in both work and personal life. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "How to Master Mistakes\ud83d\ude80\ud83c\udf93",
          "desc": "Mistakes are a part of life, so learn some tips on how to deal with the consequences and how to rectify it. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Managing Conflicts\ud83c\udf10\ud83d\udca5",
          "desc": "Conflicts in the workplace are perfectly normal - all you need to know is how to manage and resolve them properly. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      }
  ],
  "Entrepreneurship": [
      {
          "name": "Women in Tech \ud83d\udcbb\ud83d\udc69\u200d\ud83d\udcbc\ud83d\udc69\u200d\ud83d\udcbb\ud83c\udf1f",
          "desc": "The tech world has been a bit of a boys' club for too long, but it's time for a major shake-up! Did you know that only 24 percent of IT jobs worldwide are held by women? Let's change that stat, shall we? \ud83d\udcaa In this quick and punchy learning nugget, get ready to meet some seriously cool women who are totally slaying it in the tech industry. By the time you're done, you'll see that the tech world is bursting with opportunities for all! This mind-blowing online course is brought to you by the awesome folks at \"atingi.\" \ud83c\udf0d\ud83d\udca1 Here's your chance to jump into the e-learning revolution: Hit that \"Go to Opportunity\" button and get zapped straight to atingi. \ud83d\ude80 Sign up using your email and a password. Look out for that verification email\u2014 it's on its way! \ud83d\udcdd Once you're in, get ready to explore all the amazing atingi opportunities. Psst, they're all totally free! \ud83c\udd93 Crush the course, snag that sweet atingi certificate, and show it off on Yoma with a proud shout-out \"I'm a tech superstar!\" \ud83d\udcaa\ud83c\udf93 Score some awesome Zlto rewards for all your hard work, wherever you can. \ud83d\udcb0\ud83d\udcab Let's make the tech world more diverse and exciting with atingi! Don't miss this chance to be inspired and carve your path in the tech universe! \ud83c\udf1f",
          "link": "https://online.atingi.org/enrol/index.php?id=943 ",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Introduction au Marketing \ud83d\udca1\ud83d\udcc8",
          "desc": "\ud83c\udf1f\ud83d\udcbc Pr\u00eat(e) \u00e0 booster ta pr\u00e9sence en ligne ? Rejoins notre cours d'introduction au marketing pour d\u00e9couvrir les techniques et outils indispensables \u00e0 la promotion de tes produits et services et \u00e0 l'augmentation de tes revenus ! \ud83d\ude80\ud83d\udcc8 Laisse ton empreinte dans le monde du marketing et atteins de nouveaux sommets ! \ud83d\udcaa\ud83d\udcbb \ud83d\udca1Ce cours en ligne est propos\u00e9 par la plateforme d'apprentissage en ligne \"atingi\". Clique sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Tu seras redirig\u00e9(e) vers le site web d'atingi.\ud83c\udf89 Une inscription pr\u00e9alable est n\u00e9cessaire pour y acc\u00e9der. Une fois inscrit(e), tu auras acc\u00e8s \u00e0 ce cours ainsi qu'\u00e0 tous les autres cours de la plateforme.\ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Apr\u00e8s avoir r\u00e9ussi le cours, tu recevras ton certificat atingi que tu pourras d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5",
          "link": "https://online.atingi.org/enrol/index.php?id=2831",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "What it means to be a Tourism Entrepreneur \ud83c\udf0d\ud83d\udcbc",
          "desc": "\ud83d\udcda Start your own business! \ud83d\ude80 Learn from the inspiring stories of successful entrepreneurs who started from scratch. \ud83d\udcaa Discover their experiences, challenges, and triumphs. \u23f0 Complete this course in 1 hour and earn an open badge upon passing the assessment. \ud83c\udf96\ufe0f Part of the \"Introduction into Professions in the Tourism Sector\" pathway: Tourism, Hospitality, Tour Guiding, and Tourism Entrepreneurship. \ud83c\udf0d\u2728 After completing the pathway, receive a digital credential (open badge) to share on social media! Take the full series or individual courses! This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" to join the course on atingi. \u2705 2. Register for free access to all atingi opportunities. \ud83c\udd93 3. Upon completion, upload your atingi certificate on Yoma and redeem Zlto rewards where applicable. \ud83d\udcaa\ud83d\udcb0 Don't miss out on learning with atingi! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=4099",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Successful Pitching for Entrepreneurs \ud83d\udca1\ud83d\ude80",
          "desc": "\ud83d\udce2 Master the art of pitching! \ud83c\udfa4\ud83d\udcca This self-paced course will guide you through the entire pitching process, step-by-step. From preparing a pitch to delivering it with confidence, and following up for success. By the end of the course, you'll have a polished pitch ready to conquer the real world! \ud83d\udcbc\u2728 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3231",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Digital Economy and Work \ud83d\udcbb\ud83c\udfe2",
          "desc": "\ud83d\udcbb\ud83c\udf0d\ud83d\udcda Dive into the digital economy! This self-paced course will introduce you to the exciting world of digital economies. Discover the building blocks and explore the pros and cons of this innovative system. Get inspired by practical examples of countries leveraging digital technologies to boost their economies. \ud83d\udcc8\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2760",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Foundations of Food & Beverage Business \ud83c\udf7d\ufe0f\ud83e\udd42",
          "desc": "\ud83c\udf7d\ufe0f\ud83c\udf7b Discover Food & Beverage! This self-paced course immerses you in the industry. Learn about businesses, leadership, and menu engineering. Master profitability in just 1 hour! \ud83c\udf93\ud83d\udd16 Part of \"Fundamentals of Tourism and Hospitality\" pathway: Tourism & Hospitality, F&B Business, Soft Skills, Customer Service, and Housekeeping. \ud83c\udf0d\ud83d\udd11 Choose full series or individual courses! \ud83d\ude80 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: Click \"Go to Opportunity\" to join. \ud83d\ude80 Register and verify your email. \ud83d\udcdd Explore all free courses. \ud83c\udd93 Complete the course, earn atingi certificate, and showcase on Yoma \"I've completed this\". \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards! \ud83d\udcb0\ud83d\udcab Don't miss out on learning and earning! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=4137",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf10\ud83c\udfe2 Creating a Digital Presence for your Business \ud83d\udcbb\ud83d\ude80",
          "desc": "\ud83c\udf10\ud83d\udcf2 Take charge of your digital presence! In this self-paced course, unlock the secrets to shaping a positive online image for yourself and your business. Gain a deep understanding of the key principles behind building a strong digital presence. \ud83d\ude80\ud83d\udcbb Build your digital empire now! \ud83d\udcaa\ud83c\udf1f This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=3409",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udc14\ud83c\udfe1 \u00c9levage de volaille \ud83c\udf31\ud83e\udd5a",
          "desc": "\ud83d\udc14\ud83d\ude0a D\u00e9couvre les secrets de la r\u00e9ussite dans la production avicole avec notre cours complet ! \ud83d\udcda\ud83c\udf31 Apprends tout ce qu'il faut savoir pour l'installation et la gestion d'un \u00e9levage de volaille. \ud83d\udc23\ud83d\udcaa Que tu sois d\u00e9butant ou exp\u00e9riment\u00e9, on te donne les cl\u00e9s du succ\u00e8s ! Pr\u00eat \u00e0 te lancer ? Inscris-toi d\u00e8s maintenant ! \ud83d\ude80\ud83c\udf1f \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2314",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf33\ud83c\udf3f Protection des parcs \u00e0 karit\u00e9 et agroforesterie \ud83c\udf31\ud83d\udee1\ufe0f",
          "desc": "\ud83d\udcda\ud83c\udf0d Explorez les Conventions Locales (CL) dans ce cours captivant ! \ud83c\udf1f\ud83d\udca1 Apprenez \u00e0 les d\u00e9finir, \u00e0 comprendre leurs objectifs, et \u00e0 les mettre en place et les g\u00e9rer. Divis\u00e9 en quatre modules passionnants : [1] G\u00e9n\u00e9ralit\u00e9s sur les CL, [2] \u00c9tablissement d'une CL, [3] Gestion d'une CL, et [4] Agroforesterie autour des parcs \u00e0 Karit\u00e9. Chaque module est accompagn\u00e9 d'une \u00e9valuation pour tester vos connaissances. \ud83d\udcdd\ud83d\udd0d Plongez dans le monde des CL et enrichissez vos comp\u00e9tences ! \ud83d\udcaa\ud83c\udf33\ud83c\udf31 \ud83d\udca1Ce cours est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour y participer. \ud83c\udf89 Vous serez redirig\u00e9 vers le site web d'atingi o\u00f9 vous devrez vous inscrire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. \u00c0 la fin du cours, vous recevrez votre certificat atingi. Vous pourrez ensuite le d\u00e9poser sur Yoma et utiliser Zlto si possible. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=1550",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf30\ud83c\udf3f Bonnes pratiques d'entretien des vergers d\u2019anacardiers\ud83c\udf33\ud83d\ude9c",
          "desc": "\ud83d\udcaa\ud83c\udf33\ud83c\udf31 Am\u00e9liore ta productivit\u00e9 et ton revenu en entretenant tes plantations d'anacardiers ! \ud83d\udcb0\u2728 Ce cours te pr\u00e9sente les bonnes pratiques agricoles pour optimiser la production de noix de cajou. \ud83d\ude1e\ud83c\udf30 Rejoins-nous et d\u00e9couvre comment obtenir des r\u00e9sultats impressionnants en entretenant tes plantations ! \ud83c\udf1f\ud83c\udf31\ud83d\udcaa \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage \"atingi\". Clique sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. \ud83c\udf89 Apr\u00e8s t'inscrire, tu auras acc\u00e8s \u00e0 ce cours ainsi qu'\u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtiens ton certificat atingi apr\u00e8s avoir termin\u00e9 le cours avec succ\u00e8s. Tu pourras \u00e9galement le d\u00e9poser sur Yoma et utiliser Zlto si possible. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=1439",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf30\ud83c\udf3f Pratiques de collecte des amandes de karit\u00e9 \ud83c\udf33\ud83d\ude9c",
          "desc": "Ce cours met en avant les meilleures pratiques de ramassage, de transformation et d'hygi\u00e8ne pour obtenir des amandes de qualit\u00e9 sup\u00e9rieure. \ud83c\udf30\ud83d\udcaa Apprends les techniques essentielles pour assurer la qualit\u00e9 des amandes tout au long du processus. \ud83d\udcda\ud83c\udf33\u2728 \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=1551",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf30\ud83c\udf33 Pratiques de r\u00e9colte des noix de cajou \ud83c\udf1e\ud83d\ude9c",
          "desc": "Ce cours va te d\u00e9voiler tous les secrets des op\u00e9rations de pr\u00e9-r\u00e9colte, r\u00e9colte et post-r\u00e9colte pour des produits de cajou de qualit\u00e9 sup\u00e9rieure ! \ud83c\udf30\ud83d\udcaa\ud83d\udca1 Sois pr\u00eat \u00e0 devenir un(e) expert(e) de la cajou-mania ! \ud83d\ude09\ud83c\udf89 \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2157",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udc69\u200d\ud83d\udcbc\ud83c\udf0d Entrepreneuriat F\u00e9minin en Afrique (B\u00e9nin) \ud83d\udcaa\ud83c\udf1f",
          "desc": "\ud83d\udeba\ud83d\udcbc D\u00e9couvrez l'entrepreneuriat f\u00e9minin en Afrique \ud83c\udf0d\ud83d\udcaa. Les femmes africaines sont des championnes de l'entrepreneuriat \ud83c\udf1f. Le potentiel \u00e9conomique des femmes pourrait stimuler la croissance et la prosp\u00e9rit\u00e9 du continent \ud83d\udcc8\ud83d\udcb0. Ce module est con\u00e7u sp\u00e9cialement pour les femmes entrepreneures ou celles qui veulent se lancer \ud83d\udcbc\u2728. Pour participer \u00e0 ce cours en ligne, cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" et inscrivez-vous. Une fois inscrite, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=1564",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Initiation au Lean Startup \ud83d\udcbc\ud83d\udca1",
          "desc": "\ud83d\ude80\ud83d\udcc9 Le \"Lean Startup\" est la m\u00e9thode id\u00e9ale pour cr\u00e9er et lancer une activit\u00e9 avec peu de ressources \ud83d\udca1\ud83d\udcb0. Cette approche repose sur la validation des concepts, l'exp\u00e9rimentation scientifique et la conception it\u00e9rative pour d\u00e9velopper des produits qui r\u00e9pondent aux besoins des consommateurs \ud83c\udfaf\ud83d\udc65. Le module sur le lean startup convient \u00e0 tous ceux qui veulent se lancer dans une nouvelle activit\u00e9 ou lancer un nouveau produit \ud83d\ude80\ud83d\udcca. Pr\u00eat \u00e0 d\u00e9marrer ? \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Une inscription pr\u00e9alable est n\u00e9cessaire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=1558",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Aviculture villageoise \ud83c\udf3e\ud83e\udd5a\ud83d\udc14",
          "desc": "\ud83d\udc14\ud83d\udcda Le manque d'infos ad\u00e9quates est souvent un gros obstacle pour les promoteurs agricoles dans la production avicole \ud83d\udeab\ud83d\udce2. Mais ne t'inqui\u00e8te pas ! Ce module te donne toutes les bases pour cr\u00e9er et g\u00e9rer ton propre \u00e9levage de volaille \ud83d\udcd6\ud83c\udfe1. C'est le guide parfait pour ceux qui sont int\u00e9ress\u00e9s par cette activit\u00e9 \ud83d\udc13\ud83d\udd27. Pr\u00eat \u00e0 te lancer ?r l'installation et la gestion d'un \u00e9levage de volaille villageoise \ud83d\udcd6\ud83c\udfe1. Il couvre les aspects fondamentaux de cette activit\u00e9, offrant ainsi un guide pr\u00e9cieux pour les int\u00e9ress\u00e9s \ud83d\udc13\ud83d\udd27. \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Une inscription pr\u00e9alable est n\u00e9cessaire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2132",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Transformer les amandes en beurre de karit\u00e9 \ud83c\udf31\ud83e\uddc8",
          "desc": "\ud83e\udd5c\ud83e\uddc8 Pour r\u00e9pondre \u00e0 la demande croissante de karit\u00e9 (amandes et beurre), les acteurs doivent surmonter les contraintes li\u00e9es au respect des bonnes pratiques de transformation et d'hygi\u00e8ne \ud83d\udee1\ufe0f\ud83e\uddfc. Ce cours propose un processus simple et efficace \u00e0 la fois pour les acteurs de la fili\u00e8re et les personnes int\u00e9ress\u00e9es par l'am\u00e9lioration de la qualit\u00e9 de la transformation des amandes en beurre \ud83d\udcda\ud83d\udc69\u200d\ud83c\udf73. Il offre des conseils pr\u00e9cieux pour am\u00e9liorer la transformation du karit\u00e9 et garantir une qualit\u00e9 optimale du produit final \ud83c\udf1f\ud83e\udd63. \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Une inscription pr\u00e9alable est n\u00e9cessaire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2527",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Installation de plantations d\u2019anacardiers \ud83d\ude9c\ud83c\udf1e",
          "desc": "\ud83c\udf31\ud83d\udcda Certains producteurs n\u00e9gligent les bonnes pratiques agricoles et recommand\u00e9es lors de l'installation de leurs plantations . Des pratiques observ\u00e9es incluent l'utilisation de mat\u00e9riel v\u00e9g\u00e9tal inappropri\u00e9, le semis direct des noix, le non-respect des densit\u00e9s de plantation, etc. Pourtant, ces bonnes pratiques sont essentielles pour obtenir une production optimale\ud83c\udf1f\ud83c\udf3e. Ce cours Ce cours vous aide \u00e0 les ma\u00eetriser et augmenter vos revenus! \ud83d\udcaa\ud83d\udcb0 \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Une inscription pr\u00e9alable est n\u00e9cessaire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2528",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Bonnes Pratiques de production du soja \ud83d\ude9c\ud83c\udf1e",
          "desc": "\ud83c\udf31 Les producteurs de soja rencontrent des d\u00e9fis pour exploiter pleinement le potentiel des semences certifi\u00e9es \ud83d\udeab. Les pratiques agricoles traditionnelles entra\u00eenent souvent des rendements faibles \u26a0\ufe0f\ud83c\udf3e. Ce cours vise \u00e0 am\u00e9liorer la productivit\u00e9 du soja et augmenter les revenus des producteurs \ud83d\udcaa\ud83d\udcb0. Apprenez les bonnes pratiques pour optimiser la culture du soja \ud83c\udf31\ud83d\udcc8. \ud83d\udca1Ce cours en ligne est offert par la plateforme d'apprentissage en ligne \"atingi\". Cliquez sur \"Acc\u00e9dez \u00e0 l'opportunit\u00e9\" pour participer. Une inscription pr\u00e9alable est n\u00e9cessaire. Une fois inscrit, vous aurez acc\u00e8s \u00e0 ce cours et \u00e0 tous les autres sur la plateforme. \ud83d\udcaa\ud83c\udffd Toutes les offres sur atingi sont gratuites. Obtenez votre certificat atingi apr\u00e8s avoir termin\u00e9 avec succ\u00e8s. D\u00e9posez-le sur Yoma et utilisez Zlto si applicable. \ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2529",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Transformation du soja en fromage \ud83c\udf31\ud83c\udf31",
          "desc": "\ud83c\udf31\ud83e\uddc0 D\u00e9couvre le monde du fromage de soja ! Ce cours te plonge dans l'univers de cette l\u00e9gumineuse riche en prot\u00e9ines \ud83c\udf3f\ud83e\udd57. Apprends l'importance du soja dans l'alimentation et la nutrition humaine, et le processus de fabrication du fromage avec les Bonnes Pratiques d'Hygi\u00e8ne \ud83d\udc69\u200d\ud83c\udf73\ud83d\udcda. Deviens un pro du fromage de soja de qualit\u00e9 ! \ud83e\uddc0\ud83c\udf31 \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2531",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Les propri\u00e9t\u00e9s du soja \ud83c\udf3f\ud83c\udf31",
          "desc": "Le soja (Glycine max) est un super-aliment proche du haricot \ud83c\udf31\ud83c\udf1f. Originaire d'Asie, il regorge de nutriments essentiels. Ce cours te d\u00e9voile toutes les propri\u00e9t\u00e9s magiques du soja et comment il peut enrichir nos repas \ud83c\udf72\ud83d\udcaa. D\u00e9couvre ses fonctions incroyables en tant que produit alimentaire pour une alimentation saine et vari\u00e9e ! \ud83e\udd57\ud83c\udf31 \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2532",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Comment produire du riz de bonne qualit\u00e9 ? \ud83c\udf3e\ud83c\udf3e",
          "desc": "\ud83c\udf3e\ud83d\udcda Ce cours vise \u00e0 augmenter les rendements du riz dans les bassins rizicoles, am\u00e9liorant les revenus des riziculteurs et r\u00e9duisant les importations \ud83d\udcc8\ud83d\udcb0. Avec des connaissances de terrain et des innovations \u00e9prouv\u00e9es, tu d\u00e9couvriras la production durable du riz \u00e0 travers quatre modules \ud83c\udf3e\ud83d\udcd6. \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2549",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "La pr\u00e9paration de la p\u00e9pini\u00e8re \ud83c\udf1e\ud83c\udf31",
          "desc": "\ud83d\udcda En riziculture, deux m\u00e9thodes de semis existent : direct et indirect (repiquage). Pour cette derni\u00e8re, une p\u00e9pini\u00e8re est cr\u00e9\u00e9e pour obtenir des plantules vigoureuses \ud83c\udf31\ud83c\udf3e. Ce cours d\u00e9taille les \u00e9tapes d'installation et d'entretien de la p\u00e9pini\u00e8re \ud83c\udfed. Il te donne les connaissances pour r\u00e9ussir la gestion efficace d'une p\u00e9pini\u00e8re dans le contexte de la riziculture.\ud83c\udf3e \ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/enrol/index.php?id=2559",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "R\u00e9ussir l'\u00e9tuvage et la transformation du riz \ud83c\udf3e\ud83d\udc69\u200d\ud83c\udf73",
          "desc": "\ud83c\udf3e\ud83d\udcda Ce cours est id\u00e9al pour les conseillers agricoles passionn\u00e9s par la gestion des ennemis des cultures \ud83c\udf31. Il peut \u00eatre suivi en ligne ou en pr\u00e9sentiel avec un apprentissage mixte \ud83d\udda5\ufe0f. Le cours am\u00e9liorera tes connaissances sur la protection des cultures irrigu\u00e9es \ud83d\udca6. Approfondis tes comp\u00e9tences en diagnostic phytosanitaire pour une agriculture durable et productive. \ud83c\udf3f \ud83d\udca1Ce cours est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13",
          "link": "https://online.atingi.org/course/view.php?id=2644",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf3e\ud83d\udcbc Agriculture as a Business \ud83d\ude80\ud83c\udf31",
          "desc": "\ud83c\udf3e\ud83d\udcc8 Ready to grow your agricultural business? Join \"Agriculture as a Business\" course! \ud83c\udf1f\ud83d\ude80 It consists of 7 self-paced sessions and a bonus session. \ud83d\udcda\u23f0 Sessions 1-3 are already available, packed with awesome learning objectives and time estimates. Stay tuned for sessions 4-7 and the bonus material \ud83c\udf89\ud83d\udcc5 Let's cultivate success together! \ud83c\udf31\ud83d\udcaa This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=1397",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf3e\ud83d\udc69\u200d\ud83c\udf3e Being an Agripreneur \ud83d\ude80\ud83c\udf31",
          "desc": "Ready to explore the world of Agripreneurs? \ud83c\udf31\ud83c\udf1f Join our self-study course and dive into the fascinating job of an \"Agripreneur.\" Gain insights from real Agripreneurs, learn about essential skills, and acquire knowledge in natural sciences. By participating in this course, you'll get a unique glimpse into the life of an Agripreneur. Developed in collaboration with Siemens Stiftung, this course is your gateway to the exciting world of agriculture and entrepreneurship. Let's embark on this journey together! \ud83d\ude80\ud83d\udcbc\ud83c\udf3e This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=64",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf3e\ud83d\udca1 Promoting Innovation through Inclusive Contract Farming \ud83e\udd1d\ud83d\ude80",
          "desc": "Get ready to dive into the world of inclusive Contract Farming (iCF)! \ud83c\udf3e\ud83e\udd1d Join our self-paced course designed to introduce this concept to a diverse range of individuals. While we won't cover the entire complexity of the topic, we'll provide you with a glimpse of what iCF entails, why it supports small farmers, farmers' associations, and SMEs, and how it contributes to sustainable development. Let's explore the power of inclusive Contract Farming together! \ud83d\ude80\ud83c\udf31\ud83c\udf0d This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=181",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83d\udcb0 E-Commerce and E-Business \ud83c\udf10\ud83d\ude80",
          "desc": "Get ready to embark on a journey through the evolution of commerce! \ud83c\udf10\ud83d\udcbc Join our course as we explore the transition from traditional business to the dynamic world of e-commerce. Discover key concepts that fall under the vast umbrella of e-commerce, stay up-to-date with the latest trends in this space, and delve into the operational side of online business. Let's navigate the ever-changing world of e-commerce together! \ud83d\ude80\ud83d\udcc8\ud83c\udf0d This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your Atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=2809",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83c\udf31 Digital Livelihoods Challenge Follow up Course: Testing Market Validation \ud83e\uddea\ud83d\udcca",
          "desc": "\ud83c\udf1f\ud83d\udca1 Ready to transform your business idea into a captivating success? Join our course designed for innovative minds like yours! Whether you're starting from scratch or struggling to find your target audience, we've got you covered. \ud83d\ude80\ud83c\udfaf In this exciting journey, we'll equip you with the tools and strategies to captivate your audience and make your business idea shine. \ud83c\udf1f\ud83d\udcbc Get ready to unleash your entrepreneurial spirit and turn your vision into a captivating reality! Let's make waves in the business world together! \ud83d\udcaa\ud83c\udf0a This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=1147",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcb0\ud83c\udfe2 Financement d'entreprises et collecte de fonds \ud83e\udd1d\ud83c\udf31",
          "desc": "\ud83d\udca1Ce cours en ligne est mis en disposition par la plateforme d'apprentissage en ligne \u00ab atingi \u00bb. Pour participer au cours, cliquez sur le bouton \u00ab Acc\u00e9dez \u00e0 l\u2019opportunit\u00e9 \u00ab . Vous serez redirig\u00e9 directement sur le site web de atingi.\ud83c\udf89 Vous devrez d'abord vous inscrire pour pouvoir y acc\u00e9der. Une fois inscrit, vous aurez un acc\u00e8s \u00e0 ce cours ainsi que tous les autres cours sur la platform.\ud83d\udcaa\ud83c\udffd Toutes les offres disponibles sur atingi sont gratuites. Vous recevrez votre certificat atingi une fois que vous aurez termin\u00e9 votre cours avec succ\u00e8s. Vous pourrez ensuite la d\u00e9poser sur Yoma et utiliser Zlto si applicable.\ud83d\udcd5\ud83e\udd13 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=98",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd0d\u2696\ufe0f Due Diligence \ud83d\udcd1\u2705",
          "desc": "Unleash your start-up potential with our self-study course! \ud83d\ude80\ud83d\udcbc Learn how to ace due diligence and captivate potential investors with an impressive pitch deck. \ud83c\udfa4\ud83d\udcca Level up your start-up game and conquer the world of entrepreneurship! \ud83d\udcaa\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=88",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcdd\ud83d\udcca How To Write and Use a Business Plan \ud83d\ude80\ud83d\udcbc",
          "desc": "Ready to unleash your inner business genius? \ud83c\udf1f\ud83d\udcc8 Join our self-study course and master the art of developing killer business plans! \ud83d\udcbc\ud83d\udca1 Perfect for young professionals and aspiring entrepreneurs, this course will equip you with the tools and strategies to create a roadmap for success. \ud83d\ude80\ud83d\uddfa\ufe0f Get ready to turn your dreams into reality and make waves in the business world! \ud83d\udcaa\ud83d\udcbc Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Follow these simple steps: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=67",
          "image": "../../../assets/images/yoma/Atingi.webp"
      }
  ],
  "Judgement and Decision Making": [
      {
          "name": "Data Awareness \ud83d\udcca\ud83d\udc40\ud83d\udce1",
          "desc": "\ud83d\udcca\ud83c\udf10 Get ready to level up your data game with this super informative microlearning session all about data awareness! Curious about who's snooping on your online moves and how your data is used? \ud83e\udd14\ud83d\udd0d Let's unveil the mysteries of data collection and arm you with the know-how to keep your digital footprint secure. \ud83d\udee1\ufe0f\ud83d\udd12 By the end of this course, you'll be a data ninja, equipped with the following skills: \ud83d\udc40\ud83d\udd0d Sharpened awareness of the data traces you're leaving behind \ud83d\udcc8\ud83e\udd14 Understanding of how your data is collected and used \ud83d\udee1\ufe0f\ud83d\udd12 Pro tips on protecting your precious data from prying eyes \ud83d\udcda\ud83e\uddd0 Empower yourself with the knowledge to safeguard your online presence! Get on board with the global e-learning squad at \"atingi\" \ud83c\udf0d\ud83d\udca1. Here's how to jump into this data-saving adventure: Click that \"Go to Opportunity\" button to warp straight to atingi. \ud83d\ude80 Sign up with your email and a password, then keep an eye out for your verification email. \ud83d\udcdd Dive into all the incredible atingi opportunities once you're in! All courses are totally free. \ud83c\udd93 Crush the course, bag that shiny atingi certificate, and flex it on Yoma with a proud shout-out \"I'm a data warrior!\" \ud83d\udcaa\ud83c\udf93 Rack up some sweet Zlto rewards for all your hard-earned achievements. \ud83d\udcb0\ud83d\udcab Ready to dive in and emerge as the data champ? Let's make learning and earning a breeze with atingi! Don't let this opportunity slip through your fingertips! \ud83d\ude80\ud83d\udcbb\ud83d\udd10",
          "link": "https://online.atingi.org/enrol/index.php?id=4286",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Introduction to Risk Management \ud83d\udea6\ud83d\udd12",
          "desc": "\ud83d\udea8\ud83d\udcbc Master risk management! This self-study course equips you with the skills to identify, manage, and avoid risks. Enterprise-wide risk management (EWRM) is the key to success. Invest your time and effort here, and reap great rewards! \ud83d\udcaa\ud83d\udcb0 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=188",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd12\ud83d\udcca Data Protection Basics \ud83d\udcbb\ud83d\udee1\ufe0f",
          "desc": "\ud83d\udd12\ud83d\udcda Protect your data! This course covers the essentials of data protection, privacy by design, and the European General Data Protection Regulation (GDPR). Gain insights on safely navigating digital environments like social media and learning platforms. Stay secure and informed! \ud83d\udcbb\ud83d\udd10 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2680",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcca\ud83d\udd0d Evaluation, Implementation & Monitoring \ud83d\ude80\ud83d\udd27",
          "desc": " \ud83d\udc69\u200d\ud83d\udcbc\ud83d\ude80 Join this course for young professionals and aspiring entrepreneurs! Topics include: \u2022 Setting up and managing an effective project team \ud83e\udd1d\u2728 \u2022 Monitoring a project's progress \ud83d\udcca\ud83d\udd0d \u2022 Evaluating a project's success \ud83c\udfaf\ud83d\udcc8 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=239",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\ude80\ud83e\udd16 Launchpad to Fundamental Questions of Artificial Intelligence - Part 3: Risks \u26a0\ufe0f\ud83d\udd12",
          "desc": "\ud83c\udf1f\ud83e\udd16 AI is all around us, but understanding its depths can be a mystery. That's where our course comes in! Join us on this thrilling journey to unravel the basics of Artificial Intelligence and conquer the challenges that come with it. Part 3 of \"Artificial Intelligence - Launchpad to Fundamental Questions\" explores the risks and the path forward in the world of AI. Get ready to dive into the risks and discover the solutions ahead! \ud83d\ude80\ud83d\udd0d\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=473",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfe0\ud83d\udcbb T\u00e9l\u00e9travail et s\u00e9curit\u00e9 \ud83d\udd12\u2728",
          "desc": "Pr\u00eats \u00e0 devenir des pros de la cybers\u00e9curit\u00e9 ? \ud83d\udee1\ufe0f\ud83d\udcbb Notre formation en ligne t'apprend tout sur la protection num\u00e9rique sur le lieu de travail priv\u00e9. Que tu sois employ\u00e9(e), travailleur ind\u00e9pendant(e) ou \u00e9tudiant(e), cette formation est faite pour toi ! D\u00e9couvre les risques du home-office, les tactiques des cybercriminels et apprends comment renforcer ta s\u00e9curit\u00e9 en ligne. Rejoins-nous pour ma\u00eetriser la cybers\u00e9curit\u00e9 et garder notre monde virtuel en toute s\u00e9curit\u00e9 ! \ud83d\ude80\ud83d\udcaa\ud83c\udf10 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=205",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd0d\u2696\ufe0f Due Diligence \ud83d\udcd1\u2705",
          "desc": "Unleash your start-up potential with our self-study course! \ud83d\ude80\ud83d\udcbc Learn how to ace due diligence and captivate potential investors with an impressive pitch deck. \ud83c\udfa4\ud83d\udcca Level up your start-up game and conquer the world of entrepreneurship! \ud83d\udcaa\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=88",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Data Interpretation Simplified\ud83d\ude80\ud83d\udcc8",
          "desc": "Learn what data is and what different types there are. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Google Cybersecurity",
          "desc": "Understand the importance of cybersecurity practices and their impact for organizations. Identify common risks, threats, and vulnerabilities, as well as techniques to mitigate them. Protect networks, devices, people, and data from unauthorized access and cyberattacks using Security Information and Event Management (SIEM) tools. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Google.org - ACN - Data Analytics",
          "desc": "Master the skills of collecting, transforming, and organising data in order to help make informed business decisions. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude test\ud83d\udd17 below \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16 Here is an overview of the course: https://www.coursera.org/professional-certificates/google-data-analytics",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Plastic Waste and the Basel Convention \ud83d\udeae\u267b\ufe0f",
          "desc": "\ud83c\udf0a\ud83d\udeaf Dive into this course to explore the impacts of marine plastic waste and international instruments tackling this issue! \ud83c\udf0e Given the alarming rise in marine litter, posing a significant environmental, social, and economic challenge worldwide, the focus is on The Basel Convention \u2013 the only global, legally binding instrument specifically addressing plastic waste. \ud83c\udf31 The course covers three key pillars: 1. \ud83d\udd04 Prevention and minimization of plastic waste generation, 2. \u267b\ufe0f Environmentally sound management of plastic waste, and 3. \ud83c\udf10 Control of transboundary movements of plastic waste. Join us in understanding and combating the plastic pollution crisis! \ud83c\udf0a\ud83c\udf3f\ud83e\udd1d",
          "link": "https://unccelearn.org/course/view.php?id=164&page=overview",
          "image": "../../../assets/images/yoma/Yoma .webp"
      },
      {
          "name": "Les d\u00e9chets plastiques et la Convention de B\u00e2le \ud83c\udf0a\ud83d\udd04\ud83d\udc9a",
          "desc": "Salut toi! \ud83c\udf89 Ce cours, c'est comme une plong\u00e9e profonde dans le monde des d\u00e9chets plastiques dans les oc\u00e9ans \ud83c\udf0a\ud83d\udeaf et les efforts mondiaux pour s'attaquer \u00e0 ce probl\u00e8me. Plonge avec nous pour d\u00e9couvrir l'impact des d\u00e9chets plastiques marins et les outils internationaux qui font face \u00e0 ce d\u00e9fi. Avec la pollution plastique qui explose, c'est pas seulement une menace environnementale, mais aussi sociale et \u00e9conomique \u00e0 l'\u00e9chelle mondiale. \ud83c\udf0e\ud83d\udc94 On mettra en lumi\u00e8re la Convention de B\u00e2le, le super-h\u00e9ros des instruments juridiques mondiaux ciblant sp\u00e9cifiquement les d\u00e9chets plastiques. \ud83e\uddb8\u200d\u2642\ufe0f Tout au long du cours, on se concentrera sur trois points principaux : 1) pr\u00e9venir et r\u00e9duire au minimum la production de d\u00e9chets plastiques, 2) la gestion \u00e9cologiquement rationnelle des d\u00e9chets plastiques, et 3) surveiller les mouvements transfrontaliers des d\u00e9chets plastiques. \ud83c\udf31\u267b\ufe0f Pr\u00eat(e) \u00e0 faire des vagues dans la lutte contre la pollution plastique ? \ud83c\udf0a\ud83c\udf0d Allons-y ! \ud83d\udcaa\ud83d\ude80",
          "link": "https://unccelearn.org/course/view.php?id=164&page=overview",
          "image": "../../../assets/images/yoma/Yoma .webp"
      }
  ],
  "Leadership": [
      {
          "name": "Women in Tech \ud83d\udcbb\ud83d\udc69\u200d\ud83d\udcbc\ud83d\udc69\u200d\ud83d\udcbb\ud83c\udf1f",
          "desc": "The tech world has been a bit of a boys' club for too long, but it's time for a major shake-up! Did you know that only 24 percent of IT jobs worldwide are held by women? Let's change that stat, shall we? \ud83d\udcaa In this quick and punchy learning nugget, get ready to meet some seriously cool women who are totally slaying it in the tech industry. By the time you're done, you'll see that the tech world is bursting with opportunities for all! This mind-blowing online course is brought to you by the awesome folks at \"atingi.\" \ud83c\udf0d\ud83d\udca1 Here's your chance to jump into the e-learning revolution: Hit that \"Go to Opportunity\" button and get zapped straight to atingi. \ud83d\ude80 Sign up using your email and a password. Look out for that verification email\u2014 it's on its way! \ud83d\udcdd Once you're in, get ready to explore all the amazing atingi opportunities. Psst, they're all totally free! \ud83c\udd93 Crush the course, snag that sweet atingi certificate, and show it off on Yoma with a proud shout-out \"I'm a tech superstar!\" \ud83d\udcaa\ud83c\udf93 Score some awesome Zlto rewards for all your hard work, wherever you can. \ud83d\udcb0\ud83d\udcab Let's make the tech world more diverse and exciting with atingi! Don't miss this chance to be inspired and carve your path in the tech universe! \ud83c\udf1f",
          "link": "https://online.atingi.org/enrol/index.php?id=943 ",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "A Career in Hospitality \ud83c\udf7d\ufe0f\ud83c\udf1f",
          "desc": "\ud83c\udfe8\u2728 Explore the dynamic world of hospitality! This self-paced course uncovers exciting job opportunities in the industry. Discover the essential skills for different roles, develop your competencies, and uncover alternative applications. Engage with real stories of young professionals and tackle practical tasks. Enjoy the journey! \ud83d\ude04\ud83c\udf1f Part of the \"Introduction into Professions in the Tourism Sector\" pathway: Tourism, Hospitality, Tour Guiding, and Tourism Entrepreneurship. \ud83c\udf0d\ud83d\udcbc Choose the full series or individual courses! \ud83d\ude80 Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 1. Click \"Go to Opportunity\" to start the course. \u2705 2. Register for free access to all atingi courses. \ud83c\udd93 3. Complete the course, earn your atingi certificate, and share your accomplishment on social media! \ud83c\udf93\ud83d\udcf2 4. Redeem Zlto rewards for your achievements. \ud83d\udcaa\ud83d\udcb0 Don't miss out on this exciting chance to explore and thrive! Let's get started! \ud83c\udf1f\ud83d\ude80",
          "link": "https://online.atingi.org/enrol/index.php?id=4177",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "A Career in Tourism \u2708\ufe0f\ud83c\udf0d",
          "desc": "\ud83c\udf0d\ud83c\udf1f Explore the exciting world of tourism! This self-paced course unveils the job opportunities and skills needed in the industry. Discover different roles, develop key competencies, and apply them beyond tourism. Solve practical tasks and be inspired by real stories of young people. Enjoy the journey! \ud83d\ude04\u2728 Part of the \"Introduction into Professions in the Tourism Sector\" pathway: Tourism, Hospitality, Tour Guiding, and Tourism Entrepreneurship. \ud83c\udf0d\ud83d\udcbc Complete the full series or take individual courses! \ud83d\ude80 Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Click \"Go to Opportunity\" to join the course. \u2705 Register for free access to all atingi courses. \ud83c\udd93 Complete the course, earn your atingi certificate, and share your achievement on social media! \ud83c\udf93\ud83d\udcf2 Redeem Zlto rewards for your accomplishments. \ud83d\udcaa\ud83d\udcb0 Don't miss out on this exciting opportunity to explore and grow! Let's get started! \ud83c\udf1f\ud83d\ude80",
          "link": "https://online.atingi.org/enrol/index.php?id=4236",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd04\ud83c\udf1f How to Manage Change \ud83d\ude80\ud83c\udf31",
          "desc": "\ud83d\udcda\ud83d\udd04 Master the art of giving feedback! This course covers: \u2022 Providing effective feedback to others. \ud83d\udde3\ufe0f\ud83e\udd1d \u2022 Two tricks to prevent feedback conflicts. \u270c\ufe0f\ud83d\udd25 \u2022 Understanding the two-way nature of feedback. \ud83d\udd04\ud83c\udfaf This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=157",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcc6\ud83d\udcca General Project Planning \ud83d\ude80\ud83d\udd27",
          "desc": "\ud83c\udf1f\ud83d\udcda Enhance your soft skills for career success! This self-study course covers: \u2022 Communication and leadership skills. \ud83d\udcac\ud83d\udc65 \u2022 Time and stress management. \u23f0\ud83d\ude13 \u2022 Conflict resolution. \ud83e\udd1d\u2696\ufe0f This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=108",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfe2\ud83d\udd27 Building a Resilient Business: Standards Implementation for Boosting Micro, Small and Medium Enterprises' Resilience \ud83d\ude80\ud83d\udcaa",
          "desc": "\ud83c\udf0d\ud83d\udcda Explore the impact of international standards on MSMEs! This self-study course emphasizes: \u2022 Enhancing business resilience through international standards. \ud83c\udfe2\ud83d\udcaa \u2022 User-friendly guide to quality infrastructure and disaster preparedness. \ud83d\udcca\ud83c\udf2a\ufe0f \u2022 Tips on selecting the right standards for your business. \u2705\ud83d\udca1 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=846",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcca\ud83d\udd0d Evaluation, Implementation & Monitoring \ud83d\ude80\ud83d\udd27",
          "desc": " \ud83d\udc69\u200d\ud83d\udcbc\ud83d\ude80 Join this course for young professionals and aspiring entrepreneurs! Topics include: \u2022 Setting up and managing an effective project team \ud83e\udd1d\u2728 \u2022 Monitoring a project's progress \ud83d\udcca\ud83d\udd0d \u2022 Evaluating a project's success \ud83c\udfaf\ud83d\udcc8 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=239",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udd25\ud83e\udd1d Action Network \ud83c\udf10\ud83d\ude80",
          "desc": "Unlock your networking potential! Join our course to boost personalized learning, skill development, and career growth through meaningful connections. Create a personal vision \u2728, identify areas for growth \ud83d\udcc8, and build your action network \ud83e\udd1d. Together, let's turn your ideas into actions and create mutually beneficial opportunities for growth. Get ready to network your way to success! \ud83c\udf1f\ud83d\udcbc\ud83d\udd25\ud83d\udcaa Join the e-learning revolution with atingi! \ud83c\udf0d\ud83d\udca1 Follow these simple steps: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=170",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\uddfa\ufe0f\ud83d\ude80 Roadmap to Success \ud83c\udf1f\ud83c\udfc6",
          "desc": "\ud83c\udf1f Dreams become reality when you take action! \ud83d\udcaa\u2728 By giving young people a tool to define and plan for their big visions, we empower them to shape their future. \ud83c\udfaf Taking just one small step toward a larger goal helps make that future a reality. \ud83d\ude80 Rooted in backcasting, this technique turns big dreams into tangible steps. Let us help you shift your mindset from \"one day\" to day one! \ud83c\udf08\ud83d\udcc6 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=172",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83e\udd1d Engaging Stakeholders for Success \ud83d\ude80",
          "desc": "Unlock the Power of Stakeholder Mastery \ud83c\udf1f Gain real-world interpersonal skills, prioritize effectively, and engage like a pro. Equip for a successful career! \ud83c\udf1f Perks: Essential for a lasting professional journey. \ud83c\udfaf Hone Universal Skills: Master transferable stakeholder abilities across industries. \u26a0\ufe0f Avoid Common Pitfalls: Get educated on stakeholder pitfalls. \ud83d\udd27 Support students in practicing stakeholder interview techniques. Let's get you started with these simple steps: 1\ufe0f\u20e3 Click on \"\"Go to Opportunity\"\" and be redirected to the exciting CISCO course portal. 2\ufe0f\u20e3 On the right side of the website, enter your credentials: First Name, Last Name, and Email. Then hit \"\"Next Account Details\"\" to provide more personal information! 3\ufe0f\u20e3 Check your Email Inbox for the Successful Enrollment Notification using the provided email address. 4\ufe0f\u20e3 Click on the Course Portal link and assign a password and start your learning journey! \ud83c\udf89 Congratulations! You now have access to the course. Get ready to dive into the class activities! \ud83d\ude80\ud83c\udf1f Your learning journey is secure and ready to begin! Happy exploring! \ud83d\ude0a\ud83d\udd10 \"",
          "link": "https://skillsforall.com/course/engaging-stakeholders?courseLang=en-US&instance_id=f0d92f04-8f68-4740-a1ca-7583ee30f2bd",
          "image": "../../../assets/images/yoma/Cisco.webp"
      },
      {
          "name": "Getting Started with Microsoft 365",
          "desc": "\ud83c\udf1f Ready to boost your skills? UNICEF and the Ministry of Education are teaming up with Microsoft for something big! \ud83d\ude80\ud83c\udf93 Introducing P2E and Nigeria Learning Passport \u2013 your free pass to job-related skills if you're 15-24. \ud83d\udcbc\ud83c\udf08 Goal: Equip 50,000 young talents in Nigeria's 4 states by 2025. Let's own that transition from school to work! \ud83d\udcaa\ud83d\udc69\u200d\ud83d\udcbc\ud83d\udc68\u200d\ud83d\udcbc Feeling lost in the digital world? No worries! \ud83c\udf10\ud83d\udca5 Microsoft 365 is your productivity hack. Join our awesome course led by Courtney Hodge. \ud83d\ude4c\ud83d\udc69\u200d\ud83d\udcbb Master Word, Excel, OneNote, Outlook, and PowerPoint. \ud83d\udcdd\ud83d\udcca\ud83d\udc8c\ud83d\udce3 Become a digital pro and get things done like a champ. \ud83c\udfc6\ud83c\udf89 Ready for action? Here's your guide to jump in: \ud83d\udc63 How to Register on NLP (Nigeria Learning Passport) \ud83d\udc63 1a. Go to https://nigeria.learningpassport.org and hit \"Get Started.\" Or... 1b. Grab the \"Nigeria Learning Passport\" app from Google Play Store! \ud83d\udcf2 2a. Click \"Sign Up Now\" and use the email you've set up for the student (do this for each student). 2b. Hit \"Send Code.\" Check your email for a secret code. Pop that code into the NLP platform, hit \"Verify,\" and then set a unique password (remember it, folks!). Fill in your profile deets. \ud83d\udcdd Oh, and the best part? You can dive into this course without worrying about data usage! \ud83d\udce1 Use an Airtel data-enabled SIM and get 300MB daily for free learning. Let's do this! \ud83d\ude80\ud83d\udcda\ud83d\udd25",
          "link": "https://learningpassport.b2clogin.com/learningpassport.onmicrosoft.com/b2c_1_nigeria_signup_signin/oauth2/v2.0/authorize?client_id=7cb7d531-ce5e-479f-a2ba-d8a56f04161e&redirect_uri=https://nigeria.learningpassport.org/signin-b2c&response_type=code&scope=openid%20profile%207cb7d531-ce5e-479f-a2ba-d8a56f04161e%20offline_access&code_challenge=BFfadHWqMOVxb2tjxJW6CcNgfHTf_NAHI6UOV1W5dHs&code_challenge_method=S256&response_mode=form_post&nonce=638284581819138875.YTc4NWFjNmItYTRiZS00ODJhLThjMWQtMDEyMzc3NGEyNjQ3YmEwNzRiM2UtMGIwYy00N2FhLTg0MGQtNWQyY2VkMTgwMTA5&client_info=1&x-client-brkrver=IDWeb.1.3.0.0&state=CfDJ8Ew275PhYxJHkkUb7nPZEBPM-DrpQKGOYeCSVPdL6uLEkSbKfL0sEdSm3J9uaoxBX2lXRsv_QmISBJUpGe5Qmduj6ccgleFUTSaPypMOfnj_hdR1gVmcM-Z0xZ2S0u_Tm2sjldCYq0C681Vsns5heoG7LxJUjqoM-vZ44AyPwheclKcDuFp9iZk0hY7_7XS_TqNMOuERpvYOrLWdkpRWU_Ok1DSu4Tb-dyvFMaPE6B95bodJDsZV5Cj57qjx8S2w3hbvR6bcoZ5mGY0LgyD_rWZ8gugJ6jKnAODqJ4fy3Nsd-LnDvM1kGSIsnDLqOag34AmKQ3lGJbLtY6sMijMs_oPhXypmJYrEB23gNCof0KmrYtSk-6nZB_OeUjhwGpN4MVWI1PkscAMvfaoIKgxEohcDLAU5gVoMNsWRsKxYf5DEbORD5t3CTzBA2wrK0bxjDSx6twgS4jAn2qoPJGul2_Kx70_zJ8TxQS_x7K_9KSMG_PTGIslbSs62X9IrjMrKdTwn9MNSCzW5wqQz7_JSSQGKeGIIIR39VOs3nnol6SLIpEEZRoxNXDb-6GRWfOS1F2W3Hho4s_HPYM6iE_z2poU&x-client-SKU=ID_NET6_0&x-client-ver=6.21.0.0",
          "image": "../../../assets/images/yoma/GenU.webp"
      },
      {
          "name": "Google.org - ACN - Project Management",
          "desc": "Get started in the in-demand field of project management with a Professional Certificate from Google. Learn how to manage projects using traditional and agile methods, create project documentation, and develop strategic communication skills. Learn the skills you need to start a career as a: Project Manager, Project Coordinator, Project Assistant, Operations Manager, Operations Associate, Program Manager, Scrum Master, Project Associate, Project Analyst, Project Administrator, Technical Project Manager \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      }
  ],
  "Mindfulness": [
      {
          "name": "\ud83d\udcad\u2764\ufe0f Mental Health and Well-Being \ud83c\udf1f\ud83d\ude0c",
          "desc": "\ud83c\udf08\ud83d\udcaa Get mentally fit with our self-paced course on mental health and well-being! \ud83d\udcda\u2728 Dive into topics like stress, anxiety, depression, mindfulness, dealing with bullying, communication skills, self-esteem, and more! \ud83e\udde0\u26a1\ufe0f Complete it in just 1 hour and earn a well-deserved certificate \ud83c\udfc6\ud83c\udf93. Plus, we've got a special well-being calendar to boost your happiness! \ud83d\uddd3\ufe0f\ud83d\udc96 Let's embark on this empowering journey together! \ud83d\ude80\ud83c\udf1f This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2454",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udf00\ud83d\ude0c How to Let Things Go \ud83c\udf1f\ud83c\udf31",
          "desc": "\ud83c\udf1f\ud83d\udcaa Let go and embrace positivity! This course covers: \u2022 Achieving emotional balance amidst setbacks. \u2696\ufe0f\ud83d\ude14 \u2022 Understanding the benefits of releasing grudges and negative feelings. \ud83c\udf08\ud83d\ude4f \u2022 Feeling empowered to make positive changes in your life. \u2728\ud83d\udd25 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=117",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Purpose Statement \ud83c\udfaf\ud83d\udcdc",
          "desc": "Discover your purpose and unlock your limitless potential! Join our course to develop a personal transformative purpose statement that guides your decision-making and partnership building. Expand your future options, identify growth opportunities, and stay true to what truly matters. Let's embark on this transformative journey together! \ud83c\udf1f\ud83d\ude80\ud83d\udcaa This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=171",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Importance of lifelong learning\ud83c\udf0e",
          "desc": "It is important to learn continuously throughout life. Learn why along with some tips to improve self-confidence.\ud83d\udcaa\ud83e\udde0 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "\ud83d\udeab\ud83e\udde0 How to Get Rid of Self-Limiting Beliefs \ud83c\udf1f\ud83d\udcaa",
          "desc": "\ud83e\udde0\ud83d\udeab Break free from self-limiting beliefs! This course includes: \u2022 Understanding the impact of self-limiting beliefs. \ud83e\uddd8\u200d\u2640\ufe0f\ud83d\udd12 \u2022 Identifying and challenging your self-limiting beliefs. \ud83d\udd75\ufe0f\u200d\u2640\ufe0f\ud83d\udd0d \u2022 Practical tips to overcome barriers and unleash your potential. \ud83d\ude80\ud83d\udcaa This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out! \"",
          "link": "https://online.atingi.org/enrol/index.php?id=169",
          "image": "../../../assets/images/yoma/Atingi.webp"
      }
  ],
  "Self Direction": [
      {
          "name": "\ud83d\udcda How to be a Successful Online Learner \ud83d\udcbb",
          "desc": "\ud83d\udda5\ufe0f\ud83d\udcda Ready to unlock the secrets of online learning and level up your educational game? Let's turn your learning journey into a turbocharged adventure! \ud83d\udca1\ud83c\udf93 In this mind-blowing microlearning course, we'll show you how to make online learning a skill in itself, one that can skyrocket your future learning outcomes both online and offline. \ud83d\ude80\ud83e\uddd0 By the end of this course, you'll be equipped with the following super skills: \ud83e\udd13\ud83d\udcad A deep understanding of why reflecting on your learning behavior is a total game-changer \ud83e\udde0\ud83d\udcc8 Discovering epic learning strategies that will set you up for success \ud83d\ude80\ud83e\uddd0 Get ready to boost your learning skills and embark on an educational journey like never before! \ud83d\udcbb\ud83d\udcd6\ud83c\udf1f Join the vibrant community at \"atingi,\" the global e-learning platform that's changing the game! \ud83c\udf0d\ud83d\udca1 Here's your ticket to the e-learning revolution: Click that \"Go to Opportunity\" button to teleport straight to atingi. \ud83d\ude80 Sign up using your email and a password. Keep an eye out for that verification email\u2014it's on its way! \ud83d\udcdd Dive headfirst into all the fantastic atingi opportunities once you're in! Psst, did we mention they're all completely free? \ud83c\udd93 Master the course, grab that sparkling atingi certificate, and showcase it proudly on Yoma with a shout-out \"I'm a learning legend!\" \ud83d\udcaa\ud83c\udf93 Cash in your well-deserved Zlto rewards for some awesome goodies wherever you can. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out on this chance to supercharge your learning skills and rock your educational world! \ud83d\ude80",
          "link": "https://online.atingi.org/enrol/index.php?id=3910",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Microlearning nugget: Know your device \ud83d\udcf1\ud83d\udcbb",
          "desc": "\ud83d\udcf1 Get to know your device! \ud83d\ude80 This self-paced course introduces you to the exciting world of Information and Communications Technology (ICT). Explore hardware, components, functions, and maintenance. By course end, understand ICT, hardware differences, and popular devices. \ud83d\udcbb\ud83d\udd27 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2718",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "How to work efficiently online \ud83d\udcbb\ud83d\ude80",
          "desc": "\ud83d\udcda\ud83d\udcbb\ud83c\udf1f Work smarter online! In this self-paced course, you'll explore strategies for efficient digital work, tackle online issues, and learn tips to protect your privacy. By course end, you'll master time management and discover collaborative applications. We'll also cover communication tools and professionalism. \u23f0\ud83d\udd12\ud83d\udcac This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2819",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Microlearning nugget: Use a search engine \ud83d\udd0d\ud83d\udcbb",
          "desc": "\ud83d\udd0d\ud83c\udf10 Ready to master the art of searching? In this course, you'll go beyond just Googling! Learn how to use search engines properly and find valuable resources. Discover effective search strategies to enhance your results. \ud83d\udcda\ud83d\udcbb\u2728 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2104",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Digital Safety and Security \ud83d\udee1\ufe0f\ud83d\udd12",
          "desc": "\ud83c\udf10\ud83d\udcbb\ud83d\udd12 Embrace the digital world! It offers endless advantages to our lives, work, and studies. But be aware of the risks too. \ud83d\udeab\u26a0\ufe0f In this course, we'll explore data protection and privacy. Learn to keep your data safe, secure, and private. Discover the importance of safeguarding your information and recognize digital threats. \ud83d\udee1\ufe0f\ud83d\udd10\ud83d\udd12 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" to redirect to atingi. \ud83d\ude80 2. Register with your email and password. Verify your email.\ud83d\udcdd 3.Explore all free atingi courses. \ud83c\udd93 4.Complete the course, earn your atingi certificate, and showcase on Yoma as \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for applicable achievements. \ud83d\udcb0\ud83d\udcab Learn and earn effortlessly with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2754",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Navigate the internet \ud83c\udf10\ud83e\udded",
          "desc": "\u26a0\ufe0f\ud83c\udf10 Stay alert online! Develop essential skills for navigating the digital world. Explore key internet drivers like big data and user interfaces. Understand internet operations, data collection, and sharing. Discover what makes a good user interface, learn about attention-seeking strategies, and understand filter bubbles. \ud83d\udcf2\ud83d\udd0d\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: Click \"Go to Opportunity\" to join atingi. \ud83d\ude80 Register with your email and password. Verify your email. \ud83d\udcdd Explore all free atingi courses. \ud83c\udd93 Complete the course, earn your atingi certificate, and showcase on Yoma as \"I have completed this\". \ud83d\udcaa\ud83c\udf93 Redeem Zlto rewards for achievements. \ud83d\udcb0\ud83d\udcab Don't miss out on learning and earning with atingi! Let's get started!",
          "link": "https://online.atingi.org/enrol/index.php?id=3015",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83d\udcda Basic Computer Applications \ud83c\udf1f\ud83d\udd27",
          "desc": "\ud83d\udcbb\ud83d\udcda Get tech-savvy! Basic Computer Applications is a self-paced course designed to introduce you to essential software skills. Discover the world of word processing, spreadsheet usage, and presentation creation. \ud83d\udd8b\ufe0f\ud83d\udcca\ud83c\udfa5 Join the course now and unlock your digital potential! \ud83c\udf1f\ud83d\udd13 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=2689",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "How to: Time and Resource Planning \u23f1\ufe0f\ud83d\udcc6",
          "desc": "\ud83d\udcda\ud83d\udcc6 Get ready to dive into the world of project management with this self-study course! \ud83d\ude80\ud83d\udcc8 You'll learn all about planning and implementing projects, including different project phases, timing, duration, and the resources you'll need (or already have) to make it happen \ud83d\udca1\ud83d\uddd3\ufe0f. Get ready to level up your project management skills and conquer any challenge that comes your way! \ud83d\udcaa\ud83c\udf1f This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"I have completed this\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=212",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udc69\u200d\ud83d\udcbb\ud83c\udf1f A Day as a Platform Worker: \ud83d\ude80\ud83d\udcbc",
          "desc": "Get ready for an immersive microlearning experience! \ud83d\ude96\ud83d\udcda Step into the shoes of Lubanzi, a ride-hailing driver in the gig economy, and explore the exciting world of the ride-hailing sector. In this interactive game, you'll make decisions to earn enough money while juggling caring responsibilities and minimizing risks. Get a firsthand experience of the challenges and rewards of being a rideshare driver. Let's dive into this engaging learning journey and unlock the secrets of the gig economy! \ud83c\udf1f\ud83d\ude98\ud83d\udcbc\ud83c\udfae This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your Atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/course/view.php?id=1371",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcbb\ud83d\udcc8 Computing Skills for Business \ud83d\ude80\ud83d\udcca",
          "desc": "\ud83c\udf0d\ud83d\udcda Explore the impact of international standards on MSMEs! This self-study course emphasizes: \u2022 Enhancing business resilience through international standards. \ud83c\udfe2\ud83d\udcaa \u2022 User-friendly guide to quality infrastructure and disaster preparedness. \ud83d\udcca\ud83c\udf2a\ufe0f \u2022 Tips on selecting the right standards for your business. \u2705\ud83d\udca1 This online course is offered by the global e-learning platform atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!\"",
          "link": "https://online.atingi.org/enrol/index.php?id=1573",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83c\udfe0\ud83d\udcbb T\u00e9l\u00e9travail et s\u00e9curit\u00e9 \ud83d\udd12\u2728",
          "desc": "Pr\u00eats \u00e0 devenir des pros de la cybers\u00e9curit\u00e9 ? \ud83d\udee1\ufe0f\ud83d\udcbb Notre formation en ligne t'apprend tout sur la protection num\u00e9rique sur le lieu de travail priv\u00e9. Que tu sois employ\u00e9(e), travailleur ind\u00e9pendant(e) ou \u00e9tudiant(e), cette formation est faite pour toi ! D\u00e9couvre les risques du home-office, les tactiques des cybercriminels et apprends comment renforcer ta s\u00e9curit\u00e9 en ligne. Rejoins-nous pour ma\u00eetriser la cybers\u00e9curit\u00e9 et garder notre monde virtuel en toute s\u00e9curit\u00e9 ! \ud83d\ude80\ud83d\udcaa\ud83c\udf10 Rejoignez la r\u00e9volution de l'apprentissage en ligne avec atingi ! \ud83c\udf0d\ud83d\udca1 Suivez ces \u00e9tapes simples : 1. Cliquez sur le bouton \"Acc\u00e9der \u00e0 l'opportunit\u00e9\" pour \u00eatre redirig\u00e9 vers atingi. \ud83d\ude80 2. Inscrivez-vous sur atingi en utilisant votre adresse e-mail et un mot de passe. Vous recevrez un e-mail de v\u00e9rification. \ud83d\udcdd 3. Explorez toutes les opportunit\u00e9s disponibles sur atingi une fois que vous \u00eates connect\u00e9 ! Tous les cours sont gratuits. \ud83c\udd93 4. R\u00e9ussissez le cours, obtenez votre certificat atingi, et montrez-le sur Yoma \"T\u00e9l\u00e9chargez votre certificat de r\u00e9ussite\". \ud83d\udcaa\ud83c\udf93 5. \u00c9changez les r\u00e9compenses Zlto pour vos r\u00e9alisations partout o\u00f9 cela est applicable. \ud83d\udcb0\ud83d\udcab Faisons de l'apprentissage et de la r\u00e9ussite un jeu d'enfant avec atingi ! Ne manquez pas cette opportunit\u00e9 !",
          "link": "https://online.atingi.org/enrol/index.php?id=205",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Purpose Statement \ud83c\udfaf\ud83d\udcdc",
          "desc": "Discover your purpose and unlock your limitless potential! Join our course to develop a personal transformative purpose statement that guides your decision-making and partnership building. Expand your future options, identify growth opportunities, and stay true to what truly matters. Let's embark on this transformative journey together! \ud83c\udf1f\ud83d\ude80\ud83d\udcaa This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=171",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\uddfa\ufe0f\ud83d\ude80 Roadmap to Success \ud83c\udf1f\ud83c\udfc6",
          "desc": "\ud83c\udf1f Dreams become reality when you take action! \ud83d\udcaa\u2728 By giving young people a tool to define and plan for their big visions, we empower them to shape their future. \ud83c\udfaf Taking just one small step toward a larger goal helps make that future a reality. \ud83d\ude80 Rooted in backcasting, this technique turns big dreams into tangible steps. Let us help you shift your mindset from \"one day\" to day one! \ud83c\udf08\ud83d\udcc6 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=172",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "\ud83d\udcb0\ud83d\udcc8 Introduction to Financial Planning \ud83c\udf31\ud83c\udfe6",
          "desc": "Master the art of financial planning in our self-study course! \ud83d\udcbc\ud83d\udcb0 Ideal for young professionals and aspiring entrepreneurs, this course explores key aspects of financial planning for companies and organizations. By the end, you'll be equipped with essential tools and knowledge to confidently manage finances and make informed decisions. \ud83d\udcca\ud83c\udf93 Join us on this exciting journey to financial success! \ud83d\ude80\ud83d\udca1 This online course is offered by the global e-learning platform \"atingi\"\ud83c\udf0d\ud83d\udca1 Follow these steps to join the e-learning revolution now: 1. Click \"Go to Opportunity\" button to be redirected to atingi. \ud83d\ude80 2. Register on atingi using your email address and a password. You will receive a verification email.\ud83d\udcdd 3. Explore all atingi opportunities once you're in! All courses are free. \ud83c\udd93 4. Nail the course, earn your atingi certificate, and show it off on Yoma \"Upload your completion certificate\". \ud83d\udcaa\ud83c\udf93 5. Redeem Zlto rewards for your achievements wherever applicable. \ud83d\udcb0\ud83d\udcab Let's make learning and earning a breeze with atingi! Don't miss out!",
          "link": "https://online.atingi.org/enrol/index.php?id=102",
          "image": "../../../assets/images/yoma/Atingi.webp"
      },
      {
          "name": "Introduction to Web Development\ud83c\udf10",
          "desc": "Get an introduction to web development, including the differences between front-end and back-end development and the daily life of a web developer. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Cloud Computing\ud83d\udcda\ud83d\udcbb\ud83c\udf1f",
          "desc": "Learn what Cloud is and why it is one of the most innovative technologies of our time. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Working Efficiently\ud83c\udf0d\ud83c\udf1f",
          "desc": "Being efficient improves your work and saves time. Learn about keyboard shortcuts, bookmarks, and having an organized workspace. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "How to Master Mistakes\ud83d\ude80\ud83c\udf93",
          "desc": "Mistakes are a part of life, so learn some tips on how to deal with the consequences and how to rectify it. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Importance of lifelong learning\ud83c\udf0e",
          "desc": "It is important to learn continuously throughout life. Learn why along with some tips to improve self-confidence.\ud83d\udcaa\ud83e\udde0 Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d Step 3: Course conquered? Upload your certificate on Yoma, score Zlto rewards! Boom!\ud83d\udca5 Get ready to celebrate. Let's go! \ud83c\udf89",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Introduction to Software Development\ud83d\udcbb\ud83d\udcda",
          "desc": "Explore the basics of software development, including the tools, programs and languages used. Step 1: Hit \"Go to Opportunity\" to be redirected to the Skills to Succeed Academy\ud83c\udf10\ud83d\udcbb Step 2: Click on \"Sign in\" and then \"Create an Account\", enter your personal information and remember your email address and password. Use access code: 02Yoma if you're new to S2S Academy \ud83d\ude80 You should now receive an email to that same address to verify your profile. Click on the link and answer a few questions about your interest. Logged in? Time to search for your dream course! \ud83d\udcda\ud83d\udd0d",
          "link": "https://s2sacademy.co.za/login/",
          "image": "../../../assets/images/yoma/S2S.webp"
      },
      {
          "name": "Umuzi Web Development Learnership",
          "desc": "Transform your passion into a profession by joining us at Umuzi! \ud83d\ude80 If successful, you could secure an invite to our immersive full-time 12-month Web-Development Learnership. \ud83d\udcbb\u2728 Plus, enjoy a stipend to support your journey! Requirements: \ud83c\uddff\ud83c\udde6 South African citizens \ud83d\udc69\u200d\ud83c\udf93 Ages 18-34 \ud83d\udcda Completed Grade 12 (or NQF 4 equivalent) \ud83d\udccd Based in/around Cape Town We're especially keen on learners interested in Full-Stack Web Development. \ud83c\udf10\ud83d\udd27 Apply now and kickstart your career with Umuzi! \ud83d\ude80\ud83d\udc69\u200d\ud83d\udcbb #WebDevelopment #UmuziOpportunity",
          "link": "https://www.umuzi.org/apply-now",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Front-End Developer Certificate",
          "desc": "\u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Gaming 4 Girls - African Coding Network",
          "desc": "\ud83c\udf1f\ud83d\udc69\u200d\ud83d\udcbb Exciting news, girls! \ud83c\udf1f\ud83d\udc69\u200d\ud83d\udcbb Umuzi & African Coding Network teamed up with UNICEF \ud83e\udd1d\ud83c\uddff\ud83c\udde6 to launch the Digital Skills 4 Girls Programme! \ud83d\ude80\ud83d\udcbb Unlock your coding & digital talents early on! \ud83c\udf08\ud83d\udcf1\ud83d\udcaa Don't miss this awesome opportunity, sign up now! \ud83c\udf1f\ud83d\ude80 No experience needed! \ud83c\udf1f\ud83d\ude80 Just bring your passion for \ud83d\udcbb coding, \ud83d\udcf1 digital marketing, or both! \ud83e\udd29\ud83d\udd25 Age: 14-19 years old \ud83d\udcc5\ud83d\udc67\ud83d\udc66 \ud83c\uddff\ud83c\udde6 South African citizens only \ud83c\uddff\ud83c\udde6 Dreaming of a digital career? \ud83c\udf08\ud83d\udcbc Be motivated, dedicated, and ready to rock! \ud83d\udcaa\ud83c\udf93 Apply now! Let's make it happen! \ud83d\udcdd\ud83d\ude80 #DigitalDreams \ud83c\udf20\ud83d\udcbb",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shroZTXQA4iia3VNi",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta React Native",
          "desc": "Learn how to create apps for \ud83d\udcf2 Android and \ud83d\uddb1\ufe0f iOS devices. learn to build and write code \ud83d\udc69\ud83c\udffc\u200d\ud83d\udcbb for apps that are hosted on mobile devices powered by multiple operating systems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta iOS developer",
          "desc": "This Professional Certificate will teach you how to build applications for iOS devices and start a new career as an iOS Developer. By the end of this program, you\u2019ll be able to create and run a mobile app powered by iOS operating systems. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      },
      {
          "name": "Meta Android Developer",
          "desc": "Have you ever wanted to build your own mobile app? This certificate will teach you the top Android programming languages and frameworks used for building apps like Facebook and Instagram, and how to create your own Android application. \u2328\ufe0f Click on Apply \u231b\ufe0fComplete the Aptitude Test- link below \ud83d\udd17 \ud83d\udcd9Participate in the course \ud83c\udf96\ufe0fSubmit your certificate upon completion of your course\ud83d\udd16",
          "link": "https://airtable.com/appPDdDtB8y4K5hJH/shrVfCwJOt3RX6W2R",
          "image": "../../../assets/images/yoma/ACN.webp"
      }
  ]
};
