import { Component, OnInit, Injectable, HostListener, ViewChild, Renderer2 } from "@angular/core";
import { CandidateLessonComponent } from "../candidate-lesson/candidate-lesson.component";
import { FormBuilder } from "@angular/forms";
import { CandidateLessonService } from "src/app/services/candidate-lesson.service";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { SeoService } from "src/app/services/seo.service";
import { ConfigsService } from "../../services/configs.service";
import { MentorService } from "src/app/services/mentor.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { LessonsService } from "src/app/services/lessons.service";
import { LoginPopupComponent } from "src/app/dialogs/login-popup/login-popup.component";
import { LocationStrategy } from "@angular/common";
import { environment } from "../../../environments/environment";
import { Track } from "ngx-audio-player";
import { OnPageHidden, OnPageVisible } from "angular-page-visibility";
import { OpenAiService } from "src/app/services/open-ai.service";
import { WhatsappLessonSubscriptionPopupComponent } from "src/app/dialogs/whatsapp-lesson-subscription-popup/whatsapp-lesson-subscription-popup.component";
import { MatTooltipModule } from "@angular/material";
import { MenuItem } from 'primeng/api';

@Component({
  selector: "app-candidate-lesson-mobile",
  templateUrl: "./candidate-lesson-mobile.component.html",
  styleUrls: ["./candidate-lesson-mobile.component.less"],
})
@Injectable({
  providedIn: "root",
})
export class CandidateLessonMobileComponent extends CandidateLessonComponent
  implements OnInit {
  mentorsUrl = environment.production ? "https://mentors.gleac.com" : "http://mentors.gleactest.com";

  @ViewChild('audio_player', { static: false })
  audioPlayer: any;

  isFirstClick: boolean = false;

  msaapPlaylist: Track[] = [
    {
      title: 'Audio One Title',
      link: 'https://gleac-assets.s3.us-east-2.amazonaws.com/audio/lesson-background.mp3',
      artist: 'Audio One Artist',
      duration: 186
    }
  ];

  finishPage: boolean = false;
  // whatsAppLessonSubscriptionPopup: MatDialogRef<WhatsappLessonSubscriptionPopupComponent>;
  issuccessPopup = false;
  success_popup = {
    display: false,
    message: "",
    buttonPositive: "",
    positiveButton: function () { },
  };
  currentPlayerStatus: boolean;

  constructor(
    configsService: ConfigsService,
    candidateLessonService: CandidateLessonService,
    sanitizer: DomSanitizer,
    route: ActivatedRoute,
    usersService: UsersService,
    router: Router,
    formBuilder: FormBuilder,
    protected dialog: MatDialog,
    meta: Meta,
    skillService: LessonsService,
    mentorService: MentorService,
    protected seo: SeoService,
    private location: LocationStrategy,
    openAiService: OpenAiService,
    private renderer: Renderer2,
    matToolTip: MatTooltipModule
  ) {
    super(
      configsService,
      candidateLessonService,
      sanitizer,
      route,
      usersService,
      mentorService,
      router,
      formBuilder,
      dialog,
      meta,
      skillService,
      seo,
      location,
      openAiService,
      matToolTip
    );
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }


  public getBasePlayer(): any {
    return this.audioPlayer.player.nativeElement;
  }

  public setPlayerVolume(value: number) {
    this.getBasePlayer().volume = value / 100
  }

  playSong() {
    this.audioPlayer.play();
    this.isSongPlaying = true;
    localStorage.setItem('muteAlways', '0');
  }

  pauseSong() {
    this.audioPlayer.player.nativeElement.pause();
    this.isSongPlaying = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.pauseSong();
  }

  @OnPageVisible()
  whenPageVisible(): void {
    if (this.currentPlayerStatus && !this.currentPlayerStatusVideo) {
      this.playSong();
    }
    this.currentPlayerStatus = false;
  }

  @OnPageHidden()
  whenPageHidden(): void {
    this.currentPlayerStatus = this.audioPlayer && this.audioPlayer.isPlaying;
    this.pauseSong();
  }

  // @HostListener('click')
  // onClick(btn) {
  //   if (!this.isFirstClick) {
  //     this.isFirstClick = true;
  //     this.audioPlayer.play();
  //     console.log(this.audioPlayer);
  //   }
  // }

  sendAccessKeysToMentorsUrl() {
    if (localStorage.getItem("GATk") && localStorage.getItem("GRTk")) {
      let mentorIframe = document.getElementById('MentorsFrame');
      if (mentorIframe == null)
        return;

      let iWindow = (<HTMLIFrameElement>mentorIframe).contentWindow;
      iWindow.postMessage({
        "accessToken": localStorage.getItem("GATk"),
        "refreshToken": localStorage.getItem('GRTk')
      }, '*');
    }
  }

  openChatBot() {
    this.chatButtonVisible = false;
    this.chatWindowVisible = true;
    this.disableScroll();
    this.scrollToBottom();
  }

  closeChatBot() {
    this.chatButtonVisible = true;
    this.chatWindowVisible = false;
    this.enableScroll();
  }

  private disableScroll() {
    const scrollY = window.scrollY;
    this.renderer.setStyle(document.body, 'position', 'fixed');
    this.renderer.setStyle(document.body, 'top', `-${scrollY}px`);
  }

  private enableScroll() {
    const scrollY = parseInt(document.body.style.top || '0', 10);
    this.renderer.removeStyle(document.body, 'position');
    this.renderer.removeStyle(document.body, 'top');
    window.scrollTo(0, Math.abs(scrollY));
  }

  // goToLoginSection() {
  //   localStorage.setItem("toLesson", this.lessonId);
  //   this.router.navigate(["/login"]);
  // }

  // forceFinishLesson(event) {
  //   this.candidateLesson.sections.forEach(section => section.isDone = true);
  //   this.userAnswer.clearValidators();
  //   this.timeRanOut = true
  //   this.finishLesson();
  // }

  toggleSong() {
    if (this.audioPlayer.isPlaying) {
      this.pauseSong();
    } else {
      this.playSong();
    }
  }
}
