import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';
import { ConfigsService } from '../../services/configs.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl()
  });
  showLoader: boolean = false;
  errors: string[] = [];
  notifs: string[] = [];
  userNotExist: boolean = false;

  constructor(private usersService: UsersService, private router: Router, private configsService: ConfigsService) { }

  ngOnInit() {
    this.initializeFormValidations();
  }

  initializeFormValidations() {
    this.forgotPasswordForm.controls["email"].setValidators([
      Validators.required,
      Validators.email
    ]);
  }

  sendForgotPasswordEmail(event) {
    this.forgotPasswordForm.markAllAsTouched();
    if (this.forgotPasswordForm.valid) {
      this.showLoader = true;
      let userEmail = this.forgotPasswordForm.controls["email"].value;
      this.usersService.sendForgotPasswordEmail(userEmail).subscribe(res => {
        this.showLoader = false;
        if (res.hasError == true) {
          this.userNotExist = true;
          setTimeout(() => {
            this.userNotExist = false;
          }, 3000);
        }
        else {
          this.notifs.push("Email has been sent to registered account.");
          setTimeout(() => {
            this.notifs = [];
          }, 3000);
        }
      }, err => {
        this.showLoader = false;
        this.notifs.push("Oooops, our system messed up.");
        setTimeout(() => {
          this.notifs = [];
        }, 3000);
      });
    }
  }

  goToLogin(event) {
    this.router.navigate(["login"]);
  }
}
