import {Injectable} from "@angular/core";
import {appSettings} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MentorBookingsService {
  baseUrl: string = appSettings.mentorsUrl;

  constructor(private http: HttpClient) {
  }

  getNftBookings(pageNumber, filterString, sortColumn = '', sortOrder = '', status: number = undefined) {
    let url = this.baseUrl + "MentorMeeting/communityDashboard/GetAllMentorBooking?PageNumber=" + pageNumber + "&PageSize=10";
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    if (status != undefined) {
      url += "&StatusFilter=" + status;
    }

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  exportNftBookings(pageNumber, filterString, sortColumn = '', sortOrder = '', status: number = undefined) {
    let timeDiff = new Date().getTimezoneOffset();
    let url = this.baseUrl + "MentorMeeting/communityDashboard/ExportAllMentorBooking?PageNumber=" + pageNumber + "&PageSize=10&timeDiff=" + timeDiff;
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    if (status) {
      url += "&StatusFilter=" + status;
    }

    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType: 'blob'
    })
  }

  updateNftBooking(data) {
    let url = this.baseUrl + "MentorMeeting/communityDashboard/UpdateMeetingRequest";
    return this.http.post<any>(url, data);
  }

  getBookings(pageNumber, filterString, sortColumn = '', sortOrder = '', status: number = undefined) {
    let url = this.baseUrl + "MentorBooking?PageNumber=" + pageNumber + "&PageSize=10";
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    if (status != undefined) {
      url += "&StatusFilter=" + status;
    }

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  exportBookings(pageNumber, filterString, sortColumn = '', sortOrder = '', status: number = undefined) {
    let timeDiff = new Date().getTimezoneOffset();
    let url = this.baseUrl + "MentorBooking/ExportAllMentorBooking?PageNumber=" + pageNumber + "&PageSize=10&timeDiff=" + timeDiff;
    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (sortColumn && sortOrder) {
      url += "&Sort.Column=" + sortColumn + "&Sort.Order=" + sortOrder;
    }

    if (status) {
      url += "&StatusFilter=" + status;
    }

    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType: 'blob'
    })
  }

  updateBooking(data) {
    let url = this.baseUrl + "MentorBooking/UpdateMentorBookingRequest";
    return this.http.post<any>(url, data);
  }

  sendRemainder(bookingId) {
    let url = this.baseUrl + "MentorBooking/SendReminder";
    return this.http.post<any>(url, {bookingId: bookingId});
  }
}
