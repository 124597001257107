import { Component, Input, OnChanges, OnInit, Pipe, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SectionElementType } from '../common/common.model';

@Pipe({ name: 'safe' })
@Component({
  selector: 'app-mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.less']
})
export class MobilePreviewComponent implements OnInit ,OnChanges {
  safeUrl: any;
  flag: number;

  constructor(private sanitizer: DomSanitizer) { }
  @Input("selectedSection") selectedSection;
  @Input("lessonData") lessonData ;
  previewLessonData :any;
  sectionElementType : SectionElementType;
  sectionIndex = 0;
  section: any;
  ngOnChanges(changes: SimpleChanges){
    if(changes.lessonData){
      console.log("changes", changes);
      this.changeSafeUrl(changes.lessonData.currentValue);
    }
    if(changes.selectedSection){
      this.sectionIndex =  changes.selectedSection.currentValue;

    }
  }
  ngOnInit() {
  }
  changeSafeUrl(lessonData){
    if(lessonData){
      let lesson = lessonData;
      this.flag =1;
      if(lessonData.sections){
        for(let i =0;i< lesson.sections.length;i++){
          let controls = lesson.sections[i].sectionControls;
          for(let j=0;j<controls.length;j++){
            if(controls[j].sectionElementType === 5){
              if(lesson.sections[i].
                sectionControls[j].sectionElementContent &&  !lesson.sections[i].
               sectionControls[j].sectionElementContent.changingThisBreaksApplicationSecurity ){
                 lesson.sections[i].
                 sectionControls[j].sectionElementContent= this.sanitize(controls[j].sectionElementContent)
               }

            }
          }
        }
      }

       this.previewLessonData = lesson;
    }


  }
  checkQuestionType(value){
    let flag=false;
    if(value !== 1 && value !==2 && value !== 3 && value !==4 && value !==5 && value !==6){
      flag = true;
    }
    return flag;
  }
   sanitize(url:any){
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
