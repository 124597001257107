import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { ConfigsService } from "src/app/services/configs.service";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.less"],
})
export class BreadcrumbComponent implements OnInit {
  @Input("breadCrums") breadcrumbList: any;

  constructor(
    protected router: Router,
    private active: ActivatedRoute,
    private location: Location,
    private config: ConfigsService
  ) {}

  ngOnInit() {}

  backToNavigate(index, url) {
    this.router.navigate([url]);
    this.breadcrumbList.splice(index, 1);
    this.config.setBreadcrumb(this.breadcrumbList);
  }
}
