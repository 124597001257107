import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LessonsService } from "src/app/services/lessons.service";
import { ConfigsService } from "src/app/services/configs.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.less"],
})
export class CartComponent implements OnInit {
  @Input("cartItems") cartSkills;
  cartCount = 0;
  queryParam: any = [];
  query;
  iserrorPopup: boolean = false;
  error_popup = {};
  constructor(
    private router: Router,
    private lessonsService: LessonsService,
    private commonService: CommonService,
    private configsService: ConfigsService
  ) {}

  ngOnInit() {
    //this.cartSkillsList();
    // console.log(this.cartSkills);
    this.cartCount = this.cartSkills.length;
  }

  addTocampaign() {
    this.lessonsService.setCampaignLessons(this.cartSkills);

    this.router.navigate(["/skill-add"]);
  }

  cartSkillsList(querytoPass?: any) {
    this.configsService.loading(true);
    let cartUrl = "LessonCart";
    // let apiLink = this.commonService.encodeQueryData(this.queryParam);
    this.commonService.getListAlone(cartUrl, querytoPass).subscribe(
      (data) => {
        this.cartSkills = data.result.list;
        this.cartCount = data.result.totalCount;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }
}
