import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class MobileGuardService implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
        var url = activatedRoute.url.join("/");
        if (environment.isMobile) {
            if (activatedRoute.queryParams != undefined) {
                this.router.navigate(["m/" + url], { queryParams: { preauth: activatedRoute.queryParams.preauth } });
            }
            else {
                this.router.navigate(["m/" + url]);
            }
        }
        return true
    }
}