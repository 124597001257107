import { Injectable } from "@angular/core";
import { appSettings } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Lesson, UserType } from "../components/common/common.model";
import { LessonSkill } from "../components/lessons/lessons.model";
import { Subject, Observable } from "rxjs";
import { UsersService } from './users.service';
import { CookieService } from 'ngx-cookie';
import { CandidatePeerAnswer, UserLessonRating } from '../dialogs/answer-popup/answer.model';
import { PostAnonymousDiscussionRequest, PostDiscussionRequest } from '../models/requests/post-comment-request';

@Injectable({
  providedIn: "root",
})
export class LessonsService {
  selectedCategory: LessonSkill;
  selectedCategoryObserver$: Subject<LessonSkill>;
  categories: LessonSkill[];
  selectedLesson: Lesson;
  cateogoriesChangedObserver$: Subject<LessonSkill[]>;
  cateogoriesObserver$: Subject<LessonSkill[]>;
  categoryChangedObserver$: Subject<string>;
  lessonChangedObserver$: Subject<string>;
  lessonSeletedObserver$: Subject<Lesson>;
  seletedLevelIndex: number = 0;
  cartCategory = new Subject<any>();
  campaignLessons: any;
  baseUrl: string = appSettings.lessonUrl;
  dashUrl: string = appSettings.dashboardUrl;

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    private cookie: CookieService
  ) {
    this.selectedCategoryObserver$ = new Subject();
    this.cateogoriesObserver$ = new Subject();
    this.categoryChangedObserver$ = new Subject();
    this.cateogoriesChangedObserver$ = new Subject();
    this.lessonChangedObserver$ = new Subject();
    this.lessonChangedObserver$.subscribe((lessonId) => {
      this.getLessonDetails(lessonId).subscribe((res) => {
        this.selectedLesson = res.result;
        this.getLessonCategoryDetailsById(
          this.selectedLesson.lessonCategoryId
        ).subscribe((category) => {
          this.selectedLesson.lessonCategory =
            category.result.lessonCategoryName;
          this.lessonSeletedObserver$.next(this.selectedLesson);
        });
      });
    });
    this.lessonSeletedObserver$ = new Subject();
    this.cateogoriesChangedObserver$.subscribe((data) => {
      this.getLessonCategories().subscribe((res) => {
        this.categories = res.result;
        if (this.usersService.currentUser && this.usersService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase()
          && this.usersService.currentUser.company != '00000000-0000-0000-0000-000000000000') {
          this.categories = this.categories.filter((x) => x.companyId == this.usersService.currentUser.company);
        } else if (this.usersService.currentUser.authRole.toLowerCase() === UserType.SA.toLowerCase()) {
          //show all
        } else {
          this.categories = [];
        }
        this.cateogoriesObserver$.next(this.categories);
      });
    });
    this.categoryChangedObserver$.subscribe((categoryId) => {
      this.getLessonCategoryDetailsById(categoryId).subscribe((res) => {
        this.selectedCategory = res.result;
        this.selectedCategory.lessons.forEach((x) => {
          x.isCustom = x.sections.some((y) => y.isCustom);
        });
        this.setupLevel();
        this.selectedCategoryObserver$.next(this.selectedCategory);
      });
    });
  }

  getLessonDetails(lessonId: string) {
    var url = this.baseUrl + "lessons/" + lessonId;
    return this.http.get<any>(url);
  }

  updateLessonDetails(lesson: Lesson) {
    var url = this.baseUrl + "lessons/" + lesson.lessonId;
    return this.http.put<any>(url, lesson);
  }

  updateChallenge(challenge: any) {
    var url = this.baseUrl + "challengeSkill/challenge/" + challenge.challengeId;
    return this.http.put<any>(url, challenge, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getLessonCategories() {
    var url = this.baseUrl + "lessoncategories";
    return this.http.get<any>(url);
  }

  getLesson(skillId) {
    var url = this.baseUrl + "Lessons/" + skillId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getChallenge(skillId) {
    var url = this.baseUrl + "ChallengeSkill/" + skillId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getImportLesson(reqParams) {
    var url = this.baseUrl + "Lessons/lessons-with-filter" + reqParams
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  postImportLesson(data) {
    var url = this.baseUrl + "Lessons/add-lessons";
    return this.http.post<any>(url, data, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getLessonCategoriesNewDashboard(
    pageNumber?: number,
    pageLimit?: number,
    status = "",
    skillName = "",
    sortColumn = "",
    sortOrder = "") {
    var url = this.baseUrl + "lessoncategories?PageNumber=" + pageNumber +
      "&PageSize=" + pageLimit + "&Status=" + status + "&SkillName=" + skillName + "&SortColumn=" + sortColumn + "&SortOrder=" + sortOrder;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  ExportLessonCategoriesNewDashboard(
    pageNumber?: number,
    pageLimit?: number,
    status = "",
    skillName = "") {
    var url = this.baseUrl + "lessoncategories/LessonExport?PageNumber=" + pageNumber +
      "&PageSize=" + pageLimit + "&Status=" + status + "&SkillName=" + skillName;
    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getMentorAnswer(lessonId) {
    var url = this.baseUrl + "Mentors/mentors-for-lessons/" + lessonId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getShortLinkForLesson(lessonId) {
    var url = this.baseUrl + "lessons/UpdateShlink/" + lessonId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getAppLinkForLesson(lessonCategoryId, levelId, lessonId) {
    var url = this.baseUrl + "lessons/UpdateApplink?lessonCategoryId=" + lessonCategoryId + "&levelId="
      + levelId + "&lessonId=" + lessonId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getMentorChallenge(challengeId) {
    var url = this.baseUrl + "Mentors/mentors-for-challenge/" + challengeId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getLessonCategoryDetailsById(categoryId: string) {
    var url = this.baseUrl + "lessoncategories/" + categoryId;
    return this.http.get<any>(url);
  }

  getLessonCategoriesbyCompany(companyId: string) {
    var url = this.baseUrl + "lessoncategories?companyId=" + companyId;
    return this.http.get<any>(url);
  }

  updateSkillDetails(category: LessonSkill) {
    let formData = new FormData();
    for (var key in category) {
      formData.append(key, category[key]);
    }
    let headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    var url = this.baseUrl + "lessoncategories/" + category.lessonCategoryId;
    return this.http.put<any>(url, formData, { headers: headers });
  }

  addLessonCategory(category) {
    let formData = new FormData();
    for (var key in category) {
      formData.append(key, category[key]);
    }
    let headers = new HttpHeaders().append(
      "Content-Disposition",
      "multipart/form-data"
    );
    var url = this.baseUrl + "lessoncategories";
    return this.http.post<any>(url, formData, { headers: headers });
  }

  addLesson(lesson: Lesson) {
    var url = this.baseUrl + "lessons/lesson";
    return this.http.post<any>(url, lesson);
  }

  deletelesson(lessonId) {
    let url = this.baseUrl + "lessons/" + lessonId
    var response = this.http.delete<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
    return response;
  }

  addChallenge(challenge) {
    var url = this.baseUrl + "ChallengeSkill";
    return this.http.post<any>(url, challenge, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  challengeSendInvites(lesson: any, emails: string[], emailContent: string, emailSubject: string, usageCount: number) {
    var url = this.baseUrl + "ChallengeSkill/invite";
    return this.http.post<any>(url, {
      emails: emails,
      challengeDetails: lesson,
      emailContent: emailContent,
      emailSubject: emailSubject
    }, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  sendInvites(lesson: Lesson, emails: string[], emailContent: string, emailSubject: string, usageCount: number) {
    var url = this.baseUrl + "lessons/invite";
    return this.http.post<any>(url, {
      emails: emails,
      lessonDetails: lesson,
      emailContent: emailContent,
      emailSubject: emailSubject,
      usageCount: usageCount
    })
  }

  getEmailLog() {
    var url = this.baseUrl + "lessons/email/log";
    return this.http.get<any>(url);
  }

  uploadSectionImagesOfChallenge(images: File[]) {
    let formData = new FormData();
    images.forEach((image) => formData.append("files", image));
    let headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data")
      .append("x-api-version", "2")
      .delete("Content-Type");
    var url = this.baseUrl + "ChallengeSkill/section/images";
    return this.http.post<any>(url, formData, { headers: headers });
  }

  uploadSectionImages(images: File[]) {
    let formData = new FormData();
    images.forEach((image) => formData.append("files", image));
    let headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data")
      .delete("Content-Type");
    var url = this.baseUrl + "lessons/section/images";
    return this.http.post<any>(url, formData, { headers: headers });
  }

  deletelessonCategory(lessonSkillId) {
    let url = this.baseUrl + "lessoncategories/" + lessonSkillId
    var response = this.http.delete<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
    return response;
  }

  updateLessonCategories(lessonCategories: LessonSkill[]) {
    var url = this.baseUrl + "lessoncategories";
    return this.http.put<any>(url, lessonCategories);
  }

  setupLevel(value?, index?) {
    if (
      this.selectedCategory.lessons &&
      this.selectedCategory.lessons.length > 0
    ) {
      this.selectedCategory.levels = [
        ...new Set(this.selectedCategory.lessons.map((x) => x.level)),
      ];
      index = this.selectedCategory.levels.indexOf(parseInt(value));
      this.selectedCategory.selectedLevel =
        this.seletedLevelIndex == -1
          ? this.selectedCategory.levels[0]
          : this.selectedCategory.levels[this.seletedLevelIndex];
      this.selectedCategory.leveledLessons = this.selectedCategory.lessons.filter(
        (x) => x.level === this.selectedCategory.selectedLevel
      );
    }
  }

  sendData(value?) {
    this.cartCategory.next(value);
  }

  clearData() {
    this.cartCategory.next();
  }

  getData(): Observable<any> {
    return this.cartCategory.asObservable();
  }

  setCampaignLessons(lessons) {
    // this.cookie.put('c_l', JSON.stringify(lessons))
    localStorage.setItem("c_l", JSON.stringify(lessons));
    this.campaignLessons = lessons;
  }

  managePeerAnswer(peerAnswer: CandidatePeerAnswer) {
    var url = this.baseUrl + "answers/peer";
    return this.http.post<any>(url, peerAnswer);
  }

  saveLessonRating(rating: UserLessonRating) {
    var url = this.baseUrl + "User/rating";
    return this.http.post<any>(url, rating);
  }

  getSkillAppLinkForLesson(lessonCategoryId) {
    var url = this.baseUrl + "LessonCategories/UpdateSkillAppLink?lessonCategoryId=" + lessonCategoryId;
    return this.http.get<any>(url, {});
  }

  testNewScoringAlgo(questionId, answer) {
    let url = this.baseUrl + "Lessons/NewScoringAlgo";
    return this.http.post<any>(url, { question_id: questionId, user_answer: answer });
  }

  getMentorRequestLessonResults(userId, lessonIds) {
    var url = this.baseUrl + "Answers/GetMentorRequestLessonResults/" + userId;
    return this.http.post<any>(url, lessonIds);
  }

  getAllLessonCategories() {
    var url = this.dashUrl + "LessonCategories";
    return this.http.get<any>(url, {});

  }

  getLessonBySkillName(skillName) {
    var url = this.baseUrl + "Lessons/GetLessonsBySkillName?skillName=" + skillName.toLowerCase();
    return this.http.get<any>(url, {});
  }

  getAllLessons(pageNumber = 1, pageSize = 4, tags?: string[]) {
    var url = this.baseUrl + 'Lessons?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    // if (tag !== undefined && tag !== null && tag.trim() !== '') {
    //   url += '&tags=' + tag;
    // }
    if (tags && tags.length > 0) {
      for (let tag of tags) {
        tag = tag.replace("&", "%26");
        url += '&tags=' + tag;
      }
    }
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getOverallLessonStreak(userId: string) {
    var url = this.baseUrl + "Lessons/GetOverallLessonStreak/" + userId;
    return this.http.get<any>(url, {});
  }

  getAllComments(sectionId: string, lessonId: string) {
    var url = this.baseUrl + "LessonDiscussions?sectionId=" + sectionId + "&lessonId=" + lessonId;
    return this.http.get<any>(url, {});
  }

  postComment(comment: PostDiscussionRequest) {
    var url = this.baseUrl + "LessonDiscussions";
    return this.http.post<PostDiscussionRequest>(url, comment);
  }

  upsertMentorAnswerLike(sectionId: string, lessonId: string, mentorUserId: string, likeStatus: number) {
    var url = this.baseUrl + "Lessons/UpsertMentorAnswerLike";
    return this.http.post<any>(url, {
      sectionId: sectionId,
      lessonId: lessonId,
      mentorUserId: mentorUserId,
      likeStatus: likeStatus
    }, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  getMentorAnswerLikesByLesson(lessonId: string) {
    var url = this.baseUrl + "Lessons/GetMentorAnswerLikesByLesson?lessonId=" + lessonId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  upsertLessonDiscussionLike(commentId: number, likeStatus: number) {
    var url = this.baseUrl + "LessonDiscussions/UpsertLessonDiscussionLike?commentId=" + commentId + "&likeStatus=" + likeStatus;
    return this.http.post<any>(url, {});
  }

  postAnonymousComment(request: PostAnonymousDiscussionRequest) {
    var url = this.baseUrl + "LessonDiscussions/PostAnonymousComment";
    return this.http.post<any>(url, request);
  }
}
