import {
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {trigger, transition, animate, style} from "@angular/animations";
import {GleacColumnDef} from "../data-grid-new-dashboard/data-grid-new-dashboard.model";
import {CommonService} from "../../services/common.service";
import {ConfigsService} from "../../services/configs.service";
import {UsersService} from "../../services/users.service";
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: "app-add-roles",
  templateUrl: "./add-role.component.html",
  styleUrls: ["./add-role.component.less"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({transform: "translateX(100%)"}),
        animate("200ms ease-in", style({transform: "translateX(70%)"})),
      ]),
      transition(":leave", [
        animate("200ms ease-in", style({transform: "translateX(100%)"})),
      ]),
    ]),
  ],
})
export class AddRoleComponent implements OnInit{
  page_title = "Add Role";
  iserrorPopup: boolean = false;
  error_popup = {};
  roleData = {};
  items: TreeviewItem[];

  roleId;

  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 300
  });

  constructor(
    private configsService: ConfigsService,
    private commonService: CommonService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {


    let usersModule = new TreeviewItem({
      text: 'Users', value: 1, collapsed: false, children: [
        { text: 'View', value: 11, checked : false },
        { text: 'Add', value: 12, checked : false },
        { text: 'Delete', value: 13 , checked : false},
        { text: 'Export', value: 13, checked : false },
      ]
    });

    let lessonsModule = new TreeviewItem({
      text: 'Lessons', value: 1, collapsed: false, children: [
        { text: 'View', value: 11 },
        { text: 'Add', value: 12 },
        { text: 'Delete', value: 13 },
        { text: 'Export', value: 13 },
      ]
    });

    this.items = [usersModule, lessonsModule,usersModule, lessonsModule,usersModule, lessonsModule]
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.roleId = params["roleId"];
      if(this.roleId != "0")
      {
        this.page_title = "Edit Role";
        this.getRoleDetails();
      }

    });
  }

  getRoleDetails()
  {
    this.commonService.getCommonApiNew("Roles/GetRolesById/" + this.roleId).subscribe(
      (data) => {
        this.roleData = data.result;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

}
