import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../services/common.service";
import { GleacColumnDef } from '../data-grid/data-grid.model';
import { ReportService } from 'src/app/services/report.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Filter } from './user-activity-report.model';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AnswerPopupComponent } from 'src/app/dialogs/answer-popup/answer-popup.component';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from '../../services/configs.service';
import { UserType } from "../common/common.model";
import {
  DataGirdServerSidePaginationComponent
} from "../data-grid-server-side-pagination/data-gird-server-side-pagination.component";
import { ToastService } from "../../services/toast-service";
import { LessonsService } from "../../services/lessons.service";
import { CandidatePeerAnswer } from "../../dialogs/answer-popup/answer.model";
import { GoalsService } from "../../services/goal.service";

@Component({
  selector: "app-user-activity-report",
  templateUrl: "./user-activity-report.component.html",
  styleUrls: ["./user-activity-report.component.less"],
})
export class UserActivityReportComponent implements OnInit {
  error_popup = { display: false, message: null };
  page_title = "User Activity Report";
  today = new Date();
  answerDialog: MatDialogRef<AnswerPopupComponent>;
  error: boolean;
  dataFilter: string;
  loading: boolean = false;
  transactionsGridData: any;
  transactionsColumnData: GleacColumnDef[] = [
    { data: "candidateName", displayName: "User Name" },
    { data: "email", displayName: "User Email" },
    {
      data: "isMentor",
      displayName: "IsMentor",
      render: (data, row) => {
        return !!row.isMentor;
      }
    },
    { data: "lessonSkill", displayName: "Skill" },
    { data: "lessonTitle", displayName: "Lesson Title" },
    {
      data: "submittedDateTime",
      displayName: "Date",
      disableSort: true,
      render: (data, row) => {
        var date = new Date(row.submittedDateTime);
        return (
          date.getUTCDate() +
          "/" +
          (date.getUTCMonth() + 1) +
          "/" +
          date.getUTCFullYear() +
          ", " +
          (date.getUTCHours() % 12
            ? date.getUTCHours() % 12 < 10
              ? "0" + (date.getUTCHours() % 12)
              : date.getUTCHours() % 12
            : 12) +
          ":" +
          (date.getUTCMinutes() < 10
            ? "0" + date.getUTCMinutes()
            : date.getUTCMinutes()) +
          " " +
          (date.getUTCHours() >= 12 ? "PM" : "AM")
        );
      },
    },
    {
      data: "lessonTimeInSeconds",
      displayName: "Time Spent",
      disableSort: true,
      render: (data, row) => {
        var minutes = Math.floor(row.lessonTimeInSeconds / 60);
        var seconds = row.lessonTimeInSeconds - minutes * 60;
        return (
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds)
        );
      },
    },
    { data: "wordCount", displayName: "Word Count" },
    {
      data: "selfReviewAnswers",
      displayName: "Self Eval Rating",
      disableSort: true,
      render: (data, row) => {
        let rating = "";
        row.selfReviewAnswers.forEach((item, index) => {
          rating =
            index < 2
              ? rating + item.reviewRating + " | "
              : rating + item.reviewRating;
        });
        return rating;
      },
    },
    {
      data: "candidateScore",
      displayName: "Score",
      render: (data, row) => {
        return (row.candidateScore * 100).toFixed(2);
      },
    },
    { data: "lessonSource", displayName: "Lesson Source" },
    {
      // <<<<<<< HEAD
      //       data: "candidateSectionAnswers",
      //       displayName: "User Answer",
      //       disableSort: true,
      //       isClickable: true,
      //       render: (data, row) => {
      //         return '<a href="javascripti:void(0)" class="btn btn-grid">Answer</a>';
      //       },
      //     },
      // =======
      data: "candidateSectionAnswers",
      displayName: "User Answer",
      disableSort: true,
      isClickable: true,
      render: (data, row) => {
        return '<a class="btn btn-grid"><i class="fa fa-eye" aria-hidden="true"></i></a>';
      }
    },
    {
      data: "isPeerAnswer",
      displayName: "Peer Answer",
      isClickable: true,
      actionName: "markPeerAnswer",
      render: (data, row) => {
        if (row.isPeerAnswer) {
          return '<a style="cursor: pointer" class="btn-star-active" title="Marked as Peer Answer"><i class="fa fa-star" aria-hidden="true"></i></a>';
        } else {
          return '<a style="cursor: pointer" class="btn-star-inactive" title="Not marked as Peer Answer"><i class="fa fa-star" aria-hidden="true"></i></a>';
        }
      }
    }
    // >>>>>>> develop
  ];
  filterForm: FormGroup = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    search: new FormControl(),
    organization: new FormControl(),
    campaign: new FormControl(),
    group: new FormControl(),
    minimumLessons: new FormControl()
  });
  selectedGrp: [];
  selectedOrg: [];
  selectedCampaign: [];
  organizationsList: {};
  groups: [];
  campaignList: {};
  url = "";
  iserrorPopup: boolean = false;
  queryParam: any = [];
  query;
  companyId;
  pageNumber: number = 1;
  pageSize: number = 25;
  userId;
  reportData;
  repFromDate;
  repToDate;
  totalCount: number = 0;
  webCount: number = 0;
  mobileCount: number = 0;
  minimumLessons: number = null;

  // @ViewChild(DataGirdServerSidePaginationComponent, {static: false}) table: DataGirdServerSidePaginationComponent;

  constructor(
    private configsService: ConfigsService,
    private reportService: ReportService,
    private dialog: MatDialog,
    public usersService: UsersService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private lessonService: LessonsService,
    private goalService: GoalsService
  ) {
  }

  ngOnInit() {
    this.usersService.isMeasure = true;

    if (this.usersService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.usersService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
      return;
    }

    this.route.params.subscribe(params => {
      this.userId = params["id"];
      var data = { search: null, fromDate: null, toDate: null, organization: null, campaign: null };

      if (this.userId) {
        this.filterForm.patchValue({ search: this.userId });
        data = { search: this.userId, fromDate: null, toDate: null, organization: null, campaign: null };
      }

      this.filterData(data);
    });

    this.getCompanyList();
    this.getGroups();
  }


  downloadReport() {
    if (this.transactionsGridData === undefined) return;

    let data = this.reportData;
    let dataFilter = data;
    var filterDetails: Filter = {
      fromDate: dataFilter.fromDate,
      toDate: dataFilter.toDate,
      search: dataFilter.search,
      organization: dataFilter.organization,
      campaign: dataFilter.campaign,
      group: dataFilter.group,
      minimumLessons: dataFilter.minimumLessons
    };

    if (filterDetails.toDate && filterDetails.fromDate && filterDetails.toDate < filterDetails.fromDate) {
      this.error_popup = { display: true, message: "Invalid Date Range" };
      return;
    }

    let dataTosend = {};
    dataTosend["searchText"] = filterDetails.search ? filterDetails.search : "";
    dataTosend["fromDate"] = filterDetails.fromDate ? filterDetails.fromDate : new Date(0, 1, 1);
    dataTosend["toDate"] = filterDetails.toDate ? filterDetails.toDate : new Date(9999, 1, 1);
    dataTosend["fromDate"] = filterDetails.fromDate ? new Date(filterDetails.fromDate.getTime() - (filterDetails.fromDate.getTimezoneOffset() * 60000)).toJSON() : "";
    dataTosend["toDate"] = filterDetails.toDate ? new Date(filterDetails.toDate.getTime() - (filterDetails.toDate.getTimezoneOffset() * 60000)).toJSON() : "";

    dataTosend["organizationId"] = filterDetails.organization ? filterDetails.organization : "";
    dataTosend["groupId"] = filterDetails.group ? filterDetails.group : "";
    dataTosend["lessonCampaignId"] = filterDetails.campaign ? filterDetails.campaign : [];
    dataTosend["minimumLessons"] = filterDetails.minimumLessons;
    let downUrl = "Reports/useractivityDownload";
    this.configsService.loading(true);
    // if(this.reportData["organizationId"])
    this.commonService.fileDownload(downUrl, dataTosend, false, this.pageNumber, this.pageSize).subscribe(
      (data) => {
        // console.log(data,data['type']);
        // document.body.appendChild(a);
        let blob = new Blob([data], { type: data['type'] }),
          url = URL.createObjectURL(blob);
        // console.log(blob);
        let a = document.createElement('a');
        a.download = 'report.csv';
        a.href = url;
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      },
      (err) => {
        console.log(err);
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
    // }
  }

  filterData(filters, clearCache?: boolean, pageNumber?: number) {
    this.pageNumber = pageNumber === undefined ? 1 : pageNumber;

    if (this.usersService.currentUser.authRole.toLowerCase() === UserType.PARTNER.toLowerCase() && this.usersService.currentUser.company == '00000000-0000-0000-0000-000000000000') {
      return;
    }
    this.reportData = filters;
    this.setupFilterFormValidations();

    if (filters.toDate && filters.fromDate && filters.toDate < filters.fromDate) {
      this.error_popup = { display: true, message: "Invalid Date Range" };
      return;
    }

    this.configsService.loading(true);
    this.usersService.currentUser.company = this.usersService.currentUser.company ? this.usersService.currentUser.company : null;

    this.reportService
      .getUserActivityReportGrid(filters.search ? filters.search.trim() : filters.search, filters.fromDate, filters.toDate, filters.organization, filters.group, filters.campaign, filters.minimumLessons, this.pageNumber, this.pageSize, clearCache)
      .subscribe(
        (data) => {
          let dataList = {
            list: data.result.list.results,
            totalCount: data.result.list.totalCount
          }

          this.totalCount = data.result.list.totalCount;
          this.webCount = data.result.list.webCount;
          this.mobileCount = data.result.list.mobileCount;

          this.transactionsGridData = dataList;
          this.configsService.loading(false);
        },
        (err) => {
          this.configsService.loading(false);
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  setupFilterFormValidations() {
    console.log(this.filterForm.controls);
    for (let key in this.filterForm.controls) {
      switch (key) {
        case "fromDate":
        case "toDate":
          this.filterForm.controls[key].setValidators([
            this.datePickerValidation.bind(this),
          ]);
          break;
      }
    }
  }

  datePickerValidation(control: AbstractControl) {
    console.log(control);
    var date = new Date(control.value);
    if (!date) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  clearTodate() {
    this.repToDate = "";
  }

  showAnswer(event) {
    let details = event.data;

    if (event.actionName === "markPeerAnswer") {
      this.configsService.loading(true);

      let peerAnswer: CandidatePeerAnswer = {
        LessonId: details.lessonId,
        CandidateAnswerId: details.candidateAnswerId,
        CandidateId: details.candidateId,
        PeerAnswerId: null
      };

      this.lessonService.managePeerAnswer(peerAnswer).subscribe(data => {
        this.configsService.loading(false);
        if (data.hasError) {
          let toast = this.toastService.show((data.errors && data.errors[0] ? data.errors[0] : "Something went wrong. Please try again."), {
            classname: 'bg-danger text-light',
            delay: 5000
          });
          let context = this;
          setTimeout(function () {
            context.toastService.remove(toast);
          }, 5000)
        } else {
          let filters: Filter = {
            fromDate: this.filterForm.controls["fromDate"].value,
            toDate: this.filterForm.controls["toDate"].value,
            campaign: this.filterForm.controls["campaign"].value,
            organization: this.filterForm.controls["organization"].value,
            search: this.filterForm.controls["search"].value,
            group: this.filterForm.controls["group"].value,
          };
          if (filters.campaign != undefined || filters.fromDate != null || filters.organization != undefined || filters.search != null || filters.toDate != null) {
            this.filterData(filters, true);
          } else {
            this.configsService.loading(true);
            this.usersService.currentUser.company = this.usersService.currentUser
              .company
              ? this.usersService.currentUser.company
              : null;
            this.reportService
              .getUserActivityReportGrid(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                this.pageNumber,
                this.pageSize,
                true
              )
              .subscribe(
                (data) => {
                  let dataList = {
                    list: data.result.list.results,
                    totalCount: data.result.list.totalCount
                  }

                  this.totalCount = data.result.list.totalCount;
                  this.webCount = data.result.list.webCount;
                  this.mobileCount = data.result.list.mobileCount;

                  this.transactionsGridData = dataList;
                  this.configsService.loading(false);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = { display: true, message: err.error.errors[0] };
                },
                () => {
                  this.configsService.loading(false);
                }
              );
          }
        }
      }, err => {
        this.configsService.loading(false);
      });
    } else {
      this.answerDialog = this.dialog.open(AnswerPopupComponent, {
        width: "50%",
        data: {
          answers: details.candidateSectionAnswers,
          answerId: details.candidateAnswerId,
          lessonId: details.lessonId,
          isPeerAnswer: details.isPeerAnswer,
          candidateId: details.candidateId
        }
      });
      this.answerDialog.afterClosed().subscribe(data => {
        if (data.confirm) {
          var filters: Filter = {
            fromDate: this.filterForm.controls["fromDate"].value,
            toDate: this.filterForm.controls["toDate"].value,
            campaign: this.filterForm.controls["campaign"].value,
            organization: this.filterForm.controls["organization"].value,
            search: this.filterForm.controls["search"].value,
            group: this.filterForm.controls["group"].value,
          };
          if (filters.campaign != undefined || filters.fromDate != null || filters.organization != undefined || filters.search != null || filters.toDate != null) {
            this.filterData(filters, true);
          } else {
            this.configsService.loading(true);
            this.usersService.currentUser.company = this.usersService.currentUser
              .company
              ? this.usersService.currentUser.company
              : null;
            this.reportService
              .getUserActivityReportGrid(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                this.pageNumber,
                this.pageSize,
                true
              )
              .subscribe(
                (data) => {
                  let dataList = {
                    list: data.result.list.results,
                    totalCount: data.result.list.totalCount
                  }

                  this.totalCount = data.result.list.totalCount;
                  this.webCount = data.result.list.webCount;
                  this.mobileCount = data.result.list.mobileCount;

                  this.transactionsGridData = dataList;
                  this.configsService.loading(false);
                },
                (err) => {
                  this.configsService.loading(false);
                  this.iserrorPopup = true;
                  this.error_popup = { display: true, message: err.error.errors[0] };
                },
                () => {
                  this.configsService.loading(false);
                }
              );
          }
        }
      });
    }
  }

  resetSearch() {
    this.reportData = [];
    this.pageNumber = 1;
    this.filterForm.reset();
    this.configsService.loading(true);
    this.usersService.currentUser.company = this.usersService.currentUser.company
      ? this.usersService.currentUser.company
      : null;
    this.reportService
      .getUserActivityReportGrid(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.pageNumber,
        this.pageSize,
        false
      )
      .subscribe(
        (data) => {
          let dataList = {
            list: data.result.list.results,
            totalCount: data.result.list.totalCount
          }

          this.totalCount = data.result.list.totalCount;
          this.webCount = data.result.list.webCount;
          this.mobileCount = data.result.list.mobileCount;

          this.transactionsGridData = dataList;
          this.configsService.loading(false);
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  getCompanyList() {
    this.configsService.loading(true);
    this.url = "Company/";
    this.commonService.getListAlone(this.url).subscribe(
      (data) => {
        this.organizationsList = data.result.list;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  getGroups() {
    this.configsService.loading(true);
    this.url = "Company/";
    this.goalService.getGroups('', 100000, 1).subscribe(
      (data) => {
        this.groups = data.result.results;
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = { display: true, message: err.error.errors[0] };
      },
      () => {
        this.configsService.loading(false);
      }
    );
  }

  listCampaign(e) {
    if (e) {
      this.selectedCampaign = [];
      this.companyId = e.companyId;
      this.queryParam["companyId"] = e.companyId;
      this.configsService.loading(true);
      this.url = "Campaigns/active";
      this.query = this.commonService.encodeQueryData(this.queryParam);
      this.commonService.getListAlone(this.url, this.query).subscribe(
        (data) => {
          this.campaignList = data.result.list;
        },
        (err) => {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = { display: true, message: err.error.errors[0] };
        },
        () => {
          this.configsService.loading(false);
        }
      );
    } else {
      this.campaignList = [];
    }
  }

  clearCampaign() {
    // console.log(this.campaignList)
    this.campaignList = [];
    this.selectedCampaign = [];
  }

  pageChange(event) {
    this.pageNumber = event;

    let filters: Filter = {
      fromDate: this.filterForm.controls["fromDate"].value,
      toDate: this.filterForm.controls["toDate"].value,
      campaign: this.filterForm.controls["campaign"].value,
      organization: this.filterForm.controls["organization"].value,
      search: this.filterForm.controls["search"].value,
      group: this.filterForm.controls["group"].value,
      minimumLessons: this.filterForm.controls["minimumLessons"].value
    };
    if ((filters.campaign && filters.campaign.length > 0) || filters.fromDate || filters.organization || filters.group || filters.search || filters.toDate || filters.minimumLessons) {
      this.filterData(filters, true, this.pageNumber);
    } else {

      this.configsService.loading(true);
      this.usersService.currentUser.company = this.usersService.currentUser
        .company
        ? this.usersService.currentUser.company
        : null;
      this.reportService
        .getUserActivityReportGrid(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.pageNumber,
          this.pageSize,
          true
        )
        .subscribe(
          (data) => {
            let dataList = {
              list: data.result.list.results,
              totalCount: data.result.list.totalCount
            }

            this.totalCount = data.result.list.totalCount;
            this.webCount = data.result.list.webCount;
            this.mobileCount = data.result.list.mobileCount;

            this.transactionsGridData = dataList;
            this.configsService.loading(false);
          },
          (err) => {
            this.configsService.loading(false);
            this.iserrorPopup = true;
            this.error_popup = { display: true, message: err.error.errors[0] };
          },
          () => {
            this.configsService.loading(false);
          }
        );
    }
  }

  formFile(data) {
    console.log("file", data)
  }

  // getData($event) {
  //   this.configsService.loading(true);
  //   this.dataFilter = $event == null || $event.srcElement == null ? null : $event.srcElement.value;
  //   let data = { search: this.userId, fromDate: null, toDate: null, organization: null, campaign: null };
  //   this.pageNumber = this.table == null ? 0 : this.table.paginator.pageIndex;
  //   this.pageSize = this.table == null ? 1000 : this.table.paginator.pageSize;
  //   this.reportService.getUserActivityReportGrid(data.search, data.fromDate, data.toDate, data.organization, data.campaign, this.pageNumber + 1, this.pageSize).subscribe(data => {

  //   // this.reportService.getUserReportGrid(this.dataFilter, this.usersService.currentUser.company, pageNumber + 1, pageSize).subscribe(data => {
  //     this.transactionsGridData = data.result;
  //   },err => {
  //     this.configsService.loading(false);
  //     this.error_popup = {display: true, message: err.error.errors[0] };
  //   },()=> {
  //       this.configsService.loading(false);
  //   });
  // }
}
