import { SafeResourceUrl } from '@angular/platform-browser';
import { AbstractControl } from '@angular/forms';

export enum SectionElementType {
  TEXTBOX_DESCRIPTION = 1,
  TEXTBOX_SINGLE = 7,
  TEXTBOX_MULTI = 8,
  IMAGE_UPLOAD = 2,
  SELECTION_BUTTONS_SINGLE = 3,
  SELECTION_BUTTONS_MULTI = 10,
  IMAGE_URL = 4,
  VIDEO_URL = 5
}

export enum UserType {
  SA = "2fddbe14-60e4-482f-851d-5f016ee3ab6b",
  PARTNER = "7748CC20-55F7-450C-B3CF-C44CB20BEF00",
  USER = "243A006B-6B78-445C-BDC4-AD083A9309A2"
}

export enum SkillType {
  GENERIC = 0,
  INDUSTRY = 1,
  CUSTOM = 2,
  TRIAL = 3,
  // CHALLENGE = 4,
  HOT_COMPANY_CULTURE = 5,
  MOOD_LEARNING = 6
}

export enum SkillTags {
  Communication = "Communication",
  Judgement_Decision_Making = "Judgement & Decision Making",
  Mindfulness = "Mindfulness",
  Emotional_IQ = "Emotional Intelligence",
  Collaboration = "Collaboration",
  Critical_Thinking = "Critical Thinking",
  Creativity = "Creativity",
  Leadership = "Leadership",
  Self_Direction = "Self-Direction",
  Entrepreneurship = "Entrepreneurship",
}

export enum QuestionType {
  Reordering = 1,
  Selection = 2,
  Drag_And_Drop = 3,
  Multiple_Choice = 4,
  True_And_False = 5,
  Textbox = 6
}

export interface Lesson {
  lessonId: string;
  lessonCategory?: string;
  lessonSummary: string;
  title: string;
  lessonImage: string;
  lessonTitle?: string,
  levelTitle?: string;
  lessonCategoryId: string;
  level: number;
  subLevel?: number;
  selfReviewQuestions: LessonReviewQuestion[];
  peerReviewQuestions?: LessonReviewQuestion[];
  sections: Section[];
  answer?: string;
  isVisible?: boolean;
  companyId: string;
  isDefault?: boolean
  sectionImages?: File[];
  isCustom?: boolean;
  isTrial?: boolean;
  shLink?: string;
  appLink?: string;
  lessonRating?: number;
}

export interface LessonReviewQuestion {
  question: string;
  questionId: string;
  maxScale: number;
  minScale: number;
  reviewQuestionType?: number;
  selectedValue?: number;
}

export interface Section {
  sectionId: string;
  sectionOrder: number;
  sectionControls: SectionElement[];
  sectionTitle: string;
  showTextBox?: boolean;
  userAnswer?: string;
  isDone?: boolean;
  isCustom?: boolean;
  sectionTag: string;
  questionType?: Number;
  mentorAnswers?: any;
  technicalAnswers?: any;
  isChallenge?: boolean;
  showMentorAnswer?: boolean;
  showMA?: boolean;
}

export interface SectionElement {
  sectionElementId: string;
  sectionElementType: SectionElementType;
  sectionElementContent: any;
  sectionElementOptions?: string[];
  sectionImageFile?: File;
  sectionElementOrder: number;
  sanitizedUrl?: SafeResourceUrl;
  selectedOption?: string,
  answerType?: string
  sectionAnswerControl?: AbstractControl,
  sectionAnswer?: string | string[]
}

export interface CandidateLessonAnswer {
  userEmailId: string;
  userEmail?: string;
  lessonId: string;
  candidateId: string;
  LessonCategoryId?: string;
  candidateSectionAnswers: SectionAnswer[];
  selfReviewAnswers: LessonReviewAnswer[];
  peerReviewAnswers?: LessonReviewAnswer[];
  lessonTimeInSeconds: number;
  companyId?: string;
}

export interface SectionAnswer {
  sectionId: string;
  sectionElementAnswers: SectionElementAnswer[];
  isLiked?: boolean;
}

export interface SectionElementAnswer {
  sectionElementId: string;
  answer: any;
  //sectionElementOptions?: string[];
}

export interface LessonReviewAnswer {
  reviewQuestionId: string;
  reviewRating: number;
}

export interface CandidateAnswerScore {
  answerScoreId: string;
  candidateId: string;
  lessonId: string;
  candidateAnswerId: string;
  perfectAnswer: string;
  candidateScore: number;
  similarityIndex: number;
  lessonTime: number;
  lessonSubmittedDate: Date
}

export interface EmailInvite {
  emailSubject: string;
  emailContent: string;
  emails: string[];
  lessonDetails: Lesson
  companyId?: string;
}

export interface PeerAnswer {
  lessonId: string;
  answers: SectionAnswer[];
  candidateId: string;
  candidateName: string;
  designation: string;
  headline: string;
  image: string;
  linkedInUrl: string;
  isLiked: boolean;
  likesCount: number;
}

export class MetaTag {
  name: string;
  value: string;
  isFacebook: boolean;

  constructor(name: string, value: string, isFacebook: boolean) {
    this.name = name;
    this.value = value;
    this.isFacebook = isFacebook;
  }
}

export interface Discussions {
  comment: string;
  mentionedUser: string;
  author: string;
  title: string;
  company: string;
  linkedIn: string;
  imageUrl: string;
  isLiked: number;
  likes: number;
  subThreads: Discussions[];
}