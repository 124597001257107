import {Component, OnInit, Input} from "@angular/core";
import {FormControl} from "@angular/forms";
import {BadgeService} from "src/app/services/badge.service";
import {Skill, UserData} from "./badge.model";
import {TransactionData} from "../user/user.model";
import {appSettings} from "src/environments/environment";
import {ConfigsService} from "../../services/configs.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from '@angular/common';
import {saveAs} from 'file-saver';


@Component({
  selector: "app-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.less"],
})
export class BadgeComponent implements OnInit {
  error_popup = {};
  @Input() data: any;
  userData: any;
  performenceTab = true;

  constructor(
    private badgeService: BadgeService,
    private configsService: ConfigsService,
    protected route: ActivatedRoute,
    protected router: Router,
    private datepipe: DatePipe
  ) {
  }

  ngOnInit() {

    this.configsService.loading(true);

    this.badgeService
      .getUserReport(this.data.userId)
      .subscribe(
        (data) => {
          this.userData = data.result;

          console.log(this.userData);

          if (!this.userData.image) {
            this.userData.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
          }

          if (this.userData.image && !this.userData.image.startsWith("http")) {
            this.userData.image = 'https://gleac-profile-pictures.s3.ap-south-1.amazonaws.com/EmptyAvatart.png';
          }

          this.userData.createdOn = this.datepipe.transform(this.userData.createdOn, 'MMM d, y');

          if (this.userData.benchmarkDetails.length > 0) {
            this.userData.benchmarkDetails.forEach(function (item) {
              if (item.benchmarkName.includes("Master")) {
                item.mUrl = item.downloadUrl;
              } else {
                item.mUrl = ''
              }
            })
          }

        },
        (err) => {
          this.configsService.loading(false);
          this.error_popup = {display: true, message: err.error.errors[0]};
        },
        () => {
          this.configsService.loading(false);
        }
      );
  }

  transform(date) {
    return this.datepipe.transform(date, 'medium');
  }

  downloadReport(url: string, fileName: string) {

    this.badgeService.getReportBlob(url).subscribe(data => saveAs(data, fileName + '.png'));
  }

  getReportUrl(url: string, fileName: string) {
    if (fileName.includes("Master"))
      return url;

    return '';
  }

  transformLearningTimeHours(value: number): string {
    let hours = Math.floor(value / 60);
    return hours + 'h';
  }

  transformLearningTimeMinutes(value: number): string {
    let minutes = Math.floor(value % 60);
    return minutes + 'm';
  }

  colorBasesOnScore(value: number)
  {
    if(value > 67)
    {
      return 'green';
    }
    else if(value < 67 && value > 50)
    {
      return 'orange';
    }
    else
    {
      return 'red';
    }
  }

}
