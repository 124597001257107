import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {

  public invokeLoadingEvent: Subject<any> = new Subject();
  breadcrumb = [];

  constructor(private cookie: CookieService) {
  }

  loading(isLoading) {
    let value = {};
    value['isLoading'] = isLoading;
    this.invokeLoadingEvent.next(value);
    return isLoading;
  }

  setBreadcrumb(breadcrumbList) {
    this.cookie.put('b_c', JSON.stringify(breadcrumbList))
    this.breadcrumb = breadcrumbList;
  }

  getBreadcrumb() {
    if (!this.breadcrumb.length && this.cookie.get('b_c')) {
      this.breadcrumb = JSON.parse(this.cookie.get('b_c'));
    }
    return this.breadcrumb;
  }
}
