import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UsersService} from '../services/users.service';
import {UserType} from '../components/common/common.model';

@Injectable({providedIn: 'root'})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private usersService: UsersService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.usersService.currentUser) {
      localStorage.clear();
      this.usersService.stopRefreshTokenTimer();
      this.router.navigate(["/login"]);
      return false;
    }

    if (this.usersService.currentUser && (!this.usersService.currentUser.permissions || this.usersService.currentUser.permissions.length == 0)) {
      localStorage.clear();
      this.usersService.currentUser = null;
      this.usersService.stopRefreshTokenTimer();
      this.router.navigate(["/benchmark/login"]);
      return false;
    }

    if (route.data && route.data.permission) {
      if (this.usersService.isAllowed(route.data.permission)) {
        return true;
      } else {
        this.router.navigate(["/select"]);
        return false;
      }
    } else {
      return true;
    }
  }
}
