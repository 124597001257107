import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { of } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { UserRegistration, UserPasswordReset } from '../login/login.model';
import { ConfigsService } from '../../services/configs.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {

  passwordResetForm: FormGroup = new FormGroup({
    newPassword: new FormControl(),
    confirmationPassword: new FormControl()
  });
  encryptedData: string;
  isKeyValid: boolean = false;
  errors: string[] = [];
  showLoader: boolean = false;
  user: UserPasswordReset;
  resendCode: boolean;
  notifs: string[] = [];
  userNotExist: boolean;

  constructor(private usersService: UsersService,private configsService : ConfigsService,private route: ActivatedRoute, private useresService: UsersService, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let key = params["key"];
      this.configsService.loading(true);
      this.useresService.confirmForgotPasswordKey(key).subscribe(res => {
        if(res.statusCode === 200){
           let user: UserPasswordReset = {
              email: res.result.email,
              userId: res.result.userId,
              password: undefined
            }
            this.user = user;
            this.isKeyValid = true;
            this.initializeFormValidations();
        }
        if(res.statusCode === 500){
          this.errors.push("Your password reset link has expired");
          this.resendCode = true;
        }
      }, err => {
        this.configsService.loading(false);
        this.isKeyValid = false;
        this.errors.push("Invalid URL. Please try again.");
      },()=> {
            this.configsService.loading(false);
       });
    });
  }

  initializeFormValidations() {
    this.passwordResetForm.controls["newPassword"].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(25)]);
    this.passwordResetForm.controls["confirmationPassword"].setValidators([Validators.required, this.passwordConfirmationValidator.bind(this)])
  }
  forgotPassword(){
    this.router.navigate(["password/forgot"]);
  }
  passwordConfirmationValidator(control: AbstractControl) {
    var value = this.passwordResetForm.controls.newPassword.value;
    return control.value !== value ? { "invalid": true } : null
  }

  resetPassword(event) {
    this.passwordResetForm.markAllAsTouched();
    if(this.passwordResetForm.valid) {
      this.showLoader = true;
      this.user.password = this.passwordResetForm.controls["newPassword"].value;
      this.configsService.loading(true);
      this.useresService.resetPassword(this.user).subscribe(res => {
        this.showLoader = false;
        this.errors.push("Your password has been successfully updated.")
        // this.router.navigate(["login"]);
      }, err => {
        this.showLoader = false;
        this.configsService.loading(false);
      },()=> {
         this.configsService.loading(false);
       });
    }
  }

}
