import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { appSettings } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  jobs: string[];
  url: string = appSettings.dashboardUrl;

  getCompanyDataGrid(
    filter?: string,
    pageIndex?: number,
    pageSize?: number,
    status?: string
  ) {
    var apiUrl;
    apiUrl = filter
      ? this.url +
        "Company/search/pagination?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize +
        "&searchText=" +
        filter
      : this.url +
        "Company/pagination?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize;
    if (status) {
      apiUrl = apiUrl + "&status=" + status;
    }
    var response = this.http.get<any>(apiUrl, {
      headers : new HttpHeaders({
        "x-api-version" : "2"
      })
    });
    return response;
  }

  version2Api = {
    headers : new HttpHeaders({
      "x-api-version" : "2"
    })
  };

  createCompany(data) {
    var url = this.url + "Company";
    return this.http.post<any>(url, data, this.version2Api);
  }

  updateCompany(data) {
    var url = this.url + "Company";
    return this.http.put<any>(url, data, this.version2Api);
  }

  deleteCompany(id) {
    var url = this.url + "Company?companyId=" + id;
    return this.http.delete<any>(url, this.version2Api);
  }

  export(
    filter?: string,
    status?: string
  ) {

    let apiUrl = this.url + "Company/export?searchText=" + filter + "&status=" + status;

    var response = this.http.get(apiUrl, {
      headers : new HttpHeaders({
        "x-api-version" : "2"
      }), responseType : 'blob'
    });
    return response;
  }
}
