import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ConfigsService} from 'src/app/services/configs.service';
import {data, param} from 'jquery';
import {HttpParams} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from "../../services/common.service";
import {AssignMentorService} from 'src/app/services/assign-mentor.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.less']
})
export class AddGroupComponent implements OnInit {
  isDisplay = true;
  pageLimit: number = 10;
  searchString: string;
  pageNumber = 1;
  @ViewChild('userList', {static: true}) private myScrollContainer: ElementRef;
  @ViewChild('addGroupUser', {static: false}) paginator: any;
  @Output() userClose: EventEmitter<any> = new EventEmitter();
  @Input() companyIdFromRoute?: any = '';
  title = "Create Group";
  userHover: number = null;
  users = [];
  newUsers = [];
  assignUsers = {};
  groupData: any = {};
  errorMessage = '';
  companyId;
  groupUrl = "";
  error_popup: { display: boolean; message: any; };
  iserrorPopup: boolean;
  scrollFlag: boolean = true;
  loadedAll: boolean;
  totalCount: any;
  issuccessPopup: boolean = false;
  success_popup = {
    display: false, message: "", buttonPositive: "", positiveButton: function () {
    },
  };

  companyDetails = {
    label: "",
    key: "company",
    placeholder: "Select the company",
    type: "dropdown",
    bindLabel: "name",
    multiple: false,
    value: [],
    options: [],
    apiUrl: "Partner",
  }

  status = {
    label: "Status",
    key: "Is_Active",
    required: "required",
    placeholder: "0",
    cssClass: 'col-6',
    value: true,
    type: "button_select",
    options: [
      {label: "Active", value: true},
      {label: "Inactive", value: false},
    ]
  }

  onApiCall = false;

  files;
  bulkFile;

  bulkInfo = {
    url: "Groups/GroupBulkUpload",
    templateUrl:
      "https://gleac-bulk-template.s3.us-west-2.amazonaws.com/Communication-User-Template.xlsx",
  };


  constructor(private configsService: ConfigsService, protected route: ActivatedRoute,
              private commonService: CommonService, private assignMentorService: AssignMentorService) {
    // if (this.companyIdFromRoute) {
    //       this.companyId = this.companyIdFromRoute;
    // }

    this.configsService.invokeLoadingEvent.subscribe((data) => {
      this.onApiCall = data.isLoading;
    })

  }

  ngOnInit() {
    this.getCompanyData();
    this.getUserData();
    this.groupData.isActive = true;
  }

  closeModel(ent) {
    this.isDisplay = false;
    this.userClose.emit();
  }

  getCompanyData() {
    this.configsService.loading(false);
    this.commonService.getCompanyData(this.companyDetails.apiUrl).subscribe((res) => {
        let context = this;
        context.companyDetails.options = [];
        res.result.forEach(function (item) {
          context.companyDetails.options.push({name: item.companyName, value: item.companyId});
        });
      },
      (err) => {
        console.log(err);
      });
  }

  getUserData() {
    this.configsService.loading(true);
    this.commonService.getCompanyUsers(this.pageLimit, this.pageNumber, this.searchString ? encodeURIComponent(this.searchString) : '', this.companyIdFromRoute ? this.companyIdFromRoute : this.companyId).subscribe((data) => {
        if (data.statusCode === 200) {
          if (data.result.results.length) {

            let context = this.newUsers;
            if (context.length > 0) {
              data.result.results.forEach(item => {
                if (context.includes(item.userId)) {
                  item.isChecked = true;
                }
              });
            }

            this.users = data.result.results;
            this.totalCount = data.result.totalCount;
          } else {
            this.users = [];
            this.totalCount = 0;
          }
          this.configsService.loading(false)
        }
        if (data.statusCode === 500) {
          this.configsService.loading(false);
          this.iserrorPopup = true;
          this.error_popup = {display: true, message: "Server is not responding"};
        }
      },
      (err) => {
        this.configsService.loading(false);
        this.iserrorPopup = true;
        this.error_popup = {display: true, message: err.error.errors[0]};
      },
      () => {
        this.configsService.loading(false);
      }
    )
  }

  addNewUser(user, event) {

    let index = this.newUsers.findIndex(d => d === user.userId); //find index in your array

    if (index >= 0) {
      this.newUsers.splice(index, 1);//remove element from array
    } else {
      this.newUsers.push(user.userId);
    }
  }

  fetchCompanyUsers(event) {
    this.companyId = event.value;
    this.configsService.loading(true);
    this.getUserData();
  }

  addGroup() {
    if (this.groupData.groupName) {
      this.groupData.groupName = this.groupData.groupName.trim();
    }
    if (!this.groupData.groupName) {
      this.errorMessage = 'Group Name is required.';
      return;
    } else if (!/^[a-zA-Z ]*$/.test(this.groupData.groupName.trim())) {
      this.errorMessage = 'Group Name is invalid.';
      return;
    }

    if (this.groupData.isBulkUpload && !this.bulkFile) {
      this.errorMessage = 'Please upload file for bulk upload.';
      return;
    }


    this.groupData.groupName = this.groupData.groupName.trim();
    this.groupUrl = "Groups/"
    this.groupData.users = this.newUsers;
    this.groupData.companyId = this.companyIdFromRoute ? this.companyIdFromRoute : this.companyId;

    this.configsService.loading(true);

    if (!this.groupData.isBulkUpload) {
      this.commonService
        .createDataV2(this.groupUrl, this.groupData)
        .subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.configsService.loading(false);
            this.errorMessage = 'Internal Server Error.';
          },
          () => {
            this.configsService.loading(false);
          }
        );
    } else {
      this.commonService
        .fileUploadWithData(this.bulkInfo.url, this.bulkFile, this.groupData)
        .subscribe(
          (data) => {
            this.postStatus(data);
          },
          (err) => {
            this.iserrorPopup = true;
            this.error_popup = {
              display: true,
              message: err.error.errors[0]
            };
          },
          () => {
            this.configsService.loading(false);
          }
        );
    }
  }

  postStatus(result) {
    if (!result.value.hasError) {
      this.issuccessPopup = true;
      this.success_popup = {
        display: true,
        message: result.value.message,
        buttonPositive: "Okay",
        positiveButton: () => {
          this.success_popup.display = false;
          this.closeModel('');
        },
      };
    } else {
      this.error_popup = {display: true, message: result.value.message};
      this.iserrorPopup = true;
    }
  }

  filterData(params) {
    this.searchString = params[0];
    this.paginator.changePage(0);
    //this.pageNumber = 1;
    //this.getUserData();
  }

  paginate(event) {
    this.pageNumber = (event.page + 1);
    this.getUserData();
    this.myScrollContainer.nativeElement.scrollTop = 0;
  }

  onGroupNameChange() {
    this.errorMessage = '';
  }


  removeFiles() {
    this.files = "";
  }

  loadFile(event) {
    let maxFileSize = 1024 * 1024 * 10;
    if (event.target.files[0].size > maxFileSize) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = {
        display: true,
        message: "Maximum file size supported is 10 MB",
      };
    } else if (
      event.target.files[0].type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.target.files[0].type != "application/vnd.ms-excel"
    ) {
      this.files = "";
      this.iserrorPopup = true;
      this.error_popup = {display: true, message: "Invalid File Type"};
    } else {
      this.bulkFile = event.target.files[0];
    }
  }
}
