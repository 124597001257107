import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { ConfigsService } from '../../services/configs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { BenchmarkLoginComponent } from 'src/app/components/benchmark-login/benchmark-login.component';
import { BenchmarkService } from 'src/app/services/benchmark.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'angular-6-social-login';
import { Login, UserRegistration } from "../../components/benchmark-login/benchmark-login.model";
import jwt_decode from "jwt-decode";
import { UserType } from "../../components/common/common.model";

declare var jQuery: any;

@Component({
    selector: 'demo-popup',
    templateUrl: './demo-popup.component.html',
    styleUrls: ['./demo-popup.component.less']
})
export class DemoPopupComponent implements OnInit {
    registerForm: FormGroup;
    timerHeader: string;
    isMobile: boolean;
    requestOTPLoader: boolean = false;
    isOTPRequested: boolean = false;
    showThanksPage = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, configsService: ConfigsService, protected usersService: UsersService, router: Router, protected formBuilder: FormBuilder,
        dialog: MatDialog, route: ActivatedRoute, benchmarkService: BenchmarkService, private dialogRef: MatDialogRef<DemoPopupComponent>,
        protected socialService: AuthService) {
        if (environment.isMobile) {
            this.isMobile = true;
        }

        (function ($) {
            $.fn.randomize = function (childElem) {
                return this.each(function () {
                    var $this = $(this);
                    var elems = shuffle($(childElem));
                    $this.remove(childElem);
                    for (var i = 0; i < elems.length; i++)
                        $this.append(elems[i]);
                });
            }
        })(jQuery);

        jQuery(function ($) {
            $("div.random-mentor-profile").randomize("div.mentor-profile");
            $(".mentor-profile:gt(4)").hide();
        });

        function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }

        let newUserRegistration = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            linkedIn: "",
            reason: ""
        }
        this.registerForm = this.formBuilder.group(newUserRegistration);
    }

    ngOnInit() {
        // this.loginForm.get("password").setValidators([Validators.required, Validators.pattern(/^[0-9]+$/)]);
        console.log(this.data);
    }

    close() {
        // this.configsService.loading(true);
        this.dialogRef.close();
    }

    submit() {
        this.usersService.submitHubSpotForm('536be582-d7b3-4e3c-9d8d-603e9470d953', {
            fields: [
                {
                    name: 'firstname',
                    value: this.registerForm.controls.firstName.value
                },
                {
                    name: 'lastname',
                    value: this.registerForm.controls.lastName.value
                },
                {
                    name: 'email',
                    value: this.registerForm.controls.email.value
                },
                {
                    name: 'phone',
                    value: this.registerForm.controls.phone.value
                },
                {
                    name: 'mentor_name',
                    value: this.data.name
                },
                {
                    name: 'mentor_email',
                    value: this.data.email
                }
                // {
                //     name: 'linkedin_profile',
                //     value: this.registerForm.controls.linkedIn.value
                // },
                // {
                //     name: 'reason_for_access',
                //     value: this.registerForm.controls.reason.value
                // }
            ]
        })
            .subscribe(() => {
                this.showThanksPage = true;
            });
    }
}